import React, { useState,useEffect,useCallback } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Icon from '../../../misc/Icon';
import orderBy from 'lodash/orderBy' 
import {getRecentMedia} from '../../../../utils/getRecentMedia'
import AddMediaPopoverResultsList from './AddMediaPopoverResultsList'
import AddMediaPopoverPrefillSearchesGallery from './AddMediaPopoverPrefillSearchesGallery'
import { prefillSearchesImage, prefillSearchesVideo } from './prefillSearchTerms';
//import {searchPexels} from '../../../../actions/imageSearch'
import { debounce } from 'lodash';
import filter from 'lodash/filter'
import AddMediaPopoverContent from './AddMediaPopoverContent'

function AddMediaModal(props) {
  const { 
    children,
    handleClosePopover,
    showPopover,
    handleNewImageFileUpload,
    insertImageFromRecent,
    handleNewVideoFileUpload,
    insertVideoFromRecent, 
    mediaType ,
    // handleNewWebcamFileUpload,
    insertWebcamFromRecent,
    isWebcam,
    addWebcamFromLibrary
  }=props

  const [submitLoading, setSubmitLoading] = useState(false);
  const [recentMedia,setRecentMedia] = useState([])

  useEffect(() => {
  if (showPopover) {
    fetchRecentMedia();
  }
}, [showPopover, mediaType]);


const fetchRecentMedia = () => {
  const fetchedRecentMedia = getRecentMedia(mediaType);
  let filteredMedia;
  if (mediaType === 'image') {
    filteredMedia = filter(fetchedRecentMedia, { type: 'image' });
  } else if (mediaType === 'video') {
    filteredMedia = filter(fetchedRecentMedia, { type: 'video' });
  } else { // mediaType === 'mixed'
    filteredMedia = fetchedRecentMedia;
  }
  
  setRecentMedia(filteredMedia);
};


  // const handleOpenPopover = () => {
  //   const recentMedia=getRecentMedia(mediaType)
  //   let filteredMedia 
  //   if(mediaType=='image'){
  //     filteredMedia=filter(recentMedia,{type:'image'})
  //   }else if(mediaType=='video'){
  //     filteredMedia=filter(recentMedia,{type:'video'})
  //   }else{ //mediaType=='mixed'
  //     filteredMedia=recentMedia
  //   }
  //   setRecentMedia(filteredMedia)
  // };

  const closePopover = () =>{
    handleClosePopover()
  }  



  return (
    <Dialog.Root open={showPopover} onOpenChange={(open) => {
      if (!open && !submitLoading) {
        closePopover(); // This will flush the blobs and call the handleClosePopover passed from the parent
      }
    }}>      
      <Dialog.Portal>
        <Dialog.Overlay className='popover--addMediaPopover--modalOverlay' />
        <Dialog.Content 
          // side="top"
          // sideOffset={7}
          // align="center"
          //alignOffset={-10}
          onCloseAutoFocus={submitLoading ? null : closePopover}
          className='popover popover--addMediaPopover popover--addMediaPopover--modal forceDarkTheme'
        >
          <AddMediaPopoverContent
            handleClosePopover={handleClosePopover}          
            handleNewImageFileUpload={handleNewImageFileUpload}
            insertImageFromRecent={insertImageFromRecent}
            handleNewVideoFileUpload={handleNewVideoFileUpload}
            insertVideoFromRecent={insertVideoFromRecent}
            mediaType={mediaType}
            // handleNewWebcamFileUpload={handleNewWebcamFileUpload}
            // insertWebcamFromRecent={insertWebcamFromRecent}
            isWebcam={isWebcam}
            recentMedia={recentMedia}
            addWebcamFromLibrary={addWebcamFromLibrary}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default AddMediaModal;
import { calculateTimelineTimeFromVideoTime } from "../../../timeline/utils/calculateTimelineTimeFromVideoTime";
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';

// Define timing constants (in seconds)
const DELAY_BEFORE_FADE = 1;      // Wait time before starting fade out
const MIN_INVISIBLE_TIME = 2;     // Minimum time cursor stays invisible
const MIN_CLIP_DURATION_FOR_FADES = 5;  // Minimum clip duration to show start/end fades
const MIN_TIME_BETWEEN_SEGMENTS = 3;     // Minimum time between fade segments
const MIN_SEGMENT_LENGTH = DELAY_BEFORE_FADE + MIN_INVISIBLE_TIME;
const MOVEMENT_THRESHOLD = 1; // 1 pixel
const FADE_IN_BEFORE_MOVEMENT = 0.4; // Start fade in this many seconds before movement
const FADE_OUT_SCALE = 0.9; // Scale multiplier when cursor is faded out
const Y_SHIFT_AMOUNT = -2; // pixels to shift up when faded out

const SCALE_SPRING_PARAMS = {
    mass: 1,
    stiffness: 200,
    damping: 4
};

const OPACITY_SPRING_PARAMS = {
    mass: 1,
    stiffness: 400,
    damping: 20
};

const Y_SHIFT_SPRING_PARAMS = {
    mass: 1,
    stiffness: 1200,
    damping: 60
};

const FPS = 60;

export class CursorOpacityManager {
    static hideInactiveCursor = false;

    static setHideInactiveCursor(value) {
        this.hideInactiveCursor = value;
    }

    

    static recalculateOpacity(cursorData, normalizedClickData, clip) {
        if (!cursorData || !cursorData.length) return cursorData;
        const baseData = cursorData.map(point => ({
            ...point,
            opacity: undefined // Remove any previously set opacity so we recalc
        }));

        return this.getCursorOpacity(baseData, normalizedClickData || [], clip); 
    }

    static getCursorOpacity = (meshScaledMovementData, normalizedClickData, clip) => {
        if (!meshScaledMovementData || !meshScaledMovementData.length) return [];

        if (!CursorOpacityManager.hideInactiveCursor) {
            return meshScaledMovementData.map(point => ({
                ...point,
                opacity: 1
            }));
        }
        
        // Convert all points to timeline time at the start
        const timelineData = meshScaledMovementData.map(point => ({
            ...point,
            timelineTime: calculateTimelineTimeFromVideoTime(point.time, clip)
        }));

        // Convert click times to timeline time
        const timelineClickData = normalizedClickData.map(click => ({
            ...click,
            timelineTime: calculateTimelineTimeFromVideoTime(click.time, clip)
        }));

        
        const clipMetadata = clip.metadata;
        
        const timelineClipStart = calculateTimelineTimeFromVideoTime(clipMetadata?.trimStart || 0, clip);
        const timelineClipEnd = calculateTimelineTimeFromVideoTime(
            clipMetadata?.trimEnd || meshScaledMovementData[meshScaledMovementData.length - 1].time,
            clip
        );

        // Find segments where the cursor stays relatively static and has no clicks
        const staticSegments = [];
        let segmentStart = 0;
        let lastX = timelineData[0].x;
        let lastY = timelineData[0].y;
        let lastSegmentEnd = -MIN_TIME_BETWEEN_SEGMENTS;

        for (let i = 1; i < timelineData.length; i++) {
            const point = timelineData[i];
            const deltaX = Math.abs(point.x - lastX);
            const deltaY = Math.abs(point.y - lastY);
            const moved = deltaX > MOVEMENT_THRESHOLD || deltaY > MOVEMENT_THRESHOLD;

            // Check if there are any clicks in this potential segment
            const hasClicksInSegment = timelineClickData.some(click => 
                click.timelineTime >= timelineData[segmentStart].timelineTime && 
                click.timelineTime <= point.timelineTime
            );

            if (moved || hasClicksInSegment) {
                const segmentStartTime = timelineData[segmentStart].timelineTime;
                const segmentEndTime = point.timelineTime;
                const segmentDuration = segmentEndTime - segmentStartTime;
                const timeSinceLastSegment = segmentStartTime - lastSegmentEnd;

                if (
                    segmentDuration >= MIN_SEGMENT_LENGTH &&
                    segmentEndTime > timelineClipStart &&
                    segmentStartTime < timelineClipEnd &&
                    timeSinceLastSegment >= MIN_TIME_BETWEEN_SEGMENTS
                ) {
                    const trimmedStart = Math.max(segmentStartTime, timelineClipStart);
                    const trimmedEnd = Math.min(segmentEndTime, timelineClipEnd);
                    const trimmedDuration = trimmedEnd - trimmedStart;

                    if (trimmedDuration >= MIN_SEGMENT_LENGTH) {
                        staticSegments.push({ start: trimmedStart, end: trimmedEnd });
                        lastSegmentEnd = trimmedEnd;
                    }
                }
                segmentStart = i;
                lastX = point.x;
                lastY = point.y;
            }
        }

        // Check final segment
        const finalStartTime = timelineData[segmentStart].timelineTime;
        const finalEndTime = timelineData[timelineData.length - 1].timelineTime;
        const finalDuration = finalEndTime - finalStartTime;
        const timeSinceLastSegment = finalStartTime - lastSegmentEnd;

        if (
            finalDuration >= MIN_SEGMENT_LENGTH &&
            finalEndTime > timelineClipStart &&
            finalStartTime < timelineClipEnd &&
            timeSinceLastSegment >= MIN_TIME_BETWEEN_SEGMENTS
        ) {
            const trimmedStart = Math.max(finalStartTime, timelineClipStart);
            const trimmedEnd = Math.min(finalEndTime, timelineClipEnd);
            const trimmedDuration = trimmedEnd - trimmedStart;

            if (trimmedDuration >= MIN_SEGMENT_LENGTH) {
                staticSegments.push({ start: trimmedStart, end: trimmedEnd });
            }
        }

        // Initialize springs for opacity, scale, and y-shift
        let opacitySpring = new SpringAnimator(
            OPACITY_SPRING_PARAMS.mass,
            OPACITY_SPRING_PARAMS.stiffness,
            OPACITY_SPRING_PARAMS.damping,
            1
        );

        let scaleSpring = new SpringAnimator(
            SCALE_SPRING_PARAMS.mass,
            SCALE_SPRING_PARAMS.stiffness,
            SCALE_SPRING_PARAMS.damping,
            1
        );

        let yShiftSpring = new SpringAnimator(
            Y_SHIFT_SPRING_PARAMS.mass,
            Y_SHIFT_SPRING_PARAMS.stiffness,
            Y_SHIFT_SPRING_PARAMS.damping,
            0
        );

        return meshScaledMovementData.map((point, index) => {
            const timelinePoint = timelineData[index].timelineTime;
            let shouldFadeOut = false;

            for (const segment of staticSegments) {
                const fadeOutStart = segment.start + DELAY_BEFORE_FADE;
                const fadeInStart = segment.end - FADE_IN_BEFORE_MOVEMENT;
                if (timelinePoint >= fadeOutStart && timelinePoint < fadeInStart) {
                    shouldFadeOut = true;
                    break;
                }
            }

            if (shouldFadeOut) {
                opacitySpring.setTarget(0);
                scaleSpring.setTarget(FADE_OUT_SCALE);
                yShiftSpring.setTarget(-Y_SHIFT_AMOUNT); // Shift up when fading out
            } else {
                opacitySpring.setTarget(1);
                scaleSpring.setTarget(1);
                yShiftSpring.setTarget(0); // Reset shift when fading in
            }

            opacitySpring.simulate(1000 / FPS);
            scaleSpring.simulate(1000 / FPS);
            yShiftSpring.simulate(1000 / FPS);

            return {
                ...point,
                opacity: opacitySpring.position,
                scale: point.scale * scaleSpring.position,
                y: point.y + yShiftSpring.position // Add the y-shift to the existing y position
            };
        });
    };
}

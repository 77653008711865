import React, { useState, useEffect, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import MiniSearch from 'minisearch';
import { getVideoFileDuration } from '../../../utils/getVideoFileDuration';
import EditorInsertLauncherSearchBar from './EditorInsertLauncherSearchBar';
import EditorInsertLauncherResultsList from './EditorInsertLauncherResultsList';

const handleFileUpload = (config) => {
  const {
    acceptTypes,
    processFile,
    closeInsertLauncher
  } = config;

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = acceptTypes;
  input.style.display = 'none';
  
  input.onchange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        await processFile(file);
      } catch (error) {
        console.error('Error processing file:', error);
      }
    }
  };
  
  document.body.appendChild(input);
  input.click();
  document.body.removeChild(input);
  closeInsertLauncher();
};

function EditorInsertLauncher({
  children,
  showInsertLauncher,
  closeInsertLauncher,
  handleClickTextButton,
  handleClickChartButton,
  openNewScreenClipPopover,
  setShowAddImagePopover,
  setShowAddFromLibraryDialog,
  addZoom,
  handleNewWebcamFileUpload
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hasMouseMoved, setHasMouseMoved] = useState(false);
  const searchInputRef = useRef(null);

  const options = [
    {
      id: 'text',
      defaultDisplay: true,
      title: 'Add Text',
      altWords: ['text', 'write', 'type', 'paragraph'],
      iconName: 'textElementMedium',
      action: () => {
        handleClickTextButton();
        closeInsertLauncher();
      }
    },
    {
      id: 'image',
      defaultDisplay: true,
      title: 'Add Image or Video',
      altWords: ['image', 'picture', 'photo', 'video', 'media'],
      iconName: 'mediaClipMedium',
      action: () => {
        closeInsertLauncher();
        setTimeout(() => {
          setShowAddImagePopover(true);
        }, 10);
      }
    },
    {
      id: 'chart',
      defaultDisplay: true,
      title: 'Add Chart',
      altWords: ['chart', 'graph', 'visualization', 'data'],
      iconName: 'chartClipMedium',
      action: () => {
        handleClickChartButton();
        closeInsertLauncher();
      }
    },
    {
      id: 'record',
      defaultDisplay: true,
      title: 'Record iPhone/iPad',
      altWords: ['record', 'screen', 'capture', 'ios', 'mobile'],
      iconName: 'deviceiPhone',
      action: () => {
        openNewScreenClipPopover();
        closeInsertLauncher();
      }
    },
    {
      id: 'library',
      defaultDisplay: true,
      title: 'Browse Team Library',
      altWords: ['library', 'team', 'browse', 'assets'],
      iconName: 'addFromLibraryMedium',
      action: () => {
        setShowAddFromLibraryDialog(true);
        closeInsertLauncher();
      }
    },
    {
      id: 'zoom',
      defaultDisplay: true,
      title: 'Add Manual Zoom',
      altWords: ['zoom', 'meeting', 'video', 'call'],
      iconName: 'magnifyingGlass',
      action: () => {
        addZoom();
        closeInsertLauncher();
      }
    },
    {
      id: 'webcam',
      defaultDisplay: true,
      title: 'Upload Camera Video...',
      altWords: ['webcam', 'camera', 'video', 'record'],
      iconName: 'speakerRectangleMedium',
      type: 'fileUpload',
      fileConfig: {
        acceptTypes: 'video/*,.mov',
        processFile: async (file) => {
          const filePath = file.path;
          const duration = await getVideoFileDuration(filePath);
          handleNewWebcamFileUpload(file, duration);
        }
      }
    }
  ];

  const miniSearch = new MiniSearch({
    fields: ['title', 'altWords'],
    storeFields: ['title', 'description', 'action', 'iconName', 'defaultDisplay', 'type', 'fileConfig'],
    searchOptions: {
      prefix: true,
      fuzzy: 0.2
    }
  });

  miniSearch.addAll(options);

  const getFilteredOptions = () => {
    if (!searchQuery) {
      return options.filter(opt => opt.defaultDisplay);
    }
    return miniSearch.search(searchQuery);
  };

  const filteredOptions = getFilteredOptions();

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < filteredOptions.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev > 0 ? prev - 1 : filteredOptions.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (filteredOptions[selectedIndex]) {
          handleOptionClick(filteredOptions[selectedIndex]);
        }
        break;
      case 'Escape':
        closeInsertLauncher();
        break;
    }
  };

  useEffect(() => {
    if (showInsertLauncher && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showInsertLauncher]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [searchQuery]);

  useEffect(() => {
    if (showInsertLauncher) {
      setSearchQuery('');
      setHasMouseMoved(false);
    }
  }, [showInsertLauncher]);

  const handleOptionClick = (option) => {
    if (option.type === 'fileUpload') {
      handleFileUpload({
        ...option.fileConfig,
        closeInsertLauncher
      });
    } else {
      option.action();
    }
  };

  const handleMouseMove = () => {
    if (!hasMouseMoved) {
      setHasMouseMoved(true);
    }
  };

  return (
    <Dialog.Root
      open={showInsertLauncher}
      onOpenChange={(open) => {
        if (!open) {
          closeInsertLauncher();
          setSearchQuery('');
          setSelectedIndex(0);
          setHasMouseMoved(false);
        }
      }}
    >
      <Dialog.Trigger asChild>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="editorInsertLauncher-overlay" />
        <Dialog.Content 
          data-transcript-panel-visible={true}
          data-mouse-moved={hasMouseMoved ? true : false}
          className="editorInsertLauncher forceDarkTheme"
          onKeyDown={handleKeyDown}
          onMouseMove={handleMouseMove}
        >
          <EditorInsertLauncherSearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchInputRef={searchInputRef}
          />
          <EditorInsertLauncherResultsList
            filteredOptions={filteredOptions}
            selectedIndex={selectedIndex}
            onOptionClick={handleOptionClick}            
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default EditorInsertLauncher;
import React, { useState, useRef, useEffect, Suspense } from 'react';
import ThreeCanvas from '../three/ThreeCanvas';
import Camera from '../three/Camera';
import { Canvas } from '@react-three/fiber';
import { computeCursorDataForLegacyClips } from '../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import { computeCursorDataForClips } from '../utils/recordings/screenRecordings/getCursorPositionAtTime'
import {getBackgroundForId} from '../utils/brands/getBackgroundForId'
import { Muxer, ArrayBufferTarget } from 'mp4-muxer';
import * as THREE from 'three';

// const SCENE_WIDTH = 1920
// const SCENE_HEIGHT = 1080
// const canvasWidth = 1280
// const canvasHeight = 720


const NORMAL_TIMEOUT = 10 
const encodingBitRate = 20e6; 

let fpsCounterInterval = null;
let fps = 0;

let lastBenchmarkTimestamp = 0;

let timeBenchmark = {
	renderScene: 0,
	crateVideoFrame: 0,
	encodeVideoFrame: 0,
	flushEncoder: 0,
	closeVideoFrame: 0,
	findVideoSegment: 0,
	setTime: 0,
	setCurrentFrame: 0,
	timeout: 0,
}

function recordBenchmarkEvent(eventName)  {
	switch (eventName) {
		case 'reset':
			console.log(`Benchmark:${JSON.stringify(timeBenchmark)}`);
			timeBenchmark = {
				renderScene: 0,
				crateVideoFrame: 0,
				encodeVideoFrame: 0,
				flushEncoder: 0,
				closeVideoFrame: 0,
				findVideoSegment: 0,
				setTime: 0,
				setCurrentFrame: 0,
				timeout: 0,
			}
			break;
		case 'renderScene':
			timeBenchmark.renderScene = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'crateVideoFrame':
			timeBenchmark.crateVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'encodeVideoFrame':
			timeBenchmark.encodeVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'flushEncoder':
			timeBenchmark.flushEncoder = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'closeVideoFrame':
			timeBenchmark.closeVideoFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'findVideoSegment':
			timeBenchmark.findVideoSegment = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'setTime':
			timeBenchmark.setTime = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'setCurrentFrame':
			timeBenchmark.setCurrentFrame = performance.now() - lastBenchmarkTimestamp;
			break;
		case 'timeout':
			timeBenchmark.timeout = performance.now() - lastBenchmarkTimestamp;
			break;
		default:
			console.error('Unknown benchmark event:', eventName);
			return;
	}
	lastBenchmarkTimestamp = performance.now();
}

function countFrame() {
	if (!fpsCounterInterval) {
		fpsCounterInterval = setInterval(() => {
			console.log("frame capture FPS:", fps);
			fps = 0;
		}, 1000);
	}
	fps++;
}

function finishCountFrame() {
	clearInterval(fpsCounterInterval);
	fpsCounterInterval = null;
	console.log("FPS:", fps);
	fps = 0;
}

function calculateSafeResolution(desiredWidth, aspectRatio, maxCodedArea) {
	// Parse the aspect ratio string to get proper dimensions
	let ratio;
	if (aspectRatio === '16_10') {
		// True 16:10 aspect ratio is 1.6:1
		ratio =  1920 / 1241;
		console.log('Using true 16:10 aspect ratio:', ratio);
	} else {
		// Standard 16:9 aspect ratio is 1.7778:1
		ratio = 16 / 9;
		console.log('Using standard 16:9 aspect ratio:', ratio);
	}
	
	// Calculate initial dimensions
	let width = desiredWidth;
	let height = Math.floor(width / ratio / 2) * 2; // Ensure even height
	
	// Log initial dimensions and aspect ratio
	console.log(`Initial dimensions: ${width}x${height}, ratio: ${width/height}`);
	
	// Check if dimensions exceed max coded area
	let codedArea = width * height;
	
	if (codedArea > maxCodedArea) {
		// Add a safety margin (0.98) to ensure we're safely under the limit
		const scaleFactor = Math.sqrt((maxCodedArea * 0.98) / codedArea);
		
		// Round down to nearest even number to ensure encoder compatibility
		width = Math.floor(width * scaleFactor / 2) * 2;
		height = Math.floor(width / ratio / 2) * 2; // Recalculate height based on width to maintain aspect ratio
		
		// Double-check that our calculation is correct
		const finalArea = width * height;
		console.log(`Scaled dimensions: ${width}x${height}, ratio: ${width/height}`);
		console.log(`Final coded area: ${finalArea} (limit: ${maxCodedArea})`);
	}
	
	return { width, height };
}

const ServerSideExportCanvas = ({
		setIsExporting,
		setExportComplete,
		projectBackgroundId,
		slideClips,
		textSlideClips,
		webcamClips,
		chartClips,
		videoClips,
		zoomClips,
		currentTime,
		setTime,
		duration,
		foxStartTime,
		foxMaxRenderSeconds,
		imageClips,
		setUploadComplete,
		s3PresignedUploadUrl,
		renderVariables,
		audioClips,
		framesPerSecond,
		exportWidth,
		subtitlesType,
		aspectRatio
	}) => {
	const FPS = framesPerSecond ||60
	const projectBackground=getBackgroundForId(projectBackgroundId)
	const cameraRef = useRef();
	const [readyVideoClips, setReadyVideoClips] = useState([]); //TODO check on this and if we need it
	const canvasRef = useRef()


	let sceneWidth = 1920
	let sceneHeight = 1080
	if(aspectRatio === '16_10'){
		sceneWidth = 1920
		sceneHeight = 1241		
	}


	const canvasWidth = 1280
	const canvasHeight = canvasWidth / (sceneWidth / sceneHeight)

	async function captureFrame(renderer,scene,camera, encoder, frameIndex,totalFrames,startFrame) {
		const canvas = renderer.domElement;
		renderer.render(scene, camera);
		recordBenchmarkEvent('renderScene');
		const videoFrame = new VideoFrame(canvas, {timestamp: frameIndex * (1 / FPS) * 1e6}); //multiple by 1e6 (to get microseconds) not encoding bitrate
		recordBenchmarkEvent('crateVideoFrame');
		encoder.encode(videoFrame, {keyFrame: frameIndex % (2 * FPS) === 0}); // Keyframe every 2 seconds
		recordBenchmarkEvent('encodeVideoFrame');
		recordBenchmarkEvent('flushEncoder');
		countFrame();
		if ((frameIndex - startFrame) % 20 === 0) {
    	console.log(`processing-progress-report;${frameIndex-startFrame};${totalFrames-startFrame}`);
    }
		videoFrame.close();
		recordBenchmarkEvent('closeVideoFrame');
	}

	
	async function finalizeVideo(muxer, encoder, is4kRes) {
		const flushStart = performance.now();
		await encoder.flush(); // wait for queue to empty
		muxer.finalize(); // close muxer
		const blob = new Blob([muxer.target.buffer], { type: 'video/mp4' }); // create a blob from the buffer
		console.log('Video finalized in: ', performance.now() - flushStart, 'ms');
		setIsExporting(false);
		setExportComplete(true);
		if (!s3PresignedUploadUrl) {
			console.log('UPLOAD FAILED', 'no s3PresignedUploadUrl');
			return;
		}
		const MAX_RETRIES = 3;

		async function uploadToS3WithRetry(attempt = 1) {
			try {
				const response = await fetch(s3PresignedUploadUrl, {
					method: 'PUT',
					body: blob,
					headers: {
						'Content-Type': 'video/mp4'
					}
				});

			if (response.ok) {
				const message = await response.text();
				console.log('UPLOAD SUCCESS', message);
				return true;
			} else {
				const message = await response.text();
				console.log(`UPLOAD FAILED (Attempt ${attempt}/${MAX_RETRIES})`, message);
				if (attempt < MAX_RETRIES) {
					console.log(`Retrying upload...`);
					return uploadToS3WithRetry(attempt + 1);
				}
			return false;
		}
	} catch (error) {
		console.error(`UPLOAD ERROR (Attempt ${attempt}/${MAX_RETRIES})`, error);
		if (attempt < MAX_RETRIES) {
			console.log(`Retrying upload...`);
			return uploadToS3WithRetry(attempt + 1);
		}
		return false;
		}
	}

		const uploadSuccess = await uploadToS3WithRetry();
		if (!uploadSuccess) {
			console.log('Upload failed after all retry attempts');
		}
		setUploadComplete(true);
	}

	

	const is4kRes = false
	const is2kRes = true

	const MAX_ENCODE_QUEUE_SIZE = 15;

	const handleExport = async () => {
		setIsExporting(true)
		if (cameraRef.current) {
			const camera = cameraRef.current.getCamera();
			const renderer = cameraRef.current.getGL();
			const scene = cameraRef.current.getScene();
			renderer.setPixelRatio(1) //ultrawide thing
			
			//Before aspect ratio
			// let targetWidth = 2880
			// let targetHeight = 1620 

			// if(exportWidth==1920){
			// 	targetWidth = 1920 
			// 	targetHeight = 1080 
			// }

			// if(exportWidth==3840){
			// 	targetWidth = 3840 
			// 	targetHeight = 2160 
			// }

			let ratio = 1920 / 1080
			if(aspectRatio === '16_10'){
				ratio = 1920 / 1241
			}
			let targetWidth = 2880			
			if(exportWidth==1920){
				targetWidth = 1920 				
			}
			if(exportWidth==3840){
				targetWidth = 3840 				 
			}
			
			// Ensure height is an even number for video encoding (1610 at 2k is 0.5 pixel too short)
			let targetHeight = Math.floor(targetWidth / ratio / 2) * 2
			
			if(exportWidth == 3840) {				
				// Max coded area for AVC Level 5.1 (slightly reduced for safety)
				let maxCodedAreaLevel51 = 9437184;
				if(aspectRatio === '16_10'){
					maxCodedAreaLevel51 = 8000000
				}													
				// Calculate safe dimensions
				const safeResolution = calculateSafeResolution(3840, aspectRatio, maxCodedAreaLevel51);				
				targetWidth = safeResolution.width;
				targetHeight = safeResolution.height;
			}





			// Adjust renderer and camera for the target resolution and aspect ratio
			renderer.setSize(targetWidth, targetHeight);
			camera.aspect = targetWidth / targetHeight;
			camera.updateProjectionMatrix();
			//////////////////////////////////////////
			const renderSeconds = foxMaxRenderSeconds || duration - foxStartTime;
			console.log(`render seconds is ${foxMaxRenderSeconds}`)
			const startFrame = foxStartTime * FPS || 0;
			const renderFrames = (renderSeconds * FPS) + startFrame;
			console.log('renderSeconds', renderSeconds)
			console.log('startFrame', startFrame)
			console.log('renderFrames', renderFrames)
			console.log('foxMaxRenderSeconds', foxMaxRenderSeconds)
			console.log('foxStartTime', foxStartTime)
	
			const muxerOptions = {
				target: new ArrayBufferTarget(),
				video: {
					codec: 'avc', // must be the same codec as used in the encoder
					width: targetWidth, // same width as used in the encoder
					height: targetHeight, // same height as used in the encoder
				},
				fastStart: 'in-memory', // we don't mind the medatada to be stored at the end of the file
				firstTimestampBehavior: 'offset', // reset the timestamp to 0 on first frame
			}

			const  muxer = new Muxer(muxerOptions)
			const encoder = new VideoEncoder({
				output(chunk, meta) {
					muxer.addVideoChunk(chunk, meta);
				},
				error(e) {
				console.error(e);
				}
			});

			encoder.configure({
				codec: 'avc1.640033',
				width: targetWidth,
				height: targetHeight,
				bitrate: encodingBitRate,
				framerate: FPS,
			});



			const videoSegments = [...videoClips, ...webcamClips].map(clip => ({
				startTime: clip.startTime,
				endTime: clip.endTime,
				isBasicVideo: clip.isBasicVideo,
				semiTransparent: clip.metadata?.semiTransparent || false,
				hasVideoTrack:clip.hasVideoTrack,
				clip: clip
			}));

			let textureUpdateResolvers = new Map();
			//let activeTimeouts = new Map();

			const globalTextureUpdateCallback = (clipId) => {
				const resolve = textureUpdateResolvers.get(clipId);
				if (resolve) {
					resolve();
					textureUpdateResolvers.delete(clipId);
				}
			};
			

			[...videoClips, ...webcamClips].forEach(clip => {
				clip.setTextureUpdatedCallback(() => globalTextureUpdateCallback(clip.id));
			})
 
			
			for (let i = startFrame; i <= renderFrames; i++) {
				const frameTime = i / FPS;

				const currentSegments = videoSegments.filter(segment => 
					segment.hasVideoTrack && frameTime >= segment.startTime && frameTime <= segment.endTime
				);

				if (currentSegments.length>0) {
					const texturePromises = currentSegments.map(segment => {
						return new Promise(resolve => {
							// // Clear any existing timeout for this clip
							// if (activeTimeouts.has(segment.clip.id)) {
							// 	clearTimeout(activeTimeouts.get(segment.clip.id));
							// 	activeTimeouts.delete(segment.clip.id);
							// }

							textureUpdateResolvers.set(segment.clip.id, resolve);
							
							// // Add a timeout to prevent hanging
							// const timeoutId = setTimeout(() => {
							// 	if (textureUpdateResolvers.has(segment.clip.id)) {
							// 		textureUpdateResolvers.delete(segment.clip.id);
							// 		activeTimeouts.delete(segment.clip.id);
							// 		resolve();
							// 	}
							// }, 500);
							// activeTimeouts.set(segment.clip.id, timeoutId);
						});
					});
					const timeoutPromise = new Promise(resolve => setTimeout(resolve, NORMAL_TIMEOUT));
					setTime(frameTime);
					await Promise.all([...texturePromises, timeoutPromise]); 
				} else {
					setTime(frameTime);
					await new Promise(resolve => setTimeout(resolve, NORMAL_TIMEOUT));
				}
				captureFrame(renderer, scene, camera, encoder, i, renderFrames,startFrame);
				while (encoder.encodeQueueSize >= MAX_ENCODE_QUEUE_SIZE) {
					await new Promise(resolve => setTimeout(resolve, 1));
				}
			}
			finishCountFrame();
			await finalizeVideo(muxer, encoder,is4kRes);
		}			
		else {
			console.error('Canvas objects are not available for export.');
		}
	}

	useEffect(() => {
		const computeData = async () => {
			const legacyClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording!=true);
			const screenClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording!==true && clip.type!=='webcam' && clip.isScreenRecording==true)
			try {
				const [legacyClipsData, screenClipsData] = await Promise.all([
					computeCursorDataForLegacyClips(legacyClips,false,sceneWidth,sceneHeight),
					computeCursorDataForClips(screenClips,false,sceneWidth,sceneHeight)
				]);
				const allCalculatedClipsIds = [
					...legacyClipsData,
					...screenClipsData
				];
			} catch (error) {
				console.error('Error computing cursor data:', error);
			}
		};
		computeData();
	}, [videoClips]);




	return (
		<>

			<div style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }} className='editor-center-center-canvasOuterOuterContainer-guide'>
				<div style={{ transform: `scale(${canvasWidth / sceneWidth})` }} className='editor-center-center-canvasContainer'>
					<div style={{ width: `${sceneWidth}px`, height: `${sceneHeight}px` }} className='editor-center-center-canvasInnerContainer' >
						<Suspense fallback={<div>Loading...</div>}>
							<Canvas 
								ref={canvasRef}
								style={{ width: `calc(${sceneWidth}px * 1/${canvasWidth / sceneWidth})`, height: `calc(${sceneHeight}px * 1/${canvasWidth / sceneWidth})` }}
								gl={{ 
									preserveDrawingBuffer: true, 
									toneMapping: THREE.NoToneMapping,
								outputColorSpace: THREE.SRGBColorSpace,
									antialias: true,									 
								}}>
								<ThreeCanvas
									currentTime={currentTime}
									slideClips={slideClips}
									textSlideClips={textSlideClips}
									videoClips={videoClips}
									webcamClips={webcamClips}
									imageClips={imageClips}
									zoomClips={zoomClips}
									chartClips={chartClips}
									audioClips={audioClips}
									textEditorIsFocused={false}
									projectBackground={projectBackground}
									readyVideoClips={readyVideoClips}
									hideRenderedTextSlide={false}
									showChartAnimated={true}
									showChartStatic={false}
									showBasicVideoStatic={false}
									variableValues={renderVariables}
									showSlideChartAnimated={true}
									showSlideChartStatic={false}
									subtitlesType={subtitlesType}
									sceneWidth={sceneWidth}
									sceneHeight={sceneHeight}
								/>
								<Camera ref={cameraRef} currentTime={currentTime} zoomClips={zoomClips} readyVideoClips={readyVideoClips} />
							</Canvas>
						</Suspense>
					</div>
				</div>
			</div>
					<button id="exportButton" onClick={handleExport}> EXPORT </button>
		<br/>

		</>
	);
};

export default ServerSideExportCanvas;
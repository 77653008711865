import find from 'lodash/find'
import store from '../../store'


export function getProjectLoadingStatus(projectId) {
  const state = store.getState()
  const project = find(state.projects,{id:projectId})
  let isLoading = false

  if(project){
	const timeline = project.timeline 
	let clips = timeline.clips

	if(clips){ //TODO update this for scenes

	for (const clip of clips) {

		if (clip.type === 'video') {

			if(!clip.isBasicVideo){
				const clipStatus = state.downloads[clip.captureId];
				if (clipStatus && clipStatus.status === 'downloading') {
					return true; // Return true if any video clip is still downloading
				}
			}else{
				const videoClipStatus = state.basicVideoDownloads[clip.videoId];
				if (videoClipStatus && videoClipStatus === 'downloading') {
					return true; // Return true if any video clip is still downloading
				}
			}
			
		}
		if (clip.type === 'image') {
			const imageClipStatus = state.imageDownloads[clip.imageId];
			if (imageClipStatus && imageClipStatus === 'downloading') {
				return true; // Return true if any video clip is still downloading
			}
		}
	}
  }
}

  return false
}
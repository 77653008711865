import {TimelineClip} from './TimelineClip'
import {estimateAudioDuration} from '../utils/estimateAudioDuration'
import { fetchSpeech } from '../actions/textToSpeech'
import store from '../store'
import debounce from 'lodash/debounce'
import {createFileNameForAudioClip} from '../utils/createFileNameForAudioClip'
import {randomID} from '../utils/randomID'
import {calculateCaptionWordGroupPositions} from './utils/calculateCaptionWordGroupPositions'
import {calculateSubtitleGroupWordPositions} from './utils/calculateSubtitleGroupWordPositions'
import {groupCharactersIntoWords} from './utils/groupCharactersIntoWords'
import {generateCaptions} from '../actions/captions'
import {generateSubtitles} from '../actions/captions'
import {applyVoiceoverPlaybackRateToSubtitles} from './utils/applyVoiceoverPlaybackRateToSubtitles'
import {applyVoiceoverPlaybackRateToCaptions} from './utils/applyVoiceoverPlaybackRateToCaptions'

const CALC_VOICE_DEBOUNCE_TIME=2000



/////// AUDIO CLIPS////////////
class AudioClip extends TimelineClip {

	constructor(options,scene,projectId,transcriptPmManager,handleVoiceoverAudioFileUpdated,setPreviewingAudioClipId,activeVoice,getTranscript,providerId,getAspectRatio) {
		super(options,scene)
		this._activeVoice = activeVoice ||1
		this._providerId = providerId

		this.voiceoverPlaybackRate = options.voiceoverPlaybackRate || 1

		this.setPreviewingAudioClipId=setPreviewingAudioClipId
		this.handleVoiceoverAudioFileUpdated=handleVoiceoverAudioFileUpdated

		this.getTranscript = getTranscript

		this.projectId = projectId

		this.parentWebcamClip = options.parentWebcamClip


		this.transcriptAudio=null //for playing from transcript panel
		this.audio = new Audio(); //for playback in timeline
		this.audio.preload = 'auto'; // Preload the audio to improve responsiveness
		//this.debouncedRecalculateAudio = debounce(this.recalculateAudio, CALC_VOICE_DEBOUNCE_TIME);
		this.debouncedRecalculateAudio = debounce(this.recalculateAudio, CALC_VOICE_DEBOUNCE_TIME, {
			leading: false,
			trailing: true
		});
		this.requiresUpdate = true
		this.originalDuration=options.originalDuration || options.duration
		this.duration = options.duration
		this.loadAudioFile()

		this._pinnedStartTime = options.pinnedStartTime

		if(!this.originalDuration){
			this.originalDuration=estimateAudioDuration(this.metadata.text)/1000
			this.duration=this.originalDuration / this.voiceoverPlaybackRate
		}
		this.currentTextChangeId = null
		this.clipIndex = options.clipIndex
		this.indexInParentClip = options.indexInParentClip
		this.transcriptPmManager=transcriptPmManager
		this.isPlayingPreview = false
		this.getAspectRatio=getAspectRatio

		if(this.metadata.alignment && !this.metadata.subtitlesGroups){
			this.calculateSubtitles()
		}

	}

	getSceneDimensions=()=>{
		const aspectRatio = this.getAspectRatio()
		let sceneWidth = 1920
		let sceneHeight = 1080
		if(aspectRatio === '16_10'){
			sceneWidth = 1920
			sceneHeight = 1200
		}
		return {sceneWidth,sceneHeight}
	}


	async calculateWordGroup(){
		const {sceneWidth,sceneHeight} = this.getSceneDimensions()
		const words = this.metadata.text.split(/\s+/);
		const avgWordDuration = this.duration / words.length;
		const wordGroup = {
			startTime: 0,
			id:`group_${this.id}`,
			endTime: this.duration,
			words: words.map((word, index) => ({
				text:word,
				id:index,
				startTime: index * avgWordDuration,
				endTime: (index + 1) * avgWordDuration
			}))
		};
		const updatedWordGroup = await calculateCaptionWordGroupPositions(wordGroup,sceneWidth,sceneHeight);
		this.captionGroups = [updatedWordGroup]
	}



	async loadAudioFile() {
		if (window.ipcRenderer) {
			try {
				const fileName=createFileNameForAudioClip(this.id,this._activeVoice,this.metadata.text)
				const audioData = await ipcRenderer.invoke('read-audio-file', this.projectId, fileName);
				this.audio.src = audioData;
				this.audio.load();
				this.audio.playbackRate = this.voiceoverPlaybackRate
				this.updateRequiresUpdateStatus(false)
				this.audio.onloadedmetadata=()=>{
					if(this.audioDuration !== this.originalDuration){ //if the duration has been saved wrong (the clipping bug) lets fix it
						this.originalDuration = this.audio.duration
						this.duration = this.originalDuration / this.voiceoverPlaybackRate
						if(this.handleVoiceoverAudioFileUpdated){
							this.handleVoiceoverAudioFileUpdated()
						}
						
					}
				}
			} catch (error) {
				this.updateRequiresUpdateStatus(true)
				this.debouncedRecalculateAudio()
			}
		}else return
	}

	reloadAudio=()=>{
		this.loadAudioFile()
	}


	changeActiveVoice = (newVoice,providerId) =>{
		this._activeVoice = newVoice 
		this._providerId = providerId
		this.updateRequiresUpdateStatus(true)
		//this.loadAudioFile()
		//this.audio.playbackRate = this.voiceoverPlaybackRate
		//instead of using the cached voice files lets recalculate so that it will gen the subtitles/captions
		this.updateRequiresUpdateStatus(true)
		this.debouncedRecalculateAudio()
	}


	changeVoiceoverPlaybackRate=(rate)=>{
		this.voiceoverPlaybackRate = rate 
		this.audio.playbackRate = this.voiceoverPlaybackRate
		this.duration = this.originalDuration / this.voiceoverPlaybackRate 

		if(this.metadata.subtitlesGroups){
			const adjustedSubtitlesGroups = applyVoiceoverPlaybackRateToSubtitles(this.metadata.subtitlesGroups,this.voiceoverPlaybackRate)
			this.metadata.subtitlesGroups = adjustedSubtitlesGroups;
		}
		if(this.metadata.captionsResponse){
			const adjustedCaptionGroups = applyVoiceoverPlaybackRateToCaptions(this.metadata.captionGroups,this.voiceoverPlaybackRate)
			this.metadata.captionGroups = adjustedCaptionGroups;
		}
		this.updatePMNode()
	}

	recalculateAudio = () => {
		if(this.isPlayingPreview){
			this.stopPreviewingClip()
		}
		
		this.updateRequiresUpdateStatus(true)
		this.metadata.captionGroups=null
		this.metadata.subtitlesGroups = null

		const text = this.metadata.text;
		this.currentTextChangeId = randomID(); // Generate a unique ID for this change
		const localChangeId = this.currentTextChangeId;
		if(text){
			store.dispatch(fetchSpeech(text, this.id, this._activeVoice,this._providerId,this.projectId)).then((response) => {
				const {audioUrl,alignment,normalized_alignment}=response
				if (localChangeId !== this.currentTextChangeId){// another change already happened so ignore this
					return
				}
				
				this.metadata.alignment = alignment
				const words = groupCharactersIntoWords(alignment)
				const {sceneWidth,sceneHeight} = this.getSceneDimensions()


				store.dispatch(generateCaptions(words)).then((captionsResponse) => {
					if (localChangeId !== this.currentTextChangeId) {// Another change happened, so ignore this response
						return;
					}
					if(captionsResponse){
          // Calculate word positions for each group in the captions response
					const updatedCaptionsGroups = captionsResponse.groups.map(async (group) => {
						return await calculateCaptionWordGroupPositions(group,sceneWidth,sceneHeight);
					});
					Promise.all(updatedCaptionsGroups).then((processedGroups) => {
						const adjustedCaptionsGroups = applyVoiceoverPlaybackRateToCaptions(processedGroups,this.voiceoverPlaybackRate)
            // Save the processed groups on the clip as caption groups
						this.metadata.captionGroups = adjustedCaptionsGroups;
						this.handleVoiceoverAudioFileUpdated()
					});
					}
				})

			
				store.dispatch(generateSubtitles(words)).then((subtitlesResponse) => {
					if (localChangeId !== this.currentTextChangeId) {// Another change happened, so ignore this response
						return;
					}
					if(subtitlesResponse){// Calculate word positions for each group in the subtitles response
						const updatedSubtitlesGroups = subtitlesResponse.groups.map(async (subtitleGroup) => {
						return await calculateSubtitleGroupWordPositions(subtitleGroup,sceneWidth,sceneHeight);
					});
					Promise.all(updatedSubtitlesGroups).then((processedGroups) => {
						const adjustedSubtitlesGroups = applyVoiceoverPlaybackRateToSubtitles(processedGroups,this.voiceoverPlaybackRate)
						this.metadata.subtitlesGroups = adjustedSubtitlesGroups;
						this.handleVoiceoverAudioFileUpdated()
					});
					}
				})

				this.audio.src = audioUrl;
				this.audio.onloadedmetadata = () => {
					this.originalDuration = this.audio.duration
					this.duration = this.originalDuration / this.voiceoverPlaybackRate
					this.updateRequiresUpdateStatus(false)
					this.handleVoiceoverAudioFileUpdated()
				};
				this.audio.load();
				this.audio.playbackRate = this.voiceoverPlaybackRate
			}).catch((error) => {
				this.updateRequiresUpdateStatus(true)
			});
		}else{//handle no text so it doesnt to the weird ummmmm clips
			this.audio.srcObject = null;

			this.audio.load();
    	this.duration = 0.01 //min duration so don't get weird timeline stuff when dragging around with empty clips
    	this.originalDuration=0.01
    	this.updateRequiresUpdateStatus(false)
    }
  };


  //for the transition when we have the captions not subtitles lets generate both
  //(we have changed the captions api so generate them too)
	calculateSubtitles(){
		const words = groupCharactersIntoWords(this.metadata.alignment)
		const {sceneWidth,sceneHeight} = this.getSceneDimensions()

		//console.log("words: " + JSON.stringify(words))
		store.dispatch(generateSubtitles(words)).then((subtitlesResponse) => {
			if(subtitlesResponse){// Calculate word positions for each group in the subtitles response
				const updatedSubtitlesGroups = subtitlesResponse.groups.map(async (subtitleGroup) => {
				return await calculateSubtitleGroupWordPositions(subtitleGroup,sceneWidth,sceneHeight);
			});
	
			Promise.all(updatedSubtitlesGroups).then((processedGroups) => {
				const adjustedSubtitlesGroups = applyVoiceoverPlaybackRateToSubtitles(processedGroups,this.voiceoverPlaybackRate)
				this.metadata.subtitlesGroups = adjustedSubtitlesGroups;
			});
			}
		})

		store.dispatch(generateCaptions(words)).then((captionsResponse) => {
			if(captionsResponse){
			// Calculate word positions for each group in the captions response
				const updatedCaptionsGroups = captionsResponse.groups.map(async (group) => {
					return await calculateCaptionWordGroupPositions(group,sceneWidth,sceneHeight);
				});
				Promise.all(updatedCaptionsGroups).then((processedGroups) => {
					const adjustedCaptionsGroups = applyVoiceoverPlaybackRateToCaptions(processedGroups,this.voiceoverPlaybackRate)
					// Save the processed groups on the clip as caption groups
					this.metadata.captionGroups = adjustedCaptionsGroups;
					this.handleVoiceoverAudioFileUpdated()
				});
			}
		})
	}


  playFromCurrentTime(currentTime) {
  	if (currentTime >= this.startTime && currentTime < this.endTime) {
  		this.audio.currentTime = currentTime - this.startTime;
  		if(this.audio.src){
  			this.audio.play();
  		}
  	}
  }

  pause() {
  	this.audio.pause();
  }

  seek(currentTime) {
  	if (currentTime >= this.startTime && currentTime < this.endTime) {
  		this.audio.currentTime = currentTime - this.startTime;
  	} else {
			this.audio.pause(); // If the current time is outside the clip, pause it
		}
	}

	playFromTranscript(){
		if(!this.requiresUpdate){
		//Creates and audio element, plays it then removes it (dont want to mess with playback of timeline audio file)
			const src= this.audio.src
			if(src && !this.isPlayingPreview){
				this.isPlayingPreview = true
				this.setPreviewingAudioClipId(this.id)
				this.transcriptAudio = new Audio(src);
				this.transcriptAudio.playbackRate=this.voiceoverPlaybackRate
				this.transcriptAudio.play()
				this.transcriptAudio.onended = () => {
					this.stopPreviewingClip()
				};
			}else{
				this.stopPreviewingClip()
			}
		}

	}

	stopPreviewingClip(){
		this.transcriptAudio.pause()
		this.transcriptAudio.srcObject = null;
		this.transcriptAudio.load(); // Force the audio to reload and release the buffer
		this.transcriptAudio = null; // Remove the reference to the Audio object
		this.isPlayingPreview = false
		this.setPreviewingAudioClipId(null)
	}


	handleTextUpdated(newText,estimatedDuration) { 
		this.metadata.text = newText;
		this.originalDuration = estimatedDuration
		this.duration = this.originalDuration / this.voiceoverPlaybackRate
		this.updateRequiresUpdateStatus(true)
		this.debouncedRecalculateAudio()
	}

	updateRequiresUpdateStatus=(requiresUpdate)=>{ //updates PM node and also lets editor know
		this.requiresUpdate = requiresUpdate
		this.updatePMNode()
		// if(this.handleVoiceoverClipUpdated){
		// 	this.handleVoiceoverClipUpdated()
		// }
	}


	updatePMNode(){ //set duration and requiresUpdate on the node
		if(this.transcriptPmManager){
			this.transcriptPmManager.updateRequiresUpdateStatus(this.id,this.requiresUpdate)
		}
	}

	
	get pinnedStartTime() {
		return this._pinnedStartTime === null ? null : this.scene.startTime + this._pinnedStartTime;
	}

	set pinnedStartTime(newStartTime) {
    this._pinnedStartTime = newStartTime === null ? null : newStartTime - this.scene.startTime;
	}

	get relativePinnedStartTime() {
		return this._pinnedStartTime 
	}

   set relativePinnedStartTime(newRelativeStartTime) {
		this._pinnedStartTime = newRelativeStartTime;
	}


	toJSON() {
		return {
			id: this.id,
			type:this.type,
			startTime:Math.max(this._startTime,0), //time relative to the scene
			absoluteStartTime:Math.max(this.startTime,0), //for server side export add this for audio- TODO maybe update ssr to calc based on scenes
			pinnedStartTime:this._pinnedStartTime,
			absolutePinnedStartTime:this.pinnedStartTime, //for api
			voiceoverPlaybackRate:this.voiceoverPlaybackRate,
			originalDuration:this.originalDuration,
			duration:this.duration, //duration *playbackRate
			activeVoice:this._activeVoice,
			metadata:this.metadata,
			parentWebcamClip:this.parentWebcamClip,
			zIndex:this.zIndex,
			clipIndex:this.clipIndex,
			indexInParentClip:this.indexInParentClip
    };
  }

  toExportJSON() {
  	return {
		id: this.id,
		startTime:this.startTime, //absolute time
		voiceoverPlaybackRate:this.voiceoverPlaybackRate,
		originalDuration:this.originalDuration,
		duration:this.duration, //duration *playbackRate
		activeVoice:this._activeVoice,
		metadata:{
		text:this.metadata.text,
      },
    };
  }


  destroy() {
  	if (!this.audio.paused) {
  		this.audio.pause();
  	}
  	this.audio.srcObject = null;
  	this.audio.load();
  	this.audio.onloadedmetadata = null;
  	if (this.transcriptAudio) {
  		this.transcriptAudio.pause();
  		this.transcriptAudio.srcObject = null;
  		this.transcriptAudio.load();
  		this.transcriptAudio.onended = null;
  		this.transcriptAudio = null;
  	}
  	this.debouncedRecalculateAudio.cancel();
  }



}



export { AudioClip };

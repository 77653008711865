const TranscriptChunkNodeSpec ={
    group: "block",
    attrs: {
        clipId:{default:'defaultID'},
        sceneId:{default:'defaultID'},
        chunkRefreshKey:{default:0},
        requiresUpdate:{default:true},
    },
    content: "paragraph",
  	toDOM(node) {return ['transcriptChunk',0]},
    parseDOM: [{tag: 'transcriptChunk'}],
}
    
 export default TranscriptChunkNodeSpec
 

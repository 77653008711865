import { keyToSoundNumber } from '../../../utils/soundEffects/keyboardSoundMappings';
//make a set of effects
//
//type is keypress or mouse
//mouseEventType is down, up
//keyboardEventType is down, up
//time 
//other key data



export const getEventsForSoundEffects = async (captureId,mouseClicksEnabled,keyboardPressesEnabled) => {
  try {  
    const relativePath = `screenRecordings/${captureId}/recording.input-events.json`;
    const content = await ipcRenderer.invoke('read-file', relativePath);
    const data = JSON.parse(content);

    return data
      .filter(item => (
        // Include keyboard events only if enabled
        (keyboardPressesEnabled && item.type === 'keyboard' && !item.isARepeat) ||
        // Include mouse events only if enabled
        (mouseClicksEnabled && item.type === 'mouse' && 
         (item.mouseEventType === 'down' || item.mouseEventType === 'up'))
      ))
      .map(item => ({
        time: item.time.value/item.time.timescale,
        effectType: item.type,
        action: item.type === 'keyboard' ? 
          item.keyboardEventType.toLowerCase() : 
          item.mouseEventType.toLowerCase(),
        ...(item.type === 'keyboard' ? {
          keyValue: item.keyType === 'character' ? item.keySymbol : item.keyType.toUpperCase(),
          soundNumber:keyToSoundNumber[item.keyType === 'character' ? item.keySymbol : item.keyType.toUpperCase()]
        } : {})
      }));
  } catch (error) {
    console.error('Failed to load sound effects data:', error);
    return [];
  }
};



// export const getEventsForSoundEffects = async (captureId,mouseClicksEnabled,keyboardPressesEnabled) => {
//   try {  
//     const relativePath = `screenRecordings/${captureId}/recording.input-events.json`;
//     const content = await ipcRenderer.invoke('read-file', relativePath);
//     const data = JSON.parse(content);

//     return data
//       .filter(item => (
//         // Include keyboard events only if enabled
//         (keyboardPressesEnabled && item.type === 'keyboard' && !item.isARepeat) ||
//         // Include mouse events only if enabled
//         (mouseClicksEnabled && item.type === 'mouse' && 
//          (item.mouseEventType === 'down' || item.mouseEventType === 'up') && 
//          item.button === 'primary')  // Only primary mouse clicks
//       ))
//       .map(item => ({
//         time: item.time.value/item.time.timescale,
//         type: item.type,
//         eventType: item.type === 'keyboard' ? 
//           item.keyboardEventType.toLowerCase() : 
//           item.mouseEventType.toLowerCase(),
//         ...(item.type === 'keyboard' ? {
//           keySymbol: item.keySymbol,
//           keyType: item.keyType,
//           modifiers: item.modifiers
//         } : {
//           button: item.button
//         })
//       }));
//   } catch (error) {
//     console.error('Failed to load sound effects data:', error);
//     return [];
//   }
// };
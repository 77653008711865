import React, { useRef, useEffect, useMemo } from 'react';
import defaultScreenDeviceFrameAnimationValues from '../utils/animations/defaultScreenDeviceFrameAnimationValues';
import { Text } from '@react-three/drei';
import renderOrders from '../renderOrders'

const FPS = 60;

const ScreenVideoClayTitleOverlay = ({ clip,frameWidth, variableValues,frameHeight, initialProperties, animationStates, localTime, visible, showScreenVideoStatic, meshScale, initialOffset }) => {
  const groupRef = useRef();  
  const clayTextMaterialRef = useRef();

  const textInsetLeft = 168 * meshScale + initialOffset;
  const textInsetTop = 22.5 * meshScale + initialOffset;
  const textFontSize = 14 * meshScale
  const textLetterSpacing = -0.0001  * meshScale

  // APPLY ANIMATIONS
  useEffect(() => {
    const frameIndex = Math.floor(localTime * FPS);
    if (groupRef.current) {
      Object.keys(animationStates).forEach(property => {
        let state = animationStates[property][frameIndex];
        if (showScreenVideoStatic) {
          state = defaultScreenDeviceFrameAnimationValues[property];
        }
        if (state !== undefined) {
          if (property === 'positionX') {
            groupRef.current.position.x = state;
          } else if (property === 'positionY') {
            groupRef.current.position.y = state;
          } else if (property === 'positionZ') {
            groupRef.current.position.z = state;
          } else if (property === 'scale' && groupRef.current.scale) {
            groupRef.current.scale.set(state, state, state);
          } else if (property.startsWith('rotation')) {
            const axis = property.slice('rotation'.length).toLowerCase();
            groupRef.current.rotation[axis] = state;
          } else if (property === 'opacity' && clayTextMaterialRef.current) {
              clayTextMaterialRef.current.opacity = state;              
              
          }
        }
      });
    } 
  }, [localTime, animationStates, showScreenVideoStatic]);

  let textColor = "rgb(40,44,53)"  
  let textOpacity = 1  
  
  const devMode = false
  if(devMode){
    textColor = "red"  
    textOpacity = 0.5    
  }

  let tableName = "GTM Pricing Tiers Research"
  // tableName='WWWWWWWWWWWWWWWWWWWWWW'

  if(variableValues && variableValues.name){
    tableName=`${variableValues.name}'s Table`    
  }

  // console.log('OVERLAY')
  const clayTitle = clip.metadata.clayTitle
  // console.log(clayTitle)
  // console.log(variableValues)

  if(clayTitle){
    tableName = clayTitle
  // if(variableValues && variableValues.name){
  //   tableName=`${variableValues.name}'s Table`    
  // }
  }


  return (
    <group
      ref={groupRef}
      position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ + 10]}
      scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
      visible={visible}        
    >
      <Text                
        anchorX="left"
        anchorY="middle"
        fontSize={textFontSize}
        textAlign="left"
        letterSpacing={textLetterSpacing}
        font="../fonts/YInter/YInter-Semibold.woff"
        position={[
          -frameWidth / 2 + textInsetLeft,
          frameHeight / 2 - textInsetTop,   
          0       
        ]}
        renderOrder={renderOrders.screenVideoOverlay}
      >
        <meshBasicMaterial ref={clayTextMaterialRef} transparent={true} color={textColor} opacity={textOpacity} />
        {tableName}
      </Text>
      
    </group>
  );
};

export default ScreenVideoClayTitleOverlay;
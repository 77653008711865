// Helper function to resize the object to cover the scene, similar to CSS "cover"
const resizeDesktopToFit = (objWidth, objHeight, sceneWidth, sceneHeight) => {
  // Calculate aspect ratios
  const sceneAspectRatio = sceneWidth / sceneHeight;
  const objectAspectRatio = objWidth / objHeight;

  let scale;
  // If scene is wider relative to its height than the object
  if (sceneAspectRatio > objectAspectRatio) {
    // Scale based on width to ensure full width coverage
    scale = sceneWidth / objWidth;
  } else {
    // Scale based on height to ensure full height coverage
    scale = sceneHeight / objHeight;
  }

  return {
    meshWidth: objWidth * scale,
    meshHeight: objHeight * scale
  };
};

export default resizeDesktopToFit;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../misc/Icon';

const DPTextArea = ({
  value,
  setValue,    
  disabled,  
  placeholder,
  minLines = 3,
  autoresize,
  maxLength = 500,
}) => {
  const [localInputValue, setLocalInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setLocalInputValue(newInputValue);
    setValue(newInputValue);
  };

  const handleInputFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (!isFocused) {
      setLocalInputValue(value);
    }
  }, [value, isFocused]);

  return (
    <div data-auto-resizing={autoresize} data-disabled={disabled} className='dpTextArea' data-input-focused={isFocused}>      
      <textarea             
        disabled={disabled}
        className='dpTextArea-input'         
        value={localInputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {autoresize && 
        <div className='dpTextArea-ghost'>
          {localInputValue}&nbsp;
        </div>
      }

    </div>
  );
};

export default DPTextArea;
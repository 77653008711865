import store from '../../store'
import {fetchWebcamRecordings} from '../../actions/webcamRecording'

export async function checkWebcamUploadStatusForCaptureId(captureId) {
	console.log('check webcam upload status for capture id',captureId)
	const recordings = await store.dispatch(fetchWebcamRecordings())
	
	// Add a small delay to allow downloads to be initiated
	await new Promise(resolve => setTimeout(resolve, 100))
	
	const state = store.getState()
	const downloadStatus = state.downloads[captureId]
	
	// If download is in progress (status is not 'complete'), return pending
	if (downloadStatus && downloadStatus.status !== 'complete') {
		console.log('is in pending downloads-------------------')
		console.log(downloadStatus	)
		return { status: 'pending' }
	}

	const recording = recordings.find(r => `${r.capture_id}` == captureId)
	if (recording) {
		return recording
	}
	return { status: 'pending' }
}
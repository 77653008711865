export const adjustClipsDraggingLeft=(sortedClips, draggedClipIndex, newStartTime, updatesArray, moveZoomsWithVideoClip, moveAudioWithWebcam, isAudioTrack, audioClipSpacing, shiftAmount,moveLinkedClip)=> {
    // const needsSpacing = (clip1, clip2) => {
    //     if (!isAudioTrack) return false;
    //     if (!clip1 || !clip2) return true;
        
    //     // Only return false if both clips are webcam, not placeholders, and have same captureId
    //     if (clip1.type === 'webcam' && clip2.type === 'webcam' && 
    //         !clip1.metadata.isVariable && !clip2.metadata.isVariable && 
    //         clip1.captureId === clip2.captureId) {
    //         return false;
    //     }
        
    //     return true;  // All other cases need spacing
    // };
    const needsSpacing = (clip1, clip2) => {
        if (!isAudioTrack) return false;
        if (!clip1 || !clip2) return true;
        
        // Only return false if both clips are webcam, not placeholders, and have same captureId
        if (clip1.type === 'webcam' || clip2.type === 'webcam') {
            return false;
        }
        
        return true;  // All other cases need spacing
    };

    let stopAdjusting = false;
    let previousClipEndTime = newStartTime;

    for (let i = draggedClipIndex - 1; i >= 0 && !stopAdjusting; i--) {
        let currentClip = sortedClips[i];
        const initialStartTime = currentClip.relativeStartTime;
        let currentClipEndTime = currentClip.startTime + currentClip.duration;
        
        // Determine if spacing is needed with the next clip
        const nextClip = sortedClips[i + 1];
        const buffer = needsSpacing(currentClip, nextClip) ? audioClipSpacing : 0;
       // console.log('needsSpacing',needsSpacing(currentClip, nextClip))

        
        if (currentClipEndTime + buffer > previousClipEndTime) {
            let newStart = previousClipEndTime - currentClip.duration;
            if (newStart < 0) {
                stopAdjusting = true;
                newStart = 0;
            }
            
            if (needsSpacing(currentClip, nextClip)) {
                newStart -= audioClipSpacing;
            }
            
            currentClip.startTime = newStart;
            currentClip.pinnedStartTime = newStart; //for audio clips
            previousClipEndTime = newStart;

            if(currentClip.metadata.linkedClipId){
                currentClip.pinnedStartTime = currentClip.startTime
            }

            updatesArray.push({ 
                clipId: currentClip.id, 
                relativeStartTime: currentClip.relativeStartTime,
                relativePinnedStartTime: currentClip.relativePinnedStartTime 
            });
            if (currentClip.type == 'video') {
                const finalStartTime = currentClip.relativeStartTime;
                const shiftAmount = finalStartTime - initialStartTime;
                updatesArray.push(...moveZoomsWithVideoClip(currentClip.id, shiftAmount));
            }
            if (currentClip.type == 'webcam') {
                const finalStartTime = currentClip.relativeStartTime;
                const shiftAmount = finalStartTime - initialStartTime;
                updatesArray.push(...moveAudioWithWebcam(currentClip.id, shiftAmount));
            }
            if(currentClip.metadata.linkedClipId){
                const finalStartTime = currentClip.relativeStartTime;
                const shiftAmount = finalStartTime - initialStartTime;
                updatesArray.push(...moveLinkedClip(currentClip.metadata.linkedClipId, shiftAmount));
            }
        }
    }

    //if its an audio clip then we pull clips to the right with it
    if(isAudioTrack){
        let stopAdjusting = false;
        for (let i = draggedClipIndex + 1; i < sortedClips.length && !stopAdjusting; i++) {
            let currentClip = sortedClips[i];
            if(currentClip.pinnedStartTime){
                stopAdjusting = true;
            } else {
                const newStart = currentClip.startTime + shiftAmount;
                currentClip.startTime = newStart;


                updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
                if(currentClip.type == 'webcam') {
                    updatesArray.push(...moveAudioWithWebcam(currentClip.id, shiftAmount));
                }
                if(currentClip.metadata.linkedClipId){
                    const finalStartTime = currentClip.relativeStartTime;
                    updatesArray.push(...moveLinkedClip(currentClip.metadata.linkedClipId, shiftAmount));
                }
            }
        }
    }
    return updatesArray;
}
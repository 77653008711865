import React, { useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { getLegacyCursorPositionAtTimelineTime } from '../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime';
import { getCursorPositionAtTimelineTime } from '../../utils/recordings/screenRecordings/getCursorPositionAtTime';
import renderOrders from '../renderOrders'
import { getCursorScaling } from '../../utils/brands/getCursorScaling'
import { getCursorMode } from '../../utils/brands/getCursorMode'


const ScreenVideoCursor = ({ localTime, cursorScale, clip, time, animationStates, videoWindowPadding, isVideoResizeModeActive }) => {  
  const FPS = 60; // Frames per second, assuming this is defined or passed as a prop

  //const cursorScaleBoost = 1.6; // this is the aesthetic boost on top of default size
  const cursorScaleBoost = getCursorScaling(); // this is the aesthetic boost on top of default size


  
  const fullCursorScale = cursorScale * cursorScaleBoost

  const deviceFrame = clip.metadata.deviceFrame

  let displayMode = 'window'
  // New recordings
  if(clip.metadata.displayMode){
    displayMode = clip.metadata.displayMode
  }

  const POINTER_CURSOR_SIZE = 48; // default size

  // Load all the cursor textures we need
  const pointerTexture = useLoader(THREE.TextureLoader, '../cursors/pointer.png');
  const grabTexture = useLoader(THREE.TextureLoader, '../cursors/grab.png');
  const grabbingTexture = useLoader(THREE.TextureLoader, '../cursors/grabbing.png');
  const arrowTexture = useLoader(THREE.TextureLoader, '../cursors/default.png');
  const textTexture = useLoader(THREE.TextureLoader, '../cursors/text.png');  
  
  const pointerCursorRef = useRef();
  const pointerCursorGeometry = new THREE.PlaneGeometry(POINTER_CURSOR_SIZE * fullCursorScale, POINTER_CURSOR_SIZE * fullCursorScale);

  //let cursorMode = 'standard'
  let cursorMode = getCursorMode()


  const getCursorTexture = (cursorType) => {
    // Standard mode (current behavior)
    if (cursorMode.toLowerCase() === 'standard') {
      if (cursorType === 'openHand') return grabTexture;
      if (cursorType === 'closedHand') return grabbingTexture;
      return pointerTexture; // Default for standard mode
    }
    // Original mode (more specific mapping)
    else if (cursorMode.toLowerCase() === 'original') {
      switch (cursorType) {
        case 'openHand': return grabTexture;
        case 'closedHand': return grabbingTexture;
        case 'pointingHand': return pointerTexture;
        case 'iBeam': return textTexture;
        default: return arrowTexture; // Default for original mode
      }
    }
    // Fallback
    return pointerTexture;
  };

  // Use effect to update cursor position and scale based on timeline time
  useEffect(() => {     
    let position;
    if(clip.isScreenRecording){
      position = getCursorPositionAtTimelineTime(clip, time);
    } else {
      position = getLegacyCursorPositionAtTimelineTime(clip, time);
    }

    if (position) {
      pointerCursorRef.current.position.set(position.x, position.y, 0);
      // Apply pre-calculated scale if available, otherwise use default scale of 1
      const scale = position.scale || 1;
      // Store the base scale as a custom property on the mesh
      pointerCursorRef.current.userData.baseScale = scale;
      pointerCursorRef.current.scale.set(scale, scale, scale);
      const opacity = position.opacity !== undefined ? position.opacity : 1;
      pointerCursorRef.current.material.opacity = opacity;

      // Update cursor texture based on cursorType
      const texture = getCursorTexture(position.cursorType);
      pointerCursorRef.current.material.map = texture;
      pointerCursorRef.current.material.needsUpdate = true;
    }
  }, [time, clip, displayMode, deviceFrame, cursorScale, videoWindowPadding]);


  // Use effect to apply animation states
  useEffect(() => {     
    const frameIndex = Math.floor(localTime * FPS);

    // Apply position animation
    if (animationStates && animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined &&
        animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
      // pointerCursorRef.current.position.x += animationStates['positionX'][frameIndex];
      // pointerCursorRef.current.position.y += animationStates['positionY'][frameIndex];
    }

    // Apply scale animation - multiply with base scale
    if (animationStates && animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
      const animationScale = animationStates['scale'][frameIndex];
      const baseScale = pointerCursorRef.current.userData.baseScale || 1;
      const finalScale = baseScale * animationScale;
      pointerCursorRef.current.scale.set(finalScale, finalScale, finalScale);
    }

    // Apply rotation animation (assuming rotation around Z-axis)
    if (animationStates && animationStates['rotationZ'] && animationStates['rotationZ'][frameIndex] !== undefined) {
      pointerCursorRef.current.rotation.z = animationStates['rotationZ'][frameIndex];
    }

    // Apply opacity animation only if not overridden by cursor data
    if (animationStates && animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
        const position = clip.isScreenRecording 
            ? getCursorPositionAtTimelineTime(clip, time)
            : getLegacyCursorPositionAtTimelineTime(clip, time);
        
        const positionOpacity = position?.opacity ?? 1; // Default to 1 if no position opacity
        const animationOpacity = animationStates['opacity'][frameIndex];
        
        pointerCursorRef.current.material.opacity = positionOpacity * animationOpacity;
        if(isVideoResizeModeActive){ //make it so you can see it while trimming
          pointerCursorRef.current.material.opacity = 1
        }
    }

  }, [localTime, animationStates, cursorScale]);



  return (
    <>                         
      <mesh 
        ref={pointerCursorRef}
        geometry={pointerCursorGeometry}            
        position={[0, 0, 0]}
        renderOrder={renderOrders.screenVideoCursor}
      >
        <meshBasicMaterial map={pointerTexture} transparent={true} opacity={1} />
      </mesh>      
    </>
  );
};

export default ScreenVideoCursor;
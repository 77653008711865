// A "window mode" variant of getNormalizedCursorData:
export const getNormalizedCursorDataForWindow = async(
  cursorData,
  masterWidth,
  masterHeight,
  windowX,
  windowY,
  windowWidth,
  windowHeight
) => {
  return cursorData.map(pt => {
    const xPixel = pt.x * masterWidth;
    const yPixel = pt.y * masterHeight;

    // The window's center is (windowX + windowWidth/2, windowY + windowHeight/2).
    // We want that to be (0,0), so subtract it.
    const finalX = xPixel - (windowX + windowWidth / 2);
    // If you want y positive "up," multiply the difference by -1
    const finalY = -1 * (yPixel - (windowY + windowHeight / 2));

    return {
      time: pt.time,
      cursorType: pt.cursorType,
      mouseEventType: pt.mouseEventType,
      x: finalX,
      y: finalY,
    };
  });
}

import React, {useState,memo} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Icon from '../../misc/Icon';
import { useHistory } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import SelectColorDropdown from '../../uiKit/SelectColorDropdown'
import find from 'lodash/find'
import {openExportWindow} from '../../../utils/export/openExportWindow'
import {getOrgBrand} from '../../../utils/brands/getOrgBrand'
import {getOrgDomain} from '../../../utils/getOrgDomain'
import EditorVariantPopover from './EditorVariantPopover'
import EditorStatusBarExportBtn from './EditorStatusBarExportBtn'
import DPColorPicker from '../detailPanel/DPColorPicker';
import {updateProjectShouldUseClayBrand} from '../../../actions/juno'
import * as Tooltip from '@radix-ui/react-tooltip';  
import EditorGeneratePopover from './generatePopover/EditorGeneratePopover'
import EditorGenerationsDialog from './generatePopover/EditorGenerationsDialog'

 const motionOptions = [
    { value: 'smooth', label: 'Smooth' },
    { value: 'bouncy', label: 'Bouncy' },
    { value: 'molasses', label: 'Molasses' },
    { value: 'slow', label: 'Slow' },
    { value: 'zippy', label: 'Zippy' },     
  ];

const EditorStatusBar = (props) => {
  const dispatch = useDispatch();
  const {
    handleExportProject, 
    project,
    projectId,
    handleUpdateProjectActiveBrand, 
    projectBackgroundId, 
    handleUpdateProjectBackground,
    defaultMotionStyle,
    handleUpdateDefaultMotionStyle,
    isEmpty,
    subscriptionStatus,
    collabGenerations,
    refreshProjectVariables,
    projectVariables,
    generationsDialogOpen,
    setGenerationsDialogOpen,
    readOnlyMode
  }=props


  const history = useHistory();
  const orgBrand=getOrgBrand()

  let backgrounds=orgBrand.backgrounds
  const projectBackground = find(backgrounds,{id:projectBackgroundId})
  const backgroundColorPreview = projectBackground
    ? { background: projectBackground.type === 'solid' ? projectBackground.rgba : '' }
    : {};

  let bgStyles=[]  
  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })

 

  const [colorDropdownOpen, setColorDropdownOpen] = useState(false)
  const backgroundColorIsImage = projectBackground && projectBackground.type === 'image'
  let backgroundColorImage
  if(backgroundColorIsImage){
    backgroundColorImage = projectBackground.src
  }

  const toggleDropdown = (isOpen) => {
    setIsAnyDropdownOpen(isOpen);
  };
  
  const [variantPopoverOpen, setVariantPopoverOpen] = useState(false)

  const [motionDropdownOpen, setMotionDropdownOpen] = useState(false);
  const isAnyDropdownOpen = colorDropdownOpen || motionDropdownOpen || variantPopoverOpen

    
  let subscription_status = subscriptionStatus

  if(getOrgDomain()==' keplerventures.com' || getOrgDomain()=='cursorcapital.vc' || getOrgDomain()=='yarn.so'  || getOrgDomain()=='jasperdstory'){
    subscription_status='active'
  }

   const handleSettingsClick = () => {
        history.push('#settings/subscription'); // Update the URL hash
    };

  const hasVariants = projectVariables && projectVariables.length > 0
  let showVariantsPopover = false 
  if(getOrgDomain()=='clay.com' || getOrgDomain()=='yarn.so'){
    showVariantsPopover = true
  }

  const projectExports = useSelector(state => state.projectExports || {});


  const disabledExport = isEmpty || Object.keys(projectExports).length > 0


  let showSetBrandButton = false 
  let isClayBrand = false
  if(getOrgDomain()=='yarn.so'){
    showSetBrandButton = true
    showSetBrandButton = false
    if(project && project.use_clay_brand){
      isClayBrand = true
    }
  }

   const handleBrandButtonClick = () => {
    const newValue = !isClayBrand;
    dispatch(updateProjectShouldUseClayBrand(projectId, newValue))
  };

  // const generateWorkflow = null
  const generateWorkflow = 'guest'
  // const generateWorkflow = 'api'

  const [guestColabPopoverOpen, setGuestColabPopoverOpen] = useState(false)
  const showGenerationsDialogBtn = collabGenerations && collabGenerations.length > 0 // true if there is at least one generation

  return (
    <div data-generate-workflow-state={generateWorkflow ? generateWorkflow : false} className='editor-statusBar'>


      {showSetBrandButton &&
        <button 
          onClick={handleBrandButtonClick} 
          className='editor-statusBar-exportBtn editor-statusBar-exportBtn--upgrade'
        >
          CB: {isClayBrand ? 'On' : 'Off'}
        </button>
      }

      {!readOnlyMode && 
        <DPColorPicker                              
          activeColorId={projectBackgroundId}
          onChange={(id)=>{handleUpdateProjectBackground(id)}}
          options={bgStyles}    
          statusBar      
        />       
      }

      
    {/*   
      {subscription_status &&       
        <button disabled={isEmpty} onClick={() => openExportWindow(projectId)} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--default'>
          <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--default'>
            Export
          </div>          
        </button>
      }
      */}
      
          
          
      {subscription_status &&       
        <button disabled={disabledExport} onClick={handleExportProject} className='editor-statusBar-btn editor-statusBar-btn--export'>
          {/*<div className='editor-statusBar-btn-iconContainer'>
            <Icon name='upArrowOutline' />
          </div>
          */}
          <div className='editor-statusBar-btn-label'>
            Export
          </div>          
        </button>
      }        
            
      {showVariantsPopover &&
      <EditorGeneratePopover
        projectVariables={projectVariables}
        refreshProjectVariables={refreshProjectVariables}
        project={project}
        updateCollabPageSettings={props.updateCollabPageSettings}
        variableWebcams={props.variableWebcams}
        updateClipMetadata={props.updateClipMetadata}
        collabGenerations={collabGenerations}
      >
        <button onClick={() => setGuestColabPopoverOpen(!guestColabPopoverOpen)} className='editor-statusBar-btn editor-statusBar-btn--generate'>
          <div className='editor-statusBar-btn-iconContainer'>
            <Icon name='generate' />
          </div>
          <div className='editor-statusBar-btn-label'>
            Generate
          </div>          
        </button>
      </EditorGeneratePopover>    
      }  

      {showGenerationsDialogBtn &&
        <EditorGenerationsDialog
          collabGenerations={collabGenerations}
          showDialog={generationsDialogOpen}
          handleCloseDialog={() => setGenerationsDialogOpen(false)}
          updateCollabPageSettings={props.updateCollabPageSettings}
          project={project}
        >
          <Tooltip.Root delayDuration={500}>
          <Tooltip.Trigger asChild> 
                      <button onClick={() => setGenerationsDialogOpen(!generationsDialogOpen)} className='editor-statusBar-btn editor-statusBar-btn--generations'>
            <div className='editor-statusBar-btn-iconContainer'>
              <Icon name='generations' />
            </div>
            <div className='editor-statusBar-btn-label'>
              {collabGenerations.length}
            </div>          
          </button>
          </Tooltip.Trigger>                  
            <Tooltip.Content side="bottom" collisionPadding={4} className="tooltip">
              Browse Generations
            </Tooltip.Content>          
        </Tooltip.Root> 




        </EditorGenerationsDialog>    
      }  


       

      



      {/*}
      {showVariantsPopover && 
        <EditorVariantPopover 
          projectVariables={projectVariables}
          refreshProjectVariables={refreshProjectVariables}
          projectId={props.projectId}
          projectName={props.projectName}
        >
          <button disabled={!hasVariants} data-active-state={hasVariants ? 'active' : 'inactive'} onClick={() => setVariantPopoverOpen(!variantPopoverOpen)} className='editor-statusBar-exportBtn editor-statusBar-exportBtn--variant'>
            <div className='editor-statusBar-exportBtn-iconContainer'>
              <Icon name='variants' />
            </div>
            {hasVariants &&
              <div className='editor-statusBar-exportBtn-label editor-statusBar-exportBtn-label--default'>
                Variants
              </div>
            }
          </button>  
        </EditorVariantPopover>
      }
      */}
      
      

    </div>
  );
}

// export default EditorStatusBar;

const MemoizedEditorStatusBar = memo(EditorStatusBar);

export default MemoizedEditorStatusBar;

import React, { useRef } from 'react';
import Icon from '../../../../misc/Icon';
import * as ContextMenu from '@radix-ui/react-context-menu';

const EditorTimelineSceneHeader = ({ scene, width, left, handleSeek, pixelsPerSec, scrollRef, splitScene, mergeScene, isLastScene }) => {
  const sceneHeaderRef = useRef(null);

  const handleContextMenuSeek = (event) => {
    if (sceneHeaderRef.current) {
      //const scrollAmount = scrollRef.current.scrollLeft;
      const clickX = event.clientX - sceneHeaderRef.current.getBoundingClientRect().left;
      const seekTime = clickX / pixelsPerSec + scene.startTime;
      handleSeek(seekTime);
    }
  };

  const handleClickSeek = (event) => {
    if (sceneHeaderRef.current) {
      const clickX = event.clientX - sceneHeaderRef.current.getBoundingClientRect().left ;
      const seekTime = clickX / pixelsPerSec + scene.startTime;
      handleSeek(seekTime);
    }
  };

  const emptyScene = scene.clips.length == 0
  
  // Calculate the position for the delete button
  let deleteButtonLeft = left + width + 2
  
  // For the final scene, if it's empty, position the delete button at 5 seconds after the start
  if (isLastScene && emptyScene) {
    const defaultDuration = 5 // 5 seconds default duration for empty final scene
    deleteButtonLeft = left + (defaultDuration * pixelsPerSec) - 8
  }

  let mergeDirection
  if(isLastScene){
    mergeDirection = 'before'
  }

  const isUntitledScene = scene.title === 'Untitled Scene'

  return (
    <>
      <ContextMenu.Root key={scene.id} modal={true}>
        <ContextMenu.Trigger asChild onContextMenu={handleContextMenuSeek}>
          <div 
            ref={sceneHeaderRef}
            style={{ width: `${width + 2}px`, left: `${left}px` }} 
            className='editor-timeline-sceneHeader'
            onClick={handleClickSeek} // Add click-to-seek functionality
          >
            <div data-untitled-scene={isUntitledScene} className='editor-timeline-sceneHeader-label'>
              <div className='editor-timeline-sceneHeader-label-label'>
                {scene.title}
              </div>
              <div className='editor-timeline-sceneHeader-label-bg' />
            </div>        

          </div>

        </ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content className='contextMenu contextMenu--timeRuler forceDarkTheme'>          
            <ContextMenu.Item onSelect={splitScene}>
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='splitClip' />
              </div>
              Split Scene
            </ContextMenu.Item>
            {/*}
            <ContextMenu.Item onSelect={() => console.log('Option 1')}>
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='aaText' />
              </div>
              Edit...
            </ContextMenu.Item>
            
            <ContextMenu.Item onSelect={() => console.log('Option 2')}>
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='folder' />
              </div>
              Save to Library
            </ContextMenu.Item>
            <ContextMenu.Separator />
            */}
            {emptyScene && 
              <ContextMenu.Item onSelect={() => mergeScene(scene.id,mergeDirection)}>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='deleted' />
                </div>
                Delete Scene
              </ContextMenu.Item>
            }            
          </ContextMenu.Content>
        </ContextMenu.Portal>
      </ContextMenu.Root>



    {emptyScene && 
      <button 
        onClick={() => mergeScene(scene.id, mergeDirection)}
        style={{ left: `${deleteButtonLeft}px`}} 
        className='editor-timeline-deleteSceneButton'>
          <div className='editor-timeline-deleteSceneButton-inner'>
            Delete
          </div>        
      </button>
    }

    </>
  );
};

export default EditorTimelineSceneHeader;

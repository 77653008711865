import React, { useState, useEffect,memo,useRef } from 'react';
import Icon from '../../misc/Icon';
import ReactSlider from 'react-slider';
import * as Tooltip from '@radix-ui/react-tooltip';
import SelectSoundsDropdown from '../../uiKit/SelectSoundsDropdown'
import {getBackgroundMusicTrackForId} from '../../../utils/backgroundMusic/getBackgroundMusicTrackForId'
import EditorTimelineFooterMusicVolumeControl from './EditorTimelineFooterMusicVolumeControl'
import { useSpring, animated } from 'react-spring';
import UpdatesPopover from '../../changelog/UpdatesPopover'
import EditorShortcutsPopover from '../popovers/EditorShortcutsPopover'

const EditorTimelineFooter = memo((props) => {
  
  const transformValue = (value) => Math.pow(value, 1 / 2.7); // Root 2.7 transformation
  const inverseTransformValue = (value) => Math.pow(value, 2.7); // Inverse transformation (cube)

  const [sliderValue, setSliderValue] = useState(transformValue(props.initialSliderValue || 0.08));
  const [isLeftKeyDown, setIsLeftKeyDown] = useState(false);
  const [isRightKeyDown, setIsRightKeyDown] = useState(false);
  const [hasKeyboardTrimSeeked, setHasKeyboardTrimSeeked] = useState(false);
  const seekIntervalRef = useRef(null);
  const seekStartTimeRef = useRef(null);
  const isMaxSeekSpeedRef = useRef(false);
  const initialSeekAmount = 1/60; // Initial seek amount in seconds
  const maxSeekAmount = 6/60; // Maximum seek amount in seconds when holding
  const accelerationTime = 600; // Time in ms to reach max seek speed
  const largeJumpAmount = 1.0; // 1 second jump for Command+Shift+Arrow

  const handleSliderChange = (value) => {
    setSliderValue(value);
    props.handleTimelineZoomChange(inverseTransformValue(value)); // Apply inverse transform
  };

  // Start continuous seeking
  const startContinuousSeeking = (direction) => {
    // If already seeking, don't restart
    if (seekIntervalRef.current) {
      return;
    }
    
    console.log('Starting continuous seeking in direction:', direction);
    
    // Set start time for acceleration
    seekStartTimeRef.current = Date.now();
    isMaxSeekSpeedRef.current = false;
    
    // Set hasKeyboardTrimSeeked to true when seeking starts
    setHasKeyboardTrimSeeked(true);
    
    // Initial seek
    props.seekFromShortcut(direction * initialSeekAmount);
    
    // Set up interval for continuous seeking
    seekIntervalRef.current = setInterval(() => {
      // Check if we should switch to max speed
      if (!isMaxSeekSpeedRef.current && (Date.now() - seekStartTimeRef.current) >= accelerationTime) {
        isMaxSeekSpeedRef.current = true;
        console.log('Switching to max seek speed');
      }
      
      // Calculate seek amount based on how long the key has been held
      const elapsedTime = Date.now() - seekStartTimeRef.current;
      let seekAmount;
      
      if (elapsedTime < accelerationTime) {
        // Linear interpolation between initial and max seek amount
        seekAmount = initialSeekAmount + (maxSeekAmount - initialSeekAmount) * (elapsedTime / accelerationTime);
      } else {
        seekAmount = maxSeekAmount;
      }
      
      console.log('Seeking with amount:', seekAmount);
      props.seekFromShortcut(direction * seekAmount);
    }, 50); // Update every 50ms for smoother seeking
  };

  // Stop continuous seeking
  const stopContinuousSeeking = () => {
    if (seekIntervalRef.current) {
      console.log('Stopping continuous seeking');
      clearInterval(seekIntervalRef.current);
      seekIntervalRef.current = null;
      seekStartTimeRef.current = null;
      isMaxSeekSpeedRef.current = false;
    }
  };

  // Reset hasKeyboardTrimSeeked when skip segment mode is deactivated
  useEffect(() => {
    if (!props.isAddSkipSegmentModeActive) {
      // Add a 200ms delay before resetting to allow for animation
      const resetTimer = setTimeout(() => {
        setHasKeyboardTrimSeeked(false);
      }, 200);
      
      // Clean up the timer if the component unmounts or the effect runs again
      return () => clearTimeout(resetTimer);
    }
  }, [props.isAddSkipSegmentModeActive]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Handle zoom keyboard shortcuts
      if ((event.metaKey || event.ctrlKey) && (event.key === '=' || event.key === '-')) {
        if (event.key === '=') {
          incrementSlider();
        } else {
          decrementSlider();
        }
        event.preventDefault();
      }

      // Handle add skip segment mode keyboard shortcuts
      if (props.isAddSkipSegmentModeActive) {
        // Handle Command+Shift+Arrow for large jumps
        if (event.shiftKey && (event.metaKey || event.ctrlKey)) {
          if (event.key === 'ArrowLeft') {
            event.preventDefault();
            console.log('Command+Shift+Left arrow pressed, jumping backward');
            props.seekFromShortcut(-1 * largeJumpAmount);
            setHasKeyboardTrimSeeked(true);
            return;
          }
          
          if (event.key === 'ArrowRight') {
            event.preventDefault();
            console.log('Command+Shift+Right arrow pressed, jumping forward');
            props.seekFromShortcut(largeJumpAmount);
            setHasKeyboardTrimSeeked(true);
            return;
          }
        }
        // Only process if Shift is pressed (but not Command/Ctrl)
        else if (event.shiftKey) {
          if (event.key === 'ArrowLeft') {
            event.preventDefault();
            
            // Ignore all repeat events completely
            if (event.repeat) {
              return;
            }
            
            console.log('Left arrow + shift pressed, starting backward seeking');
            setIsLeftKeyDown(true);
            setIsRightKeyDown(false); // Ensure right is not active
            stopContinuousSeeking(); // Stop any existing seeking
            startContinuousSeeking(-1); // Start seeking backward
            
            return;
          }
          
          if (event.key === 'ArrowRight') {
            event.preventDefault();
            
            // Ignore all repeat events completely
            if (event.repeat) {
              return;
            }
            
            console.log('Right arrow + shift pressed, starting forward seeking');
            setIsRightKeyDown(true);
            setIsLeftKeyDown(false); // Ensure left is not active
            stopContinuousSeeking(); // Stop any existing seeking
            startContinuousSeeking(1); // Start seeking forward
            
            return;
          }
        }
        
        if (event.key === 'Enter') {
          event.preventDefault();
          props.handleAddSkipSegmentFromSkipSegmentMode();
          return;
        }
        
        if (event.key === 'Escape') {
          event.preventDefault();
          props.toggleAddSkipSegmentMode();
          return;
        }
      }
    };

    const handleKeyUp = (event) => {
      if (props.isAddSkipSegmentModeActive) {
        if (event.key === 'ArrowLeft') {
          console.log('Left arrow released');
          setIsLeftKeyDown(false);
          stopContinuousSeeking();
          return;
        }
        
        if (event.key === 'ArrowRight') {
          console.log('Right arrow released');
          setIsRightKeyDown(false);
          stopContinuousSeeking();
          return;
        }
        
        // Also stop seeking if shift key is released
        if (event.key === 'Shift') {
          console.log('Shift released');
          if (isLeftKeyDown || isRightKeyDown) {
            setIsLeftKeyDown(false);
            setIsRightKeyDown(false);
            stopContinuousSeeking();
          }
          return;
        }
      }
    };

    // Use capture phase to ensure our handlers run first
    window.addEventListener('keydown', handleKeyDown, true);
    window.addEventListener('keyup', handleKeyUp, true);

    // Clean up on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
      window.removeEventListener('keyup', handleKeyUp, true);
      stopContinuousSeeking();
    };
  }, [props.isAddSkipSegmentModeActive]); // Remove isLeftKeyDown and isRightKeyDown from dependencies

  const incrementSlider = () => {
    setSliderValue((prevValue) => {
      const newValue = Math.min(prevValue + 0.05, 1);
      props.handleTimelineZoomChange(inverseTransformValue(newValue));
      return newValue;
    });
  };

  const decrementSlider = () => {
    setSliderValue((prevValue) => {
      const newValue = Math.max(prevValue - 0.05, 0);
      props.handleTimelineZoomChange(inverseTransformValue(newValue));
      return newValue;
    });
  };

  const [soundDropdownOpen, setSoundDropdownOpen] = useState(false);

  const hideFooterElements = props.isAddSkipSegmentModeActive && props.skipSegmentModeFromShortcut


  const hideElementAnimationProps = useSpring({
    opacity: hideFooterElements  ? 0 : 1,
    transform: hideFooterElements ? 'translateY(15px)' : 'translateY(0px)',
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 0 : 75
  });

  const showAltTooltipAnimationProps = useSpring({
    opacity: hideFooterElements  ? 1 : 0,
    transform: hideFooterElements ? 'scale(1)' : 'scale(0.9)',
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 75 : 0
  });


  const hideBottomRightElementAnimationProps = useSpring({
    opacity: hideFooterElements  ? 0 : 1,    
    config: { tension: 400, friction: 24 },
    delay: hideFooterElements ? 0 : 75
  });



  const hasUnreadUpdates = false

  const [showUpdatesPopover, setShowUpdatesPopover] = useState(false);


  const openUpdatesPopover = () => {
    if (!showUpdatesPopover) {
      setShowUpdatesPopover(true);
    }
    if (showUpdatesPopover) {    
      setShowUpdatesPopover(false);
    }
  };  

  

  const openShortcutsPopover = () => {
    if (!props.showShortcutsPopover) {
      props.openShortcutsPopover();
    }
    if (props.showShortcutsPopover) {    
      props.closeShortcutsPopover();
    }
  };  



  return (
    <div data-read-only={props.readOnlyMode ? true : false} data-promote-zindex-state={props.showShortcutsPopover ? true : false} data-pro-mode={props.userIsProMode ? true : false} className={'editor-timelineFooter ' + (props.isEmpty ?  ' editor-timelineFooter--isEmpty ' : '' )}>        

        <div className='editor-timelineFooter-leftContainer'>                

          {/*
          <UpdatesPopover
            showModal={showUpdatesPopover}
            closeModal={() => setShowUpdatesPopover(false)}            
          >          
            <button data-state={showUpdatesPopover ? 'active' : 'inactive'} onClick={openUpdatesPopover}  className='editor-timelineFooter-footerBtn editor-timelineFooter-footerBtn--updates'>
              {hasUnreadUpdates &&
                <div className='editor-timelineFooter-footerBtn--updates-unreadIndicator' /> 
              }              
              <div className='editor-timelineFooter-footerBtn-label'>
                Updates
              </div>
            </button>    

          </UpdatesPopover>
          */}
          

          {/*}
          <button className='editor-timelineFooter-supportBtn'> 
            Tutorials
          </button>
          <button className='editor-timelineFooter-supportBtn'>
            Support
          </button>
          */}

          {props.userIsProMode && 
            <div className='editor-timelineFooter-proShortcut'>
              Seek back ⇧⌘J &nbsp;&nbsp;&nbsp; Seek forward ⇧⌘K
            </div>          
          }
        </div>

        <div className='editor-timelineFooter-centerContainer'>

        
          <animated.div style={hideElementAnimationProps} className='editor-timelineFooter-addMusicBtnContainer'>
            <SelectSoundsDropdown
              updateBackgroundMusicTrack={props.updateBackgroundMusicTrack}
              activeBackgroundMusicTrack = {props.activeBackgroundMusicTrack}
              handleClosePopover={() => setSoundDropdownOpen(false)}
              handleNewMusicFileUpload={props.handleNewMusicFileUpload}
              soundEffectsSettings={props.soundEffectsSettings}
              updateSoundEffectsSettings={props.updateSoundEffectsSettings}
            >
              <button onClick={() => setSoundDropdownOpen(!soundDropdownOpen)} className={'uiButton uiButton--addMusic uiButton--addMusic--empty'}>                                          
                <div className='uiButton-iconContainer'>
                  <Icon name='musicNotes' />
                </div>
                <div className='uiButton-label'>
                  {props.activeBackgroundMusicTrack ?getBackgroundMusicTrackForId(props.activeBackgroundMusicTrack).title:"Add Sounds"}
                </div>              
              </button>
            </SelectSoundsDropdown>

            {props.activeBackgroundMusicTrack && 
              <EditorTimelineFooterMusicVolumeControl 
                backgroundMusicVolume={props.backgroundMusicVolume}
                updateBackgroundMusicVolume={props.updateBackgroundMusicVolume}
              />
            }
          </animated.div>


          <animated.div style={showAltTooltipAnimationProps} className='editor-timelineFooter-skipSegmentTooltipContainer'>
            <div className='editor-timelineFooter-skipSegmentTooltip'>
              
              <div data-visibility-state={!hasKeyboardTrimSeeked ? 'visible' : 'hidden'} className='editor-timelineFooter-skipSegmentTooltip-inner'>
                Use Shift + Arrow Keys                              
              </div>
                            
              <div data-visibility-state={hasKeyboardTrimSeeked ? 'visible' : 'hidden'} className='editor-timelineFooter-skipSegmentTooltip-inner'>
                Press <span className='editor-timelineFooter-skipSegmentTooltip-inner-emph'>Enter</span> to Add Trim
              </div>              
              
            </div>
          </animated.div>
          
        </div>


        <div className='editor-timelineFooter-rightContainer'>        
        {/*  */}

          {/*}
              <button onClick={props.deleteScene} className={'uiButton uiButton--addMusic'}>      
              delete Scene
              </button>

               <button onClick={props.addSceneToTimeline} className={'uiButton uiButton--addMusic'}>      
              add Scene
              </button>
          */}
        
          <div className='editor-timelineFooter-zoomSliderContainer'>
                  
            <div className='editor-timelineFooter-zoomSliderLabel'>
              Timeline
            </div>              
            
            <div className='editor-timelineFooter-zoomSliderInnerContainer'>
              <ReactSlider
                className="editor-timelineFooter-zoomSlider"
                thumbClassName="editor-timelineFooter-zoomSlider-thumb"
                trackClassName="editor-timelineFooter-zoomSlider-track"                  
                renderThumb={(props, state) => {
                  const { key, ...restProps } = props;
                  return <div key={key} className='editor-timelineFooter-zoomSlider-thumb-thumb' {...restProps} />;
                }}
                min={0}
                max={0.95}
                step={0.01}
                value={sliderValue}
                onChange={handleSliderChange}
              />
            </div>
            
          </div>

          <button onClick={props.onZoomToFit} className='editor-timelineFooter-footerBtn'>
            <div className='editor-timelineFooter-footerBtn-iconContainer'>
              <Icon name='fitTimelineMedium' />
            </div>                              
            <div className='editor-timelineFooter-footerBtn-label'>
              Fit
            </div>            
          </button> 

          {/*}
            <button data-state={props.showShortcutsPopover ? 'active' : 'inactive'} onClick={openShortcutsPopover}  className='editor-timelineFooter-footerBtn editor-timelineFooter-footerBtn--shortcuts'>
              <div className='editor-timelineFooter-footerBtn-iconContainer'>
                <Icon name='keyboard' />
              </div>
              <div className='editor-timelineFooter-footerBtn-label'>
                Shortcuts
              </div>
            </button>  
          */}                           

      

        </div>

                      
    </div>
  );
})

export default EditorTimelineFooter;



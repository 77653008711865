import React from 'react';
import {calculateTimelineTimeFromVideoTime} from '../../../../../timeline/utils/calculateTimelineTimeFromVideoTime'

const EditorTimelineClipContentsWebcam = ({ clip,webcamChunks,preVideoResizeSnapshot,segments }) => {
  let chunks 
  let clipDuration = clip.duration

  ///When resizing webcam clip and making it shorter show the snapshot chunks from preresize state
  if(preVideoResizeSnapshot && preVideoResizeSnapshot.webcamClipId==clip.id && (preVideoResizeSnapshot.endTime - preVideoResizeSnapshot.startTime) > clip.duration){
    chunks=preVideoResizeSnapshot.webcamChunks
    clipDuration = preVideoResizeSnapshot.endTime - preVideoResizeSnapshot.startTime
  }else{

    if(clip.metadata.isVariable && webcamChunks){ //work out the chunks from the audio clips
      const sortedChunks = webcamChunks.sort((a, b) => a.indexInParentClip - b.indexInParentClip);
    let placeholderChunks=[] 
    sortedChunks.forEach((chunk)=>{
      placeholderChunks.push({duration:chunk.duration,text:chunk.metadata.text,startTime:chunk._startTime - clip.startTime + clip.scene.startTime})
    })
    chunks=placeholderChunks
  }else{
    if(clip.metadata.transcript && clip.metadata.transcript.chunks){
      let transcriptChunks =[]
      //const skippedIndices = (clip.metadata.transcript.skippedItems || []).map(item => item.originalIndex)
     const skipSegments = segments.filter(segment => segment.isSkipSegment)

     const skipRanges = skipSegments.map(segment => ({
      startTime: segment.originalStart,
      endTime: segment.originalEnd,
     }))
      
      clip.metadata.transcript.chunks.forEach((chunk)=>{
        const startTime = Math.max(chunk.startTime,clip.metadata.trimStart)
        const endTime = Math.min(chunk.endTime,clip.metadata.trimEnd)

        const duration = calculateTimelineTimeFromVideoTime(endTime,clip) -calculateTimelineTimeFromVideoTime(startTime,clip) 
        const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime,clip) - clip.startTime
        let filteredText = chunk.text;
        if (chunk.items && clip.metadata.trimStart !== undefined && clip.metadata.trimEnd !== undefined) {
          // const filteredItems = chunk.items.filter(item => 
          //   !(item.startTime < clip.metadata.trimStart || item.startTime > clip.metadata.trimEnd)&&
          //    !skippedIndices.includes(item.originalIndex) 
          // );

          const filteredItems = chunk.items.filter(item => 
            !(item.endTime < clip.metadata.trimStart || item.startTime > clip.metadata.trimEnd)&&
             !skipRanges.some(range => item.startTime >= range.startTime && item.endTime <= range.endTime)
          );

          filteredText = filteredItems.map(item => {
            const word = item.word || '';
            return item.isPause ? word + '.' : word;
          }).join(' ');
        }
        if(duration>0){
          transcriptChunks.push({
            duration: duration,
            text: filteredText,
            startTime: relativeStartTime
          })
        }
      })
      chunks=transcriptChunks
    }else{
        chunks=[{duration:clip.duration,text:'placeholder chunk'}]
      }
    }
  }

  const hasTranscript = clip.metadata.transcript && clip.metadata.transcript.chunks
  const isLoadingTranscript = !clip.metadata.isVariable && !hasTranscript

  return (
    <>
      <div 
        data-instructions-clip={false}
        className='editor-timeline-clip-inner'
      >
        {chunks && chunks.map((chunk, index) => (
          <div 
            key={index}
            className='editor-timeline-clip-inner-chunk'
            style={{ 
              left: `${((chunk.startTime) / clipDuration) * 100}%`,
              width: `${((chunk.duration) / clipDuration) * 100}%`
            }}
          >
            <div className="editor-timeline-clip-inner-chunk-line" />
            {/*{isInstructions &&
            <div className="editor-timeline-clip-inner-chunk-iconContainer">
              <Icon name='speakerRectangleMedium' />
            </div>
            }*/}

            {isLoadingTranscript && 
              <div className="editor-timeline-clip-inner-chunk-text">
                <div className='editor-timeline-clip-inner-chunk-text-inner editor-timeline-clip-inner-chunk-text-inner--loading'>
                  <div className='editor-timeline-clip-inner-chunk-text-inner-text'>
                    •••••
                  </div>
                </div>
              </div>            
            }
            {!isLoadingTranscript && 
              <div className="editor-timeline-clip-inner-chunk-text">
                {chunk.text}
              </div>            
            }
            <div className="editor-timeline-clip-inner-chunk-bg" />
          </div>
        ))}
      </div>
    </>
  );
};

export default EditorTimelineClipContentsWebcam;
import React, { useState, useEffect,useRef } from 'react';
import Icon from '../../../misc/Icon';
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring';
import EditorNewScreenClipPermissions from './EditorNewScreenClipPermissions';
import EditorNewScreenClipPopover from './EditorNewScreenClipPopover';
import {cancelRecording} from '../../../../utils/recordingUtils/cancelRecording'; 
import { getAvailableRecordingWindows } from '../../../../utils/recordingUtils/getAvailableRecordingWindows';
import { getConnectedAppleDevices } from '../../../../utils/recordingUtils/deviceRecording/getConnectedAppleDevices';



const EditorNewScreenClipPopoverContainer = ({ showModal, openModal, closeModal, children }) => {
	const [forceMount, setForceMount] = useState(false)
	const [permissionsStatus, setPermissionsStatus] = useState(null)
	const [windowsList, setWindowsList] = useState([])
	const [devicesList, setDevicesList] = useState([])
	const permissionsPollInterval = useRef(null);
	
	useEffect(() => {
		if (showModal) {
			setForceMount(true);
			checkPermissions();
		} else {
			setForceMount(false);
			if (permissionsPollInterval) {
				clearInterval(permissionsPollInterval);
				clearPermissionsPoll();
			}
		}
	}, [showModal]);


	const checkPermissions = async () => {
		try {
			const status = await window.ipcRenderer.invoke('check-permissions');
			setPermissionsStatus(status);
			if (status.screenRecordingPermissions === false || status.accessibilityPermissions === false) {
				startPermissionsPoll();
			} else {
				clearPermissionsPoll();
			}
		} catch (error) {
			console.error('Error checking permissions:', error);
		}
	};

	const startPermissionsPoll = () => {
		if (!permissionsPollInterval.current) {
			permissionsPollInterval.current = setInterval(checkPermissions, 1000);
		}
	};

	const clearPermissionsPoll = () => {
		if (permissionsPollInterval.current) {
			clearInterval(permissionsPollInterval.current);
			permissionsPollInterval.current = null;
		}
	};


	useEffect(() => {

		const handleWindowsUpdate = (data) => {
			setWindowsList(data);
		}

		const handleDevicesUpdate = (data) => {
			setDevicesList(data)
		}

		if (showModal) {
			window.ipcRenderer.receive('windows-list', handleWindowsUpdate)
			window.ipcRenderer.receive('apple-devices-list', handleDevicesUpdate)
			const fetchData = () => {
				getAvailableRecordingWindows()
				getConnectedAppleDevices()
			};
			fetchData(); // Initial fetch
			const intervalId = setInterval(fetchData, 3000)
			return () => {
				clearInterval(intervalId);
				window.ipcRenderer.removeListener('windows-list', handleWindowsUpdate)
				window.ipcRenderer.removeListener('apple-devices-list', handleDevicesUpdate);
			}
		}
	}, [showModal])


	let showPermissionsDialogue = false
	
	if (permissionsStatus && (permissionsStatus.accessibilityPermissions == false || permissionsStatus.screenRecordingPermissions == false)) {
		showPermissionsDialogue = true
	}    

	return (
		<React.Fragment>
			<Popover.Root open={showModal} modal={true} onOpenChange={(open) => {
				if (!open) {
					//cancelRecording()
					closeModal(); 
				}
			}}>
				<Popover.Trigger asChild>
					{children}
				</Popover.Trigger>
				<Popover.Portal forceMount={showModal ? true : forceMount}>					
					<Popover.Content
						className="editorPopover editorPopover--screenClip forceDarkTheme"            
						forceMount={showModal ? true : forceMount}
						side="top"
						sideOffset={8}
						align="center"
						alignOffset={0}
					>

						{showPermissionsDialogue && 
							<EditorNewScreenClipPermissions 
								permissionsStatus={permissionsStatus}
								closeModal={closeModal}
							/>
						}

						{!showPermissionsDialogue && 
							<EditorNewScreenClipPopover 
								windowList={windowsList}
								devicesList={devicesList}
								closeModal={closeModal}
							/>
						}
						
					</Popover.Content>
				</Popover.Portal>
			</Popover.Root>
		</React.Fragment>
	);
};

export default EditorNewScreenClipPopoverContainer;


import React, {useRef, useState, useEffect} from 'react';
import Icon from '../../misc/Icon'; // Adjust the path as needed
import ReactPlayer from 'react-player/file';
import VideoPreviewPlayerControls from './VideoPreviewPlayerControls';
import Hls from 'hls.js';
import mux from "mux-embed";
import VideoDuration from './videoDuration';

const MUX_DATA_ENV_KEY = 'i82d6jem8j27egsirrokd61ds'

function VideoPreviewPlayer({width, height, playbackId, onPlayerReady, isMuted, storedVideoProgress, setStoredVideoProgress, hoveredOver, forwardDurationCounter}) {

  const playbackUrl = `https://stream.mux.com/${playbackId}.m3u8`

  // console.log(playbackUrl)

  const playerRef = useRef(null); 

  useEffect(() => {
  let hls;

  if (Hls.isSupported() && playerRef.current) {
    const video = playerRef.current.getInternalPlayer();
    const initTime = mux.utils.now();

    hls = new Hls();
    hls.loadSource(playbackUrl);
    hls.attachMedia(video);
    hls.config.maxBufferLength = 60; // Buffer up to 60 seconds of video
    // hls.config.abrEwmaDefaultEstimate = 10000000;
    // hls.config.abrBandWidthFactor = 0.95;
    // hls.config.abrBandWidthUpFactor = 0.95;

    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      // Log levels for debugging purposes
      // hls.levels.forEach((level, index) => console.log(`Level ${index}: ${level.height}`));
      
      // Set the initial quality to the first level with a height of at least 720p
      const minDesiredHeight = height * 2;
      const targetLevel = hls.levels.findIndex(level => level.height >= minDesiredHeight);

      if (targetLevel !== -1) {
        hls.currentLevel = targetLevel;
        hls.loadLevel = targetLevel;
        hls.startLevel = targetLevel;
      }
    });

    mux.monitor(video, {
      debug: false,
      hlsjs: hls,
      Hls,
      data: {
        env_key: MUX_DATA_ENV_KEY, // required
        player_name: "Yarn Static Player", // any arbitrary string you want to use to identify this player
        player_init_time: initTime,
        video_id: playbackId,
      }
    });
  }

  return () => {
    if (hls) {
      hls.destroy();
      if (hls.media) {
        hls.detachMedia();
      }
    }

    if (playerRef.current) {
      const video = playerRef.current.getInternalPlayer();
      if (video) {
        video.src = "";
        video.load();
      }
    }
  };
}, [playbackUrl]);


  const [playing, setPlaying] = useState(hoveredOver);
  const [volume, setVolume] = useState(1);
  //const [muted, setMuted] = useState(isMuted);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(storedVideoProgress || 0);
  const [progressSeconds, setProgressSeconds] = useState(0);

  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);

  useEffect(() => {
    setPlaying(hoveredOver);
  }, [hoveredOver]);


  // Restore progress on mount if it exists
  useEffect(() => {
    if (storedVideoProgress > 0 && playerRef.current) {
      playerRef.current.seekTo(storedVideoProgress);
    }
  }, [playerRef.current]);

  

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleEnded = () => {
    setPlaying(false);    
    setProgress(0);
    setProgressSeconds(0);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);
    }
    if (state.played > 0 && onPlayerReady) {
      onPlayerReady();
    }
  };
  
    useEffect(() => {
      return () => {
        setStoredVideoProgress(progress);
      };
    }, [progress]);




  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
    //setMuted(true);
  };

  const handleSeekMouseUp = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
    setSeeking(false);
    //setMuted(false);
  };

  // Ready handler
  const handlePlayerReady = () => {
    //console.log('Player is ready');    
  };

  let durationLabel = duration - progressSeconds
  if(forwardDurationCounter){
    durationLabel = progressSeconds
  }

  return (       
    <div style={{width: `${width}px`}} className='videoPreview-player'>
      <div className='videoPreview-duration'>            
        <VideoDuration seconds={durationLabel} />
      </div>

      <div className='videoPreview-player-videoContainer'>
        <ReactPlayer
          ref={playerRef}
          className='videoPreview-player-video'
          width='100%'
          height='100%'
          url={playbackUrl}
          playing={playing}
          volume={0.8}
          progressInterval={50}
          muted={isMuted}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onReady={handlePlayerReady}
          
        />      
      </div>
      
      <VideoPreviewPlayerControls 
        playbackId={playbackId}
        playing={playing}
        onPlayPauseClick={() => setPlaying(!playing)}
        onVolumeChange={(e) => setVolume(parseFloat(e.target.value))}
        onMuteClick={() => setMuted(!muted)}
        progress={progress}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        duration={duration}
      />
      
    </div>
  );
}

export default VideoPreviewPlayer;

export default function (state = [], action) {
	switch (action.type) {
	
    case 'FETCH_SCREEN_RECORDINGS_SUCCESS':
      return action.response

    case 'FETCH_SINGLE_SCREEN_RECORDING_SUCCESS':
      const newScreenRecording = action.response
      const screenRecordingIndex = state.findIndex(screenRecording => screenRecording.capture_id === newScreenRecording.capture_id)
      if (screenRecordingIndex !== -1) {
        const newState = [...state.slice(0, screenRecordingIndex), newScreenRecording, ...state.slice(screenRecordingIndex + 1)]
        return newState
      }
      return [...state, newScreenRecording]

    case 'TRIGGER_AUTO_ZOOM_SUCCESS':
      if (!action.response.capture_id) return state
      return state.map(recording => 
        recording.capture_id === action.response.capture_id
          ? { ...recording, ai_zoom_boxes: action.response.autoZooms }
          : recording
      )

    default:
      return state;
	}
}

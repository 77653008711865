import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { SpringAnimator } from '../utils/animations/SpringAnimator';
import CanvasBackground from '../CanvasBackground';
import ImageClipDevice from './ImageClipDevice'
import {getAnimationParamsForMotionStyle} from '../utils/animations/getAnimationParamsForMotionStyle'
import { startTransitionConfigs } from './transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './transitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../utils/animations/simulateAnimationDuration';
import { getBackgroundForId } from '../../utils/brands/getBackgroundForId'
import defaultImageAnimationValues from '../utils/animations/defaultImageAnimationValues'
import renderOrders from '../renderOrders'
import {isLightBackground} from '../../utils/brands/isLightBackground'


const FPS = 60; // Frames per second


const ImageClip = ({clip,time,updateMeshWidth,updateMeshHeight,projectBackground,showImageStatic,isPlaying, sceneWidth, sceneHeight}) => {

  const maxScalar = 1;
  const minScalar = 0.3;
  const maxDisplayScale = 1800; 
  const minDisplayScale = 300;

  let clipDisplayWidth = maxDisplayScale; // Default value

  // Get actual displayWidth from clip metadata if available
  if (clip && clip.metadata && clip.metadata.displayWidth) {
    clipDisplayWidth = clip.metadata.displayWidth;
}

  // Ensure clipDisplayWidth is within bounds
  clipDisplayWidth = Math.min(Math.max(clipDisplayWidth, minDisplayScale), maxDisplayScale);

  // Calculate scalar based on clipDisplayWidth
  let scalar = minScalar + (clipDisplayWidth - minDisplayScale) * (maxScalar - minScalar) / (maxDisplayScale - minDisplayScale);

  //console.log(`${scalar}`);


  //Animation style
  const startTransitionType = clip.metadata.startTransitionType
  const endTransitionType = clip.metadata.endTransitionType
  const motionStyle=clip.metadata.motionStyle || 'smooth'
  const animationParams=getAnimationParamsForMotionStyle(motionStyle)

  const relativeTime = time - clip.startTime 

  //Visibility
  let visible = false 
  if(time >= clip.startTime && time < clip.endTime){
    visible=true
  }

  //background
  const backgroundId = clip.metadata.backgroundId
  let imageClipBackground = projectBackground
  const isNoneBGColor = backgroundId =='none'

  const idToTest=backgroundId||projectBackground.id
  const isLightBG = isLightBackground(idToTest)

  if(backgroundId){
    imageClipBackground=getBackgroundForId(backgroundId)
  }



  //

  const clipDuration = clip.duration
  const localStartTime = 0
  const localEndTime = clipDuration - 0.1
  const calculationWindowEnd = localEndTime + 0.2

  //

  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = (startTransitionConfigs[startTransitionType] || (() => ({})))(scalar);
  const { endTo: transitionEndTo } = (endTransitionConfigs[endTransitionType] || (() => ({})))(scalar);

  const startFrom = { ...defaultImageAnimationValues, ...transitionStartFrom };
  const startTo = { ...defaultImageAnimationValues, ...transitionStartTo };
  const endTo = { ...defaultImageAnimationValues, ...transitionEndTo };

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [startToCompletedTime, setStartToCompletedTime] = useState(null);
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(null);

  useEffect(() => {
    let newPropertySprings = {};
    let newAnimationStates = {};

    // Ensure that calculationWindowEnd and FPS are defined and valid
    const framesCount = calculationWindowEnd && FPS ? Math.round(calculationWindowEnd * FPS) : 0;

    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      
      newAnimationStates[property] = new Array(framesCount).fill(startFrom[property]);
    });

    // Calculate the animation durations
    let startToDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    if(startToDuration === 'none'){
      startToDuration = 0
    }
    let endToDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);
    if(endTransitionType === 'none'){
      endToDuration = 0
    }

    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < startToDuration) {
        Object.keys(startTo).forEach(property => {
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the endInitiateAnimationTime
      if (simulationLocalTime >= (localEndTime - endToDuration) && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];        
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // Update states at the end of the calculation
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);
    setStartToCompletedTime(startToDuration);
    setEndToAnimationDuration(endToDuration);
  
  }, [clipDuration, JSON.stringify(animationParams), startTransitionType, endTransitionType]);




  return (
    <>      

      {imageClipBackground && visible && !isNoneBGColor &&
        <CanvasBackground renderOrder={renderOrders.slideBG} background={imageClipBackground} />
      }

      
        <ImageClipDevice
          animationStates={animationStates}
          initialProperties={startFrom}
          FPS={FPS}

          clip={clip}
          time={time}
          localTime={relativeTime}
          visible={visible}

          startTransitionType={startTransitionType}
          endTransitionType={endTransitionType}
          animationParams={animationStates}   
          showImageStatic={showImageStatic}       
          isPlaying={isPlaying} 

          isLightBG={isLightBG}   
          renderOrder={renderOrders.legacyImageClip}

          sceneWidth={sceneWidth}
          sceneHeight={sceneHeight}
        />
      


    </>
  );
};

export default ImageClip;



import {getOrgBrand} from './getOrgBrand'

export function getClickScaleAnimationConfig  () {
  let clickScaleAnimationConfig = {enabled:true}
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.clickScaleAnimationConfig){
    clickScaleAnimationConfig = orgBrand.screenVideoStyle.clickScaleAnimationConfig
  }
  return clickScaleAnimationConfig
}

export function trimZoomBoxes(clip, zoomBoxes) {
    const startTime = clip.metadata.trimStart || 0
    const endTime = clip.metadata.trimEnd || clip.duration
    console.log('startTime', startTime, 'endTime', endTime)
    // Process each zoom box
    zoomBoxes.forEach((zoomBox) => {
        if (!zoomBox.duration) {
            zoomBox.duration = zoomBox.endTime - zoomBox.startTime
        }
    })

    zoomBoxes = zoomBoxes.reduce((acc, zoomBox) => {
        if (zoomBox.startTime < endTime && (zoomBox.startTime + zoomBox.duration) >= startTime) {
            const newZoomBox = {
                ...zoomBox,
                startTime: Math.max(zoomBox.startTime, startTime),
                duration: Math.min(zoomBox.duration, endTime - zoomBox.startTime)
            }
            if (newZoomBox.duration > 1) {
                acc.push(newZoomBox)
            }
        }
        return acc
    }, [])

    return zoomBoxes
}


import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { SpringAnimator } from '../../utils/animations/SpringAnimator';
import { getMasterRecordingData } from '../../../utils/recordings/screenRecordings/getMasterRecordingData'
import { getLegacyMasterRecordingData } from '../../../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData'
import {calulateVideoTimeFromTimelineTime} from '../../../timeline/utils/calulateVideoTimeFromTimelineTime'
import {getAnimationParamsForMotionStyle} from '../../utils/animations/getAnimationParamsForMotionStyle'
import { startTransitionConfigs } from '../transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from '../transitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../../utils/animations/simulateAnimationDuration';
import { calculateVideoZoom } from '../../utils/videoZoom/calculateVideoZoom'

import ScreenVideoDesktopModeDevice from './ScreenVideoDesktopModeDevice'

const DEFAULT_MASTER_DATA={
  videoURL: '',
  recordingWidth: 0,
  recordingHeight: 0,
  recordingXOffset: 0,
  recordingYOffset: 0,
  recordingVideoProcessStartTimeMs: 0,
  recordingVideoProcessEndTimeMs: 0,
  recordingVideoDuration: 0,
  cursorData: []
}

const FPS = 60; // Frames per second
const defaultValues = { // these are default values for animations
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};



const ScreenVideoDesktopMode = (props) => {
  const { 
    clip,
    time,
    updateMeshWidth,
    updateMeshHeight,
    showScreenVideoStatic, 
    zoomClips,
    readyVideoClips, 
    sceneWidth, 
    sceneHeight, 
    isVideoResizeModeActive,
    preVideoResizeSnapshot
  } = props
  
  
  
  
  let startTransitionType = clip.metadata.startTransitionType
  if(clip.metadata.startTransitionType === 'fadeAndMoveDown'){
    startTransitionType = 'fadeIn'
  }
  let endTransitionType = clip.metadata.endTransitionType
  if(clip.metadata.endTransitionType === 'fadeAndMoveUp'){
    endTransitionType = 'fadeOut'
  }

  const motionStyle=clip.metadata.motionStyle || 'smooth'

  let adjustedSpeed = 0
  let adjustedBounciness = 0
  if(clip.metadata.enterTransitionSpeedFactor){
    adjustedSpeed = clip.metadata.enterTransitionSpeedFactor
  }
  if(clip.metadata.enterTransitionBouncinessFactor){
    adjustedBounciness = clip.metadata.enterTransitionBouncinessFactor
  }

  const animationParams=getAnimationParamsForMotionStyle(motionStyle, adjustedSpeed, adjustedBounciness)

  const relativeTime = time - clip.startTime 
  const localTime = calulateVideoTimeFromTimelineTime(time,clip)

  const [masterRecordingData, setMasterRecordingData] = useState(DEFAULT_MASTER_DATA);

  useEffect(() => {
    const fetchData = async () => {
      if(clip.isScreenRecording){
        const data = await getMasterRecordingData(clip.captureId);
        setMasterRecordingData(data);
      }else{
        const data = await getLegacyMasterRecordingData(null, clip.captureId);
        setMasterRecordingData(data);
      }
    };
    fetchData();
  }, [clip]);

 
  

  let recordingWidth = masterRecordingData.recordingWidth
  const recordingPointScale = masterRecordingData.pointScale
  
  //
  // MESH DIMENSIONS AND SCALE
  // here we basically cover the scene in the screen recording.

  const sceneAspectRatio = sceneWidth / sceneHeight


  const { meshWidth, meshHeight } = useMemo(() => {
  
    const recordingAspectRatio = masterRecordingData.recordingWidth / masterRecordingData.recordingHeight;

    let width, height;
    
    if (recordingAspectRatio > sceneAspectRatio) {    
      height = sceneHeight;
      width = height * recordingAspectRatio;
      
    } else {      
      // Recording is taller relative to its width than the scene
      width = sceneWidth;
      height = width / recordingAspectRatio;
    }

    if(updateMeshHeight){
      updateMeshHeight(clip.id, height)
    }
    if(updateMeshWidth){
      updateMeshWidth(clip.id, width)
    }

    return {
      meshWidth: width,
      meshHeight: height
    }
  }, [masterRecordingData.recordingWidth, masterRecordingData.recordingHeight, sceneWidth]);


  const meshScale = useMemo(() => {
    return masterRecordingData.recordingWidth > 0 ? meshWidth / masterRecordingData.recordingWidth : 1;
  }, [meshWidth, masterRecordingData.recordingWidth]);

  const cursorScale = useMemo(() => {
  return recordingWidth > 0 ? 
    (meshWidth * recordingPointScale) / recordingWidth : 1;
}, [meshWidth, recordingWidth, recordingPointScale]);


  let visible = false   
  if(localTime >= clip.metadata.trimStart && parseFloat(localTime.toFixed(10)) < parseFloat(clip.metadata.trimEnd.toFixed(10))){ //new Feb 23rd for trim problem  
    visible=true
  }


  //If currently resizing the clip it should be visible (prevent flashing as resize it shorter)
  if(preVideoResizeSnapshot && preVideoResizeSnapshot.videoClipId === clip.id){
    visible=true
  }

  const videoDuration = clip.duration // plays as long of and then exits
  const localStartTime = 0
  const localEndTime = videoDuration - 0.1
  const calculationWindowEnd = localEndTime + 0.2
  
  let scalar = 1
  if(clip.metadata.enterTransitionValueFactor){
    scalar = clip.metadata.enterTransitionValueFactor + 1 // convert to scalar
  }

  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = (startTransitionConfigs[startTransitionType] || (() => ({})))(scalar);
  const { endTo: transitionEndTo } = endTransitionConfigs[endTransitionType] || {};
  const startFrom = { ...defaultValues, ...transitionStartFrom };
  const startTo = { ...defaultValues, ...transitionStartTo };
  const endTo = { ...defaultValues, ...transitionEndTo };

  // console.log('transitionStartFrom')
  // console.log(transitionStartFrom)

  let initialProperties = startFrom
  if(showScreenVideoStatic){
    initialProperties = defaultValues
  }

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [startToCompletedTime, setStartToCompletedTime] = useState(null);
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(null);


  useEffect(() => {
    let newPropertySprings = {};
    let newAnimationStates = {};

    // Ensure that calculationWindowEnd and FPS are defined and valid
    const framesCount = calculationWindowEnd && FPS ? Math.round(calculationWindowEnd * FPS) : 0;

    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      
      newAnimationStates[property] = new Array(framesCount).fill(startFrom[property]);
    });

    // Calculate the animation durations
    let startToDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    if(startToDuration === 'none'){
      startToDuration = 0
    }
    let endToDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);
    if(endTransitionType === 'none'){
      endToDuration = 0
    }

    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < startToDuration) {
        Object.keys(startTo).forEach(property => {
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the endInitiateAnimationTime
      if (simulationLocalTime >= (localEndTime - endToDuration) && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];        
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // Update states at the end of the calculation
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);
    setStartToCompletedTime(startToDuration);
    setEndToAnimationDuration(endToDuration);
  
  }, [videoDuration, JSON.stringify(animationParams), startTransitionType, endTransitionType, scalar, adjustedSpeed, adjustedBounciness]);



  //
  // VIDEO ZOOM

   const groupRef = useRef();
  const [zoomFrames, setZoomFrames] = useState([]);

  const zoomClipsDependency = JSON.stringify(
    zoomClips
      .slice() // Create a shallow copy to avoid mutating the original array
      .sort((a, b) => a.startTime - b.startTime) // Sort by startTime
      .map(clip => ({
        id: clip.id, // Include if you need to track addition/removal of clips
        startTime: clip.startTime,
        duration: clip.duration,
        originX: clip.metadata.zoomValues.originX,
        originY: clip.metadata.zoomValues.originY,
        scale: clip.metadata.zoomValues.scale,
        motionSettings: clip.metadata.zoomValues.motionSettings,
        endMotionSettings: clip.metadata.zoomValues.endMotionSettings
      }))
  );

  const readyVideoClipsDependency = JSON.stringify(readyVideoClips);

  useEffect(() => {
    const frames = calculateVideoZoom(zoomClips, clip.id, clip.startTime, clip.duration);
    setZoomFrames(frames);
  }, [zoomClipsDependency, readyVideoClipsDependency, clip.startTime, clip.duration]);

  const findCurrentZoomFrame = (zoomFrames, currentTime) => {
    if (!zoomFrames || zoomFrames.length === 0) {
      return { scale: 1, originX: 0, originY: 0 };
    }

    let start = 0;
    let end = zoomFrames.length - 1;

    while (start <= end) {
      let mid = Math.floor((start + end) / 2);
      if (zoomFrames[mid].time === currentTime) {
        return zoomFrames[mid];
      } else if (zoomFrames[mid].time < currentTime) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    // Fallback to the closest frame if exact match not found
    const closestFrameIndex = Math.max(0, Math.min(end, zoomFrames.length - 1));
    return zoomFrames[closestFrameIndex];
  };

  useEffect(() => {
    const currentZoomFrame = findCurrentZoomFrame(zoomFrames, time);

    if (groupRef.current) {
      groupRef.current.scale.set(currentZoomFrame.scale, currentZoomFrame.scale, currentZoomFrame.scale);
      groupRef.current.position.set(-currentZoomFrame.originX, currentZoomFrame.originY, 0);
    }
  }, [zoomFrames, time]);





  return (
      <group ref={groupRef}>
      
      {meshWidth && meshHeight && 
        <ScreenVideoDesktopModeDevice 
          animationStates={animationStates}
          initialProperties={initialProperties}
          FPS={60}
          showScreenVideoStatic={showScreenVideoStatic}
          meshWidth={meshWidth}
          meshHeight={meshHeight}          
          clip={clip}
          time={time}
          localTime={relativeTime}
          visible={visible}
          meshScale={meshScale}          
          startTransitionType={startTransitionType}
          endTransitionType={endTransitionType}
          animationParams={animationStates}
          cursorScale={cursorScale}
          isVideoResizeModeActive={isVideoResizeModeActive}
        />
      }        
        
    
    </group>
  );
};

export default ScreenVideoDesktopMode;



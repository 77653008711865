import React, { useRef, useState, useEffect, useImperativeHandle, useMemo, useCallback } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { calculateCameraBoxSequence } from './utils/zoom/calculateCameraBoxSequence';
import { calculateAnimatedCameraBoxFrames } from './utils/zoom/calculateAnimatedCameraBoxFrames';
import { throttle } from 'lodash';



const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;

const defaultCameraBox = {
    width: 1920,
    height: 1080,
    x: 0,
    y: 0
  };


CameraControls.install({ THREE });


function binarySearchNearest(arr, target, key) {
  let start = 0;
  let end = arr.length - 1;
  let mid;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    if (arr[mid][key] === target) {
      return arr[mid];
    } else if (arr[mid][key] < target) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  // Handle edge cases where the target is beyond the array bounds
  if (mid === 0) {
    return arr[0];
  } else if (mid === arr.length - 1) {
    return arr[arr.length - 1];
  }

  // Check which of the two elements is closer to the target
  const prevDiff = Math.abs(arr[mid - 1][key] - target);
  const nextDiff = Math.abs(arr[mid][key] - target);
  return prevDiff <= nextDiff ? arr[mid - 1] : arr[mid];
}

//Ready video clips is an array of clip ids that have cursor data ready
//need to calculate the zoom box once the cursor data is ready on initial load
//otherwise just get pos 0,0 when load

const Camera = React.forwardRef(({ currentTime, zoomClips,readyVideoClips}, ref) => {
	
	const { camera, gl, scene } = useThree();	 

  useImperativeHandle(ref, () => ({
    getCamera: () => camera,
    getGL: () => gl,
    getScene: () => scene
  }));

	const cameraControlsRef = useRef(null);

	const boxRef = useRef();
	const [cameraBoxSequence, setCameraBoxSequence] = useState([]);

	//  // Serialize the relevant parts of zoomClips for useEffect dependency
  // const zoomClipsDependency = JSON.stringify(zoomClips.map(clip => ({
  //   id: clip.id, // Include if you need to track addition/removal of clips
  //   startTime: clip.startTime,
  //   duration: clip.duration,
  //   originX: clip.metadata.zoomValues.originX,
  //   originY: clip.metadata.zoomValues.originY,
  //   scale: clip.metadata.zoomValues.scale,
  //   motionSettings: clip.metadata.zoomValues.motionSettings
  // })));

////Nicole- i updated this to sort by time before stringifying because its triggering change when data the same
const zoomClipsDependency = JSON.stringify(
  zoomClips
    .slice() // Create a shallow copy to avoid mutating the original array
    .sort((a, b) => a.startTime - b.startTime) // Sort by startTime
    .map(clip => ({
      id: clip.id, // Include if you need to track addition/removal of clips
      startTime: clip.startTime,
      duration: clip.duration,
      originX: clip.metadata.zoomValues.originX,
      originY: clip.metadata.zoomValues.originY,
      scale: clip.metadata.zoomValues.scale,
      motionSettings: clip.metadata.zoomValues.motionSettings
    }))
);

	const readyVideoClipsDependency = JSON.stringify(readyVideoClips);


  useEffect(() => {
    const newCameraBoxSequence = calculateCameraBoxSequence(zoomClips);
    setCameraBoxSequence(newCameraBoxSequence); // This will update the state
  }, [zoomClipsDependency,readyVideoClipsDependency]);   


  //
  // Clean Frames

  // const [cleanedCameraBoxSequence, setCleanedCameraBoxSequence] = useState([]);

	// useEffect(() => {
	//   const adjustAndSortSequence = () => {
	//     const adjustedSequence = {};
	//     Object.entries(cameraBoxSequence).forEach(([time, value]) => {
	//       const adjustedTime = parseFloat(time) < 0 ? 0 : parseFloat(time);
	//       adjustedSequence[adjustedTime] = value;
	//     });

	//     const sortedSequenceArray = Object.entries(adjustedSequence).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));
	//     return Object.fromEntries(sortedSequenceArray);
	//   };

	//     setCleanedCameraBoxSequence(adjustAndSortSequence());
	// }, [cameraBoxSequence]); 


	// console.log('cameraBoxSequence')
	// console.log(cameraBoxSequence)

	// console.log('cleanedCameraBoxSequence')
	// console.log(cleanedCameraBoxSequence)

	//
	// ANIMATED FRAMES

	// const [animatedCameraBoxFrames, setAnimatedCameraBoxFrames] = useState([]);	

  // useEffect(() => {
  //   const newAnimatedCameraBoxFrames = calculateAnimatedCameraBoxFrames(cleanedCameraBoxSequence, zoomClips);
  //   setAnimatedCameraBoxFrames(newAnimatedCameraBoxFrames); // This will update the state
  // }, [cleanedCameraBoxSequence, zoomClipsDependency]);   
  

	// // // Zoom box camera tracking

	// const findCurrentCameraBox = (animatedCameraBoxFrames, currentTime) => {
	//   if (!animatedCameraBoxFrames || animatedCameraBoxFrames.length === 0) {
	//     return defaultCameraBox; // defaultCameraBox as defined earlier
	//   }

	//   let start = 0;
	//   let end = animatedCameraBoxFrames.length - 1;

	//   while (start <= end) {
	//     let mid = Math.floor((start + end) / 2);
	//     if (animatedCameraBoxFrames[mid].time === currentTime) {
	//       return animatedCameraBoxFrames[mid];
	//     } else if (animatedCameraBoxFrames[mid].time < currentTime) {
	//       start = mid + 1;
	//     } else {
	//       end = mid - 1;
	//     }
	//   }

	//   // Fallback to the closest frame if exact match not found
	//   const closestFrameIndex = Math.max(0, Math.min(end, animatedCameraBoxFrames.length - 1));
	//   return animatedCameraBoxFrames[closestFrameIndex];
	// };


	// useEffect(() => {
  //   const currentCameraBox = findCurrentCameraBox(animatedCameraBoxFrames, currentTime) || defaultCameraBox;

  //   if (boxRef.current) {
  //     boxRef.current.scale.set(currentCameraBox.width, currentCameraBox.height, 1);
  //     boxRef.current.position.set(currentCameraBox.x, currentCameraBox.y, 10);
  //   }
  // }, [currentTime, animatedCameraBoxFrames, zoomClipsDependency]);

	// console.log('animatedCameraBoxFrames')
	// console.log(animatedCameraBoxFrames)



	useEffect(() => {
	  cameraControlsRef.current = new CameraControls(camera, gl.domElement);

	  // Return a cleanup function
	  return () => {
	    if (cameraControlsRef.current) {
	      cameraControlsRef.current.dispose(); // Dispose the camera controls
	    }
	  };
	}, [camera, gl.domElement]);

  useEffect(() => {
	  if (cameraControlsRef.current) {
	    //const currentCameraBox = findCurrentCameraBox(animatedCameraBoxFrames, currentTime);
	    const currentCameraBox = defaultCameraBox

	    const box = new THREE.Box3().setFromCenterAndSize(
	      new THREE.Vector3(currentCameraBox.x, currentCameraBox.y, 0),
	      new THREE.Vector3(currentCameraBox.width, currentCameraBox.height, 0)
	    );

	    cameraControlsRef.current.fitToBox(box, false); // Set enableTransition to false
	    cameraControlsRef.current.enabled = false;
	  }
	}, [currentTime, zoomClipsDependency]);




  // Animate camera controls on each frame
  useFrame((_, delta) => {
    if (cameraControlsRef && cameraControlsRef.current) {
      cameraControlsRef.current.update(delta);
    }
  });


  return (
		<>

		{/*
		<mesh ref={boxRef} position={[0, 0, 0]}>
      <boxGeometry args={[1, 1, 1]} />
      <meshBasicMaterial color={'red'} transparent={true} opacity={0.15} />
    </mesh>
    */}
			
			
  
		</>
	)
	
});

export default Camera;

//return null 
// Modify the LerpAnimator class to coordinate timing across all properties
export class LerpAnimator {
  constructor(baseDuration, easingFunction, initialPosition = 0, precision = 0.01, instant = false) {
    this.baseDuration = baseDuration;
    this.duration = baseDuration;
    this.easingFunction = easingFunction || easingFunctions.easeOutCubic;
    this.target = initialPosition;
    this.position = initialPosition;
    this.startPosition = initialPosition;
    this.startTime = 0;
    this.currentTime = 0;
    this.precision = precision;
    this.instant = instant;
    this.animationProgress = 0; // New property to track shared animation progress
  }

  // Method to update shared animation duration when any property changes
  updateSharedDuration(duration) {
    this.duration = duration;
  }

  // Method to update shared animation progress
  updateSharedProgress(progress) {
    this.animationProgress = progress;
    
    // Calculate position based on shared progress
    if (progress >= 1) {
      this.position = this.target;
    } else {
      const easedT = this.easingFunction(progress);
      this.position = this.startPosition + (this.target - this.startPosition) * easedT;
    }
  }

  setTarget(target) {
    this.startPosition = this.position;
    this.target = target;
    this.startTime = this.currentTime;
    
    // Note: duration is now set externally to coordinate across properties
  }

  simulate(deltaMS) {
    if (this.instant) {
      this.position = this.target;
      return;
    }

    // Only update time - position is updated via updateSharedProgress
    this.currentTime += deltaMS / 1000;
  }
}
import React, {useState, useRef, useEffect} from 'react'
import Icon from '../misc/Icon'
import DPSelectMenuWithAudioPreview from '../editor/detailPanel/DPSelectMenuWithAudioPreview'
import DPSelectMenu from '../editor/detailPanel/DPSelectMenu'
import {SOUND_EFFECTS_CONFIG} from '../../utils/soundEffects/soundEffectsConfig'

function SelectSoundsDropdownEffectsValue({label, options, value, audioPreview, setValue}) {
	return (
		<div className='dropdownMenu--selectSounds-effects-type-value'>
			<div className='dropdownMenu--selectSounds-effects-type-value-label'>
				{label}
			</div>
			<div className='dropdownMenu--selectSounds-effects-type-value-value'>
				{options && value && audioPreview && 
					<DPSelectMenuWithAudioPreview
						value={value}
						setValue={setValue}
						options={options}
						width={115}            
					/>    
				}

				{options && value && !audioPreview && 
					<DPSelectMenu
						value={value}
						onValueChange={setValue}
						options={options}
						width={115}            
					/>    
				}
			</div>
		</div>
	)
}

function SelectSoundsDropdownEffectsTypeWrapper({label, type, initialActiveState = false, children, onToggle}) {
	const [typeActive, setTypeActive] = useState(initialActiveState)

	const handleToggle = () => {
		setTypeActive(!typeActive)
		if (onToggle) {
			onToggle(!typeActive)
		}
	}

	return (
		<div data-active-state={typeActive ? 'active' : 'inactive'} className='dropdownMenu--selectSounds-effects-type'>
			<div onClick={handleToggle} className='dropdownMenu--selectSounds-effects-type-primary'>
				<div className='dropdownMenu--selectSounds-effects-type-primary-checkbox'>
					<div className='dropdownMenu--selectSounds-effects-type-primary-checkbox-iconContainer'>
						<Icon name='toDoItemCheckmark' />
					</div>          
				</div>
				<div className='dropdownMenu--selectSounds-effects-type-primary-label'>
					{label}
				</div>
			</div>
			{typeActive && 
				<div className='dropdownMenu--selectSounds-effects-type-secondary'>
					{children}
				</div>
			}
		</div>
	)
}

function SelectSoundsDropdownEffects({soundEffectsSettings, updateSoundEffectsSettings}) {
	const {loudnessOptions} = SOUND_EFFECTS_CONFIG
	
	const updateSettings = (type, updates) => {
		updateSoundEffectsSettings({
			...soundEffectsSettings,
			[type]: {
				...soundEffectsSettings[type],
				...updates
			}
		})
	}


	return (
		<div className='dropdownMenu--selectSounds-effects'>
			<SelectSoundsDropdownEffectsTypeWrapper
				type='mouseClicks'
				label='Mouse Clicks'
				initialActiveState={soundEffectsSettings.mouse.enabled}
				onToggle={(enabled) => updateSettings('mouse', { enabled })}
			>
				<SelectSoundsDropdownEffectsValue 
					label='Loudness'
					options={loudnessOptions}          
					value={soundEffectsSettings.mouse.loudness}
					setValue={(loudness) => updateSettings('mouse', { loudness })}
				/>
			</SelectSoundsDropdownEffectsTypeWrapper>

			<SelectSoundsDropdownEffectsTypeWrapper
				type='keyboardPresses'
				label='Keyboard Presses'
				initialActiveState={soundEffectsSettings.keyboard.enabled}
				onToggle={(enabled) => updateSettings('keyboard', { enabled })}
			>
				<SelectSoundsDropdownEffectsValue 
					label='Sound Set'
					options={SOUND_EFFECTS_CONFIG.sounds.keyboardSets}
					value={soundEffectsSettings.keyboard.soundSet}
					setValue={(soundSet) => updateSettings('keyboard', { soundSet })}
					audioPreview
				/>
				<SelectSoundsDropdownEffectsValue 
					label='Loudness'
					options={loudnessOptions}
					value={soundEffectsSettings.keyboard.loudness}
					setValue={(loudness) => updateSettings('keyboard', { loudness })}
				/>
			</SelectSoundsDropdownEffectsTypeWrapper>
     
			<SelectSoundsDropdownEffectsTypeWrapper
				type='sceneChanges'
				label='Scene Changes'        
				initialActiveState={soundEffectsSettings.sceneTransition.enabled}
				onToggle={(enabled) => updateSettings('sceneTransition', { enabled })}
			>
				<SelectSoundsDropdownEffectsValue 
					label='Sound Effect'
					options={SOUND_EFFECTS_CONFIG.sounds.sceneTransition}
					value={soundEffectsSettings.sceneTransition.soundEffect}
					setValue={(soundEffect) => updateSettings('sceneTransition', { soundEffect })}
					audioPreview
				/>
				<SelectSoundsDropdownEffectsValue 
					label='Loudness'
					options={loudnessOptions}
					value={soundEffectsSettings.sceneTransition.loudness}
					setValue={(loudness) => updateSettings('sceneTransition', { loudness })}
				/>
			</SelectSoundsDropdownEffectsTypeWrapper>
 
			<SelectSoundsDropdownEffectsTypeWrapper
				type='allZooms'
				label='All Zooms'       
				initialActiveState={soundEffectsSettings.zoom.enabled}
				onToggle={(enabled) => updateSettings('zoom', { enabled })}
			>
				<SelectSoundsDropdownEffectsValue 
					label='Loudness'
					options={loudnessOptions}
					value={soundEffectsSettings.zoom.loudness}
					setValue={(loudness) => updateSettings('zoom', { loudness })}					
				/>
			</SelectSoundsDropdownEffectsTypeWrapper>
		{/*
			<SelectSoundsDropdownEffectsTypeWrapper
				type='allScreenClips'
				label='All Screen Clips'        
				initialActiveState={soundEffectsSettings.screenClipTransition.enabled}
				onToggle={(enabled) => updateSettings('screenClipTransition', { enabled })}
			>
				<SelectSoundsDropdownEffectsValue 
					label='Loudness'
					options={loudnessOptions}
					value={soundEffectsSettings.screenClipTransition.loudness}
					setValue={(loudness) => updateSettings('screenClipTransition', { loudness })}
				/>
			</SelectSoundsDropdownEffectsTypeWrapper>
      */}
		</div>
	)
}

export default SelectSoundsDropdownEffects
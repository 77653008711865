import {TimelineClip} from './TimelineClip'
import {fetchLegacyVideoURL} from '../utils/recordings/legacyScreenRecordings/getLegacyMasterRecordingData'
import {getEventsForSoundEffects} from '../utils/recordings/screenRecordings/getEventsForSoundEffects'
import {fetchBasicVideo} from '../utils/basicVideos/fetchBasicVideo'
import {fetchDeviceVideoUrl} from '../utils/recordings/deviceRecordings/fetchDeviceVideoUrl'
import {calculateLegacyRecordingSilentAndActiveSegments} from '../utils/recordings/legacyScreenRecordings/calculateLegacyRecordingSilentAndActiveSegments'
import {calculateRecordingSilentAndActiveSegments} from '../utils/recordings/screenRecordings/calculateRecordingSilentAndActiveSegments'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import {addFreezeFrame} from './utils/addFreezeFrame'
import {addSkipSegment} from './utils/addSkipSegment'
import {removeQuietOrSkipSegment} from './utils/removeQuietOrSkipSegment'
import {updateSkipSegmentDuration} from './utils/updateSkipSegmentDuration'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import {calculateTimelineTimeFromVideoTime} from './utils/calculateTimelineTimeFromVideoTime'
import find from 'lodash/find'
import {fetchScreenRecordingURL} from '../utils/recordings/screenRecordings/getMasterRecordingData'
import {checkVideoUploadStatus} from './utils/checkVideoUploadStatus'
import { checkScreenRecordingAutoZoom } from '../actions/screenRecordings'
import store from '../store'
import {updateCursorOpacityForClip} from '../utils/recordings/screenRecordings/getCursorPositionAtTime'
import { getScreenRecordingChunks } from '../utils/recordings/screenRecordings/getScreenRecordingChunks'
import { calculateDefaultWebcamLayouts } from './utils/recordingSessionWebcamLayoutUtils'
import { getMasterRecordingCursorData } from '../utils/recordings/screenRecordings/getMasterRecordingCursorData'
import { calculateScreenRecordingDisplaySettings } from '../utils/recordings/screenRecordings/calculateScreenRecordingDisplaySettings'

/// Feb 23rd rename
//screenVideoDisplayType ---> displayMode can be window or desktop 


/**
  * 
  * Supports multiple video types:
  * - Basic videos (user uploaded videos-not screen recordings) (clip.isBasicVideo)
  * - Screen recordings (new RecordKit recordings) (clip.isScreenRecording)
  * - Legacy screen recordings
  * - Device (iPhone/iPad) recordings (clip.isDeviceRecording)
  * 
  * Key features:
  * - Segment management: Handles both recording segments (raw/untrimmed) and playback segments (trimmed)
  * - Playback control: Supports variable playback rates, seeking, and pause/play
  * - Special effects: Can add freeze frames and skip segments
  * - Upload handling: Manages video upload states with status polling
  * - Device frame support: Can display videos with optional device frames/colors
  * 
  * - recordingDuration: Actual video file duration
  * - duration: Timeline duration (after things like trims and playback rates are applied)
  * - clipPlaybackRate: Global playback rate applied to all segments
  * - recordingSegments: Original untrimmed segments
  * - segments: Trimmed segments with applied playback rate
*/



///New macrecorder clips will have sessionCaptureId
//Put sessionCaptureId on clip (doesnt change)
//put linkedClipId in metadata as thats easier to update undo/redo 
//chunks dont need to go into the prosemirror as we can just reload it

class VideoClip extends TimelineClip {

	constructor(options,scene,handleVideoClipLoaded,updateVideoClipSegments,videoWindowPadding) {
    super(options,scene)
		this.video = document.createElement('video'); 
    this.video.muted=false
    this.isBasicVideo = options.isBasicVideo
    this.isDeviceRecording = options.isDeviceRecording || false
    this.isUploadingVideo=options.isUploadingVideo || false
    this.videoId=options.videoId
    this.segments=options.segments || []
    this.recordingSegments = options.recordingSegments||[]
    this.hasSegments = this.segments.length
    this.hasRecordingSegments = this.recordingSegments.length
    this.isScreenRecording = options.isScreenRecording
    this.duration=options.duration 
    this.recordingDuration=options.recordingDuration
    this.videoWindowPadding = videoWindowPadding
   
    this.clipPlaybackRate = options.clipPlaybackRate || 1
   
    ///New stuff
    this.recordingChunks = options.recordingChunks || []
    this.sessionCaptureId = options.sessionCaptureId || null
        
    if(!this.isUploadingVideo){
      if(!this.isBasicVideo){
        this.captureId=options.captureId
        this.initialize(options.captureId)
      }else{
        if(this.videoId){
          this.initializeBasicVideo()
        } 
      }
    }

    //Legacy screen recordings
    if(!this.sessionCaptureId){
      if (this.metadata.label=='Google Chrome') {
        this.metadata.screenVideoApp='chrome'
      } else {
        this.metadata.screenVideoApp=''
      }
    }



    if(this.isDeviceRecording && !this.metadata.horizontalOffset){
      this.metadata.horizontalOffset=0
    }
    this.video.addEventListener('ended', this.onEnded.bind(this));
    this.ended = false;
    
    this._handleVideoClipLoaded=handleVideoClipLoaded
    this.updateVideoClipSegments=updateVideoClipSegments
    if(this.metadata.isMuted){
      this.video.muted = true
    }

    this.pollInterval = null 
    this.pollAttempts = 0 
    this.maxPollAttempts = 30
    if (this.isUploadingVideo) {
      this.startPollingUploadStatus()
    }
    this.playPromise = null
  }

  //// Sometimes basic videos can get stuck in uploading state
  //e.g if you upload a video and navigate away so lets poll for status and update if it has finished uploading
  async startPollingUploadStatus() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
    this.pollInterval = setInterval(async () => {
      try {
        // Stop polling if we're no longer uploading or hit max attempts
        if (!this.isUploadingVideo || this.pollAttempts >= this.maxPollAttempts) {
          this.stopPolling()
          return
       }
      const response = await checkVideoUploadStatus(this.videoId)
      if (response.status === 'complete') {
        await this.handleUploadCompleteFromPolling(response)
      } else if (response.status === 'failed') {
        console.log('upload failed')
      }
      this.pollAttempts++
     } catch (error) {
      console.error('Error polling upload status:', error)
    }
   }, 20000)
  }


  async getSoundEffects(mouseClicksEnabled,keyboardPressesEnabled){
    if(this.isScreenRecording){
      const effectsData = await getEventsForSoundEffects(this.captureId,mouseClicksEnabled,keyboardPressesEnabled)
      //filter out events that are in a skip or outside the trim 
      const filtered = effectsData.filter(event => {
        return this.segments.some(segment => {
          return (
            event.time >= segment.originalStart &&
            event.time <= segment.originalEnd &&
            segment.newDuration > 0
          )
        })
      })

      return filtered.map(({ time, ...rest }) => ({
        ...rest,
        startTime: calculateTimelineTimeFromVideoTime(time, this),
        sceneId: this.sceneId
      }))
    }
  }


  handleUploadCompleteFromPolling(videoObj){ //TODO make sure timeline saves after do this
    this.metadata.originalWidth = videoObj.original_width 
    this.metadata.originalHeight = videoObj.original_height
    this.metadata.displayWidth=videoObj.default_display_width
    this.metadata.semiTransparent = videoObj.semi_transparent
    this.metadata.originalFileName = videoObj.original_filename 
    this.finishUpload()
  }


  stopPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
      this.pollInterval = null
    }
  }

  updateIsMuted(value){
    this.video.muted=value
  }

 
  onEnded() {
    this.ended = true; // Set ended to true when video ends
  }

  async finishUpload(){
    await this.initializeBasicVideo()
  }

  async initializeBasicVideo() {
    const videoUrl = await fetchBasicVideo(`${this.videoId}.mp4`);
    if(videoUrl){
      this.videoUrl = videoUrl;
      this.video.src = videoUrl;
      this.video.preload = 'auto';
      this.video.playbackRate=this.clipPlaybackRate
      this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this)) 
    }
  }

  async initialize(captureId) {
    let videoUrl 
    if(this.isScreenRecording){
      videoUrl = await fetchScreenRecordingURL(captureId);
      this.updateChunks()
    }else if(this.isDeviceRecording){
      videoUrl = await fetchDeviceVideoUrl(captureId)
    }
    else{
      videoUrl = await fetchLegacyVideoURL(captureId);
    }
    this.videoUrl = videoUrl;
    this.video.src = videoUrl;
    this.video.preload = 'auto';
    this.video.playbackRate=this.clipPlaybackRate
    this.video.addEventListener('loadedmetadata', this.onMetadataLoaded.bind(this)) 
  }

  async calculateRecordingSegments(captureId){
    if(this.isBasicVideo || this.metadata.linkedClipId){ //dont add freeze frames for linked clips
      const segment={
        id: 0,
        originalDuration: this.recordingDuration,
        originalStart: 0,
        originalEnd:this.recordingDuration ,
        isQuiet: false,
        playbackRate:1,
        newStart:0,
        newEnd: this.recordingDuration,
        newDuration: this.recordingDuration,
      }
      const segments = [segment]
      this.recordingSegments=segments
      this.calculateTrimmedSegments()
    }else{
      let segments 
      if(this.isScreenRecording){
        segments = await calculateRecordingSilentAndActiveSegments(captureId,this.recordingDuration)
      }else{
        segments = await calculateLegacyRecordingSilentAndActiveSegments(captureId,this.recordingDuration,this.isDeviceRecording)
      }
      
      this.recordingSegments=segments
      this.calculateTrimmedSegments()
      this._handleVideoClipLoaded()
    }
  }

  updateClipPlaybackRate(playbackRate){
    this.clipPlaybackRate = playbackRate 
    this.video.playbackRate=this.clipPlaybackRate
    this.calculateTrimmedSegments()
  }

  updateSkipSegmentDuration(segmentId,newDuration,direction){
    const adjustedSegments =updateSkipSegmentDuration(this.recordingSegments,segmentId,newDuration,this.recordingDuration,direction)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }


  calculateTrimmedSegments(){ //apply trim and clip playback rate
    const isInitialLoad = this.segments.length==0
    this.segments = calculateTrimmedSegments(this.recordingSegments,this.metadata.trimStart,this.metadata.trimEnd,this.clipPlaybackRate)
    this.calculateDurationFromSegments()
    if(isInitialLoad){
       this.updateVideoClipSegments(this.id)
    }
    this._handleVideoClipLoaded()
    updateCursorOpacityForClip(this)
  }


  updateTrimValues(trimStartTime,trimEndTime){
    const oldTrimStart = this.metadata.trimStart
    this.metadata.trimStart=trimStartTime
    if(!this.isUploadingVideo && oldTrimStart!=trimStartTime){
      this.video.currentTime=trimStartTime
    }
    
    this.metadata.trimEnd=trimEndTime
    this.calculateTrimmedSegments()
    this.updateDisplayMode()
  }

  areLayoutsEqual(layout1, layout2){
    return layout1.length === layout2.length && layout1.every((layout, index) => layout.startTime === layout2[index].startTime && layout.duration === layout2[index].duration && layout.size === layout2[index].size && layout.position === layout2[index].position)
  }

  addSkipSegmentFromSkipSegmentMode(skipStartTime, skipEndTime) {
    let trimStart = this.metadata.trimStart
    let trimEnd = this.metadata.trimEnd

    //Is skip to start/end then trim instead of add a skip
    if(Number(skipStartTime.toFixed(2)) === Number(trimStart.toFixed(2))){
      const startTimeDelta = calculateTimelineTimeFromVideoTime(skipEndTime,this) - calculateTimelineTimeFromVideoTime(skipStartTime,this)
      trimStart = skipEndTime 
      this.updateTrimValues(trimStart,trimEnd)
      this.startTime +=startTimeDelta
      return
    }
    if(Number(skipEndTime.toFixed(2)) === Number(trimEnd.toFixed(2))){
      trimEnd = skipStartTime 
      this.updateTrimValues(trimStart,trimEnd)
      return
    }
    const skipDuration = skipEndTime - skipStartTime
    const adjustedSegments = addSkipSegment(
      this.recordingSegments,
      skipStartTime,
      this.clipPlaybackRate,
      skipDuration,
      false // Pass isExpanded=false to create it collapsed initially
      //true // Pass isExpanded=false to create it collapsed initially
    )    
    this.recordingSegments = adjustedSegments
    this.calculateTrimmedSegments()
  }
  

  addSkipSegment(skipTime){
    const adjustedSegments =addSkipSegment(this.recordingSegments,skipTime,this.clipPlaybackRate)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  addFreezeFrame(freezeTime){
    const adjustedSegments =addFreezeFrame(this.recordingSegments,freezeTime,this.clipPlaybackRate)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  removeFreeze(segmentId){
    const adjustedSegments = removeQuietOrSkipSegment(this.recordingSegments,segmentId,this.recordingDuration)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  removeSkip(segmentId){
    const adjustedSegments = removeQuietOrSkipSegment(this.recordingSegments,segmentId,this.recordingDuration)
    this.recordingSegments=adjustedSegments
    this.calculateTrimmedSegments()
  }

  toggleSkipSegment(segmentId,isExpanded){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    if(segment){
      segment.isExpanded = isExpanded 
      segment.newDuration = isExpanded?segment.originalDuration : 0
    }
    this.calculateTrimmedSegments()
  }

  expandSkipSegment(segmentId){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    if(segment){
      segment.isExpanded = false 
      segment.originalDuration = 0
      segment.newDuration = 0
    }
    this.calculateTrimmedSegments()
  }

  updateSegmentPlaybackRate(segmentId,playbackRate){
    const segment = this.recordingSegments.find(segment => segment.id === segmentId);
    const adjustedPlaybackRate = playbackRate/this.clipPlaybackRate
    if (segment) {
      segment.playbackRate = adjustedPlaybackRate
      segment.newDuration = segment.originalDuration / adjustedPlaybackRate;
    } else {
      console.warn(`Segment with ID ${segmentId} not found.`);
    }
    this.calculateTrimmedSegments()
  }
  
  calculateDurationFromSegments() {
    let totalDuration = 0;
    this.segments.forEach(segment => {
      totalDuration += segment.newDuration;
    });
    this.duration = totalDuration;
  }

  onMetadataLoaded() {
    this.recordingDuration = Math.max(this.duration,this.video.duration)
    if(!this.metadata.trimEnd){
      this.metadata.trimStart=0
      this.metadata.trimEnd=this.duration
    }else{
      //Fix for cartage bug check that the trim is not greater than the last segment
      if(this.hasSegments){
        if(this.metadata.trimEnd > this.segments[this.segments.length-1].originalEnd){
          this.metadata.trimEnd = this.segments[this.segments.length-1].originalEnd
          this._handleVideoClipLoaded()
        }
      }
    }
    this.video.currentTime = this.metadata.trimStart
    if(!this.hasSegments || !this.hasRecordingSegments){
      this.calculateRecordingSegments(this.captureId)
    }else{
      this._handleVideoClipLoaded()
    }
   
    if(this.isScreenRecording){
      store.dispatch(checkScreenRecordingAutoZoom(this.captureId))
    }
    this.isUploadingVideo=false 
  }

  async updateChunks() {
    if(!this.sessionCaptureId){
      return
    }
    if (this.recordingChunks?.length > 0 && (this.recordingChunks[0].inferredTitle || this.recordingChunks[0].inferredSubtitle)) {
      this.stopChunksPolling()
      this._handleVideoClipLoaded()
    } else if (!this.chunksPollingInterval) {
      this.startChunksPolling()
    }
  }

  async startChunksPolling() {
    this.chunksPollingAttempts = 0
    
    // Immediate first check
    try {
      this.recordingChunks = await getScreenRecordingChunks(this.captureId)
      if (this.recordingChunks.length > 0 && (this.recordingChunks[0].inferredTitle || this.recordingChunks[0].inferredSubtitle)) {
        this._handleVideoClipLoaded()
        return // Exit early if we already have the data
      }
    } catch (error) {
      console.error('Error in initial chunks poll:', error)
    }

    // Start interval polling if initial check didn't succeed
    this.chunksPollingInterval = setInterval(async () => {
        try {
            if (this.chunksPollingAttempts >= 30) {
                this.stopChunksPolling()
                return
            }
            this.recordingChunks = await getScreenRecordingChunks(this.captureId)
            if (this.recordingChunks.length > 0 && (this.recordingChunks[0].inferredTitle || this.recordingChunks[0].inferredSubtitle)) {
                this.stopChunksPolling()
                this._handleVideoClipLoaded()
            }
            this.chunksPollingAttempts++
        } catch (error) {
            this.stopChunksPolling()
        }
    }, 10000)
  }

  stopChunksPolling() {
    if (this.chunksPollingInterval) {
      clearInterval(this.chunksPollingInterval)
      this.chunksPollingInterval = null
    }
  }

  async playFromCurrentTime(currentTime) {
    if (!this.video || !this.video.src || this.ended) {
      return
    }

    if (currentTime >= this.startTime && currentTime < this.endTime) {
      const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
      this.video.currentTime = videoTime

      try {
        // If there's an existing play promise, wait for it
        if (this.playPromise) {
          await this.playPromise
        }

        // Start new play attempt
        this.playPromise = this.video.play()
        
        if (this.playPromise) {
          await this.playPromise
          this.playPromise = null
        }
      } catch (error) {
        // Only log if it's not an abort error
        if (!error.name === 'AbortError') {
          console.warn('Play interrupted:', error)
        }
        this.playPromise = null
      }
    }
  }



  async pause() {
    if (!this.isUploadingVideo) {
      try {
        if (this.playPromise) {
          await this.playPromise
        }
        this.video.pause()
      } catch (error) {
        console.warn('Error during pause:', error)
      }
      this.playPromise = null
    }
  }

  seek(currentTime) {
    this.ended = false
    if(!this.isUploadingVideo && this.video && this.video.src){
      if (currentTime >= this.startTime && currentTime < this.endTime) {
        const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
        this.video.currentTime = videoTime
     } else {
        this.video.pause(); // If the current time is outside the clip, pause it
      }
    }
  }

  ///colors and stuff
  setBackgroundColor(backgroundColorId){
    let backgroundColor
    if(!backgroundColorId){ //remove background
      backgroundColor = this.projectBackground
      this.metadata.isAutoBackgroundColor=true
    }else{
      backgroundColor = find(this.brandKit.backgrounds,{id:backgroundColorId})
      this.metadata.isAutoBackgroundColor=false
    }
    this.metadata.backgroundColor=backgroundColor
  }

  updateDisplayMode(){
    if(this.isScreenRecording && this.sessionCaptureId){
      calculateScreenRecordingDisplaySettings({
        recordingChunks: this.recordingChunks,
        trimStart: this.metadata.trimStart,
        trimEnd: this.metadata.trimEnd,
        recordingDuration: this.recordingDuration,
        currentDisplayMode: this.metadata.displayMode,
        currentWebcamLayouts: this.metadata.webcamLayouts,
        captureId: this.captureId
      }).then((options)=>{

        this.metadata.displayMode = options.displayMode
        this.metadata.allowWindowMode = options.allowWindowMode
        this.metadata.screenVideoApp = options.screenVideoApp
      // this.metadata.deviceFrame = options.deviceFrame
        this.metadata.screenVideoDisplayPosition = options.displayPosition
        this.metadata.webcamLayouts = options.webcamLayouts
      })

      /////TODO when we switch the webcam layout to using video time we will calc this once on load/insert clip
      //and there will be no need to recalculate it
      //(move to calculateScreenRecordingDisplaySettings)
    }
  }


  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      captureId:this.captureId,
      sessionCaptureId:this.sessionCaptureId,
      isDeviceRecording:this.isDeviceRecording,
      isScreenRecording:this.isScreenRecording,
      isUploadingVideo:this.isUploadingVideo,
      videoId:this.videoId,
      fileName:this.fileName,
      isBasicVideo:this.isBasicVideo,
      startTime:this._startTime,
      absoluteStartTime:this.startTime,
      duration:this.duration,
      recordingDuration:this.recordingDuration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate,
      recordingChunks: this.recordingChunks,
    };
    return json
  }

  destroy() {
    this.stopPolling()
    this.stopChunksPolling() // Add this line to clean up chunks polling
    if (!this.video.paused) {
      this.video.pause();
    }
    this.video.removeEventListener('playing', this.onPlaying);
    this.video.removeEventListener('ended', this.onEnded);
    this.video.removeEventListener('loadedmetadata', this.onMetadataLoaded);
    this.video.src = '';
    this.video.load(); // Forces the release of the video buffer
    this.video = null;
    this.ended = false;
    this.captureId = null;
    this.playPromise = null
  }

}

export { VideoClip }

 //example fix for pablo thing
    //  if(this.videoId=='23891685'){
    //   this.isUploadingVideo=false
    //   this.metadata.originalHeight = 2160
    //   this.metadata.originalWidth=3456
    //   this.metadata.displayWidth=1440
    //   this.metadata.trimStart = 0 
    //   this.metadata.trimEnd=14.333333
    // }
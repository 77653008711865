
const recordingFiles= [
    { "name": "keystrokes-0.json", "type": "application/json" },
	{ "name": "mouseclicks-0.json", "type": "application/json" },
	{ "name": "mousemoves-0.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "channel-1-display-0.mp4", "type": "video/mp4" }
]


const deviceFiles= [
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "recordKit.json", "type": "application/json" },
	{ "name": "deviceRecording.mov", "type": "video/mp4" }
  ]

const screenRecordingFiles= [
	// { "name": "log.json", "type": "application/json" },
	// { "name": "recording.input-events.json", "type": "application/json" },
	// { "name": "metadata.json", "type": "application/json" },
	// { "name": "recording.mp4", "type": "video/mp4" }
	{ "name": "recording.top-windows.json", "type": "application/json" },
	{ "name": "chunks.json", "type": "application/json" },
	{ "name": "sessionMetadata.json", "type": "application/json" },
	{ "name": "recording.input-events.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
]


const webcamFiles = [
	{ "name": "transcript.json", "s3Name": "transcript.json", "type": "application/json", "required": true },
	// { "name": "faceBox.json", "s3Name": "faceBox.json", "type": "application/json", "required": false },
	{ "name": "recording.webm", "s3Name": "recording_clientReady.webm", "type": "video/webm", "required": true },
	{ "name": "audio_normalized.mp3", "s3Name": "audio_normalized.mp3", "type": "audio/mpeg", "required": true },
];


export function setFilesToBeDownloaded(captureId,isDevice,isScreenRecording,isWebcam){
	return (dispatch) => {
		let files = recordingFiles 
		if(isDevice){
			files=deviceFiles
		}
		if(isScreenRecording){
			files=screenRecordingFiles
		}
		if(isWebcam){
			files=webcamFiles
		}
		dispatch({ type: 'SET_FILES_TO_DOWNLOAD', captureId,files})
	}
}


export function handleDownloadFileSucess(captureId,fileName){
	return (dispatch) => {
		dispatch({ type: 'DOWNLOAD_SUCCESS', captureId,fileName})
	}
}


export function handleImageDownloadSucess(imageId){
	return (dispatch) => {
		dispatch({ type: 'IMAGE_DOWNLOAD_SUCCESS', imageId})
	}
}

export function handleBasicVideoDownloadSucess(fileId){
	return (dispatch) => {
		dispatch({ type: 'BASIC_VIDEO_DOWNLOAD_SUCCESS', fileId})
	}
}


export function handleMusicDownloadSucess(trackId){
	return (dispatch) => {
		dispatch({ type: 'MUSIC_DOWNLOAD_SUCCESS', trackId})
	}
}
import {randomID} from '../randomID'
import {getCurrentUserId} from '../getCurrentUserId'
import {createProject} from '../../actions/projects'
import store from '../../store'
import {getDefaultBackground} from '../brands/getDefaultBackground'
import {openProject} from './openProject'


export async function createNewProject(history,collectionId,overrideProjectId,shouldOpenProject=true) {
  const nowDate=new Date().toISOString()
  const projectId = overrideProjectId || `${randomID()}`
  const createdBy=getCurrentUserId()
  const projectName = `Untitled`
  const background = getDefaultBackground()

  let is_private = true 
  if(collectionId){
    is_private = false 
  }

  let project={
    id:projectId,
    created_at:nowDate,
    updated_at:nowDate,
    created_by:createdBy,
    updated_by:createdBy,
    name:projectName,
    timeline:{activeVoice:'1',backgroundMusicTrack:null,clips:[]},
    archived_at:null,
    duration:0,
    default_motion_style:'smooth',
    background:background.id,
    is_private:is_private
  }

  store.dispatch(createProject(project,collectionId))
  if(shouldOpenProject){
    openProject(projectId,history)
  }

}
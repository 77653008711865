import React, { useState } from 'react';
import LibraryPageHeaderGalleryDisplay from './headers/LibraryPageHeaderGalleryDisplay';
import LibraryPageHeaderSort from './headers/LibraryPageHeaderSort';
import LibraryProjectGalleryGrid from './LibraryProjectGalleryGrid';
import LibraryProjectGalleryList from './LibraryProjectGalleryList';

const LEFT_MARGIN = 56; // Adjust the left margin as needed
const RIGHT_MARGIN = 56; // Adjust the right margin as needed

function LibraryHomePageAllVideos({ containerWidth,projects,sortOption,handleSortChange,LibraryHomePageAllVideos,displayMode }) {
  const adjustedContainerWidth = containerWidth - LEFT_MARGIN - RIGHT_MARGIN + 8 + 8;
  return (
    <>
    {/*
      <div className='libraryPage-sectionHeader'>
        <div className='libraryPage-sectionHeader-label'>
          All Videos
        </div>
        <div className='libraryPage-sectionHeader-rightContainer'>
          <LibraryPageHeaderGalleryDisplay 
            galleryDisplay={galleryDisplay} 
            setGalleryDisplay={setGalleryDisplay} // Pass the setter function
          />                        
          <div className='libraryPage-header-smallSpacer'/>
          <LibraryPageHeaderSort 
          sortOption={sortOption}
          handleSortChange={handleSortChange}
          />
        </div>
      </div>      */}  


      
      <div className='libraryPage-allVideosContainer'>



        {displayMode === 'grid' && 
          <LibraryProjectGalleryGrid 
            projects={projects}
            containerWidth={adjustedContainerWidth} 
          />
        }
        {displayMode === 'list' && 
          <LibraryProjectGalleryList 
            projects={projects}             
          />
        }
      </div>
    </>
  );
}

export default LibraryHomePageAllVideos;

const initialState = {
  fileStatuses: {} // { captureId: { transcript: true, facebox: true } }
};

export default function webcamFiles(state = initialState, action) {
  switch (action.type) {
    case 'WEBCAM_FILE_UPDATE':
      const { captureId, fileType } = action.payload;
      return {
        ...state,
        fileStatuses: {
          ...state.fileStatuses,
          [captureId]: {
            ...(state.fileStatuses[captureId] || {}),
            [fileType]: true
          }
        }
      };
    default:
      return state;
  }
}

import abbreviations from './abbreviations'
import editorSchema from '../schema/transcriptSchema'
import {randomID} from '../../../utils/randomID'


export function	handlePaste(view, event, slice){
	let text = (event.clipboardData || window.clipboardData).getData('text/plain');
	if (text) {
		event.preventDefault();
		const { state, dispatch } = view;
		const { selection } = state;
		let { $from,$to } = selection;
		let parentNode = $from.node($from.depth - 1);
	
		const isEmptyTranscriptChunk = parentNode.type.name === 'transcriptChunk' && !parentNode.textContent.trim();
		const atEndOfTranscriptChunk = $to.pos === $from.end($from.depth);

		if (isEmptyTranscriptChunk || atEndOfTranscriptChunk) {				
			let processedText = text
			Object.entries(abbreviations).forEach(([abbr, placeholder]) => {
				const regex = new RegExp(abbr, "g");
				processedText = processedText.replace(regex, placeholder);
			});

			processedText = processedText.replace(/(\d+)\.\s+/g, (match, number) => `PLACEHOLDER_NUMBERED_LIST_${number}`);

			const processedSentences = processedText
				.split(/(?<=[.!?])\s+(?=[A-Z"“])/g)  // Split on punctuation followed by a space and an uppercase letter or quote
				.filter(sentence => sentence.trim().length > 0);

			const sentences = processedSentences.map(sentence => {
				let reverted = sentence;
				Object.entries(abbreviations).forEach(([abbr, placeholder]) => {
					const regex = new RegExp(placeholder, "g")
					reverted = reverted.replace(regex, abbr.replace(/\\./g, '.'))
				})
				reverted = reverted.replace(/PLACEHOLDER_NUMBERED_LIST_(\d+)/g, "$1. ");
				return reverted.trim(); // Trim each sentence
			})
	
		const firstSentence = sentences.shift().trim(); // Extract the first sentence
		// Prepare to replace the content of the current empty transcriptChunk with the first sentence
		let tr = state.tr;
		if (!selection.empty) {
			tr = tr.deleteSelection()
			$from = tr.selection.$from
			$to = tr.selection.$to
		}
	
		const insertPos = $from.pos - $from.parentOffset -1; 
		const endPos = insertPos + $from.parent.nodeSize-1 ; 
		// Replace the current empty transcriptChunk content with the first sentence
		parentNode = $from.node($from.depth - 1);
		const existingTextContent = parentNode.textContent;
		const paragraphNode = editorSchema.nodes.paragraph.create({}, editorSchema.text(existingTextContent ? `${existingTextContent.trim()} ${firstSentence.trim()}` : firstSentence.trim()));
		//const paragraphNode = editorSchema.nodes.paragraph.create({}, editorSchema.text(`${existingTextContent} ${firstSentence}`));
		tr.replaceRangeWith(insertPos, endPos, paragraphNode)
		const belowClipId=parentNode.attrs.clipId 
		let lastClipId = belowClipId
		let nextInsertPos = tr.mapping.map(endPos+2)
		sentences.forEach(sentence => {
			const clipId = randomID()
			const sentenceText = sentence.trim();
			const textNode = editorSchema.text(sentenceText);
			const paragraphNodeForSentence = editorSchema.nodes.paragraph.create({}, textNode);
			const transcriptChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({clipId:clipId}, paragraphNodeForSentence);			
			lastClipId=clipId
			if (transcriptChunkNode) {
				tr = tr.insert(nextInsertPos, transcriptChunkNode);
				nextInsertPos += transcriptChunkNode.nodeSize; // Adjust position for the next insert
			}
		})
		dispatch(tr);
		return true;
	}
	}
	return false;
}
import React, { useMemo, useState, useCallback } from 'react';
import TranscriptPanelSceneHeaderUI from './TranscriptPanelSceneHeaderUI';

const TranscriptPanelSceneHeaderGutter = ({transcriptSceneHeaders,scenes,mergeScene}) => {

  const gutterHeight = useMemo(() => {
    return Math.max(...transcriptSceneHeaders.map(header => header.dimensions.top), 0) + 20;
  }, [transcriptSceneHeaders]);



  return (
    <>    
      <div style={{height: `${gutterHeight + 120}px`}} className='editor-transcriptPanel-sceneHeaderGutter'>
        {transcriptSceneHeaders.map((header, index) => {
          const scene = scenes.find(s => s.id === header.sceneId);
          const sceneDuration = scene ? scene.duration : null;
          const isEmpty = scene && scene.clips ? scene.clips.length === 0 : true;
          const isFirstHeader = index === 0;
          const isOnlyHeader = transcriptSceneHeaders.length === 1;
          const isLastScene = index === transcriptSceneHeaders.length - 1;

          if(header.sceneTitle != 'Default title'){
            return(
              <TranscriptPanelSceneHeaderUI
                key={`gutter_${header.sceneId}`}              
                yPos={header.dimensions.top + 17 + 93}              
                sceneTitle={header.sceneTitle}
                sceneDuration={sceneDuration}  
                sceneId={header.sceneId}     
                mergeScene={mergeScene}
                isFirstHeader={isFirstHeader}
                isOnlyHeader={isOnlyHeader} 
                isEmpty={isEmpty}
                isLastScene={isLastScene}
              />
            )
          }
        }
        )}
      </div>
    </>
  );
};

export default TranscriptPanelSceneHeaderGutter;
// getSkipSegmentsForClip.js
export const getSkipSegmentsForClip = (clip) => {
  // Extract skip segments from the clip that are not expanded
  const skipSegments = clip.segments
    .filter(segment => segment.isSkipSegment === true && segment.isExpanded === false)
    .map(segment => ({
      start: segment.originalStart,
      end: segment.originalEnd
    }));
  
  // console.log('clip.segments');
  // console.log(clip.segments);
  // console.log(`Found ${skipSegments.length} skip segments in clip`);
  return skipSegments;
};
import React, { useState, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import EditorNewScreenClipPopoverSelectWindow from './EditorNewScreenClipPopoverSelectWindow'
import EditorNewScreenClipPopoverPhoneSetup from './EditorNewScreenClipPopoverPhoneSetup'
import EditorNewScreenClipPopoverPhoneRecord from './EditorNewScreenClipPopoverPhoneRecord'
import {getOrgDomain} from '../../../../utils/getOrgDomain'

const EditorNewScreenClipPopover = ({windowList, closeModal, devicesList }) => {
  
  const deviceFound = devicesList && devicesList.length > 0    

  return (
    <React.Fragment>
      <div className='editorPopover-header'>
        <div className='editorPopover-header-tabs'>
          
          <button data-state={"active"} className='editorPopover-header-tabs-tab'>
            <div className='editorPopover-header-tabs-tab--iPhone-recordIconContainer'>
              {!deviceFound && 
                <div className='editorPopover-header-tabs-tab--iPhone-recordIcon editorPopover-header-tabs-tab--iPhone-recordIcon--searching'>
                  <div className='editorPopover-header-tabs-tab--iPhone-recordIcon--searching-spinner' />                
                </div>
              }              
              {deviceFound && 
                <div className='editorPopover-header-tabs-tab--iPhone-recordIcon editorPopover-header-tabs-tab--iPhone-recordIcon--active' />                
              }
            </div>
            
              <div className='editorPopover-header-tabs-tab-label'>
                iPhone<span className='editorPopover-header-tabs-tab-label-sub'>/</span>iPad
              </div>
                        
          </button>
        </div>      
      </div>      

      {!deviceFound && 
        <EditorNewScreenClipPopoverPhoneSetup 
          includeiPadLabel={true}
        />
      }

      {deviceFound && 
        <EditorNewScreenClipPopoverPhoneRecord 
          includeiPadLabel={true}
          closeModal={closeModal}
          devicesList={devicesList}
        />
      }

    </React.Fragment>
  );
};

export default EditorNewScreenClipPopover;
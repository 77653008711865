import {TimelineClip} from './TimelineClip'
import {calculateWordsArrayForTextSlide} from './utils/calculateWordsArrayForTextSlide'
import find from 'lodash/find'
import {calculateAnimationDurationsForClip} from '../three/utils/animations/calculateTextAnimationDurationByGrouping'
import { getFontForTextStyle } from '../utils/brands/getFontForTextStyle'

/**
 * Legacy text slides- we now use SlideClip
 * Text Styles
  * title
  * heading1
  * heading2
  * body
*/

class TextSlideClip extends TimelineClip {


  constructor(options,scene) {
    super(options,scene)


    this.wordsArray = options.wordsArray || []
    this.metadata.textStyle = options.metadata.textStyle || 'title'
    const font = getFontForTextStyle(this.metadata.textStyle)

    this.metadata.fontFamily=font.fontFamily
    this.metadata.fontWeight=font.fontWeight
    this.metadata.fontSize=font.fontSize
    this.metadata.lineHeight = font.lineHeight
    this.metadata.letterSpacing = font.letterSpacing

  }

  destroy() {
    this.wordsArray = [];
    this.metadata = {};
  }

  async initialize(clip) {
    const wordsArray = await calculateWordsArrayForTextSlide(clip)
    this.wordsArray = wordsArray
    this.calculateMinSlideDuration()
  }


  updateProjectDefaultMotionStyle(motionStyle){
    if(this.metadata.isAutoMotionStyle){
      this.metadata.motionStyle=motionStyle
    }
  }

  calculateMinSlideDuration(){ //do this after have calced words array
    const durations=calculateAnimationDurationsForClip(this)
    const {startDuration,endDuration}=durations
    this.startTranstionDuration = startDuration
    this.endTranstionDuration = endDuration
    this.minDuration = startDuration+endDuration
  }

  toJSON() {
    const clipJSON =  {
      id: this.id,
      type:this.type,
      startTime:this._startTime, //time relative to the scene
      duration:this.duration,
      metadata:this.metadata,
      wordsArray:this.wordsArray,
      zIndex:this.zIndex
    };
    return clipJSON
  }

  
}


export { TextSlideClip }

import {fetch,batchedFetch,post,put,postCloudinary} from '../api'
import axios from 'axios'
import {downloadMissingScreenRecordings, downloadScreenRecordingsForCaptureId} from '../utils/assets/downloadMissingScreenRecordings'
import * as Sentry from '@sentry/electron/renderer'

export function fetchScreenRecordings(){
	return (dispatch) => fetch(`/screen-recordings`)
	.then((response) => {
		dispatch({ type: 'FETCH_SCREEN_RECORDINGS_SUCCESS', response })
		downloadMissingScreenRecordings(response)
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function fetchSingleScreenRecording(captureId){
	return (dispatch) => fetch(`/screen-recordings/${captureId}`)
	.then((response) => {
		dispatch({ type: 'FETCH_SINGLE_SCREEN_RECORDING_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function checkScreenRecordingAutoZoom(captureId){
	return (dispatch, getState) => {
		// Check if recording already has zoom boxes
		const state = getState();
		const recording = state.screenRecordings?.find(r => r.capture_id === captureId.toString());
		if (recording?.ai_zoom_boxes) {
			return Promise.resolve({ status: 200 });
		}

		return post(`/screen-recordings/${captureId}/zoomBox`)
			.then((response) => {
				if (response.capture_id) {
					dispatch({ type: 'TRIGGER_AUTO_ZOOM_SUCCESS', response })
				}
				return response
			})
			.catch((error) => {
				console.log(error)
				return error
			})
	}
}

const files= [
	{ "name": "recording.top-windows.json", "type": "application/json" },
	{ "name": "sessionMetadata.json", "type": "application/json" },
	{ "name": "recording.input-events.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" }
]

export function syncNewScreenRecording(captureId) {
	const requestBody = {capture_id: captureId}
	return async (dispatch) => {
		let endpoint = '/screen-recordings'
		let basePath = `screenRecordings/${captureId}`
		let videoFileName = 'recording.mp4';
		let videoMimeType = 'video/mp4';
		let currentFile=''
		try {
			//console.log(`Starting sync for recording`, captureId);
			const metadataPath = `${basePath}/metadata.json`;
			const metadataContent = await ipcRenderer.invoke('read-file', metadataPath);
			const metadata = JSON.parse(metadataContent);
			requestBody.duration = metadata.durationSeconds
			requestBody.width = metadata.dimensions.width
			requestBody.height = metadata.dimensions.height
			requestBody.recordingChunks = await ipcRenderer.invoke('read-file', `${basePath}/chunks.json`);
			const recordingChunks = JSON.parse(requestBody.recordingChunks)?.chunks
			requestBody.appName = recordingChunks?.[0]?.appName || metadata.appName
			requestBody.displayName = recordingChunks?.[0]?.title || metadata.displayName
			requestBody.topWindows = await ipcRenderer.invoke('read-file', `${basePath}/recording.top-windows.json`);
			requestBody.pausesResumes = await ipcRenderer.invoke('read-file', `${basePath}/pauses-resumes.json`);
			const response = await post(endpoint, requestBody);

			currentFile = videoFileName;
			//upload video
			// const videoPath = `${basePath}/${videoFileName}`;
			// const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
			// const videoUploadURL = response.uploadURLs.find(url => url.fileName.includes(videoFileName)).uploadURL;
			// await axios.put(videoUploadURL, videoBuffer, { headers: { 'Content-Type': videoMimeType } });
			// console.log('Upload successful for video', videoFileName);

			// Upload each JSON file (and any other files)
			for (const file of files) {
				currentFile = file.name;
				const filePath = `${basePath}/${file.name}`;
				const fileContent = await ipcRenderer.invoke('read-file', filePath);
				const uploadURL = response.uploadURLs.find(url => url.fileName === file.name).uploadURL;
				await axios.put(uploadURL, fileContent, { headers: { 'Content-Type': file.type } });
				//console.log('Upload successful for file:', file.name);
			}
			// // Update the recording status to complete
			// const updateResponse = await put(`/screen-recordings/${captureId}/complete`);
			// console.log('Recording status updated to complete');
			dispatch(fetchScreenRecordings())
		} catch (error) {
			console.error('Error during screen recording sync', error);			
			Sentry.withScope(scope => {
				scope.setExtra("RequestBody", requestBody);
				scope.setExtra("CaptureID", captureId);
				scope.setTag("error-type", "SyncNewRecordingError");
				scope.setLevel("error");
				
				if (currentFile) {
					scope.setExtra("FailedFile", currentFile);
					scope.setExtra("FilePath", `${basePath}/${currentFile}`);
					scope.setTag("Step", "FileUpload");
				}
			});
			
			if (currentFile) {
		
				try {
					await put(`/screen-recordings/${captureId}/sync-failed`, { 
						failedFile: currentFile 
					});
				} catch (updateError) {
					console.error('Failed to update recording status', updateError);
				}
			}
		}
	}
}

export function fetchScreenRecordingsForCaptureId(captureId){
	return (dispatch) => fetch(`/screen-recordings`)
	.then((response) => {
		dispatch({ type: 'FETCH_SCREEN_RECORDINGS_SUCCESS', response })
		downloadScreenRecordingsForCaptureId(captureId)
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}








import React, { useState, useMemo } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import EditorGenerationsDialogList from './EditorGenerationsDialogList';
import EditorGenerationsDialogVideoPlayer from './EditorGenerationsDialogVideoPlayer'
import Icon from '../../../misc/Icon';

const INITIAL_FIELDS = {
  headline: '',
  subtitle: '',
  enableSelectBGMusic: false,
  enableSelectBGColor: false,
  previewVideo:null
};

// Create a forwarded ref wrapper for the children
const ForwardedTrigger = React.forwardRef((props, ref) => {
  return <div ref={ref} {...props} />;
});

function EditorGenerationsDialog({ children, handleCloseDialog, showDialog,collabGenerations,project,updateCollabPageSettings }) {
  
  const [activeGeneration, setActiveGeneration] = useState(collabGenerations[0]);

  const handleOpenDialog = async () => {
    // const fetchedAssets = await getAssetLibrary();    
  };

  const handleGenerationClick = (generation) => {
    setActiveGeneration(generation);
  };

  const closeDialog = () => {
    handleCloseDialog();
  };

  const generationCount = collabGenerations.length

  const handleDownloadVideo = () => {
    //index with the oldest being 0
    const index = collabGenerations.length - collabGenerations.findIndex(g => g.id === activeGeneration.id);
    const filename = `${project.name} (#${index}).mp4`;
    window.ipcRenderer.send('download-exported-video', {
      url: activeGeneration.result_url,
      filename
    });
  }

  let previewVideo
  if(project && project.collab_page_settings){
    previewVideo=project.collab_page_settings.previewVideo
  }

  const handleUpdatePreviewVideo = (value) => {
    const newFields={ 
      ...INITIAL_FIELDS,
      ...project.collab_page_settings,
      previewVideo:value
    }
    updateCollabPageSettings(newFields);
  };

  return (
    <Dialog.Root
      open={showDialog}
      onOpenChange={(open) => {
        if (!open) {
          closeDialog();
        }
      }}
    >
      <Dialog.Trigger asChild onClick={handleOpenDialog}>
        <ForwardedTrigger>
          {children}
        </ForwardedTrigger>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="editor-generationsDialog-overlay" />
        <Dialog.Content className="editor-generationsDialog forceDarkTheme">
          <div className="editor-generationsDialog-leftPanel">
            <div className="editor-generationsDialog-title">
              <div className="editor-generationsDialog-title-iconContainer">
                <Icon name='generations' />
              </div>
              <div className="editor-generationsDialog-title-label">
                Generations
              </div>          
              <div className="editor-generationsDialog-title-count">
                {generationCount}
              </div>              
            </div>
            <EditorGenerationsDialogList 
              generations={collabGenerations}
              activeGeneration={activeGeneration}
              onGenerationClick={handleGenerationClick}
              previewVideo={previewVideo}
              handleUpdatePreviewVideo={handleUpdatePreviewVideo}
            />            
          </div>
          <div className="editor-generationsDialog-rightPanel">
            <EditorGenerationsDialogVideoPlayer 
              handleDownloadVideo={handleDownloadVideo}
              activeGeneration={activeGeneration}
              previewVideo={previewVideo}
              handleUpdatePreviewVideo={handleUpdatePreviewVideo}
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default EditorGenerationsDialog;
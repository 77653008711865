import {fetch} from '../api'


const WEB_APP_VERSION='2.0.0'

export function checkWebVersion(){
  return (dispatch) => fetch(`/version-check?version=${WEB_APP_VERSION}`)
  .then((response) => {
   // console.log('web version check response',response)
   	 if (response.shouldRefresh) {
        window.location.reload();
      }
   return
  })
  .catch((error) => {
    
  })
}



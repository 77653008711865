import { Plugin } from 'prosemirror-state'
import {randomID} from '../../../utils/randomID'

export const enforceStandardFinalGroupPlugin = () => {
  return new Plugin({
    appendTransaction: (transactions, oldState, newState) => {
      // Skip if no changes
      if (!transactions.some(tr => tr.docChanged)) return null;
      
      const doc = newState.doc;
      
      // Find the last transcriptGroup
      let lastGroup = null;
      doc.descendants((node, pos) => {
        if (node.type.name === 'transcriptGroup') {
          lastGroup = { node, pos };
        }
      });
      
      if (!lastGroup || lastGroup.node.attrs.groupType !== 'standard') {
        // Create a transaction to append a new standard group
        const tr = newState.tr;
        const schema = newState.schema;
        

        // Create a new standard transcriptGroup
        const newGroup = schema.nodes.transcriptGroup.createAndFill({
          groupId: randomID(),
          groupType: 'standard'
        });
        
        // Append it to the end of the document
        tr.insert(doc.content.size, newGroup);
        return tr;
      }
      
      return null;
    }
  });
};
import {getLegacyMasterRecordingData} from './getLegacyMasterRecordingData'
import { getNormalizedLegacyCursorData } from './getNormalizedLegacyCursorData';
import { identifySlowLegacyCursorPeriods } from './identifySlowLegacyCursorPeriods'
import {identifyLegacyStillVideoPeriods} from './identifyLegacyStillVideoPeriods'
import {getLegacyKeystrokeData} from './getLegacyKeystrokeData'
import {getNormalizedLegacyKeystrokeData} from './getNormalizedLegacyKeystrokeData'
import {getOrgId} from '../../../utils/getOrgId'

const MIN_CURSOR_QUIET_PERIOD=1
const MIN_OVERLAP_PERIOD= 1



const noise = `0.0001`
//const deviceNoiseThreshold=`0.0005`
const deviceNoiseThreshold=`0.0011`

//clipPlaybackRate is applied to the whole clip as a multiple

async function calculateLegacyRecordingSilentAndActiveSegments(captureId,totalDuration,isDeviceRecording) {

	let modifiedOverlappingPeriods 

	if(isDeviceRecording){
		const stillVideoPeriods = await identifyLegacyStillVideoPeriods(captureId,isDeviceRecording,deviceNoiseThreshold)
		modifiedOverlappingPeriods=stillVideoPeriods
	}else{
		const masterRecordingData = await getLegacyMasterRecordingData(null, captureId);
		const normalizedCursorData = await getNormalizedLegacyCursorData(
				masterRecordingData.cursorData,
				masterRecordingData.recordingVideoProcessStartTimeMs,
				masterRecordingData.recordingVideoProcessEndTimeMs,
				masterRecordingData.recordingWidth,
				masterRecordingData.recordingHeight,
				masterRecordingData.recordingXOffset,
				masterRecordingData.recordingYOffset
			)
		let slowCursorPeriods = []
		const orgId=getOrgId()
		if(orgId !=='3' && orgId != '60'){ //TEMP DISABLE FREEZE ZONES FOR CREO
			slowCursorPeriods = await identifySlowLegacyCursorPeriods(normalizedCursorData,MIN_CURSOR_QUIET_PERIOD);	
		}

		const stillVideoPeriods = await identifyLegacyStillVideoPeriods(captureId,isDeviceRecording,noise)
	 	let overlappingPeriods = [];
		slowCursorPeriods.forEach(scp => {
			stillVideoPeriods.forEach(svp => {
				if (scp.start < svp.end && svp.start < scp.end) {
					// Create a new period that is within both
					const start = Math.max(scp.start, svp.start);
					const end = Math.min(scp.end, svp.end);

					// Ensure the period is at least 0.5 seconds long
					if ((end - start) >= MIN_OVERLAP_PERIOD) {
						overlappingPeriods.push({ start, end });
					}
				}
			});
		});
		const keystrokeData = await getLegacyKeystrokeData(captureId)
		const normalizedKeystrokeData = await getNormalizedLegacyKeystrokeData(keystrokeData,masterRecordingData.recordingVideoProcessStartTimeMs,masterRecordingData.recordingVideoProcessEndTimeMs)
	  modifiedOverlappingPeriods = overlappingPeriods.filter(period => {
	      return !normalizedKeystrokeData.some(keystroke => {
	          return keystroke.time >= period.start && keystroke.time <= period.end;
	      });
	  });

	}
	let segments = [];
	let lastEnd = 0;

		modifiedOverlappingPeriods.forEach(period => {
			if (lastEnd < period.start) {
				// Add active segment before the quiet period
				segments.push({
					id: segments.length,
					originalDuration: period.start - lastEnd,
					originalStart: lastEnd,
					originalEnd: period.start,
					isQuiet: false,
					playbackRate:1,

				});
			}
			// Add quiet segment
			segments.push({
				id: segments.length,
				originalDuration: period.end - period.start,
				originalStart: period.start,
				originalEnd: period.end,
				isQuiet: true,
				playbackRate:1,

			});
			lastEnd = period.end;
		});

		if (lastEnd < totalDuration) {
			// Add remaining active segment
			segments.push({
				id: segments.length,
				originalStart:lastEnd,
				originalDuration: totalDuration - lastEnd,
				originalEnd: totalDuration,
				isQuiet: false,
				playbackRate:1,

			});
		}

	return segments

}




export { calculateLegacyRecordingSilentAndActiveSegments }



import React, { useState, Suspense, useRef, useEffect} from 'react'
import ThreeCanvas from '../../../three/ThreeCanvas'
import Camera from '../../../three/Camera';
import { Canvas } from '@react-three/fiber'
// import { Perf } from 'r3f-perf'
import SlideEditor from '../../../three/slide/slideEditor/SlideEditor'
import CaptionsGroupGhost from '../../../three/captions/CaptionsGroupGhost'
import EditTrimVideoPlayer from './EditTrimVideoPlayer'
import { computeCursorDataForLegacyClips } from '../../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import { computeCursorDataForClips } from '../../../utils/recordings/screenRecordings/getCursorPositionAtTime'
import {getBackgroundForId} from '../../../utils/brands/getBackgroundForId'
import * as THREE from 'three';


const VARIABLE_VALUES={
	profile_pic:'http://res.cloudinary.com/yarn/image/upload/v1714086693/image_uploads/1798620531.png'
}


const EditorCanvasContainer = ({
		projectBackgroundId, 
		slideClips,
		textSlideClips,
		chartClips,
		videoClips,
		zoomClips,
		imageClips,		
		currentTime,
		canvasWidth,
		canvasHeight,
		canvasX,
		canvasY,
		isPlaying,
		handleSetActivePanelClip,
		updateMeshWidth,
		updateMeshHeight,
		zoomBox,
		activePanelClip,
		trimPreviewPlaying,
		trimPreviewProgress,
		onTrimPreviewPlayPause,
		onTrimPreviewSeekChange,
		trimMode,
		isDraggingProgressMarker,
		testKey,
		selectSlideElement	,
		updateSlideTextElementText	,
		updateSlideElementMetadata,
		clearSlideElementsSelection,
		setSlideEditorSelection,
		selectedLayoutGroup,
		updateSlideElementField,
		setFocusedSlideElement,
		focusedSlideElement,
		audioClips,
		duplicateSlideItems,
		openSlideEditorContextMenu,
		handleSetSlideEditorDragElement,
		slideEditorDragElementId,
		slideEditorDragLayoutGroupId,
		updateSlideElementTextSelectionInfo,
		handleSetSlideEditorResizeElement,
		slideEditorResizeElementId,
		slideEditorDraggingSpacer,
		setSlideEditorDraggingSpacer,
		selectedSlideItems,
		handleSyncSlideChanges,
		showCaptions,
		setIsDraggingToReorder,
		isDraggingToReorder,
		groupSlideItems,
		ungroupLayoutGroup,
		handleSlideDragOrResizeStart,
		handleSlideDragOrResizeEnd,
		handleUpdateCanvasFromSlideEditor,
		handleSlideImageFileUpload,
		subtitlesType,
		webcamClips,
		updateClipMetadata,
		isDraggingWebcam,
		setIsDraggingWebcam,
		isResizingWebcam,
		setIsResizingWebcam,
		sceneWidth,
		sceneHeight,
		videoWindowPadding,
		isVideoResizeModeActive,
		preVideoResizeSnapshot
	}) => {

	
	const projectBackground=getBackgroundForId(projectBackgroundId)

	let slideClipAtPlayhead=null 

	if(!isPlaying){
		slideClips.forEach((slide)=>{
			if(slide.startTime <= currentTime && (slide.startTime+slide.duration) >= currentTime){
				slideClipAtPlayhead=slide
			}
		})
	}

	let videoClipAtPlayhead = null
	if(!isPlaying){
		videoClips.forEach((clip)=>{
			if(clip.startTime <= currentTime && (clip.startTime+clip.duration) >= currentTime){
				videoClipAtPlayhead=clip
			}
		})
	}


	const handleSetLettersArray = (lettersArray,text,docJson,clipId,elementId) => {updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId)}
	const [textEditorIsFocused, setTextEditorIsFocused] = useState(false);
	const [readyVideoClips, setReadyVideoClips] = useState([]); //clips that have the cursor data calulated

	let selectedWebcam=null 

	if(!isPlaying){
		webcamClips.forEach((webcam)=>{
			if(webcam.startTime <= currentTime && (webcam.startTime+webcam.duration) >= currentTime){
				selectedWebcam=webcam
			}
		})
	}

	const showCameraEditor = selectedWebcam && !isDraggingProgressMarker && !isPlaying
	const [cameraPreviewRect, setCameraPreviewRect] = useState(null); 

	const showSlideEditor = slideClipAtPlayhead && !isDraggingProgressMarker && !isPlaying
	const showSlideEditorUI = showSlideEditor || showCameraEditor
	const hideRenderedTextSlide = showSlideEditor

	let slideEditorKey = null
	if(slideClipAtPlayhead && testKey){
		slideEditorKey = `${testKey}_${slideClipAtPlayhead.id}`
	}
	if(!slideClipAtPlayhead && selectedWebcam){
		slideEditorKey = selectedWebcam.id
	}

	const prevClipsRef = useRef([]);

	const computeData = async (forceRecalculate = false) => {
		const legacyClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording !== true && clip.type !== 'webcam' && clip.isScreenRecording != true);
		const screenClips = videoClips.filter(clip => clip.isBasicVideo !== true && clip.isDeviceRecording !== true && clip.type !== 'webcam' && clip.isScreenRecording == true)
		//for legacy clips do computeCursorDataForLegacyClips
		//for screen clips do computeCursorDataForClips
		try {
			// Compute cursor data for both types of clips in parallel
			const [legacyClipsData, screenClipsData] = await Promise.all([
				computeCursorDataForLegacyClips(legacyClips, forceRecalculate, sceneWidth, sceneHeight),
				computeCursorDataForClips(screenClips, forceRecalculate, sceneWidth, sceneHeight)
			]);
			// Combine the results
			const allCalculatedClipsIds = [
				...legacyClipsData,
				...screenClipsData
			];
			setReadyVideoClips(allCalculatedClipsIds);
		} catch (error) {
			console.error('Error computing cursor data:', error);
		}
	};
	
	useEffect(() => {
		computeData();
	}, [videoClips]);

	useEffect(() => {
		computeData(true);
	}, [videoWindowPadding]);

	const textEditorDevMode = true

	let selectedChartSlide=null 

	if(!isPlaying){
		chartClips.forEach((clip)=>{
			if(clip.type=='chart' && clip.startTime <= currentTime && (clip.startTime+clip.duration) >= currentTime){
				selectedChartSlide=true
			}
		})
	}

	const showChartStatic = !isDraggingProgressMarker && !isPlaying && selectedChartSlide
	const showChartAnimated = !showChartStatic
	const showSlideChartStatic = !isDraggingProgressMarker && !isPlaying && slideClipAtPlayhead
	const showSlideChartAnimated = !showSlideChartStatic
	const cameraRef = useRef();

	//

	let currentTimeOverSelectedBasicVideoClip = null
	if(!isPlaying){
		videoClips.forEach((clip)=>{
			if(clip.type=='video' && clip.isBasicVideo && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedBasicVideoClip=true
			}
		})
	}

	const showBasicVideoStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedBasicVideoClip

	//

	let currentTimeOverSelectedScreenVideoClip = null
	if(!isPlaying){
		videoClips.forEach((clip)=>{
			if(clip.type=='video' && !clip.isBasicVideo && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedScreenVideoClip=true
			}
		})
	}

	const showScreenVideoStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedScreenVideoClip

	// 

	let currentTimeOverSelectedImageClip = null
	if(!isPlaying){
		imageClips.forEach((clip)=>{
			if(clip.type=='image' && clip.startTime <= currentTime && (clip.startTime+clip.duration) > currentTime){
				currentTimeOverSelectedImageClip=true
			}
		})
	}

	const showImageStatic = !isDraggingProgressMarker && !isPlaying && currentTimeOverSelectedImageClip

	const showSlideImageStatic = !isDraggingProgressMarker && !isPlaying
	const showSlideTextStatic = !isDraggingProgressMarker && !isPlaying
	const showWebcamStatic = !isDraggingProgressMarker && !isPlaying
	const showCaptionsPreview = false
	const segmentWords = 'Here is some example text';
  const wordsArray = segmentWords.split(' ');


  let selectedSlideItemId 
	if(selectedSlideItems.length==1 && selectedSlideItems[0].type=='element'){
		selectedSlideItemId = selectedSlideItems[0].id
	}

	return (            				
			<>

			<div style={{transform: `scale(${canvasWidth / sceneWidth})`}} className='editor-center-center-canvasContainer'>
				<div style={{ width: `${sceneWidth}px`, height: `${sceneHeight}px`}} className='editor-center-center-canvasInnerContainer' >						 			
					
					{trimMode && activePanelClip &&
						<EditTrimVideoPlayer
							clip={activePanelClip}
							captureId={activePanelClip.captureId}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
							trimPreviewPlaying={trimPreviewPlaying}
							trimPreviewProgress={trimPreviewProgress}
							onTrimPreviewPlayPause={onTrimPreviewPlayPause}
							onTrimPreviewSeekChange={onTrimPreviewSeekChange}
							sceneWidth={sceneWidth}
							sceneHeight={sceneHeight}
						/>
					}

					{showSlideEditorUI &&
						<SlideEditor
							key={slideEditorKey}							
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}							
							canvasX={canvasX}
							canvasY={canvasY}
							invertScalar={1/(canvasWidth / sceneWidth)}        
							slide={slideClipAtPlayhead}      
							setTextEditorIsFocused={setTextEditorIsFocused}      
							handleSetActivePanelClip={handleSetActivePanelClip}   
							activePanelClip={activePanelClip}
							projectBackground={projectBackground}	
							selectSlideElement={selectSlideElement}
							handleSetLettersArray={handleSetLettersArray}
							updateSlideElementMetadata={updateSlideElementMetadata}
							clearSlideElementsSelection={clearSlideElementsSelection}
							setSlideEditorSelection={(selection)=>{setSlideEditorSelection(selection,slideClipAtPlayhead?.id)}}
							selectedLayoutGroup={selectedLayoutGroup}
							updateSlideElementField={updateSlideElementField}
							setFocusedSlideElement={setFocusedSlideElement}
							focusedSlideElement={focusedSlideElement}
							duplicateSlideItems={duplicateSlideItems}
							openSlideEditorContextMenu={openSlideEditorContextMenu}
							handleSetSlideEditorDragElement={handleSetSlideEditorDragElement}
							dragElementId={slideEditorDragElementId}
							dragLayoutGroupId={slideEditorDragLayoutGroupId}
							updateSlideElementTextSelectionInfo={updateSlideElementTextSelectionInfo}
							handleSetSlideEditorResizeElement={handleSetSlideEditorResizeElement}
							resizeElementId={slideEditorResizeElementId}
							slideEditorDraggingSpacer={slideEditorDraggingSpacer}
							setSlideEditorDraggingSpacer={setSlideEditorDraggingSpacer}
							selectedSlideItems={selectedSlideItems}
							handleSyncSlideChanges={handleSyncSlideChanges}
							setIsDraggingToReorder={setIsDraggingToReorder}
							isDraggingToReorder={isDraggingToReorder}
							groupSlideItems={groupSlideItems}
							ungroupLayoutGroup={ungroupLayoutGroup}
							handleSlideDragOrResizeStart={handleSlideDragOrResizeStart}
							handleSlideDragOrResizeEnd={handleSlideDragOrResizeEnd}
							handleUpdateCanvasFromSlideEditor={handleUpdateCanvasFromSlideEditor}
							handleSlideImageFileUpload={handleSlideImageFileUpload}
							showDragToSelect={showSlideEditor}

							slideWidth={sceneWidth}
							slideHeight={sceneHeight}

							//Camera Editor
							showCameraEditor={showCameraEditor}
							currentTime={currentTime}
							slideClips={slideClips} 
							videoClips={videoClips}
							selectedWebcam={selectedWebcam ? selectedWebcam : null}													
							updateClipMetadata={updateClipMetadata}
							isResizingWebcam={isResizingWebcam}
							isDraggingWebcam={isDraggingWebcam}
							setIsResizingWebcam={(value)=>{setIsResizingWebcam(value,selectedWebcam?.id)}}
							setIsDraggingWebcam={(value)=>{setIsDraggingWebcam(value,selectedWebcam?.id)}}
							setCameraPreviewRect={setCameraPreviewRect}
							slideClipAtPlayhead={slideClipAtPlayhead}
							videoClipAtPlayhead={videoClipAtPlayhead}

						/>
					}
					{showCaptionsPreview &&
						<CaptionsGroupGhost
							wordsArray={wordsArray}
							visible={true}
							sceneWidth={sceneWidth}
							sceneHeight={sceneHeight}
						/>
					}

					
					<Suspense>
						<Canvas 
							style={{ width: `calc(${sceneWidth}px * 1/${canvasWidth / sceneWidth})`, height: `calc(${sceneHeight}px * 1/${canvasWidth / sceneWidth})` }}
							 gl={{ 
						    toneMapping: THREE.NoToneMapping,
						    outputColorSpace: THREE.SRGBColorSpace
						  }}
						>
							<ThreeCanvas 	            
								currentTime={currentTime}
								slideClips={slideClips}   
								textSlideClips={textSlideClips}
								videoClips={videoClips}   
								zoomClips={zoomClips}
								chartClips={chartClips}
								webcamClips={webcamClips}
								textEditorIsFocused={textEditorIsFocused}    	
								updateMeshWidth={updateMeshWidth}
								updateMeshHeight={updateMeshHeight}
								zoomBox={zoomBox}		
								projectBackground={projectBackground}	
								readyVideoClips={readyVideoClips}	
								hideRenderedTextSlide={hideRenderedTextSlide}
								showChartStatic={showChartStatic}
								showChartAnimated={showChartAnimated}
								showBasicVideoStatic={showBasicVideoStatic}
								showScreenVideoStatic={showScreenVideoStatic}
								showImageStatic={showImageStatic}
								testKey={testKey}
								imageClips={imageClips}
								isPlaying={isPlaying}
								variableValues={VARIABLE_VALUES}
								showSlideEditor={showSlideEditor}
								audioClips={audioClips}
								showSlideChartStatic={showSlideChartStatic}
								showSlideChartAnimated={showSlideChartAnimated}
								showSlideImageStatic={showSlideImageStatic}
								showSlideTextStatic={showSlideTextStatic}
								showWebcamStatic={showWebcamStatic}
								focusedSlideElement={focusedSlideElement}
								draggingElementId={slideEditorDragElementId}
								draggingGroupId={slideEditorDragLayoutGroupId}
								resizingElementId={slideEditorResizeElementId}
								slideEditorDraggingSpacer={slideEditorDraggingSpacer}
								showCaptions={showCaptions}
								isDraggingToReorder={isDraggingToReorder}
								selectedSlideItemId={selectedSlideItemId}
								subtitlesType={subtitlesType}
								isDraggingWebcam={isDraggingWebcam}
								isResizingWebcam={isResizingWebcam}
								cameraPreviewRect={cameraPreviewRect}
								sceneWidth={sceneWidth}
								sceneHeight={sceneHeight}
								videoWindowPadding={videoWindowPadding}
								isVideoResizeModeActive={isVideoResizeModeActive}
								preVideoResizeSnapshot={preVideoResizeSnapshot}
							/>                            
							<Camera ref={cameraRef} currentTime={currentTime} zoomClips={zoomClips} readyVideoClips={readyVideoClips} />
						</Canvas>
					</Suspense>
					
				</div>			
			</div>

			
			</>	
	)
	
}

export default EditorCanvasContainer


// WebcamConfigs.js

export const SLIDE_WIDTH = 1920;
export const SLIDE_HEIGHT = 1080;
export const FLOAT_CAMERA_INSET = 40;


export const webcamBorderRadius = 24

export const defaultColorAdjustments={
  exposure:0,
  highlights:0,
  shadows:0,
  brightness:0,
  contrast:0,
  saturation:0,
  whiteBalance:0
}


export const defaultWebcamLayout={	
	isFullScreen:false,
	isFixed:false,
	//size:"smallCircle",
  // position:"bottomLeft",
  size:"xSmallSquare",
  position:"bottomRight"
}


export const defaultSlideClipWebcamLayout={
  //size:"smallCircle",
  // position:"bottomLeft",
  size:"xSmallSquare",
  position:"bottomRight"
}

export const defaultVideoClipWebcamLayout={
  //size:"smallCircle",
  // position:"bottomLeft",
  size:"xSmallSquare",
  position:"bottomRight"
}



export const webcamPositions = [
  {name:'topLeft',          userLabel: 'Top Left', anchor: "bottomRight",   altPositions: ['middleLeft', 'fillLeft', 'center']},
  {name:'middleLeft',       userLabel: 'Middle Left', anchor: "topRight",      altPositions: ['bottomLeft', 'fillLeft', 'center']},
  {name:'bottomLeft',       userLabel: 'Bottom Left', anchor: "topRight",      altPositions: ['middleLeft', 'fillLeft', 'center']},
  {name:'fillLeft',         userLabel: 'Fill Left', anchor: "topRight",      altPositions: ['bottomLeft', 'middleLeft', 'center']},
  {name:'center',           userLabel: 'Center', anchor: "topLeft",       altPositions: ['bottomRight', 'middleRight', 'fillRight']},
  {name:'topRight',         userLabel: 'Top Right', anchor: "bottomLeft",    altPositions: ['middleRight', 'fillRight', 'center']},
  {name:'middleRight',      userLabel: 'Middle Right', anchor: "topLeft",       altPositions: ['bottomRight', 'fillRight', 'center']},
  {name:'bottomRight',      userLabel: 'Bottom Right', anchor: "topLeft",       altPositions: ['middleRight', 'fillRight', 'center']},
  {name:'fillRight',        userLabel: 'Fill Right', anchor: "topLeft",       altPositions: ['bottomRight', 'middleRight', 'center']},
];


export const webcamSizes = [
  {name:'hidden',           userLabel:'Hidden', width: 10,     height: 10,    cornerRadius: 0,  validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]}, 
  {name:'xSmallCircle',     userLabel:'Tiny Circle', width: 240,    height: 240,   cornerRadius: 120,  validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]}, 
  {name:'xSmallSquare',     userLabel:'Tiny Square', width: 340,    height: 340,   cornerRadius: 16,  validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  //{name:'xSmallLandscape',  userLabel:'Tiny Landscape', width: 340,    height: 240,   cornerRadius: 16,  validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},  
  {name:'smallCircle',      userLabel:'Small Circle', width: 400,    height: 400,   cornerRadius: 200, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallLandscape',   userLabel:'Small Landscape', width: 480,    height: 380,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallPortrait',    userLabel:'Small Portrait', width: 360,    height: 440,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},
  {name:'smallSquare',      userLabel:'Small Square', width: 440,    height: 440,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "topRight", "bottomRight"]},    
  {name:'mediumCircle',     userLabel:'Mid Circle', width: 500,    height: 500,   cornerRadius: 250, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'mediumLandscape',  userLabel:'Mid Landscape', width: 670,    height: 520,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'mediumPortrait',   userLabel:'Mid Portait', width: 520,    height: 670,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},
  {name:'mediumSquare',     userLabel:'Mid Square', width: 600,    height: 600,   cornerRadius: 20, validPositions: ["topLeft", "bottomLeft", "middleLeft", "topRight", "middleRight", "bottomRight"]},  
  {name:'largeLandscape',   userLabel:'Large Landscape', width: 890,    height: 720,   cornerRadius: 20,   validPositions: ["middleLeft", "middleRight"]},
  {name:'largePortrait',    userLabel:'Large Portrait', width: 700,    height: 920,   cornerRadius: 20, validPositions: ["middleLeft", "middleRight"]},
  {name:'largeSquare',      userLabel:'Large Square', width: 900,    height: 900,   cornerRadius: 20, validPositions: ["middleLeft", "middleRight"]},  
  {name:'halfFloat',        userLabel:'Half Float', width: 890,    height:1010,   cornerRadius: 20,   height_16_10: 1130, validPositions: ["middleLeft", "middleRight"]},
  {name:'halfFill',         userLabel:'Half Fill', width: 960,    height:1080,   cornerRadius: 0,    height_16_10: 1241, validPositions: ["fillLeft", "fillRight"]},
  {name:'overHalfFloat',    userLabel:'Over Half Float', width: 1200,   height:960,    cornerRadius: 20,    height_16_10: 1080, validPositions: ["middleLeft", "middleRight"]},
  {name:'overHalfFill',     userLabel:'Over Half Fill', width: 1120,   height:1080,   cornerRadius: 0,    height_16_10: 1241, validPositions: ["fillLeft", "fillRight"]},
  {name:'fullScreen',       userLabel:'Full Screen', width: 1920,   height:1080,   cornerRadius: 0,    height_16_10: 1241, validPositions: ["center"]}
];

export const SIZE_TO_LABEL = {
  'hidden': 'Hidden',
  'xSmallSquare': 'Tiny Square',
  'xSmallLandscape': 'Tiny Landscape',
  'smallSquare': 'Small Square',
  'smallPortrait': 'Small Portrait',
  'smallLandscape': 'Small Landscape',
  'mediumSquare': 'Medium Square',
  'mediumPortrait': 'Medium Portrait',
  'mediumLandscape': 'Medium Landscape',
  'largeSquare': 'Large Square',
  'largePortrait': 'Large Portrait',
  'largeLandscape': 'Large Landscape',
  'halfFloat': 'Half Float',
  'halfFill': 'Half Fill',
  'overHalfFloat': 'Over Half Float',
  'overHalfFill': 'Over Half Fill',
  'fullScreen': 'Full Screen'
}

export const POSITION_TO_LABEL = {
  'topLeft': 'Top Left',
  'topRight': 'Top Right',
  'middleLeft': 'Middle Left',
  'middleRight': 'Middle Right',
  'bottomLeft': 'Bottom Left',
  'bottomRight': 'Bottom Right',
  'fillLeft': 'Fill Left',
  'fillRight': 'Fill Right',
  'center': 'Center'
}

export const IMPORTANT_POSITIONS = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'middleLeft', 'middleRight']

export const IMPORTANT_SIZES = ['xSmallCircle', 'smallCircle', 'smallSquare', 'smallLandscape', 'smallPortrait', 'fullScreen', 'hidden']

export const webcamClipDefaultMetadata={
  backgroundId:'none',
  startTransitionType:'none',
  endTransitionType:'none',
  isAutoMotionStyle:true,
  motionStyle:"smooth",
  label:"placeholder webcam",
  positionStyle:'right',
  zoom:0,
  xOffset:0,
  yOffset:0,
  layout:defaultWebcamLayout
}
  

import { ExportTimeline} from './ExportTimeline';


//For all media items create a load media promise and when they all resolve set readyToExport to true
//TODO check this works for new clip types and stuff

const DEFAULT_ASPECT_RATIO = '16_9'


async function createExportTimeline(project,exportStartTime,exportEndTime,onTimeUpdate,renderVariables) {
	try {
		const aspectRatio = project.timeline.aspectRatio || DEFAULT_ASPECT_RATIO
		const videoWindowPadding = (project.timeline.videoWindowPadding === undefined || project.timeline.videoWindowPadding === null) ? 18 : project.timeline.videoWindowPadding
		const newTimeline = new ExportTimeline(exportStartTime,exportEndTime,onTimeUpdate,renderVariables,aspectRatio,videoWindowPadding);
		let scenes = project.timeline.scenes
		await newTimeline.initScenes(scenes)
		await Promise.all(newTimeline.loadMediaPromises);
		return newTimeline;
	} catch (error) {
		console.error('Error creating timeline:', error);
		throw error; // Rethrow or handle the error as appropriate
	}
}

export { createExportTimeline };


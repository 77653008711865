import React, {useState, useEffect} from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import Icon from '../../../misc/Icon';
import {formatTimeAgo} from '../../../../utils/dateFormatters/formatTimeAgo'


function EditorGeneratePopoverColabPageSelectPreviewDropdown({ 
    btnLabel,
    selectedPreviewVideo,
    selectPreviewVideo,
    collabGenerations
  }) {
  

  return (
    <>
    
      <DropdownMenu.Root modal={true}> 
          <DropdownMenu.Trigger asChild>
            <button className='generatePopover-section-header-btn generatePopover-section-header-btn--selectVideo'>              
              {btnLabel}
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>            
          <DropdownMenu.Content             
            align="end"
            alignOffset={-10}
            sideOffset={-2}
            className='dropdownMenu dropdownMenu--collabPreviewVideo forceDarkTheme'
          >           
            {selectedPreviewVideo && 
              <>
                <DropdownMenu.Item onSelect={()=>{selectPreviewVideo(null)}}>
                  Remove Preview Video
                </DropdownMenu.Item>
                <DropdownMenu.Separator />
              </>
            }  

            {collabGenerations.slice(0, 100).map((generation) => (
              <DropdownMenu.Item className={(selectedPreviewVideo && generation.mux_playback_id === selectedPreviewVideo.mux_playback_id) ? 'dropdownMenu-item--active dropdownMenu-item--active--bright' : ''} onSelect={()=>{selectPreviewVideo(generation)}}>
                {/*24th June 2:25pm*/}
                {formatTimeAgo(generation.updated_at)}
              </DropdownMenu.Item>
            ))}            
          </DropdownMenu.Content>   
          </DropdownMenu.Portal>       
        </DropdownMenu.Root>
    </>
  );
}

export default EditorGeneratePopoverColabPageSelectPreviewDropdown;
import {getOrgBrand} from '../../utils/brands/getOrgBrand'

export function getCursorScaling() {
  let cursorScaling = 1.6
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.cursorScaling){
    cursorScaling = orgBrand.screenVideoStyle.cursorScaling
  }
  return cursorScaling
}

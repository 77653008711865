import {fetchUser} from './user'
import {fetchProjects,fetchArchivedProjects} from './projects'
import {fetchOrganization} from './organization'
import {fetchRecordings} from './recordings'
import {fetchOrgMembers} from './orgMembers'
import {fetchUploadedImages} from './imageUpload'
import {fetchUploadedVideos} from './videoUpload'
import {fetchUploadedMusic} from './backgroundMusic'
import {fetchCollections} from './collections'
import {fetchSceneTemplates} from './sceneTemplate'
import {fetchSlideTemplates} from './slideTemplates'
import {fetchArchivedSlideTemplates} from './slideTemplates'
import {fetchScreenRecordings} from './screenRecordings'
// import {fetchWebcamVideos} from './webcamVideo'
import {fetchWebcamRecordings} from './webcamRecording'
import {fetchProjectCollections} from './collections'


export function loadInitialData() {

	return (dispatch) => {
		dispatch(fetchOrganization())
		dispatch(fetchUser())
		//dispatch(fetchOrganization())
		dispatch(fetchProjects())
		dispatch(fetchScreenRecordings())
		dispatch(fetchRecordings())
		dispatch(fetchOrgMembers())
		dispatch(fetchUploadedImages())
		dispatch(fetchUploadedVideos())
		dispatch(fetchUploadedMusic())
		dispatch(fetchCollections())
		// dispatch(fetchSceneTemplates())
		dispatch(fetchArchivedProjects())

		dispatch(fetchSlideTemplates())
		dispatch(fetchArchivedSlideTemplates())
		// dispatch(fetchWebcamVideos())
		dispatch(fetchWebcamRecordings())
		dispatch(fetchProjectCollections())

		

	}
}


import React, { useState } from 'react';
import Measure from 'react-measure';
import LibraryProjectGalleryGrid from './LibraryProjectGalleryGrid';
import LibraryProjectGalleryList from './LibraryProjectGalleryList';


const LEFT_MARGIN = 56; // Adjust the left margin as needed
const RIGHT_MARGIN = 56; // Adjust the right margin as needed

function LibrarySubPage({ projects, downloads, subscriptionStatus,sortOption,handleSortChange,displayMode,subPage }) {
    const [dimensions, setDimensions] = useState({ width: -1 });

    const hasFilter = false
    const hasSearch = false

    const containerWidth = dimensions.width

    const adjustedContainerWidth = containerWidth - LEFT_MARGIN - RIGHT_MARGIN + 8 + 8;

    let noItemsLabel
    if(subPage === 'drafts'){
      noItemsLabel = 'No private drafts.'
    }
    if(subPage === 'deleted'){
      noItemsLabel = 'No deleted items.'
    }
    if(subPage === 'org'){
      noItemsLabel = 'No team items yet. Move your private drafts here to share them with your teammates.'
    }
    
    return (
      <>
        <Measure
          bounds
          onResize={(contentRect) => {
            if (contentRect.bounds) {
              setDimensions({ width: contentRect.bounds.width });
            }
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className='libraryPage-measurer' />
          )}
        </Measure>
        


        
        
        {!hasFilter && !hasSearch &&
          <>

            <div className='libraryPage-allVideosContainer'>

              {projects.length < 1 && noItemsLabel &&
                <div className='libraryPage-noItems'>
                  {noItemsLabel}
                </div>
              }

              {displayMode === 'grid' && 
                <LibraryProjectGalleryGrid 
                  projects={projects}
                  containerWidth={adjustedContainerWidth} 
                />
              }
              {displayMode === 'list' && 
                <LibraryProjectGalleryList 
                  projects={projects}             
                />
              }
            </div>          

          </>
        }

        {(hasSearch) &&

        <>           

          {displayMode === 'grid' && 
            <LibraryProjectGalleryGrid 
              projects={sampleProjects} 
              containerWidth={adjustedContainerWidth} 
            />
          }
          {displayMode === 'list' && 
            <LibraryProjectGalleryList 
              projects={sampleProjects}             
            />
          }          


        </>
        }

      </>
    );
}

export default LibrarySubPage;

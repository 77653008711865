import React, {useState, useRef, useEffect} from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../misc/Icon';
import tracks from '../../utils/backgroundMusic/backgroundMusicTracks'
import {getOrgDomain} from '../../utils/getOrgDomain'
import {getUploadedBackgroundTracks} from '../../utils/backgroundMusic/getUploadedBackgroundTracks'
import SelectSoundsDropdownEffects from './SelectSoundsDropdownEffects';            

function SelectMusicPopoverItem({ track, active, handleClosePopover, handleSelectBackgroundMusicTrack, isPlaying, handlePlaySample,isUpload }) {

  const handleClick = () => {
    handleSelectBackgroundMusicTrack(track.id)    
  };

  const playSample = (e) => {
    e.stopPropagation(); // Prevent triggering click events on parent elements
    handlePlaySample(track.id, track.src,isUpload); // Use the handler from the parent component
  };

    
  return (
    <button 
      data-state={active ? "active" : "inactive"} 
      className='dropdownMenu--selectMusic-grid-item'      
      onClick={handleClick} 
    >      
      <div className='dropdownMenu--selectMusic-grid-item-inner'>
        
        {(track.src || track.isUpload) && 
          <div onClick={playSample} className='dropdownMenu--selectMusic-grid-item-inner-playPauseBtn'>
            {isPlaying ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' /> }
          </div>
        }

        <div className='dropdownMenu--selectMusic-grid-item-inner-title'>
          {track.title}
        </div>
      </div>            
    </button>
  );
}


function SelectSoundsDropdown(props) {
  const { children, activeBackgroundMusicTrack,updateBackgroundMusicTrack,handleNewMusicFileUpload} = props

  const currentAudioRef = useRef(null); // Ref to hold the currently playing Audio object
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClosePopover = () => {    
    setDropdownOpen(false); // Close the dropdown
  };

  const handleUploadClick = () => {
    document.getElementById('file-upload').click();
  };

  const handleSelectBackgroundMusicTrack = (trackId) => {    
    setDropdownOpen(false);
    updateBackgroundMusicTrack(trackId)
  };


  const uploadFile = async (file) => {
    try {
       setUploadSubmitLoading(true)
        // Assuming handleNewMusicFileUpload is an async function that uploads the file
        await handleNewMusicFileUpload(file);
        setUploadSubmitLoading(false)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  };

  useEffect(() => {
    if (!dropdownOpen) {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
        setCurrentPlayingTrack(null); // Reset current playing track
      }
    }
  }, [dropdownOpen]); // This effect depends on soundDropdownOpen


  const orgDomain = getOrgDomain()

  const isRootly = (orgDomain === 'rootly.com')
  const isUnsupervised = (orgDomain === 'unsupervised.com')
  const isCanvas = (orgDomain === 'canvasapp.com')

//  const isStack = (orgDomain === 'stack-ai.com' || orgDomain=='yarn.so')
  const isClay = (orgDomain=='clay.com' || orgDomain=='clay.run' || orgDomain=='yarn.so')

  

  const isStack = (orgDomain === 'stack-ai.com')
  const isParcha = (orgDomain === 'canvasapp.com' || orgDomain=="parcha.ai")
 // const isFaire = (orgDomain === "faire.com" || orgDomain === 'yarn.so')
    const isFaire = (orgDomain === "faire.com" )

    const isMaven=(orgDomain =='mavenagi.com')

    const isFurtherai=(orgDomain=='furtherai.com')
  //const isCanvas = (orgDomain === 'canvasapp.com')

  const hasCustomTracks = isRootly || isUnsupervised || isCanvas || isParcha || isFaire || isStack || isMaven || isFurtherai || isClay

  let customTrackDomain = null

  if(isRootly){
   customTrackDomain = 'Rootly'
  }
  if(isUnsupervised){
   customTrackDomain = 'Unsupervised' 
  }
  if(isCanvas || isStack){
   customTrackDomain = 'Canvas'
  }
  if(isParcha || isMaven){
   customTrackDomain = 'Parcha' 
  }
  if(isFaire){
   customTrackDomain = 'Faire' 
  }
  if(isClay){
    customTrackDomain='Clay'
  }

  const [activeTab, setActiveTab] = useState("effects"); //default, custom or settings

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null);
  

  // Handler to manage playing a sample
  const handlePlaySample = async(trackId, trackSrc,isUpload) => {
    // Stop and reset the current playing track if it exists
    if (currentAudioRef.current && currentPlayingTrack !== trackId) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
    }

    // If the clicked track is the same as the currently playing, just reset it
    if (currentPlayingTrack === trackId) {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
        setCurrentPlayingTrack(null); // No track is playing
      }
    } else {

      if(isUpload){
        const audioData = await ipcRenderer.invoke('read-background-music-file', trackId);
        const newAudio = new Audio(audioData);
        newAudio.play();
        newAudio.onended = () => {
          setCurrentPlayingTrack(null); // Reset playing track when audio ends
        };
        currentAudioRef.current = newAudio; // Update the ref to the new audio
        setCurrentPlayingTrack(trackId); // Update the state to the new playing track

      }else{
        // Play the new track
        const newAudio = new Audio(trackSrc);
        newAudio.play();
        newAudio.onended = () => {
          setCurrentPlayingTrack(null); // Reset playing track when audio ends
        };
        currentAudioRef.current = newAudio; // Update the ref to the new audio
        setCurrentPlayingTrack(trackId); // Update the state to the new playing track
        }
      }    
  };

  const toggleLibraryTabActive = (isActive) => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
      setCurrentPlayingTrack(null); // Reset current playing track
    }
    setLibraryTabActive(isActive);
  };

  //const uploadSubmitLoading = false
  const [uploadSubmitLoading, setUploadSubmitLoading] = useState(false)


  const uploadedBackgroundTracks=getUploadedBackgroundTracks()
  // console.log('uploaded tracks is----->>>>>>>>')
  // console.log(uploadedBackgroundTracks)


  let brandTracks = tracks.filter(track => track.custom === customTrackDomain)
  brandTracks= brandTracks.concat(uploadedBackgroundTracks)

  return (
    <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}  modal={true}>
      <DropdownMenu.Trigger asChild>
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content 
          side="top"
          sideOffset={4}
          // align="start"
          align="center"
          //alignOffset={-24}
          className='dropdownMenu dropdownMenu--selectSounds forceDarkTheme'
        >                
          
          <div className='dropdownMenu--selectSounds-inner'>
         
            <>       
              
             
                <div className='dropdownMenu--selectSounds-groupingRow'>              
                  <button data-state={activeTab=="effects" ? 'active' : 'inactive'} className='dropdownMenu--selectSounds-groupingRow-smallLabelBtn' onClick={() => setActiveTab("effects")}>
                    Sound Effects
                  </button> 
                  {hasCustomTracks && 
                  <button data-state={activeTab=="custom" ? 'active' : 'inactive'} className='dropdownMenu--selectSounds-groupingRow-smallLabelBtn' onClick={() => setActiveTab("custom")}>
                    Brand Tracks
                  </button>      
                }           
                {!isFaire &&
                  <button data-state={activeTab=="default" ? 'active' : 'inactive'} className='dropdownMenu--selectSounds-groupingRow-smallLabelBtn' onClick={() => setActiveTab("default")}>
                    Default Tracks
                  </button>                   
                  }         
                  

                  <div className='dropdownMenu--selectSounds-groupingRow-hSpacer' />
                
                {/*}
                

                    <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger className='dropdownMenu--selectSounds-groupingRow-settingsSub-btn'>
                        <Icon name='ellipses' />
                      </DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent className='dropdownMenu-subMenu dropdownMenu--selectSounds-groupingRow-settingsSub'>
                        <div className='dropdownMenu-explainer'>
                          Volume
                        </div>
                        <DropdownMenu.Item>Max</DropdownMenu.Item>
                        <DropdownMenu.Item>High</DropdownMenu.Item>
                        <DropdownMenu.Item>Medium</DropdownMenu.Item>
                        <DropdownMenu.Item>Low</DropdownMenu.Item>

                                         
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub>


                {/*}
                <button data-state={activeTab=="settings" ? 'active' : 'inactive'} className='dropdownMenu--selectSounds-groupingRow-smallLabelBtn' onClick={() => setActiveTab("settings")}>
                  Settings
                </button>          
                */}
                        
              </div>  
              }



              {activeTab=="custom" && 
                <div className='dropdownMenu--selectMusic-grid'>


                  <div className='dropdownMenu--selectMusic-grid-grid'>

                    {brandTracks.length > 0 && (              
                      <button 
                        data-state={!activeBackgroundMusicTrack ? "active" : "inactive"}                  
                        className='dropdownMenu--selectMusic-grid-item'
                        onClick={()=>{handleSelectBackgroundMusicTrack(null)}}
                      >           
                        <div className='dropdownMenu--selectMusic-grid-item-inner'>   
                          No background track
                        </div>
                      </button>  
                    )}   

                    {brandTracks.map(track => (                  

                      <SelectMusicPopoverItem 
                        key={track.id}
                        isUpload={track.isUpload}
                        track={track}              
                        active={track.id == activeBackgroundMusicTrack}
                        handleSelectBackgroundMusicTrack={handleSelectBackgroundMusicTrack}
                        handlePlaySample={handlePlaySample}
                        isPlaying={track.id === currentPlayingTrack}
                      />
                    ))}

                  </div>

                  {brandTracks.length < 1 && (              
                    <button 
                      data-state={!activeBackgroundMusicTrack ? "active" : "inactive"}                  
                      className='dropdownMenu--selectMusic-grid-uploadLarge'
                      onClick={handleUploadClick}
                    >     
                      {!uploadSubmitLoading &&       
                        <>
                          <div className='dropdownMenu--selectMusic-grid-uploadLarge-iconContainer'>   
                            <Icon name='laptopUpload' />
                          </div>
                          <div className='dropdownMenu--selectMusic-grid-uploadLarge-title'>   
                            Upload background track
                          </div>
                          <div className='dropdownMenu--selectMusic-grid-uploadLarge-small'>   
                            MP3 · WAV · M4A
                          </div>
                        </>
                      }

                      {uploadSubmitLoading && 
                        <div className='dropdownMenu--selectMusic-grid-uploadLarge-spinnerContainer'>   
                          <div className='dropdownMenu--selectMusic-grid-uploadLarge-spinner' />
                        </div> 
                      }

                      <input 
                        id="file-upload" 
                        type="file" 
                        style={{ display: 'none' }} 
                        onChange={(event) => {
                          const file = event.target.files[0]
                          uploadFile(file)
                        }}                      
                         accept="audio/*"
                      />

                    </button>  
                  )} 


                  {brandTracks.length > 0 && (              
                    <>
                    <div className='dropdownMenu--selectMusic-grid-vSpacer' />
                    <button 
                      data-state={!activeBackgroundMusicTrack ? "active" : "inactive"}                  
                      className='dropdownMenu--selectMusic-grid-upload'
                      onClick={handleUploadClick}
                    >           
                      {!uploadSubmitLoading &&                     
                        <div className='dropdownMenu--selectMusic-grid-upload-iconContainer'>   
                          <Icon name='laptopUpload' />
                        </div> 
                      }
                      {uploadSubmitLoading &&                     
                        <div className='dropdownMenu--selectMusic-grid-upload-spinnerContainer'>   
                          <div className='dropdownMenu--selectMusic-grid-upload-spinner' />
                        </div> 
                      }



                      <input 
                        id="file-upload" 
                        type="file" 
                        style={{ display: 'none' }} 
                        // onChange={(event) => {
                        //   console.log('upload file here0000000')
                        //   const file = event.target.files[0]
                        //   //console.log('selected file')
                        //   handleNewMusicFileUpload(file)
                        // }}   
                        onChange={(event) => {
                          console.log('upload file here0000000 1234')
                          const file = event.target.files[0]
                          uploadFile(file)
                        }}                     
                         accept="audio/*"
                      />
                                         
                    </button>  
                    </>
                  )}   

                </div>
              }

              {activeTab=="default" && 
                <div className='dropdownMenu--selectMusic-grid'>
                  <div className='dropdownMenu--selectMusic-grid-grid'>

                    <button 
                      data-state={!activeBackgroundMusicTrack ? "active" : "inactive"} 
                     // data-state="inactive"
                      className='dropdownMenu--selectMusic-grid-item'
                      onClick={()=>{handleSelectBackgroundMusicTrack(null)}}
                    >           
                      <div className='dropdownMenu--selectMusic-grid-item-inner'>   
                        No background track                          
                      </div>
                    </button>     

                    {tracks.filter(track => track.custom == null).map(track => (              
                      <SelectMusicPopoverItem 
                        key={track.id}
                        track={track}              
                        active={track.id == activeBackgroundMusicTrack}
                        handleSelectBackgroundMusicTrack={handleSelectBackgroundMusicTrack}
                        // // handleSelectColor={handleSelectColor}
                        handleClosePopover={handleClosePopover}                        
                        handlePlaySample={handlePlaySample}
                        isPlaying={track.id === currentPlayingTrack}
                      />
                    ))}

                  </div>
                </div>
              }

              
              {activeTab=="effects" &&
                <SelectSoundsDropdownEffects 
                  soundEffectsSettings={props.soundEffectsSettings}
                  updateSoundEffectsSettings={props.updateSoundEffectsSettings}
                /> 
                
              }


              {activeTab=="settings" && 
                <div className='dropdownMenu--selectMusic-grid'>

                 <button 
                    data-state={!activeBackgroundMusicTrack ? "active" : "inactive"} 
                   // data-state="inactive"
                    className='dropdownMenu--selectMusic-grid-item'
                    onClick={()=>{handleSelectBackgroundMusicTrack(null)}}
                  >           
                    <div className='dropdownMenu--selectMusic-grid-item-inner'>   
                      fade out                          
                    </div>
                  </button>   

                </div>
              }

            </>
          

        {/*  {!hasCustomTracks && 
          <div className='dropdownMenu--selectMusic-grid'>
           
            <button 
              data-state={!activeBackgroundMusicTrack ? "active" : "inactive"} 
             // data-state="inactive"
              className='dropdownMenu--selectMusic-grid-item'
              onClick={()=>{handleSelectBackgroundMusicTrack(null)}}
            >           
              <div className='dropdownMenu--selectMusic-grid-item-inner'>   
                None                          
              </div>
            </button>                  


            {tracks.filter(track => track.custom == null).map(track => (
              <SelectMusicPopoverItem 
                key={track.id}
                track={track}              
                active={track.id == activeBackgroundMusicTrack}
                handleSelectBackgroundMusicTrack={handleSelectBackgroundMusicTrack}
                // // handleSelectColor={handleSelectColor}
                handleClosePopover={handleClosePopover}    
                handlePlaySample={handlePlaySample}
                      isPlaying={track.id === currentPlayingTrack}              
              />
            ))}
          </div>
          }*/}
          
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default SelectSoundsDropdown;
import React from 'react';
import {formatTimelineDuration} from '../../../../../utils/dateFormatters/formatTimelineDuration'
import EditorTimelineSceneTimeRuler from './EditorTimelineSceneTimeRuler'
import EditorTimelineSceneHeader from './EditorTimelineSceneHeader'
import EditorTimelineSceneGapButtons from './EditorTimelineSceneGapButtons'
import Icon from '../../../../misc/Icon';


const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

const DEFAULT_TITLE="Default title"

const EditorTimelineSceneBounds = ({ scene,splitScene,addSceneAfterScene, mergeScene,pixelsPerSec, sceneGap, devMode, TRACK_HEIGHT, CLIP_HEIGHT, sceneCount, maxTimelineDurationSeconds, setShowDraggingCursor, handleSeek, scrollRef}) => {

  const {sceneIndex} = scene 
  const gapOffset = sceneIndex * sceneGap
 
  const left = scene.startTime * pixelsPerSec + gapOffset

  let duration = scene.duration

  const isLastScene = sceneIndex === sceneCount - 1

  if(isLastScene){  
    duration = maxTimelineDurationSeconds - scene.startTime
  }

  let width = duration * pixelsPerSec

  let endMarkerLeft
  let endMarkerTime

  if(isLastScene){
    endMarkerLeft = (scene.startTime + scene.duration) * pixelsPerSec + gapOffset
    endMarkerTime = scene.startTime + scene.duration
  }

  return (
    <>
      <div style={{left: `${left}px`, width: `${width}px`}} className='editor-timeline-sceneTimeRulerContainer'>
        <EditorTimelineSceneTimeRuler 
          pixelsPerMs={pixelsPerSec / 1000}
          timelineDurationSeconds={duration}
          width={width}
          startTime={scene.startTime}
        />
      </div>

      {isLastScene &&
        <div data-visibility-state={endMarkerTime > 5 ? 'true' : 'false'} style={{left: `${endMarkerLeft}px`}}  className='editor-timeline-endMarker'>
          <div className='editor-timeline-endMarker-marker'>
            <div className='editor-timeline-endMarker-marker-topLine' />
            <div className='editor-timeline-endMarker-marker-label'>
              {formatTime(endMarkerTime)}
            </div>
          </div>
          <div className='editor-timeline-endMarker-line' />
        </div>
      }

      {/*}
      {isLastScene &&
        <div data-visibility-state={endMarkerTime > 5 ? 'true' : 'false'} style={{left: `${endMarkerLeft}px`}}  className='editor-timeline-endNewSceneBtnContainer'>
          <button className='editor-timeline-endNewSceneBtn'>
            <Icon name='plusMedium' />
          </button> 
        </div>
      }
      */}


      {/*}
      {!devMode && sceneCount > 1 && 
        <div style={{width: `${width + 2}px`, left: `${left}px`}} className='editor-timeline-sceneHeader'>
          <div className='editor-timeline-sceneHeader-label'>
            <div className='editor-timeline-sceneHeader-label-label'>
              {scene.title} <span className='editor-timeline-sceneHeader-label-timestamp'>{formatTimelineDuration(scene.duration)}</span>            
            </div>
            <div className='editor-timeline-sceneHeader-label-bg' />
          </div>
        </div>
      }
      */}

      
      {/*{sceneCount > 1 && */}
      {scene.title != DEFAULT_TITLE &&
        <EditorTimelineSceneHeader 
          scene={scene}
          width={width}
          left={left}
          handleSeek={handleSeek}
          scrollRef={scrollRef}
          pixelsPerSec={pixelsPerSec}
          splitScene={splitScene}
          mergeScene={mergeScene}
          isLastScene={isLastScene}
        />        
      }
      


      <EditorTimelineSceneGapButtons 
        left={left + width}
        gapWidth={sceneGap}
        scene={scene}
        mergeScene={mergeScene}
        setShowDraggingCursor={setShowDraggingCursor}
        addSceneAfterScene={addSceneAfterScene}
        handleSeek={handleSeek}
      />


      <div style={{width: `${width}px`, height: `${TRACK_HEIGHT * 3 - 14}px`, left: `${left}px`}} className='editor-timeline-sceneBounds'>
        
        {!devMode && 
          <>
            <div style={{height: `${TRACK_HEIGHT}px`}} className='editor-timeline-trackBG'>
              <div className='editor-timeline-trackBG-inner' />          
            </div>

            <div style={{height: `${TRACK_HEIGHT}px`}} className='editor-timeline-trackBG'>
              <div style={{height: `${CLIP_HEIGHT}px`}} className='editor-timeline-trackBG-inner' />          
            </div>

            <div style={{height: `${TRACK_HEIGHT}px`}} className='editor-timeline-trackBG'>
              <div style={{height: `${CLIP_HEIGHT}px`}} className='editor-timeline-trackBG-inner' />          
            </div>

            <div style={{height: `${TRACK_HEIGHT}px`}} className='editor-timeline-trackBG'>
              <div style={{height: `${CLIP_HEIGHT}px`}} className='editor-timeline-trackBG-inner' />          
            </div>
          </>
        }
        
        
      </div>

      <div  style={{width: `${width}px`, height: `${TRACK_HEIGHT * 3 - 14}px`, left: `${left}px`}}  className='editor-timeline-sceneBoundsOutline'/>
            
    </>
  );
};

export default EditorTimelineSceneBounds;
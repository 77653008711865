import React, { useState, useEffect, useRef } from 'react';
import { pixelsToTime } from '../utils/pixelsToTime';
import { timeToPixels } from '../utils/timeToPixels';
import Icon from '../../../misc/Icon';

const EditorTimelineAddSkipSegmentMode = (props) => {
  const {
    currentTime,
    scenes,
    pixelsPerSec,
    timelineDurationSeconds,
    maxTimelineDurationSeconds,
    sceneGap,
    handleSeek,
    isAddSkipSegmentModeActive,
    preAddSkipCurrentTime,
    addSkipSegmentModeClip,
    handleAddSkipSegmentFromSkipSegmentMode
  } = props;

  // Define bounds from the clip (if available); otherwise use full timeline.
  let boundStartTime = 0;
  let boundEndTime = timelineDurationSeconds;
  if (addSkipSegmentModeClip && addSkipSegmentModeClip.startTime != null && addSkipSegmentModeClip.endTime != null) {
    boundStartTime = addSkipSegmentModeClip.startTime;
    boundEndTime = addSkipSegmentModeClip.endTime;
  }
  const boundStartPos = timeToPixels(boundStartTime, pixelsPerSec, scenes, sceneGap);
  const boundEndPos = timeToPixels(boundEndTime, pixelsPerSec, scenes, sceneGap);

  const [isMouseControlActive, setIsMouseControlActive] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const containerRef = useRef(null);
  const sidePanelOffset = 16;

  useEffect(() => {
    if (!isAddSkipSegmentModeActive) {
      setCursorPosition(null);
      setIsMouseControlActive(false);
    }
  }, [isAddSkipSegmentModeActive]);

  const handleMouseMove = (e) => {
    if (!isAddSkipSegmentModeActive) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const relativeX = e.clientX - rect.left;
    
    // Store the raw cursor position without the offset for rendering
    // No need to clamp here as we'll clamp in the render
    setCursorPosition(relativeX);
    setIsMouseControlActive(true);
    
    // Subtract the sidePanelOffset from relativeX to get the correct position for time calculation
    // Ensure the adjusted position stays within bounds
    const adjustedX = Math.max(relativeX - sidePanelOffset, 0);
    // Clamp the adjusted position to the bounds (without offset)
    const clampedX = Math.min(Math.max(adjustedX, boundStartPos), boundEndPos);
    
    const newTime = pixelsToTime(clampedX, pixelsPerSec, scenes, sceneGap);
    handleSeek(newTime);
  };

  const handleMouseLeave = () => {
    setCursorPosition(null);
    setIsMouseControlActive(false);
  };

  // Listen for keyboard-driven seeking
  useEffect(() => {
    // Create a reference to the previous time
    const timeChange = timeRef.current !== null ? Math.abs(currentTime - timeRef.current) : 0;
    
    // If current time changed significantly without mouse being active,
    // it was likely from keyboard input
    if (timeChange > 0.01 && !isMouseControlActive) {
      // Keep mouse control inactive - currentTime will be used for marker
    } else if (isMouseControlActive) {
      // If mouse is controlling, update cursor position for accurate rendering
      // Convert current time to pixels
      const newPosition = timeToPixels(currentTime, pixelsPerSec, scenes, sceneGap);
      // Add the offset but don't clamp here - we'll clamp in the render
      setCursorPosition(newPosition + sidePanelOffset);
    }
    
    // Update ref for next comparison
    timeRef.current = currentTime;
  }, [currentTime, isMouseControlActive, pixelsPerSec, scenes, sceneGap, sidePanelOffset]);

  // Reference to track previous currentTime
  const timeRef = useRef(currentTime);

  // Compute positions (in pixels) from time values.
  const preSkipPosRaw =
    preAddSkipCurrentTime != null
      ? timeToPixels(preAddSkipCurrentTime, pixelsPerSec, scenes, sceneGap)
      : null;
  const currentPosRaw = timeToPixels(currentTime, pixelsPerSec, scenes, sceneGap);

  // Clamp these positions to the bounds.
  const clampedPreSkipPos = preSkipPosRaw !== null
    ? Math.min(Math.max(preSkipPosRaw, boundStartPos), boundEndPos)
    : null;
  const clampedCurrentPos = Math.min(Math.max(currentPosRaw, boundStartPos), boundEndPos);

  // For the preview segment, we span from the lower to the higher of these two.
  let segStart = 0;
  let segEnd = 0;
  if (clampedPreSkipPos !== null) {
    segStart = Math.min(clampedPreSkipPos, clampedCurrentPos);
    segEnd = Math.max(clampedPreSkipPos, clampedCurrentPos);
  }
  const constrainedSegmentWidth = segEnd - segStart;

  // Use mouse position for marker only if mouse control is active
  const markerCurrent = isMouseControlActive && cursorPosition !== null
    ? Math.min(Math.max(cursorPosition, boundStartPos + sidePanelOffset), boundEndPos + sidePanelOffset)
    : clampedCurrentPos;

  // We'll render two preview markers: one at the frozen pre-skip time and one for the current time.
  const preMarkerLeft = clampedPreSkipPos;
  // When mouse is active, use the cursor position but ensure it stays within bounds
  // When not mouse active, use the clamped current position with offset
  const currentMarkerLeft = isMouseControlActive 
    ? Math.min(Math.max(cursorPosition, boundStartPos + sidePanelOffset), boundEndPos + sidePanelOffset)
    : clampedCurrentPos + sidePanelOffset;

  return (
    <>
      {/* Render the preview segment, but only if its width is > 0 */}
      {clampedPreSkipPos !== null && constrainedSegmentWidth > 0 && (
        <div
          className="editor-timeline-skipSegment-previewSegment"
          style={{                                             
            left: `${segStart + sidePanelOffset}px`,
            width: `${constrainedSegmentWidth}px`,            
          }}
        >
          {constrainedSegmentWidth > 40 &&
          <div className='editor-timeline-skipSegment-previewSegment-trimBadge'>
            Trim
          </div>
          }
        </div>
      )}

      <div 
        ref={containerRef}
        onClick={handleAddSkipSegmentFromSkipSegmentMode}
        className="editor-timeline-addSkipSegmentModeContainer"
        style={{           
          width: `${pixelsPerSec * maxTimelineDurationSeconds}px`
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {/* Render the preAddSkip marker */}
        {preMarkerLeft !== null && (
          <div 
            className="editor-timeline-skipSegment-timePreviewLine editor-timeline-skipSegment-timePreviewLine--original"
            style={{ left: `${preMarkerLeft + sidePanelOffset}px` }}
          >
            <div className="editor-timeline-skipSegment-timePreviewLine-iconContainer">
              <Icon name="previewPlayheadTriangle" />
            </div>
            <div className="editor-timeline-skipSegment-timePreviewLine-line" />
            <div className="editor-timeline-skipSegment-timePreviewLine-bgLine" />
          </div>
        )}

        {/* Render the current marker */}
        {currentMarkerLeft !== null && (
          <div 
            className="editor-timeline-skipSegment-timePreviewLine editor-timeline-skipSegment-timePreviewLine--current"
            style={{ left: `${currentMarkerLeft}px` }}
          >
            <div className="editor-timeline-skipSegment-timePreviewLine-iconContainer">
              <Icon name="previewPlayheadTriangle" />
            </div>
            <div className="editor-timeline-skipSegment-timePreviewLine-line" />
            <div className="editor-timeline-skipSegment-timePreviewLine-bgLine" />
          </div>
        )}
      </div>
    </>
  );
};

export default EditorTimelineAddSkipSegmentMode;
import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { SpringAnimator } from '../utils/animations/SpringAnimator';
import CanvasBackground from '../CanvasBackground';
import {calulateVideoTimeFromTimelineTime} from '../../timeline/utils/calulateVideoTimeFromTimelineTime'
import BasicVideoDevice from './BasicVideoDevice'
import { getAnimationParamsForMotionStyle } from '../utils/animations/getAnimationParamsForMotionStyle'
import { startTransitionConfigs } from './transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './transitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../utils/animations/simulateAnimationDuration';
import { getBackgroundForId } from '../../utils/brands/getBackgroundForId'
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import {isLightBackground} from '../../utils/brands/isLightBackground'
import { calculateVideoZoom } from '../utils/videoZoom/calculateVideoZoom'
import renderOrders from '../renderOrders'

const FPS = 60; // Frames per second

const BasicVideo = ({clip,time,projectBackground, showBasicVideoStatic, isPlaying, zoomClips, readyVideoClips, sceneWidth, sceneHeight}) => {
  
  const startTransitionType = clip.metadata.startTransitionType
  const endTransitionType = clip.metadata.endTransitionType
  const motionStyle=clip.metadata.motionStyle || 'smooth'
  
  let adjustedSpeed = 0
  let adjustedBounciness = 0
  if(clip.metadata.enterTransitionSpeedFactor){
    adjustedSpeed = clip.metadata.enterTransitionSpeedFactor
  }
  if(clip.metadata.enterTransitionBouncinessFactor){
    adjustedBounciness = clip.metadata.enterTransitionBouncinessFactor
  }

  const animationParams=getAnimationParamsForMotionStyle(motionStyle, adjustedSpeed, adjustedBounciness)

  const relativeTime = time - clip.startTime 
  const localTime = calulateVideoTimeFromTimelineTime(time,clip)

  //

  let visible = false 


  //if(localTime >= clip.metadata.trimStart && localTime < clip.metadata.trimEnd){
    if(localTime >= clip.metadata.trimStart && parseFloat(localTime.toFixed(10)) < parseFloat(clip.metadata.trimEnd.toFixed(10))){
    visible = true
  }

  if(clip.isUploadingVideo){ //When video is uploading we don't have the trim stuff yet so do this so it shows the placeholder
    if(relativeTime >= clip.startTime && relativeTime < clip.endTime){
      visible=true
    }
  }





  const backgroundId = clip.metadata.backgroundId
  let screenVideoBackground = projectBackground
   const isNoneBGColor = backgroundId =='none'

  const idToTest=backgroundId||projectBackground.id
  const isLightBG = isLightBackground(idToTest)


  //console.log(`basic video is light?----- ${isLight}`)

  if(backgroundId){
    screenVideoBackground=getBackgroundForId(backgroundId)
  }

  //

  const videoDuration = clip.duration // plays as long of and then exits
  const localStartTime = 0
  const localEndTime = videoDuration - 0.1
  const calculationWindowEnd = localEndTime + 0.2

  //

  let scalar = 1
  if(clip.metadata.enterTransitionValueFactor){
    scalar = clip.metadata.enterTransitionValueFactor + 1 // convert to scalar
  }


  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = (startTransitionConfigs[startTransitionType] || (() => ({})))(scalar);
  const { endTo: transitionEndTo } = endTransitionConfigs[endTransitionType] || {};
  const startFrom = { ...defaultVideoAnimationValues, ...transitionStartFrom };
  const startTo = { ...defaultVideoAnimationValues, ...transitionStartTo };
  const endTo = { ...defaultVideoAnimationValues, ...transitionEndTo };

  let initialProperties = startFrom
  if(showBasicVideoStatic){
    initialProperties = defaultVideoAnimationValues
  }

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [startToCompletedTime, setStartToCompletedTime] = useState(null);
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(null);

  useEffect(() => {
    let newPropertySprings = {};
    let newAnimationStates = {};

    // Ensure that calculationWindowEnd and FPS are defined and valid
    const framesCount = calculationWindowEnd && FPS ? Math.round(calculationWindowEnd * FPS) : 0;

    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      
      newAnimationStates[property] = new Array(framesCount).fill(startFrom[property]);
    });

    // Calculate the animation durations
    let startToDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    if(startToDuration === 'none'){
      startToDuration = 0
    }
    let endToDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);
    if(endTransitionType === 'none'){
      endToDuration = 0
    }

    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < startToDuration) {
        Object.keys(startTo).forEach(property => {
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the endInitiateAnimationTime
      if (simulationLocalTime >= (localEndTime - endToDuration) && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];        
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // Update states at the end of the calculation
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);
    setStartToCompletedTime(startToDuration);
    setEndToAnimationDuration(endToDuration);
  
  }, [videoDuration, JSON.stringify(animationParams), startTransitionType, endTransitionType, scalar, adjustedSpeed, adjustedBounciness]);


 // console.log(`----isNoneBGColor ${isNoneBGColor}`)

  //const horizontalOffsetPercentage = 50 // pull from data
  const horizontalOffsetPercentage = clip.metadata.horizontalOffset || 0
  const horizontalOffset = (horizontalOffsetPercentage / 100) * (sceneWidth / 2)

  const verticalOffsetPercentage = clip.metadata.verticalOffset || 0
  const verticalOffset = (verticalOffsetPercentage / 100) * (sceneHeight / 2) * -1

  //
  // VIDEO ZOOM

  const groupRef = useRef();
  const [zoomFrames, setZoomFrames] = useState([]);

  const zoomClipsDependency = JSON.stringify(
    zoomClips
      .slice() // Create a shallow copy to avoid mutating the original array
      .sort((a, b) => a.startTime - b.startTime) // Sort by startTime
      .map(clip => ({
        id: clip.id, // Include if you need to track addition/removal of clips
        startTime: clip.startTime,
        duration: clip.duration,
        originX: clip.metadata.zoomValues.originX,
        originY: clip.metadata.zoomValues.originY,
        scale: clip.metadata.zoomValues.scale,
        motionSettings: clip.metadata.zoomValues.motionSettings,
        endMotionSettings: clip.metadata.zoomValues.endMotionSettings
      }))
  );

  const readyVideoClipsDependency = JSON.stringify(readyVideoClips);
  
  useEffect(() => {
    const frames = calculateVideoZoom(zoomClips, clip.id, clip.startTime, clip.duration);
    setZoomFrames(frames);
  }, [zoomClipsDependency, readyVideoClipsDependency, clip.startTime, clip.duration]);

  const findCurrentZoomFrame = (zoomFrames, currentTime) => {
    if (!zoomFrames || zoomFrames.length === 0) {
      return { scale: 1, originX: 0, originY: 0 };
    }

    let start = 0;
    let end = zoomFrames.length - 1;

    while (start <= end) {
      let mid = Math.floor((start + end) / 2);
      if (zoomFrames[mid].time === currentTime) {
        return zoomFrames[mid];
      } else if (zoomFrames[mid].time < currentTime) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    // Fallback to the closest frame if exact match not found
    const closestFrameIndex = Math.max(0, Math.min(end, zoomFrames.length - 1));
    return zoomFrames[closestFrameIndex];
  };

  useEffect(() => {
    const currentZoomFrame = findCurrentZoomFrame(zoomFrames, time);

    if (groupRef.current) {
      groupRef.current.scale.set(currentZoomFrame.scale, currentZoomFrame.scale, currentZoomFrame.scale);
      groupRef.current.position.set(
        -currentZoomFrame.originX + horizontalOffset, 
        currentZoomFrame.originY + verticalOffset, 
        0);
    }
  }, [zoomFrames, time, horizontalOffset, verticalOffset]);


  return (
    <group ref={groupRef}>

      {screenVideoBackground && visible && !isNoneBGColor &&
        <CanvasBackground background={screenVideoBackground} renderOrder={renderOrders.videoBG} sceneWidth={sceneWidth} sceneHeight={sceneHeight} />
      }
      <BasicVideoDevice 
        animationStates={animationStates}        
        initialProperties={initialProperties}
        FPS={FPS}

        showBasicVideoStatic={showBasicVideoStatic}
        
        clip={clip}
        time={time}
        localTime={relativeTime}
        visible={visible}          

        startTransitionType={startTransitionType}
        endTransitionType={endTransitionType}
        animationParams={animationStates}

        isLightBG={isLightBG}

        isPlaying={isPlaying}
        sceneWidth={sceneWidth}
        sceneHeight={sceneHeight}
      />      

    </group>
  );
};

export default BasicVideo
;



import {getOrgBrand} from './getOrgBrand'

export function getChromeFrameBorderIsDisabled  () {
  let chromeFrameBorderIsDisabled = false
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.chromeFrameBorderIsDisabled){
    chromeFrameBorderIsDisabled = orgBrand.screenVideoStyle.chromeFrameBorderIsDisabled
  }
  return chromeFrameBorderIsDisabled
}

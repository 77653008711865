import pronunciationDictionary from '../../actions/pronunciationDictionary';

function replacePronunciationWords(text) {
    let modifiedText = text;
    pronunciationDictionary.forEach(entry => {
      const regex = new RegExp(entry.word, 'gi');
      modifiedText = modifiedText.replace(regex, entry.alt);
    });
    return modifiedText;
  }

export function groupCharactersIntoWords(response, offset = 0) {
    // First, strip phoneme tags from the characters array while retaining the words inside
    const cleanedResponse = stripPhonemesFromCharacters(response);
   // console.log("cleanedResponse: " + JSON.stringify(cleanedResponse))

    const { characters, character_start_times_seconds, character_end_times_seconds } = cleanedResponse;
    const words = [];
    let currentWord = '';
    let wordStartTime = null;
    let wordEndTime = null;
    let i = 0;

    while (i < characters.length) {
        let char = characters[i];
        const startTime = character_start_times_seconds[i];
        const endTime = character_end_times_seconds[i];

        if (char === ' ' || char == '\n') { // if character is a space
            if (currentWord !== '') {
                // Check if the current word matches any pronunciation dictionary entries
                const dictEntry = pronunciationDictionary.find(entry => 
                    entry.alt && currentWord.toLowerCase().startsWith(entry.alt.toLowerCase()));
                
                if (dictEntry) {
                    // Keep the original word but replace the base part with the dictionary word
                    const baseLength = dictEntry.alt.length;
                    const restOfWord = currentWord.slice(baseLength);
                    words.push({
                        word: dictEntry.word + restOfWord,
                        start_time: wordStartTime + offset,
                        end_time: wordEndTime + offset
                    });
                } else {
                    // Handle special cases
                    if (currentWord === 'YOUR') {
                        currentWord = 'your';
                    }
                    if (currentWord === 'PERFECT') {
                        currentWord = 'perfect';
                    }
                    words.push({
                        word: currentWord,
                        start_time: wordStartTime + offset,
                        end_time: wordEndTime + offset
                    });
                }
                currentWord = '';
                wordStartTime = null;
                wordEndTime = null;
            }
        } else {
            if (currentWord === '') {
                wordStartTime = startTime;
            }
            currentWord += char;
            wordEndTime = endTime;
        }
        i++;
    }

    // Don't forget to add the last word if there is one
    if (currentWord !== '') {
        const dictEntry = pronunciationDictionary.find(entry => 
            entry.alt && currentWord.toLowerCase().startsWith(entry.alt.toLowerCase()));
        
        if (dictEntry) {
            // Keep the original word but replace the base part with the dictionary word
            const baseLength = dictEntry.alt.length;
            const restOfWord = currentWord.slice(baseLength);
            words.push({
                word: dictEntry.word + restOfWord,
                start_time: wordStartTime + offset,
                end_time: wordEndTime + offset
            });
        } else {
            // Handle special cases for the last word
            if (currentWord === 'YOUR') {
                currentWord = 'your';
            }
            if (currentWord === 'PERFECT') {
                currentWord = 'perfect';
            }
            words.push({
                word: currentWord,
                start_time: wordStartTime + offset,
                end_time: wordEndTime + offset
            });
        }
    }

  // console.log("words: " + JSON.stringify(words))

    return words;
}

function stripPhonemesFromCharacters(response) {
    const { characters, character_start_times_seconds, character_end_times_seconds } = response;
    const newCharacters = [];
    const newStartTimes = [];
    const newEndTimes = [];

    let i = 0;
    while (i < characters.length) {
        let char = characters[i];

        // Detect start of phoneme tag
        if (char === '<' && characters.slice(i, i + 8).join('') === '<phoneme') {
            // Skip until end of opening tag '>'
            while (i < characters.length && characters[i] !== '>') {
                i++;
            }
            i++; // Skip '>'
            // Now we are at the content inside the phoneme tag (the word)
            // Collect the word inside the phoneme tag
            while (i < characters.length) {
                char = characters[i];
                // Check if we have reached the start of the closing tag '</phoneme'
                if (char === '<' && characters.slice(i, i + 9).join('') === '</phoneme') {
                    // Skip until end of closing tag '>'
                    while (i < characters.length && characters[i] !== '>') {
                        i++;
                    }
                    i++; // Skip '>'
                    break;
                } else {
                    // Include the character and its timings
                    newCharacters.push(char);
                    newStartTimes.push(character_start_times_seconds[i]);
                    newEndTimes.push(character_end_times_seconds[i]);
                    i++;
                }
            }
        } else {
            // Include the character and its timings
            newCharacters.push(char);
            newStartTimes.push(character_start_times_seconds[i]);
            newEndTimes.push(character_end_times_seconds[i]);
            i++;
        }
    }

    return {
        characters: newCharacters,
        character_start_times_seconds: newStartTimes,
        character_end_times_seconds: newEndTimes,
    };
}


import React from 'react';
import Icon from '../../misc/Icon'

const EditorInsertLauncherResultsItem = ({ 
  option, 
  isActive, 
  onClick 
}) => {
  return (
    <button
      data-active-state={isActive ? 'active' : 'inactive'}
      className='editorInsertLauncher-resultsList-item'
      onClick={() => onClick(option)}
    >
      <div className='editorInsertLauncher-resultsList-item-leftContainer'>        
        <div className='editorInsertLauncher-resultsList-item-iconContainer'>
          <Icon name={option.iconName} />
        </div>
      </div>
      <div className='editorInsertLauncher-resultsList-item-centerContainer'>
        <div className='editorInsertLauncher-resultsList-item-title'>
          {option.title}
        </div>
        {/*<div className='editorInsertLauncher-resultsList-item-description'>
          {option.description}
        </div>
        */}
      </div>
      <div className='editorInsertLauncher-resultsList-item-rightContainer'>
        {isActive && 
          <div className='editorInsertLauncher-resultsList-item-shortcutIconContainer'>
            <Icon name='enterKey' />
          </div>          
        }
      </div>
    </button>
  );
};



const EditorInsertLauncherResultsList = ({ 
  filteredOptions, 
  selectedIndex, 
  onOptionClick 
}) => {  

  return (
    <div className="editorInsertLauncher-resultsList">
      {filteredOptions.map((option, index) => (
        <EditorInsertLauncherResultsItem
          key={option.id}
          option={option}
          isActive={index === selectedIndex}
          onClick={onOptionClick}
        />
      ))}
      {filteredOptions.length === 0 && (
        <div className="editorInsertLauncher-noResults">
          
        </div>
      )}

      
    </div>
  );
};

export default EditorInsertLauncherResultsList;
import { convertZoomValuesToZoomBox } from './convertZoomValuesToZoomBox';
import { calculateManualZoomSequence } from './manualZoomSequence';
import { simulateAnimationDuration } from '../animations/simulateAnimationDuration';

const FPS = 60;
const ANIMATION_TIME_FACTOR = 0.7; // Used for both pre-clip timing and transition time calculations

const defaultCameraBox = {
    width: 1920,
    height: 1080,
    x: 0,
    y: 0,
    motionSettings: 'zoomSmooth',
};

const animationParamsMap = {
    zoomSmooth: { mass: 1, stiffness: 120, damping: 30, precision: 10, instant: false },
    zoomZippy: { mass: 1, stiffness: 220, damping: 28, precision: 10, instant: false },
    zoomSlow: { mass: 1, stiffness: 120, damping: 80, precision: 10, instant: false },
    zoomInstant: { mass: 1, stiffness: 120, damping: 80, precision: 10, instant: true }
};

/**
 * Calculates an adjusted start time for a zoom clip.
 *
 * For far-apart clips, subtract 80% of the spring animation duration (computed via simulateAnimationDuration)
 * from the clip's start time. However, if the gap between the previous clip's end (lastEndTime)
 * and the current clip's start is less than MIN_GAP (e.g. 0.5 sec), then we simply use the clip's start time.
 */
function calculateAdjustedStartTime(clip, defaultBox, lastEndTime) {
    const { startTime, metadata: { zoomValues } } = clip;
    const initialCameraBox = convertZoomValuesToZoomBox({
        originX: zoomValues.originX,
        originY: zoomValues.originY,
        scale: zoomValues.scale
    });

    const durations = ['x', 'y', 'width', 'height'].map(property => {
        const startValueObj = { [property]: defaultBox[property] };
        const endValueObj = { [property]: initialCameraBox[property] };
        return simulateAnimationDuration(
            startValueObj,
            endValueObj,
            animationParamsMap[zoomValues.motionSettings],
            FPS
        );
    });

    const maxAnimationDuration = Math.max(...durations);
    return Math.max(startTime - (maxAnimationDuration * ANIMATION_TIME_FACTOR), lastEndTime + (1 / FPS));
}

/**
 * Calculates the time needed to animate between two camera boxes
 */
function calculateTransitionDuration(fromBox, toBox, motionSettings) {
    const durations = ['x', 'y', 'width', 'height'].map(property => {
        const startValueObj = { [property]: fromBox[property] };
        const endValueObj = { [property]: toBox[property] };
        return simulateAnimationDuration(
            startValueObj,
            endValueObj,
            animationParamsMap[motionSettings],
            FPS
        );
    });
    return Math.max(...durations);
}

export const calculateCameraBoxSequence = (zoomClips, currentClipId) => {
    const cameraBoxSequence = {};

    // Ensure we have a default camera box at time 0.
    const sortedZoomClips = [...zoomClips].sort((a, b) => a.startTime - b.startTime);
    if (!sortedZoomClips.length || sortedZoomClips[0].startTime >= 0.05) {
        cameraBoxSequence[0] = { ...defaultCameraBox };
    }

    let lastEndTime = 0;

    for (let i = 0; i < sortedZoomClips.length; i++) {
        const currentClip = sortedZoomClips[i];
        const nextClip = sortedZoomClips[i + 1];
        
        const { startTime, duration, metadata: { zoomValues }, scene: currentScene } = currentClip;
        const adjustedStartTime = calculateAdjustedStartTime(currentClip, defaultCameraBox, lastEndTime);
        let originalEndTime = startTime + duration;

        // Adjust the end time if this clip overlaps with the next clip in the same scene
        if (nextClip && 
            nextClip.scene.id === currentScene.id && 
            originalEndTime > nextClip.startTime) {
            originalEndTime = nextClip.startTime - (1 / FPS);
        }

        // Calculate the current clip's camera box
        const currentCameraBox = convertZoomValuesToZoomBox({
            originX: zoomValues.originX,
            originY: zoomValues.originY,
            scale: zoomValues.scale
        });

        // Calculate time needed to zoom out to default
        const timeToZoomOut = calculateTransitionDuration(
            currentCameraBox,
            defaultCameraBox,
            zoomValues.motionSettings
        );

        // If there's a next clip in the same scene, calculate time needed to zoom into it
        let timeToZoomIntoNext = 0;
        if (nextClip && nextClip.scene.id === currentScene.id) {
            const nextCameraBox = convertZoomValuesToZoomBox({
                originX: nextClip.metadata.zoomValues.originX,
                originY: nextClip.metadata.zoomValues.originY,
                scale: nextClip.metadata.zoomValues.scale
            });
            timeToZoomIntoNext = calculateTransitionDuration(
                defaultCameraBox,
                nextCameraBox,
                nextClip.metadata.zoomValues.motionSettings
            ) * ANIMATION_TIME_FACTOR;
        }

        // Total time needed for a complete transition through default camera box
        const requiredTransitionTime = timeToZoomOut + timeToZoomIntoNext;

        // Insert a default camera box before the clip if we have enough time to transition
        // and there's no previous clip in the same scene
        const previousClip = sortedZoomClips[i - 1];
        const isPreviousClipDifferentScene = !previousClip || previousClip.scene.id !== currentScene.id;
        
        if (isPreviousClipDifferentScene) {
            cameraBoxSequence[adjustedStartTime - (1 / FPS)] = {
                ...defaultCameraBox,
                motionSettings: 'zoomSmooth'
            };
        }

        // Calculate and add the clip's sequence
        const sequence = calculateManualZoomSequence({
            ...currentClip,
            startTime: adjustedStartTime
        });
        
        // If there is a next clip, only include keyframes until its start time
        if (nextClip && nextClip.scene.id === currentScene.id) {
            Object.keys(sequence).forEach(time => {
                if (parseFloat(time) < nextClip.startTime) {
                    cameraBoxSequence[time] = sequence[time];
                }
            });
        } else {
            Object.assign(cameraBoxSequence, sequence);
        }

        // Add a default camera box after the clip only if:
        // 1. There's no next clip, OR
        // 2. The next clip is in a different scene, OR
        // 3. There's enough time to transition and the next clip is in the same scene
        const isNextClipDifferentScene = !nextClip || nextClip.scene.id !== currentScene.id;
        
        if (isNextClipDifferentScene || 
            (nextClip.startTime - originalEndTime > requiredTransitionTime)) {
            const endMotionSettings = zoomValues.endMotionSettings || zoomValues.motionSettings || 'zoomSmooth';
            cameraBoxSequence[originalEndTime + (2 / FPS)] = {
                ...defaultCameraBox,
                motionSettings: endMotionSettings
            };
        }

        lastEndTime = originalEndTime;
    }

    return cameraBoxSequence;
};
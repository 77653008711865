import React, { useState, useEffect } from 'react';
import Measure from 'react-measure';
import CursorGameButton from './CursorGameButton';
import ConfettiExplosion from 'react-confetti-explosion';
import CursorGameVideoBG from './CursorGameVideoBG';


const CursorGame = () => {
  const [dimensions, setDimensions] = useState({ width: -1, height: -1 });
  const [buttons, setButtons] = useState([]);
  const [allClicked, setAllClicked] = useState(false);
  const [hoverAreas, setHoverAreas] = useState([]);

  const [stopwatchTime, setStopwatchTime] = useState(0);
  const [stopwatchStart, setStopwatchStart] = useState(null);
  const [stopwatchInterval, setStopwatchInterval] = useState(null);

  const inset = 20; // Inset for the fixed buttons
  const buttonSize = 16; // New constant for button size

  useEffect(() => {
    const generateRandomPosition = () => {
      // Updated to use buttonSize
      const x = Math.random() * (dimensions.width - buttonSize) + buttonSize/2;
      const y = Math.random() * (dimensions.height - buttonSize) + buttonSize/2;
      return { x, y };
    };

        // Add fixed buttons
    const fixedButtons = [      
      { x: dimensions.width - 20, y: 20, visible: true, id: 'top-right' },
      { x: 20, y: dimensions.height - 20, visible: true, id: 'bottom-left' },
      { x: dimensions.width - 20, y: dimensions.height - 20, visible: true, id: 'bottom-right' }
    ];

    // Add random buttons
    const randomButtons = [];
    for (let i = 0; i < 12; i++) {
      randomButtons.push({
        ...generateRandomPosition(),
        visible: true,
        id: `random-${i}`
      });
    }

    const generatedHoverAreas = [];    
    for (let i = 0; i < 4; i++) {
      generatedHoverAreas.push({
        ...generateRandomPosition(),
        id: `hover-${i}`
      });
    }

    setHoverAreas(generatedHoverAreas);

    setButtons([...fixedButtons, ...randomButtons]);
  }, [dimensions]);

   const startStopwatch = () => {
    if (!stopwatchStart) {
      const startTime = Date.now();
      setStopwatchStart(startTime);
      const interval = setInterval(() => {
        setStopwatchTime(Date.now() - startTime);
      }, 10);
      setStopwatchInterval(interval);
    }
  };

  const stopStopwatch = () => {
    if (stopwatchInterval) {
      clearInterval(stopwatchInterval);
      setStopwatchInterval(null);
    }
  };



  const handleButtonClick = (id) => {
    const visibleButtonCount = buttons.filter(button => button.visible).length;

    // Start the stopwatch when the first visible button is clicked
    if (visibleButtonCount === buttons.length) {
      startStopwatch();
    }

    const updatedButtons = buttons.map(button => 
      button.id === id ? { ...button, visible: false } : button
    );
    setButtons(updatedButtons);

    // Check if all buttons are clicked
    const allHidden = updatedButtons.every(button => !button.visible);
    if (allHidden) {
      setAllClicked(true);
      stopStopwatch();
    }
  };


    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    // Effect to add mouse move event listener
    useEffect(() => {
      const handleMouseMove = (e) => {
        setCursorPosition({ x: e.clientX, y: e.clientY });
      };

      window.addEventListener('mousemove', handleMouseMove);

      // Cleanup function
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);


  const formatStopwatchTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const milliseconds = Math.floor((time % 1000) / 10);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(2, '0')}`;
  };


  return (
    <Measure
      bounds
      onResize={contentRect => {
        setDimensions(contentRect.bounds);
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className='cursorGame'>
          {allClicked && (
            <ConfettiExplosion
              force={0.8}
              duration={3000}
              particleCount={200}
              floorHeight={window.innerHeight}
              floorWidth={window.innerWidth}
            />
          )}

            <div 
              className="cursorGame-fakeCursor" 
              style={{ 
                position: 'absolute', 
                left: cursorPosition.x, 
                top: cursorPosition.y
              }}
            />


          {buttons.map(button => button.visible && (
            <CursorGameButton
              key={button.id}
              label={`Button ${button.id}`}
              x={button.x}
              y={button.y}
              onClick={() => handleButtonClick(button.id)}
              displayWidth={dimensions.width}
              displayHeight={dimensions.height}
              buttonSize={buttonSize}              
            />
          ))}

          {hoverAreas.map(area => (
            <div 
              key={area.id}
              className="cursorGame-hoverAreas"
              style={{ 
                position: 'absolute', 
                left: area.x, 
                top: area.y,
                width: `${buttonSize}px`,  // Updated size
                height: `${buttonSize}px`, // Updated size               
              }}
            />
          ))}


          <div className='cursorGame-outerBorder' />
          <div className='cursorGame-dimensions'>
            <div className='cursorGame-dimensions-label'>
              W {dimensions.width}px
            </div>
            <div className='cursorGame-dimensions-label'>
              H {dimensions.height}px
            </div>            
          </div>

          {/*}
          <div className='cursorGame-stopwatch'>
            <div className='cursorGame-dimensions-label'>
              {formatStopwatchTime(stopwatchTime)}
            </div>            
          </div>
          */}

          {/*}
          <CursorGameVideoBG /> */}

        </div>
      )}
    </Measure>
  );
};

export default CursorGame;
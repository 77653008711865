import {getOrgBrand} from '../../utils/brands/getOrgBrand'

//Default is spring
//for webflow its lerp

export function getZoomAnimationType() {
  let zoomAnimationType = 'spring'
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.zoomAnimationType){
    zoomAnimationType = orgBrand.screenVideoStyle.zoomAnimationType
  }
  return zoomAnimationType
}

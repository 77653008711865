import {getOrgBrand} from '../../utils/brands/getOrgBrand'

export function getDefaultVideoWindowPadding() {
  let defaultVideoWindowPadding = 18
  const orgBrand = getOrgBrand()
  if (orgBrand?.screenVideoStyle?.windowPadding !== undefined) {
    defaultVideoWindowPadding = orgBrand.screenVideoStyle.windowPadding
  }
  return defaultVideoWindowPadding
}

import store from '../../store'
import {syncNewScreenRecording} from '../../actions/screenRecordings'
import { Mixpanel } from '../../Mixpanel'

//TODO might try and sync the same thing multiple times but dont worry about this for now as unique constraint on capture id will prevent making multiple entries
//TODO what if syncing has failed (not uploaded to s3 or cloudinary failed)
export async function maybeSyncRecording(captureId,isDevice) {
    // Retrieve state from the Redux store
    const state = store.getState();
    const recordings = state.screenRecordings;
    // console.log('recordings.lenth',recordings)
    if(recordings.length){ //dirty to make sure recordings have loaded wont work if very first recording was not synced but yeah ignore this
    // Check if the captureId exists in the recordings array
    const hasRecording = recordings.some(recording => `${recording.capture_id}` === `${captureId}`);

    // Log the result or perform actions based on whether the captureId is found
    if (hasRecording) {
      //  console.log(`Recording with captureId ${captureId} already exists.`);
        // Additional actions can be added here if the recording exists
    } else {
    //console.log(`No recording found with captureId ${captureId}.`);
       store.dispatch(syncNewScreenRecording(captureId,isDevice))
    }
}
}
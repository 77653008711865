import {webcamSizes as sizes, webcamPositions as positions, FLOAT_CAMERA_INSET} from './webcamConfigs'


export function calculateWebcamRectForSizeAndPosition(sizeName, position,sceneWidth,sceneHeight) {
  const size = sizes.find(s => s.name === sizeName);
  let webcamHeight = size.height 
  if(sceneHeight==1241 && size.height_16_10){
    webcamHeight = size.height_16_10
  }
  // console.log('calculateWebcamRectForSizeAndPosition sizeName',sizeName)
  // console.log('calculateWebcamRectForSizeAndPosition webcamHeight------>>>>>>>>>>>>>>',webcamHeight)

  let rect = {
    sizeName: sizeName,
    position: position,
    height: webcamHeight,
    width: size.width,
    cornerRadius: size.cornerRadius || 0,
    x: 0,
    y: 0
  };

  const verticalCenter = (sceneHeight - webcamHeight) / 2;
  const bottomVerticalFloat = sceneHeight - webcamHeight - FLOAT_CAMERA_INSET;
  const rightHorizontalFloat = sceneWidth - size.width - FLOAT_CAMERA_INSET;

  switch(position) {
    case 'topLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = FLOAT_CAMERA_INSET;
      break;
    case 'middleLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = verticalCenter;
      break;
    case 'bottomLeft':
      rect.x = FLOAT_CAMERA_INSET;
      rect.y = bottomVerticalFloat;
      break;
    case 'fillLeft':
      rect.x = 0;
      rect.y = 0;
      break;
    case 'center':
      rect.x = (sceneWidth - size.width) / 2;
      rect.y = verticalCenter;
      break;
    case 'topRight':
      rect.x = rightHorizontalFloat;
      rect.y = FLOAT_CAMERA_INSET;
      break;
    case 'middleRight':
      rect.x = rightHorizontalFloat;
      rect.y = verticalCenter;
      break;
    case 'bottomRight':
      rect.x = rightHorizontalFloat;
      rect.y = bottomVerticalFloat;
      break;
    case 'fillRight':
      rect.x = sceneWidth - size.width;
      rect.y = 0;
      break;
    default:
      break;
  }
  // console.log('sceneHeight',sceneHeight)
  // console.log('rect is',rect)

  return rect;
}
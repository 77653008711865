
import store from '../../store'



export async function downloadMissingVideos(videos) {
	const videosList = await ipcRenderer.invoke('get-video-uploads-list'); 
	for (const video of videos) {
		const fileId=video.id
		if (!videosList.includes(fileId)) {
			//console.log(`Downloading missing video: ${fileId}`);
			ipcRenderer.invoke('download-basic-video',fileId,video.delivery_url) 
			store.dispatch({ type: 'SET_BASIC_VIDEO_FILE_TO_DOWNLOAD', fileId})
		}else{
		//	console.log('we already have the recording ')
		}
	}
}

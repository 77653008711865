import {randomID} from '../../utils/randomID'


const DEFAULT_SKIP_DURATION = 3


export function addSkipSegment(originalSegments, skipTime, clipPlaybackRate, customDuration = null, isExpanded = true) {
    const skipDuration = customDuration || DEFAULT_SKIP_DURATION
    const adjustedSkipDuration = skipDuration * clipPlaybackRate

    const skipSegment = {
        id: randomID(),
        originalStart: skipTime,
        originalEnd: skipTime + skipDuration,
        playbackRate: null,
        originalDuration: adjustedSkipDuration,
        isSkipSegment: true,
        isExpanded: isExpanded,
        newDuration: isExpanded ? adjustedSkipDuration : 0,
    }

    let adjustedSegments = []
    let currentTime = 0
    let skipSegmentAdded = false

    originalSegments.forEach(segment => {
        let segmentEndTime = segment.originalStart + segment.originalDuration

        // Skip segments completely within the skip duration
        if (segment.originalStart >= skipTime && segmentEndTime <= skipTime + skipDuration) {
            return
        }

        if ((segment.originalStart <= skipTime && segmentEndTime >= skipTime) ||
            (segment.originalStart > skipTime && segment.originalStart < skipTime + skipDuration && segmentEndTime > skipTime + skipDuration)) {
            // Split segment
            if (segment.originalStart < skipTime) {
                let firstPart = { ...segment, id: randomID() }
                firstPart.originalEnd = skipTime
                firstPart.originalDuration = skipTime - firstPart.originalStart
                adjustedSegments.push(firstPart)
                currentTime = currentTime + firstPart.originalDuration
            }

            if (!skipSegmentAdded) {
                adjustedSegments.push(skipSegment)
                currentTime = currentTime + skipSegment.newDuration
                skipSegmentAdded = true
            }

            if (segmentEndTime > skipTime + skipDuration) {
                let secondPart = { ...segment, id: randomID() }
                secondPart.originalStart = skipTime + skipDuration
                secondPart.originalDuration = segmentEndTime - secondPart.originalStart
                adjustedSegments.push(secondPart)
                currentTime = currentTime + secondPart.originalDuration
            }
        } else {
            // Segment not affected
            let newSegment = { ...segment }
            adjustedSegments.push(newSegment)
            currentTime += segment.originalDuration
        }
    })

    return adjustedSegments.filter(seg => seg.originalDuration > 0)
}




import React, { useState } from 'react';
import Icon from '../../../misc/Icon';

// Tab component with active state handling
const AddFromLibraryDialogHeaderTab = ({ label, isActive, onClick, iconName, activeIconName }) => {
  return (
    <button
      onClick={onClick}
      data-active-state={isActive ? 'active' : 'inactive'}
      className='addFromLibraryDialog-header-tabBar-tab'
    >
      {/*}
      <div className='addFromLibraryDialog-header-tabBar-tab-iconContainer'>
        {isActive &&
          <Icon name={activeIconName} />
        }
        {!isActive &&
          <Icon name={iconName} />
        }
      </div>
      */}
      <div className='addFromLibraryDialog-header-tabBar-tab-label'>
        {label}
      </div>
      <div className='addFromLibraryDialog-header-tabBar-tab-bottomBorder' />
    </button>
  );
};

const AddFromLibraryDialogHeader = ({
  switchHeaderScroll,
  activeTypeTab,
  setActiveTypeTab,
  setSearchInputValue,
  searchInputValue
}) => {
  // Define tab types
  const tabTypes = [
    { id: 'everything', iconName: 'addFromLibraryMedium', activeIconName: 'addFromLibraryMediumFill', label: 'Everything' },
    //{ id: 'webcamClip', iconName: 'speakerRectangleMedium', activeIconName: 'mediaVideoFill', label: 'Camera' },
    { id: 'image', iconName: 'mediaImage', activeIconName: 'mediaImageFill', label: 'Images' },
    { id: 'screenClip', iconName: 'mediaScreen', activeIconName: 'mediaScreenFill', label: 'Screen Clips' },
    { id: 'videoClip', iconName: 'mediaVideo', activeIconName: 'mediaVideoFill', label: 'Videos' }

  ];

  const inputEmpty = searchInputValue.length < 1;

  const handleTabClick = (tabId) => {
    setActiveTypeTab(tabId);
  };

  const activeTabLabel = tabTypes.find(tab => tab.id === activeTypeTab)?.label || 'Everything';
  let searchPlaceholder = `Search ${activeTabLabel}...`


  return (
    <div className="addFromLibraryDialog-header">      

      <div className="addFromLibraryDialog-header-tabBar">
        {tabTypes.map((tab) => (
          <AddFromLibraryDialogHeaderTab
            key={tab.id}
            label={tab.label}
            isActive={activeTypeTab === tab.id}
            onClick={() => handleTabClick(tab.id)}
            iconName={tab.iconName}
            activeIconName={tab.activeIconName}
          />
        ))}
      </div>

      <div data-search-state={inputEmpty ? 'empty' : 'nonEmpty'} className="addFromLibraryDialog-header-searchBar">
        <div className="addFromLibraryDialog-header-searchBar-inputContainer">
          <div className="addFromLibraryDialog-header-searchBar-inputContainer-leftSearchUI">
            <div className="addFromLibraryDialog-header-searchBar-leftSearchUI-iconContainer">
              <Icon name="magnifyingGlass" />
            </div>
          </div>
          <input
            type="text"
            className="addFromLibraryDialog-header-searchBar-input"
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            placeholder={searchPlaceholder}
          />
        </div>
      </div>

      {/*}      

      <button data-layout-type='gallery' className='addFromLibraryDialog-header-layoutBtn'>
        <div className='addFromLibraryDialog-header-iconContainer'>
          <Icon name='galleryDisplayGallery' />
        </div>
        <div className='addFromLibraryDialog-header-label'>
          Gallery
        </div>
      </button>
      <button data-layout-type='grid' className='addFromLibraryDialog-header-layoutBtn'>
        <div className='addFromLibraryDialog-header-iconContainer'>
          <Icon name='galleryDisplayGrid' />
        </div>
        <div className='addFromLibraryDialog-header-label'>
          Grid
        </div>
      </button>

      <button className='addFromLibraryDialog-header-sortBtn'>
        <div className='addFromLibraryDialog-header-iconContainer'>
          <Icon name='sortArrows' />
        </div>
        
      </button>

      */}
    </div>
  );
};

export default AddFromLibraryDialogHeader;
export function updateTranscriptChunks(existingTranscript, newChunkBoundaries, trimStart, trimEnd) {
  if (!existingTranscript?.items || !newChunkBoundaries?.length) {
    return null;
  }
  
  const items = existingTranscript.items;
  
  // Keep chunks that are outside trim bounds
  const unchangedChunks = existingTranscript.chunks.filter(chunk => 
    chunk.endTime < trimStart || chunk.startTime > trimEnd
  );

//console.log('newChunkBoundaries',newChunkBoundaries)
  // Create new chunks for the boundaries within trim bounds
  const updatedChunks = newChunkBoundaries
    .sort((a, b) => a.firstIndex - b.firstIndex)
    .map(boundary => {
      const chunkItems = items.filter(item => 
        item.startTime >= boundary.startTime && 
        //item.originalIndex <= boundary.lastIndex
        item.startTime < boundary.endTime
      );
      
      if (chunkItems.length > 0) {
        return {
          startTime:boundary.startTime,
          endTime: boundary.endTime,
          items: chunkItems,
          text: chunkItems
            .filter(item => !item.isPause)
            .map(w => w.word)
            .join(' ')
            .replace(/\s+([.,!?])/g, '$1'),
          // firstIndex: boundary.firstIndex,
          // lastIndex: boundary.lastIndex
        };
      }
      return null;
    })
    .filter(chunk => chunk !== null);

  // Combine unchanged and new chunks, sorted by firstIndex
  const allChunks = [...unchangedChunks, ...updatedChunks]
    .sort((a, b) => a.firstIndex - b.firstIndex);


  return {
    ...existingTranscript,
    items,
    chunks: allChunks
  };
}
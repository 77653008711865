const TranscriptGroupNodeSpec = {
  group: "block",
  content: "(transcriptChunk | webcamRecordingChunk)+",
  attrs: {
    groupId: { default: null },
    groupType:{default:'standard'}, //standard or variable-webcam webcam-recording 
    hasInstructions:{default:false}, //is webcam.metadata.hasInstructions we set this flag and then use the text for instructions instead of tts
    isProcessing:{default:false},
    skipRanges:{default:[]},
    // skippedItems: { default: [] },  // Array of { text, startTime, endTime } objects
    linkedClipId:{default:null},
    clipTrimStart:{default:null},
    clipTrimEnd:{default:null}
  },
  toDOM(node) { return ['transcriptGroup', 0]; },
  parseDOM: [{ tag: 'transcriptGroup' }],
};

export default TranscriptGroupNodeSpec;

import { fetch } from '../../../api';

export const getScreenRecordingChunks = async (captureId) => {
    try {  
        const screenRecording = await fetch(`/screen-recordings/${captureId}`);
        const data = screenRecording?.recording_chunks?.chunks;
        if (data && data.length > 0) {
            return data
        }
        return await getScreenRecordingChunksFromFile(captureId)
    } catch (error) {
        console.error('Failed to load screen recording chunks:', error);
        return []; 
    }
};


export const getScreenRecordingChunksFromFile = async (captureId) => {
  const relativePath = `screenRecordings/${captureId}/chunks.json`;
  const content = await ipcRenderer.invoke('read-file', relativePath);
  const data = JSON.parse(content);
  if (data.chunks && data.chunks.length > 0) {
    return data.chunks
  }
  return []
}
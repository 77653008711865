export const getMasterRecordingCursorData = async (captureId) => {
  try {
    let data;
    
    if(window.isElectron){
      const relativePath = `screenRecordings/${captureId}/recording.input-events.json`;
      const content = await ipcRenderer.invoke('read-file', relativePath);
      data = JSON.parse(content);
    
    }else { // Server side export
      if (!captureId) {
        throw new Error('captureId is required for server-side export');
      }
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      const cursorDataUrl = `${s3BaseUrl}screenRecordings/${captureId}/recording.input-events.json`;
      const response = await fetch(cursorDataUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      data = await response.json();
    }
    //console.log('Raw cursor data:', data);
    
    return data.map(item => ({
      x: item.x,
      y: item.y,
      time: item.time.value/item.time.timescale,
      cursorType: item.cursorType,
      mouseEventType: item.mouseEventType
    }));
  } catch (error) {
    console.error('Failed to load mouse data:', error);
    return []; // Return an empty array in case of error
  }
};
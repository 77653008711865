import React, { useRef, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import createRoundedRectShape from '../../utils/createRoundedRectShape';
import ScreenVideoMaterial from '../ScreenVideoMaterial'
import ScreenVideoCursor from '../ScreenVideoCursor'
import defaultVideoAnimationValues from '../../utils/animations/defaultVideoAnimationValues'
import renderOrders from '../../renderOrders'
import ExportScreenVideoMaterial from '../ExportScreenVideoMaterial'


const FPS = 60; // Frames per second

const ScreenVideoDesktopModeDevice = ({
    meshWidth,
    meshHeight,
    clip,
    time,
    localTime,
    visible,
    initialProperties,
    animationStates,
    startTransitionType,
    endTransitionType,
    showScreenVideoStatic,
    cursorScale,
    isVideoResizeModeActive
  }) => {
 

  const deviceRef = useRef();  


  const meshRadius = 0

  //
  // DEVICE GEOMETRY  
  // const deviceGeometry = useMemo(() => {
  //   const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
  //   const geometry = new THREE.ShapeGeometry(shape);        
  //   // Compute UV mapping for the geometry using the provided logic that works
  //   const uvs = new Float32Array(geometry.attributes.position.count * 2);
  //   for (let i = 0; i < geometry.attributes.position.count; i++) {
  //     const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
  //     // Map x, y vertex position to uv coordinates
  //     uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
  //     uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
  //   }
  //   geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
  //   return geometry;
  // }, [meshWidth, meshHeight, meshRadius]);

  const deviceGeometry = useMemo(() => {
  // Use a simple PlaneGeometry instead of ShapeGeometry
    const geometry = new THREE.PlaneGeometry(meshWidth, meshHeight);
    return geometry;
  }, [meshWidth, meshHeight]);


  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showScreenVideoStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              deviceRef.current.material.opacity = state;            
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates, showScreenVideoStatic]);    

  return (
    <>          
      
      <mesh        
        ref={deviceRef}
        position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
        scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
        geometry={deviceGeometry}
        visible={visible}
        renderOrder={renderOrders.screenVideo}
      >
        {!clip.isExport &&
          <ScreenVideoMaterial 
            videoElement={clip.video}
            opacity={1} // update to animated     
          />
        }

        
        {clip.isExport && 
          <ExportScreenVideoMaterial 
            opacity={1} // update to animated     
            codecVideoClip={clip}   
          />
        }
         
       <ScreenVideoCursor          
          localTime={localTime}
          cursorScale={cursorScale}
          clip={clip}
          time={time}
          animationStates={animationStates}                    
          isVideoResizeModeActive={isVideoResizeModeActive}
        />
        
      
     
      </mesh>

    </>
  );
};

export default ScreenVideoDesktopModeDevice;



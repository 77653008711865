export const getNormalizedCursorData = async (
  masterRecordingCursorData,
  masterRecordingWidth,
  masterRecordingHeight,
  masterRecordingXOffset,
  masterRecordingYOffset
) => {
  try {
    // Adjust the time property of each object to start from zero and shift the origin
    const normalizedCursorData = masterRecordingCursorData.map(cursorPoint => ({
      x: ((cursorPoint.x *masterRecordingWidth ) - masterRecordingXOffset) - (masterRecordingWidth / 2),
      y: ((cursorPoint.y * masterRecordingHeight) - masterRecordingYOffset - (masterRecordingHeight / 2)) * -1, // Inverting y if needed
      time: cursorPoint.time,
      cursorType: cursorPoint.cursorType,
      mouseEventType: cursorPoint.mouseEventType
    }));
    return normalizedCursorData;
  } catch (error) {
    console.error('Failed to get calculate normalizedCursor Data:', error);
    return []; // Return an empty array in case of error
  }
};

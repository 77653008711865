import {webcamSizes as sizes, webcamPositions as positions, FLOAT_CAMERA_INSET,defaultSlideClipWebcamLayout,defaultVideoClipWebcamLayout} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'
import { calulateVideoTimeFromTimelineTime } from '../../timeline/utils/calulateVideoTimeFromTimelineTime';


export function getWebcamRectForTime(webcamLayout, currentTime, slideClips, videoClips,sceneWidth,sceneHeight) {
 

  const defaultRect = calculateWebcamRectForSizeAndPosition(
    webcamLayout.size,
    webcamLayout.position,
    sceneWidth,
    sceneHeight
  );

  // Check for slide clips first
  if (slideClips?.length > 0) {
    const overlappingSlideClip = slideClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingSlideClip) {
      const slideWebcamLayout = overlappingSlideClip.metadata.webcamLayout || defaultSlideClipWebcamLayout;
      return calculateWebcamRectForSizeAndPosition(
        slideWebcamLayout.size,
        slideWebcamLayout.position,
        sceneWidth,
        sceneHeight
      );
    }
  }

  // If no overlapping slide clip, check video clips
  if (videoClips?.length > 0) {
    const overlappingVideoClip = videoClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingVideoClip) {
      let videoWebcamLayout = overlappingVideoClip.metadata.webcamLayout || defaultVideoClipWebcamLayout;
      if (overlappingVideoClip.metadata.webcamLayouts && overlappingVideoClip.metadata.webcamLayouts.length > 0) {
        const clipTime = calulateVideoTimeFromTimelineTime(currentTime, overlappingVideoClip)
        videoWebcamLayout = overlappingVideoClip.metadata.webcamLayouts.find(
          layout => clipTime >= layout.startTime && clipTime < (layout.startTime + layout.duration)
        ) || defaultVideoClipWebcamLayout;
      }
      return calculateWebcamRectForSizeAndPosition(
        videoWebcamLayout.size,
        videoWebcamLayout.position,
        sceneWidth,
        sceneHeight
      );
    }
  }


  // Fallback to default if no overlapping clips found
  return defaultRect;
}

export function getWebcamSizeForTime(webcamLayout, currentTime, slideClips, videoClips) {
  const defaultSize = webcamLayout.size;
  if (slideClips?.length > 0) {
    const overlappingSlideClip = slideClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );
    if (overlappingSlideClip) {
      return overlappingSlideClip.metadata.webcamLayout.size;
    }
  }

  if (videoClips?.length > 0) {
    const overlappingVideoClip = videoClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingVideoClip) {
      let videoWebcamLayout = overlappingVideoClip.metadata.webcamLayout || defaultVideoClipWebcamLayout;
      if (overlappingVideoClip.metadata.webcamLayouts && overlappingVideoClip.metadata.webcamLayouts.length > 0) {
        const clipTime = calulateVideoTimeFromTimelineTime(currentTime, overlappingVideoClip)
        videoWebcamLayout = overlappingVideoClip.metadata.webcamLayouts.find(
          layout => clipTime >= layout.startTime && clipTime < (layout.startTime + layout.duration)
        ) || defaultVideoClipWebcamLayout;
      }
      return videoWebcamLayout.size;
    }
  }

  return defaultSize;
}

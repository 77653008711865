import React, { useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import * as Select from '@radix-ui/react-select';
import Icon from '../../misc/Icon';
import EditorDetailPanelSlideAnimationPanelItemDelayInput from './EditorDetailPanelSlideAnimationPanelItemDelayInput'
import DPAnimationPanelTransitionPopover from './DPAnimationPanelTransitionPopover'
//updateSlideElementMetadata(clipId,element.id,{...element.metadata,isFill:value})

const defaultStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade In", iconName: "fadeIn" },
  fadeAndMoveUp: { label: "Fade Up", iconName: "fadeUp" },
  fadeAndMoveDown: { label: "Fade Down", iconName: "fadeUp" },
  fadeAndMoveRight: { label: "Fade Left", iconName: "fadeRight" },
  fadeAndMoveLeft: { label: "Fade Right", iconName: "fadeRight" },
  fadeAndScaleUp: { label: "Scale Up", iconName: "scaleUp" },
  fadeAndScaleDown: { label: "Scale Down", iconName: "scaleDown" },  
  moveUp: { label: "Move Up", iconName: "scaleDown" },  
};

const donutChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },  
  countUp: { label: "Count Up", iconName: "countUp" },
  fadeIn: { label: "Fade In", iconName: "fadeIn" },
  scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};

const barChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  countUp: { label: "Count Up", iconName: "countUp" },
  // fadeIn: { label: "Fade In", iconName: "fadeIn" },
  // scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};


// const transitionConfigs = {
//     none: { label: 'None', iconName: 'noTransition' },
//     fade: { label: 'Fade In', iconName: 'fadeIn' },    
//     fadeAndMoveDown: { label: 'Fade Down', iconName: 'fadeDown' },    
//     fadeAndMoveUp: { label: 'Fade Up', iconName: 'fadeUp' },
//     fadeAndMoveLeft: { label: 'Fade Left', iconName: 'fadeLeft' },
//     fadeAndMoveRight: { label: 'Fade Right', iconName: 'fadeRight' },
//     fadeAndScaleUp: { label: 'Scale Up', iconName: 'scaleUp' },
//     fadeAndScaleDown: { label: 'Scale Down', iconName: 'scaleDown' },
//     countUp: { label: 'Count Up', iconName: 'scaleDown' },
//     fadeIn: { label: 'Fade In', iconName: 'scaleDown' },
//     scaleUp: { label: 'Scale Up', iconName: 'scaleDown' },    
// };


const EditorDetailPanelSlideAnimationPanelItem = ({ id,clipId,elementId,metadata,delay,activeStartTransition, updateSlideElementMetadata,label, isDragging, ITEM_HEIGHT, index, isOriginal,elementType,panelDetailType, setIsDragResizingNumberInput, chartType,itemType }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  let activeStartTransitionConfig 
  let startTransitionConfigs

  if(itemType=='barChart'){
    activeStartTransitionConfig = barChartStartTransitionConfigs[activeStartTransition]
    startTransitionConfigs = barChartStartTransitionConfigs
  }else if(itemType=='donutChart'){
    activeStartTransitionConfig = donutChartStartTransitionConfigs[activeStartTransition]
    startTransitionConfigs = donutChartStartTransitionConfigs
  }else{
    activeStartTransitionConfig = defaultStartTransitionConfigs[activeStartTransition]
    startTransitionConfigs=defaultStartTransitionConfigs
  }


const handleDelayChange = (newDelay) => {
  updateSlideElementMetadata(clipId, elementId, { ...metadata,enterDelay: newDelay });
};


const handleAnimationTypeChange = (animationType) => {
  let newMetadata = {...metadata}
  if(elementType === 'donutChart'){
    newMetadata.donutChartData.animationType=animationType
  }else if(elementType=='barChart'){
    newMetadata.barChartData.animationType=animationType
  }else{
    newMetadata.startTransitionType = animationType
  }
  updateSlideElementMetadata(clipId, elementId, newMetadata);
};

  const updateAnimationGrouping=(value)=>{    
    updateSlideElementMetadata(clipId, elementId, { ...metadata,animationGrouping: value });
  }


  const { attributes, listeners, setNodeRef, transform } = useDraggable({ 
    id,
    data: { label, index }
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    height: `${ITEM_HEIGHT}px`,
    position: 'absolute',
    top: `${index * ITEM_HEIGHT}px`,
    left: 0,
    right: 0,
    zIndex: isDragging ? 20 : (isOriginal ? 0 : 1),
    touchAction: 'none',
    opacity: isOriginal ? 0.25 : 1,
    pointerEvents: isOriginal ? 'none' : 'auto',
  };



  let displayLabel = label
  let itemAimationType = activeStartTransition // charts are different
  let iconName = 'textElementMedium'

  if(elementType === 'image'){
    iconName = 'insertBarImage'
  }
  if(elementType === 'donutChart'){
    iconName = 'chartDonut'
    displayLabel = 'Donut Chart'
  }
  if(elementType === 'barChart'){
    iconName = 'chartBar'
    displayLabel = 'Bar Chart'
  }


  return (
    <div
      ref={setNodeRef}
      style={style}
      data-dragging-state={isDragging ? 'true' : 'false'}
      data-underlay-item={isOriginal ? 'true' : 'false'}      
      data-menu-open={isMenuOpen ? 'true' : 'false'}
      className='editor-detailPanel--slide-animationPanel-item'
    >
      <div className='editor-detailPanel--slide-animationPanel-item-inner'>        
        <div 
          className='editor-detailPanel--slide-animationPanel-item-dragHandle'
          {...(isOriginal ? {} : { ...attributes, ...listeners })}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
          <div className='editor-detailPanel--slide-animationPanel-item-dragHandle-itemIconContainer'>
            <Icon name={iconName} />
          </div>
          <div className='editor-detailPanel--slide-animationPanel-item-dragHandle-dragIconContainer'>
            <Icon name='dragHandle' />
          </div>
        </div>
        {panelDetailType === 'animationType' &&           
        
          <DPAnimationPanelTransitionPopover
              activeTransition={activeStartTransition}
              onTransitionChange={handleAnimationTypeChange}
              transitionConfigs={startTransitionConfigs}
              animationGroupingType={metadata && metadata.animationGrouping}
              updateAnimationGrouping={updateAnimationGrouping}
              alignOffset={-15}
              sideOffset={-4}
              showGroupingOptions={elementType === 'text'}
            >
            <div className='editor-detailPanel--slide-animationPanel-item-content editor-detailPanel--slide-animationPanel-item-content--type'>
              <div className='editor-detailPanel--slide-animationPanel-item-label'>
                {displayLabel}
              </div>                                
              <div className='editor-detailPanel--slide-animationPanel-item-sub editor-detailPanel--slide-animationPanel-item-sub--animationType'>
                {startTransitionConfigs[activeStartTransition]?.label || activeStartTransition}
              </div>
            </div>
          </DPAnimationPanelTransitionPopover>                
        }
        {panelDetailType === 'delay' &&           
        <div className='editor-detailPanel--slide-animationPanel-item-content editor-detailPanel--slide-animationPanel-item-content--delay'>
          <div className='editor-detailPanel--slide-animationPanel-item-label'>
            {label}
          </div>      
          
          <div className='editor-detailPanel--slide-animationPanel-item-sub editor-detailPanel--slide-animationPanel-item-sub--delay'>
            {`${delay}s`}
          </div>     
          
          <EditorDetailPanelSlideAnimationPanelItemDelayInput 
            isDraggingParent={isDragging}
            enterDelay={delay}
            handleDelayChange={handleDelayChange}
            setIsDragResizingNumberInput={setIsDragResizingNumberInput}
          />                    
        </div>
      }


      </div>
    </div>
  );
};

export default EditorDetailPanelSlideAnimationPanelItem;
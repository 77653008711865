import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import ReactSlider from 'react-slider';

const DEFAULT_SLIDER_VALUE = 0.4;
const SNAPPING_RANGE = 0.05;

const DEFAULT_MUSIC_VOLUME = 0.08
const MIN_MUSIC_VOLUME = 0
const MAX_MUSIC_VOLUME = 1


function convertVolumeToSliderValue(volume) {
  let sliderValue 
    if (volume === DEFAULT_MUSIC_VOLUME) {
        sliderValue= DEFAULT_SLIDER_VALUE;
    } else if (volume < DEFAULT_MUSIC_VOLUME) {
        // Linearly scale the slider value from 0 to DEFAULT_SLIDER_VALUE as volume goes from MIN_MUSIC_VOLUME to DEFAULT_MUSIC_VOLUME
        sliderValue= (volume / DEFAULT_MUSIC_VOLUME) * DEFAULT_SLIDER_VALUE;
    } else {
        // Linearly scale the slider value from DEFAULT_SLIDER_VALUE to 1 as volume goes from DEFAULT_MUSIC_VOLUME to MAX_MUSIC_VOLUME
        sliderValue= DEFAULT_SLIDER_VALUE + (volume - DEFAULT_MUSIC_VOLUME) * (1 - DEFAULT_SLIDER_VALUE) / (MAX_MUSIC_VOLUME - DEFAULT_MUSIC_VOLUME);
    }
    return sliderValue
}



function convertSliderValueToVolume(sliderValue) {
  // console.log(`convert slider to volume`)
  // console.log(`sliderValue ---- ${sliderValue}`)
  let volume 
   if (sliderValue === DEFAULT_SLIDER_VALUE) {
        volume= DEFAULT_MUSIC_VOLUME;
    } else if (sliderValue < DEFAULT_SLIDER_VALUE) {
        // Linearly scale the volume from MIN_MUSIC_VOLUME to DEFAULT_MUSIC_VOLUME as the slider goes from 0 to DEFAULT_SLIDER_VALUE
        volume= (sliderValue / DEFAULT_SLIDER_VALUE) * DEFAULT_MUSIC_VOLUME;
    } else {
        // Linearly scale the volume from DEFAULT_MUSIC_VOLUME to MAX_MUSIC_VOLUME as the slider goes from DEFAULT_SLIDER_VALUE to 1
        volume= DEFAULT_MUSIC_VOLUME + (sliderValue - DEFAULT_SLIDER_VALUE) * (MAX_MUSIC_VOLUME - DEFAULT_MUSIC_VOLUME) / (1 - DEFAULT_SLIDER_VALUE);
    }

    volume = Math.max(MIN_MUSIC_VOLUME, Math.min(MAX_MUSIC_VOLUME, volume));
    // console.log(`volume is ---- ${volume}`)
    return parseFloat(volume.toFixed(2));
  
}



function EditorTimelineFooterMusicVolumnControl({backgroundMusicVolume,updateBackgroundMusicVolume}) {
  // State to store the volume
  const [sliderValue, setSliderValue] = useState(0);
  // State to track if the mouse is pressed down on the slider
  const [mouseIsDown, setMouseIsDown] = useState(false);
  useEffect(() => {
    const sliderValue = convertVolumeToSliderValue(backgroundMusicVolume)
    setSliderValue(sliderValue)
  }, [backgroundMusicVolume]);





  const lowerSnapBound = DEFAULT_SLIDER_VALUE - SNAPPING_RANGE;
  const upperSnapBound = DEFAULT_SLIDER_VALUE + SNAPPING_RANGE;

  // Function to update the volume state, possibly snapping back to default
  const handleSliderChange = (value) => {
    let newValue = value
    if (value >= lowerSnapBound && value <= upperSnapBound) {
      newValue = DEFAULT_SLIDER_VALUE
    }
    const newVolume = convertSliderValueToVolume(newValue)
    updateBackgroundMusicVolume(newVolume)

    //   setSliderValue(DEFAULT_VOLUME);
    // } else {
    //   setSliderValue(value);
    // }
  };

  const inRange = sliderValue >= lowerSnapBound && sliderValue <= upperSnapBound;

  let volumeIcon;
  if (sliderValue === 0) {
    volumeIcon = 'speaker-muted';
  } else if (sliderValue > 0 && sliderValue <= 0.25) {
    volumeIcon = 'speaker-wave-0';
  } else if (sliderValue > 0.25 && sliderValue <= 0.5) {
    volumeIcon = 'speaker-wave-1';
  } else if (sliderValue > 0.5 && sliderValue <= 0.75) {
    volumeIcon = 'speaker-wave-2';
  } else if (sliderValue > 0.75) {
    volumeIcon = 'speaker-wave-3';
  }

  return (
    <div data-state={mouseIsDown ? 'forceVisible' : 'notForceVisible'} className='editor-timelineFooter-musicVolume'>
      <div className='editor-timelineFooter-musicVolume-leftContainer'>
        <div className='editor-timelineFooter-musicVolume-iconContainer'>
          <Icon name={volumeIcon} />
        </div>
      </div>
      <div className='editor-timelineFooter-musicVolume-sliderContainer'>
        <ReactSlider
          className="editor-timelineFooter-musicVolume-slider"
          thumbClassName="editor-timelineFooter-musicVolume-slider-thumb"
          trackClassName="editor-timelineFooter-musicVolume-slider-track"
          renderThumb={(props) => {
            const { key, ...restProps } = props;
            return <div key={key} className='editor-timelineFooter-musicVolume-slider-thumb-thumb' {...restProps}></div>;
          }}
          min={0}
          max={1}
          step={0.01}
          value={sliderValue}
          onChange={handleSliderChange}
          onBeforeChange={() => setMouseIsDown(true)} // Handles mouse down
          onAfterChange={() => setMouseIsDown(false)} // Handles mouse up
        />
        <div data-state={inRange ? 'hidden' : 'visible'} style={{left: `${DEFAULT_SLIDER_VALUE * 100}%`}} className='editor-timelineFooter-musicVolume-sliderMark'/>
      </div>
    </div>
  );
}

export default EditorTimelineFooterMusicVolumnControl;

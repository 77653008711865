// DropShadow.js
import * as THREE from 'three';

export const createDropShadow = (deviceWidth, deviceHeight, shadowOptions, sceneWidth, sceneHeight) => {
  
  
  const {
    xOffset = 0,
    yOffset = 0,
    blur = 10,
    spread = 1,
    shadowColor = 'rgba(0,0,0,0.5)',
    opacity = 0.5,
  } = shadowOptions;

  

  // Use scene dimensions for the shadow canvas to ensure no clipping
  const canvas = document.createElement('canvas');
  canvas.width = sceneWidth;
  canvas.height = sceneHeight;

  const context = canvas.getContext('2d');
  context.filter = `blur(${blur}px)`;
  context.fillStyle = shadowColor;
  context.globalAlpha = opacity;

  // Calculate the center position for the shadow based on the device size
  const centerX = (sceneWidth - deviceWidth) / 2;
  const centerY = (sceneHeight - deviceHeight) / 2;

  // Draw the shadow rectangle with appropriate spread and blur
  context.fillRect(
    centerX + xOffset - spread - blur, // center shadow and apply offsets
    centerY - yOffset - spread - blur, // center shadow and apply offsets
    deviceWidth + spread * 2 + blur * 2, // add spread and blur on both sides
    deviceHeight + spread * 2 + blur * 2 // add spread and blur on both sides
  );

  // Reset alpha to default
  context.globalAlpha = 1.0;

  // Create texture and material for the shadow
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    depthWrite: false, // Prevents writing to the depth buffer
    depthTest: false, // Disables depth testing
  });

  // Create the shadow mesh
  const shadowMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(sceneWidth, sceneHeight),
    material
  );

  // Return the shadow mesh
  return shadowMesh;
};

const WebcamClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'webcam'},
		captureId:{default:null},
		sessionCaptureId:{default:null},
		isUploadingVideo:{default:false},
		fileName:{default:null},
		relativeStartTime: {default:0},
		relativePinnedStartTime:{default:null},
		duration:  {default:0},
		recordingDuration:  {default:0},
		recordingSegments:{default:[]},
		segments:{default:[]},
		metadata: { default: [] },
		zIndex:  {default:0},
		sceneId:{default:'defaultSceneId'},
		clipIndex:{default:0},

	},
};

 export default WebcamClipNodeSpec
 

 
import React, { useRef, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';
import { calculateWebcamCrop } from '../utils/calculateWebcamCrop';

const WebcamVideoPreviewMaterial = ({ 
  videoElement, 
  opacity, 
  meshWidth, 
  meshHeight, 
  videoAssetWidth, 
  videoAssetHeight,
  faceBox,
  renderOrder,
  applyFaceBox,
  isDelayedDraggingResizing,
  immediate,
  isDraggingWebcam,
  isResizingWebcam,
  colorAdjustments = {},
  currentTime = 0,
  transcript,
  startTime = 0,
  facePaddingRatio
}) => {
  const { invalidate } = useThree();
  const materialRef = useRef();
  
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [shouldZoomIn, setShouldZoomIn] = useState(false);

  // Handle transcript-based zoom
  useEffect(() => {
    if (currentTime - lastUpdateTime >= 0.1) {
      if (transcript && transcript.words) {
        const currentWord = transcript.words.find(word => 
          currentTime >= (word.start + startTime) && 
          currentTime <= (word.end + startTime)
        );

        const isInGapBetweenEmphasized = transcript.words.some(word => {
          const wordStart = word.start + startTime;
          const wordEnd = word.end + startTime;
          return word.emphasized && 
                 currentTime > wordEnd && 
                 currentTime < wordEnd + 0.5 &&
                 transcript.words.some(nextWord => 
                   nextWord.emphasized && 
                   (nextWord.start + startTime) > currentTime && 
                   (nextWord.start + startTime) < currentTime + 0.5
                 );
        });

        setShouldZoomIn(currentWord?.emphasized || isInGapBetweenEmphasized);
      }
      setLastUpdateTime(currentTime);
    }
  }, [transcript, currentTime, startTime, lastUpdateTime]);

  const videoTexture = useMemo(() => {
    if (videoElement) {
      const texture = new THREE.VideoTexture(videoElement);
      texture.generateMipmaps = true;
      texture.minFilter = THREE.LinearMipmapLinearFilter;
      texture.colorSpace = THREE.SRGBColorSpace;      
      texture.magFilter = THREE.LinearFilter;
      texture.needsUpdate = true;
      return texture;
    }
  }, [videoElement]);

  // Calculate crop values based on current dimensions
  const crop = useMemo(() => {
    return calculateWebcamCrop({
      originalWidth: videoAssetWidth,
      originalHeight: videoAssetHeight,
      targetWidth: meshWidth,
      targetHeight: meshHeight,
      faceBox: faceBox,
      zoomIn: shouldZoomIn,
      applyFaceBox: applyFaceBox,
      facePaddingRatio: facePaddingRatio
    });
  }, [
    videoAssetWidth,
    videoAssetHeight,
    faceBox,
    meshWidth,
    meshHeight,
    shouldZoomIn,
    applyFaceBox,
    facePaddingRatio
  ]);

  // Create springs for UV scale and offset
  const [springs] = useSpring(() => ({
    scaleX: crop.cropWidth,
    scaleY: crop.cropHeight,
    offsetX: crop.cropX,
    offsetY: 1.0 - crop.cropY - crop.cropHeight,
    config: {
      tension: 280,
      friction: 24,
    },
    immediate: immediate || isDraggingWebcam || isResizingWebcam
  }), [crop, immediate, isDraggingWebcam, isResizingWebcam]);

  const { 
    exposure = 0,
    whiteBalance = 0 
  } = colorAdjustments;

  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        videoTexture: { value: videoTexture },
        opacity: { value: opacity },
        uvScale: { value: new THREE.Vector2(1, 1) },
        uvOffset: { value: new THREE.Vector2(0, 0) },
        exposure: { value: exposure },
        whiteBalance: { value: whiteBalance }
      },
      vertexShader: `
        uniform vec2 uvScale;
        uniform vec2 uvOffset;
        varying vec2 vUv;
        void main() {
          vUv = uv * uvScale + uvOffset;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D videoTexture;
        uniform float opacity;
        uniform float exposure;
        uniform float whiteBalance;
        varying vec2 vUv;

        vec3 adjustExposure(vec3 color, float exposure) {
          return color * pow(2.0, exposure * 0.75);
        }
        
        vec3 srgbToLinear(vec3 color) {
          return vec3(
            color.r < 0.04045 ? color.r / 12.92 : pow((color.r + 0.055) / 1.055, 2.4),
            color.g < 0.04045 ? color.g / 12.92 : pow((color.g + 0.055) / 1.055, 2.4),
            color.b < 0.04045 ? color.b / 12.92 : pow((color.b + 0.055) / 1.055, 2.4)
          );
        }

        vec3 linearToSrgb(vec3 color) {
          return vec3(
            color.r < 0.0031308 ? 12.92 * color.r : 1.055 * pow(color.r, 1.0/2.4) - 0.055,
            color.g < 0.0031308 ? 12.92 * color.g : 1.055 * pow(color.g, 1.0/2.4) - 0.055,
            color.b < 0.0031308 ? 12.92 * color.b : 1.055 * pow(color.b, 1.0/2.4) - 0.055
          );
        }

        vec3 adjustWhiteBalance(vec3 color, float temperature) {
          vec3 linearColor = srgbToLinear(color);
          float t = clamp(temperature * 0.5 + 0.5, 0.0, 1.0);
          float redAdjustment = 1.0 + (t - 0.5) * 0.3;
          float blueAdjustment = 1.0 - (t - 0.5) * 0.3;
          vec3 adjustedColor = linearColor * vec3(redAdjustment, 1.0, blueAdjustment);
          return linearToSrgb(adjustedColor);
        }

        void main() {
          vec4 videoColor = texture2D(videoTexture, vUv);
          vec3 color = videoColor.rgb;
          
          color = adjustExposure(color, exposure);
          color = adjustWhiteBalance(color, whiteBalance);
          
          gl_FragColor = vec4(color, videoColor.a * opacity);
        }
      `,
      transparent: true
    });
  }, [videoTexture, opacity]);

  useFrame(() => {
    if (materialRef.current && videoTexture) {
      materialRef.current.uniforms.uvScale.value.set(springs.scaleX.get(), springs.scaleY.get());
      materialRef.current.uniforms.uvOffset.value.set(springs.offsetX.get(), springs.offsetY.get());
      materialRef.current.uniforms.videoTexture.value = videoTexture;
      materialRef.current.uniforms.opacity.value = opacity;
      materialRef.current.uniforms.exposure.value = exposure;
      materialRef.current.uniforms.whiteBalance.value = whiteBalance;
      materialRef.current.needsUpdate = true;
    }
  });

  useEffect(() => {
    if (materialRef.current && renderOrder !== undefined) {
      materialRef.current.renderOrder = renderOrder;
      invalidate();
    }
  }, [renderOrder, invalidate]);

  return <primitive object={shaderMaterial} ref={materialRef} attach="material" />;
};

export default WebcamVideoPreviewMaterial;
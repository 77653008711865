import React, { useState, useEffect, useRef } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import AddFromLibraryDialogGridItem from './AddFromLibraryDialogGridItem';


const PREVIEW_ITEM_HEIGHT = 230;
const ITEM_FOOTER_HEIGHT = 41;
const ROW_HEIGHT = PREVIEW_ITEM_HEIGHT + ITEM_FOOTER_HEIGHT;
const ITEM_OUTER_PADDING = 7;
const ITEM_INNER_PADDING = 4;

const mediaGallerySizing = (media, rowHeight, rowWidth, minItemWidth, maxItemWidth) => {
    if (!media || !media.length) return [];
    
    const footerHeight = ITEM_FOOTER_HEIGHT;
    const outerPadding = ITEM_OUTER_PADDING;
    const innerPadding = ITEM_INNER_PADDING;
    const totalPadding = (outerPadding + innerPadding) * 2;
    
    const availableHeight = rowHeight - totalPadding - footerHeight;
    
    const rows = [];
    let currentRow = [];
    let currentRowWidth = 0;

    media.forEach((item) => {
        const aspectRatio = item.width / item.height;
        let width = availableHeight * aspectRatio;

        if (width < minItemWidth) width = minItemWidth;
        if (width > maxItemWidth) width = maxItemWidth;

        if (currentRowWidth + width <= rowWidth) {
            currentRow.push({ 
                ...item, 
                calculatedWidth: width,
                originalAspectRatio: aspectRatio
            });
            currentRowWidth += width;
        } else {
            if (currentRow.length > 0) {
                const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.calculatedWidth, 0);
                const scale = rowWidth / totalCurrentRowWidth;

                const scaledRow = currentRow.map(img => ({
                    ...img,
                    containerWidth: Math.floor(img.calculatedWidth * scale)
                }));

                rows.push(scaledRow);
            }

            currentRow = [{ 
                ...item, 
                calculatedWidth: width, 
                originalAspectRatio: aspectRatio 
            }];
            currentRowWidth = width;
        }
    });

    if (currentRow.length > 0) {
        const totalCurrentRowWidth = currentRow.reduce((sum, img) => sum + img.calculatedWidth, 0);
        const scale = rowWidth / totalCurrentRowWidth;

        const scaledRow = currentRow.map(img => ({
            ...img,
            containerWidth: Math.floor(img.calculatedWidth * scale)
        }));
        rows.push(scaledRow);
    }

    return rows;
};

const findMatchingSearchResult = (mediaItem, searchResults) => {
  if (!searchResults) return null;
  
  const result = searchResults.find(result => {
    const captureIdMatches = result.capture_id === mediaItem.captureId;
    
    if (!captureIdMatches) return false;
    
    // For chunk_index -1, match with items that don't have a chunkIndex
    if (result.chunk_index === "-1") {
      return mediaItem.chunkIndex === null;
    }
    
    // For specific chunk_index, match with items that have the same chunkIndex
    return parseInt(result.chunk_index) === mediaItem.chunkIndex;
  });
  
  return result?.summary;
};

const Row = ({ mediaItems, rowHeight, insertFromLibrary, searchResults }) => {
  return (
    <div className="popover--addMediaPopover-list-row" style={{ height: `${rowHeight}px` }}>
      {mediaItems.map((mediaItem, index) => (
        <AddFromLibraryDialogGridItem
          key={index}
          mediaItem={mediaItem}
          index={index}
          containerWidth={mediaItem.containerWidth}
          rowHeight={rowHeight}
          outerPadding={ITEM_OUTER_PADDING}
          innerPadding={ITEM_INNER_PADDING}
          footerHeight={ITEM_FOOTER_HEIGHT}
          insertFromLibrary={insertFromLibrary}
          summary={findMatchingSearchResult(mediaItem, searchResults)}
        />
      ))}
    </div>
  );
};

function AddFromLibraryDialogGrid({ media, rowWidth, insertFromLibrary, activeTypeTab, searchResults }) {
  const listRef = useRef(null);
  const rowHeight = ROW_HEIGHT;
  const minItemWidth = 180;
  const maxItemWidth = 320;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToPosition(0);
    }
  }, [activeTypeTab]);

  useEffect(() => {
    if (media && media.length > 0) {
      const calculatedRows = mediaGallerySizing(
        media,
        rowHeight,
        rowWidth,
        minItemWidth,
        maxItemWidth
      );
      setRows(calculatedRows);
    }
  }, [media, rowWidth]);

  const rowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style}>
        <Row
          mediaItems={rows[index]}
          rowHeight={rowHeight}
          insertFromLibrary={insertFromLibrary}
          searchResults={searchResults}
        />
      </div>
    );
  };

  return (
    <div className="addFromLibraryDialog-scrollContainer" style={{ height: '100%' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            width={width || rowWidth}
            height={height || 400}
            rowCount={rows.length}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
            overscanRowCount={3}
            className="addFromLibraryDialog-grid"
          />
        )}
      </AutoSizer>
    </div>
  );
}

export default AddFromLibraryDialogGrid;
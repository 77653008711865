import React, { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

const TOP_OFFSET = 50;
const LEFT_OFFSET = 20;
const LEFT_ALIGN_POPOVER_THRESHOLD = 44;
const RIGHT_ALIGN_POPOVER_THRESHOLD = 355;
const MIN_ADJUSTED_LEFT = 34;
const END_OF_PARAGRAPH_RIGHT_OFFSET = 6; // Additional offset for end-of-paragraph markers

const TranscriptPanelWebcamSkipMarkers = ({ skipMarkers, restoreWebcamSkip }) => {
  // State to track which popover is open
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);

  // Handle right-click on a skip marker
  const handleContextMenu = (e, index) => {
    e.preventDefault(); // Prevent default context menu
    setOpenPopoverIndex(index);
  };

  return (
    <>
      {skipMarkers && skipMarkers.map((marker, i) => {
        const adjustedTop = marker.position.top - TOP_OFFSET;
        
        // Apply additional right offset for end-of-paragraph markers
        let adjustedLeft;
        if (marker.isEndOfParagraph) {
          adjustedLeft = Math.max((marker.position.left - LEFT_OFFSET + END_OF_PARAGRAPH_RIGHT_OFFSET), MIN_ADJUSTED_LEFT);
        } else {
          adjustedLeft = Math.max((marker.position.left - LEFT_OFFSET), MIN_ADJUSTED_LEFT);
        }

        let popoverAlignment = 'center';

        if (adjustedLeft < LEFT_ALIGN_POPOVER_THRESHOLD) {
          popoverAlignment = 'left';
        }
        if (adjustedLeft > RIGHT_ALIGN_POPOVER_THRESHOLD) {
          popoverAlignment = 'right';
        }

        return (
          <div
            key={`skip_marker_${i}`}
            style={{
              top: adjustedTop,
              left: adjustedLeft
            }}
            className="editor-transcriptPanel-list-skipMarkerContainer"            
            data-skip-marker-type={marker.isScreencastLinked ? 'screencastLinked' : 'camera'}                
          >
            <Popover.Root open={openPopoverIndex === i} onOpenChange={(open) => {
              if (open) {
                setOpenPopoverIndex(i);
              } else if (openPopoverIndex === i) {
                setOpenPopoverIndex(null);
              }
            }}>
              <Popover.Trigger asChild>              
                <button 
                  data-line-start={marker.position.left < 47}
                  data-para-end={marker.isEndOfParagraph ? true : false}
                  className="editor-transcriptPanel-list-skipMarker"
                  onContextMenu={(e) => handleContextMenu(e, i)}
                >
                  <div className="editor-transcriptPanel-list-skipMarker-inner">
                    <div className="editor-transcriptPanel-list-skipMarker-inner-line" />
                    
                  </div>          
                  <div data-alignment-state={popoverAlignment} className="editor-transcriptPanel-list-skipMarker-tooltipContainer">
                    <div className="tooltip editor-transcriptPanel-list-skipMarker-tooltip">
                      Edit Cut
                    </div>
                  </div>
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content 
                  className={"editor-transcriptPanel-list-skipMarker-popover " + (marker.isScreencastLinked ? ' editor-transcriptPanel-list-skipMarker-popover--screencastLinked ' : ' editor-transcriptPanel-list-skipMarker-popover--camera ')}
                  align="start"
                  alignOffset={-40}
                  side="top"
                  sideOffset={5}
                  collisionPadding={10}
                  onEscapeKeyDown={() => setOpenPopoverIndex(null)}
                  onInteractOutside={() => setOpenPopoverIndex(null)}
                >
                  <div className="editor-transcriptPanel-list-skipMarker-header">
                    <div className="editor-transcriptPanel-list-skipMarker-header-title">
                      Deleted
                    </div>
                    <button 
                      onClick={() => restoreWebcamSkip(marker)}
                      className="editor-transcriptPanel-list-skipMarker-header-restoreBtn"
                    >
                      Restore
                    </button>
                  </div>   
                  <div className="editor-transcriptPanel-list-skipMarker-content">
                    {marker.items.map(item => item.word||'.').join(' ')}
                  </div>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        );
      })}
    </>
  );
};

export default TranscriptPanelWebcamSkipMarkers;
import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../../../misc/Icon';

const TranscriptPanelVariableWebcamHeader = (props) => {
  
  const { 
    hasInstructions, 
    isVariable, 
    y,
    deleteWebcamClipFromHeader,
    clipId,
    setWebcamHasInstructionsFromHeader,
    recordWebcamClipFromHeader } = props

  let cameraType = 'script'
  let title = 'Script'

  if(hasInstructions){
    cameraType = 'variable'
    title = 'Instructions'
  }

  const showRecordButton = true

  // Use absolute positioning relative to the scrollable container
  // The y value is already calculated relative to the content
  // This ensures headers stay aligned with their content when scrolling
  return (
    <div style={{top: `${y + 89}px`, position: 'absolute'}} className='editor-transcriptPanel-webcamHeader'>          
        
      {!hasInstructions &&
        <button onClick={()=>{recordWebcamClipFromHeader(clipId)}} data-camera-type={cameraType} data-instructions-type={hasInstructions ? 'instructions' : 'script'}  className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--script'>
          
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--default'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Script
            </div>
          </div>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--hover'>          
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='cameraOutline' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Record Now
            </div>
          </div>
          {/*}
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Script
            </div>
          </div>
          */}
        </button>
      }

      {hasInstructions &&
        <button data-camera-type={cameraType} data-instructions-type={hasInstructions ? 'instructions' : 'script'}  className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--instructions'>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              {title}
            </div>
          </div>
        </button>
      }

      <div className='editor-transcriptPanel-webcamHeader-hSpacer' />

      <DropdownMenu.Root modal={false}> 
        <DropdownMenu.Trigger asChild>
          
          <button data-instructions-type={hasInstructions ? 'instructions' : 'script'} className='editor-transcriptPanel-webcamHeader-dropdownBtn'>
            <div className='editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer'>
              <Icon name='ellipses' />
            </div>          
          </button>

        </DropdownMenu.Trigger>
             
        <DropdownMenu.Portal>
          <DropdownMenu.Content             
            align="start"
            alignOffset={-28}
            className='dropdownMenu dropdownMenu--webcamHeader forceDarkTheme'
          >

            
            {showRecordButton && isVariable &&
              <DropdownMenu.Item onSelect={()=>{recordWebcamClipFromHeader(clipId)}} >              
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='screenVideoClipMediumAlt2' />
                </div>               
                Record now       
              </DropdownMenu.Item>  
            }            
            
            {!hasInstructions &&
              <DropdownMenu.Item onSelect={()=>{setWebcamHasInstructionsFromHeader(clipId,true)}}>              
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='speakerRectangleMedium' />
                </div>                  
                Make instructions...
              </DropdownMenu.Item>
            }

            {hasInstructions && 
              <DropdownMenu.Item onSelect={()=>{setWebcamHasInstructionsFromHeader(clipId,false)}}>              
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='speakerRectangleMedium' />
                </div>
                Make script...
              </DropdownMenu.Item>
            }

            

            {/*}
            <DropdownMenu.Separator/>
            <DropdownMenu.Item>              
              Convert to voiceover
            </DropdownMenu.Item>
            */}
            <DropdownMenu.Separator/>
            <DropdownMenu.Item onSelect={()=>{deleteWebcamClipFromHeader(clipId)}}> 
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='deleted' />
              </div>                             
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>       
        </DropdownMenu.Portal>   
      </DropdownMenu.Root>
    </div>
  );
};

const TranscriptPanelStaticWebcamHeader = (props) => {
  const {     
    y,
    deleteWebcamClipFromHeader,
    clipId,
    screencastLinked,
    unlinkScreencast,
    recordWebcamClipFromHeader,
    convertWebcamRecordingIntoScript,
    convertWebcamRecordingIntoVoiceover,
    isLoadingTranscript,
    handleSeekToClipInTimelineWithScroll
  } = props

  //const isLoadingTranscript = false


  return (
    <div data-camera-type={screencastLinked ? 'screencastLinked' : 'static'} style={{top: `${y + 89}px`, position: 'absolute'}} className='editor-transcriptPanel-webcamHeader'>          

        <button onClick={()=>{handleSeekToClipInTimelineWithScroll(clipId)}} data-camera-type={screencastLinked ? 'screencastLinked' : 'static'} className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--static'>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              {screencastLinked ? <Icon name='screenCastMedium' /> : <Icon name='speakerRectangleMedium' />}
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              {screencastLinked ? 'Screen Clip' : 'Camera'}              
            </div>

          </div>
        </button>

        <div className='editor-transcriptPanel-webcamHeader-hSpacer' />        

      <DropdownMenu.Root modal={false}> 
        <DropdownMenu.Trigger asChild>
          
          <button data-camera-type='static' className='editor-transcriptPanel-webcamHeader-dropdownBtn'>
            <div className='editor-transcriptPanel-webcamHeader-dropdownBtn-iconContainer'>
              <Icon name='ellipses' />
            </div>
          </button>


        </DropdownMenu.Trigger>
             
        <DropdownMenu.Portal>
          <DropdownMenu.Content             
            align="start"
            alignOffset={-28}
            className='dropdownMenu dropdownMenu--webcamHeader forceDarkTheme'
          >
            
            
            {!screencastLinked &&
            <>
              <DropdownMenu.Item onSelect={()=>{recordWebcamClipFromHeader(clipId)}}>              
                Re-record now
              </DropdownMenu.Item>
              <DropdownMenu.Item onSelect={()=>{convertWebcamRecordingIntoScript(clipId)}}>           
                Edit as script...
              </DropdownMenu.Item>   
              <DropdownMenu.Separator/>
            </>
            }   

            
            <DropdownMenu.Item onSelect={()=>{convertWebcamRecordingIntoVoiceover(clipId)}}>           
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='para' />
              </div>                
              Convert to voiceover
            </DropdownMenu.Item>            
            {screencastLinked && 
              <DropdownMenu.Item onSelect={()=>{unlinkScreencast(clipId)}}>              
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='speakerRectangleMedium' />
                </div>   

                Delink camera
              </DropdownMenu.Item>
            }
            <DropdownMenu.Separator/>
            <DropdownMenu.Item onSelect={()=>{deleteWebcamClipFromHeader(clipId)}}>              
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='deleted' />
              </div>
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>       
        </DropdownMenu.Portal>   
      </DropdownMenu.Root>

      {isLoadingTranscript &&
        <div className='editor-transcriptPanel-webcamHeader-loadingTranscript'>
          <div className='editor-transcriptPanel-webcamHeader-loadingTranscript-inner'>
            •••••
          </div>
        </div>
      }

    </div>
  );
};

const TranscriptPanelWebcamHeaders = (props) => {
  

  return (
    <>    
      {props.webcamHeaders.map((header, index) => {
        // Log each header's position as it's rendered
        // console.log(`Rendering header ${header.clipId} at position ${header.dimensions.top + 89}px`);
        
        return header.isVariable ? (
          <TranscriptPanelVariableWebcamHeader 
            key={index}
            clipId={header.clipId}
            hasInstructions={header.hasInstructions}
            isVariable={header.isVariable}
            y={header.dimensions.top}
            deleteWebcamClipFromHeader={props.deleteWebcamClipFromHeader}
            setWebcamHasInstructionsFromHeader={props.setWebcamHasInstructionsFromHeader}
            recordWebcamClipFromHeader={props.recordWebcamClipFromHeader}
          />
        ) : (
          <TranscriptPanelStaticWebcamHeader 
            key={index}
            clipId={header.clipId}        
            y={header.dimensions.top}
            deleteWebcamClipFromHeader={props.deleteWebcamClipFromHeader}
            recordWebcamClipFromHeader={props.recordWebcamClipFromHeader}
            screencastLinked={header.isLinked}
            unlinkScreencast={props.unlinkScreencast}
            convertWebcamRecordingIntoScript={props.convertWebcamRecordingIntoScript}
            convertWebcamRecordingIntoVoiceover={props.convertWebcamRecordingIntoVoiceover}
            isLoadingTranscript={header.isProcessing}
            handleSeekToClipInTimelineWithScroll={props.handleSeekToClipInTimelineWithScroll}
          />
        );
      })}      
    </>
  );
};

export default TranscriptPanelWebcamHeaders;
import {TimelineClip} from './TimelineClip'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import {defaultColorAdjustments,defaultWebcamLayout} from '../utils/webcam/webcamConfigs'
import {fetchWebcamVideo,fetchPlaceholderWebcamVideo} from '../utils/webcam/fetchWebcamVideo'
import {getTranscriptForWebcamCaptureId} from '../utils/webcam/getTranscriptForWebcamCaptureId'
import {updateTranscriptChunks} from '../utils/webcam/updateTranscriptChunks'
import {calculateWebcamRecordingSegments} from '../utils/webcam/calculateWebcamRecordingSegments'
import store from '../store'
import { fetchSpeechToSpeech } from '../actions/speechToSpeech'
import { getFaceBoxForWebcamCaptureId } from '../utils/webcam/getFaceBoxForWebcamCaptureId'
import {generateCaptions} from '../actions/captions'
import {generateSubtitles} from '../actions/captions'
import {calculateCaptionWordGroupPositions} from './utils/calculateCaptionWordGroupPositions'
import {calculateSubtitleGroupWordPositions} from './utils/calculateSubtitleGroupWordPositions'
import debounce from 'lodash/debounce'
import {randomID} from '../utils/randomID'

//Duration is the duration on the timeline
//recording duration is the duration of the underlying video

//Webcam clip can have optional audioTransformation
// {type:normalized},{type:enhanced},{type:voiceMatch,voiceId:abc}
//transcript items (words and pauses) have originalIndex which is the index in the full unskipped transcript

class WebcamClip extends TimelineClip {

	constructor(options,scene,handleVideoClipLoaded,updateVideoClipSegments,updateTranscriptPanelOnLoadWebcam,updateNodeAttrsWithoutUndo,checkWebcamUploadStatus,getAspectRatio){
		super(options,scene)
		this.video = document.createElement('video');   
		this.video.muted=false
		this.isUploadingVideo=options.isUploadingVideo || false
		this.captureId=options.captureId
		this.segments=options.segments || []
		this.recordingSegments = options.recordingSegments||[]
		this.clipPlaybackRate = options.clipPlaybackRate || 1
		this._pinnedStartTime = options.pinnedStartTime
		this.clipIndex = options.clipIndex
		this.minDuration = options.minDuration || null
		this.duration = options.duration 
		this.recordingDuration = options.recordingDuration || options.duration
		this._handleVideoClipLoaded=handleVideoClipLoaded
		this.updateVideoClipSegments=updateVideoClipSegments
		this.updateTranscriptPanelOnLoadWebcam = updateTranscriptPanelOnLoadWebcam
		this.updateNodeAttrsWithoutUndo=updateNodeAttrsWithoutUndo
		this.placeholderDuration=options.placeholderDuration
		this.isLoadingTranscript=this.metadata.transcript ? false : true
		this.getAspectRatio=getAspectRatio
		this.currentCaptionRequestId = null

		// Create debounced version of calculateSubtitlesAndCaptions
		this.debouncedCalculateSubtitlesAndCaptions = debounce(this._calculateSubtitlesAndCaptions.bind(this), 500, {
			leading: false,
			trailing: true
		});

		// Create the public method that immediately nulls the groups
		this.calculateSubtitlesAndCaptions = () => {
			this.metadata.captionGroups = null;
			this.metadata.subtitlesGroups = null;
			this.debouncedCalculateSubtitlesAndCaptions();
		};

		if(!this.metadata.trimEnd){
			this.metadata.trimStart=0
			this.metadata.trimEnd=this.recordingDuration
		}

		if(!this.isUploadingVideo && !this.metadata.isVariable){
			 this.initalizeWebcamVideo()
		}
		if(this.isUploadingVideo){
			this.initalizePlaceholderWebcamVideo()
		}

		if(!this.metadata.colorAdjustments){
			this.metadata.colorAdjustments=defaultColorAdjustments
		}

		if(!this.metadata.layout){
			this.metadata.layout = defaultWebcamLayout
		}
		if(!this.metadata.borderMode){ //can be auto/forceHide/forceShow
			this.metadata.borderMode='forceHide'
		}

		if(this.metadata.isMuted){
			this.video.muted = true
		}

		this.sessionCaptureId = options.sessionCaptureId || null

	  if(!this.metadata.isVariable && !this.isUploadingVideo && !this.metadata.transcript){
			this.getWebcamTranscript()
		}else{
			if(this.metadata.transcript && !this.metadata.captionGroups ){
				this.debouncedCalculateSubtitlesAndCaptions()
			}
		}
		
		//This is for handling old webcam clips in old transcript format (Clay onboarding video)
		if(this.metadata.transcript && !this.metadata.transcript.items){
			this.metadata.transcript = null 
			this.isLoadingTranscript=true
			this.getWebcamTranscript()
		}

		this.isLoadingVoice = false
		this.playPromise = null
		this.checkWebcamUploadStatus=checkWebcamUploadStatus
		//Add polling for upload status incase miss pusher or have navigated away before upload complete
		this.pollInterval = null 
    this.pollAttempts = 0 
    this.maxPollAttempts = 30
    if (this.isUploadingVideo) {
      this.startPollingUploadStatus()
    }
	}

	getSceneDimensions=()=>{
		const aspectRatio = this.getAspectRatio()
		let sceneWidth = 1920
		let sceneHeight = 1080
		if(aspectRatio === '16_10'){
			sceneWidth = 1920
			sceneHeight = 1200
		}
		return {sceneWidth,sceneHeight}
	}

	checkForFileUpdates(fileStatuses) {
		const clipStatuses = fileStatuses[this.captureId];
		if (!clipStatuses) return;
		if (clipStatuses.transcript ) {
			this.getWebcamTranscript();
		}
		if (clipStatuses.facebox && !this.metadata.faceBox) {
			this.loadFaceBoxes()
		}
	}

	async loadFaceBoxes() {
		this.metadata.faceBox = await getFaceBoxForWebcamCaptureId(this.captureId)
	}

  async startPollingUploadStatus() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
    // Initial poll
    await this.pollUploadStatus()
    // Set timeout for subsequent polls
    this.pollInterval = setTimeout(async () => {
      try {
        if (!this.isUploadingVideo || this.pollAttempts >= this.maxPollAttempts) {
          this.stopPolling()
          return
        }
        await this.pollUploadStatus()
      } catch (error) {
        console.error('Error polling upload status:', error)
      }
    }, 10000)
  }

  async pollUploadStatus() {
    await this.checkWebcamUploadStatus(this.id)
    this.pollAttempts++
  }

  stopPolling() {
    if (this.pollInterval) {
      clearTimeout(this.pollInterval)
      this.pollInterval = null
    }
  }

	
	updateIsMuted(value){
		this.video.muted=value
	}

	calculateFormattedWords(){
		const trimStartTime = this.metadata.trimStart
		const trimEndTime = this.metadata.trimEnd

		const skipSegments = this.segments.filter(segment => segment.isSkipSegment)

		const skipRanges = skipSegments.map(segment => ({
		 startTime: segment.originalStart,
		 endTime: segment.originalEnd,
		}))

		const isInSkipRange = (startTime, endTime) => {
			return skipRanges.some(range => 
				startTime < range.endTime && range.startTime < endTime
			);
		};
		 
	//	console.log('skipRanges',skipRanges)


		//apply trims to words and offset

		const words = this.metadata.transcript.items.filter(item => !item.isPause)
		//console.log('words',words)
		
		const filteredWords = words.filter(item => 
			!(item.startTime < trimStartTime || item.startTime > trimEndTime) && 
			!isInSkipRange(item.startTime,item.endTime)
		);

	//	console.log('words',words)
		const formattedWords = filteredWords.map(word => {
			// Calculate total duration of skip sections before this word
			const skipDurationBeforeWord = skipRanges
				.filter(range => range.endTime <= word.startTime)
				.reduce((total, range) => total + (range.endTime - range.startTime), 0);
			//	console.log('word.word', 'skipDurationBeforeWord',skipDurationBeforeWord)

			return {
				word: word.word,
				start_time: word.startTime - trimStartTime - skipDurationBeforeWord,
				end_time: word.endTime - trimStartTime - skipDurationBeforeWord
			}
		})
		return formattedWords
	}

///TODO should use chunks to split the captions and subtitles into groups

//TODO playback rates
	async _calculateSubtitlesAndCaptions(){
		if(!this.metadata.transcript){
			return
		}

		const {sceneWidth,sceneHeight} = this.getSceneDimensions()

	//	console.log('words',words)
		const formattedWords = this.calculateFormattedWords()

	//	console.log('---------------------formattedWords',formattedWords)

		// Generate a new request ID for this calculation
		const requestId = randomID();
		this.currentCaptionRequestId = requestId;

		store.dispatch(generateCaptions(formattedWords)).then((captionsResponse) => {			
			if(captionsResponse && requestId === this.currentCaptionRequestId){
		//		console.log('captionsResponse',captionsResponse)
			// Calculate word positions for each group in the captions response
			const updatedCaptionsGroups = captionsResponse.groups.map(async (group) => {
				return await calculateCaptionWordGroupPositions(group,sceneWidth,sceneHeight);
			});
			Promise.all(updatedCaptionsGroups).then((processedGroups) => {
				if (requestId === this.currentCaptionRequestId) {
					this.metadata.captionGroups = processedGroups;
				}
			});
			}
		})

		store.dispatch(generateSubtitles(formattedWords)).then((subtitlesResponse) => {			
			if(subtitlesResponse && requestId === this.currentCaptionRequestId){
			//	console.log('subtitlesResponse',subtitlesResponse)
			// Calculate word positions for each group in the captions response
			const updatedSubtitlesGroups = subtitlesResponse.groups.map(async (group) => {
				return await calculateSubtitleGroupWordPositions(group,sceneWidth,sceneHeight);
			});
			Promise.all(updatedSubtitlesGroups).then((processedGroups) => {
				if (requestId === this.currentCaptionRequestId) {
					this.metadata.subtitlesGroups = processedGroups;
				}
			});
			}
		})
	}

  async getWebcamTranscript(){
		if(!this.metadata.transcript){
			const transcript = await getTranscriptForWebcamCaptureId(this.captureId,this.duration)
			this.metadata.transcript = transcript
			const hasWords = this.metadata.transcript.items.some(item => !item.isPause);
			if(!this.metadata.linkedClipId && hasWords){ //dont auto trim screencasts
				this.autoApplyStartAndEndSkips()
			}
			this.updateNodeAttrsWithoutUndo(this)
			this.isLoadingTranscript=false
			this.updateTranscriptPanelOnLoadWebcam()
			this.debouncedCalculateSubtitlesAndCaptions()
		}else{
			this.isLoadingTranscript=false
		}
  }

  //automatically skip pauses at the start and end of clip (as long as there are some words)
	autoApplyStartAndEndSkips() {
		if (!this.metadata.transcript || !this.metadata.transcript.items || this.metadata.transcript.items.length === 0) {
			return;
		}
		const { items, chunks } = this.metadata.transcript;
		let skippedItems = [...(this.metadata.transcript.skippedItems || [])];
		
		// Find leading pauses
		let leadingSkipRange = null;
		for (let i = 0; i < items.length; i++) {
			if (items[i].isPause) {
				skippedItems.push({...items[i],text:'·'});
				if (!leadingSkipRange) {
					leadingSkipRange = {
						startTime: items[i].startTime,
						endTime: items[i].endTime,
						isSkipSegment: true
					};
				} else {
					leadingSkipRange.endTime = items[i].endTime;
				}
			} else {
				break;
			}
		}

		// Find trailing pauses
		let trailingSkipRange = null;
		for (let i = items.length - 1; i >= 0; i--) {
			if (items[i].isPause) {
				skippedItems.push({...items[i],text:'·'});
				if (!trailingSkipRange) {
					trailingSkipRange = {
						startTime: items[i].startTime,
						endTime: items[i].endTime,
						isSkipSegment: true
					};
				} else {
					trailingSkipRange.startTime = items[i].startTime;
				}
			} else {
				break;
			}
		}

		// Combine skip ranges
		const skipRanges = [];
		if (leadingSkipRange) skipRanges.push(leadingSkipRange);
		if (trailingSkipRange) skipRanges.push(trailingSkipRange);
		this.updateChunksAndSkipRanges(chunks, skipRanges);
	}

	updateTrimValues(trimStartTime,trimEndTime){
		this.metadata.trimStart=trimStartTime
		this.video.currentTime=trimStartTime
		this.metadata.trimEnd=trimEndTime
		this.calculateTrimmedSegments()
	}

	updateChunksAndSkipRanges(newChunks,skipRanges){
		if (!this.metadata.transcript || !newChunks?.length) {
			return;
		}
		const updatedTranscript = updateTranscriptChunks(
			this.metadata.transcript,
			newChunks,
			this.metadata.trimStart,
			this.metadata.trimEnd
		);
		if (updatedTranscript) {
			this.metadata.transcript = updatedTranscript;    
		}
		this.calculateRecordingSegments(skipRanges)
	}

	restoreWebcamSkip(skip){
		if (!this.metadata.transcript) {
			return;
		}
		const skipSegments = this.segments?.filter(segment => segment.isSkipSegment) || [];
		const newSkipSegments = skipSegments.filter(segment => segment.originalStart !== skip.startTime && segment.originalEnd !== skip.endTime)
		const newSkipRanges = newSkipSegments.map(segment => ({
			startTime: segment.originalStart,
			endTime: segment.originalEnd,
		}))
		this.updateChunksAndSkipRanges(this.metadata.transcript.chunks,newSkipRanges)
	}


	calculateRecordingSegments(skipRanges=[]){
		const segments = calculateWebcamRecordingSegments(this.recordingDuration,skipRanges)
		this.recordingSegments = segments 
		this.calculateTrimmedSegments()
	}

	calculateTrimmedSegments(){ //apply trim and clip playback rate
		const isInitialLoad = this.segments.length==0
		this.segments = calculateTrimmedSegments(this.recordingSegments,this.metadata.trimStart,this.metadata.trimEnd,this.clipPlaybackRate)
		this.calculateDurationFromSegments()
		if(isInitialLoad){
			 this.updateVideoClipSegments(this.id)
		}
		this.debouncedCalculateSubtitlesAndCaptions()
	}


	async finishUpload(){
		this.stopPolling()
		await this.getWebcamTranscript()
		await this.initalizeWebcamVideo()
	}

	handleUpdateAudioTrack(){ //for switching between e.g. normalised, enhanced, voice match etc
	 if(!this.metadata.isVariable && !this.isUploadingVideo){
		this.initalizeWebcamVideo()
	 }
	}

	async initalizePlaceholderWebcamVideo() {
		const  videoUrl  = await fetchPlaceholderWebcamVideo(this.captureId,this.metadata.userUploadFileExtension);
		if (videoUrl) {
			this.videoUrl = videoUrl;
			this.video.src = videoUrl;
			this.hasPlaceholderVideo = true
			this.video.crossOrigin = 'anonymous';
			this.video.preload = 'auto';
			if (!this.segments.length || !this.recordingSegments.length) {
				this.calculateRecordingSegments()
			}
			this.video.addEventListener('loadedmetadata', () => {
			if (!this.metadata.originalWidth) {
				this.metadata.originalWidth = this.video.videoWidth;
			}
			if (!this.metadata.originalHeight) {
				this.metadata.originalHeight = this.video.videoHeight;
			}
			if (!this.metadata.applyFaceBox) {
				this.metadata.applyFaceBox = false;
			}
		});
		}
	}

	async initalizeWebcamVideo() {
		const { videoUrl, voiceMatchFailed } = await fetchWebcamVideo(this.captureId, this.metadata.audioTransformation);
		if(voiceMatchFailed){
			this.fetchSpeechToSpeech()
		}
		this.videoUrl = videoUrl
		this.video.src = videoUrl
		this.hasPlaceholderVideo = false
		this.video.crossOrigin = 'anonymous' // Add this line to enable CORS
		this.video.preload = 'auto'
		this.video.playbackRate = this.clipPlaybackRate
		if (!this.segments.length || !this.recordingSegments.length) {
			this.calculateRecordingSegments()
		}
		this.isUploadingVideo = false
	}

	fetchSpeechToSpeech = () => {
		this.isLoadingVoice = true
		const voiceId = this.metadata.audioTransformation.voiceId 
		store.dispatch(fetchSpeechToSpeech(this.captureId, voiceId))
			.then((response) => {
				if (response.success) {
					this.isLoadingVoice = false
					this.initalizeWebcamVideo() 
				}
			})
			.catch(error => {
				console.error('STS error:', error)
			})
	}

	 calculateDurationFromSegments() {
		let totalDuration = 0;
		this.segments.forEach(segment => {
			totalDuration += segment.newDuration;
		});
		this.duration = totalDuration;
	}

	async playFromCurrentTime(currentTime) {
		if (!this.video || !this.video.src || this.metadata.isVariable) { //add variable check for when reset to script
			return
		}
		if (currentTime >= this.startTime && currentTime < this.endTime) {
			const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
			this.video.currentTime = videoTime
			try {
				// Wait for any existing play operation
				if (this.playPromise) {
					await this.playPromise
				}
				// Start new play attempt
				this.playPromise = this.video.play()
				if (this.playPromise) {
					await this.playPromise
					this.playPromise = null
				}
			} catch (error) {// Only log if it's not an abort error
				if (!error.name === 'AbortError') {
					console.warn('Play interrupted:', error)
				}
				this.playPromise = null
			}
		}
	}

	async pause() {
		try {
			// Wait for any existing play operation
			if (this.playPromise) {
				await this.playPromise
			}
			this.video.pause()
		} catch (error) {
			console.warn('Error during pause:', error)
		}
		this.playPromise = null
	}

	seek(currentTime) {
		this.ended = false
		if(!this.metadata.isVariable &&this.video && this.video.src && (!this.isUploadingVideo || this.hasPlaceholderVideo)){
			if (currentTime >= this.startTime && currentTime < this.endTime) {
				const videoTime = calulateVideoTimeFromTimelineTime(currentTime, this)
				this.video.currentTime = videoTime
		 } else {
				this.video.pause(); // If the current time is outside the clip, pause it
			}
		}
	}

	get pinnedStartTime() {
    return this._pinnedStartTime === null ? null : this.scene.startTime + this._pinnedStartTime;
}

	set pinnedStartTime(newStartTime) {
    this._pinnedStartTime = newStartTime === null ? null : newStartTime - this.scene.startTime;
	}

	get relativePinnedStartTime() {
		return this._pinnedStartTime 
	}

   set relativePinnedStartTime(newRelativeStartTime) {
		this._pinnedStartTime = newRelativeStartTime;
	}


  toJSON() {
   const json = {
      id: this.id,
      type:this.type,
      clipIndex:this.clipIndex,
      captureId:this.captureId,
      sessionCaptureId:this.sessionCaptureId,
      minDuration:this.minDuration,
      placeholderDuration:this.placeholderDuration,
      isUploadingVideo:this.isUploadingVideo,
      fileName:this.fileName,
      startTime:this._startTime,
      pinnedStartTime:this._pinnedStartTime,
      absolutePinnedStartTime:this.pinnedStartTime, //for api
      absoluteStartTime:this.startTime, //for server side export add this for audio- TODO maybe update ssr to calc based on scenes
      duration:this.duration,
      recordingDuration:this.recordingDuration,
      name:this.name,
      metadata:this.metadata,
      zIndex:this.zIndex,
      recordingSegments:this.recordingSegments,
      segments:this.segments,
      clipPlaybackRate:this.clipPlaybackRate,
    };
    return json
  }
 

	destroy() {
		this.stopPolling()
		if (!this.video.paused) {
			this.video.pause();
		}
		this.video.removeEventListener('playing', this.onPlaying);
		this.video.removeEventListener('ended', this.onEnded);
		this.video.src = '';
		this.video.load(); // Forces the release of the video buffer
		this.video = null;
		this.ended = false;
		this.captureId = null;
		this.playPromise = null
	}

}

export { WebcamClip }
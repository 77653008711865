import getSlideConfigs from '../../../../timeline/slide/slideUtils/getSlideConfigs'


const slideConfigs = getSlideConfigs()

export const calculateSlideSnapLines = (layout,dragItemId, slideWidth, slideHeight) => {
  const { slideHPadding, slideVPadding } = slideConfigs;
  
  const slideAspect = slideWidth / slideHeight

  const snapLines = {
    vertical: [],
    horizontal: []
  };

  // Helper function to add a snap line if it doesn't already exist
  const addSnapLine = (array, value, type) => {
    if (!array.some(line => Math.abs(line.position - value) < 0.1)) {
      array.push({ position: value, type });
    }
  };

  // Add slide edges
  addSnapLine(snapLines.vertical, 0, 'edge');
  addSnapLine(snapLines.vertical, slideWidth, 'edge');
  addSnapLine(snapLines.horizontal, 0, 'edge');
  addSnapLine(snapLines.horizontal, slideHeight, 'edge');

  // // Add slide center lines
  addSnapLine(snapLines.vertical, slideWidth / 2, 'center');
  addSnapLine(snapLines.horizontal, slideHeight / 2, 'center');

  // Add padding lines
  addSnapLine(snapLines.vertical, slideHPadding, 'padding');
  addSnapLine(snapLines.vertical, slideWidth - slideHPadding, 'padding');
  addSnapLine(snapLines.horizontal, slideVPadding, 'padding');
  addSnapLine(snapLines.horizontal, slideHeight - slideVPadding, 'padding');

  // Function to recursively process layout groups and elements
  const processLayoutGroup = (group) => {
    group.children.forEach(child => {
      if (child.isLayoutGroup && child.depth==0 ) {
        processLayoutGroup(child);
      } else {
    
        if(child.id!==dragItemId && !(child.metadata && child.metadata.isBGImage)){

                  // Add lines for element edges
        addSnapLine(snapLines.vertical, child.x, 'element');
        addSnapLine(snapLines.vertical, child.x + child.width, 'element');
        addSnapLine(snapLines.horizontal, child.y, 'element');
        addSnapLine(snapLines.horizontal, child.y + child.height, 'element');

        // Add lines for element centers
        addSnapLine(snapLines.vertical, child.x + child.width / 2, 'element-center');
        addSnapLine(snapLines.horizontal, child.y + child.height / 2, 'element-center');
          
        }

      }
    });

    // Add lines for layout group edges and centers
    // addSnapLine(snapLines.vertical, group.x, 'group');
    // addSnapLine(snapLines.vertical, group.x + group.width, 'group');
    // addSnapLine(snapLines.horizontal, group.y, 'group');
    // addSnapLine(snapLines.horizontal, group.y + group.height, 'group');
    // addSnapLine(snapLines.vertical, group.x + group.width / 2, 'group-center');
    // addSnapLine(snapLines.horizontal, group.y + group.height / 2, 'group-center');
  };

  // // Process the main layout
  processLayoutGroup(layout);

  // Sort the snap lines
  snapLines.vertical.sort((a, b) => a.position - b.position);
  snapLines.horizontal.sort((a, b) => a.position - b.position);

  return snapLines;
};
import React, { useState, useEffect, useRef, useCallback } from 'react';
import RecordRTC from 'recordrtc';
import RecordWebcamClean from './RecordWebcamClean';
import RecordWebcamScript from './RecordWebcamScript';
import RecordWebcamInstructions from './RecordWebcamInstructions';

//Lets save camera/microphone choices in local storage
const STORAGE_KEYS = {
  CAMERA: 'preferred-camera-id',
  MICROPHONE: 'preferred-microphone-id'
};

const RecordWebcamRecord = ({
  script,
  instructions,
  layout,
  onRecordingComplete
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [showIsRecordingIndicator, setShowIsRecordingIndicator] = useState(false);
  const [videoStreamReady, setVideoStreamReady] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [microphones, setMicrophones] = useState([]);
  const [activeCamera, setActiveCamera] = useState('');
  const [activeMicrophone, setActiveMicrophone] = useState('');
  
  const mediaStreamRef = useRef(null);
  const recorderRef = useRef(null);
  const videoRef = useRef(null);
  const mountedRef = useRef(false);

  const [audioAssets, setAudioAssets] = useState({
    start: null,
    stop: null
  });

  const handleCameraChange = (deviceId) => {
    setActiveCamera(deviceId);
    localStorage.setItem(STORAGE_KEYS.CAMERA, deviceId);
  };

  const handleMicrophoneChange = (deviceId) => {
    setActiveMicrophone(deviceId);
    localStorage.setItem(STORAGE_KEYS.MICROPHONE, deviceId);
  };

  const findPreferredDevice = useCallback((devices) => {
    // First try to find a non-mobile device
    const nonMobileDevice = devices.find(device => {
      const label = device.label.toLowerCase();
      return !label.includes('phone') && 
             !label.includes('mobile') && 
             !label.includes('iphone') &&
             !label.includes('android') &&
             !label.includes('ipad');
    });        
    // If found, use it, otherwise fall back to first device
    return nonMobileDevice || devices[0];
  }, []);

  const enumerateDevices = useCallback(async () => {
    try {
      const savedCameraId = localStorage.getItem(STORAGE_KEYS.CAMERA);
      const savedMicrophoneId = localStorage.getItem(STORAGE_KEYS.MICROPHONE);
      
      const devices = await navigator.mediaDevices.enumerateDevices();
      
      const videoDevices = devices
        .filter(device => device.kind === 'videoinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || `Camera ${devices.filter(d => d.kind === 'videoinput').indexOf(device) + 1}`
        }));
      
      const audioDevices = devices
        .filter(device => device.kind === 'audioinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || `Microphone ${devices.filter(d => d.kind === 'audioinput').indexOf(device) + 1}`
        }));

      setCameras(videoDevices);
      setMicrophones(audioDevices);

      if (videoDevices.length > 0) {
        // First check if saved camera is still available
        const savedCameraExists = videoDevices.some(device => device.value === savedCameraId);
        if (savedCameraExists && activeCamera !== savedCameraId) {
          handleCameraChange(savedCameraId);
        } else if (!savedCameraExists && (!activeCamera || !videoDevices.some(device => device.value === activeCamera))) {
          // If no saved camera or current camera is no longer available, find preferred device
          const preferredCamera = findPreferredDevice(videoDevices);
          handleCameraChange(preferredCamera.value);
        }
      }

      if (audioDevices.length > 0) {
        // First check if saved microphone is still available
        const savedMicrophoneExists = audioDevices.some(device => device.value === savedMicrophoneId);
        if (savedMicrophoneExists && activeMicrophone !== savedMicrophoneId) {
          handleMicrophoneChange(savedMicrophoneId);
        } else if (!savedMicrophoneExists && (!activeMicrophone || !audioDevices.some(device => device.value === activeMicrophone))) {
          // If no saved microphone or current microphone is no longer available, find preferred device
          const preferredMicrophone = findPreferredDevice(audioDevices);
          handleMicrophoneChange(preferredMicrophone.value);
        }
      }
    } catch (error) {
      console.error('Error enumerating devices:', error);
    }
  }, [activeCamera, activeMicrophone, findPreferredDevice]);

  // Device monitoring effect
  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', enumerateDevices);
    enumerateDevices(); // Initial enumeration
    
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', enumerateDevices);
    };
  }, [enumerateDevices]);

  // Audio setup effect
  useEffect(() => {
    const startAudio = new Audio('/recordStart.mp3');
    const stopAudio = new Audio('/recordEnd.mp3');
    startAudio.volume = 0.05;
    stopAudio.volume = 0.05;
    Promise.all([
      startAudio.load(),
      stopAudio.load()
    ]).then(() => {
      setAudioAssets({
        start: startAudio,
        stop: stopAudio
      });
    });
  }, []);

  // Cleanup effect
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      cleanupMediaStream();
    };
  }, []);

  const cleanupMediaStream = () => {
    if (recorderRef.current) {
      if (recorderRef.current.stream) {
        recorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      recorderRef.current.destroy();
      recorderRef.current = null;
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      mediaStreamRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
    setVideoStreamReady(false);
  };

  // Video element ready state handler
  useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    const handleCanPlay = () => {
      if (mountedRef.current) {
        setVideoStreamReady(true);
      }
    };

    videoEl.addEventListener('loadedmetadata', handleCanPlay);
    videoEl.addEventListener('loadeddata', handleCanPlay);

    return () => {
      videoEl.removeEventListener('loadedmetadata', handleCanPlay);
      videoEl.removeEventListener('loadeddata', handleCanPlay);
    };
  }, [videoRef.current]);

  // Media stream setup
  useEffect(() => {
    if (!activeCamera || !activeMicrophone) {
      return;
    }

    const constraints = {
      video: {
        deviceId: activeCamera ? { exact: activeCamera } : undefined,
        width: { 
          ideal: 1920,    // Default to 1080p
          min: 1280,      // Minimum HD          
        },
        height: {
          ideal: 1080,    // Default to 1080p
          min: 720,       // Minimum HD          
        },
      },
      audio: {
        deviceId: activeMicrophone ? { exact: activeMicrophone } : undefined,
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: {
          ideal: 48000,
          min: 44100
        },
      }
    };

    let isMounted = true;

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        if (!isMounted) {
          stream.getTracks().forEach(track => track.stop());
          return;
        }
        mediaStreamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.load();
        }
      })
      .catch(error => {
        console.error('Error accessing media devices:', error);
        if (isMounted) {
          setVideoStreamReady(false);
        }
      });

    return () => {
      isMounted = false;
      cleanupMediaStream();
    };
  }, [activeCamera, activeMicrophone]);

  const handleStartRecordingClick = async () => {
    if (!mediaStreamRef.current || !audioAssets.start) return;
    setShowIsRecordingIndicator(true);
    await audioAssets.start.play();
    await new Promise(resolve => setTimeout(resolve, 550));
    const recordRTC = new RecordRTC(mediaStreamRef.current, {
      type: 'video',
      mimeType: 'video/webm;codecs=vp9,opus',    
      videoBitsPerSecond: 2000000,
      audioBitsPerSecond: 128000
    });
    recordRTC.startRecording();
    recorderRef.current = recordRTC;
    setIsRecording(true);
  };

  const handleStopRecordingClick = () => {
    if (!recorderRef.current || !audioAssets.stop) return;

    setShowIsRecordingIndicator(false);
    recorderRef.current.stopRecording(async () => {
      audioAssets.stop.play();
      try {
        const blob = recorderRef.current.getBlob();
        cleanupMediaStream();
        onRecordingComplete(blob);
        setIsRecording(false);
      } catch (err) {
        console.error('Error saving recording:', err);
      }
    });
  };

  const handleRestartClick = () => {
    if (recorderRef.current) {
      recorderRef.current.destroy();
      recorderRef.current = null;
    }
    setIsRecording(false);
    setShowIsRecordingIndicator(false);
    audioAssets.stop.play();
  };

  const isReadyToRecord = (videoStreamReady || isRecording) && mediaStreamRef.current !== null;

  const sharedProps = {
    videoRef,
    isRecording,
    showIsRecordingIndicator,
    onStartRecording: handleStartRecordingClick,
    onStopRecording: handleStopRecordingClick,
    onRestart: handleRestartClick,
    activeCamera,
    setActiveCamera: handleCameraChange,
    activeMicrophone,
    setActiveMicrophone: handleMicrophoneChange,
    cameras,
    microphones,
    isReadyToRecord,
    mediaStream: mediaStreamRef.current
  };

  return (    
    <>
      {layout === 'script' && 
        <RecordWebcamScript 
          {...sharedProps}
          script={script}              
        />
      }
      {layout === 'clean' && 
        <RecordWebcamClean 
          {...sharedProps}
        />
      }
      {layout === 'instructions' && 
        <RecordWebcamInstructions
          {...sharedProps}
          instructions={instructions}              
        />
      }
    </>
  );
};

export default RecordWebcamRecord;
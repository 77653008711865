

//const tracks=[-1,0,1,2] //zindex


export const dragPullClips=(allClips, dragClip,initialStartTime,shiftAmount,updatesArray,tracks=[0,1,2])=> {

	if(shiftAmount > 0){ //Drag right- dont need to worry about conflicts
		tracks.forEach((zIndex)=>{
			const trackClips = allClips.filter(clip => clip.zIndex === zIndex)
			const sortedClips = trackClips.sort((a, b) => a.startTime - b.startTime);
			sortedClips.forEach((trackClip)=>{
				if(trackClip.id!==dragClip.id && trackClip.relativeStartTime >= initialStartTime){
					trackClip.startTime += shiftAmount
					updatesArray.push({ clipId: trackClip.id, relativeStartTime: trackClip.relativeStartTime,pinnedStartTime:null });
				}
			})
		})
	}
	else{ //drag left

		tracks.forEach((zIndex)=>{
			const trackClips = allClips.filter(clip => clip.zIndex === zIndex)
			const sortedClips = trackClips.sort((a, b) => a.startTime - b.startTime);
			let firstClip
			// if(zIndex == dragClip.zIndex){
			// 	firstClip = dragClip
			// }else{
				sortedClips.forEach((trackClip)=>{
					if(trackClip.id !== dragClip.id && trackClip.startTime>dragClip.startTime && !firstClip){
						firstClip = trackClip
					}
				})
			//}
			
			if(firstClip){
				const firstClipIndex = sortedClips.findIndex(c => c.id === firstClip.id)
				let firstClipNewStartTime = firstClip.relativeStartTime + shiftAmount
				const totalDurationOfPrecedingClips = sortedClips.slice(0, firstClipIndex)
					.reduce((acc, c) => acc + c.duration, 0)


				if (firstClipNewStartTime < totalDurationOfPrecedingClips) {
					firstClipNewStartTime = totalDurationOfPrecedingClips;
				}	
				if (firstClipNewStartTime < 0) {
						firstClipNewStartTime = 0;
				}
				firstClip.relativeStartTime = firstClipNewStartTime
				updatesArray.push({ clipId: firstClip.id, relativeStartTime: firstClip.relativeStartTime });


				let previousClipEndTime = firstClipNewStartTime
				let stopAdjusting = false;

				for (let i = firstClipIndex - 1; i >= 0 && !stopAdjusting; i--) {
						let currentClip = sortedClips[i];
						let currentClipEndTime = currentClip.relativeStartTime + currentClip.duration;
						if (currentClipEndTime > previousClipEndTime) {
							let newStart = previousClipEndTime - currentClip.duration;
							if (newStart < 0) {
								stopAdjusting = true;
								newStart = 0;
							}
							previousClipEndTime = newStart;
							currentClip.relativeStartTime = newStart
							updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
						}
					}
					for (let i = firstClipIndex+1 ; i < sortedClips.length; i++) {
						let currentClip = sortedClips[i];
						const totalDurationOfPrecedingClips = sortedClips.slice(0, i)
							.reduce((acc, c) => acc + c.duration, 0)
						const newStartTime = Math.max(currentClip.relativeStartTime+shiftAmount,totalDurationOfPrecedingClips)
						currentClip.relativeStartTime=newStartTime
						updatesArray.push({ clipId: currentClip.id, relativeStartTime:currentClip.relativeStartTime });
					}

			}


		})


	}




		return updatesArray;
	}



import {getOrgBrand} from '../../utils/brands/getOrgBrand'

export function getCursorMode() {
  let cursorMode = 'standard'
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.cursorMode){
    cursorMode = orgBrand.screenVideoStyle.cursorMode
  }
  return cursorMode
}

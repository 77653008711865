import React, { useState } from 'react';
import Icon from '../../misc/Icon'
import LibraryPageHeaderGalleryDisplay from './LibraryPageHeaderGalleryDisplay'
import LibraryPageHeaderFilter from './LibraryPageHeaderFilter'
import LibraryPageHeaderSearch from './LibraryPageHeaderSearch'
import LibraryPageHeaderSort from './LibraryPageHeaderSort'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {archiveCollection} from '../../../utils/collections/archiveCollection'
import { useHistory } from 'react-router-dom';
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'


function LibraryPageHeader(props) {
    const { 
      subPage,
      displayMode,
      setDisplayMode, 
      isHome,
      isCollection,
      collectionId,
      searchQuery,
      handleSearchChange,
      createNewDraft,
      sortOption,
      handleSortChange,
      activeCollection,
      allowChangeDisplayMode } = props

    let showSearch = true
    let showFilter = true

    let showGalleryDisplay = true
    let showSortList = true

    let showPrimaryNewBtn = true
    let showSecondaryNewBtn = true

    if(isHome){
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = true
      showSecondaryNewBtn = false
    }

    if(isCollection){
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = false
      showSecondaryNewBtn = true
    }

    let subPageLabel
    let subPageIconName

    if(subPage === 'drafts'){
      subPageLabel = 'Drafts'  
      subPageIconName = 'drafts'
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = true
      showSecondaryNewBtn = false
    }
     if(subPage === 'org'){
      subPageLabel = getOrgNameLabel()  
      subPageIconName = null
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = false
      showSecondaryNewBtn = false
    }

    if(subPage === 'deleted'){
      subPageLabel = 'Deleted'
      subPageIconName = 'deleted'
      showSearch = true
      showFilter = false
      // showGalleryDisplay = false
      // showSortList = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = false
      showSecondaryNewBtn = false
    }

    if(!allowChangeDisplayMode){
      showGalleryDisplay = false
    }

    const history = useHistory();

    return (
      <div className='libraryPage-header'>
        <div className='libraryPage-header-leftContainer'>
          {isHome && 
            <>
            <div className='libraryPage-header-iconContainer'>
              <Icon name='yourlibrary' />
            </div>
            <div className='libraryPage-header-title'>
              Your Library
            </div>
            </>
          }
          {isCollection && activeCollection &&
            <>
            
            <div className={'libraryPage-header-iconContainer libraryPage-header-iconContainer--collection ' + (activeCollection.color ? `libraryPage-header-iconContainer--collection--${activeCollection.color}` : '')}>                          
              <Icon name='collectionSquircle' />
            </div>
            
            <div className='libraryPage-header-title'>
              {activeCollection.title}
            </div>
            </>
          }

           {subPage&&
            <>            
              {subPageIconName && 
              <div className='libraryPage-header-iconContainer'>
                <Icon name={subPageIconName} />
              </div>
              }
              <div className='libraryPage-header-title'>
                {subPageLabel}
              </div>                          
            </>
          }
        </div>
        
        <div className='libraryPage-header-rightContainer'>
          {showSearch && 
            <LibraryPageHeaderSearch 
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            />
          }
          {showFilter && 
            <LibraryPageHeaderFilter 

            />
          }
          {showGalleryDisplay && 
            <LibraryPageHeaderGalleryDisplay 
              displayMode={displayMode}
              setDisplayMode={setDisplayMode}
            />
          }

          {showSortList && 
            <LibraryPageHeaderSort
              sortOption={sortOption}
              handleSortChange={handleSortChange}
            />
          }

          

          {showPrimaryNewBtn && 
            <>
            <div className='libraryPage-header-tinySpacer' />
            <button onClick={createNewDraft} className='uiButton uiButton--primaryNewBtn'>
              <div className='uiButton-iconContainer'>
                <Icon name='composeMedium' />
              </div>
              <div className='uiButton-label'>
                New Draft
              </div>  
            </button>
            </>
          }

          {showSecondaryNewBtn && 
            <button onClick={()=>{props.createNewProjectInCollection(activeCollection.id)}} className='uiButton uiButton--secondaryNewBtn'>
              <div className='uiButton-iconContainer'>
                <Icon name='composeMedium' />
              </div>
              <div className='uiButton-label'>
                New in Collection
              </div>         
            </button>
          }

          {activeCollection && 
            <DropdownMenu.Root modal={true}> 
            <DropdownMenu.Trigger asChild>
              <button className='uiButton uiButton--iconOnly uiButton--collectionHeaderDropdownBtn'>  
                <div className='uiButton-iconContainer'>
                  <Icon name='ellipses' />                                          
                </div>
              </button>
            </DropdownMenu.Trigger>       
            <DropdownMenu.Portal>                
              <DropdownMenu.Content 
                align="end"
                alignOffset={-10}                      
                className='dropdownMenu dropdownMenu--collectionHeader forceDarkTheme'
              >                      
                <DropdownMenu.Item onSelect={()=>{archiveCollection(activeCollection.id,history)}} className='dropdownMenu-item--warning'>
                  Delete Collection
                </DropdownMenu.Item>            
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          }



        </div>

      </div>
    );
}

export default LibraryPageHeader

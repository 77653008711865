// similar to timelineClip.js

class ExportTimelineClip {
	constructor({ id, type, startTime, duration, zIndex,metadata },scene) {
		this.id = id;
		this.type = type; // 'video', 'audio', 'text', etc.
		this._startTime = startTime; //private
		this.duration = duration;
		this.zIndex = zIndex || 0; 
		this.metadata = metadata
		this.scene = scene

	}


	get startTime() {
        return this.scene.startTime + this._startTime;
    }

    set startTime(newStartTime) {
        this._startTime = newStartTime - this.scene.startTime;
    }

	get relativeStartTime() {
        return this._startTime 
    }
    
	get sceneId() {
        return this.scene.id;
    }

    get sceneIndex() {
        return this.scene.sceneIndex;
    }

    get sceneStartTime() {
        return this.scene.startTime;
    }

	get endTime() {
		return this.startTime+this.duration
	}
}



export { ExportTimelineClip };

import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import EditorNewScreenClipPopoverContainer from '../popovers/EditorNewScreenClipPopover/EditorNewScreenClipPopoverContainer'
import EditorInsertLauncher from '../popovers/EditorInsertLauncher'
import { TextSlideTooltipContent, ChartsTooltipContent, MediaTooltipContent, ScreenVideoTooltipContent, RecordTooltipContent, AddFromLibraryTooltipContent } from './EditorToolbarInsertBarTooltipContents';
import AddMediaPopover from '../popovers/addMediaPopover/AddMediaPopover'
import { getOrgDomain } from '../../../utils/getOrgDomain'

function EditorToolbarInsertBar(props) {

	const {
		handleSlideImageFileUpload,
		addSlide,
		insertImageFromRecent,
		handleNewVideoFileUpload,
		insertVideoFromRecent,
		addSlideElement,
		handleNewWebcamFileUpload,
		slideClipAtPlayhead,
		openScreenshareSwiftApp,
		setShowAddFromLibraryDialog,
		addZoom,
	} = props

	const [showNewScreenClipPopover, setShowNewScreenClipPopover] = useState(false);
	const [showAddImagePopover, setShowAddImagePopover] = useState(false)
	const [showAddFromLibraryPopover, setShowAddFromLibraryPopover] = useState(false)
	const [showInsertLauncher, setShowInsertLauncher] = useState(false);


	useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Cmd/Ctrl + K
      if ((e.metaKey) && e.key === 'k' && !e.shiftKey) {
        e.preventDefault(); // Prevent default browser behavior
        setShowInsertLauncher(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

	const openNewScreenClipPopover = () => {
		if (!showNewScreenClipPopover) {
			setShowNewScreenClipPopover(true);
		}
		if (showNewScreenClipPopover) {
			setShowNewScreenClipPopover(false);
		}
	};


	const hideTooltip = showNewScreenClipPopover || showAddFromLibraryPopover
	//const hideTooltip = true
	//const hideTooltip = showAddImagePopover || showAddFromLibraryDialog || showNewScreenClipPopover || setShowAddFromLibraryPopover

	let showUploadMediaButton = true

	const handleClickTextButton = () => {
		if (slideClipAtPlayhead) {
			addSlideElement(slideClipAtPlayhead.id, 'text');
		} else {
			addSlide()
		}
	};

	const handleClickChartButton = () => {
		if (slideClipAtPlayhead) {
			addSlideElement(slideClipAtPlayhead.id, 'chart');
		} else {
			const imageObj = null
			const elementId = null
			addSlide(imageObj, elementId, null, 'chart')
		}
	};


	const showWebcamButton = getOrgDomain() == 'yarn.so'
	const showScreencastButton = true
	// const showWebcamButton = true


	return (

		<>

			<div className='editor-toolbar-insertBar'>
				<div className='editor-toolbar-insertBar-inner'>
					{/*<button className='editor-toolbar-insertBar-btn' onClick={addTextSlide}>
					<Icon name='textSlideClipMedium' />					
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<TextSlideTooltipContent hideTooltip={hideTooltip} />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Text Slide
								</div>
							</div>
						</div>
					}
				</button>*/}


					<button className='editor-toolbar-insertBar-btn' onClick={handleClickTextButton}>
						<Icon name='textElementMedium' />
						{!hideTooltip &&
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<TextSlideTooltipContent hideTooltip={hideTooltip} />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Text
									</div>
								</div>
							</div>
						}
					</button>

					

					<AddMediaPopover
						showPopover={showAddImagePopover}
						handleClosePopover={() => setShowAddImagePopover(false)}
						//  mediaType='image'
						mediaType='mixed'//image and video
						handleNewImageFileUpload={handleSlideImageFileUpload}
						handleNewVideoFileUpload={handleNewVideoFileUpload}
						insertImageFromRecent={insertImageFromRecent}
						insertVideoFromRecent={insertVideoFromRecent}
					>
						<button data-state={showAddImagePopover ? 'active' : 'inactive'} onClick={() => setShowAddImagePopover(!showAddImagePopover)} className='editor-toolbar-insertBar-btn'>
							<Icon name='mediaClipMedium' />
							{!hideTooltip &&
								<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
									<div className='editor-toolbar-insertBar-btn-tooltip'>
										<MediaTooltipContent />
										<div className='editor-toolbar-insertBar-btn-tooltip-label'>
											Add Image or Video
										</div>
									</div>
								</div>
							}
						</button>
					</AddMediaPopover>


		
					{showScreencastButton &&
							<button  onClick={() => openScreenshareSwiftApp()} className='editor-toolbar-insertBar-btn'>
								<Icon name='screenVideoClipMediumAlt2' />
								{!hideTooltip &&
									<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
										<div className='editor-toolbar-insertBar-btn-tooltip'>
											<RecordTooltipContent />
											<div className='editor-toolbar-insertBar-btn-tooltip-label'>
												Record Camera / Screen
											</div>
										</div>
									</div>
								}
							</button>
					}


					<EditorNewScreenClipPopoverContainer
						showModal={showNewScreenClipPopover}
						closeModal={() => setShowNewScreenClipPopover(false)}
					>
						
						<div className='editor-toolbar-insertBar-centerPositionGuide' />
						
					</EditorNewScreenClipPopoverContainer>
					







					
					<button className='editor-toolbar-insertBar-btn' onClick={handleClickChartButton}>
						<Icon name='chartClipMedium' />
						{!hideTooltip &&
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<ChartsTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Chart
									</div>
								</div>
							</div>
						}
					</button>


					<div className='editor-toolbar-insertBar-insertBtnContainer'>
					<EditorInsertLauncher
				    showInsertLauncher={showInsertLauncher}
				    closeInsertLauncher={() => setShowInsertLauncher(false)}				   
				    handleClickTextButton={handleClickTextButton}
				    handleClickChartButton={handleClickChartButton}
  					openNewScreenClipPopover={openNewScreenClipPopover}
  					setShowAddImagePopover={setShowAddImagePopover}
  					setShowAddFromLibraryDialog={setShowAddFromLibraryDialog}
  					handleNewWebcamFileUpload={handleNewWebcamFileUpload}
  					addZoom={addZoom}
					>
				    <button 
			        data-state={showInsertLauncher ? 'active' : 'inactive'} 
			        onClick={() => setShowInsertLauncher(!showInsertLauncher)} 
			        className='editor-toolbar-insertBar-btn'
				    >
				      <Icon name='moreClipMedium' />
				      {!hideTooltip &&
								<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
									<div className='editor-toolbar-insertBar-btn-tooltip editor-toolbar-insertBar-btn-tooltip--insertBar'>										
										<div className='editor-toolbar-insertBar-btn-tooltip-label'>
											Insert
										</div>
										<div className='editor-toolbar-insertBar-btn-tooltip-shortcut'>
											<div className='editor-toolbar-insertBar-btn-tooltip-shortcut-iconContainer'>
												<Icon name='keyCommandMedium' />
											</div>
											<div className='editor-toolbar-insertBar-btn-tooltip-shortcut-letter'>
												K
											</div>
										</div>
									</div>
								</div>
							}
				    </button>
					</EditorInsertLauncher>
					</div>






					{/*}
				<button className='editor-toolbar-insertBar-btn' onClick={addImageClip}>
					<Icon name='imageClipMedium' />
					<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
						<div className='editor-toolbar-insertBar-btn-tooltip'>
							<ChartsTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add Image
							</div>
						</div>
					</div>
				</button> 
				*/}





					{/*}
        <AddVideoPopover
					showPopover={showAddVideoPopover}
          handleClosePopover={() => setShowAddVideoPopover(false)}
          handleNewImageFileUpload={handleNewImageFileUpload}
          insertImageFromRecent={insertImageFromRecent}
          //addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
        >          
          <button data-state={showAddVideoPopover ? 'active' : 'inactive'} onClick={() => setShowAddVideoPopover(!showAddVideoPopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='imageClipMedium' />
						{!hideTooltip && 
							<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
								<div className='editor-toolbar-insertBar-btn-tooltip'>
									<ChartsTooltipContent />
									<div className='editor-toolbar-insertBar-btn-tooltip-label'>
										Add Video
									</div>
								</div>
							</div>
						}
					</button>
        </AddVideoPopover>	




				<button className='editor-toolbar-insertBar-btn' onClick={addVideoClipFromUpload}>
					<Icon name='videoClipMedium' />
					{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
								<ChartsTooltipContent />
								<div className='editor-toolbar-insertBar-btn-tooltip-label'>
									Add Video
								</div>
							</div>
						</div>
					}
				</button>

				*/}

					{/*}
				<AddFromLibraryPopover
					showPopover={showAddFromLibraryPopover}
          handleClosePopover={() => setShowAddFromLibraryPopover(false)}
          addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
        >          
          <button onClick={() => setShowAddFromLibraryPopover(!showAddFromLibraryPopover)} className='editor-toolbar-insertBar-btn'>
						<Icon name='folderClipMedium' />
						{!hideTooltip && 
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
							<AddFromLibraryTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add from Library
							</div>
						</div>
						</div>
						}
					</button>
        </AddFromLibraryPopover>			
        */}


					{/*}
				<AddFromLibraryDialog
					showPopover={showAddFromLibraryDialog}
          handleClosePopover={() => setShowAddFromLibraryDialog(false)}
          addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
          addWebcamFromLibrary={addWebcamFromLibrary}
        >          
          <button onClick={() => setShowAddFromLibraryDialog(!showAddFromLibraryDialog)} className='editor-toolbar-insertBar-btn'>
						<Icon name='folderClipMedium' />
						<div className='editor-toolbar-insertBar-btn-tooltipContainer'>
							<div className='editor-toolbar-insertBar-btn-tooltip'>
							<AddFromLibraryTooltipContent />
							<div className='editor-toolbar-insertBar-btn-tooltip-label'>
								Add from Library
							</div>
						</div>
						</div>
					</button>

        </AddFromLibraryDialog>			
        */}

				</div>
			</div>

		</>
	);
}

export default EditorToolbarInsertBar
const MIN_SILENCE_FOR_PAUSE = 1; // only show a pause if total is more than 1 second
const PAUSE_SLICE_SIZE = 0.2; // divide the silence into units of 0.2 seconds

const isSentenceEnd = (word) => {
  return word.endsWith('.') || word.endsWith('!') || word.endsWith('?');
};

const parseTranscript = (transcriptData, duration) => {
  const allItems = []; //words and pauses 
  const words = transcriptData.words || [];
  let currentIndex = 0;

  // If no words, fill entire duration with pauses
  if (words.length === 0) {
    const numPauses = Math.floor(duration / PAUSE_SLICE_SIZE);
    for (let i = 0; i < numPauses; i++) {
      allItems.push({
        startTime: i * PAUSE_SLICE_SIZE,
        endTime: (i + 1) * PAUSE_SLICE_SIZE,
        isPause: true,
        originalIndex: currentIndex++
      });
    }
  } else {
    // Handle initial silence
    if (words[0].start >= MIN_SILENCE_FOR_PAUSE) {
      const numPauses = Math.floor(words[0].start / PAUSE_SLICE_SIZE);
      for (let i = 0; i < numPauses; i++) {
        allItems.push({
          startTime: i * PAUSE_SLICE_SIZE,
          endTime: (i + 1) * PAUSE_SLICE_SIZE,
          isPause: true,
          originalIndex: currentIndex++
        });
      }
    }

    // Process words and inter-word silences
    words.forEach((word, wordIdx) => {
      // Add the word
      allItems.push({
        startTime: word.start,
        endTime: word.end,
        word: word.punctuated_word,
        originalIndex: currentIndex++
      });

      // Check for silence after word
      const nextWord = words[wordIdx + 1];
      if (nextWord) {
        const silence = nextWord.start - word.end;
        if (silence >= MIN_SILENCE_FOR_PAUSE) {
          const numPauses = Math.floor(silence / PAUSE_SLICE_SIZE);
          for (let i = 0; i < numPauses; i++) {
            allItems.push({
              startTime: word.end + (i * PAUSE_SLICE_SIZE),
              endTime: word.start + ((i + 1) * PAUSE_SLICE_SIZE),
              isPause: true,
              originalIndex: currentIndex++
            });
          }
        }
      }
    });

    // Handle trailing silence after the last word
    if (words.length > 0) {
      const lastWord = words[words.length - 1];
      const trailingSilence = duration - lastWord.end;      
      if (trailingSilence >= MIN_SILENCE_FOR_PAUSE) {
        const numPauses = Math.floor(trailingSilence / PAUSE_SLICE_SIZE);
        for (let i = 0; i < numPauses; i++) {
          allItems.push({
            startTime: lastWord.end + (i * PAUSE_SLICE_SIZE),
            endTime: lastWord.end + ((i + 1) * PAUSE_SLICE_SIZE),
            isPause: true,
            originalIndex: currentIndex++
          });
        }
      }
    }
  }

  // Create sentence chunks
  const chunks = [];
  let currentChunk = null;
  let lastWordItem = null;

  // Find the last non-pause item
  for (let i = allItems.length - 1; i >= 0; i--) {
    if (!allItems[i].isPause) {
      lastWordItem = allItems[i];
      break;
    }
  }

  allItems.forEach((item, index) => {
    // If we don't have a current chunk yet, initialize one
    if (!currentChunk) {
      currentChunk = {
        startTime: item.startTime,
        endTime: item.endTime,
        items: [item],
        text: '',
        firstIndex: item.originalIndex,
        lastIndex: item.originalIndex
      };
      return;
    }

    // Always add the item to the current chunk
    currentChunk.items.push(item);
    currentChunk.endTime = item.endTime;
    currentChunk.lastIndex = item.originalIndex;

    // Only finalize chunk if:
    // 1. We hit a sentence end AND
    // 2. It's not the last word (so trailing pauses stay with last chunk) AND
    // 3. Current item is not a pause
    if (!item.isPause && 
        isSentenceEnd(item.word) && 
        item !== lastWordItem) {
      currentChunk.text = currentChunk.items
        .filter(item => !item.isPause)
        .map(item => item.word)
        .filter(Boolean)
        .join(' ')
        .replace(/\s+([.,!?])/g, '$1');
      chunks.push(currentChunk);
      currentChunk = null;
    }
  });

  // Handle remaining chunk if any
  if (currentChunk) {
    currentChunk.text = currentChunk.items
      .filter(item => !item.isPause)
      .map(item => item.word)
      .filter(Boolean)
      .join(' ')
      .replace(/\s+([.,!?])/g, '$1');
    chunks.push(currentChunk);
  }

  const transcript = allItems
    .filter(item => !item.isPause)
    .map(item => item.word)
    .filter(Boolean)
    .join(' ')
    .replace(/\s+([.,!?])/g, '$1');
  

  return {
    transcript,
    items: allItems,
    chunks,

  };
};

export const getTranscriptForWebcamCaptureId = async (captureId, duration) => {
  if (window.isElectron) {
    const relativePath = `webcamRecordings/${captureId}/transcript.json`;
    try {
      const content = await ipcRenderer.invoke('read-file', relativePath);
      const data = JSON.parse(content);
      const parsedTranscript = parseTranscript(data.transcription, duration);
      return parsedTranscript;
    } catch (error) {
      console.error('Error reading or parsing transcript:', error);
      throw error;
    }
  }
  return null;
};
import React from 'react';
import AddFromLibraryDialogGridVideo from './AddFromLibraryDialogGridVideo';
import { getUserForId } from '../../../../utils/getUserForId';
import { formatTimestamp } from './addFromLibraryDialogTimestamp';
import { formatDuration } from './AddFromLibraryDialogGridVideo';
import Icon from '../../../misc/Icon'

const AddFromLibraryDialogGridItem = ({ 
  mediaItem, 
  index, 
  containerWidth, 
  rowHeight, 
  outerPadding, 
  innerPadding, 
  footerHeight, 
  insertFromLibrary,
  summary
}) => {
  const totalPadding = (outerPadding + innerPadding) * 2;
  const availableWidth = mediaItem.containerWidth - totalPadding;
  const availableHeight = rowHeight - totalPadding - footerHeight;
  
  let itemWidth;
  let itemHeight;
  
  if (availableWidth / availableHeight > mediaItem.width / mediaItem.height) {
    itemHeight = availableHeight;
    itemWidth = itemHeight * (mediaItem.width / mediaItem.height);
  } else {
    itemWidth = availableWidth;
    itemHeight = itemWidth / (mediaItem.width / mediaItem.height);
  }

  const isVideo = ['basicVideo', 'recording', 'deviceRecording', 'screenRecording'].includes(mediaItem.type);

  let uploadUserObj;
  let uploadUserName;
  
  if (mediaItem && mediaItem.createdBy) {
    uploadUserObj = getUserForId(mediaItem.createdBy);
    if (uploadUserObj) {
      uploadUserName = uploadUserObj.first_name;
    }
  }

  const startTime = mediaItem.startTime ? formatDuration(mediaItem.startTime) : 0;
  //const thumbnailUrl = `https://res.cloudinary.com/yarn/video/upload/so_${startTime},h_460/${mediaItem.cloudinaryId}.webp`;
  const formattedUploadDate = formatTimestamp(mediaItem.createdAt);
  const hasLinkedWebcam = mediaItem.linkedWebcamCaptureId;

  const itemSummary = summary || null


  return (
    <div 
      key={index} 
      style={{ 
        width: `${containerWidth}px`, 
        height: `${rowHeight}px`, 
        padding: `${outerPadding}px`
      }} 
      onClick={() => insertFromLibrary(mediaItem, mediaItem.chunkIndex)} 
      className="addFromLibraryDialog-grid-itemOuterContainer"
    >
      <div 
        style={{
          padding: `${innerPadding}px`,                
          height: `${rowHeight - (outerPadding * 2)}px`,
          paddingBottom: `${innerPadding + footerHeight}px`,          
        }} 
        data-item-type={mediaItem.type}
        className="addFromLibraryDialog-grid-item"
      >
        <div 
          className="addFromLibraryDialog-grid-item-preview"
          style={{
            width: `${itemWidth}px`,
            height: `${itemHeight}px`,                  
          }}
        >

          {hasLinkedWebcam &&             
            <button 
              onClick={(e) => {
                e.stopPropagation();
                insertFromLibrary(mediaItem, mediaItem.chunkIndex, true);
              }}
              data-linked-item-type='webcam'
              className='addFromLibraryDialog-grid-item-preview-linkedAddButton'
            > 
              <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-inner'>
                <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-iconOuterContainer'>
                  <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--default'>
                    <Icon name='speakerRectangleMedium' />
                  </div>
                  <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer addFromLibraryDialog-grid-item-preview-linkedAddButton-iconContainer--hover'>
                     <Icon name='plusMedium' />
                  </div>
                </div>
                <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-label addFromLibraryDialog-grid-item-preview-linkedAddButton-label--hover'>
                  Add with
                </div>
                <div className='addFromLibraryDialog-grid-item-preview-linkedAddButton-label'>
                  Camera
                </div>
              </div>
            </button>                                    
          }

          {itemSummary &&             
            <>
            <button 
              onClick={(e) => {
                e.stopPropagation();                
              }}
              data-linked-item-type='webcam'
              className='addFromLibraryDialog-grid-item-preview-summary'
            > 
              <div className='addFromLibraryDialog-grid-item-preview-summary-inner'>
                <div className='addFromLibraryDialog-grid-item-preview-summary-iconOuterContainer'>
                  <div className='addFromLibraryDialog-grid-item-preview-summary-iconContainer'>
                    <Icon name='infoOutline' />
                  </div>                  
                </div>                
                <div className='addFromLibraryDialog-grid-item-preview-summary-label'>
                  Summary
                </div>
              </div>
              
            </button>  
            <div className='addFromLibraryDialog-grid-item-preview-summaryContents'>
              <div className='addFromLibraryDialog-grid-item-preview-summaryContents-label'>
                <div className='addFromLibraryDialog-grid-item-preview-summaryContents-label'>
                  {itemSummary.split('\n').map((line, i) => (
                    <div key={i} className='addFromLibraryDialog-grid-item-preview-summaryContents-label-para'>
                      {line}
                    </div>
                  ))}

                </div>
              </div>
              <div className='addFromLibraryDialog-grid-item-preview-summaryContents-bg' />
            </div> 
            </>                                 
          }


          {mediaItem.type === 'image' &&
            <img 
             // src={thumbnailUrl} 
              src={`https://res.cloudinary.com/yarn/image/upload/h_460/${mediaItem.cloudinaryId}.png`} 
              className="addFromLibraryDialog-grid-item-preview-image"
              alt={mediaItem.title}
            />
          }
          {mediaItem.type !== 'image' && mediaItem.duration &&
            <AddFromLibraryDialogGridVideo
              mediaItem={mediaItem} 
              footerHeight={footerHeight}
              innerPadding={innerPadding}      
              frameWidth={itemWidth}   
              frameHeight={Math.round(itemHeight)}              
            />
          }
        </div>

        <div 
          className="addFromLibraryDialog-grid-item-footer"
          style={{
            height: `${footerHeight}px`,                  
          }}
        >                
          <div className='addFromLibraryDialog-grid-item-footer-title'>
            {mediaItem.title} <span className='addFromLibraryDialog-grid-item-footer-title-sub'>{mediaItem.subtitle}</span>
          </div>
          <div className='addFromLibraryDialog-grid-item-footer-subtitle'>
            {uploadUserName} · {formattedUploadDate}
          </div>
          
        </div>
        <div 
          className="addFromLibraryDialog-grid-item-previewBG"
          style={{
            bottom: `${footerHeight + innerPadding}px`,
            left: `${innerPadding}px`,
            top: `${innerPadding}px`,
            right: `${innerPadding}px`,
          }}
        />                                          
      </div>
    </div>
  );
};

export default AddFromLibraryDialogGridItem;
import React, { useState, useEffect } from 'react';
import { fontWeightToNumber } from '../utils/fontWeightToNumber';


const SubtitlesGroupGhost = ({ subtitleGroup, visible,sceneWidth,sceneHeight }) => {
  const [showBalancer, setShowBalancer] = useState(false);
  const bottomMargin = 50;
  const maxWidth = 1600;
  const fontSize = 48;
  
  const groupGhostStyle = {
    fontFamily: 'YInter',
    fontSize: `${fontSize}px`,
    fontWeight: `${fontWeightToNumber('Medium')}`,
    letterSpacing: '-0.005em',
    lineHeight: '1.2',
    width: 'fit-content',
    maxWidth: `${maxWidth}px`,
    marginBottom: `${bottomMargin}px`,
    position: 'absolute',    
    visibility: visible ? 'visible' : 'hidden',
    opacity: visible ? '1' : '0',
    pointerEvents: 'none',    
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  };

  const padding = 0.12 * fontSize;
  const spanStyle = {
    display: 'inline-block',
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
  };


  useEffect(() => {
    // Delay showing the Balancer to ensure the component is fully mounted
    const timer = setTimeout(() => setShowBalancer(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    
      <div id={`ghost_container_${subtitleGroup.id}`} style={{
        width: `${sceneWidth}px`, 
        height: `${sceneHeight}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }} className='editor-subtitlesGroupGhostContainer'>          
        <div id={`ghost_${subtitleGroup.id}`} style={groupGhostStyle} className="editor-subtitlesGroupGhost">
          {subtitleGroup.lines.map((line,index)=>{
            return(
              <div key={`subtitle_ghost_group_${subtitleGroup.id}_line_${line.id}`} className='editor-subtitlesGroupGhost-line' id={`subtitle_line_ghost_${line.id}`} >
                {line.words.map((word)=>{
                  return(
                   <span style={spanStyle} id={`subtitle_word_ghost_${word.id}`} key={`subtitle_word_ghost_${word.id}`} className="editor-captionsGroupGhost-word">
                    {word.text}
                  </span>
                  )
                })}         
              </div>
            )
          })
          
          }
              
        </div>
      </div>  
    
  );
};

export default SubtitlesGroupGhost;
import React, {useState, useEffect} from 'react'

//Dev panel that shows the prosemirror doc

const PMPanel = ({pmDoc,transcriptPmDoc}) => {
  const showTranscriptPmDoc = false;

  let doc = pmDoc 
  if(showTranscriptPmDoc){
    doc = transcriptPmDoc
  }

  return (

    <div className='editor-PMPanelContainer'>
      <div className='editor-PMPanel'>

        <pre>
        <code>
        {JSON.stringify(doc, null, 2)}
        </code>
        </pre>
      </div>

    </div>
  );
}

export default PMPanel;

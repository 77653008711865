import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';
import {findParentNode} from './utils/findParentNode'
import {isFirstWordInChunk} from './utils/isFirstWordInChunk'
import {handleBackspaceInRecordingChunk} from './utils/handleBackspaceInRecordingChunk'

const webcamRecordingKeyPressPluginKey = new PluginKey('webcamKeypress');

function checkIsAtEnd($from, nodePos, transcriptChunkNode) {
  const chunkStart = nodePos + 1;
  let currentPos = chunkStart;
  let hasWordsAfterCursor = false;
  transcriptChunkNode.content.forEach((childNode) => {
    if (childNode.type.name === 'webcamTranscriptItem') {
      if (currentPos > $from.pos) {
        hasWordsAfterCursor = true;
      }
    }
    currentPos += childNode.nodeSize;
  });
  // If no word was found after the cursor, we consider ourselves "at the end"
  const isAtEnd = !hasWordsAfterCursor;
  return isAtEnd;
}

const webcamRecordingKeyPressPlugin = (maybeMergeRecordingClipFromTranscript) => new Plugin({
	key: webcamRecordingKeyPressPluginKey,
	props: {
		handleKeyDown(view, event) {
			const { state } = view;
			const { selection, doc } = state;
			const parent = findParentNode(
				node => node.type.name === 'transcriptGroup',
				selection
			);

			// Allow copy command to pass through
			if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'c') {
				return false;
			}
			//This only applies to webcam recording groups
			if(!parent || parent.node.attrs.groupType !== 'webcam-recording'){
				return false;
			}
			if(event.key == 'Backspace'){
				return handleBackspaceInRecordingChunk(view,event,maybeMergeRecordingClipFromTranscript)
			}
			if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
				if(event.shiftKey){
					return false
				} //todo had a bunch of custom stuff here think we dont need but lets check
				return false 	
			}
if (event.key === 'ArrowLeft') {
  const { state } = view;
  const { selection, doc } = state;
  
  if (!event.shiftKey) {// Original non-shift left arrow behavior
    const { $from } = selection;
    const chunkStart = parent.pos + 1; // Position after the opening tag
    let isAlreadyAtChunkStart = false;
    doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
      if (node.type.name === 'webcamRecordingChunk' && $from.pos === pos + 1) { // +1 because chunk start is after opening tag
        isAlreadyAtChunkStart = true;
        return false;
      }
      return true;
    });
    // If already at chunk start, let default behavior handle it
    if (isAlreadyAtChunkStart) {
      return false;
    }
    const result = isFirstWordInChunk(doc, $from.pos, parent.pos);
    if (result.isFirst) {
      // Move to the start of the chunk using the chunk position we found
      view.dispatch(state.tr.setSelection(TextSelection.create(doc, result.chunkPos)));
      event.preventDefault();
      return true;
    }
    // Otherwise, find the previous word as before
    const prevPos = findNextNodePosition(
      doc,
      $from.pos,
      chunkStart,
      ['webcamTranscriptItem'],
      'backward'
    );
    if (prevPos !== null) {
      view.dispatch(state.tr.setSelection(TextSelection.create(doc, prevPos)));
      event.preventDefault();
      return true;
    }
    return false;
  }

  // Shift+Left behavior
  let oldAnchor = selection.anchor;
  const oldHead = selection.head;
  const headPos = selection.$head.pos;

  // If cursor is mid node then normalize it to select the whole word
  const currentNode = doc.resolve(oldAnchor).parent;
  if (currentNode.type.name === 'webcamTranscriptItem') {
    const nodeEndPos = doc.resolve(oldAnchor).after(doc.resolve(oldAnchor).depth);
    const nodeStartPos = doc.resolve(oldAnchor).before(doc.resolve(oldAnchor).depth);
    if (oldAnchor !== nodeEndPos) {
      // If we needed to normalize, select from end to start of current word
      const tr = state.tr.setSelection(
        TextSelection.create(doc, nodeEndPos, nodeStartPos)
      );
      view.dispatch(tr);
      event.preventDefault();
      return true;
    }
    oldAnchor = nodeEndPos;
  }

  // Get all chunks in the group
  const chunks = [];
  doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
    if (node.type.name === 'webcamRecordingChunk') {
      chunks.push({ node, pos });
    }
    return true;
  });

  // Which chunk is HEAD in?
  const currentChunkIndex = chunks.findIndex(({ node, pos }) => {
    const chunkStart = pos + 1;
    const chunkEnd = pos + node.nodeSize - 1;
    return headPos >= chunkStart && headPos <= chunkEnd;
  });
  if (currentChunkIndex === -1) return false;
  const currentChunk = chunks[currentChunkIndex];
  const { node: transcriptChunkNode, pos: nodePos } = currentChunk;

  // Check if HEAD is at the start of this chunk
  const isAtStart = isFirstWordInChunk(doc, oldHead+1, nodePos).isFirst;
  console.log('isAtStart',isAtStart)
  
  if (isAtStart) {
    // If we're at the start, jump to the previous chunk if there is one
    const prevChunk = chunks[currentChunkIndex - 1];
    if (prevChunk) {
      // SHIFT PRESSED: Jump to last word in previous chunk
      const prevChunkEnd = prevChunk.pos + prevChunk.node.nodeSize - 1;
      const lastWordPos = findNextNodePosition(
        doc,
        prevChunkEnd,
        prevChunk.pos,
        ['webcamTranscriptItem'],
        'backward'
      );
      if (lastWordPos !== null) {
        const tr = state.tr.setSelection(
          TextSelection.create(doc, oldAnchor, lastWordPos+2)
        );
        view.dispatch(tr);
        event.preventDefault();
        return true;
      }
    }
    return false;
  }

  // Otherwise, not at chunk start—find the previous word in the same chunk
  const chunkStart = nodePos + 1;
  const prevPos = findNextNodePosition(
    doc,
    oldHead,
    chunkStart,
    ['webcamTranscriptItem'],
    'backward'
  );
  if (prevPos !== null) {
    const tr = state.tr.setSelection(
      TextSelection.create(doc, oldAnchor, prevPos+2)
    );
    view.dispatch(tr);
    event.preventDefault();
    return true;
  }
  return false;
}


			if (event.key === 'ArrowRight') {
				const { state } = view;
				const { selection, doc } = state;

				let oldAnchor = selection.anchor;
				const oldHead = selection.head;
				const headPos = selection.$head.pos;

				if (event.shiftKey) {
					//If cursor is mid node then normalise it to the end of the node
					const currentNode = doc.resolve(oldAnchor).parent;
					if (currentNode.type.name === 'webcamTranscriptItem') {
						// We're in a word node, so adjust anchor to the end of this word
						const nodeEndPos = doc.resolve(oldAnchor).after(doc.resolve(oldAnchor).depth);
						oldAnchor = nodeEndPos;
					}
				}

				//1) get all the chunks in the group
				const chunks = [];
				doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
					if (node.type.name === 'webcamRecordingChunk') {
						chunks.push({ node, pos });
					}
					return true;
				});

				// 2) Which chunk is HEAD in?
				const currentChunkIndex = chunks.findIndex(({ node, pos }) => {
					const chunkStart = pos + 1;
					const chunkEnd   = pos + node.nodeSize - 1;
					return headPos >= chunkStart && headPos <= chunkEnd;
				});
				if (currentChunkIndex === -1) return false;
				const currentChunk = chunks[currentChunkIndex];
				const { node: transcriptChunkNode, pos: nodePos } = currentChunk;

				// 3) Check if HEAD is at the end of this chunk
				const isAtEnd = checkIsAtEnd(doc.resolve(oldHead-1), nodePos, transcriptChunkNode);
				if (isAtEnd) {
				// 4) If we're at the end, jump to the next chunk if there is one
				const nextChunk = chunks[currentChunkIndex + 1];
				if (nextChunk) {
					const nextChunkStart = nextChunk.pos + 1;
					if (event.shiftKey) {
					// ─────────────────────────────────────────────────
					// SHIFT PRESSED: Jump to end of first word in next chunk
					// ─────────────────────────────────────────────────
					let firstWordEnd = nextChunkStart;
					const wordNode = doc.nodeAt(nextChunkStart);
					if(wordNode){
						firstWordEnd =  nextChunkStart + wordNode.nodeSize-1
					}
					// Extend selection from oldAnchor → firstWordEnd
					const tr = state.tr.setSelection(
						TextSelection.create(doc, oldAnchor, firstWordEnd)
					);
					view.dispatch(tr);
					event.preventDefault();
					return true;
				} else {
					const tr = state.tr.setSelection(
						TextSelection.create(doc, nextChunkStart)
					);
					view.dispatch(tr);
					event.preventDefault();
					return true;
				}
			}
			return false;
			}

			// 5) Otherwise, not at chunk end—find the next word in the same chunk
				const chunkEnd = nodePos + transcriptChunkNode.nodeSize - 1;
				const nextPos = findNextNodePosition(
					doc,
					oldHead-1,
					chunkEnd,
					['webcamTranscriptItem'],
					'forward'
				);
				if (nextPos !== null) {
					const tr = state.tr.setSelection(
					event.shiftKey
						? TextSelection.create(doc, oldAnchor, nextPos+2)
						: TextSelection.create(doc, nextPos)
					);
					view.dispatch(tr);
					event.preventDefault();
					return true;
				}
				return false;
			}

					// Allow only arrow keys and enter key
					const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter','Backspace'];
					if (allowedKeys.includes(event.key)) {
						return false; // Allow default behavior
					} else {
						event.preventDefault();
						return true;
					}
        },
        // Rest of the plugin implementation remains the same...
        handleKeyPress(view, event) {
					const { state } = view;
					const { selection } = state;
					const parent = findParentNode(
						node => node.type.name === 'webcamRecordingChunk',
						selection
					);
					if (parent) {
						// Allow copy command to pass through
						if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'c') {
							return false;
						}
        		event.preventDefault();
        		return true;
    			}
					return false;
        },

				handleDOMEvents: {
					beforeinput(view, event) {
						const { state } = view;
						const { selection } = state;
						const parent = findParentNode(
							node => node.type.name === 'webcamRecordingChunk',
							selection
						);
						if (!parent) return false;
						
						const blockedInputTypes = [
							'insertText',
							'insertCompositionText',
							'insertParagraph',
							'insertLineBreak',
							'deleteContent',
							'deleteByCut',
							'deleteContentBackward',
							'deleteContentForward',
							'insertFromPaste',
							'insertFromDrop',
						];
						if (blockedInputTypes.includes(event.inputType)) {
							event.preventDefault();
							return true;
						}
						return false;
					},
				},
         decorations(state) {
            const { selection } = state;
            if(!selection.empty){
            	return null
            }
            const parent = findParentNode(
                node => node.type.name === 'webcamRecordingChunk',
                selection
            );
            if (!parent) return null;
            const { $from } = selection;
            const decorations = [];
            // Check if cursor is at the start of a chunk
            let isAtChunkStart = false;
            let chunkStartPos = null;
            state.doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
                if (node.type.name === 'webcamRecordingChunk' && 
                    $from.pos === pos + 1) { // +1 because chunk start is after opening tag
                    isAtChunkStart = true;
                    chunkStartPos = pos + 1;
                    return false;
                }
                return true;
            });
            // If at chunk start, add gap cursor
            if (isAtChunkStart && chunkStartPos !== null) {
                decorations.push(
                    Decoration.widget(chunkStartPos, () => {
                        const span = document.createElement('span');
                        span.className = 'editor-transcriptPanel-transcriptGroup--cameraRecording-gapCursor';
                        return span;
                    })
                );
            }
            // If within a word, add existing decorations
            const currentNode = $from.parent;
            if (currentNode.type.name === 'webcamTranscriptItem') {
                decorations.push(
                    Decoration.node($from.before($from.depth), $from.after($from.depth), {
                        class: 'editor-transcriptPanel-transcriptGroup--cameraRecording-word--active'
                    }),
                    Decoration.widget($from.after($from.depth), () => {
                        const span = document.createElement('span');
                        span.className = 'editor-transcriptPanel-transcriptGroup--cameraRecording-gapCursor';
                        return span;
                    })
                );
            }
            return decorations.length > 0 ? DecorationSet.create(state.doc, decorations) : null;
        }
    },
});


function findNextNodePosition(doc, startPos, endPos, nodeTypes, direction = 'forward') {
	let foundPos = null;
	const positions = [];
	doc.nodesBetween(
		Math.min(startPos, endPos),
		Math.max(startPos, endPos),
		(node, pos) => {
			if (nodeTypes.includes(node.type.name)) {
				positions.push({ node, pos, nodeSize: node.nodeSize, type: node.type.name, text: node.textContent });
			}
		}
	);
	if (direction === 'forward') {
		for (let i = 0; i < positions.length; i++) {
			if (positions[i].pos > startPos) {
				foundPos = positions[i].pos + positions[i].nodeSize - 2;
				break;
			}
		}
	} else {
		for (let i = positions.length - 1; i >= 0; i--) {
			if (positions[i].pos < startPos) {
				foundPos = positions[i].pos - 2;
				break;
			}
		}
	}
	return foundPos;
}

export default webcamRecordingKeyPressPlugin;
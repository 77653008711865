import React, { useState, useEffect } from 'react';
import { fontWeightToNumber } from '../utils/fontWeightToNumber';
import Balancer, { Provider } from 'react-wrap-balancer';

const CaptionsGroupGhost = ({ wordGroup, visible, sceneWidth, sceneHeight }) => {
  const [showBalancer, setShowBalancer] = useState(false);
  const bottomMargin = 50;
  const maxWidth = 1400;
  const fontSize = 70;
  
  const groupGhostStyle = {
    fontFamily: 'YInter',
    fontSize: `${fontSize}px`,
    fontWeight: `${fontWeightToNumber('Semibold')}`,
    letterSpacing: '-0.002em',
    lineHeight: '1.2',
    width: 'fit-content',
    maxWidth: `${maxWidth}px`,
    marginBottom: `${bottomMargin}px`,
    position: 'absolute',    
    visibility: visible ? 'visible' : 'hidden',
    opacity: visible ? '1' : '0',
    pointerEvents: 'none',    
    textAlign: 'center',
  };

  const padding = 0.07 * fontSize;
  const spanStyle = {
    display: 'inline-block',
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
  };

  const wordsArray = wordGroup.words;


  useEffect(() => {
    // Delay showing the Balancer to ensure the component is fully mounted
    const timer = setTimeout(() => setShowBalancer(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Provider>
      <div id={`ghost_container_${wordGroup.id}`} style={{
        width: `${sceneWidth}px`, 
        height: `${sceneHeight}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }} className='editor-captionsGroupGhostContainer'>          
        <div id={`ghost_${wordGroup.id}`} style={groupGhostStyle} className="editor-captionsGroupGhost">
          {showBalancer ? (
            <Balancer>
              {wordsArray.map((word, index) => (
                <span style={spanStyle} id={`word_ghost_${word.id}`} key={`word_ghost_${index}`} className="editor-captionsGroupGhost-word">
                  {word.text}
                </span>
              ))}
            </Balancer>
          ) : (
            wordsArray.map((word, index) => (
              <span style={spanStyle} id={`word_ghost_${word.id}`} key={`word_ghost_${index}`} className="editor-captionsGroupGhost-word">
                {word.text}
              </span>
            ))
          )}
        </div>
      </div>  
    </Provider>
  );
};

export default CaptionsGroupGhost;
const VideoClipNodeSpec = {
	attrs: {
		id:{default:'defaultID'},
		type:{default:'video'},
		captureId:{default:null},
		sessionCaptureId:{default:null},
		isScreenRecording:{default:false},
		isBasicVideo:{default:false},
		isUploadingVideo:{default:false},
		videoId:{default:null},
		fileName:{default:null},
		recordingSegments:{default:[]},
		segments:{default:[]},
		relativeStartTime: {default:0},
		recordingDuration:{default:0},
		duration:  {default:0},
		metadata: { default: [] },
		zIndex:  {default:0},
		sceneId:{default:'defaultSceneId'},
		clipPlaybackRate:{default:1}

	},
};

 export default VideoClipNodeSpec
 


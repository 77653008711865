import React from 'react'
import TranscriptChunkNodeSpec from './TranscriptChunkNodeSpec'
import SceneHeaderNodeSpec from './SceneHeaderNodeSpec'
import TranscriptGroupNodeSpec from './TranscriptGroupNodeSpec'
import WebcamRecordingChunkNodeSpec from './WebcamRecordingChunkNodeSpec'
import WebcamTranscriptItemNodeSpec from './WebcamTranscriptItemNodeSpec'


export const nodes = {
	doc: {
		content: "(sceneHeader transcriptGroup+)+"
	},
	paragraph: {
		content: "inline*",
		group: "block",
		parseDOM: [
			{
				tag: "p"
			},
		],
		toDOM(node) {
			const {nodeId,indentLevel} = node.attrs;
			return ["div",{class:`doc-para`}, 0]
		}},
	transcriptChunk:TranscriptChunkNodeSpec,
	sceneHeader:SceneHeaderNodeSpec,
	text: {
		group: "inline",
		marks: "", // Explicitly prevent any marks
		isolating: true // Try to isolate text nodes from parent marks
	},
	transcriptGroup:TranscriptGroupNodeSpec,
	webcamRecordingChunk:WebcamRecordingChunkNodeSpec,
	webcamTranscriptItem:WebcamTranscriptItemNodeSpec,
}

export default nodes
import React, {useRef, useState, useEffect} from 'react';
import Icon from '../../../misc/Icon';
import SpritesheetImage from '../../../uiKit/videoPreview/SpritesheetImage';
import ReactSlider from 'react-slider';
import moment from 'moment';

function VideoDuration({ seconds }) {
  const duration = moment.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const secs = duration.seconds();

  // Format time based on duration
  const formattedTime = hours > 0
    ? `${hours}:${pad(minutes)}:${pad(secs)}`
    : `${minutes}:${pad(secs)}`;

  return (
    <time dateTime={`P${Math.floor(seconds)}S`}>
      {formattedTime}
    </time>
  );
}

function pad(number) {
  return number.toString().padStart(2, '0');
}



async function fetchStoryboardImageJSON(muxPlaybackId) {
  const url = `https://image.mux.com/${muxPlaybackId}/storyboard.json`;
  try {
    const response = await fetch(url, { cache: "no-store" }); // Ensuring no cache is used
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    throw new Error('Failed to fetch storyboard JSON');
  } catch (error) {
    //console.error('Error fetching storyboard JSON:', error);
    return null; // Returning null or a default state in case of an error
  }
}


function VideoPlayerSeekbar({playing, progress, onSeekMouseDown, onSeekMouseUp, onSeek, playbackId, duration}) {

  const [storyboardJson, setStoryboardJson] = useState(null);
  
  const [seekBarWidth, setSeekBarWidth] = useState(0);

  const [hoverTime, setHoverTime] = useState(0);
  const [hoverPercentage, setHoverPercentage] = useState(0);
  const [hoverX, setHoverX] = useState(0);
  const seekBarRef = useRef(null); // Ref for the seekBar
  const [seekBarHovered, setSeekBarHovered] = useState(false); 
  
  useEffect(() => {
    fetchStoryboardImageJSON(playbackId).then(setStoryboardJson);
  }, [playbackId]); // Fetch storyboard JSON when playbackId changes

  const calculateSpritesheetOffset = (duration) => {
    let x = 0;
    let y = 0;
    let foundTile = false;
    if (storyboardJson && storyboardJson.tiles) {
      storyboardJson.tiles.forEach((tile) => {
        if (tile.start > duration && !foundTile) {
          x = tile.x;
          y = tile.y;
          foundTile = true;
        }
      });
    }
    return { x, y };
  };

  const handleMouseMove = (e) => {
    if (!seekBarHovered) return; // Only proceed if hovered

    const seekBar = seekBarRef.current;
    if (seekBar) {
      const { left, width } = seekBar.getBoundingClientRect();
      setSeekBarWidth(width);
      const hoverPositionX = e.clientX - left; // Cursor position within the seekBar      
      setHoverX(hoverPositionX);
      const hoverPercentage = hoverPositionX / width; // Percentage of hover position
      setHoverPercentage(hoverPercentage);
      const durationHoverX = hoverPercentage * duration; // Calculate time based on hover
      setHoverTime(durationHoverX);
    }
  };

  const handleMouseEnter = () => setSeekBarHovered(true);
  const handleMouseLeave = () => setSeekBarHovered(false);

  let storyboardTileWidth = 256
  let storyboardTileHeight = 160     
  if(storyboardJson){
    storyboardTileWidth = storyboardJson.tile_width
    storyboardTileHeight = storyboardJson.tile_height  
  }

  const storyboardTileAspect = storyboardTileWidth / storyboardTileHeight
  const storyboardImageURL = `https://image.mux.com/${playbackId}/storyboard.png`

  const widthHoverPreview = 200
  const heightHoverPreview = widthHoverPreview / storyboardTileAspect

  const storyboardOffsetX = 0
  const storyboardOffsetY = 200

  const minXHoverPreview = 5 // don't let preview overflow left edge
  const maxXHoverPreview = seekBarWidth - widthHoverPreview - 5  // don't let preview overflow right edge      
  const standardXHoverPreview = hoverX - (0.5 * widthHoverPreview)

  const hoverPreviewPosition = Math.max(minXHoverPreview, Math.min(maxXHoverPreview, standardXHoverPreview));

  const percentHoverX = hoverX / seekBarWidth
  const durationHoverX = percentHoverX * duration

  let spriteSheetOffset={x:0,y:0}
  if(seekBarHovered){
    spriteSheetOffset=calculateSpritesheetOffset(durationHoverX)
  }



  return (
    <>
    <div 
      ref={seekBarRef}
      className='editor-generationsDialog-controls-seekBar'
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >


      <ReactSlider
        className="editor-generationsDialog-controls-seekBar-seekBar"
        thumbClassName="editor-generationsDialog-controls-seekBar-seekBar-thumb"
        trackClassName="editor-generationsDialog-controls-seekBar-seekBar-track"                  
        renderThumb={(props, state) => <div className='editor-generationsDialog-controls-seekBar-thumb-thumb' {...props} />}

        value={progress}
        min={0}
        max={0.999999}
        step={0.0001}
        onAfterChange={onSeekMouseUp}
        onBeforeChange={onSeekMouseDown}
        onChange={onSeek}
      />  


      {seekBarHovered &&
        <div style={{width: `${widthHoverPreview}px`, height: `${heightHoverPreview}px`, top: `${(heightHoverPreview * -1)}px`,  left: `${hoverPreviewPosition}px`}} className='editor-generationsDialog-controls-seekBar-hoverPreview'>
          <SpritesheetImage 
            tileWidth={storyboardTileWidth}
            tileHeight={storyboardTileHeight}
            tileAspect={storyboardTileAspect}
            outputImageWidth={widthHoverPreview}
            outputImageHeight={heightHoverPreview}
            offsetX={spriteSheetOffset.x}
            offsetY={spriteSheetOffset.y}
            spriteSheetImageUrl={storyboardImageURL}
          />

          <div className='editor-generationsDialog-controls-seekBar-hoverPreview-timestampContainer'>             
            <div className='editor-generationsDialog-controls-seekBar-hoverPreview-timestamp'>
              <VideoDuration seconds={durationHoverX} />                
            </div>
          </div>
        </div>
      }


      

      <div data-state={seekBarHovered ? 'visible' : 'hidden'} className='editor-generationsDialog-controls-seekBar-marker' style={{left: `${hoverPercentage * 100}%` }} />
      

    </div>
    </>
  );
}

export default VideoPlayerSeekbar;
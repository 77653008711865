import { SET_PENDING_SESSION_CAPTURE } from '../actions/editor';

const initialState = {
	pendingSessionCapture: null,
	// ... other editor state
};

export default function editor(state = initialState, action) {
	switch (action.type) {
		case SET_PENDING_SESSION_CAPTURE:
			return {
				...state,
				pendingSessionCapture: {
					sessionCaptureId: action.payload.sessionCaptureId,
					projectId: action.payload.projectId
				}
			};
		// ... other cases
		default:
			return state;
	}
} 
import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Editor from '../components/editor/Editor'
import { openProject } from '../utils/projectUtils/openProject'
// import find from 'lodash/find'


//Wraps editor to key it so it reloads on id change
class EditorWrapperContainer extends Component {
	render() {
		return (
			<Editor 
				key={this.props.projectId} 
				{...this.props}
			/>
		)
	}
}

function mapStateToProps(state, ownProps) {
	const projectId = ownProps.match.params.id
	return {
		projectId: projectId,
		pendingSessionCapture: state.editor.pendingSessionCapture
	}
}

export default withRouter(connect(mapStateToProps)(EditorWrapperContainer))
// keyboardSoundMappings.js

export const keyToSoundNumber = {
  // Maps based on keyType
  'RETURN': 28,
  'TAB': 15,
  'ESCAPE': 1,
  'SPACE': 58,
  'DELETE': 14,
  
  // Arrow keys
  'UPARROW': 57416,
  'LEFTARROW': 57419,
  'RIGHTARROW': 57421,
  'DOWNARROW': 57424,

  // Regular character keys
  'A': 30,
  'B': 48,
  'C': 46,
  'D': 32,
  'E': 18,
  'F': 33,
  'G': 34,
  'H': 35,
  'I': 23,
  'J': 36,
  'K': 37,
  'L': 38,
  'M': 50,
  'N': 49,
  'O': 24,
  'P': 25,
  'Q': 16,
  'R': 19,
  'S': 31,
  'T': 20,
  'U': 22,
  'V': 47,
  'W': 17,
  'X': 45,
  'Y': 21,
  'Z': 44,
  
  // Numbers
  '1': 2,
  '2': 3,
  '3': 4,
  '4': 5,
  '5': 6,
  '6': 7,
  '7': 8,
  '8': 9,
  '9': 10,
  '0': 11,
  
  // Special characters
  '-': 12,
  '=': 13,
  '[': 26,
  ']': 27,
  ';': 39,
  "'": 40,
  '`': 41,
  '\\': 43,
  ',': 51,
  '.': 52,
  '/': 53,
};

export const getSoundPathForKey = (keySymbol, eventType, keyType) => {
  // Handle special key types first
  if (keyType && keyType !== 'character') {
      const specialKeyNumber = keyToSoundNumber[keyType.toUpperCase()];
      if (specialKeyNumber) {
          return `cream/${specialKeyNumber}-${eventType.toLowerCase()}.wav`;
      }
  }

  // For regular character keys, use the keySymbol
  const upperKey = keySymbol.toUpperCase();
  const soundNumber = keyToSoundNumber[upperKey];
  
  if (!soundNumber) {
      return null;
  }

  return `cream/${soundNumber}-${eventType.toLowerCase()}.wav`;
};

export const isValidKeySound = (keySymbol, keyType) => {
  // Check special key types first
  if (keyType && keyType !== 'character') {
      return keyType.toUpperCase() in keyToSoundNumber;
  }

  // Then check regular character keys
  return keySymbol.toUpperCase() in keyToSoundNumber;
};
import { Timeline} from '../Timeline';
import {randomID} from '../../utils/randomID'

// 1. create timeline
// 2. init PM doc (creates project settings node)
// 3. call timeline init scenes to add scenes to timeline. Scenes have an init function that creates TimelineScene and Clips as well as PM nodes for each of these
//when adding nodes we use isInitialLoad so we know if we should add the step to the PM history



//the default scene has a title untitled
//when you add scenes it changed to Untitled Scene 
//so we can tell if it has ever been updated
// we dont show titles if there is only one scene and the tite='Untitled'
//but if there is only one scene and the title is "Untitled Scene" then we do show the title (because have added a scene and removed it so it would be weird if title went away)

function makeSceneTemplate(clips){
	return{
		id: randomID(),
		title:"Default title", 
		sceneIndex:0,
		startTime:0,
		duration:10,
		clips: clips|| []
	}
}

async function createTimeline(projectId,timelineData, onTimeUpdate,handlePlaybackEnded,handleClipMediaLoaded,setPMDocForDevMode,projectBackgroundId,handleUpdateProjectBackground,pmManager,transcriptPmManager,handleVoiceoverAudioUpdated,setPreviewingAudioClipId,handleTextElementFontLoaded,handleCreateVoiceMatchForProject) {
	try {
		const newTimeline = new Timeline(projectId,timelineData,onTimeUpdate,handlePlaybackEnded,handleClipMediaLoaded,setPMDocForDevMode,projectBackgroundId,handleUpdateProjectBackground,pmManager,transcriptPmManager,handleVoiceoverAudioUpdated,setPreviewingAudioClipId,handleTextElementFontLoaded,handleCreateVoiceMatchForProject);
		newTimeline.createPMDoc()

		let scenes = timelineData.scenes || [makeSceneTemplate()]
		if(timelineData.clips){ //legacy pre scenes support
			let audioClips = [];
			let nonAudioClips = [];
			timelineData.clips.forEach(clip => {
				if (clip.type === 'audio') {
					audioClips.push({
					...clip,
					pinnedStartTime: clip.startTime
				});
				} else {
					nonAudioClips.push(clip);
				}
			});

			audioClips.sort((a, b) => a.startTime - b.startTime);

			audioClips.forEach((clip, index) => {
				clip.clipIndex = index;
			});
			scenes = [makeSceneTemplate([...audioClips, ...nonAudioClips])];
		}

		await newTimeline.initScenes(scenes)
		return newTimeline;
	} catch (error) {
		console.error('Error creating timeline:', error);
		throw error; // Rethrow or handle the error as appropriate
	}
}

export { createTimeline };


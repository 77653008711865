import {randomID} from '../../utils/randomID'
import {getDurationForCaptureId} from '../../utils/recordings/getDurationForCaptureId'
import {getLabelForCaptureId} from '../../utils/recordings/getLabelForCaptureId'
import {getDeviceDimensionsForCaptureId} from '../../utils/recordings/deviceRecordings/getDeviceDimensionsForCaptureId'
import {getOrgScreenVideoStyle} from '../../utils/brands/getOrgScreenVideoStyle'
import {calculateScreenRecordingDisplaySettings} from '../../utils/recordings/screenRecordings/calculateScreenRecordingDisplaySettings'
import {getScreenVideoDefaultTransitions} from '../../utils/brands/getScreenVideoDefaultTransitions'
//TODO webcam layouts

export async function createVideoClipObjFromCaptureId(captureId, isDevice, isScreenRecording, motionStyle, currentTime, chunkIndex = null, chunkTrimStart,chunkTrimEnd, sessionCaptureId, clipId, linkedWebcamClip,sessionDuration, recordingChunks) {    
    // Get the full video duration - this will be our recordingDuration
    const recordingDuration = await getDurationForCaptureId(captureId, isDevice, isScreenRecording)    
    let label = await getLabelForCaptureId(captureId, isDevice, isScreenRecording)
    let duration = sessionDuration || recordingDuration // Default duration to full length



    const {enterTransition, exitTransition} = getScreenVideoDefaultTransitions()


    let metadata = {
        label: label,
        linkedClipId: linkedWebcamClip,
        startTransitionType: enterTransition,
        endTransitionType: exitTransition,
        motionStyle: motionStyle,
        isAutoMotionStyle: true,
        backgroundId: 'none',
        chunkIndex: chunkIndex,
        trimStart: 0,
        trimEnd: recordingDuration
    }


    // If we're using a chunk, adjust the duration and trim points
    if (chunkTrimStart || chunkTrimEnd) {
        metadata.trimStart = chunkTrimStart
        metadata.trimEnd = chunkTrimEnd
        //New for insert as chunk at the min and max trim times
        metadata.minTrimStart = chunkTrimStart
        metadata.maxTrimEnd = chunkTrimEnd
        duration = chunkTrimEnd-chunkTrimStart
    }

    let canHaveDeviceFrame = false
    if (label == 'Google Chrome') {
        canHaveDeviceFrame = true
    }
    if (canHaveDeviceFrame) {
        const orgScreenVideoStyle = getOrgScreenVideoStyle()
        if (orgScreenVideoStyle && orgScreenVideoStyle.defaultColorFrame) {
            metadata.deviceFrame = 'color'
        }
    }
    if (isDevice) {
        const deviceDimensions = await getDeviceDimensionsForCaptureId(captureId)
        metadata.deviceWidth = deviceDimensions.width
        metadata.deviceHeight = deviceDimensions.height
    }

    if(isScreenRecording && sessionCaptureId){
        const displaySettings = await calculateScreenRecordingDisplaySettings({
            recordingChunks: recordingChunks,
            trimStart: metadata.trimStart,
            trimEnd: metadata.trimEnd,
            recordingDuration: recordingDuration,
            currentDisplayMode: null,
            currentWebcamLayouts: null,
            captureId: captureId
        })
        metadata.displayMode = displaySettings.displayMode
        metadata.allowWindowMode = displaySettings.allowWindowMode
        metadata.screenVideoApp = displaySettings.screenVideoApp
        metadata.deviceFrame = displaySettings.deviceFrame
        metadata.screenVideoDisplayPosition = displaySettings.displayPosition
        metadata.webcamLayouts = displaySettings.webcamLayouts
    }else{
        if(metadata.label=='Google Chrome'){
            metadata.screenVideoApp='chrome'
        }else{
            metadata.screenVideoApp=''
        }
    }

    let newClip = {
        id: clipId || randomID(),
        captureId: captureId,
        isDeviceRecording: isDevice,
        sessionCaptureId: sessionCaptureId,
        type: 'video',
        isScreenRecording: isScreenRecording ? true : false,
        startTime: currentTime,
        duration: duration,
        recordingDuration: recordingDuration, // Add the full recording duration
        zIndex: 0,
        metadata: metadata,
        recordingChunks: recordingChunks
    }
    
    return newClip
}
import React, { useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import Icon from './misc/Icon'

function ForceUpdateModal({ showModal }) {

   // Add effect to capture all keyboard events when modal is open
  useEffect(() => {
    if (showModal) {
      const preventKeyboardShortcuts = (e) => {
        // Prevent all keyboard events except those needed for accessibility
        e.preventDefault();
        e.stopPropagation();
      };
      
      // Add capture phase event listener to catch events before they reach other handlers
      window.addEventListener('keydown', preventKeyboardShortcuts, { capture: true });
      
      return () => {
        window.removeEventListener('keydown', preventKeyboardShortcuts, { capture: true });
      };
    }
  }, [showModal]);


  return (
    <Dialog.Root open={showModal} modal={true}>
      <Dialog.Portal>
        <Dialog.Overlay className='forceUpdateModal-overlay' />
        <Dialog.Content 
          className="forceUpdateModal forceDarkTheme"
          onEscapeKeyDown={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
          onInteractOutside={(e) => e.preventDefault()}
        >
          <VisuallyHidden.Root>
            <Dialog.Title>Update Required</Dialog.Title>            
          </VisuallyHidden.Root>
            <div className='forceUpdateModal-iconContainer'>
              <Icon name='arrowUpCircleMedium' />
            </div>
            <div className='forceUpdateModal-title'>
              App update required         
            </div>
            <div className='forceUpdateModal-description'>
              <div className='forceUpdateModal-description-para'>
                A major new update of Yarn is available.
              </div>
              <div className='forceUpdateModal-description-para'>
                It’s currently downloading to your computer. 
              </div>
              <div className='forceUpdateModal-description-para'>
                Once the download is finished and you see the update prompt, click “Restart” to complete the upgrade process.
              </div>            
            </div>            
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default ForceUpdateModal;
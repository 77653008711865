import * as THREE from 'three';

//const BASE_RADIUS = 12; // radius at 1920 by 1080
const BASE_RADIUS = 12; // radius at 1920 by 1080



// Maximum dimensions for which no additional padding is applied
const MAX_DEVICE_WIDTH = 6016;
const MAX_DEVICE_HEIGHT = 3384;
// Define a scalar for padding
const PADDING_SCALAR = 1; // smaller is larger

// Helper function to resize the object to fit within the scene with dynamic padding
const resizeWindowDeviceToFit = (objWidth, objHeight, basePaddingPercent, sceneWidth, sceneHeight, recordingPointScale) => {
  // Determine the scale of the object's dimensions relative to the maximum dimensions
  const widthScaleFactor = objWidth / MAX_DEVICE_WIDTH;
  const heightScaleFactor = objHeight / MAX_DEVICE_HEIGHT;

  // Use the largest scale factor (closest to 1) to reduce padding,
  // ensuring that no additional padding is added if the object is at maximum dimensions
  const scaleFactor = Math.max(widthScaleFactor, heightScaleFactor);

  // Adjust padding based on the scale factor
  const adjustedPaddingPercent = basePaddingPercent * (1 - scaleFactor * PADDING_SCALAR);

  // Calculate the effective scene size after applying the dynamic padding
  const effectiveSceneWidth = sceneWidth * (1 - adjustedPaddingPercent / 100);
  const effectiveSceneHeight = sceneHeight * (1 - adjustedPaddingPercent / 100);

  // Calculate the scale factors for width and height
  const widthScale = effectiveSceneWidth / objWidth;
  const heightScale = effectiveSceneHeight / objHeight;

  // Use the smallest scale factor to ensure the object fits within the scene
  const scale = Math.min(widthScale, heightScale);

  // Calculate the new radius based on the scaling factor

  const meshRadius = BASE_RADIUS * scale * recordingPointScale;

  return { meshWidth: objWidth * scale, meshHeight: objHeight * scale, meshRadius };

};



export default resizeWindowDeviceToFit
import React, { useState, useRef, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import Icon from '../../../../misc/Icon';
import { calculateTimelineTimeFromVideoTime } from '../../../../../timeline/utils/calculateTimelineTimeFromVideoTime';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { IMPORTANT_POSITIONS, IMPORTANT_SIZES, webcamPositions, webcamSizes } from '../../../../../utils/webcam/webcamConfigs';
import { calulateVideoTimeFromTimelineTime } from '../../../../../timeline/utils/calulateVideoTimeFromTimelineTime';

const HANDLE_WIDTH = 20;

// Helper functions to get labels
const getPositionLabel = (positionName) => {
  const position = webcamPositions.find(p => p.name === positionName);
  return position ? position.userLabel : positionName;
};

const getSizeLabel = (sizeName) => {
  const size = webcamSizes.find(s => s.name === sizeName);
  return size ? size.userLabel : sizeName;
};

const WebcamPhaseHandle = ({ 
  leftPhase,
  rightPhase,
  totalDuration,
  clipWidth,
  onDrag,
  yOffset,
  sceneGapOffset,
  setIsDraggingSegment,
  clipType
}) => {
  const [isDragging, setIsDragging] = useState(false);
  
  const handlePosition = ((leftPhase.startTime + leftPhase.duration) / totalDuration * clipWidth + sceneGapOffset) - (HANDLE_WIDTH/2);
  
  return (
    <>
      <Rnd        
        className="editor-timeline-clip-webcamPhase-handle editor-timeline-clip-webcamPhase-handle--draggable"
        size={{ width: HANDLE_WIDTH, height: 30 }}
        position={{ x: handlePosition, y: yOffset }}
        onDragStart={() => {
          setIsDragging(true);
          setIsDraggingSegment(true);
        }}
        onDrag={(e, d) => {
          const newTime = ((d.x + HANDLE_WIDTH/2) - sceneGapOffset) / clipWidth * totalDuration;
          onDrag(leftPhase.id, rightPhase.id, newTime);
        }}
        enableResizing={false}
        onDragStop={() => {
          setIsDragging(false);
          setIsDraggingSegment(false);
        }}
        dragAxis="x"
        style={{ cursor: 'grab'}}
      />              

      <div 
        data-dragging-state={isDragging ? 'dragging' : 'notDragging'}
        data-hovered-state
        className="editor-timeline-clip-webcamPhase-handle editor-timeline-clip-webcamPhase-handle--render"
        style={{
          position: 'absolute',
          left: `${handlePosition}px`,
          top: `${yOffset}px`,
          height: '30px',
          width: `${HANDLE_WIDTH}px`,
          pointerEvents: 'none',          
        }}
      >
        <div className="editor-timeline-clip-webcamPhase-handle-inner">
          <div data-clip-type={clipType} className="editor-timeline-clip-webcamPhase-handle-bar" />
        </div>
      </div>
    </>
  );
};

const WebcamPhaseEditButton = ({ 
  phase,
  totalDuration,
  clipWidth,
  yOffset,
  sceneGapOffset,
  onSplitPhase,
  onRemovePhase,
  index,
  singleWebcamPhase,
  clipType,
  updateSingleWebcamPhase
}) => {
  const handlePositionChange = (newPosition) => {
    const updatedPhase = {
      ...phase,
      position: newPosition
    };
    updateSingleWebcamPhase(updatedPhase);
  };

  const handleSizeChange = (newSize) => {
    // Get the size configuration
    const sizeConfig = webcamSizes.find(s => s.name === newSize);
    
    // Check if current position is valid for new size
    if (sizeConfig && !sizeConfig.validPositions.includes(phase.position)) {
      // Find the webcam position config for current position
      const currentPosConfig = webcamPositions.find(p => p.name === phase.position);
      if (currentPosConfig) {
        // Try to find first valid alternative position
        const newPosition = currentPosConfig.altPositions.find(alt => 
          sizeConfig.validPositions.includes(alt)
        ) || sizeConfig.validPositions[0]; // Fallback to first valid position

        // Update both size and position
        const updatedPhase = {
          ...phase,
          size: newSize,
          position: newPosition
        };
        updateSingleWebcamPhase(updatedPhase);
        return;
      }
    }

    // If current position is valid for new size, just update size
    const updatedPhase = {
      ...phase,
      size: newSize
    };
    updateSingleWebcamPhase(updatedPhase);
  };

  // Get available sizes and positions
  const availableSizes = [...IMPORTANT_SIZES];
  if (!IMPORTANT_SIZES.includes(phase.size)) {
    availableSizes.push(phase.size);
  }

  // Get valid positions for current size
  const currentSizeConfig = webcamSizes.find(s => s.name === phase.size);
  const validPositions = currentSizeConfig ? currentSizeConfig.validPositions : [];

  const availablePositions = [...IMPORTANT_POSITIONS];
  if (!IMPORTANT_POSITIONS.includes(phase.position)) {
    availablePositions.push(phase.position);
  }

  const pixelWidth = (phase.duration / totalDuration) * clipWidth;
  const left = (phase.startTime / totalDuration) * clipWidth + sceneGapOffset;  

  return (    
    <div style={{
        position: 'absolute',
        left: `${left}px`,
        top: `${yOffset}px`,
        width: `${pixelWidth}px`,
        height: '30px'
      }}
      data-first-phase={index === 0 ? true : false}
      className='editor-timeline-clip-webcamPhase-buttonContainer'
      data-clip-type={clipType}
    >
      <div className='editor-timeline-clip-webcamPhase-buttonContainer-lineContainer editor-timeline-clip-webcamPhase-buttonContainer-lineContainer--left'>
        <div className='editor-timeline-clip-webcamPhase-buttonContainer-line' />
      </div>

      <DropdownMenu.Root modal={true}> 
        <DropdownMenu.Trigger asChild>            
          <button className="editor-timeline-clip-webcamPhase-button">
            <div className="editor-timeline-clip-webcamPhase-button-iconContainer">
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className="editor-timeline-clip-webcamPhase-button-label">                
              {getPositionLabel(phase.position)}                
              <span className='editor-timeline-clip-webcamPhase-button-label-sub'>
                {getSizeLabel(phase.size)}                
              </span>
            </div>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content             
            align="middle"            
            side="top"
            sideOffset={2}
            className='dropdownMenu dropdownMenu--timelineSelect forceDarkTheme'
          >                     
            <div className='dropdownMenu-subHeader'>
              Camera Shape
            </div>            

            {availableSizes.map(size => (
              <DropdownMenu.Item 
                key={size}
                className={phase.size === size ? 'dropdownMenu-item--active' : ''}
                onSelect={(event) => {
                  event.preventDefault();
                  handleSizeChange(size);
                }}
              >
                {getSizeLabel(size)}
              </DropdownMenu.Item>
            ))}

            
            <DropdownMenu.Separator/>
            <div className='dropdownMenu-extraTinyVSpacer' />
            <div className='dropdownMenu-subHeader'>
              Camera Position
            </div>            
            
            {availablePositions.map(position => {
              const disabled = !validPositions.includes(position);
              return (
                <DropdownMenu.Item 
                  key={position}
                  className={`${phase.position === position ? 'dropdownMenu-item--active' : ''} ${disabled ? 'dropdownMenu-item--disabled' : ''}`}
                  onSelect={(event) => {
                    event.preventDefault();
                    handlePositionChange(position);
                  }}
                  disabled={disabled}
                >
                  {getPositionLabel(position)}
                </DropdownMenu.Item>
              );
            })}

            <DropdownMenu.Separator/>
          
            <DropdownMenu.Item onSelect={() => onSplitPhase(phase.id)}>
              Add layout switch
            </DropdownMenu.Item>
            <DropdownMenu.Item 
              onSelect={() => onRemovePhase(phase.id)}
              className={singleWebcamPhase ? 'dropdownMenu-item--disabled' : ''}
              disabled={singleWebcamPhase}
            >
              Remove layout switch
            </DropdownMenu.Item>      

          </DropdownMenu.Content>
        </DropdownMenu.Portal>          
      </DropdownMenu.Root>

      <div className='editor-timeline-clip-webcamPhase-buttonContainer-lineContainer editor-timeline-clip-webcamPhase-buttonContainer-lineContainer--right'>
        <div className='editor-timeline-clip-webcamPhase-buttonContainer-line' />
      </div>
    </div>
  );
};

function convertWebcamLayoutToTimelinePhase(webcamLayout, clip) {
  const clipStart = clip.startTime
  const newPhase = {
    ...webcamLayout,
    endTime: calculateTimelineTimeFromVideoTime(webcamLayout.startTime + webcamLayout.duration, clip) - clipStart,
    startTime: calculateTimelineTimeFromVideoTime(webcamLayout.startTime, clip) - clipStart
  }
  newPhase.startTime = Math.max(0, newPhase.startTime)
  newPhase.duration = newPhase.endTime - newPhase.startTime
  return newPhase
}

function convertWebcamLayoutsToTimelinePhases(webcamLayouts, clip) {
  const timelinePhases = []
  for (const layout of webcamLayouts) {
    timelinePhases.push(convertWebcamLayoutToTimelinePhase(layout, clip))
  }
  timelinePhases.filter(p => p.duration > 0)
  return timelinePhases
}


const EditorTimelineClipWebcamPhases = ({ 
  clip, 
  clipWidth, 
  trimStart, 
  scrollRef, 
  handleSeek, 
  pixelsPerSec,
  yOffset,
  sceneGap,
  setIsDraggingSegment,
  clipType,
  updateClipMetadata
}) => {

  const [webcamPhases, setWebcamPhases] = useState([])
  
  useEffect(() => {
    const newWebcamPhases = convertWebcamLayoutsToTimelinePhases(clip.metadata.webcamLayouts, clip).filter(p => p.duration > 0)
    setWebcamPhases(newWebcamPhases)
  }, [clip.metadata.webcamLayouts])

  const updateWebcamPhaseSizeOrPosition = (newPhase) => {
    updateClipMetadata(clip.id, {
      webcamLayouts: clip.metadata.webcamLayouts.map(p => p.id === newPhase.id ? {...p, position: newPhase.position, size: newPhase.size} : p)
    })
  }

  const updateWebcamPhaseEndTime = (id, newEndTime) => {
    updateClipMetadata(clip.id, {
      webcamLayouts: clip.metadata.webcamLayouts.map(p => p.id === id ? {...p, endTime: newEndTime, duration: newEndTime - p.startTime} : p)
    })
  }

  const updateWebcamPhaseStartTime = (id, newStartTime) => {
    updateClipMetadata(clip.id, {
      webcamLayouts: clip.metadata.webcamLayouts.map(p => p.id === id ? {...p, startTime: newStartTime, duration: p.endTime - newStartTime} : p)
    })
  }

  const addWebcamPhase = (newPhase, index) => {
    updateClipMetadata(clip.id, {
      webcamLayouts: [...clip.metadata.webcamLayouts.slice(0, index), newPhase, ...clip.metadata.webcamLayouts.slice(index)]
    })
  }

  const removeWebcamPhase = (index) => {
    updateClipMetadata(clip.id, {
      webcamLayouts: clip.metadata.webcamLayouts.filter((_, i) => i !== index)
    })
  }

  const handlePhaseDrag = (leftPhaseId, rightPhaseId, newBoundaryTime) => {
    const newPhases = [...webcamPhases];
    const leftPhaseIndex = newPhases.findIndex(p => p.id === leftPhaseId);
    const rightPhaseIndex = leftPhaseIndex + 1;

    const minTime = leftPhaseIndex > 0 ? newPhases[leftPhaseIndex - 1].startTime + 0.1 : 0;
    const maxTime = rightPhaseIndex < newPhases.length - 1 
      ? newPhases[rightPhaseIndex + 1].startTime - 0.1 
      : clip.duration;
    
    const clampedTime = Math.min(Math.max(newBoundaryTime, minTime), maxTime);
    
    newPhases[leftPhaseIndex] = {
      ...newPhases[leftPhaseIndex],
      duration: clampedTime - newPhases[leftPhaseIndex].startTime
    };

    newPhases[rightPhaseIndex] = {
      ...newPhases[rightPhaseIndex],
      startTime: clampedTime,
      duration: newPhases[rightPhaseIndex].startTime + newPhases[rightPhaseIndex].duration - clampedTime
    };
    setWebcamPhases(newPhases)

    const clampedVideoTime = calulateVideoTimeFromTimelineTime(clampedTime + clip.startTime, clip)
    updateWebcamPhaseEndTime(leftPhaseId, clampedVideoTime)
    updateWebcamPhaseStartTime(rightPhaseId, clampedVideoTime)
  };

  const handleSplitPhase = (phaseId) => {
    const phaseIndex = webcamPhases.findIndex(p => p.id === phaseId);
    const phaseToSplit = webcamPhases[phaseIndex]
    
    // Split at the midway of the clip phase instead of the video phase so we split where the user expects.
    const halfDuration = phaseToSplit.duration / 2;
    const midpointTime = phaseToSplit.startTime + halfDuration;
    const videoMidpointTime = calulateVideoTimeFromTimelineTime(midpointTime + clip.startTime, clip)

    
    const leftPhase = {
      ...clip.metadata.webcamLayouts[phaseIndex],
      id: `${phaseId}-left-${Date.now()}`,
      endTime: videoMidpointTime,
      duration: videoMidpointTime - clip.metadata.webcamLayouts[phaseIndex].startTime
    };
    
    const rightPhase = {
      ...clip.metadata.webcamLayouts[phaseIndex],
      id: `${phaseId}-right-${Date.now()}`,
      startTime: videoMidpointTime,
      duration: clip.metadata.webcamLayouts[phaseIndex].endTime - videoMidpointTime
    };
    
    removeWebcamPhase(phaseIndex)
    addWebcamPhase(leftPhase, phaseIndex)
    addWebcamPhase(rightPhase, phaseIndex + 1)
  };

  const handleRemovePhase = (phaseId) => {
    // Don't remove if there's only one phase
    if (webcamPhases.length <= 1) return;

    const phaseIndex = webcamPhases.findIndex(p => p.id === phaseId);
    
    // If removing first phase, extend the next phase to start from 0
    if (phaseIndex === 0 && webcamPhases.length > 1) {
      updateWebcamPhaseStartTime(webcamPhases[1].id, 0)
    }
    
    // If removing middle phase, extend the previous phase to cover the removed space
    else if (phaseIndex > 0) {
      const newEndTime = clip.metadata.webcamLayouts[phaseIndex].endTime
      updateWebcamPhaseEndTime(webcamPhases[phaseIndex - 1].id, newEndTime)
    }
    removeWebcamPhase(phaseIndex)
  };

  const sceneGapOffset = clip.sceneIndex * sceneGap;
  const singleWebcamPhase = webcamPhases.length === 1;

  return (
    <>
      {webcamPhases.map((phase, index) => (
        <WebcamPhaseEditButton
          key={phase.id}
          phase={phase}
          totalDuration={clip.duration}
          clipWidth={clipWidth}
          yOffset={yOffset}
          sceneGapOffset={sceneGapOffset}
          onSplitPhase={handleSplitPhase}
          onRemovePhase={handleRemovePhase}
          index={index}
          singleWebcamPhase={singleWebcamPhase}
          clipType={clipType}
          updateSingleWebcamPhase={updateWebcamPhaseSizeOrPosition}
        />
      ))}

      {webcamPhases.slice(0, -1).map((phase, index) => (
        <WebcamPhaseHandle
          key={`handle-${phase.id}`}
          leftPhase={phase}
          rightPhase={webcamPhases[index + 1]}
          totalDuration={clip.duration}
          clipWidth={clipWidth}
          onDrag={handlePhaseDrag}
          yOffset={yOffset}
          sceneGapOffset={sceneGapOffset}
          setIsDraggingSegment={setIsDraggingSegment}
          clipType={clipType}
        />
      ))}
    </>
  );
};

export default EditorTimelineClipWebcamPhases;
import sortBy from 'lodash/sortBy'
import {randomID} from '../../../utils/randomID'
import editorSchema from '../schema/transcriptSchema'

const DEFAULT_INSTRUCTIONS = ' ';


export function createProsemirrorDocFromClips(webcamClips, clips, unsortedScenes) {   
	let contentArray = [];
	const scenes = unsortedScenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
	const sceneCount = scenes.length;
	let totalChunkCount = 0;

	scenes.forEach((scene) => {
		let title = scene.title;
		if (title == 'Untitled Scene') {
			title = '';
		}
		
		let paragraphNode = title ? 
			editorSchema.nodes.paragraph.create({}, [editorSchema.text(title)]) :
			editorSchema.nodes.paragraph.create({});
		
		const sceneHeaderNode = editorSchema.nodes.sceneHeader.createAndFill({ 
			sceneId: scene.id,
			sceneDuration: scene.duration,
			sceneCount: sceneCount 
		}, [paragraphNode]);
		
		contentArray.push(sceneHeaderNode);

		// Filter and sort clips
		const sceneAudioClips = clips.filter(clip => clip.sceneId === scene.id);
		const sceneWebcamClips = webcamClips.filter(clip => clip.sceneId === scene.id);
		const sceneClips = [...sceneAudioClips, ...sceneWebcamClips];
		const sortedClips = sortBy(sceneClips, 'startTime');

		// Organize clips into groups
		const groups = [];
		let currentStandardGroup = null;

		function finalizeCurrentStandardGroup() {
			if (currentStandardGroup && currentStandardGroup.clips.length > 0) {
				groups.push(currentStandardGroup);
				currentStandardGroup = null;
			}
		}

		if (sortedClips.length === 0) {
			// Create a default empty group for scenes with no clips
			groups.push({
				type: 'standard',
				id: randomID(),
				clips: [null] // Indicates empty chunk needed
			});
		} else {
			sortedClips.forEach(clip => {
				if (clip.type === 'webcam') {
					finalizeCurrentStandardGroup();
					
					if (clip.metadata.isVariable) {
						// Find any audio clips that reference this webcam
						let associatedAudioClips =[]
						if(!clip.metadata.hasInstructions){
							associatedAudioClips = sceneAudioClips.filter(
								audioClip => audioClip.parentWebcamClip === clip.id
							);
						}
						groups.push({
							type: 'variable-webcam',
							id: clip.id,
							clips: associatedAudioClips.length > 0 ? associatedAudioClips : [null],
							hasInstructions:clip.metadata.hasInstructions
						});
					} else {
						const skipRanges = clip?.segments?.filter(segment => segment.isSkipSegment) || [];
						//return segment originalSTart and originalEnd
						const skipRangesWithOriginalStartAndEnd = skipRanges.map(segment => ({
							startTime: segment.originalStart,
							endTime: segment.originalEnd
						}));
					//	console.log('skipRangesWithOriginalStartAndEnd',skipRangesWithOriginalStartAndEnd)
						groups.push({
							type: 'webcam-recording',
							id: clip.id,
							clips: [clip],
							isProcessing: clip.isLoadingTranscript,
							transcript: clip.metadata?.transcript,
							linkedClipId:clip.metadata.linkedClipId,
							clipTrimStart:clip.metadata.trimStart,
							clipTrimEnd:clip.metadata.trimEnd,
							skipRanges: skipRangesWithOriginalStartAndEnd
						});
					}
				} else if (!clip.parentWebcamClip) { // Audio clip with no parent webcam
					if (!currentStandardGroup) {
						currentStandardGroup = {
							type: 'standard',
							id: randomID(),
							clips: []
						};
					}
					currentStandardGroup.clips.push(clip);
				}
				
			});
			
			finalizeCurrentStandardGroup();
		}

		// Create nodes from groups
		const sceneContentArray = groups.map(group => {
			if (group.type === 'webcam-recording') {	
				const webcamClip = group.clips[0];
				const transcript = group.transcript;
				// const skipRanges = group.skipRanges;
				if (transcript?.chunks && transcript.chunks.length > 0) {
					const chunkNodes = transcript.chunks
					.map((chunk, idx) => {
						totalChunkCount++;
						return createRecordingChunkNode(
							webcamClip,
							scene.id,
							chunk,
							group.skipRanges
						);
					})
					.filter(chunk => chunk !== null);

					// If we have any chunks after filtering, create group with them
					if (chunkNodes.length > 0) {
						return editorSchema.nodes.transcriptGroup.createAndFill({
							groupId: group.id,
							groupType: 'webcam-recording',
							isProcessing: group.isProcessing,
							skipRanges: group.skipRanges,
							linkedClipId: group.linkedClipId,
							clipTrimStart:group.clipTrimStart,
							clipTrimEnd:group.clipTrimEnd
						}, chunkNodes);
					}
				}

				// Handle both no chunks case and all chunks filtered out case
				return editorSchema.nodes.transcriptGroup.createAndFill({
					groupId: group.id,
					groupType: 'webcam-recording',
					isProcessing: group.isProcessing,
					skippedItems: transcript?.skippedItems || []
				}, []);
	
			} else {

				// standard or variable-webcam groups
				const webcamClip = group.type === 'variable-webcam' ? 
					webcamClips.find(clip => clip.id === group.id) : null;

				const chunks = group.hasInstructions ? 
        // If has instructions, split into multiple chunks
        (webcamClip?.metadata?.instructions || DEFAULT_INSTRUCTIONS).split('\n').map((instruction, index) => {
            totalChunkCount++;
            const paragraphNode = instruction.trim() ?  // Only create text node if instruction is not empty
                editorSchema.nodes.paragraph.create({}, [editorSchema.text(instruction)]) :
                editorSchema.nodes.paragraph.create({});
            
            return editorSchema.nodes.transcriptChunk.createAndFill({
                clipId: randomID(),
                sceneId: scene.id,
                requiresUpdate: false,
                transcriptChunkIndex: index,
                transcriptChunkCount: (webcamClip?.metadata?.instructions || DEFAULT_INSTRUCTIONS).split('\n').length
            }, [paragraphNode]);
        })
    	: // If no instructions, handle as before
    	group.clips.map(clip => {
					totalChunkCount++;
					
					// Handle empty chunk case
					if (!clip) {
					const emptyParagraphNode = editorSchema.nodes.paragraph.create({});
						return editorSchema.nodes.transcriptChunk.createAndFill({
							clipId: randomID(),
							sceneId: scene.id,
							requiresUpdate: false,
							transcriptChunkIndex: 0,
							transcriptChunkCount: 1
						}, [emptyParagraphNode]);
					}

					let paragraphNode;
					if (clip.metadata.text.trim() !== '' && clip.metadata.text.trim() !== '#') {
						paragraphNode = editorSchema.nodes.paragraph.create({}, 
						[editorSchema.text(clip.metadata.text)]);
					} else {
						paragraphNode = editorSchema.nodes.paragraph.create({});
					}

					return editorSchema.nodes.transcriptChunk.createAndFill({
						clipId: clip.id,
						sceneId: scene.id,
						requiresUpdate: clip.requiresUpdate,
						transcriptChunkIndex: 0,
						transcriptChunkCount: group.clips.length
					}, [paragraphNode]);
				});

				return editorSchema.nodes.transcriptGroup.createAndFill({
					groupId: group.id,
					groupType: group.type,
					skippedItems: [],
					hasInstructions:group.hasInstructions || false
				}, chunks);
			}
		});
		
		contentArray.push(...sceneContentArray);
	});


	 // Make sure there is always a standard group at the end of the doc
    const lastGroup = contentArray[contentArray.length - 1];
    if (!lastGroup || 
        lastGroup.type.name !== 'transcriptGroup' || 
        lastGroup.attrs.groupType !== 'standard') {
        
        // Create an empty standard group at the end
        const emptyParagraphNode = editorSchema.nodes.paragraph.create({});
        const emptyChunkNode = editorSchema.nodes.transcriptChunk.createAndFill({
            clipId: randomID(),
            sceneId: scenes[scenes.length - 1]?.id || '',
            requiresUpdate: false,
            transcriptChunkIndex: 0,
            transcriptChunkCount: 1
        }, [emptyParagraphNode]);

        const standardGroup = editorSchema.nodes.transcriptGroup.createAndFill({
            groupId: randomID(),
            groupType: 'standard',
            skippedItems: []
        }, [emptyChunkNode]);

        contentArray.push(standardGroup);
    }


	return editorSchema.nodes.doc.createAndFill({}, contentArray);
}

//For partially trimmed words we include in both the skip and the transcript 
//TODO skip ranges split by transcript chunk

function createRecordingChunkNode(clip, sceneId, chunk, skipRanges = []) {
	const clipId = clip.id
	const items = chunk.items

	const chunkStartTime = chunk.startTime;
	const chunkEndTime = chunk.endTime;
	// If chunk is completely outside trim bounds, return null
	if (chunkStartTime >= clip.metadata.trimEnd || 
			chunkEndTime <= clip.metadata.trimStart) {
			return null;
	}

	if (items.length > 0) {
		// Helper function to check if a time range overlaps with any skip range
		const isInSkipRange = (startTime, endTime) => {
			return skipRanges.some(range => 
				startTime < range.endTime && range.startTime < endTime
			);
		};

		// Filter out items that are in skip ranges and create item nodes
		const itemNodes = items.reduce((acc, item) => {
			// Skip items that are in skip ranges or outside trim bounds
			if (isInSkipRange(item.startTime, item.endTime) ||
				item.endTime < clip.metadata.trimStart || 
				item.startTime > clip.metadata.trimEnd) {
				return acc;
			}

			let itemNode;
			if(item.isPause){
				itemNode = editorSchema.nodes.webcamTranscriptItem.create({
					startTime: item.startTime,
					endTime: item.endTime,
					originalIndex: item.originalIndex,
					isPause:true
				},
					[editorSchema.text('·')]
			);
			}else{
				itemNode = editorSchema.nodes.webcamTranscriptItem.create(
					{
						startTime: item.startTime,
						endTime: item.endTime,
						originalIndex: item.originalIndex
					},
					[editorSchema.text(item.word)]
				);
			}

			acc.push(itemNode);
			return acc;
		}, []);

		if(itemNodes.length==0){
			return null
		}

		return editorSchema.nodes.webcamRecordingChunk.create({
			clipId,
			sceneId,
			requiresUpdate: false,
			startTime:chunkStartTime,
			endTime:chunkEndTime,
		}, itemNodes);
	} else {
		return editorSchema.nodes.webcamRecordingChunk.create({
			clipId,
			sceneId,
			requiresUpdate: false,
		});
	}
}

import {randomID} from '../../utils/randomID'

export function calculateWebcamRecordingSegments(recordingDuration, skipRanges = []) {

  if (!skipRanges.length) {
    // If no skip ranges, return single segment for whole duration
    return [{
      id: randomID(),
      originalDuration: recordingDuration,
      originalStart: 0,
      originalEnd: recordingDuration,
      isQuiet: false,
      playbackRate: 1,
      newStart: 0,
      newEnd: recordingDuration,
      newDuration: recordingDuration,

    }];
  }

  const segments = [];
  let currentTime = 0;

  // Sort skip ranges by start time to ensure proper ordering
  const sortedSkipRanges = [...skipRanges].sort((a, b) => a.startTime - b.startTime);

  sortedSkipRanges.forEach((skipRange) => {
    // Add regular segment before skip if there's a gap
    if (currentTime < skipRange.startTime) {
      segments.push({
        id: randomID(),
        originalDuration: skipRange.startTime - currentTime,
        originalStart: currentTime,
        originalEnd: skipRange.startTime,
        isQuiet: false,
        playbackRate: 1,
        newStart: currentTime,
        newEnd: skipRange.startTime,
        newDuration: skipRange.startTime - currentTime,
  
      });
    }

    // Add skip segment
    segments.push({
      id: randomID(),
      originalDuration: skipRange.endTime - skipRange.startTime,
      originalStart: skipRange.startTime,
      originalEnd: skipRange.endTime,
      isQuiet: false,
      playbackRate: null,
      newStart: skipRange.startTime,
      newEnd: skipRange.endTime,
      newDuration: skipRange.endTime - skipRange.startTime,

      isSkipSegment: true,
      isExpanded: false
    });

    currentTime = skipRange.endTime;
  });

  // Add final regular segment if there's remaining duration
  if (currentTime < recordingDuration) {
    segments.push({
      id: randomID(),
      originalDuration: recordingDuration - currentTime,
      originalStart: currentTime,
      originalEnd: recordingDuration,
      isQuiet: false,
      playbackRate: 1,
      newStart: currentTime,
      newEnd: recordingDuration,
      newDuration: recordingDuration - currentTime,

    });
  }

  return segments;
}
import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';

const DPSlider = ({
  min,
  max,
  value,
  setValue,
  setIsDragResizingNumberInput,
  label,
  disabled,
  percentage,
  centerZero,
  whiteBalance,
  suggestedValue,
  step = 0.01,
  width = 222,
  hideNonSuggestedLabels
}) => {  
  const [isDragging, setIsDragging] = useState(false);

  const getDecimalPlaces = (step) => {
    if (Math.floor(step) === step) return 0;
    return step.toString().split(".")[1].length || 0;
  }

  const roundToStep = (value, step) => {
    const decimalPlaces = getDecimalPlaces(step);
    const inverse = 1 / step;
    return Math.round(value * inverse) / inverse;
  }

  const handleSliderChange = (newValue) => {
    const roundedValue = roundToStep(newValue, step);
    setValue(roundedValue);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleWindowBlur = () => {
      if (isDragging) {
        setIsDragging(false);
        setIsDragResizingNumberInput(false);
      }
    };
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [isDragging, setIsDragResizingNumberInput]);

  const decimalPlaces = getDecimalPlaces(step);
  let formattedValue = value.toFixed(decimalPlaces);
  
  if (percentage && centerZero) {
    if (value > 0) {
      formattedValue = `+${Math.round(value * 100)}%`;
    } else if (value < 0) {
      formattedValue = `${Math.round(value * 100)}%`;
    } else {
      formattedValue = '0%';
    }
  }

  if (percentage) {
    if (value > 0) {
      formattedValue = `${Math.round(value * 100)}%`;    
    } else {
      formattedValue = '0%';
    }
  }

  if(suggestedValue !== null && suggestedValue !== undefined && value === suggestedValue){
    formattedValue = 'Auto'
  }

  // Calculate normalized position and bar styles for center zero
  let normalizedPosition;
  let mainBarStyle;

  if (centerZero) {
    // Calculate the normalized position (0 to 1) based on the full range
    normalizedPosition = (value - min) / (max - min);
    
    const centerPosition = width / 2;
    const zeroPosition = (-min / (max - min)) * width;
    
    if (value > 0) {
      mainBarStyle = {
        left: `${zeroPosition}px`,
        width: `${(normalizedPosition * width) - zeroPosition}px`
      };
    } else if (value < 0) {
      mainBarStyle = {
        left: `${normalizedPosition * width}px`,
        width: `${zeroPosition - (normalizedPosition * width)}px`
      };
    } else {
      mainBarStyle = {
        left: `${zeroPosition}px`,
        width: '0px'
      };
    }
  } else {
    // Non-center zero calculation
    normalizedPosition = (value - min) / (max - min);
    mainBarStyle = {
      left: '0px',
      width: `${normalizedPosition * width}px`
    };
  }

  const showSuggestedBar = suggestedValue !== null && suggestedValue !== undefined && value !== suggestedValue && suggestedValue > 0
  const suggestedBarLeft = suggestedValue !== undefined ? (suggestedValue - min) / (max - min) : 0

  let isSuggestedValue = false
  if(suggestedValue !== null && suggestedValue !== undefined && value === suggestedValue){
    isSuggestedValue = true;
  }


  let forceHideLabel = false
  if(hideNonSuggestedLabels && !isSuggestedValue){
    forceHideLabel = true
  }


  return (
    <div 
      data-dragging-state={isDragging ? 'dragging' : 'notDragging'} 
      data-white-balance={whiteBalance ? 'true' : 'false'} 
      style={{width: `${width}px`}} 
      className="dpSlider"
    >
      <div className="dpSlider-label dpSlider-label--title">
        {label}
      </div>      
      
      <div 
        data-value-state={value < 0 ? 'negative' : 'positive'} 
        style={{left: `${normalizedPosition * 100}%`}} 
        className="dpSlider-valueBar" 
      />
          
      <div 
        data-value-state={value < 0 ? 'negative' : 'positive'} 
        style={mainBarStyle} 
        className="dpSlider-mainBar" 
      />      


      
      <div className="dpSlider-sliderContainer">
        <ReactSlider
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={handleSliderChange}
          onBeforeChange={handleDragStart}
          onAfterChange={handleDragEnd}
          disabled={disabled}
          className="dpSlider-slider"   
          trackClassName="dpSlider-slider-track"
          thumbClassName="dpSlider-slider-thumb"
          renderThumb={(props) => {
            const { key, ...restProps } = props;
            return <div key={key} {...restProps} />;
          }}
        />

        {showSuggestedBar &&        
          <div style={{left: `${suggestedBarLeft * 100}%`}} className='dpSlider-suggestedBar' />          
        }

      </div>
      
      {!forceHideLabel &&
        <div className="dpSlider-label dpSlider-label--value">
          {formattedValue}
        </div>
      }
    </div>
  );
};

export default DPSlider;
import React, { useState, useEffect, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import { updateDimensions } from './utils/updateDimensions';

const createHandles = ({ 
  x, y, width, height, handleThickness,
  topEdge, bottomEdge, leftEdge, rightEdge,
  topLeftCorner, topRightCorner, bottomLeftCorner, bottomRightCorner
}) => {

  const halfThickness = handleThickness / 2;
  const handles = [];
  if (topEdge) {
    handles.push({ 
      left: x,
      top: y - halfThickness,
      width: width, 
      height: handleThickness,
      cursor: 'ns-resize',
      className: 'horizontal',
      handleType: 'top',
    });
  }
  if (bottomEdge) {
    handles.push({ 
      left: x, 
      top: y + height - halfThickness, 
      width: width, 
      height: handleThickness,
      cursor: 'ns-resize',
      className: 'horizontal',
      handleType: 'bottom',
    });
  }
  if (leftEdge) {
    handles.push({ 
      left: x - halfThickness, 
      top: y, 
      width: handleThickness, 
      height: height,
      cursor: 'ew-resize',
      className: 'vertical',
      handleType: 'left',
    });
  }
  if (rightEdge) {
    handles.push({ 
      left: x + width - halfThickness, 
      top: y, 
      width: handleThickness, 
      height: height,
      cursor: 'ew-resize',
      className: 'vertical',
      handleType: 'right',
    });
  }
  if (topLeftCorner) {
    handles.push({ 
      left: x - halfThickness, 
      top: y - halfThickness, 
      width: handleThickness, 
      height: handleThickness,
      cursor: 'nwse-resize',
      className: 'corner',
      handleType: 'topLeft',
    });
  }
  if (topRightCorner) {
    handles.push({ 
      left: x + width - halfThickness, 
      top: y - halfThickness, 
      width: handleThickness, 
      height: handleThickness,
      cursor: 'nesw-resize',
      className: 'corner',
      handleType: 'topRight',
    });
  }
  if (bottomLeftCorner) {
    handles.push({ 
      left: x - halfThickness, 
      top: y + height - halfThickness, 
      width: handleThickness, 
      height: handleThickness,
      cursor: 'nesw-resize',
      className: 'corner',
      handleType: 'bottomLeft',
    });
  }
  if (bottomRightCorner) {
    handles.push({ 
      left: x + width - halfThickness, 
      top: y + height - halfThickness, 
      width: handleThickness, 
      height: handleThickness,
      cursor: 'nwse-resize',
      className: 'corner',
      handleType: 'bottomRight',
    });
  }
  return handles;
};

const HandleVisual = ({ left, top, width, height, cursor, className, isCorner, isText, cornerBoxSide, cornerBoxBorderWidth }) => (
  <div
    style={{
      position: 'absolute',
      left, top, width, height,
      cursor,
      pointerEvents: 'none',
    }}
    className={`editor-slideEditor-resizeableHandle editor-slideEditor-resizeableHandle--${className}`}
  >
    <div className='editor-slideEditor-resizeableHandle-inner' style={{ width: '100%', height: '100%', position: 'relative' }}>
      {(isCorner || isText) && (
        <div 
          style={{
            width: `${cornerBoxSide}px`, 
            height: `${cornerBoxSide}px`, 
            borderWidth: `${cornerBoxBorderWidth}px`,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }} 
          className={isText ? 'editor-slideEditor-resizeableHandle-inner-textBoxHandle' : 'editor-slideEditor-resizeableHandle-inner-cornerBox'} 
        />
      )}
    </div>
  </div>
);

const SlideEditorResizableHandles = (props) => {

  const {isResizing,isInLayoutGroup,x, y, width, height, scalar, updateSlideElementField, clipId,element, fixedAspect, isText, handleResizeStart, handleResizeEnd, invertScalar, uiColorMode}=props
  

  const [initialDimensions, setInitialDimensions] = useState(null);

  const handleResizeStartWrapper = () => {
    setInitialDimensions({ x, y, width, height });
    handleResizeStart(element.id);
  };

  const handleResizeEndWrapper = () => {
    setInitialDimensions(null);
    handleResizeEnd();
  };

  const handleThickness = isText ? 20 * invertScalar : 15 * invertScalar;
  let cornerBoxSide = 7 * invertScalar;
  let cornerBoxBorderWidth = 1 * invertScalar;

  if(uiColorMode === 'yellow'){
    cornerBoxSide = 4 * invertScalar
  }
  // Define which handles are visible
  const topEdge = !isText;
  const bottomEdge = !isText;
  const leftEdge = true;
  const rightEdge = true;
  const topLeftCorner = !isText;
  const topRightCorner = !isText;
  const bottomLeftCorner = !isText;
  const bottomRightCorner = !isText;


  const handleOnDrag = (handleType, e, d) => {
    if (!initialDimensions) return;

    const halfThickness = handleThickness / 2;
    let newDimensions;
    const { x: initialX, y: initialY, width: initialWidth, height: initialHeight } = initialDimensions;

    switch (handleType) {
      case 'top':
        newDimensions = { y: d.y + halfThickness, height: initialHeight - (d.y + halfThickness - initialY) };
        break;
      case 'bottom':
        newDimensions = { height: d.y + halfThickness - initialY };
        break;
      case 'left':
        newDimensions = { x: d.x + halfThickness, width: initialWidth - (d.x + halfThickness - initialX) };
        break;
      case 'right':
        newDimensions = { width: d.x + halfThickness - initialX };
        break;
      case 'topLeft':
        newDimensions = { 
          x: d.x + halfThickness, 
          y: d.y + halfThickness,
          width: initialWidth - (d.x + halfThickness - initialX),
          height: initialHeight - (d.y + halfThickness - initialY)
        };
        break;
      case 'topRight':
        newDimensions = { 
          y: d.y + halfThickness,
          width: d.x + halfThickness - initialX,
          height: initialHeight - (d.y + halfThickness - initialY)
        };
        break;
      case 'bottomLeft':
        newDimensions = { 
          x: d.x + halfThickness,
          width: initialWidth - (d.x + halfThickness - initialX),
          height: d.y + halfThickness - initialY
        };
        break;
      case 'bottomRight':
        newDimensions = { 
          width: d.x + halfThickness - initialX,
          height: d.y + halfThickness - initialY
        };
        break;
    }

    updateDimensions(
      initialDimensions,
      newDimensions,
      handleType,
      //handleType.includes('corner') ? 'corner' : 'edge',
      element,
      clipId,
      element.id,
      updateSlideElementField,
      fixedAspect,
      isInLayoutGroup
    );
  };

    const handles = createHandles({
      x, y, width, height,
      handleThickness,
      topEdge: !isText,
      bottomEdge: !isText,
      leftEdge: true,
      rightEdge: true,
      topLeftCorner: !isText,
      topRightCorner: !isText,
      bottomLeftCorner: !isText,
      bottomRightCorner: !isText
    });

  return (
    <>
      {handles.map((handle, index) => (
        <React.Fragment key={`handle-${handle.handleType}-${index}`}>
          <Rnd
            key={`rnd-${handle.handleType}-${index}`}
            position={isResizing?null : {x: handle.left, y: handle.top }}
            size={isResizing?null : { width: handle.width, height: handle.height }}
            onDragStart={() => handleResizeStartWrapper()}
            onDragStop={handleResizeEndWrapper}
            onDrag={(e, d) => handleOnDrag(handle.handleType, e, d)}
            enableResizing={false}
            scale={scalar}
            className='editor-slideEditor-resizeableHandleRnd'
            style={{ cursor: isText ? 'ew-resize' : handle.cursor }}
          />
          <HandleVisual 
            key={`visual-${handle.handleType}-${index}`}
            {...handle}
            isCorner={handle.className === 'corner'}
            isText={isText}
            cornerBoxSide={cornerBoxSide}
            cornerBoxBorderWidth={cornerBoxBorderWidth}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default SlideEditorResizableHandles;
import { combineEqualAdjacentPositions } from './screenRecordingDisplayUtils'
import { getOrgScreenVideoStyle } from '../../brands/getOrgScreenVideoStyle'
import { getMasterRecordingCursorData } from './getMasterRecordingCursorData'
import { calculateDefaultWebcamLayouts } from '../../../timeline/utils/recordingSessionWebcamLayoutUtils'

/**
 * Calculates all display-related settings for a screen recording including
 * display mode, window mode availability, app detection, device frame, and position
 */

//TODO webcam layouts
export async function calculateScreenRecordingDisplaySettings({ recordingChunks, trimStart, trimEnd, recordingDuration, currentDisplayMode, currentWebcamLayouts, captureId }) {
  const defaultPosition = {x:0, y:0, width:1, height:1}

  // If recording chunks is not defined or empty, return legacy mode
  if (!recordingChunks || recordingChunks.length === 0) {
    return {
      displayMode: null,
      allowWindowMode: false,
      screenVideoApp: '',
      deviceFrame: null,
      displayPosition: defaultPosition,
      webcamLayouts: null
    }
  }

  // Check chunks in trim section
  const chunksInTrim = recordingChunks.filter(chunk => {
    const chunkEnd = chunk.startTime + chunk.duration
    // Convert to numbers with fixed precision for comparison
    const startBeforeTrimEnd = Number(chunk.startTime.toFixed(2)) < Number(trimEnd.toFixed(2))
    const endAfterTrimStart = Number(chunkEnd.toFixed(2)) > Number(trimStart.toFixed(2))
    return startBeforeTrimEnd && endAfterTrimStart
  })

  // Calculate display position
  let displayPosition = defaultPosition
  if (chunksInTrim.length > 0) {
    // Find the last chunk that started before or at the trim start time
    const currentChunk = chunksInTrim.reduce((latest, chunk) => {
      if (chunk.startTime <= trimStart && (!latest || chunk.startTime > latest.startTime)) {
        return chunk
      }
      return latest
    }, null)
    
    if (currentChunk?.positions) {
      // Find the last position with a startTime less than or equal to the trim start time
      const currentPosition = currentChunk.positions.reduce((latest, position) => {
        if (position.startTime <= trimStart && (!latest || position.startTime > latest.startTime)) {
          return position
        }
        return latest
      }, null)
      
      if (currentPosition) {
        displayPosition = currentPosition
      }
    }
  }

  // Calculate display mode options
  const positionsFromChunks = chunksInTrim.map(chunk => chunk.positions).flat()
  const combinedPositions = combineEqualAdjacentPositions(positionsFromChunks, recordingDuration)

  const positionsInTrim = combinedPositions.filter(position => {
    const startBeforeTrimEnd = Number(position.startTime.toFixed(2)) < Number(trimEnd.toFixed(2))
    const endAfterTrimStart = Number(position.endTime.toFixed(2)) > Number(trimStart.toFixed(2))
    return startBeforeTrimEnd && endAfterTrimStart
  })

  // Determine if window mode is allowed (single position)
  const allowWindowMode = positionsInTrim.length === 1

  // Check if all chunks are Chrome
  const allChunksAreChrome = chunksInTrim.length > 0 && 
    chunksInTrim.every(chunk => chunk.appName === 'Google Chrome')

  const allChunksAreFigma = chunksInTrim.length > 0 && 
    chunksInTrim.every(chunk => chunk.appName === 'Figma')

  // Determine screen video app
  const screenVideoApp = (allChunksAreChrome && allowWindowMode) ? 'chrome' : ((allChunksAreFigma && allowWindowMode) ? 'figma' : '')

  // Calculate display mode
  let displayMode = currentDisplayMode
  let deviceFrame = null

  if (allowWindowMode) {
    if (!currentDisplayMode) {
      displayMode = 'window'
      const orgScreenVideoStyle = getOrgScreenVideoStyle()
      if (orgScreenVideoStyle?.defaultColorFrame && (screenVideoApp === 'chrome' || screenVideoApp === 'figma')) {
        deviceFrame = 'color'
      }
    }
  } else {
    displayMode = 'desktop'
  }

  let webcamLayouts = currentWebcamLayouts
  const cursorData = await getMasterRecordingCursorData(captureId);
  const windowBox = displayMode === 'window' ? displayPosition : null
  if (!currentWebcamLayouts){
    webcamLayouts = await calculateDefaultWebcamLayouts(recordingDuration, cursorData, windowBox);
  }

  return {
    displayMode,
    allowWindowMode,
    screenVideoApp,
    deviceFrame,
    displayPosition,
    webcamLayouts
  }
}

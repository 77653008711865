import React from 'react'
import Icon from '../../misc/Icon'
import TextSlideBar from './TextSlideBar'
import ScreenVideoBar from './ScreenVideoBar'
import ImageBar from './ImageBar'
import ZoomBar from './ZoomBar'
import SlideBar from './SlideBar'
import SlideAlignBar from './SlideAlignBar'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

class EditorTopBar extends React.PureComponent{  

  render(){         		
  	const {activePanelClip, activeZoomClip,selectedSlideItems, aiZoomStatus, cursorZoomStatus} = this.props
  	//console.log(activePanelClip)
  	const showTextSlidePanel = activePanelClip && activePanelClip.type=='textSlide'

		const showSlidePanel = activePanelClip && activePanelClip.type=='slide'

  	const showFixedPanel = activePanelClip && (activePanelClip.type=='zoom' || activePanelClip.type=='textSlide' || activePanelClip.type=='video' || activePanelClip.type=='chart')

  	const showVideoPanel = activePanelClip && activePanelClip.type=='video' 

		const showAutoZoomPanel = activePanelClip && activePanelClip.isScreenRecording

		// console.log('activePanelClip')
		// console.log(activePanelClip)

  	const showChartPanel = activePanelClip && activePanelClip.type=='chart'
  	
  	const showZoomPanel = activePanelClip && activePanelClip.type=='zoom'

  	const showImageClipPanel = activePanelClip && activePanelClip.type=='image'

  	let disableTopbar = activePanelClip && (activePanelClip.isUploadingVideo || activePanelClip.isUploadingImage)

  	const hasSlideSelection = selectedSlideItems.length>0

  	// const aiZoomStatus = // 'exists', 'empty', 'pending'
  	// const cursorZoomStatus = // 'exists', 'empty', 'pending'

  	let aiZoomTooltip = 'Auto-add zooms based on visual analysis of the recording.'
  	if(aiZoomStatus === 'pending'){
  		aiZoomTooltip = 'Calculating the AI auto-zoom for this clip.'
  	}
  	if(aiZoomStatus === 'empty'){
  		aiZoomTooltip = 'No appropriate AI-based zooms found for this clip.'
  	}

  	let cursorZoomTooltip = 'Auto-add zooms based on cursor behaviour of the recording. Expect more zooms.'
  	if(cursorZoomStatus === 'pending'){
  		cursorZoomTooltip = 'Calculating the cursor auto-zoom for this clip.'
  	}
  	if(cursorZoomStatus === 'empty'){
  		cursorZoomTooltip = 'No appropriate cursor-based zooms found for this clip.'
  	}



			return (  
			<>          	 
					{/*}
					{hasSlideSelection && activePanelClip&& activePanelClip.type=='slide' &&
						<SlideAlignBar
							slideClip={activePanelClip}						
							handleAlignSlideElements={this.props.alignSlideItems}				
						/>
					}

					{/*}
					{showSlidePanel && 
						<SlideBar 
							slideClip={activePanelClip}
							addSlideElement={this.props.addSlideElement}
							selectedSlideElement={this.props.selectedSlideElement}
							updateSlideElementMetadata={this.props.updateSlideElementMetadata}
							updateClipMetadata={this.props.updateClipMetadata}
							//handleNewImageFileUpload={this.props.handleNewImageFileUpload}
							insertImageFromRecent={this.props.insertImageFromRecent}
							projectBackgroundId={this.props.projectBackgroundId}
							updateClipMetadata={this.props.updateClipMetadata}
							updateSlideBackgroundColor={this.props.updateSlideBackgroundColor}
							updateSlideAlignment={this.props.updateSlideAlignment}
							handleSlideImageFileUpload={this.props.handleSlideImageFileUpload}
						/>
					}




					
					{showTextSlidePanel && 
						<TextSlideBar 
							textSlideClip={activePanelClip}
							updateClipAnimationSettings={this.props.updateClipAnimationSettings}
							updateClipMetadata={this.props.updateClipMetadata}
							updateSlideClipTextStyle={this.props.updateSlideClipTextStyle}
							deleteClip={this.props.deleteClip}
							previewAnimation={this.props.previewAnimation}
							previewClip={this.props.previewClip}
							previewType={this.props.previewType}
							resetPreview={this.props.resetPreview}
							projectBackgroundId={this.props.projectBackgroundId}
							setRandomTextSlideContent={this.props.setRandomTextSlideContent}
							updateTextSlideTextColor={this.props.updateTextSlideTextColor}
							updateTextSlideBackgroundColor={this.props.updateTextSlideBackgroundColor}
							defaultMotionStyle={this.props.defaultMotionStyle}
						/>
					}

					{/*}
					{showChartPanel && 
						<ChartsPanel 
							clip={activePanelClip}
							deleteClip={this.props.deleteClip}
							projectBackgroundId={this.props.projectBackgroundId}
							updateChartClip={this.props.updateChartClip}
							updateChartBackgroundColor={this.props.updateChartBackgroundColor}
							defaultMotionStyle={this.props.defaultMotionStyle}
						/>
					}
					*/}

			{showVideoPanel && !hasSlideSelection &&
				<div className='editor-topBar editor-topBar--addZoom'>
							<div className='editor-topBar-leftContainer' />
							<div className='editor-topBar-centerContainer'>
				<DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button className='dpButton dpButton--mixed dpButton--zoomBarMotion'>
              <div className='dpButton-iconContainer'>
                <Icon name='magnifyingGlass' />
              </div>
              <div className='dpButton-label'>
                Add Zoom                
              </div>
              <div className='dpButton-keyboardShortcut'>
              	⌘O
              </div>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content             
              align="middle"
              alignOffset={0}
              className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
            >                    
	            <DropdownMenu.Item onSelect={this.props.addZoom}>
	            	<div className='dropdownMenu-item-iconContainer'>
	            		<Icon name='magnifyingGlass' />
	          		</div>
	              Add Manual Zoom          	              
	            </DropdownMenu.Item>

              <DropdownMenu.Item 
              	onSelect={() => this.props.createAutoZooms('modal')}
              	className={!aiZoomStatus ? 'dropdownMenu-item--disabled' : ''}
              >
                <div className='dropdownMenu-item-iconContainer'>
	            		<Icon name='wandMedium' />
	          		</div>
                Add AI Zoom
              </DropdownMenu.Item>              
              <DropdownMenu.Item 
              	onSelect={() => this.props.createAutoZooms('click')}
              	className={!cursorZoomStatus ? 'dropdownMenu-item--disabled' : ''}
              >
              	<div className='dropdownMenu-item-iconContainer'>
	            		<Icon name='drawingCursor' />
	          		</div>
                Auto Cursor Zoom
               </DropdownMenu.Item>               
            </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>  
          </div>
          <div className='editor-topBar-rightContainer' />
							<div className='editor-topBar-rightEdgeContainer' />
						</div>
					}

					
					{/*}

					{showVideoPanel && !hasSlideSelection &&
						<div className='editor-topBar editor-topBar--addZoom'>
							<div className='editor-topBar-leftContainer' />
							<div className='editor-topBar-centerContainer'>
								<button onClick={this.props.addZoom} className='dpButton dpButton--mixed dpButton--addZoomBtn'>
									<div className='dpButton-iconContainer'>
										<Icon name='magnifyingGlass' />
									</div>
									<div className='dpButton-label'>
										Add Manual Zoom
									</div>
									<div className='dpButton-keyboardShortcut'>
										<div className='dpButton-keyboardShortcut-command'>
											⌘
										</div>
										<div className='dpButton-keyboardShortcut-letter'>
											O
										</div>
									</div>
								</button>
								{showAutoZoomPanel && 
									<>
										
								<Tooltip.Root delayDuration={0}>
				          <Tooltip.Trigger asChild> 
				            <button 
				            	data-state={aiZoomStatus ? aiZoomStatus : 'pending'}
				            	onClick={() => this.props.createAutoZooms('modal')} className='dpButton dpButton--mixed dpButton--addZoomBtn'>
											<div className='dpButton-iconContainer'>
												<Icon name='wandMedium' />
											</div>
											<div className='dpButton-label'>
												Add AI Zoom
											</div>
										</button>
				          </Tooltip.Trigger>  
				          <Tooltip.Portal>                
				            <Tooltip.Content side="bottom" collisionPadding={10} className="forceDarkTheme tooltip tooltip--addSpecialZoom">
				              {aiZoomTooltip}
				            </Tooltip.Content>  
			            </Tooltip.Portal>        
				        </Tooltip.Root> 


				        <Tooltip.Root delayDuration={0}>
				          <Tooltip.Trigger asChild> 
				            <button 
											data-state={cursorZoomStatus ? cursorZoomStatus : 'pending'}
											onClick={() => this.props.createAutoZooms('click')} 
											className='dpButton dpButton--mixed dpButton--addZoomBtn'
										>
											<div className='dpButton-iconContainer'>
												<Icon name='drawingCursor' />
											</div>
											<div className='dpButton-label'>
												Auto Cursor Zoom
											</div>
										</button>				          
				          </Tooltip.Trigger>                  
				          <Tooltip.Portal>
				            <Tooltip.Content side="bottom" collisionPadding={10} className="forceDarkTheme tooltip tooltip--addSpecialZoom">
				              {cursorZoomTooltip}
				            </Tooltip.Content>          
				           </Tooltip.Portal>
				        </Tooltip.Root> 


									</>
								}
							</div>
							<div className='editor-topBar-rightContainer' />
							<div className='editor-topBar-rightEdgeContainer' />
						</div>
					*/}

					{/*}
					{showVideoPanel && 
						<ScreenVideoBar 
							activePanelClip={activePanelClip}
							deleteClip={this.props.deleteClip}
							//currentTime={this.props.currentTime}
							trimMode={this.props.trimMode}
							toggleTrimMode={this.props.toggleTrimMode}
							updateClipMetadata={this.props.updateClipMetadata}
							updateClipAnimationSettings={this.props.updateClipAnimationSettings}
							defaultMotionStyle={this.props.defaultMotionStyle}
							projectBackgroundId={this.props.projectBackgroundId}
							updateScreenClipBackgroundColor={this.props.updateScreenClipBackgroundColor}
							disabled={disableTopbar}
							changeVideoClipPlaybackRate={this.props.changeVideoClipPlaybackRate}
						/>
					}
					*/}

					{showZoomPanel && !hasSlideSelection &&
						<ZoomBar 
							clip={activePanelClip}						
							updateZoomValues={this.props.updateZoomValues}
							updateZoomBox={this.props.updateZoomBox}
							deleteClip={this.props.deleteClip}
							getClipForId={this.props.getClipForId}

						/>
					}

					{/*}
					{showImageClipPanel && 
						<ImageBar 
							activePanelClip={activePanelClip}
							projectBackgroundId={this.props.projectBackgroundId}		
							updateClipBackgroundColor={this.props.updateClipBackgroundColor}		
							updateClipMetadata={this.props.updateClipMetadata}
							updateClipAnimationSettings={this.props.updateClipAnimationSettings}
							defaultMotionStyle={this.props.defaultMotionStyle}
							disabled={disableTopbar}
						/>
					}
					*/}


					{/*}
					{activePanelClip && activePanelClip.type=='zoom' &&
						<ZoomPanel
							meshWidth={this.props.meshWidth}
							meshHeight={this.props.meshHeight}
							clip={activePanelClip}						
							updateZoomValues={this.props.updateZoomValues}
							updateZoomBox={this.props.updateZoomBox}
							deleteClip={this.props.deleteClip}
							getClipForId={this.props.getClipForId}
						/>
					}
					*/}

				</>
			)
  }
}

export default EditorTopBar

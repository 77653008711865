import {getOrgBrand} from '../../utils/brands/getOrgBrand'

export function getCustomWindowResizeOptions() {
  let options=null
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenRecordingWindowResizeOptions){
    options=orgBrand.screenRecordingWindowResizeOptions
  }
  return options
}

import { EditorState, TextSelection, Plugin, PluginKey} from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { baseKeymap } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
import { inputRules } from 'prosemirror-inputrules';

import schema  from './schema/transcriptSchema'
import TranscriptChunkNodeView from './nodeViews/TranscriptChunkNodeView'
import SceneHeaderNodeView from './nodeViews/SceneHeaderNodeView' 
import TranscriptGroupNodeView from './nodeViews/TranscriptGroupNodeView' 
import WebcamRecordingChunkNodeView from './nodeViews/WebcamRecordingChunkNodeView'


//plugins
import {cursorInsideNodePlugin} from './plugins/cursorInsideNodePlugin'
// import {showInlineControlsPlugin} from './plugins/showInlineControlsPlugin'
import webcamRecordingKeyPressPlugin from './plugins/webcamRecordingKeyPressPlugin'
import selectionPlugin from './plugins/selectionPlugin'
import {arrowPlugin} from './plugins/arrowPlugin'
import skipDecorationPlugin from './plugins/skipDecorationPlugin'
import {enforceStandardFinalGroupPlugin} from './plugins/enforceStandardFinalGroupPlugin'
import {selectionStylingPlugin} from './plugins/selectionStylingPlugin'
import {activeAudioChunkPlugin} from './plugins/activeAudioChunkPlugin'

import {enterKeyHandler} from './keyHandlers/enterKeyHandler'
import {backspaceHandler} from './keyHandlers/backspaceHandler'
import {tabKeyHandler} from './keyHandlers/tabKeyHandler'
import {handlePaste} from './utils/handlePaste'

import { createProsemirrorDocFromClips } from './utils/createProsemirrorDocFromClips'
import { updateClipIndexAndSceneCounts } from './utils/updateClipIndexAndSceneCounts'
import { insertSceneInputRule } from './inputRules/insertSceneInputRule'
import {insertWebcamGroupInputRule} from './inputRules/insertWebcamGroupInputRule'
import {randomID} from '../../utils/randomID'
import getParsedNodes from './utils/getParsedNodes'

import filter from 'lodash/filter'


const SET_DOC_FOR_DEV_PANEL=true //show the transcript doc in dev panel


function handleDragAndDropEvent(e){ //Disable node drag and drop
	e.preventDefault()
	return true
}

export class TranscriptProsemirrorManager {

	 constructor(seekToTimeInWebcamClip,setPMDocForDevMode, updateTimelineFromTranscript,playClipFromTranscript,recalculateAudioClip,addSceneFromTranscriptPanel,handleMouseOverTranscriptChunk,handleMouseLeaveTranscriptChunk,handleCursorInsideTranscriptChunk,handleSelectionRectsUpdated,getTitleForScene,setActiveTranscriptPanelAudioChunk,splitRecordingClipFromTranscript,getSkippedItemsForRange,maybeMergeRecordingClipFromTranscript) {
		this.setPMDocForDevMode = setPMDocForDevMode;
		this.updateTimelineFromTranscript=updateTimelineFromTranscript
		this.playClipFromTranscript=playClipFromTranscript
		this.recalculateAudioClip=recalculateAudioClip
		this.addSceneFromTranscriptPanel=addSceneFromTranscriptPanel
		this.handleMouseOverTranscriptChunk=handleMouseOverTranscriptChunk
		this.handleMouseLeaveTranscriptChunk=handleMouseLeaveTranscriptChunk
		this.handleCursorInsideTranscriptChunk=handleCursorInsideTranscriptChunk
		this.view = null;
		this.seekToTimeInWebcamClip=seekToTimeInWebcamClip
		this.handleSelectionRectsUpdated=handleSelectionRectsUpdated
		this.getTitleForScene=getTitleForScene
		this.setActiveTranscriptPanelAudioChunk=setActiveTranscriptPanelAudioChunk
		this.splitRecordingClipFromTranscript=splitRecordingClipFromTranscript
		this.getSkippedItemsForRange=getSkippedItemsForRange
		this.maybeMergeRecordingClipFromTranscript=maybeMergeRecordingClipFromTranscript
	}



init(container, clips, scenes) {
    const audioClips = filter(clips, {type: 'audio'})
    const webcamClips = filter(clips, {type: 'webcam'})
    
    const doc = createProsemirrorDocFromClips(webcamClips, audioClips, scenes);
    const handleMouseOverTranscriptChunk = this.handleMouseOverTranscriptChunk
    const handleMouseLeaveTranscriptChunk = this.handleMouseLeaveTranscriptChunk
    
    const editorConfig = {
        state: EditorState.create({
            doc: doc,
            plugins: this.createPlugins(),
        }),
        nodeViews: {
            transcriptChunk: (node, view, getPos) => 
                new TranscriptChunkNodeView(node, view, getPos, handleMouseOverTranscriptChunk, handleMouseLeaveTranscriptChunk),
            sceneHeader: (node, view, getPos) => 
                new SceneHeaderNodeView(node, view, getPos),
            transcriptGroup: (node, view, getPos) => 
                new TranscriptGroupNodeView(node, view, getPos),
            webcamRecordingChunk: (node, view, getPos) => 
                new WebcamRecordingChunkNodeView(node, view, getPos, handleMouseOverTranscriptChunk, handleMouseLeaveTranscriptChunk),
        },
				handleDOMEvents: { //we disable all of these events
					drop(view, event) { handleDragAndDropEvent(event)},
					dragstart(view, event) { handleDragAndDropEvent(event) },
					dragend(view, event) { handleDragAndDropEvent(event) },
					dragover(view, event) { handleDragAndDropEvent(event) }
				},
        handlePaste: handlePaste,
        dispatchTransaction: this.dispatchTransaction.bind(this)
    };

    if (this.view) {
        // Store the DOM node before destroying
        const editorDOM = this.view.dom.parentNode;
        // Clear the container's content
        while (editorDOM.firstChild) {
            editorDOM.removeChild(editorDOM.firstChild);
        }
        // Destroy the old view
        this.view.destroy();
        // Create new view with the same container
        this.view = new EditorView(editorDOM, editorConfig);
    } else {
        // Initial creation
        this.view = new EditorView(container, editorConfig);
    }

    if (SET_DOC_FOR_DEV_PANEL) {
        this.setPMDocForDevMode(this.view.state.doc);
    }
}

	getInitialChunks(){
		if(this.view){
			const parsedNodes = getParsedNodes(this.view,this.getSkippedItemsForRange)
			return parsedNodes
		}
	}

	getTrancriptChunkNodeDimensions(pos, view, id) {
		const domNode = view.nodeDOM(pos);
		let dimensions = { top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 };  
		if (domNode) {
			const rect = domNode.getBoundingClientRect();
			// Get the scrollable container
			const scrollContainer = document.querySelector('.editor-transcriptPanel-list');
			
			// Add the container's scroll position to convert to absolute positions
			const scrollTop = scrollContainer ? scrollContainer.scrollTop : 0;
			const scrollLeft = scrollContainer ? scrollContainer.scrollLeft : 0;
			
			dimensions = {
				top: rect.top + scrollTop,
				left: rect.left + scrollLeft,
				bottom: rect.bottom + scrollTop,
				right: rect.right + scrollLeft,
				width: rect.width,
				height: rect.height
			};
		}
		return dimensions;
	}

	getDimensionsForAudioClip(clipId){
		const { state } = this.view;
		let foundChunk = null;
		let foundPos = null;

		state.doc.descendants((node, pos) => {
			if (node.type.name === 'transcriptChunk' && node.attrs.clipId === clipId) {
				foundChunk = node;
				foundPos = pos;
				return false; // Stop traversal
			}
		});

		if (foundChunk && foundPos !== null) {
			const dimensions = this.getTrancriptChunkNodeDimensions(foundPos, this.view, foundChunk.attrs.clipId)
			return dimensions
		}
		return null;
	}

	manuallyAddAudioClip(){
		const clipId=randomID()
		const {state} = this.view
		let {tr} = state
		let lastSceneId = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader") {
				lastSceneId = node.attrs.sceneId;
			} 
		});
		const newNode = schema.nodes.transcriptChunk.createAndFill({
			clipId: clipId,
			sceneId: lastSceneId,
		});
		const insertPos = state.doc.content.size;
		tr = tr.insert(insertPos, newNode);
		const newNodePos = insertPos + 1; // +1 to move inside the node
		tr = tr.setSelection(TextSelection.create(tr.doc, newNodePos));
		this.view.dispatch(tr);
		this.view.focus()
	}

	focusSceneHeader(sceneId) { //actually lets focus the first transcript chunk
		const { state, dispatch } = this.view;
		let foundPos = null;
		let foundNode = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "sceneHeader" && node.attrs.sceneId === sceneId) {
				foundPos = pos;
				foundNode = node;
				return false; // Stop the search
			}
		});
		
		if (foundPos !== null && foundNode) {
			// this puts cursor in the header this does the text in the header
			const start = foundPos + 2;
			//const end = start + foundNode.nodeSize - 1;
			const end = start
			const transaction = state.tr.setSelection(TextSelection.create(state.doc, start, end));
			dispatch(transaction);
			this.view.focus();
		} else {
			console.log(`Scene header with sceneId ${sceneId} not found.`);
		}
	}

	putCursorAtTopOfNewGroup(clipId){
		console.log('putCursorAtTopOfNewGroup',clipId)
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptGroupNode = null;
		let transcriptGroupPos = null;
		state.doc.descendants((node, pos) => {
			if (node.type.name === "transcriptGroup" && node.attrs.groupId==clipId) {
				transcriptGroupNode = node;
				transcriptGroupPos = pos;
				return false;
			}
		});
		if(transcriptGroupNode){
			console.log('transcriptGroupNode',transcriptGroupNode)
			const selectionPos = transcriptGroupPos+2;
			//console.log('start',start)
			// const end = start +4
			//console.log('end',end)
			const transaction = state.tr.setSelection(TextSelection.create(state.doc, selectionPos, selectionPos));
			this.view.dispatch(transaction);
			this.view.focus();
		}
	}



	

	createPlugins() {
		const copyPlugin = new Plugin({
			props: {
				handleDOMEvents: {
					copy: (view, event) => this.handleCopy(view, event)
				}
			}
		});
		return [
			webcamRecordingKeyPressPlugin(this.maybeMergeRecordingClipFromTranscript),
			selectionPlugin,
			selectionStylingPlugin(this.handleSelectionRectsUpdated),
			arrowPlugin,
			skipDecorationPlugin,
			enforceStandardFinalGroupPlugin(),
			keymap({
				"Shift-Enter": this.regenClip,
				"Mod-Enter": this.playClip,
				"Enter": enterKeyHandler(this.splitRecordingClipFromTranscript),
				'Backspace': backspaceHandler(),
				"Tab":tabKeyHandler()
			}),
			keymap(baseKeymap),
			cursorInsideNodePlugin(this.handleCursorInsideTranscriptChunk),
			activeAudioChunkPlugin(this.setActiveTranscriptPanelAudioChunk),
			inputRules({
				rules: [insertSceneInputRule(this.addSceneFromTranscriptPanel),insertWebcamGroupInputRule],
			}),
			copyPlugin
		];
	}


handleCopy = (view, event) => {
  const { state } = view;
  const { from, to } = state.selection;
  const isWholeDocSelected = from === 0 && to === state.doc.content.size;
  const textParts = [];
  state.doc.nodesBetween(from, to, (node, pos) => {
    // Skip scene headers for whole document selection
    if (node.type.name === 'sceneHeader') {
      if (isWholeDocSelected) {
        return false;
      } else {
      	const sceneTitle=this.getTitleForScene(node.attrs.sceneId)
        textParts.push(sceneTitle);
      }
    }
    if (node.type.name === 'transcriptChunk') {
      textParts.push(node.textContent);
      return false;
    }
    if (node.type.name === 'webcamRecordingChunk') {
      const chunkWords = [];
      // Use nodesBetween to only get items within selection
      state.doc.nodesBetween(Math.max(from, pos), Math.min(to, pos + node.nodeSize), (childNode, childPos) => {
        if (childNode.type.name === 'webcamTranscriptItem' && childNode.textContent) {
          chunkWords.push(childNode.textContent);
        }
      });
      if (chunkWords.length > 0) {
        textParts.push(chunkWords.join(' '));
      }
      return false; 
    }
    return true;
  });
  
  if (textParts.length === 0) return false;
  const plainText = textParts.join('\n');
  event.preventDefault();
  event.clipboardData.setData('text/plain', plainText);
  return true;
}


	regenClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.recalculateAudioClip(clipId)
		} 
	}

	playClip=()=>{
		const { state } = this.view;
		const { selection } = state;
		const { $from, $to } = selection;
		let transcriptChunkNode = null;
		state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
		  if (node.type.name === 'transcriptChunk') {
			transcriptChunkNode = node;
			return false;  // Stops the iteration after the first voiceClip node is found
			}
		});
		if (transcriptChunkNode) {
			const clipId = transcriptChunkNode.attrs.clipId;
			this.playClipFromTranscript(clipId)
		} 
	}

	updateRequiresUpdateStatus(id,requiresUpdate){
		if(this.view){
			let tr = this.view.state.tr
			this.view.state.doc.descendants((node, pos) => {
			  if (node.type.name === "transcriptChunk" && node.attrs.clipId==id) {
					const updatedAttrs = { ...node.attrs, requiresUpdate:requiresUpdate };
					tr.setNodeMarkup(pos, null, updatedAttrs);
				}
			});
			tr.setMeta('addToHistory', false)
			let newState = this.view.state.apply(tr);
			this.view.updateState(newState);
			// this.editorState = this.editorState.apply(tr);
			if(SET_DOC_FOR_DEV_PANEL){
				this.setPMDocForDevMode(this.view.state.doc);
			}
		}
	}

	updateTranscriptFromTimeline(voiceoverClips, scenes) {
		const { state } = this.view;
		const { from, to } = state.selection;
		const currentSelectionPos = { from, to }
		const container = null;
		this.init(container, voiceoverClips, scenes);
		const newState = this.view.state;
		let newFrom = Math.min(currentSelectionPos.from, newState.doc.content.size);
		let newTo = Math.min(currentSelectionPos.to, newState.doc.content.size);
		const transaction = newState.tr.setSelection(TextSelection.create(newState.doc, newFrom, newTo));
		this.view.dispatch(transaction);
		//this.view.focus();
	}

  dispatchTransaction(transaction) {
    try {
      let newState = this.view.state.apply(transaction);
      this.view.updateState(newState);

      if (transaction.docChanged) {
        try{
          const updateTransaction = updateClipIndexAndSceneCounts(newState);
          
          if (updateTransaction.docChanged) {
            const docSize = newState.doc.content.size;
            // Check if any step in the transaction would create invalid positions
            const isValid = updateTransaction.steps.every(step => {
              const from = step.from || 0;
              const to = step.to || 0;
              return from <= docSize && to <= docSize;
            });
            if (isValid) {
              newState = this.view.state.apply(updateTransaction);
              this.view.updateState(newState);
            } else {
              console.warn('Skipped invalid update transaction');
            }
          }
          // Get the updated chunks and update the timeline
          const parsedNodes = getParsedNodes(this.view,this.getSkippedItemsForRange);

        	this.updateTimelineFromTranscript(parsedNodes);          
          if (SET_DOC_FOR_DEV_PANEL) {// Update dev panel if enabled
            this.setPMDocForDevMode(newState.doc);
          }
        } catch (updateError) {
          console.error('Error applying update transaction:', updateError);
          // Revert to the state after the initial transaction if the update fails
          this.view.updateState(newState);
        }
      }
    } catch (error) {
      console.error('Error applying transaction:', error);
      // Attempt to recover by creating a new valid selection
      try {
        const recovery = this.view.state.tr;
        const docSize = this.view.state.doc.content.size;
        const validPos = Math.min(transaction.selection.$from.pos, docSize);
        recovery.setSelection(TextSelection.create(this.view.state.doc, validPos));
        this.view.dispatch(recovery);
      } catch (recoveryError) {
        console.error('Failed to recover from transaction error:', recoveryError);
      }
    }
  }

  // Add a helper method to validate transaction positions
  validateTransaction(tr, docSize) {
    if (!tr || !tr.steps) return false; 
    return tr.steps.every(step => {
      const from = step.from || 0;
      const to = step.to || 0;
      return from >= 0 && to >= 0 && from <= docSize && to <= docSize;
    });
  }


	destroy() {
		if (this.view) {
			this.view.destroy();
			this.view = null;
		}
	}
}

import React from 'react';
import Icon from '../../misc/Icon'; // Adjust the path as needed
import VideoPreviewPlayerControlsSeekbar from './VideoPreviewPlayerControlsSeekbar'

function VideoPreviewPlayerControls({
  width,
  playbackId,
  playing,
  progress,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  duration
}) {

  return (    
    <div className='videoPreview-player-controls'>      
      <VideoPreviewPlayerControlsSeekbar 
        playbackId={playbackId}
        playing={playing}
        progress={progress}
        onSeek={onSeek}
        onSeekMouseDown={onSeekMouseDown}
        onSeekMouseUp={onSeekMouseUp}
        duration={duration}
      />    
    </div>
  );
}

export default VideoPreviewPlayerControls;

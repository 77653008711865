import {getOrgBrand} from '../brands/getOrgBrand'

export function getDefaultSoundEffectsSettings() {
	const defaultSettings = {
		mouse: {
			enabled: true,
			soundEffect: 'click',
			loudness: 'soft'
		},
		keyboard: {
			enabled: true,
			soundSet: 'setB',
			loudness: 'soft'
		},
		sceneTransition: {
			enabled: false,
			soundEffect: 'snap5',
			loudness: 'soft'
		},
		zoom: {
			enabled: false,
			loudness: 'soft'
		},
		screenClipTransition: {
			enabled: false,
			loudness: 'medium'
		}
	}

	const orgBrand = getOrgBrand()
	if (orgBrand && orgBrand.soundEffectsSettings) {
		return Object.keys(defaultSettings).reduce((acc, effectType) => ({
			...acc,
			[effectType]: {
				...defaultSettings[effectType],
				enabled: orgBrand.soundEffectsSettings[effectType]?.enabled ?? defaultSettings[effectType].enabled
			}
		}), {})
	}

	return defaultSettings
}

// export function getBrandSoundEffectsSettings  () {
//   const orgBrand=getOrgBrand()

//   if(orgBrand && orgBrand.soundEffectsSettings){
//     return orgBrand.soundEffectsSettings
//   }
//  return null
// }

export const SOUND_EFFECTS_CONFIG = {
	// Global loudness options used across all effect types
	loudnessOptions: [
		{ value: 'extraSoft', label: 'Extra Soft' },
		{ value: 'soft', label: 'Soft' },
		{ value: 'medium', label: 'Medium' },
		{ value: 'loud', label: 'Loud' },
	],

	// Volume multipliers for each effect type and loudness level
	volumeLevels: {
		mouse: {
			extraSoft: 0.05,
			soft: 0.13,
			medium: 0.6,
			loud: 0.9
		},
		// Keyboard volume levels are now per sound set
		keyboard: {
			setA: {
				extraSoft: 0.08,
				soft: 0.14,
				medium: 0.4,
				loud: 0.75
			},
			setB: {
				extraSoft: 0.08,
				soft: 0.14,
				medium: 0.4,
				loud: 0.75
			},
			setC: {
				extraSoft: 0.08,
				soft: 0.14,
				medium: 0.4,
				loud: 0.75
			},
      setD: {
				extraSoft: 0.08,
				soft: 0.14,
				medium: 0.4,
				loud: 0.75
			}
		},
		sceneTransition: {
			extraSoft: 0.25,
			soft: 0.45,
			medium: 0.65,
			loud: 0.85
		},
    zoom: {
      extraSoft: 0.08,
      soft: 0.10,
      medium: 0.3,
      loud: 0.65
    }
	},
	timingOffset: {
		keyboard: {
			setA: {
				up: -0.15,
				down: -0.15,
			},
			setB: {
				up: 0,
				down: 0,
			},
			setC: {
				up: 0,
				down: 0,
			},
      setD: {
				up: 0,
				down: 0,
			}
		},
    mouse: {
			up: -0.15,
			down: -0.15,
		},
    zoom: {
			in: -1.9,
			out: -0.5,
      switch: -0,
		},
		sceneTransition: {
			in: -0.1,
		},
		
	},

	// Sound options for different effect types
	sounds: {
		// Keyboard sets are folders containing multiple sounds
		keyboardSets: [
			{ value: 'setA', label: 'Set A', folderPath: 'soundEffects/keyboard/setA', audioPath: 'soundEffects/keyboardPreviews/Set A - JapaneseBlackPreview.mp3'  },
			{ value: 'setB', label: 'Set B', folderPath: 'soundEffects/keyboard/setB', audioPath: 'soundEffects/keyboardPreviews/Set B - CrystalPurplePreview.mp3'  },
			{ value: 'setC', label: 'Set C', folderPath: 'soundEffects/keyboard/setC', audioPath: 'soundEffects/keyboardPreviews/Set C - OreoPreview.mp3'  },
			{ value: 'setD', label: 'Set D', folderPath: 'soundEffects/keyboard/setD', audioPath: 'soundEffects/keyboardPreviews/Set D - CreamKeyboardPreview.mp3'  }
		],

		// Scene changes are individual sound files
		sceneTransition: [
			{ value: 'snap5', label: 'Snap 1', audioPath: 'soundEffects/sceneTransition/Scene - Click 5.wav' },
			{ value: 'snap6', label: 'Snap 2', audioPath: 'soundEffects/sceneTransition/Scene - Click 6.wav' },
			{ value: 'tone', label: 'Tone', audioPath: 'soundEffects/sceneTransition/Scene - Tone.wav' },
			{ value: 'whoosh', label: 'Whoosh', audioPath: 'soundEffects/sceneTransition/Scene - Whoosh 1.wav' },
			{ value: 'bubble', label: 'Bubble', audioPath: 'soundEffects/sceneTransition/Scene - Bubble.mp3' }
		],		

		// Mouse clicks are individual sound files
		mouseClicks: [
			{ value: 'click', label: 'Click', audioPath: 'soundEffects/mouseClicks/click.wav' }
		]
	},

  // Get volume level for any effect type
	getVolumeForEffect: (effectType, settings) => {

   // console.log('getVolumeForEffect',effectType, settings)
		const { volumeLevels } = SOUND_EFFECTS_CONFIG
		const { loudness } = settings

		// Special handling for keyboard presses since they have per-set volumes
		if (effectType === 'keyboard') {
			const { soundSet } = settings
			return volumeLevels.keyboard[soundSet]?.[loudness] ?? 0.5
		}

		// For all other effect types
		if (volumeLevels[effectType]) {
			return volumeLevels[effectType][loudness] ?? 0.5
		}

		// Default fallback
		return volumeLevels.mouse[loudness] ?? 0.5
	},



	// // Default configuration for all effect types
	// defaults: {
	// 	mouse: {
	// 		enabled: true,
	// 		soundEffect: 'click',
	// 		loudness: 'soft'
	// 	},
	// 	keyboard: {
	// 		enabled: true,
	// 		soundSet: 'setB',
	// 		loudness: 'soft'
	// 	},
	// 	sceneTransition: {
	// 		enabled: false,
	// 		soundEffect: 'snap5',
	// 		loudness: 'soft'
	// 	},
	// 	zoom: {
	// 		enabled: false,
  //     loudness: 'soft'
	// 	},
	// 	screenClipTransition: {
	// 		enabled: false,
  //     loudness: 'medium'
	// 	}
//	}
}
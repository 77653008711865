import React from 'react'
import TranscriptPanelDragHandleGutter from './TranscriptPanelDragHandleGutter'
import TranscriptPanelSceneHeaderGutter from './TranscriptPanelSceneHeaderGutter'
import TranscriptPanelWebcamSkipMarkers from './TranscriptPanelWebcamSkipMarkers'
import TranscriptPanelSelectionRects from './TranscriptPanelSelectionRects'
import TranscriptPanelWebcamHeaders from './TranscriptPanelWebcamHeaders'
import TranscriptPanelVoiceGutter from './TranscriptPanelVoiceGutter'
import TranscriptPanelFixedHeader from './TranscriptPanelFixedHeader'
import TranscriptPanelTitleBtn from './TranscriptPanelTitleBtn'

import isEqual from 'lodash/isEqual'

class TranscriptPanel extends React.Component{ 
	constructor(props) {
		super(props);   
		this.state = {
			isFocused: false,
			isDocEmpty: true,
			isDragging: false,
			draggingId: null,
			dragPosition: 0,
			scrollPosition: 0
		} 
		this.listRef = React.createRef();
	}


	shouldComponentUpdate(nextProps, nextState) {
		if (!isEqual(this.props.audioTrackClips, nextProps.audioTrackClips)) {
			return true;
		}
		const propsToCheck = ['activeVoice', 'transcriptChunkWithCursorInside', 'transcriptSceneHeaders','transcriptWebcamHeaders', 'hoveredTranscriptChunk', 'previewingAudioClipId', 'activeVoiceoverPlaybackRate','showCaptions','subtitlesType','duration','hideInactiveCursor','voiceMatch','variableWebcams','transcriptSelectionRects','aspectRatio','isLoadingWebcamVoice','overrideVoiceMatch','activeTranscriptPanelAudioChunk'];
		for (let prop of propsToCheck) {
			if (this.props[prop] !== nextProps[prop]) {
				return true;
			}
		}
		if (!isEqual(this.state, nextState)) {
			return true;
		}
		// If we've made it here, no relevant props or state have changed
		return false;
	}

	componentDidMount() {
		this.props.transcriptPmManager.init(document.querySelector("#editor"), this.props.audioTrackClips, this.props.scenes);
		this.listRef.current.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleScroll);
	}

  componentWillUnmount(){
    this.props.transcriptPmManager.destroy();
    this.listRef.current.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    if (this.listRef.current) {
      this.setState({ scrollPosition: this.listRef.current.scrollTop });
    }
  }

	handleDragStateChange = (id, isDragging, yPosition) => {
		this.setState({
			isDragging,
			draggingId: isDragging ? id : null,
			dragPosition: yPosition
		});
	}

	render(){     
		const {audioTrackClips, activeVoice, hideTranscriptPanel} = this.props
		const {isDragging, isDocEmpty, isFocused, draggingId, dragPosition, scrollPosition} = this.state
		

		//todo handle only 1 placeholder webcam (has 2 clips)
		const hasMoreThanOneChunk = audioTrackClips && audioTrackClips.length > 1 

		const voiceoverClips = audioTrackClips.filter(c => !c.parentWebcamClip && c.type!=='webcam' );		

		const fixedHeaderHeight = 50
		const staticHeaderHeight = 80

		return (                  
			<div 
				data-dragging-state={isDragging ? 'dragging' : 'notDragging'} 
				className={`editor-transcriptPanel editor-transcriptPanel--transcript ${isDocEmpty ? 'editor-transcriptPanel--emptyTranscript' : 'editor-transcriptPanel--nonEmptyTranscript'} ${isDragging ? 'editor-transcriptPanel--isDragging' : ''}`}
			>  				
				<div className='editor-transcriptPanel-listBG' />


				
				<TranscriptPanelFixedHeader 
					handleChangeActiveVoice={this.props.handleChangeActiveVoice}
					handleChangeVoiceoverPlaybackRate={this.props.handleChangeVoiceoverPlaybackRate}
					activeVoiceoverPlaybackRate={this.props.activeVoiceoverPlaybackRate}
					activeVoiceId={activeVoice}
					projectId={this.props.projectId}
					projectName={this.props.projectName}
					subtitlesType={this.props.subtitlesType}
					handleChangeSubtitlesType={this.props.handleChangeSubtitlesType}
					handleGenerateSRTFile={this.props.handleGenerateSRTFile}						
					hideTranscriptPanel={hideTranscriptPanel}
					fixedHeaderHeight={fixedHeaderHeight}
					displayTitleBtn={scrollPosition > 52}
					displayBottomBorder={scrollPosition > 57}
					scrollPosition={scrollPosition}
					hideInactiveCursor={this.props.hideInactiveCursor}
					toggleHideInactiveCursor={this.props.toggleHideInactiveCursor}
					voiceMatch={this.props.voiceMatch}
					variableWebcams={this.props.variableWebcams}
					isLoadingWebcamVoice={this.props.isLoadingWebcamVoice}
					overrideVoiceMatch={this.props.overrideVoiceMatch}
					// overrideVoiceMatch={this.props.overrideVoiceMatch}
				/>				





				<div className='editor-transcriptPanel-list' ref={this.listRef}>  					
				
					
					<div style={{height: `${staticHeaderHeight}px`}} className='editor-transcriptPanel-staticHeader'>  
						<div className='editor-transcriptPanel-staticHeader-titleBtnContainer'>
							<TranscriptPanelTitleBtn 
								key='static'
								projectId={this.props.projectId}
								projectName={this.props.projectName}
								subtitlesType={this.props.subtitlesType}
								handleChangeSubtitlesType={this.props.handleChangeSubtitlesType}
								handleGenerateSRTFile={this.props.handleGenerateSRTFile}						
								hideTranscriptPanel={hideTranscriptPanel}
								scrollPosition={scrollPosition}			
								hideInactiveCursor={this.props.hideInactiveCursor}
								toggleHideInactiveCursor={this.props.toggleHideInactiveCursor}			
								aspectRatio={this.props.aspectRatio}
								changeAspectRatio={this.props.changeAspectRatio}				
							/>
						</div>
						<div className='editor-transcriptPanel-staticHeader-bottomBorder' />
					</div>

					

					<TranscriptPanelWebcamHeaders 
						webcamHeaders={this.props.transcriptWebcamHeaders}
						deleteWebcamClipFromHeader={this.props.deleteWebcamClipFromHeader}
						setWebcamHasInstructionsFromHeader={this.props.setWebcamHasInstructionsFromHeader}
						recordWebcamClipFromHeader={this.props.recordWebcamClipFromHeader}
						unlinkScreencast={this.props.unlinkScreencast}
						convertWebcamRecordingIntoScript={this.props.convertWebcamRecordingIntoScript}
						convertWebcamRecordingIntoVoiceover={this.props.convertWebcamRecordingIntoVoiceover}
						handleSeekToClipInTimelineWithScroll={this.props.handleSeekToClipInTimelineWithScroll}
					/>

					<TranscriptPanelSceneHeaderGutter 
						transcriptSceneHeaders={this.props.transcriptSceneHeaders || []}
						scenes={this.props.scenes}
						mergeScene={this.props.mergeScene}
						playClipFromTranscript={this.props.playClipFromTranscript}
						recalculateAudioClip={this.props.recalculateAudioClip}
						previewingAudioClipId={this.props.previewingAudioClipId}
						cancelPreviewingAudioClip={this.props.cancelPreviewingAudioClip}
					/>
					

					<TranscriptPanelVoiceGutter
						playClipFromTranscript={this.props.playClipFromTranscript}
						recalculateAudioClip={this.props.recalculateAudioClip}
						previewingAudioClipId={this.props.previewingAudioClipId}
						cancelPreviewingAudioClip={this.props.cancelPreviewingAudioClip}
						activeTranscriptPanelAudioChunk={this.props.activeTranscriptPanelAudioChunk}
						convertAudioClipIntoScript={this.props.convertAudioClipIntoScript}
						scrollPosition={scrollPosition}
					/>
					
					
					{/*}
					<TranscriptPanelDragHandleGutter 
						transcriptChunksForDragHandles={this.props.transcriptChunksForDragHandles || []}
						hoveredTranscriptChunk={this.props.hoveredTranscriptChunk}
						onDragStateChange={this.handleDragStateChange}
						isDragging={isDragging}
						draggingId={draggingId}
						dragPosition={dragPosition}
						handleTranscriptPanelChunkDrop={this.props.handleTranscriptPanelChunkDrop}
						transcriptChunkWithCursorInside={this.props.transcriptChunkWithCursorInside}
						voiceoverClips={voiceoverClips}
						playClipFromTranscript={this.props.playClipFromTranscript}
						recalculateAudioClip={this.props.recalculateAudioClip}
						scrollPosition={scrollPosition}
						previewingAudioClipId={this.props.previewingAudioClipId}
						cancelPreviewingAudioClip={this.props.cancelPreviewingAudioClip}
						addSceneAtEndOfProject={this.props.addSceneAtEndOfProject}
						addAudioClipAtEndOfProject={this.props.addAudioClipAtEndOfProject}
					/>    
					*/}    
					
					<TranscriptPanelWebcamSkipMarkers 
						skipMarkers={this.props.skipMarkers}
						restoreWebcamSkip={this.props.restoreWebcamSkip}
					/>

					<TranscriptPanelSelectionRects
						transcriptSelectionRects={this.props.transcriptSelectionRects}
					/>
					

					

					<div id={"editor"}/>														
				</div>
			</div>
		)
	}
}

export default TranscriptPanel
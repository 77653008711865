import React from 'react';
import { timeToPixels } from '../utils/timeToPixels';
import Icon from '../../../misc/Icon';

const EditorTimelineVideoResizeMode = (props) => {
  const {
    currentTime,
    scenes,
    pixelsPerSec,
    sceneGap,
    preVideoResizeSnapshot,
  } = props;
  const sidePanelOffset = 16;

 let preVideoResizeMarkerTime 
 if(preVideoResizeSnapshot.direction=='right'){
  preVideoResizeMarkerTime = preVideoResizeSnapshot.endTime - 0.001 //to handle scene boundary
 }else{
  preVideoResizeMarkerTime = preVideoResizeSnapshot.startTime
 }

  const preVideoResizeCurrentTimePixels = timeToPixels(preVideoResizeMarkerTime, pixelsPerSec, scenes, sceneGap);
  const currentTimePixels = timeToPixels(currentTime, pixelsPerSec, scenes, sceneGap);

  let originalLeft
  let previewSegmentLeft
  let previewSegmentWidth 
  let isExpanding

  const currentLeft = currentTimePixels + sidePanelOffset



  if(preVideoResizeCurrentTimePixels!==null){
    originalLeft = preVideoResizeCurrentTimePixels + sidePanelOffset
    previewSegmentLeft = Math.min(originalLeft, currentLeft)
    previewSegmentWidth = Math.abs(currentLeft - originalLeft)

    isExpanding = originalLeft > currentLeft

    if(preVideoResizeSnapshot.direction === 'right'){
      isExpanding = originalLeft < currentLeft  
    }    
  }


  
  return (
    <>
        
        {preVideoResizeCurrentTimePixels !== null && previewSegmentLeft && previewSegmentWidth && previewSegmentWidth > 0 &&(
          <>
          {preVideoResizeSnapshot && preVideoResizeSnapshot.videoClipId &&
            <div
              className="editor-timeline-skipSegment-previewSegment"
              data-expanded-state={isExpanding ? 'lengthening' : 'shortening'}
              data-track='videoClip'
              style={{                                             
                left: `${previewSegmentLeft}px`,
                width: `${previewSegmentWidth}px`,            
              }}
            />
          }
          {preVideoResizeSnapshot && preVideoResizeSnapshot.webcamClipId &&
            <div
              className="editor-timeline-skipSegment-previewSegment"
              data-expanded-state={isExpanding ? 'lengthening' : 'shortening'}
              data-track='webcamClip'
              style={{                                             
                left: `${previewSegmentLeft}px`,
                width: `${previewSegmentWidth}px`,            
              }}
            />
          }
          </>
        )}
    
      
        {/* Render the preAddSkip marker */}
        {preVideoResizeCurrentTimePixels !== null && (
          <div 
            className="editor-timeline-skipSegment-timePreviewLine editor-timeline-skipSegment-timePreviewLine--original editor-timeline-skipSegment-timePreviewLine--resize"
            style={{ left: `${preVideoResizeCurrentTimePixels + sidePanelOffset}px` }}
          >
            <div className="editor-timeline-skipSegment-timePreviewLine-iconContainer">
              <Icon name="previewPlayheadTriangle" />
            </div>
            <div className="editor-timeline-skipSegment-timePreviewLine-line" />
            <div className="editor-timeline-skipSegment-timePreviewLine-bgLine" />
          </div>
        )}

        {/* Render the current marker */}
        <div 
          className="editor-timeline-skipSegment-timePreviewLine editor-timeline-skipSegment-timePreviewLine--current editor-timeline-skipSegment-timePreviewLine--resize"
          style={{ left: `${currentTimePixels + sidePanelOffset}px` }}
        >
          <div className="editor-timeline-skipSegment-timePreviewLine-iconContainer">
            <Icon name="previewPlayheadTriangle" />
          </div>
          <div className="editor-timeline-skipSegment-timePreviewLine-line" />
          <div className="editor-timeline-skipSegment-timePreviewLine-bgLine" />
        </div>
    </>
  );
};

export default EditorTimelineVideoResizeMode;
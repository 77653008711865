import {post} from '../api'

export function fetchSpeechToSpeech(captureId,voiceId) {
	return async (dispatch) => {
		try {
			const response = await post('/sts', {captureId,voiceId});
			return response	
		} catch (error) {
			console.error('Error speech to speech speech:', error);
			throw error;
		}
	};
}


const blobCache = {};


export const fetchBasicVideo = async (videoId) => {
  let videoURL
  const videoPath = `videoUploads/${videoId}`;
  if (blobCache[videoPath]) {
    return blobCache[videoPath];
  } else {
    if(window.isElectron){
      const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
      if(videoBuffer){
        const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
        videoURL = URL.createObjectURL(videoBlob);
        blobCache[videoPath] = videoURL;
        return videoURL;
      }else{
        return null
      }
    }else{
      return `https://res.cloudinary.com/yarn/video/upload/v1719086772/video_uploads/${videoId}`
    }
  }
};








//Use this one for resizing clips we can resize beyond the current trim values 


export function calulateVideoTimeFromTimelineTimeNoTrims(timelineTime, clip){
  const clipPlaybackRate = clip.clipPlaybackRate  
  const timeSinceVideoStart = (timelineTime - clip.startTime)*clipPlaybackRate+clip.metadata.trimStart

  if(timeSinceVideoStart<0){
    return 0
  }
 
  const segments = clip.recordingSegments //use recording segments instead of segments which have trims applies 
  const trimStart = clip.metadata.trimStart

  let accumulatedDuration = 0
  let videoTime = 0;

  for (const segment of segments) {
    let segmentDuration 
    if(segment.isSkipSegment){
      if(segment.isExpanded){
        segmentDuration = segment.originalDuration
      }else{
        segmentDuration=0
      }
    }else if(segment.isQuiet){
      segmentDuration = segment.originalDuration / segment.playbackRate
    }else{
     segmentDuration= segment.originalDuration
    }
    if (timeSinceVideoStart >= accumulatedDuration && timeSinceVideoStart < accumulatedDuration + segmentDuration) {
    // Calculate the position within the current segment
      const segmentElapsedTime = timeSinceVideoStart - accumulatedDuration; 
      videoTime += segment.isQuiet ? segmentElapsedTime * segment.playbackRate : segmentElapsedTime;
      break;
     } else {
        videoTime += segment.originalDuration
    }
    accumulatedDuration += segmentDuration;
  }

  return videoTime;
};




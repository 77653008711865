const DEFAULT_TITLE="Default title"

class SceneHeaderNodeView {

	constructor(node, view, getPos) {
		this.node = node;
		this.view = view;
		this.getPos = getPos;

		this.dom = document.createElement("div")
		this.dom.classList.add('editor-transcriptPanel-sceneHeader')


		this.contentDOM = document.createElement("div");
		this.contentDOM.classList.add('editor-transcriptPanel-sceneHeader-contents')
		this.dom.appendChild(this.contentDOM)

		this.updateVisibility()
		this.updateClassNames()

	}


	updateClassNames(){
		const textContent=this.node.textContent
		if (textContent.length == 0){
			this.dom.classList.add("editor-transcriptPanel-sceneHeader--empty")
		}else{
			this.dom.classList.remove("editor-transcriptPanel-sceneHeader--empty")
		}
	}



	updateVisibility() {
		if (this.node.textContent==DEFAULT_TITLE) {
			this.contentDOM.style.display = 'none';
			this.dom.contentEditable = 'false'
		} else {
			this.contentDOM.style.display = '';
			this.dom.contentEditable = 'true'
		}
	}


update(node) {
    if (this.node.type !== node.type) {
      return false
    }
    const oldSceneDuration = this.node.attrs.sceneDuration
    const oldSceneCount = this.node.attrs.sceneCount
    const oldTextContent = this.node.textContent
    this.node = node
    if (
    	node.textContent !== oldTextContent ||
        node.attrs.sceneDuration !== oldSceneDuration ||
        node.attrs.sceneCount !== oldSceneCount
        ) {  
    	this.updateVisibility()
    	this.updateClassNames()
    }
    return true;
  }



	destroy() {
		
	}


}

export default SceneHeaderNodeView

import { fetch } from "../../api";
import store from "../../store";
import { getWebcamForCaptureId } from "./getWebcamForCaptureId";

export const getFaceBoxForWebcamCaptureId = async (
  captureId
) => {
  try {
    // First check store
    let webcamObj = getWebcamForCaptureId(captureId)
    if (webcamObj?.face_boxes && webcamObj.face_boxes.length > 0) {
      return webcamObj.face_boxes;
    }

    // Check pending webcam uploads
    webcamObj = store.getState().pendingWebcamUploads[captureId]?.webcam;
    if (webcamObj?.face_boxes && webcamObj.face_boxes.length > 0) {
      return webcamObj.face_boxes;
    }

    // Check API
    webcamObj = await fetch(`/webcam-recording/${captureId}`);
    const data = webcamObj?.face_boxes;
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error('Failed to load face box:', error);
    return null;
  }
};
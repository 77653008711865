import React, { useContext } from 'react';
import { connect } from 'react-redux';
// import {createTimeline} from '../../timeline/utils/createTimeline'
import {createExportTimeline} from '../../serverSideExport/exportTimeline/createExportTimeline'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import ExportDevModeUI from './ExportDevModeUI'
import ExportCanvasContainer from './ExportCanvasContainer'
import {fetchSingleProject} from '../../actions/projects'


class ExportPlayer extends React.Component{  
  
  constructor(props) {
    super(props);    
    this.state = {
      clips:[],
      currentTime: 0,
      isPlaying: false,
      duration:0,
    //  projectBackgroundId:null,
     // backgroundMusicTrack:null,
      testKey:0
    };  
  }

  componentDidMount(){
    if(this.props.project){
      this.loadProject()
    }else{
      this.props.fetchSingleProject(this.props.projectId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.project && !prevProps.project) {
      this.loadProject();
    }
   } 


  loadProject=()=>{
    const projectId = this.props.projectId
    const project = this.props.project 
    const timeline = this.props.project.timeline
    const isExport = true

   createExportTimeline(project,0,project.duration,this.onTimeUpdate,()=>{},{})
   .then(timeline => {
    this.timeline = timeline;
    this.updateStateFromTimeline()
   })
   .catch(error => {
    console.error('Error in creating timeline:', error);
   })
    //     this.timeline = timeline;
    //     this.updateStateFromTimeline()
    //this.updateStateFromTimeline()
    // createTimeline(isExport,projectId,timeline, this.onTimeUpdate,this.handlePlaybackEnded,this.handleVideoClipLoaded,this.handleVoiceoverClipUpdated,this.getClipForId)
    //   .then(timeline => {
    //     this.timeline = timeline;
    //     this.updateStateFromTimeline()
    //     this.setState({
    //       defaultMotionStyle:project.default_motion_style,
    //       projectName: project.name,
    //       projectBackgroundId:project.background,
    //     })
    //   })
    //   .catch(error => {
    //     console.error('Error in creating timeline:', error);
    //   });
  }


  componentWillUnmount() {
    if(this.timeline){
      this.timeline.destroy()
      this.timeline = null
    }
  }


  ////// Timeline playback
  togglePlayPause=()=>{
    console.log('toggle play pause')
    if(this.state.isPlaying){
      this.timeline.pause()
    }else{
      this.timeline.play()
    }
    this.setState({isPlaying:!this.state.isPlaying})
  }

  onTimeUpdate=(currentTime)=>{
    this.setState({currentTime:currentTime})
    const {isPreviewing} = this.state
    if(isPreviewing){
      const {previewClip} = this.state
      if(currentTime>=previewClip.startTime+previewClip.startTranstionDuration){
        this.resetPreview()
      }
    }
  }

  handleSeek=(newTime)=>{
    console.log(`seek to ${newTime}`)
    this.timeline.seek(newTime)
  }

  handlePlaybackEnded=()=>{
    this.setState({isPlaying:false})
  }

  updateStateFromTimeline = () =>{
    if(this.timeline){ //for initial load
    this.setState({
      duration:this.timeline.duration,
      currentTime:this.timeline.currentTime,
      clips:this.timeline.clips,
      activeVoice:this.timeline.activeVoice,
    })
  }
  }

  getClipForId=(clipId)=>{
    const {clips} = this.state 
    const clip=find(clips,{id:clipId})
    return clip
  }

  handleVideoClipLoaded=()=>{
    //bump state when video clip finishes loading
    this.updateStateFromTimeline()
  }


  render(){     
    let centerContainerHeight = this.state.windowHeight - this.state.timelineHeight - 42// 34 is topbar height    
    const {clips,duration,currentTime,isPlaying}=this.state
    const voiceClips = filter(clips,{zIndex:-1})
    const sortedVoiceClips = sortBy(voiceClips,'startTime')
    const {meshHeight, meshWidth, zoomBox} = this.state


    const devMode = true


    let audioClipsJSON=[]
    let webcamClipsJSON = []
    let basicVideoClipsJSON = []

    clips.forEach((clip)=>{
      if(clip.type=='audio'){
  //      audioClipsJSON.push(clip.toExportJSON())
      }
    })

    clips.forEach((clip)=>{
      if(clip.type=='webcam'){
       // webcamClipsJSON.push(clip.toJSON())
      }
    })

    clips.forEach((clip)=>{
      if(clip.type=='video' && clip.isBasicVideo){
        //basicVideoClipsJSON.push(clip.toJSON())
      }
    })

    // console.log('webcamClipsJSON')
    // console.log(webcamClipsJSON)
    // if(this.timeline){
    //   console.log(this.timeline.clips)
    // }
    let soundEffects = []
   // if(this.timeline){
      //console.log('timeline',this.timeline)
    this.props.project?.timeline?.scenes?.forEach(scene=>{
     // console.log('scene',scene)
      soundEffects.push(...scene.soundEffects)
    })
      // this.timeline.scenes.forEach(scene=>{
      //   console.log('scene',scene)
      //   soundEffects.push(...scene.soundEffects)
      // })
      //soundEffects = this.timeline.soundEffects || []
  //  }
     console.log('soundEffects----->>>>>>>>>',soundEffects)
    return (            

        <div className='exportPlayer'>
                                  
          <div className={'exportPlayer-preview ' + (devMode ? ' exportPlayer-preview--dev ' : ' exportPlayer-preview--prod ') }>
            <ExportCanvasContainer                         
              isPlaying = {isPlaying}
              currentTime={currentTime}
              projectBackgroundId={this.props.project?.background}
              slideClips = {filter(clips, {zIndex: 1, type: 'slide'})}
              textSlideClips = {filter(clips, {zIndex: 1, type: 'textSlide'})}
              imageClips = {filter(clips, {zIndex: 1, type: 'image'})}
              chartClips = {filter(clips, {type: 'chart'})}
              videoClips = {filter(clips, {zIndex: 0})}
              zoomClips = {filter(clips, {zIndex: 2})}
              audioClips = {filter(clips,{type:'audio'})}
              audioClipsJSON = {audioClipsJSON}
              webcamClipsJSON={webcamClipsJSON}
              basicVideoClipsJSON={basicVideoClipsJSON}
              webcamClips={filter(clips,{type:'webcam'})}
              isDraggingProgressMarker={false}              
              setTime={this.handleSeek}
              duration={duration}
              projectId={this.props.projectId}
              project={this.props.project}
              devMode={devMode}
              soundEffects={soundEffects}
            />  
          </div>

          {devMode && 
            <ExportDevModeUI 
              togglePlayPause={this.togglePlayPause}
              currentTime={this.state.currentTime}
              handleSeek={this.handleSeek}
              duration={this.state.duration}
            />
          }
        </div>
    
    )
  }
}

function mapStateToProps(state,ownProps) {
  const project = find(state.projects,{id:`${ownProps.projectId}`})
  return {
    project:project
  }
}

export default connect(
    mapStateToProps,
    {fetchSingleProject}
)(ExportPlayer)
// reducers/voiceMatch.js
const initialState = {
  activeRequests: {},  // projectId -> requestId mapping
  results: {}         // projectId -> {requestId, result} mapping
};

export default function voiceMatchReducer(state = initialState, action) {
  switch (action.type) {
    case 'VOICE_MATCH_REQUESTED':
      // Simply overwrite any existing request for this project
      return {
        ...state,
        activeRequests: {
          ...state.activeRequests,
          [action.projectId]: action.requestId
        },
        // Clear any existing results for this project since we're starting fresh
        results: {
          ...state.results,
          [action.projectId]: null
        }
      };
      
    case 'VOICE_MATCH_RECEIVED':
      // Only store result if it matches the current active request
      if (state.activeRequests[action.projectId] == action.requestId) {
        return {
          ...state,
          results: {
            ...state.results,
            [action.projectId]: {
              requestId: action.requestId,
              sources: action.sources,
              voiceId:action.voiceId
            }
          }
        };
      }
      // If it doesn't match the active request, ignore it
     // console.log('is an old request ignore it!!!!!!')
      return state;
      
    default:
      return state;
  }
}
import {TextSelection} from "prosemirror-state"
import {estimateAudioDuration} from '../../../utils/estimateAudioDuration'
import {randomID} from '../../../utils/randomID'
/**
 * Handles Enter key behavior transcript panel
 * 
 * Types of transcript groups and chunks
 * - Standard transcript chunks
 * - Webcam recording chunks
 * - Variable webcam placeholder chunks
 * 
 * Key behaviors:
 * 1. For webcam recording groups:
 *    - At start of first chunk: Inserts standard group/chunk above
 *    - At end of last chunk: Adds standard group/chunk below
 *    - Mid-chunk: Splits webcam transcript words between chunks
 * 
 * 2. For variable webcam (placeholder) groups:
 *  	- At start of first chunk: Inserts standard group/chunk above
 *    - Empty last chunk: If it is the only chunk in the group then convert to standard group else creates new standard group below
 *    - Non-empty chunks: Splits content between chunks
 * 
 * 3. For standard groups:
 *    - Splits content at cursor position
 *    - Creates new chunk with unique ID and estimated duration (this will be an audio clip)
 * */


const isFirstChunkInGroup = (groupNode, chunkNode) => {
  let firstChunk = null;
  groupNode.forEach((node) => {
    if (!firstChunk && (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk')) {
      firstChunk = node;
    }
  });
  return firstChunk === chunkNode;
};

const isLastChunkInGroup = (groupNode, chunkNode) => {
  let lastChunk = null;
  groupNode.forEach((node) => {
    if (node.type.name === 'webcamRecordingChunk' || node.type.name === 'transcriptChunk') {
      lastChunk = node;
    }
  });
  return lastChunk === chunkNode;
};

const findActiveTranscriptNodes = (state) => { //find the nodes at the cursor pos
    const { $from, $to } = state.selection;
    let nodes = {
        transcriptChunkNode: null,
        sceneHeaderNode: null,
        transcriptGroupNode: null,
        nodePos: null,
        groupPos: null
    };
    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
        if (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') {
            nodes.transcriptChunkNode = node;
            nodes.nodePos = pos;
        }
        if (node.type.name === 'sceneHeader') {
            nodes.sceneHeaderNode = node;
            nodes.nodePos = pos;
        }
        if (node.type.name === 'transcriptGroup') {
            nodes.transcriptGroupNode = node;
            nodes.groupPos = pos;
        }
    });
    return nodes;
};


export const enterKeyHandler = (splitRecordingClipFromTranscript) => {

	return (state, dispatch, view) => {
		const nodes = findActiveTranscriptNodes(state);
		const {transcriptGroupNode,transcriptChunkNode,sceneHeaderNode,nodePos,groupPos} = nodes
		
		if (!transcriptChunkNode && !sceneHeaderNode) {
			return false;
		}
		
		let tr = state.tr;

		// Helper function to set cursor position at start of a chunk
		//const setCursorToChunkStart = (chunkPos) => {
		// 	return tr.setSelection(TextSelection.create(tr.doc, chunkPos + 2));
		// };
		const setCursorToChunkStart = (chunkPos) => {
			return tr.setSelection(TextSelection.create(tr.doc, chunkPos));
		};

		if (sceneHeaderNode) {
			tr = setCursorToChunkStart(nodePos + sceneHeaderNode.nodeSize);
			dispatch(tr);
			return true;
		}

		const groupType = nodes.transcriptGroupNode?.attrs.groupType //'variable-webcam' || 'webcam-recording' || 'standard'
		
		const createStandardGroup = (content) => {
			return state.schema.nodes.transcriptGroup.createChecked({
				groupId: randomID(),
				groupType: 'standard'
			}, content);
		};

		const { selection } = state;
		const { $from, $to } = selection;
		const relativePos = $from.pos - nodePos - 2;
		const textBeforeCursor = transcriptChunkNode.textContent.slice(0, relativePos).trim();
		const textAfterCursor = transcriptChunkNode.textContent.slice(relativePos).trim();
		const isFirstChunk=isFirstChunkInGroup(transcriptGroupNode, transcriptChunkNode)
		const isLastChunk=isLastChunkInGroup(transcriptGroupNode, transcriptChunkNode)

		if (groupType=='standard' && !textBeforeCursor && !textAfterCursor) {
			return true;
		}
		
		////WEBCAM RECORDINGS
	if (groupType === 'webcam-recording') {
  	const isAtStart = relativePos === -1;
  	let hasWordsAfterCursor = false;
  	let currentPos = nodePos + 1;
  	let wordCount = 0;

  	// Count total words and check if any are after cursor
		transcriptChunkNode.content.forEach((node) => {
			if (node.type.name === 'webcamTranscriptItem') {
				wordCount++;
				const nodeStartPos = currentPos;
				if (nodeStartPos >= $from.pos) {
					hasWordsAfterCursor = true;
				}
				currentPos += node.nodeSize;
			}
		});
  
 	 const isAtEnd = !hasWordsAfterCursor;

  // Handle start case - make a new standard group with a new chunk
  if (isAtStart) {
    if(isFirstChunk) {
      const newChunk = state.schema.nodes.transcriptChunk.createChecked({
        clipId: randomID(),
        sceneId: transcriptChunkNode.attrs.sceneId,
        requiresUpdate: false,
      }, [state.schema.nodes.paragraph.createChecked()]);
      
      const standardGroup = createStandardGroup([newChunk]);
      tr = tr.insert(groupPos, standardGroup);
      tr = setCursorToChunkStart(groupPos);
      dispatch(tr);
      return true;
    } else {
			const clipId=transcriptChunkNode.attrs.clipId
			splitRecordingClipFromTranscript(clipId,transcriptChunkNode.attrs.startTime)
      return true;
    }
  }

  // Handle end case - make a new standard group if tehre is not already one
  if (isAtEnd && isLastChunk) {
    // Check for empty standard chunk in next group
    let nextGroup = null;
    let nextGroupPos = groupPos + transcriptGroupNode.nodeSize;
    
    // Find next group
    state.doc.nodesBetween(nextGroupPos, nextGroupPos + 1, (node, pos) => {
      if (node.type.name === 'transcriptGroup') {
        nextGroup = node;
        nextGroupPos = pos;
      }
    });

    // If next group is standard and has an empty chunk, move cursor there
    if (nextGroup && nextGroup.attrs.groupType === 'standard') {
      let hasEmptyChunk = false;
      let emptyChunkPos = null;
      
      nextGroup.forEach((node, offset) => {
        if (node.type.name === 'transcriptChunk' && node.textContent.trim() === '') {
          hasEmptyChunk = true;
          emptyChunkPos = nextGroupPos + offset + 1;
        }
      });

      if (hasEmptyChunk) {
        tr = setCursorToChunkStart(emptyChunkPos);
        dispatch(tr);
        return true;
      }
    }

    // If no empty standard chunk found, create new one
    const newChunk = state.schema.nodes.transcriptChunk.createChecked({
      clipId: randomID(),
      sceneId: transcriptChunkNode.attrs.sceneId,
      requiresUpdate: false,
    }, [state.schema.nodes.paragraph.createChecked()]);

    const standardGroup = createStandardGroup([newChunk]);
    const insertPos = groupPos + transcriptGroupNode.nodeSize;
    tr = tr.insert(insertPos, standardGroup);
    tr = setCursorToChunkStart(insertPos);
    dispatch(tr);
    return true;
  }

  if (isAtEnd) {
		const clipId=transcriptChunkNode.attrs.clipId
		splitRecordingClipFromTranscript(clipId,transcriptChunkNode.attrs.endTime)
    return true;
  }

  // Split current chunk
  let wordsBeforeCursor = [];
  let wordsAfterCursor = [];
  currentPos = nodePos + 1;
  let lastEndTimeBeforeCursor = null;
  let firstStartTimeAfterCursor = null;
  
  transcriptChunkNode.content.forEach((node) => {
    if (node.type.name === 'webcamTranscriptItem') {
      const nodeStartPos = currentPos;
      if (nodeStartPos < $from.pos) {
        wordsBeforeCursor.push(node);
        lastEndTimeBeforeCursor = node.attrs.endTime;
      } else {
        wordsAfterCursor.push(node);
        if (firstStartTimeAfterCursor === null) {
          firstStartTimeAfterCursor = node.attrs.startTime;
        }
      }
      currentPos += node.nodeSize;
    }
  });


  // Create new chunks with updated indices
  const updatedOriginalChunk = state.schema.nodes.webcamRecordingChunk.createChecked(
    {
      ...transcriptChunkNode.attrs,
      startTime: transcriptChunkNode.attrs.startTime,
      endTime: lastEndTimeBeforeCursor
    },
    wordsBeforeCursor
  );

  const newSplitChunk = state.schema.nodes.webcamRecordingChunk.createChecked({
    clipId: transcriptChunkNode.attrs.clipId,
    sceneId: transcriptChunkNode.attrs.sceneId,
    requiresUpdate: false,
    transcriptChunkIndex: transcriptChunkNode.attrs.transcriptChunkIndex + 1,
    transcriptChunkCount: transcriptChunkNode.attrs.transcriptChunkCount,
    startTime: firstStartTimeAfterCursor,
    endTime: transcriptChunkNode.attrs.endTime
  }, wordsAfterCursor);

  tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, updatedOriginalChunk);
  const insertPos = nodePos + updatedOriginalChunk.nodeSize;
  tr = tr.insert(insertPos, newSplitChunk);
  tr = setCursorToChunkStart(insertPos);
  dispatch(tr);
  return true;
}

	////// VARIABLE WEBCAM
	else if (groupType=='variable-webcam') {
		const isAtStart = relativePos === 0;
		const isOnlyChunk = transcriptGroupNode.childCount === 1;
		
		if (isAtStart && isFirstChunk && !isOnlyChunk) { //add a standard group above the current group
			const newChunk = state.schema.nodes.transcriptChunk.createChecked({
				clipId: randomID(),
				sceneId: transcriptChunkNode.attrs.sceneId,
				requiresUpdate: false,
			}, [state.schema.nodes.paragraph.createChecked()]);
			const standardGroup = createStandardGroup([newChunk]);
			tr = tr.insert(groupPos, standardGroup);
			tr = setCursorToChunkStart(groupPos);
			dispatch(tr);
			return true;
		}

		//if we are in an empty chunk only do something if its the last chunk
		if (!textBeforeCursor && !textAfterCursor) {
			const isLastChunk = isLastChunkInGroup(transcriptGroupNode, transcriptChunkNode);

    // If it's not the last chunk and it's not the only chunk, split the group
    if (!isLastChunk && !isOnlyChunk) {
        const chunksBeforeCurrent = [];
        const chunksAfterCurrent = [];
        let foundCurrent = false;
        
        transcriptGroupNode.forEach((node) => {
            if (node === transcriptChunkNode) {
                foundCurrent = true;
            } else if (!foundCurrent) {
                chunksBeforeCurrent.push(node);
            } else {
                chunksAfterCurrent.push(node);
            }
        });

        // Create first group with chunks before current
        const firstGroupNode = state.schema.nodes.transcriptGroup.createChecked({
            groupId: transcriptGroupNode.attrs.groupId,
            groupType: 'variable-webcam',
            hasInstructions: transcriptGroupNode.attrs.hasInstructions
        }, chunksBeforeCurrent);

        // Create second group with chunks after current
        const secondGroupNode = state.schema.nodes.transcriptGroup.createChecked({
            groupId: randomID(),
            groupType: 'variable-webcam',
            hasInstructions: transcriptGroupNode.attrs.hasInstructions
        }, chunksAfterCurrent);

        // Replace current group with first group and insert second group
        tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, firstGroupNode);
        const secondGroupPos = groupPos + firstGroupNode.nodeSize;
        tr = tr.insert(secondGroupPos, secondGroupNode);
        tr = setCursorToChunkStart(secondGroupPos);
        dispatch(tr);
        return true;
    }



			///If it is the only chunk in the group then we can convert it into a standard group
			
			if (isOnlyChunk) {
				const standardGroup = state.schema.nodes.transcriptGroup.createChecked({
					groupId: transcriptGroupNode.attrs.groupId,
					groupType: 'standard'
				}, [transcriptChunkNode]);
					tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, standardGroup);
					tr = setCursorToChunkStart(groupPos); // Position cursor at start of the chunk
					dispatch(tr);
					return true;
				}

				//If it is the lastchunk in the group lets make a standard group below it 
				if (isLastChunk) {
					const newChunk = state.schema.nodes.transcriptChunk.createChecked({
						clipId: randomID(),
						sceneId: transcriptChunkNode.attrs.sceneId,
						requiresUpdate: false,
						transcriptChunkIndex: 0,
						transcriptChunkCount: 1
					}, [state.schema.nodes.paragraph.createChecked()]);

					const newGroup = state.schema.nodes.transcriptGroup.createChecked({
						groupId: randomID(),
						groupType: 'standard'
					}, [newChunk]);

					const chunksWithoutEmpty = [];
					transcriptGroupNode.forEach((node) => {
						if (node !== transcriptChunkNode) {
							chunksWithoutEmpty.push(node);
						}
					});

					const updatedCurrentGroup = state.schema.nodes.transcriptGroup.createChecked({
						groupId: transcriptGroupNode.attrs.groupId,
						groupType: 'variable-webcam',
						hasInstructions:transcriptGroupNode.attrs.hasInstructions
					}, chunksWithoutEmpty);

					tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, updatedCurrentGroup);
					const newGroupPos = groupPos + updatedCurrentGroup.nodeSize;
					tr = tr.insert(newGroupPos, newGroup);
					tr = setCursorToChunkStart(newGroupPos); // Position cursor at start of new chunk
					dispatch(tr);
					return true;
					} else { //the chunk is not an empty chunk lets work out what to do
						const chunksBeforeCurrent = [];
						const chunksAfterCurrent = [];
						foundCurrent = false;
						transcriptGroupNode.forEach((node, pos) => {
							if (node === transcriptChunkNode) {
								foundCurrent = true;
							} else if (!foundCurrent) {
								chunksBeforeCurrent.push(node);
							} else {
								chunksAfterCurrent.push(node);
							}
						});

						const firstGroupNode = state.schema.nodes.transcriptGroup.createChecked({
							groupId: transcriptGroupNode.attrs.groupId,
							groupType: 'variable-webcam',
							hasInstructions:transcriptGroupNode.attrs.hasInstructions
						}, chunksBeforeCurrent);

						const secondGroupNode = state.schema.nodes.transcriptGroup.createChecked({
							groupId: randomID(),
							groupType: 'variable-webcam',
							hasInstructions:transcriptGroup.attrs.hasInstructions
						}, chunksAfterCurrent);

						tr = tr.replaceWith(groupPos, groupPos + transcriptGroupNode.nodeSize, firstGroupNode);
						const secondGroupPos = groupPos + firstGroupNode.nodeSize;
						tr = tr.insert(secondGroupPos, secondGroupNode);
						tr = setCursorToChunkStart(secondGroupPos); // Position cursor at start of first chunk in second group
						dispatch(tr);
						return true;
					}
				} else {
					const isAtEndOfChunk = relativePos === transcriptChunkNode.textContent.length;
					
					if (isAtEndOfChunk) {
						let nextChunk = null;
						let nextChunkPos = null;
						let foundCurrent = false;
						
						transcriptGroupNode.forEach((node, pos, index) => {
							if (foundCurrent && !nextChunk && node.type.name === 'transcriptChunk') {
								nextChunk = node;
								nextChunkPos = groupPos + pos + 1;
							}
							if (node === transcriptChunkNode) {
								foundCurrent = true;
							}
						});

						if (nextChunk && nextChunk.textContent.trim() === '') {
							tr = setCursorToChunkStart(nextChunkPos);
							dispatch(tr);
							return true;
						}
					}

					const paragraphWithContentBefore = state.schema.nodes.paragraph.createChecked(
						{},
						textBeforeCursor ? [state.schema.text(textBeforeCursor)] : null
					);

					const paragraphWithContentAfter = state.schema.nodes.paragraph.createChecked(
						{},
						textAfterCursor ? [state.schema.text(textAfterCursor)] : null
					);

					const updatedOriginalChunk = state.schema.nodes.transcriptChunk.createChecked(
						transcriptChunkNode.attrs,
						[paragraphWithContentBefore]
					);

					const newChunk = state.schema.nodes.transcriptChunk.createChecked({
						clipId: randomID(),
						sceneId: transcriptChunkNode.attrs.sceneId,
						requiresUpdate: false,
						transcriptChunkIndex: transcriptChunkNode.attrs.transcriptChunkIndex + 1,
						transcriptChunkCount: transcriptChunkNode.attrs.transcriptChunkCount
					}, [paragraphWithContentAfter]);

					tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, updatedOriginalChunk);
					const insertPos = nodePos + updatedOriginalChunk.nodeSize;
					tr = tr.insert(insertPos, newChunk);
					tr = setCursorToChunkStart(insertPos); // Position cursor at start of new chunk
				}
			}
			else if(groupType=='standard') {// Standard group behavior
				const isAtEndOfChunk = relativePos === transcriptChunkNode.textContent.length;
				
				if (isAtEndOfChunk) {
					let nextChunk = null;
					let nextChunkPos = null;
					let foundCurrent = false;
					
					transcriptGroupNode.forEach((node, pos, index) => {
						if (foundCurrent && !nextChunk && node.type.name === 'transcriptChunk') {
							nextChunk = node;
							nextChunkPos = groupPos + pos + 1;
						}
						if (node === transcriptChunkNode) {
							foundCurrent = true;
						}
					});

					if (nextChunk && nextChunk.textContent.trim() === '') {
						tr = setCursorToChunkStart(nextChunkPos);
						dispatch(tr);
						return true;
					}
				}

				const paragraphBefore = state.schema.nodes.paragraph.createChecked(
					{},
					textBeforeCursor ? [state.schema.text(textBeforeCursor)] : null
				);

				const paragraphAfter = state.schema.nodes.paragraph.createChecked(
					{},
					textAfterCursor ? [state.schema.text(textAfterCursor)] : null
				);

				const newClipId = randomID();
				const estimatedDuration = estimateAudioDuration(textAfterCursor)/1000;

				const newOriginalChunk = state.schema.nodes.transcriptChunk.createChecked(
					{...transcriptChunkNode.attrs},
					[paragraphBefore]
				);

				const newChunk = state.schema.nodes.transcriptChunk.createChecked({
					clipId: newClipId,
					clipDuration: estimatedDuration,
					sceneId: transcriptChunkNode.attrs.sceneId,
					requiresUpdate: false,
				}, [paragraphAfter]);

				tr = tr.replaceWith(nodePos, nodePos + transcriptChunkNode.nodeSize, newOriginalChunk);
				const insertPos = nodePos + newOriginalChunk.nodeSize;
				tr = tr.insert(insertPos, newChunk);
				tr = setCursorToChunkStart(insertPos); // Position cursor at start of new chunk
			}
			
			dispatch(tr);
			return true;
		} 
};
import {TimelineClip} from './TimelineClip'
import {calculateMinSlideDuration,calculateStartAndEndAnimationDuration} from '../three/utils/animations/calculateMinSlideDuration'
import { startTransitionConfigs } from '../three/textSlide/transitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from '../three/textSlide/transitionConfigs/endTransitionConfigs';
import { brands } from '../utils/brands/brandKits'
import find from 'lodash/find'

/**
 * Legacy chart slides- we now use SlideClip
*/



class ChartClip extends TimelineClip {

	constructor(options,scene) {
		super(options,scene)    
	}

 // updateWithNewActiveBrand(brand,background){
 //    //console.log(`NEW ACTIVE BRAND_____${brand}`)
 //    const brandKit = find(brands,{name:brand})
 //    this.brandKit=brandKit
 //    this.brand = brand
 //    this.projectBackground=background
 //    this.metadata.fontFamily=brandKit.font.fontFamily
 //    this.metadata.fontWeight=brandKit.font.fontWeight
 //    this.metadata.fontSize=brandKit.font.fontSize

 //    const textColor = find(brandKit.textColors,{id:background.defaultTextColor})
 //    this.metadata.textColor = textColor
 //    this.metadata.isAutoTextColor=true
 //    this.metadata.chartBackgroundColor=this.projectBackground
 //    this.metadata.isAutoBackgroundColor=true
 
 //  }



  updateProjectDefaultMotionStyle(motionStyle){
   // console.log('here in CHART 123 update project motion style')
    //console.log(motionStyle)    
    if(this.metadata.numberChartData.isAutoMotionStyle){
      this.metadata.numberChartData.motionStyle=motionStyle
    }
    if(this.metadata.barChartData.isAutoMotionStyle){
      this.metadata.barChartData.motionStyle=motionStyle
    }
     if(this.metadata.donutChartData.isAutoMotionStyle){
      this.metadata.donutChartData.motionStyle=motionStyle
    }
  }

  // updateWithNewProjectBackground(brand,background){
  //   this.projectBackground=background
  //   if(this.metadata.isAutoBackgroundColor){ //otherwise keep the same color
  //     const textColor = find(this.brandKit.textColors,{id:background.defaultTextColor})
  //     this.metadata.textColor = textColor
  //     this.metadata.chartBackgroundColor=background
  //   }

  // }

 // setBranding(brand,background){
 //    const brandKit = find(brands,{name:brand})
 //    this.activeBrand=brand 
 //    this.projectBackground=background
 //    this.brandKit=brandKit
 //    this.projectBackground=background
 //    this.metadata.fontFamily=brandKit.font.fontFamily
 //    this.metadata.fontWeight=brandKit.font.fontWeight
 //    this.metadata.fontSize=brandKit.font.fontSize
 //    if(!this.metadata.chartBackgroundColor){
 //      this.metadata.chartBackgroundColor=this.projectBackground
 //    }

 //  } 


  // setBackgroundColor(backgroundColorId){
  //   let backgroundColor
  //   if(backgroundColorId){
  //     backgroundColor = find(this.brandKit.backgrounds,{id:backgroundColorId})
  //     this.metadata.isAutoBackgroundColor=false
  //   }else{
  //     backgroundColor = this.projectBackground
  //     this.metadata.isAutoBackgroundColor=true
  //   }
  
  //   this.metadata.chartBackgroundColor=backgroundColor
  //   const textColor = find(this.brandKit.textColors,{id:backgroundColor.defaultTextColor})
  //   this.metadata.textColor = textColor
  // }


  toJSON() {
    return {
      id: this.id,
      type:this.type,
      startTime:this._startTime, //time relative to the scene
      //startTime:this.startTime,
      duration:this.duration,
      metadata:this.metadata,
      zIndex:this.zIndex
    };
  }


  destroy() {

  }
  
}


export { ChartClip }

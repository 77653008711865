import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import LibraryHomePage from '../components/library/LibraryHomePage'
import LibrarySubPage from '../components/library/LibrarySubPage'
import LibraryCollectionPage from '../components/library/LibraryCollectionPage'
import LibrarySideMenu from '../components/library/sideMenu/LibrarySideMenu'
import LibraryPageHeader from '../components/library/headers/LibraryPageHeader'
import LibraryPageStickyHeader from '../components/library/headers/LibraryPageStickyHeader'
import sortBy from 'lodash/sortBy'
import throttle from 'lodash/throttle'
import find from 'lodash/find'
import {createNewProject} from '../utils/projectUtils/createNewProject'
import {getCollectionsForProject} from '../utils/collections/getCollectionsForProject'
import { updateLastVisitedLibraryRoute,updatePageSettings } from '../actions/librarySettings'
import UpdatesMajorReleaseBanner from '../components/changelog/UpdatesMajorReleaseBanner'

//sort options are newest, oldest, a-z, z-a
const DEFAULT_SETTINGS={
	sortOption: 'newest',
	displayMode:'grid'//grid or list
}


class LibraryPageContainer extends Component {
	state = {
		searchQuery: ''
	};
 
	componentDidMount() {
    this.mainContainer = document.querySelector('.libraryPage-mainContainer');
    this.stickyHeader = document.querySelector('.libraryPage-stickyHeader');
    this.mainContainer.addEventListener('scroll', this.handleScroll);
    this.updateLastVisitedRoute();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateLastVisitedRoute();
      this.setState({searchQuery:''})
    }
  }

  componentWillUnmount() {
    this.mainContainer.removeEventListener('scroll', this.handleScroll);
  }

  updateLastVisitedRoute = () => {
    const { location, updateLastVisitedLibraryRoute } = this.props;
  	updateLastVisitedLibraryRoute(location.pathname);
  }

  handleScroll = throttle(() => {
    const scrollTop = this.mainContainer.scrollTop;
    const startFade = 50;
    const endFade = 50;
    let opacity = 0;
    if (scrollTop > startFade) {
      opacity = (scrollTop - startFade) / (endFade - startFade);
      if (opacity > 1) opacity = 1;
    }
    this.stickyHeader.style.opacity = opacity;
  }, 5)

	handleSearchChange = (event) => {
		this.setState({ searchQuery: event.target.value });
	};


	handleSortChange = (sortOption) => {
    this.updatePageSettings('sortOption',sortOption);
  };

  handleDisplayModeChange = (displayMode) => {
    this.updatePageSettings('displayMode',displayMode);
  };


	updatePageSettings = (setting,value) => {
		const { location, updatePageSettings } = this.props;
		updatePageSettings(location.pathname, setting,value);
	};


	filterProjects = (projects) => {
		const { searchQuery } = this.state;
		if (!searchQuery) return projects;
		return projects.filter(project => 
			project.name.toLowerCase().includes(searchQuery.toLowerCase())
		);
	};

	createNewDraft=()=>{
		createNewProject(this.props.history)
	}

	createNewProjectInCollection=(collectionId)=>{
		createNewProject(this.props.history,collectionId)
	}

	sortProjects = (projects,sortOption) => {
		switch (sortOption) {
			case 'newest':
				return sortBy(projects, 'updated_at').reverse();
			
			case 'oldest':
				return sortBy(projects, 'updated_at');
	
			case 'a-z':
				 return sortBy(projects, project => project.name.toLowerCase());
	
			case 'z-a':
				return sortBy(projects, project => project.name.toLowerCase()).reverse();
	
			default:
				return projects;
		}
	}

	getCurrentPageSettings = () => {
    const { location, pageSettings } = this.props;
    const pageSetting = pageSettings[location.pathname] || {}
    return {
			sortOption: pageSetting.sortOption || 'newest',
			displayMode:pageSetting.displayMode || 'grid'//grid or list
    }
  }

	render() {
	//	const { sortOption, displayMode } = this.getCurrentPageSettings();
		const { sortOption } = this.getCurrentPageSettings();

		const displayMode = 'list'
		const { projects, match, activeCollection } = this.props;
    const { params } = match;
    let libraryPageType = 'home' //home, collection or subpage. 
    let subPage //drafts or deleted
    let collectionId
    if (params.id) {
      libraryPageType = 'collection';
      collectionId=params.id
    } else if (params.subPage) {
      libraryPageType = 'subpage'
      subPage=params.subPage
    }
		let filteredProjects = this.filterProjects(projects)
		const sortedProjects = this.sortProjects(filteredProjects,sortOption)

		let pageProjects =filteredProjects
		if(subPage=='drafts'){
			pageProjects=filteredProjects.filter(project => 
				project.is_private==true
			)
		}
		if(subPage=='org'){
			pageProjects=filteredProjects.filter(project => 
				project.is_private==false
			)
		}

		if(subPage=='deleted'){
			pageProjects = this.filterProjects(this.props.archivedProjects)
		}
		else if (collectionId) {
			pageProjects = filteredProjects.filter(project => {
			const projectCollections = getCollectionsForProject(project.id);
			return projectCollections.includes(collectionId);
		});
		}
		const sortedPageProjects = this.sortProjects(pageProjects,sortOption)
		//const showMajorReleaseBanner = !this.props.userSettings.updateBannerSlidesDismissed
		const showMajorReleaseBanner = false

		//For now lets prevent changing display mode and only show list view
		const allowChangeDisplayMode = false

		return(
			<div className={'libraryPage ' + (libraryPageType === 'home' ? 'libraryPage--sub' : 'libraryPage--home')}>
			
								
				<UpdatesMajorReleaseBanner 
	      	animateIn={true}
	      	showMajorReleaseBanner={showMajorReleaseBanner}
	      />
									  


				<div className='libraryPage-sideMenuContainer'>
					<LibrarySideMenu 
						libraryPageType={libraryPageType}
						subPage={subPage}
						collectionId={collectionId}
						collections={this.props.collections}
					/>
				</div>
				<div className='libraryPage-mainOuterContainer'>

					<LibraryPageStickyHeader 
						isHome={libraryPageType === 'home'}
						subPage={subPage}
						isCollection={libraryPageType === 'collection'}					
						activeCollection={activeCollection}
						searchQuery={this.state.searchQuery}
						handleSearchChange={this.handleSearchChange}
						createNewDraft={this.createNewDraft}
						sortOption={sortOption}
						handleSortChange={this.handleSortChange}
						displayMode={displayMode}
						setDisplayMode={this.handleDisplayModeChange}
						createNewProjectInCollection={this.createNewProjectInCollection}
						allowChangeDisplayMode={allowChangeDisplayMode}
					/>
				
					<div className='libraryPage-mainContainer'>
						
						<LibraryPageHeader 
							isHome={libraryPageType === 'home'}
							subPage={subPage}
							isCollection={libraryPageType === 'collection'}					
							activeCollection={activeCollection}
							searchQuery={this.state.searchQuery}
							handleSearchChange={this.handleSearchChange}
							createNewDraft={this.createNewDraft}
							sortOption={sortOption}
							handleSortChange={this.handleSortChange}
							displayMode={displayMode}
							setDisplayMode={this.handleDisplayModeChange}
							createNewProjectInCollection={this.createNewProjectInCollection}
							allowChangeDisplayMode={allowChangeDisplayMode}
						/>
						

						{libraryPageType === 'home' &&
							<>
							<LibraryHomePage 
								projects={sortedProjects}
								sortOption={sortOption}
								handleSortChange={this.handleSortChange}
								displayMode={displayMode}
								
							/>
							</>
						}

						{libraryPageType === 'collection' &&
							<>								
								<LibraryCollectionPage 
									projects={sortedPageProjects}
									sortOption={sortOption}
									handleSortChange={this.handleSortChange}									
									displayMode={displayMode}
								/>
							</>
						}

						{libraryPageType === 'subpage' &&
							<>								
								<LibrarySubPage 
									projects={sortedPageProjects}
									sortOption={sortOption}
									handleSortChange={this.handleSortChange}
									subPage={subPage}
									displayMode={displayMode}
								/>
							</>
						}

					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const projects = state.projects 
	const sortedProjects = sortBy(projects,'updated_at').reverse()

	const subscriptionStatus=state.organization.subscription_status

	let activeCollection 

	if(ownProps.match.params.id ){
		activeCollection=find(state.collections,{id:ownProps.match.params.id})

	}


	return {
		projects:sortedProjects,
		organization:state.organization, //to force rerender when org loads
		downloads:state.downloads, //to force rerender
		subscriptionStatus:subscriptionStatus,
		collections:state.collections,
		activeCollection:activeCollection,
		archivedProjects:state.archivedProjects,
		pageSettings: state.librarySettings.pageSettings,
		userSettings:state.userSettings
	}
}

export default withRouter(connect(
	mapStateToProps,
	{updateLastVisitedLibraryRoute,updatePageSettings})(LibraryPageContainer))



import { calculateCameraBoxSequence } from '../zoom/calculateCameraBoxSequence';
import { calculateLerpCameraBoxSequence } from '../zoom/calculateLerpCameraBoxSequence';
import { calculateAnimatedCameraBoxFrames } from '../zoom/calculateAnimatedCameraBoxFrames';
import { calculateLerpAnimatedCameraBoxFrames } from '../zoom/calculateLerpAnimatedCameraBoxFrames';
import { getZoomAnimationType } from '../../../utils/brands/getZoomAnimationType';

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const defaultCameraBox = {
  width: 1920,
  height: 1080,
  x: 0,
  y: 0
};

// Animation type constant - change this to switch between animation types
//const ZOOM_ANIMATION_TYPE = 'spring'; // options: 'spring' or 'lerp'

export const calculateVideoZoom = (zoomClips, currentClipId, currentClipStartTime, currentClipDuration) => {
 
  const zoomAnimationType = getZoomAnimationType()

  const currentClipEndTime = currentClipStartTime + currentClipDuration;
  const zoomTimeCheck = 7;
  
  const filteredZoomClips = zoomClips
    .filter(clip => {
      const zoomClipStartTime = clip.startTime;
      const zoomClipEndTime = clip.startTime + clip.duration;
      return (
        (zoomClipStartTime >= currentClipStartTime - zoomTimeCheck && zoomClipStartTime <= currentClipEndTime + zoomTimeCheck) ||
        (zoomClipEndTime >= currentClipStartTime - zoomTimeCheck && zoomClipEndTime <= currentClipEndTime + zoomTimeCheck) ||
        (zoomClipStartTime <= currentClipStartTime - zoomTimeCheck && zoomClipEndTime >= currentClipEndTime + zoomTimeCheck)
      );
    })
    .slice()
    .sort((a, b) => a.startTime - b.startTime);
  
  // Use the appropriate sequence calculator based on animation type
  let newCameraBoxSequence;
  if (zoomAnimationType === 'lerp') {
    newCameraBoxSequence = calculateLerpCameraBoxSequence(filteredZoomClips, currentClipId);
  } else {
    newCameraBoxSequence = calculateCameraBoxSequence(filteredZoomClips, currentClipId);
  }
  
  const adjustedSequence = {};
  Object.entries(newCameraBoxSequence).forEach(([time, value]) => {
    const adjustedTime = parseFloat(time) < 0 ? 0 : parseFloat(time);
    adjustedSequence[adjustedTime] = value;
  });
  
  const cleanedCameraBoxSequence = Object.fromEntries(
    Object.entries(adjustedSequence).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
  );
  
  // Calculate frames using the appropriate animator
  let animatedCameraBoxFrames;
  if (zoomAnimationType === 'lerp') {
    animatedCameraBoxFrames = calculateLerpAnimatedCameraBoxFrames(cleanedCameraBoxSequence, filteredZoomClips);
  } else {
    animatedCameraBoxFrames = calculateAnimatedCameraBoxFrames(cleanedCameraBoxSequence, filteredZoomClips);
  }
  
  const zoomFrames = animatedCameraBoxFrames.map(frame => ({
    time: frame.time,
    scale: 1 / (frame.width / SCENE_WIDTH),
    originX: frame.x * (1 / (frame.width / SCENE_WIDTH)),
    originY: frame.y * -1 * (1 / (frame.width / SCENE_WIDTH))
  }));
  
  return zoomFrames;
};
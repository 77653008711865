import {setFilesToBeDownloaded} from '../../actions/downloads'
import store from '../../store'


export async function downloadMissingWebcamRecordings(webcamRecordings) {
	const recordingsList = await ipcRenderer.invoke('get-webcam-recordings-list'); 
	for (const recording of webcamRecordings) {
		const captureId=recording.capture_id
		if (!recordingsList.includes(captureId)) {
			ipcRenderer.invoke('download-webcam-recording-files',captureId); 
			// store.dispatch({ type: 'SET_WEBCAM_FILE_TO_DOWNLOAD', captureId})
			const isDevice = false
			const isScreenRecording = false
			const isWebcam = true
			store.dispatch(setFilesToBeDownloaded(captureId,isDevice,isScreenRecording,isWebcam))
		}else{
		//	console.log('we already have the recording ')
		}
	}
}




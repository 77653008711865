import React, { useState, useRef, useEffect } from 'react';
import {getCollectionForId} from '../../../utils/collections/getCollectionForId'
import * as Tooltip from '@radix-ui/react-tooltip';

function LibraryProjectListItemCollectionDot({collection}) {  

  return (

    <div className={'libraryPage-projectListItem-collections-dot ' + (collection.color ? ` libraryPage-projectListItem-collections-dot--${collection.color} ` : ``)}>        
      <div className='libraryPage-projectListItem-collections-dot-dot' /> 
      <div className='libraryPage-projectListItem-collections-dot-tooltipContainer'>
        <div className='libraryPage-projectListItem-collections-dot-tooltip'>
          <div className='libraryPage-projectListItem-collections-dot-tooltip-label'>
            {collection.title}
          </div>
          <div className='libraryPage-projectListItem-collections-dot-tooltip-bg'/>
        </div>
      </div>
    </div>

  );
}


function LibraryProjectListItemCollections({collectionIds}) {

  let collections = []
  collectionIds.forEach((collectionId)=>{
    const collection = getCollectionForId(collectionId)
    if(collection){
       collections.push(collection)
    }
   
  })
  // console.log(collections)

  return (
      <div className='libraryPage-projectListItem-collectionsContainer'>
        <div className='libraryPage-projectListItem-collections'>
        {collections
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((collection) => (
          <LibraryProjectListItemCollectionDot 
            key={collection.id} 
            collection={collection}            
          />
        ))}   
        </div>
      </div>          
  );
}

export default LibraryProjectListItemCollections

import { useState, useCallback,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addProjectToCollection, removeProjectFromCollection } from '../../../actions/collections';
import { updateProjectPrivateStatus } from '../../../actions/projects'
import {showToastNotification} from '../../../actions/toastNotification'
import {getCollectionForId} from '../../../utils/collections/getCollectionForId'
import {getCollectionsForProject} from '../../../utils/collections/getCollectionsForProject'


export const useMoveToCollectionPopover = (project) => {
	const [showMoveToPopover, setShowMoveToPopover] = useState(false);  
	// const [pendingChanges, setPendingChanges] = useState(new Set(getCollectionsForProject(project.id)));
	const [pendingChanges, setPendingChanges] = useState(
		new Set(project ? getCollectionsForProject(project.id) : [])
	);
	const dispatch = useDispatch();
 
	const handleCollectionChange = useCallback((collectionId) => {
   setPendingChanges(prevChanges => {
     const newChanges = new Set(prevChanges);
     if (newChanges.has(collectionId)) {
       newChanges.delete(collectionId);
     } else {
       newChanges.add(collectionId);
     }
     return newChanges;
   });
 }, []);


	const saveMoveToCollectionChanges = ()=>{
		const currentCollections = getCollectionsForProject(project.id) || [];
		const toAdd = [...pendingChanges].filter(id => !currentCollections.includes(id));
		const toRemove = currentCollections.filter(id => !pendingChanges.has(id));
		toAdd.forEach(id => dispatch(addProjectToCollection(project.id, id)));
		toRemove.forEach(id => dispatch(removeProjectFromCollection(project.id, id)));
		if (project.is_private && pendingChanges.size > 0) {
			dispatch(updateProjectPrivateStatus(project.id, false));
		}
		createNotification(toAdd,toRemove)
	}



const createNotification = (toAdd, toRemove) => {
	let toastType;
	let data = { projectName: project.name };

	if (toAdd.length > 0 || (toAdd.length > 0 && toRemove.length > 0)) {
		if (toAdd.length === 1) {
			const collectionInfo = getCollectionForId(toAdd[0]);
			toastType = 'addToSingleCollection';
			data = {
				...data,
				collectionName: collectionInfo.title,
				collectionColor: collectionInfo.color
			};
		} else if (toAdd.length === 2) {
			const collection1Info = getCollectionForId(toAdd[0]);
			const collection2Info = getCollectionForId(toAdd[1]);
			toastType = 'addToTwoCollections';
			data = {
				...data,
				collection1Name: collection1Info.title,
				collection1Color: collection1Info.color,
				collection2Name: collection2Info.title,
				collection2Color: collection2Info.color
			};
		} else {
			toastType = 'addToMoreThanTwoCollections';
		}
	} else if (toAdd.length === 0 && toRemove.length > 0) {
		if (toRemove.length === 1) {
			const collectionInfo = getCollectionForId(toRemove[0]);
			toastType = 'removeFromSingleCollection';
			data = {
				...data,
				collectionName: collectionInfo.title,
				collectionColor: collectionInfo.color
			};
		} else {
			toastType = 'removeFromMultipleCollections';
		}
	}

	if (toastType) {
		dispatch(showToastNotification(toastType, data));
	}
}

	return {
		showMoveToPopover,
		setShowMoveToPopover,
		pendingChanges,
		handleCollectionChange,
		saveMoveToCollectionChanges
	};
};
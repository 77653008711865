const MAX_CURSOR_SPEED_PIXELS_PER_SECOND = 5000;
const MIN_CURSOR_SPEED_PIXELS_PER_SECOND = 2000;
const DISTANCE_THRESHOLD_LOW = 300;
const DISTANCE_THRESHOLD_HIGH = 1000;
const MIN_TRANSITION_TIME = 0;
//const MAX_TRANSITION_TIME = 2.5;
const MAX_TRANSITION_TIME = 5;

// Calculate max speed based on distance
const calculateMaxSpeedForDistance = (distance) => {
  if (distance <= DISTANCE_THRESHOLD_LOW) {
    return MIN_CURSOR_SPEED_PIXELS_PER_SECOND;
  } else if (distance >= DISTANCE_THRESHOLD_HIGH) {
    return MAX_CURSOR_SPEED_PIXELS_PER_SECOND;
  } else {
    // Linear interpolation between MIN and MAX speed based on distance
    const distanceRatio = (distance - DISTANCE_THRESHOLD_LOW) / (DISTANCE_THRESHOLD_HIGH - DISTANCE_THRESHOLD_LOW);
    return MIN_CURSOR_SPEED_PIXELS_PER_SECOND + distanceRatio * (MAX_CURSOR_SPEED_PIXELS_PER_SECOND - MIN_CURSOR_SPEED_PIXELS_PER_SECOND);
  }
};

// Existing calculation functions remain unchanged
const calculateRequiredTransitionTime = (startPoint, endPoint) => {
  const dx = endPoint.x - startPoint.x;
  const dy = endPoint.y - startPoint.y;
  const distance = Math.sqrt(dx * dx + dy * dy);
  
  // Use distance-based max speed instead of fixed value
  const maxSpeed = calculateMaxSpeedForDistance(distance);
  
  const timeAtMaxSpeed = distance / maxSpeed;
  
  return Math.min(MAX_TRANSITION_TIME, Math.max(MIN_TRANSITION_TIME, timeAtMaxSpeed));
};

const calculateCurvedPathPoint = (startPoint, endPoint, normalizedPosition, curvature = 0.3) => {
  // For a lopsided curve, we'll use a quadratic bezier curve approach
  // with the control point positioned to create the desired curve shape
  
  // Calculate the midpoint between start and end
  const midX = (startPoint.x + endPoint.x) / 2;
  const midY = (startPoint.y + endPoint.y) / 2;
  
  // Calculate the vector from start to end
  const dx = endPoint.x - startPoint.x;
  const dy = endPoint.y - startPoint.y;
  const pathLength = Math.sqrt(dx * dx + dy * dy);
  
  if (pathLength < 0.001) {
    // Points are too close, just return linear interpolation
    return {
      x: startPoint.x + dx * normalizedPosition,
      y: startPoint.y + dy * normalizedPosition
    };
  }
  
  // Calculate perpendicular vector
  const perpX = -dy / pathLength;
  const perpY = dx / pathLength;
  
  // Position the control point at 70% along the path from start to end
  // and offset it perpendicular to create the curve
  const controlPointPosition = 0.7; // 70% along the path
  const controlX = startPoint.x + dx * controlPointPosition + perpX * pathLength * curvature;
  const controlY = startPoint.y + dy * controlPointPosition + perpY * pathLength * curvature;
  
  // Quadratic bezier formula: B(t) = (1-t)²P₀ + 2(1-t)tP₁ + t²P₂
  // where P₀ is start point, P₁ is control point, P₂ is end point, and t is normalizedPosition
  const t = normalizedPosition;
  const oneMinusT = 1 - t;
  
  // Calculate the point on the bezier curve
  const bezierX = oneMinusT * oneMinusT * startPoint.x + 
                 2 * oneMinusT * t * controlX + 
                 t * t * endPoint.x;
                 
  const bezierY = oneMinusT * oneMinusT * startPoint.y + 
                 2 * oneMinusT * t * controlY + 
                 t * t * endPoint.y;
  
  // Add a small random factor for more natural movement
  const randomFactor = 0.006;
  const randomX = (Math.random() * 2 - 1) * pathLength * randomFactor * t * oneMinusT; // Max at middle
  const randomY = (Math.random() * 2 - 1) * pathLength * randomFactor * t * oneMinusT;
  
  return {
    x: bezierX + randomX,
    y: bezierY + randomY
  };
};

export const getTrimInterpolatedCursorData = async (
  normalizedCursorData,
  skipSegments
) => {
  const FPS = 60;
    
  // If there are no skip segments, return the data as is
  if (!skipSegments || skipSegments.length === 0) {
   // console.log('No skip segments found, returning original cursor data');
    return normalizedCursorData;
  }
  
  // console.log('skipSegments')
  // console.log(skipSegments)

  // Sort skip segments by start time
  skipSegments.sort((a, b) => a.start - b.start);
  
  // First step: analyze the cursor data to identify the points at each side of the skip
  let analyzedSegments = skipSegments.map((segment, index) => {
    // Find the points just outside the skip segment
    const pointBeforeSkip = normalizedCursorData
      .filter(p => p.time < segment.start)
      .sort((a, b) => b.time - a.time)[0];
      
    const pointAfterSkip = normalizedCursorData
      .filter(p => p.time > segment.end)
      .sort((a, b) => a.time - b.time)[0];
    
    // Skip segments at the very beginning or end
    if (!pointBeforeSkip || !pointAfterSkip) {
    //  console.log(`Skipping interpolation for segment at ${segment.start}-${segment.end} - at data boundary`);
      return {
        ...segment,
        validForInterpolation: false
      };
    }
    
    // Calculate distance between the points
    const dx = pointAfterSkip.x - pointBeforeSkip.x;
    const dy = pointAfterSkip.y - pointBeforeSkip.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    // Calculate the required transition time based on max speed
    const transitionTime = calculateRequiredTransitionTime(
      pointBeforeSkip, 
      pointAfterSkip
    );
    
    // console.log(`Skip segment ${segment.start.toFixed(2)}s - ${segment.end.toFixed(2)}s:`);
    // console.log(`  Distance between points: ${distance.toFixed(1)} pixels`);
    // console.log(`  Max speed for this distance: ${calculateMaxSpeedForDistance(distance).toFixed(1)} pixels/second`);
    // console.log(`  Required transition time: ${transitionTime.toFixed(2)}s`);
    
    // Calculate the time boundaries for the before and after periods
    const beforePeriodStart = segment.start - transitionTime;
    const beforePeriodEnd = segment.start;
    const afterPeriodStart = segment.end;
    const afterPeriodEnd = segment.end + transitionTime;
    
    // Find the points at the boundaries of the transition periods
    const pointAtBeforePeriodStart = normalizedCursorData
      .filter(p => p.time <= beforePeriodStart)
      .sort((a, b) => b.time - a.time)[0] || pointBeforeSkip; // Fallback to pointBeforeSkip if not found
      
    const pointAtAfterPeriodEnd = normalizedCursorData
      .filter(p => p.time >= afterPeriodEnd)
      .sort((a, b) => a.time - b.time)[0] || pointAfterSkip; // Fallback to pointAfterSkip if not found
    
    return {
      ...segment,
      index,
      beforePoint: pointBeforeSkip,
      afterPoint: pointAfterSkip,
      beforePeriodStartPoint: pointAtBeforePeriodStart,
      afterPeriodEndPoint: pointAtAfterPeriodEnd,
      distance,
      transitionTime,
      beforePeriodStart,
      beforePeriodEnd,
      afterPeriodStart,
      afterPeriodEnd,
      validForInterpolation: true
    };
  });
  
  // Filter out any segments that couldn't be analyzed properly
  const validSegments = analyzedSegments.filter(segment => segment.validForInterpolation);
  
  // Now adjust the before/after periods based on adjacent segments
  const adjustedSegments = validSegments.map((segment, index) => {
    let adjustedSegment = { ...segment };
    
    // Check if there's a next segment to compare with
    if (index < validSegments.length - 1) {
      const nextSegment = validSegments[index + 1];
      
      // Calculate the midpoint between this segment's end and the next segment's start
      const midpointTime = (segment.end + nextSegment.start) / 2;
      
      // If our afterPeriodEnd extends beyond the midpoint, cap it
      if (segment.afterPeriodEnd > midpointTime) {
       // console.log(`Adjusting afterPeriodEnd for segment ${index} from ${segment.afterPeriodEnd.toFixed(2)}s to ${midpointTime.toFixed(2)}s (midpoint to next segment)`);
        adjustedSegment.afterPeriodEnd = midpointTime;
      }
    }
    
    // Check if there's a previous segment to compare with
    if (index > 0) {
      const prevSegment = validSegments[index - 1];
      
      // Calculate the midpoint between this segment's start and the previous segment's end
      const midpointTime = (prevSegment.end + segment.start) / 2;
      
      // If our beforePeriodStart extends before the midpoint, cap it
      if (segment.beforePeriodStart < midpointTime) {
      //  console.log(`Adjusting beforePeriodStart for segment ${index} from ${segment.beforePeriodStart.toFixed(2)}s to ${midpointTime.toFixed(2)}s (midpoint from previous segment)`);
        adjustedSegment.beforePeriodStart = midpointTime;
      }
    }
    
    return adjustedSegment;
  });
  
  // Now remove original data points that fall within the before/after periods and skip segments
  let cleanedCursorData = normalizedCursorData.filter(point => {
    for (const segment of adjustedSegments) {
      // If the point falls in our target range, remove it
      if (point.time >= segment.beforePeriodStart && point.time <= segment.afterPeriodEnd) {
        return false;
      }
    }
    return true;
  });
  
 // console.log(`Removed ${normalizedCursorData.length - cleanedCursorData.length} original points from transition periods`);
  
  // Now add interpolated points for each skip segment
  for (const segment of adjustedSegments) {
    // Count how many points we generate in each period
    let beforePointsCount = 0;
    let afterPointsCount = 0;
    
    // Determine curvature based on distance
    const pathLength = segment.distance;
    const baseCurvature = 0.12;
    
    // console.log(`Interpolating between points over total duration: ${(segment.afterPeriodEnd - segment.beforePeriodStart).toFixed(2)}s`);
    // console.log(`Before period: ${segment.beforePeriodStart.toFixed(2)}s - ${segment.beforePeriodEnd.toFixed(2)}s (${(segment.beforePeriodEnd - segment.beforePeriodStart).toFixed(2)}s duration)`);
    // console.log(`After period: ${segment.afterPeriodStart.toFixed(2)}s - ${segment.afterPeriodEnd.toFixed(2)}s (${(segment.afterPeriodEnd - segment.afterPeriodStart).toFixed(2)}s duration)`);
    // console.log(`Start point: time=${segment.beforePoint.time.toFixed(2)}s, x=${segment.beforePoint.x.toFixed(1)}, y=${segment.beforePoint.y.toFixed(1)}`);
    // console.log(`End point: time=${segment.afterPoint.time.toFixed(2)}s, x=${segment.afterPoint.x.toFixed(1)}, y=${segment.afterPoint.y.toFixed(1)}`);
    
    // Create interpolated points every 0.01 seconds
    const stepSize = 0.01; // seconds
    const totalTransitionDuration = segment.afterPeriodEnd - segment.beforePeriodStart;
    
    for (let t = segment.beforePeriodStart; t <= segment.afterPeriodEnd; t += stepSize) {
      // Skip if this is in the original skip segment (we only want to interpolate before and after periods)
      if (t > segment.beforePeriodEnd && t < segment.afterPeriodStart) {
        continue;
      }
      
      // Calculate normalized position across the entire range (from beginning of before to end of after)
      // We need to account for the gap in the middle (the skip segment)
      const beforeDuration = segment.beforePeriodEnd - segment.beforePeriodStart;
      const afterDuration = segment.afterPeriodEnd - segment.afterPeriodStart;
      const totalActiveDuration = beforeDuration + afterDuration;
      
      // Calculate how far we are in the active periods
      let progressInActivePeriods;
      
      if (t <= segment.beforePeriodEnd) {
        // We're in the before period
        progressInActivePeriods = (t - segment.beforePeriodStart) / totalActiveDuration;
      } else {
        // We're in the after period
        // Add the full before duration plus how far we are in the after period
        progressInActivePeriods = beforeDuration / totalActiveDuration + 
                                 (t - segment.afterPeriodStart) / totalActiveDuration;
      }
      
      // Use curved path interpolation from the point at the start of the before period
      // to the point at the end of the after period
      const curvedPoint = calculateCurvedPathPoint(
        segment.beforePeriodStartPoint,
        segment.afterPeriodEndPoint,
        progressInActivePeriods,
        baseCurvature
      );
      
      // Create interpolated point
      const interpolatedPoint = {
        time: t,
        x: curvedPoint.x,
        y: curvedPoint.y,
        cursorType: segment.beforePoint.cursorType
      };
      
      // Count the point based on which period it falls in
      if (t >= segment.beforePeriodStart && t <= segment.beforePeriodEnd) {
        beforePointsCount++;
      } else if (t >= segment.afterPeriodStart && t <= segment.afterPeriodEnd) {
        afterPointsCount++;
      }
      
      // Add to our result array
      cleanedCursorData.push(interpolatedPoint);
    }
    
  //  console.log(`Added ${beforePointsCount} interpolated points to before period (${(segment.beforePeriodEnd - segment.beforePeriodStart).toFixed(2)}s duration)`);
  //  console.log(`Added ${afterPointsCount} interpolated points to after period (${(segment.afterPeriodEnd - segment.afterPeriodStart).toFixed(2)}s duration)`);
  }
  
  // Sort by time to ensure proper sequence
  cleanedCursorData.sort((a, b) => a.time - b.time);
  
 // console.log(`Final cursor data has ${cleanedCursorData.length} points (cleaned original + interpolated)`);
  
  return cleanedCursorData;
};
import { ExportTimelineClip } from './ExportTimelineClip'
import { calculateCaptionWordGroupPositions } from '../../timeline/utils/calculateCaptionWordGroupPositions'
import { calculateSubtitleGroupWordPositions } from '../../timeline/utils/calculateSubtitleGroupWordPositions'

//For server side export the audio clip doesnt actually have audio (we add audio later with ffmpeg)
// instead it is there for the subtitles/captions
// when generating a project with variable audio the backend makes unprocessedSubtitlesGroups
// the client then needs to "process" them by measuring the text and working out postions before we render it


class ExportAudioClip extends ExportTimelineClip {
  constructor(options, scene,aspectRatio) {
    super(options, scene)
    this.scene = scene
    this.aspectRatio=aspectRatio
    this.loadPromise = this.processCaptionGroups()
  }

  getSceneDimensions=()=>{
		let sceneWidth = 1920
		let sceneHeight = 1080
		if(this.aspectRatio === '16_10'){
			sceneWidth = 1920
			sceneHeight = 1200
		}
		return {sceneWidth,sceneHeight}
	}


  ///TODO test the process caption groups stuff with aspect ration
   async processCaptionGroups() {
    const {sceneWidth,sceneHeight} = this.getSceneDimensions()
    if (this.metadata.unprocessedSubtitlesGroups && this.metadata.unprocessedSubtitlesGroups.length > 0) {
     // console.log('Processing unprocessed caption groups')
      try {
        const updatedSubtitlesGroups = this.metadata.unprocessedSubtitlesGroups.map(async (group) => {
          return await calculateSubtitleGroupWordPositions(group,sceneWidth,sceneHeight)
        })
        const processedGroups = await Promise.all(updatedSubtitlesGroups)
        this.metadata.subtitlesGroups = processedGroups
        this.metadata.unprocessedSubtitlesGroups = null // Clear the unprocessed groups
     //   console.log('Updated caption groups', this.metadata.captionGroups)
      } catch (error) {
   //     console.error('Error processing caption groups:', error)
        // Don't throw the error, as we want the promise to resolve even if processing fails
      }
    } else {
   //   console.log('No unprocessed caption groups to process')
    }
    // Always resolve the promise, even if there were no captions to process or if processing failed
    return Promise.resolve()
  }

  destroy() {
    // Add any cleanup logic here if needed
  }
}

export { ExportAudioClip };
import { convertZoomValuesToZoomBox } from './convertZoomValuesToZoomBox';
import { calculateManualZoomSequence } from './manualZoomSequence';

const FPS = 60;
const ANIMATION_TIME_FACTOR = 0.7;

const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const defaultCameraBox = {
    width: 1920,
    height: 1080,
    x: 0,
    y: 0,
    motionSettings: 'zoomSmooth',
};

// Baseline animation durations for lerp animations
const lerpDurationMap = {
    zoomSmooth: 1.2,
    zoomZippy: 0.4,
    zoomSlow: 1.2,
    zoomInstant: 0
};

/**
 * Calculates time needed to animate between two camera boxes using lerp
 * Scales based on the magnitude of change in position and zoom
 */
function calculateLerpTransitionDuration(fromBox, toBox, motionSettings) {
    if (motionSettings === 'zoomInstant') return 0;
    
    // Base duration from the motion settings
    const baseDuration = lerpDurationMap[motionSettings] || lerpDurationMap.zoomSmooth;
    
    // Calculate scale difference (using width as proxy for scale)
    const scaleRatio = fromBox.width / toBox.width;
    const scaleFactor = Math.abs(Math.log2(scaleRatio)); // Log scale for more natural timing
    
    // Calculate position change as percentage of frame
    const xDiff = Math.abs(fromBox.x - toBox.x) / SCENE_WIDTH;
    const yDiff = Math.abs(fromBox.y - toBox.y) / SCENE_HEIGHT;
    const positionFactor = Math.max(xDiff, yDiff) * 2; // Position changes impact transition time
    
    // Combined factor (weighted more toward scale)
    const combinedFactor = (scaleFactor * 0.7) + (positionFactor * 0.3);
    
    // Calculate time with a minimum transition time
    return Math.max(baseDuration * (1 + combinedFactor), 0.2);
}

/**
 * Calculates an adjusted start time for a zoom clip with lerp animation
 */
function calculateLerpAdjustedStartTime(clip, defaultBox, lastEndTime) {
    const { startTime, metadata: { zoomValues } } = clip;
    const initialCameraBox = convertZoomValuesToZoomBox({
        originX: zoomValues.originX,
        originY: zoomValues.originY,
        scale: zoomValues.scale
    });

    // Calculate time to animate from default to this zoom state
    const transitionDuration = calculateLerpTransitionDuration(
        defaultBox,
        initialCameraBox,
        zoomValues.motionSettings
    );

    // Apply animation time factor - this will start the animation early enough
    // so that it completes just as the clip should begin
    return Math.max(startTime - (transitionDuration * ANIMATION_TIME_FACTOR), lastEndTime + (1 / FPS));
}

export const calculateLerpCameraBoxSequence = (zoomClips, currentClipId) => {
    console.log('Starting calculateLerpCameraBoxSequence with clips:', zoomClips);
    
    const cameraBoxSequence = {};

    // Ensure we have a default camera box at time 0
    const sortedZoomClips = [...zoomClips].sort((a, b) => a.startTime - b.startTime);
    if (!sortedZoomClips.length || sortedZoomClips[0].startTime >= 0.05) {
        cameraBoxSequence[0] = { ...defaultCameraBox };
    }

    let lastEndTime = 0;

    for (let i = 0; i < sortedZoomClips.length; i++) {
        const currentClip = sortedZoomClips[i];
        const nextClip = sortedZoomClips[i + 1];
        
        const { id, startTime, duration, metadata: { zoomValues }, scene: currentScene } = currentClip;
        
        console.log(`\nProcessing clip ${id} at index ${i}:`, {
            clipId: id,
            startTime,
            duration,
            sceneId: currentScene.id,
            zoomValues
        });
        
        // Original clip timing
        const clipStartTime = startTime;
        let clipEndTime = startTime + duration;

        // Adjust the end time if this clip overlaps with the next clip
        if (nextClip && 
            nextClip.scene.id === currentScene.id && 
            clipEndTime > nextClip.startTime) {
            clipEndTime = nextClip.startTime - (1 / FPS);
            console.log(`Adjusted clipEndTime due to overlap with next clip: ${clipEndTime}`);
        }

        // Calculate the target camera box for this clip
        const targetCameraBox = convertZoomValuesToZoomBox({
            originX: zoomValues.originX,
            originY: zoomValues.originY,
            scale: zoomValues.scale
        });
        
        console.log('Target camera box:', targetCameraBox);

        // Calculate animation durations
        const entryAnimationDuration = calculateLerpTransitionDuration(
            defaultCameraBox,
            targetCameraBox,
            zoomValues.motionSettings
        );
        
        const exitAnimationDuration = calculateLerpTransitionDuration(
            targetCameraBox,
            defaultCameraBox,
            zoomValues.endMotionSettings || zoomValues.motionSettings
        );
        
        console.log('Animation durations:', {
            entryDuration: entryAnimationDuration,
            exitDuration: exitAnimationDuration
        });

        // Calculate animation timing:
        // Entry animation ENDS exactly at clip start time
        const entryAnimationStartTime = clipStartTime - entryAnimationDuration;
        
        // Exit animation STARTS exactly at clip end time
        const exitAnimationEndTime = clipEndTime + exitAnimationDuration;
        
        console.log('Animation timing:', {
            clipStartTime,
            clipEndTime,
            entryAnimationStartTime,
            exitAnimationEndTime,
            lastEndTime
        });

        // Check if we can fit the entry animation
        const canFitEntryAnimation = entryAnimationStartTime > lastEndTime;
        console.log(`Can fit entry animation: ${canFitEntryAnimation}`);
        
        if (canFitEntryAnimation) {
            // Add keyframe at the start of the entry animation
            cameraBoxSequence[entryAnimationStartTime] = {
                ...defaultCameraBox,
                motionSettings: zoomValues.motionSettings || 'zoomSmooth'
            };
            console.log(`Added entry animation start keyframe at ${entryAnimationStartTime}`);
        } else {
            // Add a keyframe at the last possible moment
            const adjustedEntryStart = lastEndTime + (1 / FPS);
            cameraBoxSequence[adjustedEntryStart] = {
                ...defaultCameraBox,
                motionSettings: zoomValues.motionSettings || 'zoomSmooth'
            };
            console.log(`Added adjusted entry animation start keyframe at ${adjustedEntryStart}`);
        }
        
        // Add keyframe at the clip start time (when entry animation ends)
        cameraBoxSequence[clipStartTime] = {
            ...targetCameraBox,
            motionSettings: zoomValues.motionSettings || 'zoomSmooth'
        };
        console.log(`Added clip start keyframe at ${clipStartTime}`);
        
        // Internal keyframes from the manual zoom sequence
        const sequence = calculateManualZoomSequence({
            ...currentClip,
            startTime: clipStartTime
        });
        
        // Add internal keyframes within this clip's time
        let internalKeyframesCount = 0;
        Object.keys(sequence).forEach(time => {
            const timeValue = parseFloat(time);
            if (timeValue > clipStartTime && timeValue < clipEndTime) {
                cameraBoxSequence[time] = sequence[time];
                internalKeyframesCount++;
            }
        });
        console.log(`Added ${internalKeyframesCount} internal keyframes`);

        // Determine if the next clip is in a different scene
        const isNextClipDifferentScene = !nextClip || nextClip.scene.id !== currentScene.id;
        
        // Check if we can add the exit animation
        const nextClipStartTime = nextClip ? nextClip.startTime : Infinity;
        const canAddExitAnimation = isNextClipDifferentScene || exitAnimationEndTime <= nextClipStartTime;
        
        console.log('Exit animation conditions:', {
            isNextClipDifferentScene,
            nextClipStartTime,
            exitAnimationEndTime,
            canAddExitAnimation
        });
        
        if (canAddExitAnimation) {
            const endMotionSettings = zoomValues.endMotionSettings || zoomValues.motionSettings || 'zoomSmooth';
            
            // Add keyframe at clip end (start of exit animation)
            cameraBoxSequence[clipEndTime] = {
                ...targetCameraBox,
                motionSettings: endMotionSettings
            };
            console.log(`Added exit animation start keyframe at ${clipEndTime}`);
            
            // Add keyframe at the end of exit animation
            cameraBoxSequence[exitAnimationEndTime] = {
                ...defaultCameraBox,
                motionSettings: endMotionSettings
            };
            console.log(`Added exit animation end keyframe at ${exitAnimationEndTime}`);
            
            // Update last end time to include exit animation
            lastEndTime = exitAnimationEndTime;
        } else {
            lastEndTime = clipEndTime;
        }
        console.log(`Updated lastEndTime to ${lastEndTime}`);
    }

    console.log('\nFinal camera box sequence:', cameraBoxSequence);
    return cameraBoxSequence;
};
import { getMasterRecordingCursorData } from "../recordings/screenRecordings/getMasterRecordingCursorData";


export async function calculateClickBasedBoundingBoxes(clip) {
    const cursorData = await getMasterRecordingCursorData(clip.captureId);
    const clicks = cursorData.filter(point => point.mouseEventType === 'down');
    // remove clicks that are too close to each other (within 0.5 seconds)
    const filteredClicks = clicks.filter((click, index, self) => {
        const previousClick = self[index - 1];
        return previousClick ? click.time - previousClick.time > 0.5 : true;
    });
    const boundingBoxes = await Promise.all(filteredClicks.map(async click => {
        const box = await calculateCursorBasedBoundingBox(clip, click.time, cursorData);
        return box;
    }));

   
    let validBoxes = boundingBoxes.filter((box, index, self) => {
        if (box === null) return false;
        const previousBox = self[index - 1];
        return previousBox ? box.startTime - previousBox.startTime > 0.5 : true;
    });
    // Trim overlapping boxes
    return trimOverlappingBoxes(validBoxes);
}

function trimOverlappingBoxes(boxes) {
    if (boxes.length <= 1) return boxes;
    
    const MIN_DURATION = 1.5;
    const result = [];
    
    for (let i = 0; i < boxes.length; i++) {
        const current = { ...boxes[i] };
        const next = boxes[i + 1];
        
        if (next && (current.startTime + current.duration > next.startTime)) {
            // Calculate overlap
            const overlap = (current.startTime + current.duration) - next.startTime;
            
            // Calculate potential durations after trimming
            const currentDurationAfterTrim = current.duration - overlap;
            const nextDurationAfterTrim = next.duration;
            
            if (currentDurationAfterTrim >= MIN_DURATION) {
                // Trim current box if it remains long enough
                current.duration = currentDurationAfterTrim;
            } else if (nextDurationAfterTrim >= MIN_DURATION) {
                // Skip current box and adjust next box's start time
                continue;
            } else {
                // If neither box would be long enough after trimming,
                // keep the one with longer original duration
                if (current.duration >= next.duration) {
                    // Keep current, skip next
                    current.duration = Math.min(current.duration, next.startTime - current.startTime);
                    i++; // Skip next box
                } else {
                    // Skip current, keep next intact
                    continue;
                }
            }
        }
        
        result.push(current);
    }
    
    return result;
}

export async function calculateCursorBasedBoundingBox(clip, clipTime, cursorData = null, MAX_AREA = 0.25*0.25) {
    if(!cursorData){
        cursorData = await getMasterRecordingCursorData(clip.captureId);
    }
    
    const TIME_WINDOW = 2;
    const ANALYSIS_WINDOW = 10;
    const MIN_DURATION = 1.5;
    const MAX_DIMENSION_RATIO = 0.5; // Maximum height/width difference

    // Get cursor data within ±2 seconds of clipTime
    const relevantCursorData = cursorData.filter(point => 
        point.time >= clipTime - TIME_WINDOW && 
        point.time <= clipTime + TIME_WINDOW
    );

    // Prioritize click events as starting points
    let startPoints = relevantCursorData.filter(point => point.mouseEventType === 'down').length > 0 ?
        relevantCursorData.filter(point => point.mouseEventType === 'down') :
        relevantCursorData;


    let bestBoundingBox = null;
    let maxDuration = 0;

    for (const startPoint of startPoints) {
        // Analyze cursor movement for the next ANALYSIS_WINDOW seconds
        const segment = cursorData.filter(point => 
            point.time >= startPoint.time && 
            point.time <= startPoint.time + ANALYSIS_WINDOW
        );

        if (segment.length < 1) continue;

        const positions = [];
        
        // Analyze each point
        for (let i = 0; i < segment.length - 1; i++) {
            const point = segment[i];
            const duration = segment[i + 1].time - point.time;
            
            positions.push({ x: point.x, y: point.y, duration });
            
            const bounds = calculateBounds(positions);
            const area = (bounds.x2 - bounds.x1) * (bounds.y2 - bounds.y1);
            const width = bounds.x2 - bounds.x1;
            const height = bounds.y2 - bounds.y1;
            const dimensionRatio = Math.max(width, height)

            // If cursor movement is within area threshold and dimension ratio
            if (area <= MAX_AREA && dimensionRatio <= MAX_DIMENSION_RATIO) {
                const duration = point.time - startPoint.time;
                if (duration > maxDuration) {
                    bestBoundingBox = {
                        boundingBox: {
                            ...bounds,
                            width: bounds.x2 - bounds.x1,
                            height: bounds.y2 - bounds.y1,
                        },
                        startTime: startPoint.time,
                        duration
                    };
                    maxDuration = duration;
                }
            } else {
                break;
            }
        }
    }

    return maxDuration >= MIN_DURATION ? bestBoundingBox : null;
}

function calculateBounds(positions) {
    return positions.reduce((bounds, p) => ({
        x1: Math.min(bounds.x1, p.x),
        y1: Math.min(bounds.y1, p.y),
        x2: Math.max(bounds.x2, p.x),
        y2: Math.max(bounds.y2, p.y)
    }), { x1: Infinity, y1: Infinity, x2: -Infinity, y2: -Infinity });
}
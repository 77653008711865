import React, { useRef, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { calculateWebcamCrop } from '../utils/calculateWebcamCrop';


const ExportWebcamVideoVideoMaterial = ({ 
  codecVideoClip, 
  opacity, 
  meshWidth, 
  meshHeight, 
  videoAssetWidth, 
  videoAssetHeight,
  zoom,
  xOffset,
  yOffset,
  renderOrder,
  colorAdjustments,
  faceBox,
  applyFaceBox,
  facePaddingRatio
}) => {
  // console.log('ExportWebcamVideoVideoMaterial',{
  //   codecVideoClip, 
  //   opacity, 
  //   meshWidth, 
  //   meshHeight, 
  //   videoAssetWidth, 
  //   videoAssetHeight,
  //   zoom,
  //   xOffset,
  //   yOffset,
  //   renderOrder,
  //   colorAdjustments,
  //   faceBox,
  //   applyFaceBox,
  // })
  const { invalidate } = useThree();
  const materialRef = useRef();
  const textureRef = useRef();
  const [updateTrigger, setUpdateTrigger] = useState(0);
  
  useEffect(() => {
    if (!textureRef.current) {
      textureRef.current = new THREE.Texture();
      textureRef.current.generateMipmaps = true;
      //if you uncomment these, the colors are all weird not super sure why
      // textureRef.current.minFilter = THREE.LinearMipmapLinearFilter;
      // textureRef.current.colorSpace = THREE.SRGBColorSpace;      
      // textureRef.current.magFilter = THREE.LinearFilter;
    }

    if (codecVideoClip) {
      codecVideoClip.setExternalTexture(textureRef.current);
      codecVideoClip.setTextureUpdatedCallback(() => {
        setUpdateTrigger(prev => prev + 1);
        invalidate();
      });
    }

    return () => {
      if (codecVideoClip) {
        codecVideoClip.setExternalTexture(null);
        codecVideoClip.setTextureUpdatedCallback(null);
      }
    };
  }, [codecVideoClip, invalidate]);

  const { exposure, whiteBalance } = colorAdjustments;

  const { cropX, cropY, cropWidth, cropHeight } = useMemo(() => {
    return calculateWebcamCrop({
      originalWidth: videoAssetWidth,
      originalHeight: videoAssetHeight,
      targetWidth: meshWidth,
      targetHeight: meshHeight,
      faceBox: faceBox,
      // zoomIn: shouldZoomIn,
      applyFaceBox:applyFaceBox,
      facePaddingRatio:facePaddingRatio
    });
  }, [
    videoAssetWidth,
    videoAssetHeight,
    faceBox,
    meshWidth,
    meshHeight,
    // shouldZoomIn
    facePaddingRatio
  ]);


  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        videoTexture: { value: textureRef.current },
        opacity: { value: 1 },
        uvScale: { value: new THREE.Vector2(1, 1) },
        uvOffset: { value: new THREE.Vector2(0, 0) },
        exposure: { value: 0 },
        whiteBalance: { value: 0 }
      },
      vertexShader: `
        uniform vec2 uvScale;
        uniform vec2 uvOffset;
        varying vec2 vUv;
        void main() {
          vUv = uv * uvScale + uvOffset;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D videoTexture;
        uniform float opacity;
        uniform float exposure;
        uniform float whiteBalance;
        varying vec2 vUv;

        vec3 adjustExposure(vec3 color, float exposure) {
          return color * pow(2.0, exposure * 0.75);
        }
        
        vec3 srgbToLinear(vec3 color) {
          return vec3(
            color.r < 0.04045 ? color.r / 12.92 : pow((color.r + 0.055) / 1.055, 2.4),
            color.g < 0.04045 ? color.g / 12.92 : pow((color.g + 0.055) / 1.055, 2.4),
            color.b < 0.04045 ? color.b / 12.92 : pow((color.b + 0.055) / 1.055, 2.4)
          );
        }

        vec3 linearToSrgb(vec3 color) {
          return vec3(
            color.r < 0.0031308 ? 12.92 * color.r : 1.055 * pow(color.r, 1.0/2.4) - 0.055,
            color.g < 0.0031308 ? 12.92 * color.g : 1.055 * pow(color.g, 1.0/2.4) - 0.055,
            color.b < 0.0031308 ? 12.92 * color.b : 1.055 * pow(color.b, 1.0/2.4) - 0.055
          );
        }

        vec3 adjustWhiteBalance(vec3 color, float temperature) {
          vec3 linearColor = srgbToLinear(color);
          float t = clamp(temperature * 0.5 + 0.5, 0.0, 1.0);
          float redAdjustment = 1.0 + (t - 0.5) * 0.3;
          float blueAdjustment = 1.0 - (t - 0.5) * 0.3;
          vec3 adjustedColor = linearColor * vec3(redAdjustment, 1.0, blueAdjustment);
          return linearToSrgb(adjustedColor);
        }

        void main() {
          vec4 videoColor = texture2D(videoTexture, vUv);
          vec3 color = videoColor.rgb;
          
          color = adjustExposure(color, exposure);
          color = adjustWhiteBalance(color, whiteBalance);
          
          gl_FragColor = vec4(color, videoColor.a * opacity);
        }
      `,
      transparent: true
    });
  }, [ updateTrigger]);



 useFrame(() => {
    if (materialRef.current && textureRef.current) {
      // The crop values are already normalized (0-1), so we can use them directly
      const finalScaleX = cropWidth;
      const finalScaleY = cropHeight;
      
      // UV offset starts from bottom-left in WebGL, so we need to flip the Y coordinate
      const offsetX = cropX;
      const offsetY = 1.0 - cropY - cropHeight;
      
      // Set the UV scale and offset uniforms
      materialRef.current.uniforms.uvScale.value.set(finalScaleX, finalScaleY);
      materialRef.current.uniforms.uvOffset.value.set(offsetX, offsetY);
      
      // Set other uniforms as before
      materialRef.current.uniforms.videoTexture.value = textureRef.current;
      materialRef.current.uniforms.opacity.value = opacity;
      materialRef.current.uniforms.exposure.value = exposure;
      materialRef.current.uniforms.whiteBalance.value = whiteBalance;
    }
  });



  // useFrame(() => {
  //   if (materialRef.current && textureRef.current) {
  //     materialRef.current.uniforms.opacity.value = opacity;
  //     const aspectRatioMesh = meshWidth / meshHeight;
  //     const aspectRatioVideo = videoAssetWidth / videoAssetHeight;
      
  //     const effectiveZoom = Math.max(1, zoom);
      
  //     let scale;
  //     if (aspectRatioMesh > aspectRatioVideo) {
  //       scale = (meshWidth / videoAssetWidth) * effectiveZoom;
  //     } else {
  //       scale = (meshHeight / videoAssetHeight) * effectiveZoom;
  //     }
      
  //     const scaledVideoWidth = videoAssetWidth * scale;
  //     const scaledVideoHeight = videoAssetHeight * scale;
      
  //     const repeatX = meshWidth / scaledVideoWidth;
  //     const repeatY = meshHeight / scaledVideoHeight;
      
  //     const centerXNormalized = xOffset / videoAssetWidth;
  //     const centerYNormalized = yOffset / videoAssetHeight;
      
  //     // const offsetX = 0.5 - (centerXNormalized * effectiveZoom + 0.5) * repeatX;
  //     // const offsetY = 0.5 - (centerYNormalized * effectiveZoom + 0.5) * repeatY;

  //     const offsetX = cropX;
  //     const offsetY = 1.0 - cropY - cropHeight;

      
  //     materialRef.current.uniforms.uvScale.value.set(repeatX, repeatY);
  //     materialRef.current.uniforms.uvOffset.value.set(offsetX, offsetY);
      
  //     materialRef.current.uniforms.videoTexture.value = textureRef.current;
  //     materialRef.current.uniforms.opacity.value = opacity;
  //     materialRef.current.uniforms.exposure.value = exposure;
  //     materialRef.current.uniforms.whiteBalance.value = whiteBalance;

  //   //  materialRef.current.needsUpdate = true;
  //   }
  // });

  useEffect(() => {
    if (materialRef.current && renderOrder !== undefined) {
      materialRef.current.renderOrder = renderOrder;
      invalidate();
    }
  }, [renderOrder, invalidate]);

  return <primitive object={shaderMaterial} ref={materialRef} attach="material" />;
};

export default ExportWebcamVideoVideoMaterial;
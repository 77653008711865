import React, { useState, useEffect, useRef } from 'react';
import Icon from '../../../../misc/Icon';
import { Rnd } from 'react-rnd'; 
import * as ContextMenu from '@radix-ui/react-context-menu';

const SegmentRightClipHandle = ({cursorStyle, onMouseDown, onMouseUp}) => (
  <div data-segment-type='freeze' style={{cursor: cursorStyle }} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={(e) => e.stopPropagation()} className='editor-timeline-clip-segment-handle'>
    <div className='editor-timeline-clip-segment-handle-inner'>
      <div className='editor-timeline-clip-segment-inner-bar' />
    </div>
    
    <div className='editor-timeline-clip-segment-handle-tooltipContainer'>
      <div className='editor-timeline-clip-segment-handle-tooltip'>
        Drag to adjust freeze-frame
      </div>
    </div>
  </div>
);


const FreezeSegment = (props) => {
  const { 
    segment, 
    totalDuration, 
    clipWidth, 
    onResize, 
    onSegmentClick,
    trimStart, 
    scrollRef, 
    handleSeek, 
    pixelsPerSec,
    removeFreeze,
    clipId, 
    yOffset,
    sceneGapOffset,
    setIsDraggingSegment
     } = props
  
  const rndRef = useRef(null); // Ref for the Rnd component

  const [cursorStyle, setCursorStyle] = useState('grab');
  const [isDragging, setIsDragging] = useState(false);

  let pixelWidth = (segment.newDuration / totalDuration) * clipWidth;
  let left = ((segment.newStart)/ totalDuration) * clipWidth + sceneGapOffset;  

  //adjust the left for the trim time
  const trimAdjustment = trimStart/totalDuration * clipWidth // need to shift segments to the left to account for start time being relative to unclipped time 

  left-=trimAdjustment  

  


  const overflowPixels = 20 // check if overflowing
  const isRightOverflow = (left + pixelWidth + overflowPixels) > clipWidth  
  const isLeftOverflow = left < overflowPixels


  
  let offsetCursor = 0

  if(isRightOverflow){
    offsetCursor = -16 // this is to prevent overflow onto the right
  }

  let offsetWidth = 0

  if(isLeftOverflow){
    offsetWidth = 8 // this is to prevent overflow onto the left
  }

  const rightHandleStyles = {
    cursor: `${cursorStyle} !important`,
    height: `13px`,
    marginTop: `6px`,    
  };

  const segmentBoundsLeft = 150
  const segmentBoundsWidth = 200
  const SHOW_BOUNDS=false


  return (
    <React.Fragment>    

      {SHOW_BOUNDS &&  
      <div 
        id={`segmentBounds-${segment.id}`}
        style={{left: `${segmentBoundsLeft}px`, width: `${segmentBoundsWidth}px`, top: `${yOffset - 20}px`}} 
        className='editor-timeline-clip-segmentBounds' 
      />
    }

      <ContextMenu.Root key={segment.id}> 
        <ContextMenu.Trigger 
          asChild
           onContextMenu={(event)=>{
            const clipRef = rndRef.current;
            if (clipRef && clipRef.props && clipRef.props.position) {
              const scrollAmount = scrollRef.current.scrollLeft;              
              const clickX = event.clientX - (segment.newStart * pixelsPerSec) + scrollAmount - 11; // 11 is left scroll gutter              
              const seekTime = segment.newStart + (clickX / pixelsPerSec);
              handleSeek(seekTime);
            }
          }} 
        > 
          <Rnd
            ref={rndRef}
            className='editor-timeline-clip-segment editor-timeline-clip-segment--freeze'
            style={{cursor: 'pointer'}}
            size={{ width: pixelWidth + offsetCursor - offsetWidth, height: 30 }}
            position={{ x: left + offsetWidth, y: yOffset }}
            onResize={(e, direction, ref, delta, position) => {
              const newPixelWidth = ref.offsetWidth - offsetCursor + offsetWidth;
              onResize(segment.id, newPixelWidth);
            }}      
            onResizeStart={()=> setIsDraggingSegment(true)}
            onResizeStop={()=> setIsDraggingSegment(false)}
            onClick={onSegmentClick}
            enableResizing={{
              right: true,
              left: false,
              top: false,
              bottom: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
            resizeHandleComponent={{
              right: <SegmentRightClipHandle cursorStyle={cursorStyle} freeze />
            }}
             resizeHandleStyles={{
              right: rightHandleStyles, // Apply the custom styles to the right handle
            }}
            //minWidth={1}
            bounds={`#segmentBounds-${segment.id}`}
            dragAxis={"none"} // Disable dragging
          >
            <div className='editor-timeline-clip-segment-fill'>
              {/* <Icon name='playbackPause' /> */}
            </div>
          </Rnd>
        </ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--timelineClip forceDarkTheme'>                                
            <ContextMenu.Item onSelect={()=>{removeFreeze(clipId,segment.id)}}>
              Remove Freeze
            </ContextMenu.Item>          
          </ContextMenu.Content>
        </ContextMenu.Portal>
      </ContextMenu.Root> 
    </React.Fragment>
  );
};



const EditorTimelineClipFreezeSegments = (props) => {
  const { 
    clip, 
    sceneGap,
    clipWidth, 
    handleChangeSegmentPlaybackRate, 
    onSegmentClick,
    trimStart, 
    scrollRef, 
    handleSeek, 
    pixelsPerSec,
    removeFreeze, 
    yOffset,
    setIsDraggingSegment
} = props


  const { segments, id: clipId } = clip; // id of the clip is needed

  const totalDuration = segments.reduce((acc, segment) => acc + segment.newDuration, 0);

  const handleResize = (segmentId, newPixelWidth) => {
    // Find the segment to update
    const segment = segments.find(seg => seg.id === segmentId);
    if (segment) {
      // Calculate the new duration based on the new width
      const newDuration = (newPixelWidth / clipWidth) * totalDuration;

      // Calculate the new playback rate
      const newPlaybackRate = segment.originalDuration / newDuration;

      // Call handleChangeSegmentPlaybackRate with the new playback rate
      //handleChangeSegmentPlaybackRate(clipId, segmentId, newPlaybackRate);
      handleChangeSegmentPlaybackRate(clipId, segmentId, newPlaybackRate);
    }
  };


 const sceneGapOffset = clip.sceneIndex * sceneGap

  const freezeSegments = segments
    .filter(segment => segment.isQuiet);


  return (
    <>
      {freezeSegments.map(segment => {

        if(segment.newDuration > 0.2){ //NEW march 30th dont show super small freeze segments that can come from trimming/splitting or inserting as a chunk
        return(
        <FreezeSegment 
          key={segment.id} 
          segment={segment} 
          removeFreeze={removeFreeze}
          clipId={clip.id}
          totalDuration={totalDuration} 
          clipWidth={clipWidth}
          onResize={handleResize} 
          onSegmentClick={onSegmentClick}
          trimStart={trimStart}
          scrollRef={scrollRef}
          handleSeek={handleSeek}          
          pixelsPerSec={pixelsPerSec}
          yOffset={yOffset}
          sceneGapOffset={sceneGapOffset}     
          setIsDraggingSegment={setIsDraggingSegment}     
        />
        )
      }
      }
      )
      }
    </>
  );
}

export default EditorTimelineClipFreezeSegments;

import { post, fetch,put } from '../api';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {setPendingWebcamUpload} from './webcamRecording'



////We extract the audio file and do the facebox screenshots, save them to the cache folder and then sync them

///TODO handle large video files/chunked uploading and retries

export const syncUserUploadedWebcam = (clipId, file, duration, captureId,fileExtension) => async (dispatch) => {
  //try {
    dispatch(setPendingWebcamUpload(captureId, clipId));
    ///// extract the audio/screenshots and save it to the cache folder
    const result = await ipcRenderer.invoke('pre-process-user-uploaded-webcam', captureId,fileExtension,duration);

    const response = await post('/webcam-recording/user-upload', {
      capture_id: captureId,
      filename: file.name,
      original_file_extension: fileExtension,
      duration,
      screenshot_count: result.numberOfScreenshots,
      content_type: file.type,
    });    

    if (!response || !response.screenshotUploadUrls || !Array.isArray(response.screenshotUploadUrls)) {
      throw new Error('Invalid response from API: missing screenshotUploadUrls');
    }

    // Upload screenshots using the signed URLs
    for (let i = 0; i < result.frameFiles.length; i++) {
      const uploadUrl = response.screenshotUploadUrls[i];
      if (!uploadUrl) {
        console.error(`Missing upload URL for screenshot ${i}`);
        continue;
      }
      const relativePath = `webcamRecordings/${captureId}/screenshots/frame_${i + 1}.jpg`;
      const screenshotBuffer = await ipcRenderer.invoke('read-file-buffer', relativePath);
      if (!screenshotBuffer) {
        console.error(`Failed to read screenshot file: ${relativePath}`);
        continue;
      }
      try {
        await axios.put(uploadUrl, screenshotBuffer, {
          headers: { 'Content-Type': 'image/jpeg' }
        });
        console.log(`Successfully uploaded screenshot ${i}`);
      } catch (error) {
        console.error(`Failed to upload screenshot ${i}:`, error);
        throw error;
      }
    }  
    try {
      const audioBuffer = await ipcRenderer.invoke('get-webcam-audio-buffer', `${captureId}`, 'audio_original');
      if (audioBuffer) {
        await axios.put(response.audioUploadUrl, audioBuffer, {
          headers: { 'Content-Type': 'audio/mpeg' }
        });
      }
    } catch (audioError) {
      console.log('No audio file found:', audioError);
    }

    // Trigger audio processing
    await post(`/webcam_recordings/process-audio/${captureId}`);


    ////upload the video 
   const videoUploadResponse = await axios.put(response.videoUploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    await post(`/webcam_recordings/aggregation-complete/${captureId}`,{success:true,outputFilename:`recording_original.${fileExtension}`});

};


import React from 'react';
import Icon from '../../../misc/Icon';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const TranscriptPanelVoiceGutterDropdownButton = ({top, height,clipId,playClipFromTranscript,recalculateAudioClip,previewingAudioClipId,cancelPreviewingAudioClip,convertAudioClipIntoScript,activeTranscriptPanelAudioChunk, scrollPosition}) => {  
  
  const isPreviewPlaying = false
  const requiresUpdate = false

  let side = "top"
  let sideOffset = 2
  
  const nearTop = (top - scrollPosition) < 150

  if(nearTop){
    side = "top"
    sideOffset = -148
  }

  

  return (
    <>    
      <DropdownMenu.Root> 
      <DropdownMenu.Trigger asChild>
          <button style={{top: `${top - 35}px`, height: `${height}px`}} className='editor-transcriptPanel-voiceHeaderGutter-dropdownBtn'>
            <div data-near-top={nearTop} className='editor-transcriptPanel-voiceHeaderGutter-dropdownBtn-rect' />
          </button>        
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
      <DropdownMenu.Content             
        //side={nearBottom ? "top" : "bottom"}
        //side='right'        
        //sideOffset={-44}
        side={side}
        sideOffset={sideOffset}
        
        align="start"
        alignOffset={0}     

        className={'dropdownMenu dropdownMenu--transcriptChunk forceDarkTheme ' + (nearTop ? ' dropdownMenu--forceAnimateDown ' : '')}
        // onEscapeKeyDown={()=> setShowDropdown(false)}
        // onPointerDownOutside={()=> setShowDropdown(false)}
        // onFocusOutside={()=> setShowDropdown(false)}
        // onInteractOutside={()=> setShowDropdown(false)}        
      >

        <DropdownMenu.Item onClick={()=> convertAudioClipIntoScript(activeTranscriptPanelAudioChunk)} className='dropdownMenu-item-transcript'>
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='speakerRectangleMedium' />           
          </div>
            <div className='dropdownMenu-item-transcript-label'>
              Camera Script
            </div>
            <div className='dropdownMenu-item-transcript-shortcut'>
              @
            </div>    
        </DropdownMenu.Item>

        {!isPreviewPlaying &&
        <DropdownMenu.Item onClick={()=>playClipFromTranscript(clipId)} className={'dropdownMenu-item-transcript ' + (isPreviewPlaying ? ' dropdownMenu-item-transcript--previewPlaying ' : ' ') + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          <div className='dropdownMenu-item-iconContainer'>
             <Icon name='chunkPreview' /> 
          </div>
              <div className='dropdownMenu-item-transcript-label'>
                Preview
              </div>
              <div className='dropdownMenu-item-transcript-shortcut'>
                Command Enter
              </div>                 
        </DropdownMenu.Item>
      }

      {isPreviewPlaying &&
         <DropdownMenu.Item onClick={()=>cancelPreviewingAudioClip(clipId)} className={'dropdownMenu-item-transcript ' + (isPreviewPlaying ? ' dropdownMenu-item-transcript--previewPlaying ' : ' ') + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='chunkPause' />           
          </div>
              <div className='dropdownMenu-item-transcript-label'>
                Pause
              </div>
              <div className='dropdownMenu-item-transcript-shortcut'>
                Command Enter
              </div>    
        </DropdownMenu.Item>
      }


        <DropdownMenu.Item onClick={()=>recalculateAudioClip(clipId)} className={'dropdownMenu-item-transcript' + (requiresUpdate ? ' dropdownMenu-item-transcript--disabled ' : ' ')}>
          {!requiresUpdate && 
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='chunkRegen' />
            </div>
          }
          {requiresUpdate && 
            <div className='dropdownMenu-item-iconContainer'>
              <div className='dropdownMenu-item-transcript-regenSpinnerContainer'>
                <div className='dropdownMenu-item-transcript-regenSpinner' />
              </div>
            </div>
          }
          <div className='dropdownMenu-item-transcript-label'>
            Regen
          </div>              
          <div className='dropdownMenu-item-transcript-shortcut'>
            Shift Enter
          </div>
        </DropdownMenu.Item>           
      </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>          
  );
};

        
      
    </>
  );
};

export default TranscriptPanelVoiceGutterDropdownButton;
import {webcamSizes as sizes, webcamPositions as positions, FLOAT_CAMERA_INSET} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'
import { calulateVideoTimeFromTimelineTime } from '../../timeline/utils/calulateVideoTimeFromTimelineTime';

export function updateWebcamLayoutForTime(
  updateClipMetadata,
  clip,
  selectedSize,
  selectedPosition,
  currentTime,
  slideClips,
  videoClips
) {
  const newWebcamLayout = {
    size: selectedSize,
    position: selectedPosition
  };

  // Check for overlapping slide clips first
  if (slideClips?.length > 0) {
    const overlappingSlideClip = slideClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingSlideClip) {
      updateClipMetadata(overlappingSlideClip.id, { webcamLayout: newWebcamLayout });
      return;
    }
  }

  // If no overlapping slide clip, check video clips
  if (videoClips?.length > 0) {
    const overlappingVideoClip = videoClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );
    if (overlappingVideoClip) {
      if (overlappingVideoClip.metadata.webcamLayouts && overlappingVideoClip.metadata.webcamLayouts.length > 0) {
        const clipTime = calulateVideoTimeFromTimelineTime(currentTime, overlappingVideoClip)
        const layouts = overlappingVideoClip.metadata.webcamLayouts.map(layout => {
          if (clipTime >= layout.startTime && clipTime < (layout.startTime + layout.duration)) {
            return {
              ...layout,
              size: selectedSize,
              position: selectedPosition
            }
          }
          return layout
        })
        updateClipMetadata(overlappingVideoClip.id, { webcamLayouts: layouts });
        return;
      }
      updateClipMetadata(overlappingVideoClip.id, { webcamLayout: newWebcamLayout });
    }
  }

  // If no overlapping clips found, update the webcam clip
  const newLayout = {
    ...clip.metadata.layout,
    size: selectedSize,
    position: selectedPosition
  };
  updateClipMetadata(clip.id, { layout: newLayout });
}


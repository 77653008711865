import React, { useRef, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import createRoundedRectShape from '../../utils/createRoundedRectShape';
import ScreenVideoCursor from '../ScreenVideoCursor'
import { createDropShadow } from '../ScreenVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../../utils/animations/defaultVideoAnimationValues'
import {getOrgScreenVideoStyle} from '../../../utils/brands/getOrgScreenVideoStyle'
import renderOrders from '../../renderOrders'
import ScreenVideoWindowModeCroppedMaterial from './ScreenVideoWindowModeCroppedMaterial'
import ExportScreenVideoWindowModeCroppedMaterial from './ExportScreenVideoWindowModeCroppedMaterial'


const FPS = 60; // Frames per second

const ScreenVideoWindowModeDevice = ({
  meshWidth,
  meshHeight,
  meshRadius,
  clip,
  time,
  localTime,
  visible,
  initialProperties,
  animationStates,
  animationParams,
  startTransitionType,
  endTransitionType,
  showScreenVideoStatic,
  screenVideoDeviceFrame,
  recordingWidth,
  recordingHeight,
  windowWidth,
  windowHeight,
  windowX,
  windowY,
  sceneWidth,
  sceneHeight,  
  cursorScale,
  isVideoResizeModeActive
}) => {
  const screenVideoStyle=getOrgScreenVideoStyle()

  const deviceRef = useRef();  
  const shadowRef = useRef();

  //
  // DEVICE GEOMETRY  
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [meshWidth, meshHeight, meshRadius]);


  //
  // SHADOW SETUP
  let shadowXOffset = 0;
  let shadowYOffset = -6;
  let shadowBlur = 18;
  let shadowSpread = -16;
  // const shadowOpacity = 0.68;
  let shadowOpacity = 0.72;

  if(screenVideoStyle && screenVideoStyle.shadowOpacity && !screenVideoDeviceFrame){
    shadowOpacity = screenVideoStyle.shadowOpacity
  }

  if(screenVideoDeviceFrame === 'color'){
    shadowXOffset = 0;
    shadowYOffset = -1;
    shadowBlur = 6;
    shadowSpread = -4;
    shadowOpacity = 0.7;
        
    if(screenVideoStyle && screenVideoStyle.shadowFrameOpacity){
      shadowOpacity = screenVideoStyle.shadowFrameOpacity
    }
  }

  const shadowMesh = useMemo(() => {
    const shadowOptions = {
      xOffset: shadowXOffset, 
      yOffset: shadowYOffset, 
      blur: shadowBlur,
      spread: shadowSpread,
      shadowColor: 'rgba(0,0,0,1)',
      opacity: shadowOpacity,
    };
    return createDropShadow(meshWidth, meshHeight, shadowOptions, sceneWidth, sceneHeight);
  }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity, sceneWidth, sceneHeight]);


  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showScreenVideoStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              deviceRef.current.material.opacity = state;            
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates, showScreenVideoStatic]);

    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (shadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + shadowXOffset;
        shadowRef.current.position.y = animatedPosY + shadowYOffset;        

        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);


        if(showScreenVideoStatic){
          shadowRef.current.position.x = shadowXOffset
          shadowRef.current.position.y = shadowYOffset
          //shadowRef.current.position.z = 0.1
          shadowRef.current.position.z = 0
          shadowRef.current.material.opacity = shadowOpacity
        }

        // if(screenVideoDeviceFrame === 'color'){          
        //   shadowRef.current.position.z = 2.4          
        // }


      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, meshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showScreenVideoStatic]);
      

  return (
    <>      
      <primitive 
        object={shadowMesh}
        ref={shadowRef} 
        visible={visible}
        renderOrder={renderOrders.deviceShadow}
      /> 
      
      
      <mesh        
        ref={deviceRef}
        position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
        scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
        geometry={deviceGeometry}
        visible={visible}
        renderOrder={renderOrders.screenVideo}
      >        

        {!clip.isExport &&
          <ScreenVideoWindowModeCroppedMaterial             
            videoElement={clip.video}
            opacity={1}
            meshWidth={meshWidth}
            meshHeight={meshHeight}
            recordingWidth={recordingWidth}
            recordingHeight={recordingHeight}
            windowX={windowX}
            windowY={windowY}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
          />
        } 

        {clip.isExport &&
          <ExportScreenVideoWindowModeCroppedMaterial             
            codecVideoClip={clip}
            opacity={1}
            meshWidth={meshWidth}
            meshHeight={meshHeight}
            recordingWidth={recordingWidth}
            recordingHeight={recordingHeight}
            windowX={windowX}
            windowY={windowY}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
          />
        } 






         
       <ScreenVideoCursor          
          localTime={localTime}
          cursorScale={cursorScale}
          clip={clip}
          time={time}
          animationStates={animationStates}    
          isVideoResizeModeActive={isVideoResizeModeActive}
        />
        
      
     
      </mesh>

    </>
  );
};

export default ScreenVideoWindowModeDevice;



import React, { useState, useEffect } from 'react';
import VideoPreviewPlayer from './VideoPreviewPlayer';
import Icon from '../../misc/Icon'
import VideoDuration from './videoDuration';

function VideoPreview({ width, playbackId, actionClick, toggleMuted, isMuted, hoveredOver, isPlayerReady, setIsPlayerReady, videoDuration, posterTime, storedVideoProgress, setStoredVideoProgress, forwardDurationCounter }) {
  
  const height = width / (16 / 9); // aspect ratio to fix

  const posterWidth = width * 2

  let posterImageURL = `https://image.mux.com/${playbackId}/thumbnail.webp`;
  if (posterTime !== undefined && posterTime !== null) {
    posterImageURL = `https://image.mux.com/${playbackId}/thumbnail.webp?width=${posterWidth}&time=${posterTime}`
  }

  const duration = videoDuration 

  console.log('posterTime')
  console.log(posterTime)
  console.log('posterImageURL')
  console.log(posterImageURL)


  return (
    <div
      style={{ width: `${width}px`, height: `${height}px` }}
      className='videoPreview'      
    >
      <button onClick={actionClick} className='videoPreview-actionBtn'>

      </button>

      {hoveredOver && 
        <button onClick={toggleMuted} className='videoPreview-muteToggle'>
          {isMuted && <Icon name='speaker-muted' /> }
          {!isMuted && <Icon name='speaker-wave-2' /> }
        </button>
      }

      

      {(hoveredOver || storedVideoProgress > 0) && (
        <VideoPreviewPlayer 
          width={width}
          height={height}
          playbackId={playbackId}
          onPlayerReady={() => setIsPlayerReady(true)}
          isMuted={isMuted}    
          storedVideoProgress={storedVideoProgress}          
          setStoredVideoProgress={setStoredVideoProgress}    
          hoveredOver={hoveredOver}
          forwardDurationCounter={forwardDurationCounter}
        />
      )}


        <div className='videoPreview-previewContainer'>
          <div className='videoPreview-duration'>            
            <VideoDuration seconds={duration} />
          </div>          
          <div className='videoPreview-staticPosterContainer'>
            <img src={posterImageURL} className='videoPreview-staticPoster' />
          </div>          
        </div>
      
    </div>
  );
}

export default VideoPreview;

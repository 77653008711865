import React from 'react';
import MobileVideo from './mobileVideo/MobileVideo';
import TextSlide from './textSlide/TextSlide';
import ImageClip from './imageClip/ImageClip'
import CanvasBackground from './CanvasBackground';
import ChartsContainer from './ChartsContainer';
import BasicVideo from './basicVideo/BasicVideo'
import Slide from './slide/Slide'
import renderOrders from './renderOrders'
import CaptionsGroup from './captions/CaptionsGroup'
import SubtitlesGroup from './subtitles/SubtitlesGroup'
import WebcamVideo from './webcamVideo/WebcamVideo'
import ScreenVideoLegacy from './screenVideo/legacy/ScreenVideoLegacy'
import ScreenVideoDesktopMode from './screenVideo/screenVideoDesktopMode/ScreenVideoDesktopMode'
import ScreenVideoWindowMode from './screenVideo/screenVideoWindowMode/ScreenVideoWindowMode'
import WebcamVideoPreview from './webcamVideo/WebcamVideoPreview'

const ThreeCanvas = ({ 
	variableValues,
	testKey,
	currentTime, 
	slideClips,
	textSlideClips,
	videoClips,
	chartClips,
	zoomClips,
	updateMeshWidth,
	updateMeshHeight,
	projectBackground,
	readyVideoClips,
	hideRenderedTextSlide,
	showChartStatic,
	showChartAnimated,
	imageClips,
	showBasicVideoStatic,
	showImageStatic,
	showScreenVideoStatic,
	isPlaying,
	setSelectedSlideElement,
	audioClips,
	showSlideChartStatic,
	showSlideChartAnimated,
	showSlideImageStatic,
	showSlideTextStatic,
	showWebcamStatic,
	focusedSlideElement,
	draggingElementId,
	draggingGroupId,
	resizingElementId,
	slideEditorDraggingSpacer,		
	selectedLayoutGroup,
	isDraggingToReorder,
	selectedSlideItemId,
	subtitlesType,
	webcamClips,
	isDraggingWebcam,
	isResizingWebcam,
	cameraPreviewRect,
	sceneWidth,
	sceneHeight,
	videoWindowPadding,
	isVideoResizeModeActive,
	preVideoResizeSnapshot
	}) => {	


	const showSubtitles = subtitlesType=='standard'
	const showCaptions = subtitlesType=='captions'	

	const hasZoomScaledProjectBG = projectBackground && projectBackground.zoomScaled

	return (
		<>			
			<CanvasBackground 
				background={projectBackground}
				renderOrder={renderOrders.projectBG}
				sceneWidth={sceneWidth}
				sceneHeight={sceneHeight}
			/>


			{webcamClips && webcamClips.map((clip) => {
		  	 if(clip.metadata.creatorSetAudioOnly){ //hacky for now for audio only creator collab page thing
		   		return null
		   	}
		   	else if (showWebcamStatic) {
					return (
						<WebcamVideoPreview
							key={clip.id}
							clip={clip}
							time={currentTime}
							projectBackground={projectBackground}
							isPlaying={isPlaying}
							zoomClips={zoomClips}
							showWebcamStatic={showWebcamStatic}
							isDraggingWebcam={isDraggingWebcam}
							isResizingWebcam={isResizingWebcam}
							slideClips={slideClips}
							cameraPreviewRect={cameraPreviewRect}
							videoClips={videoClips}
							sceneWidth={sceneWidth}
							sceneHeight={sceneHeight}
							preVideoResizeSnapshot={preVideoResizeSnapshot}
						/>
					);
					}
					else {
						return (
							<WebcamVideo
								key={clip.id}
								clip={clip}
								time={currentTime}
								projectBackground={projectBackground}
								isPlaying={isPlaying}
								zoomClips={zoomClips}
								showWebcamStatic={showWebcamStatic}
								isDraggingWebcam={isDraggingWebcam}
								isResizingWebcam={isResizingWebcam}
								slideClips={slideClips}
								videoClips={videoClips}
								sceneWidth={sceneWidth}
								sceneHeight={sceneHeight}
							/>
						);
					}
				})
			}

		{videoClips && videoClips.map((clip) => {    
			const displayMode = clip.metadata.displayMode
			if(clip.isDeviceRecording ){
				return (
					<MobileVideo
						key={clip.id}    
						clip={clip}
						time={currentTime}					
						updateMeshWidth={updateMeshWidth}
						updateMeshHeight={updateMeshHeight}
						projectBackground={projectBackground}
						showMobileVideoStatic={showScreenVideoStatic}
						isPlaying={isPlaying}
						zoomClips={zoomClips}
						readyVideoClips={readyVideoClips}
						sceneWidth={sceneWidth}
						sceneHeight={sceneHeight}
					/>			
				)
			}
			else if(clip.isBasicVideo){
				return (
					<BasicVideo
						key={clip.id}    
						clip={clip}
						time={currentTime}					
						projectBackground={projectBackground}
						showBasicVideoStatic={showBasicVideoStatic}
						isPlaying={isPlaying}
						zoomClips={zoomClips}
						readyVideoClips={readyVideoClips}
						sceneWidth={sceneWidth}
						sceneHeight={sceneHeight}
					/>
				);
			}			
			else if(displayMode === 'desktop'){
				return (
					<ScreenVideoDesktopMode
						key={clip.id}    
						clip={clip}
						time={currentTime}
						updateMeshWidth={updateMeshWidth}
						updateMeshHeight={updateMeshHeight}
						projectBackground={projectBackground}
						showScreenVideoStatic={showScreenVideoStatic}																					
						zoomClips={zoomClips}
						readyVideoClips={readyVideoClips}
						variableValues={variableValues}
						sceneWidth={sceneWidth}
						sceneHeight={sceneHeight}
						isVideoResizeModeActive={isVideoResizeModeActive}
						preVideoResizeSnapshot={preVideoResizeSnapshot}
					/>	
				);
			}
			else if(displayMode === 'window'){
				return (
					<ScreenVideoWindowMode
						key={clip.id}    
						clip={clip}
						time={currentTime}
						updateMeshWidth={updateMeshWidth}
						updateMeshHeight={updateMeshHeight}
						projectBackground={projectBackground}
						showScreenVideoStatic={showScreenVideoStatic}										
						screenVideoDevice={clip.metadata.screenVideoApp}
						screenVideoDeviceFrame={clip.metadata.deviceFrame}
						// screenVideoDevice='figma'
						// screenVideoDeviceFrame='color'
						zoomClips={zoomClips}
						readyVideoClips={readyVideoClips}
						variableValues={variableValues}
						hasZoomScaledProjectBG={hasZoomScaledProjectBG}
						sceneWidth={sceneWidth}
						sceneHeight={sceneHeight}
						videoWindowPadding={videoWindowPadding}
						isVideoResizeModeActive={isVideoResizeModeActive}
						preVideoResizeSnapshot={preVideoResizeSnapshot}
					/>	
				);
			}
			else return ( 
				<ScreenVideoLegacy
					isLegacyClip={clip.isScreenRecording?false:true}
					key={clip.id}    
					clip={clip}
					time={currentTime}
					updateMeshWidth={updateMeshWidth}
					updateMeshHeight={updateMeshHeight}
					projectBackground={projectBackground}
					showScreenVideoStatic={showScreenVideoStatic}										
					screenVideoDevice={clip.metadata.screenVideoApp}
					screenVideoDeviceFrame={clip.metadata.deviceFrame}
					zoomClips={zoomClips}
					readyVideoClips={readyVideoClips}
					variableValues={variableValues}
					hasZoomScaledProjectBG={hasZoomScaledProjectBG}					
					sceneWidth={sceneWidth}
					sceneHeight={sceneHeight}
					videoWindowPadding={videoWindowPadding}
					isVideoResizeModeActive={isVideoResizeModeActive}
					preVideoResizeSnapshot={preVideoResizeSnapshot}
				/>			
			);
		})}

		
		{slideClips && slideClips.map((clip) => {        
			return (
				<Slide
					key={`${testKey}_${clip.id}`}    
					clip={clip}
					time={currentTime}          	
					projectBackground={projectBackground}
					isPlaying={isPlaying}
					setSelectedSlideElement={setSelectedSlideElement}
					hideRenderedTextSlide={hideRenderedTextSlide}
					variableValues={variableValues}
					showSlideChartAnimated={showSlideChartAnimated}
					showSlideChartStatic={showSlideChartStatic} 
					showSlideImageStatic={showSlideImageStatic}
					showSlideTextStatic={showSlideTextStatic}
					focusedSlideElement={focusedSlideElement}
					draggingElementId={draggingElementId}
					draggingGroupId={draggingGroupId}
					resizingElementId={resizingElementId}
					slideEditorDraggingSpacer={slideEditorDraggingSpacer}
					selectedLayoutGroup={selectedLayoutGroup}
					isDraggingToReorder={isDraggingToReorder}					
					selectedSlideItemId={selectedSlideItemId}
					sceneWidth={sceneWidth}
					sceneHeight={sceneHeight}
				/>
			);
		})}

		{chartClips && chartClips.map((clip) => {        
			return (
				<ChartsContainer
					key={clip.id}    
					clip={clip}
					time={currentTime}   
					showChartAnimated={showChartAnimated}
					showChartStatic={showChartStatic} 
					projectBackground={projectBackground}
					sceneWidth={sceneWidth}
					sceneHeight={sceneHeight}
				/>
			);
		})}

		{showCaptions && audioClips && audioClips.map((audioClip) => {
		  const captionGroups = audioClip.metadata.captionGroups || [];		  
		  return captionGroups.map((captionsGroup) => {
		    const globalGroupStartTime = audioClip.startTime + captionsGroup.startTime;		    
		    return (
		      <CaptionsGroup
		        key={`${audioClip.id}-${captionsGroup.id}`}
		        captionsGroup={captionsGroup}
		        globalGroupStartTime={globalGroupStartTime}
		        time={currentTime}
		        isPlaying={isPlaying}		        
		      />
		    );
		  });
		})}

		{showSubtitles && audioClips && audioClips.map((audioClip) => {
		  const subtitlesGroups = audioClip.metadata.subtitlesGroups || [];
		   return subtitlesGroups.map((subtitlesGroup) => {
		    const globalGroupStartTime = audioClip.startTime + subtitlesGroup.startTime;		    
		    return (
		      <SubtitlesGroup
		        key={`${audioClip.id}-${subtitlesGroup.id}`}
		        subtitlesGroup={subtitlesGroup}
		        globalGroupStartTime={globalGroupStartTime}
		        time={currentTime}
		        isPlaying={isPlaying}		        
		      />
		    );
		  });
		})}


{showCaptions && webcamClips && webcamClips.map((webcamClip) => {
		  const captionGroups = webcamClip.metadata.captionGroups || [];		 
		  return captionGroups.map((captionsGroup) => {
		    const globalGroupStartTime = webcamClip.startTime + captionsGroup.startTime;		    
		    return (
		      <CaptionsGroup
		        key={`${webcamClip.id}-${captionsGroup.id}`}
		        captionsGroup={captionsGroup}
		        globalGroupStartTime={globalGroupStartTime}
		        time={currentTime}
		        isPlaying={isPlaying}		        
		      />
		    );
		  });
		})}

	{showSubtitles && webcamClips && webcamClips.map((webcamClip) => {
				const subtitlesGroups = webcamClip.metadata.subtitlesGroups || [];
				return subtitlesGroups.map((subtitlesGroup) => {
					const globalGroupStartTime = webcamClip.startTime + subtitlesGroup.startTime;		    
					return (
						<SubtitlesGroup
							key={`${webcamClip.id}-${subtitlesGroup.id}`}
							subtitlesGroup={subtitlesGroup}
							globalGroupStartTime={globalGroupStartTime}
							time={currentTime}
							isPlaying={isPlaying}		        
						/>
					);
				});
			})}
		
		
		{textSlideClips && textSlideClips.map((clip) => {        
			return (
				<TextSlide
					key={`${testKey}_${clip.id}`}    
					clip={clip}
					time={currentTime}          	
					hideRenderedTextSlide={hideRenderedTextSlide}
					projectBackground={projectBackground}
					sceneWidth={sceneWidth}
					sceneHeight={sceneHeight}    
				/>
			);
		})}

		{imageClips && imageClips.map((clip) => {        
			return (
				<ImageClip
					key={clip.id}    
					clip={clip}
					time={currentTime}
					updateMeshWidth={updateMeshWidth}
					updateMeshHeight={updateMeshHeight}
					projectBackground={projectBackground}
					showImageStatic={showImageStatic}
					isPlaying={isPlaying}
					sceneWidth={sceneWidth}
					sceneHeight={sceneHeight}    
				/>
			);
		})}
		</>
	);
};

export default ThreeCanvas




			
import {getMasterRecordingData} from './getMasterRecordingData'
import { getNormalizedCursorData } from './getNormalizedCursorData';
import { identifySlowCursorPeriods } from './identifySlowCursorPeriods'
import {identifyStillVideoPeriods} from './identifyStillVideoPeriods'
import {getKeystrokeData} from './getKeystrokeData'


const MIN_CURSOR_QUIET_PERIOD=1
const MIN_OVERLAP_PERIOD= 1

const noise = `0.0001`
const deviceNoiseThreshold=`0.0011`

//clipPlaybackRate is applied to the whole clip as a multiple

async function calculateRecordingSilentAndActiveSegments(captureId,totalDuration) {
	let modifiedOverlappingPeriods 

	const masterRecordingData = await getMasterRecordingData(captureId);
		
	const normalizedCursorData = await getNormalizedCursorData(
		masterRecordingData.cursorData,
		masterRecordingData.recordingWidth,
		masterRecordingData.recordingHeight,
		0,
		0
	);
		
	const slowCursorPeriods = await identifySlowCursorPeriods(normalizedCursorData,MIN_CURSOR_QUIET_PERIOD);	
	const stillVideoPeriods = await identifyStillVideoPeriods(captureId,noise)

	let overlappingPeriods = [];
		slowCursorPeriods.forEach(scp => {
			stillVideoPeriods.forEach(svp => {
			if (scp.start < svp.end && svp.start < scp.end) {
				// Create a new period that is within both
				const start = Math.max(scp.start, svp.start);
				const end = Math.min(scp.end, svp.end);
				// Ensure the period is at least 0.5 seconds long
				if ((end - start) >= MIN_OVERLAP_PERIOD) {
					overlappingPeriods.push({ start, end });
				}
			}
		});
	});


	const keystrokeData = await getKeystrokeData(captureId)

  modifiedOverlappingPeriods = overlappingPeriods.filter(period => {
      return !keystrokeData.some(keystroke => {
          return keystroke.time >= period.start && keystroke.time <= period.end;
      });
  });

	///New March 24th to prevent back to back quiet periods
  // Merge overlapping or adjacent quiet periods
  function mergeOverlappingPeriods(periods) {
    if (periods.length <= 1) return periods;
    
    // Sort periods by start time
    periods.sort((a, b) => a.start - b.start);
    
    const merged = [];
    let current = {...periods[0]};
    
    for (let i = 1; i < periods.length; i++) {
      const period = periods[i];
      // Only merge if periods overlap or are exactly adjacent
      if (period.start <= current.end) {
        current.end = Math.max(current.end, period.end);
      } else {
        merged.push(current);
        current = {...period};
      }
    }
    merged.push(current);
    return merged;
  }

  modifiedOverlappingPeriods = mergeOverlappingPeriods(modifiedOverlappingPeriods);

	let segments = [];
	let lastEnd = 0;
	modifiedOverlappingPeriods.forEach(period => {
		if (lastEnd < period.start) {
			// Add active segment before the quiet period
			segments.push({
				id: segments.length,
				originalDuration: period.start - lastEnd,
				originalStart: lastEnd,
				originalEnd: period.start,
				isQuiet: false,
				playbackRate:1,

			});
		}
		// Add quiet segment
		segments.push({
			id: segments.length,
			originalDuration: period.end - period.start,
			originalStart: period.start,
			originalEnd: period.end,
			isQuiet: true,
			playbackRate:1,
	
		});
		lastEnd = period.end;
	});

	if (lastEnd < totalDuration) {
		// Add remaining active segment
		segments.push({
			id: segments.length,
			originalStart:lastEnd,
			originalDuration: totalDuration - lastEnd,
			originalEnd: totalDuration,
			isQuiet: false,
			playbackRate:1,

		});
	}

return segments

}


export { calculateRecordingSilentAndActiveSegments }



import React, {useState, useEffect} from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import DPTextArea from '../../detailPanel/DPTextArea';
import DPTextInput from '../../detailPanel/DPTextInput';
import EditorGeneratePopoverColabPageVariables from './EditorGeneratePopoverColabPageVariables'
import EditorGeneratePopoverColabPageLinks from './EditorGeneratePopoverColabPageLinks'
import EditorGeneratePopoverInfoBox from './EditorGeneratePopoverInfoBox'
import EditorGeneratePopoverColabPageSelectPreviewDropdown from './EditorGeneratePopoverColabPageSelectPreviewDropdown'
import VideoPreview from '../../../uiKit/videoPreview/VideoPreview'


const INITIAL_FIELDS = {
  headline: '',
  subtitle: '',
  pageTitle: '',
  enableSelectBGMusic: false,
  enableSelectBGColor: false,
  enableSelectLibraryVoice:false,
  previewVideo:null
};

function EditorGeneratePopoverColabPage({ 
    goBack,
    project,
    updateCollabPageSettings,
    stringVariables,
    variableWebcams,
    updateClipMetadata,
    collabGenerations
  }) {
  
  const pageSettings = project.collab_page_settings

  const [fields, setFields] = useState(() => ({
    ...INITIAL_FIELDS,
    ...project.collab_page_settings,
  }));


  const handleFieldUpdate = (fieldName, value) => {
    const newFields = {
      ...fields,
      [fieldName]: value,
    };
    setFields(newFields);
    updateCollabPageSettings(newFields);
  };
  
  const hideSubtitleOption = true
  const [addPageSubtitle, setAddPageSubtitle] = useState(false);
  const showSubtitleInput = fields.subtitle || (fields.subtitle && fields.subtitle.length > 0)
  const showAddSubtitleButton = !fields.subtitle
  const hasStringVariables = stringVariables && stringVariables.length > 0

  const hasGenerations = collabGenerations && collabGenerations.length >0
  const hasPreviewVideo = fields.previewVideo?true:false
  console.log('hasPreviewVideo',hasPreviewVideo)

  let previewViewSectionState = 'disabled'

  if(hasGenerations && !hasPreviewVideo){
    previewViewSectionState = 'inactive'
  }
  if(hasGenerations && hasPreviewVideo){
    previewViewSectionState = 'active'
  }


  const [hoveredOverPreviewVideo, setHoveredOverPreviewVideo] = useState(false)
  const [previewVideoMuted, setPreviewVideoMuted] = useState(true)
  const [previewVideoReady, setPreviewVideoReady] = useState(false)
  const [storedVideoProgress, setStoredVideoProgress] = useState(0)

  let previewInfoBoxLabel = 'Select a generation to appear as a preview video for context.'
  if(previewViewSectionState === 'disabled'){
    previewInfoBoxLabel = "After a video has been created through the colab page, you'll be able to select it as a preview video."
  }

  // console.log('PREVIEW VIDEO')
  // console.log(fields.previewVideo)

  return (
    <>
    {/*}
    <div className='generatePopover-header'>
      <button onClick={() => goBack()} className='generatePopover-header-backBtn'>
        <Icon name='chevronRight' />
      </button>
      <div className='generatePopover-header-label'>
        Colab Page
      </div>
      <div className='generatePopover-header-bottomBorder' />
    </div>
    */}
    
    <div className='generatePopover-main'>
      <div className='generatePopover-tabDescription'>
        Send people a link where they can individually record webcam, voice, or make custom selections.
      </div>

      <div className='generatePopover-section generatePopover-section--pageHeader'>

        <div className='generatePopover-section-header'>
          Title <span className='generatePopover-section-header-light'>Optional</span> 
        </div>

        <div className='generatePopover-section--pageHeader-textAreaContainer'>
          <DPTextInput
            value={fields.pageTitle}
            setValue={(value) => handleFieldUpdate('pageTitle', value)}
            placeholder='Add a page title...'
            fullWidth={true}
            maxLength={50}
          />
        </div>  

        <div className='generatePopover-divider' />

        <div className='generatePopover-section-smallVSpacer' />

        <div className='generatePopover-section-header'>
          Description <span className='generatePopover-section-header-light'>Optional</span>          
          <EditorGeneratePopoverInfoBox 
            label='You can insert string variables in the description text, like {firstName}.'
          />      
        </div>

        <div className='generatePopover-section--pageHeader-textAreaContainer'>
          <DPTextArea
            value={fields.headline}
            setValue={(value) => handleFieldUpdate('headline', value)}
            placeholder='Use description to provide context or instructions...'
            autoresize
            maxLength={1000}
          />   
        </div>  

        <div className='generatePopover-divider' />

        <div data-active-state={previewViewSectionState} className='generatePopover-section generatePopover-section--previewVideo'>

          <div className='generatePopover-section-header'>
            Preview Video
            {previewViewSectionState !== 'disabled' &&
              <EditorGeneratePopoverColabPageSelectPreviewDropdown 
                btnLabel={hasPreviewVideo ? 'Select Video' : 'Select Video'}
                selectedPreviewVideo={fields.previewVideo}
                collabGenerations={collabGenerations}
                selectPreviewVideo={(value) => handleFieldUpdate('previewVideo', value)}
              />
            }
            <EditorGeneratePopoverInfoBox 
              label={previewInfoBoxLabel} 
            />      
          </div>

          {hasPreviewVideo &&
            <>
              <div onMouseEnter={() => setHoveredOverPreviewVideo(true)} onMouseLeave={() => setHoveredOverPreviewVideo(false)} className='generatePopover-section--previewVideo-video'>
                <VideoPreview 
                  playbackId={fields.previewVideo.mux_playback_id}
                  width={356}
                  isMuted={previewVideoMuted}
                  toggleMuted={() => setPreviewVideoMuted(!previewVideoMuted)}
                  hoveredOver={hoveredOverPreviewVideo}
                  videoDuration={fields.previewVideo.duration}
                  isPlayerReady={previewVideoReady}
                  setIsPlayerReady={setPreviewVideoReady}                  
                  storedVideoProgress={storedVideoProgress}
                  setStoredVideoProgress={setStoredVideoProgress}
                  posterTime={0}
                  forwardDurationCounter
                />
              </div>            
            </>
          }
        </div>

        {!hideSubtitleOption && showSubtitleInput &&
          <>
            <div className='generatePopover-section-header generatePopover-section--pageHeader-subtitle'>
              Page Subtitle
            </div>
            <div className='generatePopover-section--pageHeader-textAreaContainer'>
              <DPTextArea
                value={fields.subtitle}
                setValue={(value) => handleFieldUpdate('subtitle', value)}
                placeholder='Add additional context...'
              />   
            </div>  
          </>
        }
        
        {!hideSubtitleOption && !addPageSubtitle && 
          <div className='generatePopover-section-footer'>
            <button 
              onClick={() => setAddPageSubtitle(!addPageSubtitle)}
              className='generatePopover-section-footer-lightBtn'
            >
              {addPageSubtitle ? 'Hide Page Subtitle' : 'Add Page Subtitle'}
            </button>      
          </div>
        }

      </div>

      <div className='generatePopover-divider' />

      <EditorGeneratePopoverColabPageVariables 
        stringVariables={stringVariables} 
        variableWebcams={variableWebcams}
        updateClipMetadata={updateClipMetadata}
        enableSelectBGMusic={fields.enableSelectBGMusic}
        enableSelectBGColor={fields.enableSelectBGColor}
        enableSelectLibraryVoice={fields.enableSelectLibraryVoice}
        handleFieldUpdate={handleFieldUpdate}
        />    

      <div className='generatePopover-divider' />

      <EditorGeneratePopoverColabPageLinks 
        stringVariables={stringVariables}
        projectName={project.name}
        projectId={project.id}
      />
    </div>



    




    </>
  );
}

export default EditorGeneratePopoverColabPage;
import {getOrgBrand} from './getOrgBrand'


const DEFAULT_CURSOR_SMOOTHING_CONFIG = {enabled:true,animationParams:{mass:1,stiffness:170,damping:26}}

export function getCursorSmoothingConfig  () {
  let cursorSmoothingConfig = DEFAULT_CURSOR_SMOOTHING_CONFIG
  const orgBrand=getOrgBrand()
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.cursorSmoothingConfig){
    cursorSmoothingConfig = orgBrand.screenVideoStyle.cursorSmoothingConfig
  }
  return cursorSmoothingConfig
}

import React, { useState, useMemo, useCallback } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Measure from 'react-measure';
import AddFromLibraryDialogHeader from './AddFromLibraryDialogHeader';
// import AddFromLibraryDialogSuggested from './AddFromLibraryDialogSuggested';
import AddFromLibraryDialogGrid from './AddFromLibraryDialogGrid';
import { getAssetLibrary } from '../../../../utils/getAssetLibrary';
import { debounce } from 'lodash';
import { fetch } from '../../../../api';
import { combineEqualAdjacentPositions } from '../../../../utils/recordings/screenRecordings/screenRecordingDisplayUtils';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';


function AddFromLibraryDialog({ children, handleClosePopover, showPopover, addWebcamFromLibrary, insertImageFromRecent, insertVideoFromRecent, addRecordingFromLibrary }) {
  const [measurements, setMeasurements] = useState({ width: 0 });
  const [layoutType, setLayoutType] = useState('grid');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [activeTypeTab, setActiveTypeTab] = useState('everything');
  const [recentAssets, setRecentAssets] = useState([]);
  const [searchResults, setSearchResults] = useState(null);

  const handleOpenDialog = async () => {
    const fetchedAssets = await getAssetLibrary();
    const sortedAssets = fetchedAssets.sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    const recentAssets = sortedAssets;
    setRecentAssets(recentAssets);
  };

  const closeDialog = () => {
    handleClosePopover();
  };

  // Add debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSearchResults(null);
        return;
      }
      try {
        const response = await fetch(`/screen-recordings/search/${encodeURIComponent(query.trim())}`);
        setSearchResults(response || []);
      } catch (error) {
        console.error('Search error:', error);
        setSearchResults(null);
      }
    }, 500),
    []
  );

  // Update search input handler
  const handleSearchInput = (value) => {
    setSearchInputValue(value);
    debouncedSearch(value);
  };

  const expandScreenRecording = (item) => {
    if (!item.recordingChunks?.chunks) return [item];
    const recordingChunks = item.recordingChunks.chunks;

    const firstChunk = recordingChunks[0];
    const updatedItem = {
      ...item,
      title: firstChunk ? `${firstChunk.inferredTitle || firstChunk.appName || 'Desktop'} (Recording Session)` : item.title
    };

    // Create individual chunk entries
    const chunks = recordingChunks
      .map((chunk, originalIndex) => ({
        ...updatedItem,
        chunkIndex: originalIndex,
        title: chunk.inferredTitle || chunk.appName || 'Desktop',
        subtitle: chunk.inferredSubtitle|| chunk.title || null,
        duration: chunk.duration,
        chunkPositions: chunk.positions.length > 0 ? combineEqualAdjacentPositions(chunk.positions) : []
      }))
      .filter(chunk => chunk.duration >= 5);

    // Only include full recording if there are multiple chunks
    if (chunks.length === 1) {
      return chunks;
    }

    // Create full recording entry
    const flatPositions = recordingChunks.map(chunk => chunk.positions).flat();
    const fullRecording = [{
      ...updatedItem,
      chunkIndex: null,
      chunkPositions: flatPositions.length > 0 ? combineEqualAdjacentPositions(flatPositions) : []
    }];

    return [...fullRecording, ...chunks];
  };

  const isValidAsset = (asset) => {
    if (asset.title?.startsWith('Google Chrome: ')) {
      asset.title = asset.title.replace(/^Google Chrome: /, '');
    }
    
    return typeof asset.width === 'number' && 
      typeof asset.height === 'number' && 
      asset.width > 0 && 
      asset.height > 0 &&
      typeof asset.cloudinaryId === 'string' &&
      asset.cloudinaryId.length > 0;
  };

  const matchesSearchResults = (asset, searchResults) => {
    if (!searchResults) return true;
    
    // If it's not a screen recording, we dont' have to worry about chunks
    if (asset.type !== 'screenRecording') {
      return searchResults.some(result => 
        result.capture_id === asset.captureId && result.chunk_index === "-1"
      );
    }

    return searchResults.some(result => {
      // If the search result is for a full recording, we need to return the main recording or the solo chunk.
      if (result.chunk_index === "-1") {
        return result.capture_id === asset.captureId && asset.chunkIndex === null;
      }
      return result.capture_id === asset.captureId && parseInt(result.chunk_index) === asset.chunkIndex;
    });
  };

  // Client-side search to check if search term appears in title or subtitle
  const matchesClientSearch = (asset, searchTerm) => {
    if (!searchTerm.trim()) return true;
    
    const term = searchTerm.toLowerCase();
    const title = (asset.title || '').toLowerCase();
    const subtitle = (asset.subtitle || '').toLowerCase();
    
    return title.includes(term) || subtitle.includes(term);
  };

  const matchesTypeFilter = (asset, activeTypeTab) => {
    if (activeTypeTab === 'everything') return true;

    const typeFilters = {
      screenClip: ['recording', 'screenRecording', 'deviceRecording'],
      videoClip: ['basicVideo'],
      webcamClip: ['webcamVideo'],
      image: ['image']
    };

    return typeFilters[activeTypeTab]?.includes(asset.type) || false;
  };

  const filteredAssets = useMemo(() => {
    // If there's a search query but no results, return empty array
    if (searchInputValue && searchResults?.length === 0) {
      return [];
    }

    // Expand assets with their chunks
    const expandedAssets = recentAssets.flatMap(item => 
      item.type === 'screenRecording' ? expandScreenRecording(item) : [item]
    );

    // Apply all filters
    return expandedAssets
      .filter(isValidAsset)
      .filter(asset => {
        // If no search input, include all assets
        if (!searchInputValue.trim()) return true;
        
        // Include asset if it matches either server-side OR client-side search
        return matchesClientSearch(asset, searchInputValue) || 
               matchesSearchResults(asset, searchResults);
      })
      .filter(asset => matchesTypeFilter(asset, activeTypeTab));

  }, [recentAssets, activeTypeTab, searchResults, searchInputValue]);

  const insertFromLibrary = (mediaItem, chunkIndex, withWebcam = false) => {
    if (mediaItem.type === 'webcamVideo') {
      addWebcamFromLibrary(mediaItem.libraryObj.capture_id, mediaItem.libraryObj.session_capture_id);
    }
    else if (mediaItem.type === 'image') {
      insertImageFromRecent(mediaItem.libraryObj);
    } else if (mediaItem.type === 'basicVideo') {
      insertVideoFromRecent(mediaItem.libraryObj);
    } else {
      const isDevice = mediaItem.type === 'deviceRecording';
      const isScreenRecording = mediaItem.type === 'screenRecording';
      addRecordingFromLibrary(
        mediaItem.captureId, 
        mediaItem.sessionCaptureId,
        isDevice, 
        isScreenRecording, 
        chunkIndex, 
        withWebcam ? mediaItem.linkedWebcamCaptureId : null,
        mediaItem.recordingChunks?.chunks
      );
    }
    closeDialog();
  };

  return (
    <Dialog.Root
      open={showPopover}
      onOpenChange={(open) => {
        if (!open) {
          closeDialog();
        }
      }}
    >
      <Dialog.Trigger asChild onClick={handleOpenDialog}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="addFromLibraryDialog-overlay" />
        <Dialog.Content className="addFromLibraryDialog forceDarkTheme">
          <VisuallyHidden.Root>
            <Dialog.Title>Add from Library</Dialog.Title>
            <Dialog.Description>
              Browse and select media from your library to add to your project. Use the tabs to filter by media type and the search bar to find specific items.
            </Dialog.Description>
          </VisuallyHidden.Root>
          {/* LATER 
          <AddFromLibraryDialogSuggested />
          */}

          <AddFromLibraryDialogHeader             
            searchInputValue={searchInputValue}
            setSearchInputValue={handleSearchInput}
            layoutType={layoutType}
            setLayoutType={setLayoutType}
            activeTypeTab={activeTypeTab}
            setActiveTypeTab={setActiveTypeTab}
          />

          

          
          {layoutType === 'grid' &&          
            <AddFromLibraryDialogGrid 
              media={filteredAssets}
              rowWidth={measurements.width - 30}
              insertFromLibrary={insertFromLibrary}
              activeTypeTab={activeTypeTab}
              searchResults={searchResults}
            />          
          }
                      
          <Measure
            bounds
            onResize={(contentRect) => {
              setMeasurements(contentRect.bounds);
            }}
          >
            {({ measureRef }) => (
              <div className="addFromLibraryDialog-measure" ref={measureRef} />
            )}
          </Measure>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default AddFromLibraryDialog;
import { SpringAnimator } from '../../../three/utils/animations/SpringAnimator';
import { calculateTimelineTimeFromVideoTime } from '../../../timeline/utils/calculateTimelineTimeFromVideoTime';
import { calulateVideoTimeFromTimelineTime } from '../../../timeline/utils/calulateVideoTimeFromTimelineTime';
import { getCursorSmoothingConfig } from '../../brands/getCursorSmoothingConfig';
import { getClickScaleAnimationConfig } from '../../brands/getClickScaleAnimationConfig';
// const cursorSmoothAnimationParams = {
//   mass: 1,
//   stiffness: 170,
//   damping: 26,
// };

// const cursorSmoothAnimationParams = {
//   mass: 0.8,      
//   stiffness: 250, 
//   damping: 30,    
// };

const longClickAnimationParams = {
  mass: 1,
  stiffness: 400,
  damping: 35,
};

const shortClickAnimationParams = {
  mass: 1,
  stiffness: 1200,
  damping: 60,
};

// Core smoothing function (with added parameters)
const smoothCursorData = (
  cursorData,
  duration,
  clickData,
  fps = 60,
  cursorSmoothEnabled = true,
  cursorClickScaleEnabled = true
) => {
  const cursorSmoothConfig = getCursorSmoothingConfig()
  const cursorSmoothAnimationParams = cursorSmoothConfig.animationParams


  const FRAME_TIME = 1 / fps;
  const CLICK_SCALE = cursorClickScaleEnabled ? 0.85 : 1;
  const SHORT_CLICK_THRESHOLD = 0.30; // seconds
  const CLICK_PRE_ANIMATION_OFFSET = 0.02; // seconds before click to start animation
  const MIN_INSTANT_CLICK_FRAMES = 12; // minimum frames for instant click animation

  const totalFrames = Math.ceil(duration * fps);
  const smoothedCursorData = [];

  // If no smoothing is enabled, simply prepare data at required FPS
  if (!cursorSmoothEnabled) {
    for (let frame = 0; frame < totalFrames; frame++) {
      const currentTime = frame / fps;
      
      // Find the closest cursor data point at or before current time
      let closestPoint = cursorData[0];
      for (let i = 0; i < cursorData.length; i++) {
        if (cursorData[i].time <= currentTime) {
          closestPoint = cursorData[i];
        } else {
          break;
        }
      }
      
      smoothedCursorData.push({
        x: closestPoint.x,
        y: closestPoint.y,
        time: currentTime,
        cursorType: closestPoint.cursorType,
        scale: 1,  // No click scaling
        opacity: 1
      });
    }
    return smoothedCursorData;
  }

  // Reduce data points (for performance)
  const filteredCursorData = cursorData.filter((_, index) => index % 3 === 0);

  if (filteredCursorData.length === 0) {
    console.error('No cursor data points to smooth');
    return [];
  }

  let currentCursorType = filteredCursorData[0].cursorType;

  // Initialize springs for X, Y, and scale.
  let springX = new SpringAnimator(
    cursorSmoothAnimationParams.mass,
    cursorSmoothAnimationParams.stiffness,
    cursorSmoothAnimationParams.damping,
    filteredCursorData[0].x
  );
  let springY = new SpringAnimator(
    cursorSmoothAnimationParams.mass,
    cursorSmoothAnimationParams.stiffness,
    cursorSmoothAnimationParams.damping,
    filteredCursorData[0].y
  );
  let scaleSpring = new SpringAnimator(
    longClickAnimationParams.mass,
    longClickAnimationParams.stiffness,
    longClickAnimationParams.damping,
    1  // initial scale is 1
  );

  let nextDataPointIndex = 0;
  let lastKnownDataPoint = filteredCursorData[0];
  let isMouseDown = false;
  let mouseDownTime = null;
  let instantClickReleaseFrame = null;

  // Process each frame (at 60fps)
  for (let frame = 0; frame < totalFrames; frame++) {
    const currentTime = frame / fps;

    // Update position targets when reaching new data points
    while (
      nextDataPointIndex < filteredCursorData.length &&
      filteredCursorData[nextDataPointIndex].time <= currentTime
    ) {
      lastKnownDataPoint = filteredCursorData[nextDataPointIndex];
      springX.setTarget(lastKnownDataPoint.x);
      springY.setTarget(lastKnownDataPoint.y);
      currentCursorType = lastKnownDataPoint.cursorType;
      nextDataPointIndex++;
    }

    // Check for click events within the current frame window
    const mouseEvent = clickData.find(event => {
      const adjustedEventTime = event.time - CLICK_PRE_ANIMATION_OFFSET;
      return adjustedEventTime >= currentTime - FRAME_TIME && adjustedEventTime <= currentTime;
    });

    if (mouseEvent && cursorClickScaleEnabled) {
      if (mouseEvent.mouseEventType === 'down') {
        if (!isMouseDown) {
          isMouseDown = true;
          mouseDownTime = currentTime + CLICK_PRE_ANIMATION_OFFSET;

          const mouseUpEvent = clickData.find(
            event => event.mouseEventType === 'up' && event.time > mouseEvent.time
          );

          if (mouseUpEvent) {
            const clickDuration = mouseUpEvent.time - mouseEvent.time;
            const isInstantClick = clickDuration < SHORT_CLICK_THRESHOLD;
            const params = isInstantClick ? shortClickAnimationParams : longClickAnimationParams;
            scaleSpring = new SpringAnimator(
              params.mass,
              params.stiffness,
              params.damping,
              scaleSpring.getPosition()
            );
            scaleSpring.setTarget(CLICK_SCALE);

            if (isInstantClick) {
              instantClickReleaseFrame = frame + MIN_INSTANT_CLICK_FRAMES;
            }
          } else {
            scaleSpring = new SpringAnimator(
              shortClickAnimationParams.mass,
              shortClickAnimationParams.stiffness,
              shortClickAnimationParams.damping,
              scaleSpring.getPosition()
            );
            scaleSpring.setTarget(CLICK_SCALE);
          }
        }
      } else if (mouseEvent.mouseEventType === 'up') {
        if (instantClickReleaseFrame === null || frame >= instantClickReleaseFrame) {
          isMouseDown = false;
          scaleSpring.setTarget(1);
          instantClickReleaseFrame = null;
        }
      }
    }

    // Force release after minimum frames for an instant click if needed
    if (instantClickReleaseFrame !== null && frame >= instantClickReleaseFrame && isMouseDown) {
      isMouseDown = false;
      scaleSpring.setTarget(1);
      instantClickReleaseFrame = null;
    }

    // Update spring simulations
    springX.simulate(1000 / fps);
    springY.simulate(1000 / fps);
    scaleSpring.simulate(1000 / fps);

    const currentX = springX.getPosition();
    const currentY = springY.getPosition();
    const scale = cursorClickScaleEnabled ? scaleSpring.getPosition() : 1;

    smoothedCursorData.push({
      x: currentX,
      y: currentY,
      time: currentTime,
      cursorType: currentCursorType,
      scale,
      opacity: 1
    });
  }

  return smoothedCursorData;
};

// getSmoothedCursorData with flattened parameters
export const getSmoothedCursorData = async (
  normalizedCursorData,          // original cursor events (with times)
  masterRecordingVideoDuration,  // total original duration (seconds)
  clickData,                     // original click events (with times)
  skipSegments = [],             // skip segments in seconds, e.g. [{ start: 2.23, end: 5.00 }, ...]
  clip,
  // cursorSmoothEnabled = true,    // whether to enable cursor smoothing
  // cursorClickScaleEnabled = true // whether to enable cursor click scaling
) => {

  const cursorSmoothConfig = getCursorSmoothingConfig()
  const cursorSmoothEnabled = cursorSmoothConfig.enabled
  const cursorClickScaleConfig = getClickScaleAnimationConfig()
  const cursorClickScaleEnabled = cursorClickScaleConfig.enabled

  try {
    const FPS = 60;

    if (skipSegments.length === 0) {
      return smoothCursorData(
        normalizedCursorData, 
        masterRecordingVideoDuration, 
        clickData, 
        FPS, 
        cursorSmoothEnabled, 
        cursorClickScaleEnabled
      );
    }

    // Sort skip segments by start time (if not already sorted)
    const sortedSkipSegments = skipSegments.slice().sort((a, b) => a.start - b.start);

    // Step 1: Filter out events that occur during skip segments and adjust times using time values.
    const filterAndNormalizeEvents = (events) => {
      return events
        .filter(event => {
          // Exclude events whose time falls within any skip segment.
          return !sortedSkipSegments.some(segment => event.time >= segment.start && event.time < segment.end);
        })
        .map(event => {
          return {
            ...event,
            originalTime: event.time,
            time: calculateTimelineTimeFromVideoTime(event.time, clip) - clip.startTime
          };
        });
    };

    const normalizedCursorDataForSmoothing = filterAndNormalizeEvents(normalizedCursorData);
    const normalizedClickDataForSmoothing = filterAndNormalizeEvents(clickData);

    // Step 2: Compute the normalized duration (original duration minus total skipped time).
    const totalSkipDuration = sortedSkipSegments.reduce((sum, seg) => sum + (seg.end - seg.start), 0);
    const normalizedDuration = masterRecordingVideoDuration - totalSkipDuration;

    // Step 3: Apply smoothing on the normalized timeline (data now exists at a regular 60fps grid).
    const smoothedNormalizedData = smoothCursorData(
      normalizedCursorDataForSmoothing,
      normalizedDuration,
      normalizedClickDataForSmoothing,
      FPS,
      cursorSmoothEnabled,
      cursorClickScaleEnabled
    );

    // Step 4: Denormalize the smoothed data (reintroduce skip segments).
    const finalSmoothedCursorData = smoothedNormalizedData.map(point => {
      return {
        ...point,
        time: calulateVideoTimeFromTimelineTime(point.time + clip.startTime, clip)
      };
    });

    return finalSmoothedCursorData;
  } catch (error) {
    console.error('Failed to calculate smoothedCursorData:', error);
    return [];
  }
};
import React, { useMemo } from 'react';
import { Text } from '@react-three/drei';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import * as THREE from 'three';
import SubtitlesWordText from './SubtitlesWordText'
import SubtitlesGroupBox from './SubtitlesGroupBox'

const SubtitlesGroup = ({ subtitlesGroup, globalGroupStartTime, time, isPlaying }) => {
  const globalStartTime = globalGroupStartTime;
  const globalEndTime = globalStartTime + (subtitlesGroup.endTime - subtitlesGroup.startTime);
  const duration = (globalEndTime - globalStartTime).toFixed(4);
  const localTime = time - globalStartTime; // time since start of slide
  const isHidden = time < globalStartTime || time >= globalEndTime;

  if (isHidden) {
    return null;
  }

  const fontSize = 48;
  const padding = 0.10 * fontSize;

  // console.log(subtitlesGroup)
  // console.log('subtitlesGroup')
  // console.log('TESTTTTTTTTT')

  return (
    <>
      <group renderOrder={100}>
        <SubtitlesGroupBox 
          width={subtitlesGroup.width}
          height={subtitlesGroup.height}
          position={[subtitlesGroup.normalLeft, subtitlesGroup.normalTop, 1]}
          renderOrder={200}
        />
      </group>
      
      {/* Words with individual rounded boxes and text */}
      {subtitlesGroup.lines.map((line) => (       
          line.words.map((word) => {        
            return (
              <group key={word.id} renderOrder={200}>            
                <SubtitlesWordText 
                  word={word} 
                  localTime={localTime} 
                  fontSize={fontSize} 
                  padding={padding}              
                />
              </group>
            );
          })
          ))}
    
    </>   
  );
};

export default SubtitlesGroup;
import { Plugin, PluginKey } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';

const skipDecorationPluginKey = new PluginKey('skipDecoration');
const ITEM_AFTER_SKIP_CLASSNAME = 'editor-transcriptPanel-transcriptGroup--cameraRecording-word--skipBefore';

function findSkipBoundaries(doc) {
	const boundaries = [];
	doc.descendants((node, pos) => {
		if (node.type.name === 'transcriptGroup' && 
			node.attrs.groupType === 'webcam-recording' && 
			node.attrs.skipRanges?.length > 0) {
			const isScreencastLinked = node.attrs.linkedClipId ? true : false;
			const trimStart = node.attrs.clipTrimStart;
			const trimEnd = node.attrs.clipTrimEnd;
			const groupId = node.attrs.groupId;

			// Filter skip ranges to only include those within trim bounds
			const validSkipRanges = node.attrs.skipRanges
				.filter(range => {
					if (trimStart === null || trimEnd === null) return true;
					return range.endTime >= trimStart && range.startTime <= trimEnd;
				})
				.sort((a, b) => a.startTime - b.startTime);

			// Process each chunk
			node.descendants((childNode, childPos) => {
				if (childNode.type.name === 'webcamRecordingChunk') {
					const chunkStartTime = childNode.attrs.startTime;
					const chunkEndTime = childNode.attrs.endTime;

					// Find skip ranges that overlap with this chunk
					const chunkSkipRanges = validSkipRanges.filter(range => 
						(range.startTime >= chunkStartTime && range.startTime < chunkEndTime) ||
						(range.endTime > chunkStartTime && range.endTime <= chunkEndTime) ||
						(range.startTime <= chunkStartTime && range.endTime >= chunkEndTime)
					);

					if (chunkSkipRanges.length > 0) {
						// Process each skip range in this chunk
						chunkSkipRanges.forEach(skipRange => {
							const range = {
								groupId,
								groupPos: pos,
								chunkPos: pos + childPos,
								chunk: childNode,
								startTime: skipRange.startTime,
								endTime: skipRange.endTime,
								isScreencastLinked
							};

							// Find the first non-skipped word after the range
							let foundIndicatorPos = false;
							let foundNextItem = false;
							
							childNode.descendants((itemNode, itemPos) => {
								if (!foundNextItem && itemNode.type.name === 'webcamTranscriptItem') {
									const itemStartTime = itemNode.attrs.startTime;
									
									// If this is the first word after our skip range
									if (itemStartTime >= skipRange.endTime) {
										range.itemAfterSkip = {
											pos: pos + childPos + 2 + itemPos,
											node: itemNode
										};
										range.skipIndicatorPos = Math.max(pos + childPos + itemPos + 2, range.chunkPos + 2);
										foundIndicatorPos = true;
										foundNextItem = true;
									}
								}
							});

							// If we didn't find a next word, put the indicator at the end of the chunk
							if (!foundIndicatorPos) {
								// Find the last rendered word in the chunk
								let lastItemPos = null;
								let lastItemNode = null;
								childNode.descendants((itemNode, itemPos) => {
									if (itemNode.type.name === 'webcamTranscriptItem') {
										lastItemPos = pos + childPos + itemPos + itemNode.nodeSize + 1;
										lastItemNode = itemNode;
									}
								});
								
								if (lastItemPos !== null) {
									range.skipIndicatorPos = lastItemPos;
									range.isEndOfParagraph = true;
									range.lastItemNode = lastItemNode;
								} else {
									// If no words rendered at all, use chunk end
									range.skipIndicatorPos = pos + childPos + childNode.nodeSize;
									range.isEndOfParagraph = true;
								}
							} else {
								range.isEndOfParagraph = false;
							}

							boundaries.push(range);
						});
					}
				}
			});
		}
	});

	return boundaries;
}

function createDecorationsFromBoundaries(doc, boundaries) {
	const decorations = [];
	boundaries.forEach(range => {
		if (range.itemAfterSkip) {
			decorations.push(
				Decoration.node(
					range.itemAfterSkip.pos,
					range.itemAfterSkip.pos + range.itemAfterSkip.node.nodeSize,
					{
						class: ITEM_AFTER_SKIP_CLASSNAME
					}
				)
			);
		}
	});
	return DecorationSet.create(doc, decorations);
}

const skipDecorationPlugin = new Plugin({
	key: skipDecorationPluginKey,
	state: {
		init(config, instance) {
			const boundaries = findSkipBoundaries(instance.doc);
			return {
				decorations: createDecorationsFromBoundaries(instance.doc, boundaries),
				boundaries
			};
		},
		apply(tr, old, oldState, newState) {
			if (!tr.docChanged && old && oldState === newState) return old;
			
			const boundaries = findSkipBoundaries(tr.doc);
			return {
				decorations: createDecorationsFromBoundaries(tr.doc, boundaries),
				boundaries
			};
		}
	},
	props: {
		decorations(state) {
			return this.getState(state).decorations;
		}
	},
 
});


export { skipDecorationPluginKey };
export default skipDecorationPlugin;
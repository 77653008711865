import {TimelineClip} from '../TimelineClip'
import {TextElement} from './slideElements/TextElement'
import {ImageElement} from './slideElements/ImageElement'
import {ChartElement} from './slideElements/ChartElement'
import {makeTextElement,makeImageElement,makeChartElement} from './slideUtils/makeSlideElement'
import {calculateLettersArrayAndPositions} from '../../serverSideExport/exportTimeline/calculateLettersArrayAndPositions'
import {calculateDynamicFontSize} from '../../serverSideExport/exportTimeline/calculateDynamicFontSize'
import {LayoutGroup} from './LayoutGroup'
import {alignSlideElements} from './slideUtils/alignSlideElements'
import { fetchProxyImage } from '../../actions/proxyImage';
import {randomID} from '../../utils/randomID'
import {getImageDimensions} from './slideUtils/getImageDimensions'
import getSlideConfigs from './slideUtils/getSlideConfigs'
import cloneDeep from 'lodash/cloneDeep'
import {measureNewTextElementHeight} from './slideUtils/measureNewTextElementHeight'
import {scaleLogoVariableImage} from './slideUtils/scaleLogoVariableImage'
import {calculateElementPositions} from './slideUtils/calculateElementPositions'

const slideConfigs = getSlideConfigs()
const {slideHPadding,slideVPadding,slideWidth,slideHeight}=slideConfigs

class SlideClip extends TimelineClip {

  constructor(options,scene,isExport,renderVariables,handleTextElementFontLoaded) {
    super(options)
    this.scene=scene
    this.isExport = isExport || false
    this.renderVariables=renderVariables || []
    this.handleTextElementFontLoaded=handleTextElementFontLoaded
    this.variables=[]
    if(options.layout){
       this.layout = this.createLayoutGroupFromJSON(options.layout);
     }else{
       this.layout = new LayoutGroup({ type: 'free' }); //root layout
     }
    if(isExport){
      this.loadPromise =  this.calculateSlideLayout()
    }
  }

  //alignType hAlign or vAlign
  //alignValue is left/right/center or top/middle/bottom
  alignSlideItems(slideItems, alignType, alignValue) {  
    if (slideItems.length === 1) {
      const item = slideItems[0];
      if (item.type === 'element') {
        const element = this.getElementById(item.id);
        if (element) {
          if (alignType === 'hAlign') {
            switch (alignValue) {
              case 'left':
                element.x = slideHPadding;
                if (element.type=='text') {
                  element.metadata.textHAlign='left'
                }
                break;
              case 'right':
                element.x = slideWidth - slideHPadding - element.width;
                 if (element.type=='text') {
                  element.metadata.textHAlign='right'
                }
                break;
              case 'center':
                element.x = (slideWidth - element.width) / 2;
                 if (element.type=='text') {
                  element.metadata.textHAlign='center'
                }
                break;
            }
          } else if (alignType === 'vAlign') {
            switch (alignValue) {
              case 'top':
                element.y = slideVPadding;
                 if (element.type=='text') {
                  element.metadata.textVAlign='top'
                }
                break;
              case 'bottom':
                element.y = slideHeight - slideVPadding - element.height;
                if (element.type=='text') {
                  element.metadata.textVAlign='bottom'
                }
                break;
              case 'middle':
                element.y = (slideHeight - element.height) / 2;
                 if (element.type=='text') {
                  element.metadata.textVAlign='middle'
                }
                break;
            }
          }
        }
      }
    }
  }

  useSlideTemplate(template) {
    this.metadata.slideTemplate = template.id;
    this.metadata.webcamLayout = template.content?.metadata?.webcamLayout;
    this.metadata.backgroundId = template.content?.metadata?.backgroundId;
    // Delete the current content
    [...this.layout.children].forEach((child) => {
      if (child instanceof LayoutGroup) {
        this.deleteLayoutGroup(child.id);
      } else {
        this.deleteElement(child.id);
      }
    });
    const newContent = template.content.layout.children;
    const applyContent = (contentArray, parentLayout) => {
      contentArray.forEach(child => {
        if (child.isLayoutGroup) {// Create a new LayoutGroup
          const newGroup = new LayoutGroup({
            ...child,
            id: randomID(),
            children: []
          });
          parentLayout.addChild(newGroup);
          // Recursively apply content to children
          applyContent(child.children, newGroup);
        } else {
          let newElement;
          switch (child.type) {
            case 'text':
              newElement = new TextElement({...child, id: randomID()}, this,this.handleTextElementFontLoaded);
              break;
            case 'image':
              newElement = new ImageElement({...child, id: randomID()}, this);
              break;
            case 'chart':
              newElement = new ChartElement({...child, id: randomID()}, this);
              break;
            default:
              console.warn(`Unknown element type: ${child.type}`);
              return;
          }
          parentLayout.addChild(newElement);
        }
      });
    };
    // Apply the new content to the existing root layout
    applyContent(newContent, this.layout);
    // Update root layout properties
    for (const key in template.content.layout) {
      if (key !== 'children' && key !== 'id') {
        this.layout[key] = template.content.layout[key];
      }
    }
    // Recalculate z-order and update other necessary properties
    this.recalculateZOrder();
    this.recalculateAnimationOrder()
    this.updateLayoutDepths();
    this.calculateClipVariables();
  }

  guessGroupingType(items) {  //TODO some more fancy guessing logic
    let allImages = true;
    let allLayoutGroups = true;
    for (const item of items) {
      if (item.type !== 'layoutGroup') {
        allLayoutGroups = false;
      }
      const element = item.type === 'element' ? this.getElementById(item.id) : this.getLayoutGroupById(item.id);
      if (element && !(element instanceof ImageElement)) {
        allImages = false;
      }
      if (!allLayoutGroups && !allImages) {
        break; // We can exit the loop early if we've determined it's neither all layout groups nor all images
      }
    }
    if (allLayoutGroups) {
      return 'horizontal';
    }
    return allImages ? 'horizontal' : 'vertical';
  }

  //group slide elements/layout groups into an hStack or vStack
  groupSlideItems(items, groupingType,newLayoutGroupId) {
    if (items.length === 0) return;
    // Find the parent layout of the first item
    const firstItem = items[0];
    const parentLayout = this.findParentLayout(firstItem.id); //TODO this should be the top level parent?
    if (!parentLayout) {
      console.error('Could not find parent layout for items');
      return;
    }
    let minX = Infinity, minY = Infinity;
    items.forEach(item => {
      const element = item.type === 'element' ? this.getElementById(item.id) : this.getLayoutGroupById(item.id);
      if (element) {
        minX = Math.min(minX, element.x);
        minY = Math.min(minY, element.y);
      }
    });
    if (!groupingType) {
      groupingType = this.guessGroupingType(items);
    }
    let hAlign,vAlign,type
    if(groupingType=='horizontal'){
      type='hstack'
      hAlign='left'
      vAlign='middle'
    }else{
      type='vstack'
      hAlign='center'
      vAlign='top'
    }
    
    const newGroup = new LayoutGroup({
      type: type,
      hAlign:hAlign,
      vAlign:vAlign,
      depth: parentLayout.depth + 1,
      id: newLayoutGroupId,
      x: minX,
      y: minY
    });
    const sortedItems = items.slice().sort((a, b) => {
      const elementA = a.type === 'element' ? this.getElementById(a.id) : this.getLayoutGroupById(a.id);
      const elementB = b.type === 'element' ? this.getElementById(b.id) : this.getLayoutGroupById(b.id);
      if (groupingType === 'horizontal') {
        return elementA.x - elementB.x;
      } else {
        return elementA.y - elementB.y;
      }
    });

    // If there are exactly two items, set the margin on the first item
    if (sortedItems.length === 2) {
      const firstElement = sortedItems[0].type === 'element' ? this.getElementById(sortedItems[0].id) : this.getLayoutGroupById(sortedItems[0].id);
      const secondElement = sortedItems[1].type === 'element' ? this.getElementById(sortedItems[1].id) : this.getLayoutGroupById(sortedItems[1].id);
      if (groupingType === 'horizontal') {
        firstElement.rightMargin = Math.max(secondElement.x - (firstElement.x + firstElement.width),50) //min margin
      } else {
        firstElement.bottomMargin = Math.max(secondElement.y - (firstElement.y + firstElement.height),50)
      }
    }

    sortedItems.forEach(item => {
      if (item.type === 'element') {
        const element = this.getElementById(item.id);
        if (element) {
          parentLayout.removeChild(item.id);
          newGroup.addChild(element);
        } else {
          console.warn(`Element with id ${item.id} not found`);
        }
      } else if (item.type === 'layoutGroup') {
        const layoutGroup = this.getLayoutGroupById(item.id);
        if (layoutGroup) {
          parentLayout.removeChild(item.id);
          newGroup.addChild(layoutGroup);
        } else {
          console.warn(`Layout group with id ${item.id} not found`);
        }
      }
    });
    parentLayout.addChild(newGroup);
    this.updateLayoutDepths()
  }

  //do the whole tree and set the depths
  updateLayoutDepths() {
    const updateDepth = (layout, currentDepth) => {
      layout.depth = currentDepth;
      layout.children.forEach(child => {
        if (child.isLayoutGroup) {
          updateDepth(child, currentDepth + 1);
        }
      });
    };
    updateDepth(this.layout, 0);
  }

  findParentLayout(elementId, currentLayout = this.layout) {
    for (const child of currentLayout.children) {
    if (child.id === elementId) {
      return currentLayout;
    }
    if (child instanceof LayoutGroup) {
      const result = this.findParentLayout(elementId, child);
      if (result) return result;
    }
    }
    return null;
  }

  ungroupLayoutGroup(layoutGroupId) {
    const layoutGroupToUngroup = this.getLayoutGroupById(layoutGroupId);
    if (!layoutGroupToUngroup) {
      console.error(`Layout group with id ${layoutGroupId} not found`);
      return;
    }

    const parentLayout = this.findParentLayout(layoutGroupId);
    if (!parentLayout) {
      console.error(`Parent layout for group ${layoutGroupId} not found`);
      return;
    }
    // Find the index of the layout group to be ungrouped
    const groupIndex = parentLayout.children.findIndex(child => child.id === layoutGroupId);
    // Move all children of the ungrouped layout to the parent
    layoutGroupToUngroup.children.forEach((child, index) => {
    parentLayout.addChild(child, groupIndex + index + 1);
    // Update the depth of the child and its descendants if it's a layout group
    this.updateLayoutDepths()
    });
    // Remove the ungrouped layout from the parent
    parentLayout.removeChild(layoutGroupId);
    this.cleanupEmptyLayouts(this.layout);
    this.recalculateZOrder();
  }

 
  updateLayoutGroupField(layoutGroupId, field,value) {
    const layoutGroup = this.getLayoutGroupById(layoutGroupId);
    if (!layoutGroup) {
      return;
    }
    layoutGroup[field] = value;
    if(field=='hAlign'){ //set textHAlign on any child text elements
      layoutGroup.children.forEach((child)=>{
        if(child.type=='text'){
          child.metadata.textHAlign = value
        }
      })
    }
  }

  isElementInLayoutGroup(elementId, groupId) {
    let foundInTargetGroup = false;
    const findInGroup = (layoutGroup, depth = 0) => {
      const indent = '  '.repeat(depth);
      let foundElement = false;
      for (const child of layoutGroup.children) {      
        if (child.id === elementId) {
          foundElement = true;
          if (depth > 0 && layoutGroup.id === groupId) {
            foundInTargetGroup = true;
          }
          break;
        }
        if (child.isLayoutGroup) {
          if (findInGroup(child, depth + 1)) {
            foundElement = true;
            if (depth > 0 && layoutGroup.id === groupId) {
              foundInTargetGroup = true;
            }
            break;
          }
        }
      }
      return foundElement;
    };

    findInGroup(this.layout);
    return foundInTargetGroup;
  }

  updateFromJSON(json) {
    for (const key in json) {
      if (key !== 'layout' && key !== 'metadata' && JSON.stringify(this[key]) !== JSON.stringify(json[key])) {
        this[key] = json[key];
      }
    }
    this.metadata = { ...json.metadata };
    this.updateLayoutAndElements(json.layout,this.layout)
    this.cleanupRemovedElements(json.layout)
  }

  updateLayoutAndElements(updatedLayout, existingLayout) {
    // Update layout properties
    for (const key in updatedLayout) {
      if (key !== 'children' && JSON.stringify(existingLayout[key]) !== JSON.stringify(updatedLayout[key])) {
        existingLayout[key] = updatedLayout[key];
      }
    }
    const updatedChildren = new Map(updatedLayout.children.map(child => [child.id, child]));
    const existingChildren = new Map(existingLayout.children.map(child => [child.id, child]));
    // Create a new array to hold the updated order of children
    const newChildrenOrder = [];
    // Process each child in the updated layout
    updatedLayout.children.forEach(updatedChild => {
      const existingChild = existingChildren.get(updatedChild.id);
      if (existingChild) {
        // Child exists, update it
        if (updatedChild.isLayoutGroup) {
          this.updateLayoutAndElements(updatedChild, existingChild);
        } else {
          this.updateSlideElement(existingChild, updatedChild);
        }
        newChildrenOrder.push(existingChild);
      } else {
        // Child is new, create it
        if (updatedChild.isLayoutGroup) {
          const newLayoutGroup = new LayoutGroup(updatedChild);
          this.updateLayoutAndElements(updatedChild, newLayoutGroup);
          newChildrenOrder.push(newLayoutGroup);
        } else {
          const newElement = this.createElementFromJSON(updatedChild);
          newChildrenOrder.push(newElement);
        }
      }
    });
    // Remove children that no longer exist in the updated layout
    existingLayout.children = newChildrenOrder;
  }


///// Z order stuff /////////
  updateElementZOrder(elementId,updateType){
    if(updateType=='front'){
      this.moveElementToFront(elementId)
    }else if(updateType=='forward'){
      this.moveElementForward(elementId)
    }else if(updateType=='back'){
    this.moveElementToBack(elementId)
    }else if(updateType=='backward'){
      this.moveElementBackward(elementId)
    }
  }

  recalculateZOrder() {
    const nonBGElements = this.elements.filter(element => !element.metadata.isBGImage);
    const sortedElements = nonBGElements.sort((a, b) => 
      (a.metadata.zOrder || 0) - (b.metadata.zOrder || 0)
    );
    sortedElements.forEach((element, index) => {
      element.updateElementField('metadata', {
        ...element.metadata,
        zOrder: index
      });
    });
  }


  recalculateAnimationOrder() {
      const nonBGElements = this.elements.filter(element => !element.metadata.isBGImage);
      const sortedElements = nonBGElements.sort((a, b) => 
        (a.metadata.animationOrderIndex || 0) - (b.metadata.animationOrderIndex || 0)
      );
      sortedElements.forEach((element, index) => {
        element.updateElementField('metadata', {
          ...element.metadata,
          animationOrderIndex: index
        });
      });
    }


  moveElementToFront(elementId) {
    const element = this.getElementById(elementId);
    if (element) {
      element.updateElementField('metadata', {
      ...element.metadata,
      zOrder: 1000
      });
      this.recalculateZOrder();
    }
  }
    
  moveElementForward(elementId) {
    const element = this.getElementById(elementId);
    if (element) {
      element.updateElementField('metadata', {
        ...element.metadata,
        zOrder: (element.metadata.zOrder || 0) + 1.5
      });
      this.recalculateZOrder();
    }
  }

  moveElementToBack(elementId) {
    const element = this.getElementById(elementId);
    if (element) {
      element.updateElementField('metadata', {
      ...element.metadata,
      zOrder: -1
    });
    this.recalculateZOrder();
    }
  }

  moveElementBackward(elementId) {
    const element = this.getElementById(elementId);
    if (element) {
      element.updateElementField('metadata', {
        ...element.metadata,
        zOrder: (element.metadata.zOrder || 0) - 1.5
      });
      this.recalculateZOrder();
    }
  }

  duplicateSlideItems(items){
    //need to add offset to the zOrder and animation order when duplicating
    //add the offset to make sure these items are at the end and then recalculate the whole slide 
    const zOrderOffset = this.elements.length
    items.forEach((item)=>{
      if(item.type=='layoutGroup'){
        this.duplicateLayoutGroup(item.originalId,item.duplicateItemId,zOrderOffset)
      }else{
        this.duplicateElement(item.originalId,item.duplicateItemId,zOrderOffset)
      }
    })
    this.recalculateZOrder();
    this.recalculateAnimationOrder()
  }

  duplicateLayoutGroup(layoutGroupId, newLayoutGroupId,zOrderOffset) {
    const originalGroup = this.getLayoutGroupById(layoutGroupId);
    if (!originalGroup) return null;

    const parentLayout = this.findParentLayout(layoutGroupId);
    if (!parentLayout) return null;

    const duplicateGroup = (group, newId) => {
      const newGroup = new LayoutGroup({
        ...group,
        id: newId,
        x: group.x + 50,
        y: group.y + 50,
        children: []
      });
      group.children.forEach(child => {
        if (child instanceof LayoutGroup) {
          const newChildId = randomID();
          const duplicatedChild = duplicateGroup(child, newChildId);
          newGroup.addChild(duplicatedChild);
        } else {
          const newChildId = randomID();
          const duplicatedChild = this.createDuplicateElement(child, newChildId,50,50,zOrderOffset);
          if (duplicatedChild) {
            newGroup.addChild(duplicatedChild);
          }
        }
      });
      return newGroup;
    };
    const newGroup = duplicateGroup(originalGroup, newLayoutGroupId);
    parentLayout.addChild(newGroup);
    return newGroup;
  }

  createDuplicateElement(originalElement, newElementId, offsetX = 50, offsetY = 50,zOrderOffset) {
    let newElement;
    let copiedElement = cloneDeep(originalElement);
    if(zOrderOffset){
      copiedElement.metadata.zOrder += zOrderOffset 
      copiedElement.metadata.animationOrderIndex += zOrderOffset
    }
    if (originalElement instanceof TextElement) {
      newElement = new TextElement(copiedElement, this,this.handleTextElementFontLoaded);
    } else if (originalElement instanceof ImageElement) {
      newElement = new ImageElement(copiedElement, this);
    } else if (originalElement instanceof ChartElement) {
      newElement = new ChartElement(copiedElement, this);
    } else {
      return null;
    }
    newElement.id = newElementId;
    newElement.x += offsetX;
    newElement.y += offsetY;
    return newElement;
  }

  duplicateElement(elementId, newElementId,zOrderOffset) {
    const originalElement = this.getElementById(elementId);
    if (!originalElement) return null;

    const parentLayout = this.findParentLayout(elementId);
    if (!parentLayout) return null;

    const newElement = this.createDuplicateElement(originalElement, newElementId,50,50,zOrderOffset);
    if (newElement) {
      parentLayout.addChild(newElement);
    }
    return newElement;
  }


  updateSlideElement(existingElement, updatedElement) {
    for (const key in updatedElement) {
      if (JSON.stringify(existingElement[key]) !== JSON.stringify(updatedElement[key])) {
        existingElement[key] = updatedElement[key];
      }
     }
    existingElement.slideClip = this;
  }

  cleanupRemovedElements(updatedLayout) {
    const updatedElementIds = this.getAllElementIds(updatedLayout);
    this.elements.forEach(element => {
      if (!updatedElementIds.has(element.id)) {
        const parentLayout = this.findParentLayout(element.id);
        if (parentLayout) {
          parentLayout.removeChild(element.id);
        }
      }
    });
    this.cleanupEmptyLayouts(this.layout);
  }

  getAllElementIds(layout) {
    const ids = new Set();
    const traverse = (node) => {
      if (node.isLayoutGroup) {
        node.children.forEach(traverse);
      } else {
        ids.add(node.id);
      }
    };
    traverse(layout);
    return ids;
  }

  alignSlideElements(selectedElementIds,alignType,alignValue){
    const selectedElements = selectedElementIds.map(id => this.getElementById(id)).filter(Boolean);
    alignSlideElements(selectedElements,alignType,alignValue)
  }

  updateSlideElementAnimationIndex(elementId, newIndex) {
    const element = this.getElementById(elementId);
    if (!element) {
      console.error(`Element with id ${elementId} not found`);
      return;
    }
    const oldIndex = element.metadata.animationOrderIndex;
    if (oldIndex === newIndex) return;
    // Get all elements and sort them by their current animationOrderIndex
    const sortedElements = this.elements.sort((a, b) => 
      a.metadata.animationOrderIndex - b.metadata.animationOrderIndex
    );
    // Remove the element from its current position
    sortedElements.splice(oldIndex, 1);
    // Insert the element at the new position
    sortedElements.splice(newIndex, 0, element);
    // Update animationOrderIndex for all elements
    sortedElements.forEach((el, index) => {
      el.updateElementField('metadata', {
        ...el.metadata,
        animationOrderIndex: index
      });
    });
  }


  updateTextElementTextProperties(elementId,textStyle,newTextProperties){
    const element = this.getElementById(elementId)
    if(element){
      element.updateTextProperties(textStyle,newTextProperties)
    }
  }

  updateImageWithUploadResponse(elementId,response){
    const element = this.getElementById(elementId)
    if(element){
      element.metadata.imgSrc=response.delivery_url
      element.metadata.semiTransparent=response.semi_transparent
    }else{
      console.log('no element')
    }
  }

  createLayoutGroupFromJSON(json) {
    const layout = new LayoutGroup(json);
    for (const childJSON of json.children) {
     if (childJSON.isLayoutGroup) {
        const childLayout = this.createLayoutGroupFromJSON(childJSON);
        layout.addChild(childLayout);
      } else {
        const element = this.createElementFromJSON(childJSON);
        layout.addChild(element);
      }
    }
    return layout;
  }

  getLayoutGroupById(id) {
    const findLayoutGroup = (layout) => {
      if (layout.id === id) return layout;
      for (const child of layout.children) {
        if (child instanceof LayoutGroup) {
          const found = findLayoutGroup(child);
          if (found) return found;
        }
      }
      return null;
    };
    return findLayoutGroup(this.layout);
  }

  createElementFromJSON(json) {
    let element;
    if (json.type === 'text') {
      element = new TextElement(json, this,this.handleTextElementFontLoaded);
    } else if (json.type === 'image') {
      element = new ImageElement(json, this);
    }
    else if (json.type === 'chart') {
      element = new ChartElement(json, this);
    }
    return element;
  }

  updateSlideAlignment(alignment,value){
    this.layout[alignment]=value
  }

////This is for export and inserting variable text and images

async calculateSlideLayout() {
  const variableTextColorId = this.renderVariables.variableTextColor;
  let hasTextToProcess = false;

  const getVariableValue = (variable) => {
    // Check if the variable contains array notation (e.g., "tools[0]")
    const arrayMatch = variable.match(/^(\w+)\[(\d+)\]$/);
    if (arrayMatch) {
      const [_, arrayName, index] = arrayMatch;
      // Check if the array exists and has the requested index
      if (Array.isArray(this.renderVariables[arrayName]) && 
          this.renderVariables[arrayName].length > parseInt(index)) {
        return this.renderVariables[arrayName][parseInt(index)] || ' ';
      }
      return ' '; // Return empty space if array or index doesn't exist
    }
    // // Regular variable handling
    // if(this.renderVariables[variable] =='you' || this.renderVariables[variable] =='You' || this.renderVariables[variable] =='there'){ //hacky for clay onboarding video
    //   return " "
    // }
    return this.renderVariables[variable] || ' ';
  };

  // First pass: collect elements to remove
  const elementsToRemove = new Set();

  const elementProcessingPromises = this.elements.map(async (element) => {
    if (element.metadata.variables && element.metadata.variables.length > 0 && element.type == 'text') {
      hasTextToProcess = true;
      const docJson = JSON.parse(element.metadata.docJson);
      
      const replaceVariablesInNode = (node) => {
        if (node.type === 'text') {
          let newText = node.text || ''; // Ensure non-null
          let marks = node.marks || [];

          element.metadata.variables.forEach(variable => {
            const variableValue = getVariableValue(variable);
            const formattedValue = String(variableValue).replace(/,/g, ' ');
            const templateToMatch = `{{${variable}}}`;
            newText = newText.split(templateToMatch).join(formattedValue);
          });

          if (!newText.trim()) {
            newText = ' ';
          }
          return { ...node, text: newText, marks };
        } else if (node.content) {
          return { ...node, content: node.content.map(replaceVariablesInNode) };
        }
        return node;
      };

      const newDocJson = replaceVariablesInNode(docJson);    
      element.metadata.docJson = JSON.stringify(newDocJson);
      
      // Update the plain text version for consistency
      let newText = element.metadata.text;
      element.metadata.variables.forEach(variable => {
        const variableValue = getVariableValue(variable);
        const formattedValue = String(variableValue).replace(/,/g, ' ');
        const templateToMatch = `{{${variable}}}`;
        newText = newText.split(templateToMatch).join(formattedValue);
      });
      
      element.metadata.text = newText;
      
      // Calculate dynamic font size if necessary
      if (element.metadata.variableTextOptions) {
        const { isDynamicFontSize, maxHeight } = element.metadata.variableTextOptions;
        let maxTextHeight = maxHeight 
        if(!maxHeight){
          maxTextHeight=element.height
        }
        if (isDynamicFontSize && maxTextHeight) { 
          await calculateDynamicFontSize(element, maxTextHeight);
        }
      }
      
      // Mark empty elements for potential removal
      if (!newText.trim()) {
        const parentLayout = this.findParentLayout(element.id);
        if (parentLayout && parentLayout.type !== 'free' && parentLayout.children.length > 1) {
          // Only mark for removal if not the last child
          elementsToRemove.add(element.id);
        }   
      }
    }
  });
  
  await Promise.all(elementProcessingPromises);

  // Second pass: remove elements safely
  elementsToRemove.forEach(elementId => {
    const parentLayout = this.findParentLayout(elementId);
    if (parentLayout && parentLayout.children.length > 1) {
      // Double check we're not removing the last child
     // console.log('Safely removing empty element:', elementId);
      parentLayout.removeChild(elementId);
    }
  });

  calculateElementPositions(this.layout);
  
  if (hasTextToProcess) {
    await calculateLettersArrayAndPositions(this, variableTextColorId);
  }
  
  await this.processImageElements();
  return;
}

// async calculateSlideLayout(){
//   const variableTextColorId = this.renderVariables.variableTextColor
//    let hasTextToProcess = false;

//   const elementProcessingPromises = this.elements.map(async (element) => {
//     //console.log(element.metadata.variables)

//     if (element.metadata.variables && element.metadata.variables.length > 0 && element.type == 'text') {
//       hasTextToProcess = true;
//       const docJson = JSON.parse(element.metadata.docJson);
//       const replaceVariablesInNode = (node) => {
//         if (node.type === 'text') {
//           let newText = node.text;
//           let marks = node.marks || [];
//           element.metadata.variables.forEach(variable => {
//             const variableValue = this.renderVariables[variable] || ' ';
//             const formattedValue = variableValue.replace(/,/g, ' ');
//             const escapedVariable = variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//             newText = newText.replace(new RegExp(`{{${escapedVariable}}}`, 'g'), formattedValue);
//             //newText = newText.replace(new RegExp(`{{${variable}}}`, 'g'), formattedValue);
//           });
//           return { ...node, text: newText, marks };
//         } else if (node.content) {
//           return { ...node, content: node.content.map(replaceVariablesInNode) };
//         }
//         return node;
//       };
//       const newDocJson = replaceVariablesInNode(docJson);      
//       element.metadata.docJson = JSON.stringify(newDocJson);

//       // Update the plain text version for consistency
//       let newText = element.metadata.text;
//       element.metadata.variables.forEach(variable => {
//         // Use empty string or space for missing values, just like in the docJson processing
//         const variableValue = this.renderVariables[variable] ?? ' ';
//         // Format the value consistently
//         const formattedValue = String(variableValue).replace(/,/g, ' ');
//         // Use the same split-join approach for consistency
//         const templateToMatch = `{{${variable}}}`;
//         newText = newText.split(templateToMatch).join(formattedValue);
//       });

//       element.metadata.text = newText;

//       // Calculate dynamic font size if necessary
//       if (element.metadata.variableTextOptions) {
//         const { isDynamicFontSize, maxHeight } = element.metadata.variableTextOptions;
//         if (isDynamicFontSize && maxHeight) { 
//           await calculateDynamicFontSize(element, maxHeight);
//         }
//       }
//       console.log('newText',newText)
//       if(!newText.trim()){
//         const parentLayout = this.findParentLayout(element.id);
//         if (parentLayout) {
//           console.log('remobe child here---------')
//           parentLayout.removeChild(element.id);
//         }   
//       }
//     }
//   });

//   await Promise.all(elementProcessingPromises);
//    calculateElementPositions(this.layout)
//   if(hasTextToProcess){
//     await calculateLettersArrayAndPositions(this,variableTextColorId);
//   }
//   await this.processImageElements();
//   return
// }


  async processImageElements() {
    for (const element of this.elements) {
      if (element.type === 'image' && element.metadata.isVariable) {
        const variable = element.metadata.variables[0];
        const variableImage = this.renderVariables[variable];
        if(variableImage){
          try {
          // Fetch the image via proxy and get the Blob URL
          const imageUrl = await fetchProxyImage(variableImage);
          console.log('Generated image URL:', imageUrl);
          // console.log(JSON.stringify(element))
          element.metadata.imgSrc = imageUrl; // Set the image source
          // // Get image dimensions using the alternative fetch + canvas method
          const dimensions = await getImageDimensions(imageUrl);
          element.metadata.original.width = dimensions.width;
          element.metadata.original.height = dimensions.height;
          if(variable=='prospectLogo' || element.metadata.areaScale){
            //do some scaling stuff 
            scaleLogoVariableImage(element)
          }
          console.log(`Width: ${dimensions.width}px, Height: ${dimensions.height}px`);
           calculateElementPositions(this.layout)
          } catch (error) {
          console.error('Error processing image:', error);
            const parentLayout = this.findParentLayout(element.id);
            if (parentLayout) {
              parentLayout.removeChild(element.id);
              calculateElementPositions(this.layout)
            }      
          } 
        }else{
          const parentLayout = this.findParentLayout(element.id);
          if (parentLayout) {
            parentLayout.removeChild(element.id);
            calculateElementPositions(this.layout)
          }
        }
       
      }
    }
  }

  calculateClipVariables() {
    const allVariables = new Set();
    this.elements.forEach(element => {
      if (element.metadata && Array.isArray(element.metadata.variables)) {
        element.metadata.variables.forEach(variable => {
          allVariables.add(variable);
        });
      }
    });
    const variables = Array.from(allVariables);
    this.metadata.variables=variables
  }

  updateElementDimensions(elementId,newDimensions){
    const element = this.getElementById(elementId)
    if(element){
      element.updateDimensions(newDimensions)
    }
  }


 updateElementField(elementId,field,value){
    const element = this.getElementById(elementId)
    if(element){
      element.updateElementField(field,value)
    }
  }

  updateElementMetadata(elementId,newMetadata){
    const element = this.getElementById(elementId)
    if(element){
      element.updateMetadata(newMetadata)
      this.calculateClipVariables()
    }
  }

  updateElementText(elementId,lettersArray,text,docJson){
    const element = this.elements.find(s => s.id === elementId)
    if(element){
      let newMetadata = {...element.metadata}
      newMetadata.text = text 
      let variables =[]
      const variableRegex = /{{(.*?)}}/g;
      let match;
      while ((match = variableRegex.exec(text)) !== null) {
        variables.push(match[1]);
      }
      newMetadata.variables = variables
      newMetadata.lettersArray = lettersArray 
      newMetadata.docJson = docJson 
      element.updateMetadata(newMetadata)
      this.calculateClipVariables()
    }
  }

  updateImageElementImage(imgObj,replaceElementId){
    const element = this.elements.find(s => s.id === replaceElementId)
    if(element){
      element.metadata.imgSrc = imgObj.delivery_url
      element.metadata.original.width = imgObj.original_width
      element.metadata.original.height = imgObj.original_height
      element.metadata.originalFilename = imgObj.original_filename
      const maxWidth = 600
      const maxHeight = 300
      const aspectRatio = imgObj.original_width / imgObj.original_height
      if(!element.metadata.isFill){
        if (aspectRatio > maxWidth / maxHeight) {
          element.width = Math.min(maxWidth, imgObj.original_width)
          element.height = element.width / aspectRatio
          element.width
        } else {
          // Height is the limiting factor
          element.height = Math.min(maxHeight, imgObj.original_height)
          element.width = element.height * aspectRatio
        }
       }
    }
  }

updateImageElementImage(imgObj, replaceElementId) {
  const element = this.elements.find(s => s.id === replaceElementId);
  if (element) {
    element.metadata.imgSrc = imgObj.delivery_url;
    element.metadata.original.width = imgObj.original_width;
    element.metadata.original.height = imgObj.original_height;
    element.metadata.originalFilename = imgObj.original_filename;
    const maxWidth = element.width;
    const maxHeight = element.height;
    // Calculate aspect ratios
    const newAspectRatio = imgObj.original_width / imgObj.original_height;
    const elementAspectRatio = maxWidth / maxHeight;
    if (!element.metadata.isFill) {
      // Determine which dimension to prioritize based on aspect ratio difference
      if (Math.abs(newAspectRatio - elementAspectRatio) < 0.1) {
        // Aspect ratios are similar, maintain current dimensions
        // No changes needed to width and height
      } else if (newAspectRatio > elementAspectRatio) {
        // New image is wider, prioritize width
        element.width = maxWidth;
        element.height = element.width / newAspectRatio;
        
        // If height exceeds max, adjust both dimensions
        if (element.height > maxHeight) {
          element.height = maxHeight;
          element.width = element.height * newAspectRatio;
        }
      } else {
        // New image is taller, prioritize height
        element.height = maxHeight;
        element.width = element.height * newAspectRatio;

        // If width exceeds max, adjust both dimensions
        if (element.width > maxWidth) {
          element.width = maxWidth;
          element.height = element.width / newAspectRatio;
        }
      }
    }
    // Round dimensions to prevent floating point issues
    element.width = Math.round(element.width);
    element.height = Math.round(element.height);
  }
}

  //position image in the center of the slide- set element x and y
    async centerNewElement(element) {
      if (element.type == 'text') {
        await measureNewTextElementHeight(element);
      }
      // Calculate the center position
      const currentElementCount = this.elements.length;
      const offset = 50 * currentElementCount;
      const centerX = (slideWidth - element.width) / 2;
      const centerY = (slideHeight - element.height) / 2;
      // Set the element's position
      element.x = Math.min(centerX + offset, 1920 * 0.75);
      element.y = Math.min(centerY + offset, 1080 * 0.75);
    }


  addImageElement(imgObj,elementId,dropPosition) {
    let newElement = makeImageElement(this, false,elementId)
    newElement.metadata.imgSrc = imgObj.delivery_url
    newElement.previewUrl = imgObj.delivery_url //this is not saved only done when first add image to prevent flashing when switch to uploaded image (dont need this for insert from recent when already have cloudianry url but can do this later)
    newElement.metadata.original.width = imgObj.original_width
    newElement.metadata.original.height = imgObj.original_height
    newElement.metadata.originalFilename = imgObj.original_filename
    const maxWidth = 600
    const maxHeight = 300
    const aspectRatio = imgObj.original_width / imgObj.original_height
    if (aspectRatio > maxWidth / maxHeight) {// Width is the limiting factor
      newElement.width = Math.min(maxWidth, imgObj.original_width)
      newElement.height = newElement.width / aspectRatio
      newElement.width
    } else {// Height is the limiting factor
      newElement.height = Math.min(maxHeight, imgObj.original_height)
      newElement.width = newElement.height * aspectRatio
    }
    if (dropPosition) {
      newElement.x = dropPosition.x;
      newElement.y = dropPosition.y;
      // Adjust position to center the image at the drop point
      newElement.x -= newElement.width / 2;
      newElement.y -= newElement.height / 2;
      // Ensure the image stays within the slide boundaries
      newElement.x = Math.max(0, Math.min(newElement.x, slideWidth - newElement.width));
      newElement.y = Math.max(0, Math.min(newElement.y, slideHeight - newElement.height));
    } else {
      // If no drop position, center the element as before
      this.centerNewElement(newElement);
    }
    this.elements.push(newElement)
    this.layout.addChild(newElement);
  }

  cleanupEmptyLayouts(layout) {
    if (layout.id === this.layout.id) {
      // Don't process the root layout, just its children
      layout.children.forEach(child => {
        if (child instanceof LayoutGroup) {
          this.cleanupEmptyLayouts(child);
        }
      });
      return;
    }
    const parentLayout = this.findParentLayout(layout.id);
    if (!parentLayout) return;
    if (layout.children.length === 0) {
      // Remove empty layout groups
      parentLayout.removeChild(layout.id);
    } else if (layout.children.length === 1) {
      // If there's only one child, move it to the parent layout
      const onlyChild = layout.children[0];
      layout.removeChild(onlyChild.id);
      parentLayout.removeChild(layout.id);
      parentLayout.addChild(onlyChild);
      // If the only child is a LayoutGroup, we need to process it as well
      if (onlyChild instanceof LayoutGroup) {
        this.cleanupEmptyLayouts(onlyChild);
      }
    } else {
      // If there are multiple children, process each of them
      layout.children.forEach(child => {
        if (child instanceof LayoutGroup) {
          this.cleanupEmptyLayouts(child);
        }
      });
    }
  }


  deleteItems(items) {
    const deleteItem = (item) => {
      if (item.type === 'element') {
        this.deleteElement(item.id);
      } else if (item.type === 'layoutGroup') {
        this.deleteLayoutGroup(item.id);
     }
    };
    items.forEach(deleteItem);
    this.cleanupEmptyLayouts(this.layout);
    this.calculateClipVariables();
    this.recalculateZOrder();
    this.recalculateAnimationOrder()
  }

  deleteElement(elementId) {
    const parentLayout = this.findParentLayout(elementId);
    if (parentLayout) {
      parentLayout.removeChild(elementId);
    }
  }

  deleteLayoutGroup(groupId) {
    const group = this.getLayoutGroupById(groupId);
    if (!group) return;
    const parentLayout = this.findParentLayout(groupId);
    if (!parentLayout) return;
    // Recursively delete all children
    [...group.children].forEach(child => {
      if (child instanceof LayoutGroup) {
        this.deleteLayoutGroup(child.id);
      } else {
        this.deleteElement(child.id);
      }
    });
    // Remove the group itself from its parent
    parentLayout.removeChild(groupId);
  }

  initSlideElement(element){
    let newElement 
    if(element.type=='text'){
      newElement = new TextElement(element,this,this.handleTextElementFontLoaded)
    }
    else if(element.type=='image'){
      newElement = new ImageElement(element,this)
    }
     else if(element.type=='chart'){
      newElement = new ChartElement(element,this)
    }
    this.layout.addChild(newElement)
  } 

  async addSlideElement(type, isVariable,newElementId) {
    let newElement;
    if (type == 'text') {
      newElement = makeTextElement(this,newElementId);
      await this.centerNewElement(newElement);
    }
    else if (type == 'chart') {
      newElement = makeChartElement(this, newElementId);
      this.centerNewElement(newElement);
    }
    this.layout.addChild(newElement);
    return newElement.id;
  }


  getElementById(id) {
    if (this.layout.id === id) return this.layout;
    return this.layout.findChildById(id);
  }

  get elements() {
    const allElements = [];
    const traverseLayout = (layout) => {
      for (const child of layout.children) {
        if (child instanceof TextElement || child instanceof ImageElement || child instanceof ChartElement) {
          allElements.push(child);
        } else if (child instanceof LayoutGroup) {
          traverseLayout(child);
        }
      }
    };
    traverseLayout(this.layout);
    return allElements;
  }

  get layoutGroups() {
    const allLayoutGroups = [];
    const traverseLayout = (layout) => {
      if (layout instanceof LayoutGroup) {
        allLayoutGroups.push(layout);
        for (const child of layout.children) {
          if (child instanceof LayoutGroup) {
            traverseLayout(child);
          }
        }
      }
    };
    traverseLayout(this.layout);
    return allLayoutGroups;
  }

  toJSON() {
    const layoutJSON = this.layout.toJSON()
    const clipJSON =  {
      id: this.id,
      type:this.type,
      startTime:this._startTime,
      duration:this.duration,
      metadata:this.metadata,
      zIndex:this.zIndex,
      layout:layoutJSON
    };
    return clipJSON
  }

  get hAlign() {
    return this.layout.hAlign
  }

  get vAlign() {
    return this.layout.vAlign
  }

  destroy() { //TODO
   
  }
  
}


export { SlideClip }

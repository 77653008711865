import { findParentNode } from './findParentNode'
import { findNextNodePosition } from './findNextNodePosition'
import { TextSelection } from 'prosemirror-state'

// function extractItemData(itemNode) {
// 	return {
// 		text: itemNode.textContent,
// 		startTime: itemNode.attrs.startTime,
// 		endTime: itemNode.attrs.endTime,
// 		originalIndex: itemNode.attrs.originalIndex
// 	}
// }

function mergeChunks(tr, baseChunkPos, baseChunk, targetChunkPos, targetChunk) {
	const baseContent = baseChunk.content
	const targetContent = targetChunk.content
	
	// Create new chunk with merged content and updated indices
	const newChunk = baseChunk.type.create(
		{
			...baseChunk.attrs,
			startTime: Math.min(baseChunk.attrs.startTime, targetChunk.attrs.startTime),
			endTime: Math.max(baseChunk.attrs.endTime, targetChunk.attrs.endTime)
		},
		baseContent.append(targetContent)
	)

	// Replace both chunks with the merged one
	const startPos = Math.min(baseChunkPos, targetChunkPos)
	const endPos = Math.max(
		baseChunkPos + baseChunk.nodeSize,
		targetChunkPos + targetChunk.nodeSize
	)
	
	tr.replaceWith(startPos, endPos, newChunk)
	return startPos + baseContent.size + 1
}

function handleEmptyChunk(tr, chunk, chunkPos, doc) {
	// Find adjacent chunks
	let prevChunk = null
	let prevChunkPos = null
	let nextChunk = null
	let nextChunkPos = null
	let foundTarget = false
	
	doc.descendants((node, pos) => {
		if (node.type.name === 'webcamRecordingChunk') {
			if (pos === chunkPos) {
				foundTarget = true
			} else if (!foundTarget) {
				prevChunk = node
				prevChunkPos = pos
			} else if (foundTarget && !nextChunk) {
				nextChunk = node
				nextChunkPos = pos
				return false
			}
		}
		return true
	})

	// Prefer merging with previous chunk if available
	if (prevChunk) {
		return mergeChunks(tr, prevChunkPos, prevChunk, chunkPos, chunk)
	} else if (nextChunk) {
		return mergeChunks(tr, chunkPos, chunk, nextChunkPos, nextChunk)
	}
	
	return null
}

function storeAndRemoveItems(tr, groupPos, items) {
	const currentGroup = tr.doc.nodeAt(groupPos)
	const currentSkipRanges = currentGroup?.attrs.skipRanges || []
	
	// Find the chunk containing the first item
	const firstItemPos = items[0].pos
	let groupInfo = null
	let chunkInfo = null
	
	tr.doc.nodesBetween(groupPos, groupPos + currentGroup.nodeSize, (node, pos) => {
		if (node.type.name === 'transcriptGroup') {
			if (firstItemPos > pos && firstItemPos < (pos + node.nodeSize)) {
				groupInfo = { node, pos }
				return false
			}
		}
		return true
	})

	tr.doc.nodesBetween(groupPos, groupPos + currentGroup.nodeSize, (node, pos) => {
		if (node.type.name === 'webcamRecordingChunk') {
			if (firstItemPos > pos && firstItemPos < (pos + node.nodeSize)) {
				chunkInfo = { node, pos }
				return false
			}
		}
		return true
	})

	// Create a new skip range from the items
	const newSkipRange = {
		startTime: Math.min(...items.map(({ node }) => node.attrs.startTime)),
		endTime: Math.max(...items.map(({ node }) => node.attrs.endTime))
	}

	// Add new range and sort all ranges by start time
	const mergedRanges = [...currentSkipRanges, newSkipRange].sort((a, b) => a.startTime - b.startTime)
	
	// Merge adjacent ranges that have no items between them
	for (let i = 0; i < mergedRanges.length - 1; i++) {
		const currentRange = mergedRanges[i]
		const nextRange = mergedRanges[i + 1]
		
		// Check if there are any items between these ranges
		let hasItemsBetween = false
		tr.doc.nodesBetween(groupPos, groupPos + currentGroup.nodeSize, (node, pos) => {
			if (node.type.name === 'webcamTranscriptItem') {
				const itemStartTime = node.attrs.startTime
				const itemEndTime = node.attrs.endTime
				// Check if this item would prevent merging the ranges
				if (itemStartTime >= currentRange.endTime && itemEndTime <= nextRange.startTime) {
					hasItemsBetween = true
					return false // Stop searching
				}
			}
			return true
		})

		// If no items between ranges, merge them
		if (!hasItemsBetween) {
			mergedRanges[i] = {
				startTime: Math.min(currentRange.startTime, nextRange.startTime),
				endTime: Math.max(currentRange.endTime, nextRange.endTime)
			}
			// Remove the next range since it's been merged
			mergedRanges.splice(i + 1, 1)
			// Decrement i since we need to check the new merged range with the next range
			i--
		}
	}

	// Update group attributes with the merged ranges
	tr.setNodeMarkup(groupPos, null, {
		...currentGroup.attrs,
		skipRanges: mergedRanges
	})

	// Delete items in reverse order
	for (let i = items.length - 1; i >= 0; i--) {
		const { pos, node } = items[i]
		tr.delete(pos, pos + node.nodeSize)
	}

	// If we found the chunk and it's now empty after deletion, handle merging
	if (chunkInfo) {
		const updatedChunk = tr.doc.nodeAt(chunkInfo.pos)
		if (updatedChunk.content.size === 0) {
			return handleEmptyChunk(tr, updatedChunk, chunkInfo.pos, tr.doc)
		}
	}
	
	return null
}

export function handleBackspaceInRecordingChunk(view, event, maybeMergeRecordingClipFromTranscript) {
	const { state } = view
	const { selection, doc } = state
	const { $from, $to } = selection
	
	const parent = findParentNode(
		node => node.type.name === 'transcriptGroup',
		selection
	)
	
	if (!parent || parent.node.attrs.groupType !== 'webcam-recording') {
		return false
	}

	if (!selection.empty) {
		const tr = state.tr
		let firstItemPos = null
		const itemsToProcess = []
		const chunkPositions = new Set()

		// First pass: collect all items and chunks
		tr.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
			if (node.type.name === 'webcamTranscriptItem') {
				if (firstItemPos === null) {
					firstItemPos = pos
				}
				itemsToProcess.push({ node, pos })
			} else if (node.type.name === 'webcamRecordingChunk') {
				chunkPositions.add(pos)
			}
			return true
		})

		if (itemsToProcess.length > 0) {
			// Store and remove all items
			storeAndRemoveItems(tr, parent.pos, itemsToProcess)

			// If we have multiple chunks to merge
			if (chunkPositions.size > 1) {
				// Get chunks in order and find their new positions after deletion
				const sortedChunkPositions = Array.from(chunkPositions).sort((a, b) => a - b)
				let baseChunkPos = sortedChunkPositions[0]
				let baseChunk = tr.doc.nodeAt(baseChunkPos)

				// Merge subsequent chunks into the base chunk
				for (let i = 1; i < sortedChunkPositions.length; i++) {
					const nextChunkPos = tr.mapping.map(sortedChunkPositions[i])
					const nextChunk = tr.doc.nodeAt(nextChunkPos)
					
					if (nextChunk && baseChunk) {
						// Merge the chunks
						const mergedContent = baseChunk.content.append(nextChunk.content)
						const newChunk = baseChunk.type.create(
							{
								...baseChunk.attrs,
								startTime: Math.min(baseChunk.attrs.startTime, nextChunk.attrs.startTime),
								endTime: Math.max(baseChunk.attrs.endTime, nextChunk.attrs.endTime)
							},
							mergedContent
						)

						// Replace the two chunks with the merged one
						tr.replaceWith(
							baseChunkPos,
							nextChunkPos + nextChunk.nodeSize,
							newChunk
						)

						// Update base chunk for next iteration
						baseChunk = newChunk
					}
				}

				// Set cursor to end of merged chunk by finding the last item in the chunk
				const finalChunk = tr.doc.nodeAt(baseChunkPos)
				let lastItemPos = null
				finalChunk.descendants((node, pos) => {
					if (node.type.name === 'webcamTranscriptItem') {
						lastItemPos = baseChunkPos + pos + node.nodeSize
					}
				})

				tr.setSelection(TextSelection.create(tr.doc, lastItemPos || (baseChunkPos + finalChunk.nodeSize - 1)))

			} else {
				// If only one chunk, move cursor to start of selection
				tr.setSelection(TextSelection.create(tr.doc, firstItemPos))
			}

			view.dispatch(tr)
			event.preventDefault()
			return true
		}
	}

	const itemParent = findParentNode(
		node => node.type.name === 'webcamTranscriptItem',
		selection
	)

	if (itemParent && parent) {
		const tr = state.tr
		const newCursorPos = storeAndRemoveItems(tr, parent.pos, [{
			node: itemParent.node,
			pos: itemParent.pos
		}])
		// Move cursor based on merge result or default to before the removed item
		tr.setSelection(TextSelection.create(
			tr.doc,
			newCursorPos?newCursorPos-1: (itemParent.pos - 2)
		))
		
		view.dispatch(tr)
		event.preventDefault()
		return true
	}

	// Handle chunk merging and cursor movement
	let currentChunkInfo = {
		chunk: null,
		pos: null,
		isAtStart: false,
		isAtEnd: false,
		previousChunk: null,
		previousChunkPos: null
	}

	let previousChunk = null
	let previousChunkPos = null
	
	doc.nodesBetween(parent.pos, parent.pos + parent.node.nodeSize, (node, pos) => {
		if (node.type.name === 'webcamRecordingChunk') {
			if ($from.pos > pos && $from.pos < (pos + node.nodeSize)) {
				currentChunkInfo = {
					chunk: node,
					pos,
					isAtStart: $from.pos === pos + 1,
					isAtEnd: $from.pos === pos + node.nodeSize - 1,
					previousChunk,
					previousChunkPos
				}
				return false
			}
			previousChunk = node
			previousChunkPos = pos
		}
		return true
	})

	if (!currentChunkInfo.chunk) {
		return false
	}
   
	console.log('currentChunkInfo',currentChunkInfo)
	console.log('currentChunkInfo.previousChunk',currentChunkInfo.previousChunk)

	//if we are at the start of a chunk and there is no previous chunk we can check if the clip before can be merged
	if(currentChunkInfo.isAtStart && !currentChunkInfo.previousChunk){
		console.log('maybe merge with previous clip')
		maybeMergeRecordingClipFromTranscript(currentChunkInfo.chunk.attrs.clipId)
	}

	if (currentChunkInfo.isAtStart && currentChunkInfo.previousChunk) {
		const tr = state.tr
		const previousChunkContent = currentChunkInfo.previousChunk.content
		const currentChunkContent = currentChunkInfo.chunk.content
		
		// Create new chunk with merged content and updated indices
		const newChunk = currentChunkInfo.previousChunk.type.create(
			{
				...currentChunkInfo.previousChunk.attrs,
				startTime: currentChunkInfo.previousChunk.attrs.startTime,
				endTime: currentChunkInfo.chunk.attrs.endTime
			},
			previousChunkContent.append(currentChunkContent)
		)

		tr.replaceWith(
			currentChunkInfo.previousChunkPos,
			currentChunkInfo.pos + currentChunkInfo.chunk.nodeSize,
			newChunk
		).setSelection(TextSelection.create(
			tr.doc,
			//currentChunkInfo.previousChunkPos + previousChunkContent.size + 2
			currentChunkInfo.previousChunkPos + previousChunkContent.size
		))
		
		view.dispatch(tr)
		event.preventDefault()
		return true
	}

	const prevWordPos = findNextNodePosition(
		doc,
		$from.pos,
		currentChunkInfo.pos + 1,
		['webcamTranscriptItem'],
		'backward'
	)

	if (prevWordPos !== null) {
		view.dispatch(state.tr.setSelection(TextSelection.create(doc, prevWordPos)))
		event.preventDefault()
		return true
	}

	if (!currentChunkInfo.isAtStart) {
		view.dispatch(state.tr.setSelection(TextSelection.create(doc, currentChunkInfo.pos + 1)))
		event.preventDefault()
		return true
	}

	return true
}
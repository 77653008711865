import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import EditorGeneratePopoverCodeBox from './EditorGeneratePopoverCodeBox';

function generateStructuredPostRequest(projectID) {
  return [
    { text: 'https://api.yarn.so/projects/', type: 'url' },
    { text: projectID, type: 'variable' },
    { text: '/generate-variant', type: 'url' }
  ];
}

function generateStructuredApiResponse() {
  const parts = [];
  
  parts.push(
    { text: '{\n', type: 'plain' },
    { text: '  "', type: 'plain' },
    { text: 'requestId', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: ',\n', type: 'plain' },
    { text: '  "', type: 'plain' },
    { text: 'status', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '"queued"', type: 'literal' },
    { text: '\n', type: 'plain' },
    { text: '}', type: 'plain' }
  );

  return parts;
}

function generateStructuredWebhookCallback() {
  const parts = [];
  
  parts.push(
    { text: '{\n', type: 'plain' },
    // status
    { text: '  "', type: 'plain' },
    { text: 'status', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '"completed"', type: 'literal' },
    { text: ',\n', type: 'plain' },
    // externalID
    { text: '  "', type: 'plain' },
    { text: 'externalId', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: ',\n', type: 'plain' },
    // requestID
    { text: '  "', type: 'plain' },
    { text: 'requestId', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: ',\n', type: 'plain' },
    // deliveryURL
    { text: '  "', type: 'plain' },
    { text: 'deliveryUrl', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: ',\n', type: 'plain' },
    // gifURL
    { text: '  "', type: 'plain' },
    { text: 'gifUrl', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: ',\n', type: 'plain' },
     // thumbnailUrl
    { text: '  "', type: 'plain' },
    { text: 'thumbnailUrl', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'placeholder' },
    { text: '\n', type: 'plain' },

    { text: '}', type: 'plain' },
  );

  return parts;
}

function generateStructuredJSON(variableNames) {
  const parts = [];
  
  // Opening brace
  parts.push({ text: '{\n', type: 'plain' });
  
  // callbackURL
  parts.push(
    { text: '  "', type: 'plain' },
    { text: 'callbackUrl', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'string' },
    { text: ',\n', type: 'plain' }
  );

  // externalId
  parts.push(
    { text: '  "', type: 'plain' },
    { text: 'externalId', type: 'property' },
    { text: '": ', type: 'plain' },
    { text: '<string>', type: 'string' },
    { text: ',\n', type: 'plain' }
  );

  // variables object
  parts.push(
    { text: '  "', type: 'plain' },
    { text: 'variables', type: 'property' },
    { text: '": {\n', type: 'plain' }
  );

  // Add each variable
  variableNames.forEach((varName, index) => {
    parts.push(
      { text: '    "', type: 'plain' },
      { text: varName, type: 'property' },
      { text: '": ', type: 'plain' },
      { text: '<string>', type: 'string' },
      { text: index < variableNames.length - 1 ? ',\n' : '\n', type: 'plain' }
    );
  });

  // Close variables object
  parts.push(
    { text: '  }\n', type: 'plain' },
    { text: '}', type: 'plain' }
  );

  return parts;
}

function EditorGeneratePopoverAPI({
  stringVariables,
  project
}) {

  // console.log('stringVariables')
  // console.log(stringVariables)

  const variableNames = stringVariables

  const projectID = project.id;
  const structuredJSON = generateStructuredJSON(variableNames);
  const postRequest = generateStructuredPostRequest(projectID);
  const apiResponse = generateStructuredApiResponse();
  const webhookCallback = generateStructuredWebhookCallback();

  return (
    <div className='generatePopover-main'>      
      <div className='generatePopover-section'>
        <div className='generatePopover-section-header'>
          Generate Request
        </div>
        <EditorGeneratePopoverCodeBox 
          content={postRequest}
          type="postRequest"
        />

        <div className='generatePopover-tabDescription generatePopover-tabDescription--bright'>
          <div className='generatePopover-tabDescription-para'>
            Generate video variations on this project by passing through variables in a POST request. 
          </div>
          <div className='generatePopover-tabDescription-para'>
            Video generation is asynchronous and relies on webhooks.
          </div>
          <div className='generatePopover-tabDescription-para'>
            You'll receive back a delivery page URL with streamable HLS video player.
          </div>
        </div>    

      </div>

      <div className='generatePopover-divider' />

      <div className='generatePopover-section'>
        <div className='generatePopover-section-header'>
          Request Body
          <div className='generatePopover-section-header-jsonTag'>
            JSON
          </div>
        </div>       
        {/*}     
        <div className='generatePopover-tabDescription generatePopover-tabDescription--bright generatePopover-tabDescription--belowSection'>
          <div className='generatePopover-tabDescription-para'>
            Add documentation
          </div>
        </div>   
        */} 
        <EditorGeneratePopoverCodeBox 
          content={structuredJSON} 
          type="json" 
          tight
          responseCode="202"          
        />        
      </div>

      <div className='generatePopover-divider' />

      <div className='generatePopover-section'>
        <div className='generatePopover-section-header'>          
          Initial Response                    
          <div className='generatePopover-section-header-jsonTag'>
            JSON
          </div>          
        </div>
        {/*}
        <div className='generatePopover-tabDescription generatePopover-tabDescription--bright generatePopover-tabDescription--belowSection'>
          <div className='generatePopover-tabDescription-para'>
            Add documentation
          </div>
        </div>    
        */}
        <EditorGeneratePopoverCodeBox 
          content={apiResponse}
          type="json"
          tight
          jsonTag
        />
      </div>

      <div className='generatePopover-divider' />

      <div className='generatePopover-section'>
        <div className='generatePopover-section-header'>
          Webhook Callback Payload
          <div className='generatePopover-section-header-jsonTag'>
            JSON
          </div>          
        </div>
        {/*}
        <div className='generatePopover-tabDescription generatePopover-tabDescription--bright generatePopover-tabDescription--belowSection'>
          <div className='generatePopover-tabDescription-para'>
            Add documentation
          </div>
        </div>  
        */}  
        <EditorGeneratePopoverCodeBox 
          content={webhookCallback}
          type="json"
          tight
        />
      </div>
    </div>
  );
}

export default EditorGeneratePopoverAPI;
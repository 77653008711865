import {getMasterRecordingData} from '../recordings/screenRecordings/getMasterRecordingData'
import resizeDeviceToFit from '../../three/utils/resizeDeviceToFit';
import { convertZoomValuesToZoomBox } from '../../three/utils/zoom/convertZoomValuesToZoomBox'; 
import { calculatePolishedZoomBox } from './calculatePolishedZoomBox';

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080
const CHROME_OFFSET_DEFAULT = 87;
const CHROME_OFFSET_BOOKMARKS_BAR = 120;

function calculateAdjustedBoundingBoxForDeviceFrame(boundingBox, masterRecordingData, deviceConfig) {
  let chromeHeightOffset = 0;
  if (deviceConfig.screenVideoDevice === 'chrome') {
    if (deviceConfig.screenVideoDeviceFrame === 'color') {
      chromeHeightOffset = CHROME_OFFSET_DEFAULT;
    } else if (deviceConfig.screenVideoDeviceFrame === 'colorBB') {
      chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR;
    }
  }

  // If no frame adjustment needed, return original
  if (!deviceConfig.screenVideoDevice || !deviceConfig.screenVideoDeviceFrame) {
    return boundingBox;
  }

  // Convert y coordinates to new frame space while staying in 0-1
  const chromeOffsetRatio = chromeHeightOffset / masterRecordingData.recordingHeight;
  const frameHeightRatio = (masterRecordingData.recordingHeight - chromeHeightOffset) / masterRecordingData.recordingHeight;
  
  return {
    x1: boundingBox.x1,
    x2: boundingBox.x2,
    y1: (boundingBox.y1 - chromeOffsetRatio) / frameHeightRatio,
    y2: (boundingBox.y2 - chromeOffsetRatio) / frameHeightRatio
  };
}

function calculateBoundingBoxInCanvas(boundingBox, width, height, videoWindowPadding) {
  const dimensions = resizeDeviceToFit(width, height, videoWindowPadding, SCENE_WIDTH, SCENE_HEIGHT);
  const windowX = (SCENE_WIDTH - dimensions.meshWidth) / 2;
  const windowY = (SCENE_HEIGHT - dimensions.meshHeight) / 2;

  return {
    x1: windowX + (boundingBox.x1 * dimensions.meshWidth),
    y1: windowY + (boundingBox.y1 * dimensions.meshHeight),
    x2: windowX + (boundingBox.x2 * dimensions.meshWidth),
    y2: windowY + (boundingBox.y2 * dimensions.meshHeight)
  };
}

function calculateZoomValuesForCanvasBoundingBox(canvasBox) {
    // Calculate box dimensions
    const boxWidth = canvasBox.x2 - canvasBox.x1
    const boxHeight = canvasBox.y2 - canvasBox.y1
    
    // Calculate center point relative to canvas center (0,0)
    // Convert from top-left (1920x1080) to center (0,0) coordinate system
    const centerX = canvasBox.x1 + (boxWidth / 2) - (SCENE_WIDTH / 2)
    const centerY = canvasBox.y1 + (boxHeight / 2) -  (SCENE_HEIGHT / 2)

    // Calculate scale 
    const scale = Math.max(boxWidth / SCENE_WIDTH, boxHeight / SCENE_HEIGHT)

    return {
        originX: centerX,
        originY: centerY,
        scale,
        motionSettings: 'zoomSmooth',
        endMotionSettings: null
    }
}

export function getZoomConfigForModal() {
  return {
    zoomValues: {
      originX: 0, //originX * VIDEO_WIDTH,  
      originY: 0, //originY * VIDEO_HEIGHT, 
      scale: 0.9,  // Keep original scale
      motionSettings: 'zoomSmooth',
      endMotionSettings:null
    },
    zoomBox: {
      x: 0,
      y: 0,
      width: 1920 * 0.9,
      height: 1080 * 0.9,
    }
  }
}

export async function calculateZoomConfigFromBoundingBox(boundingBox, clip) {
  try {
    const masterRecordingData = await getMasterRecordingData(clip.captureId);
    //console.log('masterRecordingData', masterRecordingData);

    const deviceConfig = {
      screenVideoDevice: clip.metadata.screenVideoApp,
      screenVideoDeviceFrame: clip.metadata.deviceFrame
    };    

    // Adjust bounding box for device frame if needed
    const adjustedBoundingBox = calculateAdjustedBoundingBoxForDeviceFrame(
      boundingBox,
      masterRecordingData,
      deviceConfig
    );
  //  console.log('adjustedBoundingBox', adjustedBoundingBox);
    // Calculate effective height based on device frame
    const effectiveHeight = deviceConfig.screenVideoDevice && deviceConfig.screenVideoDeviceFrame
      ? masterRecordingData.recordingHeight - (deviceConfig.screenVideoDeviceFrame === 'colorBB' ? CHROME_OFFSET_BOOKMARKS_BAR : CHROME_OFFSET_DEFAULT)
      : masterRecordingData.recordingHeight;

    // Calculate canvas coordinates
    const canvasBox = calculateBoundingBoxInCanvas(
      adjustedBoundingBox,
      masterRecordingData.recordingWidth,
      effectiveHeight,
      clip.videoWindowPadding()
    );

    const windowDimensions = resizeDeviceToFit(
      masterRecordingData.recordingWidth,
      effectiveHeight,
      clip.videoWindowPadding(),
      SCENE_WIDTH,
      SCENE_HEIGHT
    );
    
    let polishedBox = canvasBox

    // uncomment to put through polish zoom box
    polishedBox = calculatePolishedZoomBox(canvasBox, windowDimensions);
    
    const zoomValues = calculateZoomValuesForCanvasBoundingBox(polishedBox);
    const zoomBox = convertZoomValuesToZoomBox(zoomValues);

    return { zoomValues, zoomBox };
  } catch (error) {
    console.error('Error getting master recording data:', error);
    throw error;
  }
}

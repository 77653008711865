import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu'
import Icon from '../../../../misc/Icon'
//while dragging in dndMode we show the proposedClip location which is where it will be when dropped




const EditorTimelineClipContextMenuContents = (props) => {
 
  const {clip, canChangePlaybackRate} = props

  const playbackRates = [0.5, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 2,3.5, 5, 10];   // Array of playback rates
  const activePlaybackRate = clip.clipPlaybackRate || 1
  
  const isLinkedClip = clip.metadata.linkedClipId?true:false


  return (
    <>    
      <ContextMenu.Portal>                    
      <ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--timelineClip forceDarkTheme'>                                
        
        {/*}
        <ContextMenu.Item onSelect={()=>{props.cutClip(clip)}}>
          Cut
        </ContextMenu.Item>          
        <ContextMenu.Item onSelect={()=>{props.copyClip(clip)}}>
          Copy
        </ContextMenu.Item>
        */}

        {clip.type=='video' && 
          <>

            {!clip.metadata.linkedClipId &&
            <>
            <ContextMenu.Item onSelect={()=>{props.toggleAddSkipSegmentMode(false)}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='splitClip' />
              </div>
              Add Trim
              <div className='contextMenu-item-keyboardShortcut'>
                ⌘E
              </div>
            </ContextMenu.Item>

            <ContextMenu.Item onSelect={()=>{props.handleTrimToClipEdge(clip.id,'start')}} >              
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='arrowRightLine' />
              </div>
              Trim from start
            </ContextMenu.Item>

            <ContextMenu.Item onSelect={()=>{props.handleTrimToClipEdge(clip.id,'end')}} >              
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='arrowLeftLine' />
              </div>
              Trim to end
            </ContextMenu.Item>        

            <ContextMenu.Separator />
            </>
}

            <ContextMenu.Item className={isLinkedClip ? ' contextMenu-item--disabled ' : ''} onSelect={()=>{props.addFreezeFrame(clip.id)}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='playbackPause' />
              </div>
              Add Freeze Frame
            </ContextMenu.Item>
        
            <ContextMenu.Item onSelect={()=>{props.splitRecordingClip()}} >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='splitClipAlt2' />
              </div>
              Split clip
              <div className='contextMenu-item-keyboardShortcut'>
                ⌘⇧S
              </div>
            </ContextMenu.Item>              
          </>
        }      

        {clip.metadata.linkedClipId &&
          <ContextMenu.Item onSelect={()=>{props.unlinkScreencast(clip.id)}} >    
            <div className='dropdownMenu-item-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>            
            {clip.type=='video' ? 'Delink camera' : 'Delink screen clip'}
          </ContextMenu.Item>
        }


      {clip.type=='webcam' &&  !clip.metadata.isVariable &&
          <ContextMenu.Item onSelect={()=>{props.splitRecordingClip(clip.id)}} >
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='splitClipAlt2' />
          </div>
          Split clip
          <div className='contextMenu-item-keyboardShortcut'>
            ⌘⇧S
          </div>
        </ContextMenu.Item>   
        }

        {clip.isScreenRecording && 
          <ContextMenu.Item onSelect={()=>{props.setShowWebcamPhasesForClipId(clip.id)}} >              
            <div className='dropdownMenu-item-iconContainer'>
                <Icon name='screenCastMedium' />
            </div>
            Camera layouts...
          </ContextMenu.Item>
        }
        

        {/*}
        {canChangePlaybackRate && !isLinkedClip && 
          <ContextMenu.Sub>
            <ContextMenu.SubTrigger>
              Edit Playback Rate          
              <div className='contextMenu-item-subMenuArrow'>
                <Icon name='chevronRight' />
              </div>                      
            </ContextMenu.SubTrigger>
            <ContextMenu.Portal>
              <ContextMenu.SubContent
                collisionPadding={20} className='contextMenu contextMenu-subMenu contextMenu-subMenu--playbackRate forceDarkTheme'
              >                  
                {playbackRates.map(rate => (
                  <ContextMenu.Item key={rate} className={'contextMenu-subMenu-item contextMenu-subMenu-item--playbackRate ' + (rate === activePlaybackRate ? ' contextMenu-subMenu-item--playbackRate--active ' : ' contextMenu-subMenu-item--playbackRate--inactive ')} onSelect={() => props.changeVideoClipPlaybackRate(clip.id, rate)}>
                    {rate.toFixed(1)} {rate === activePlaybackRate && <div className='contextMenu-subMenu-item--playbackRate-iconContainer'><Icon name='multiplySemibold'/></div>}
                  </ContextMenu.Item>
                ))}                     
              </ContextMenu.SubContent>
            </ContextMenu.Portal>
          </ContextMenu.Sub>
        }           
        */}


        

        {clip.type!=='video' && clip.type!=='audio' && !isLinkedClip && !clip.metadata.isVariable &&
        <ContextMenu.Item onSelect={()=>{props.duplicateClip(clip)}} >
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='duplicateMedium' />
          </div>
          Duplicate Clip
        </ContextMenu.Item>
      }

      {clip.type!=='audio' && !clip.metadata.isVariable &&
          <ContextMenu.Separator />    
      }      
        <ContextMenu.Item onSelect={()=>{props.deleteClip(clip.id,true)}}>
          <div className='dropdownMenu-item-iconContainer'>
            <Icon name='deleted' />
          </div>
          Delete
        </ContextMenu.Item>   
      </ContextMenu.Content>
    </ContextMenu.Portal>
    </>
  );
};

export default EditorTimelineClipContextMenuContents;
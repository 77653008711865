import {TimelineClip} from './TimelineClip'
import {fetchImageUrl} from '../utils/images/fetchImageUrl'
import {calculateAnimationDurationsForClip} from '../three/utils/animations/calculateTextAnimationDurationByGrouping'


/**
 * Legacy image slides- we now use SlideClip
*/


class ImageClip extends TimelineClip {

  constructor(options,scene,handleClipMediaLoaded) {

    super(options,scene)
    this._handleClipMediaLoaded=handleClipMediaLoaded
    this.imageId=options.imageId
    this.isUploadingImage = options.isUploadingImage
    
    if(this.imageId){
      this.initialize(this.imageId)
    }
      this.calculateMinSlideDuration()
  }


  calculateMinSlideDuration(){ //do this after have calced words array
    this.minDuration = 0.5
  }


  async finishUpload(){
    this.isUploadingImage=false 
    await this.initialize(this.imageId)
  }


  async initialize(imageId) {
    const imageSrc = await fetchImageUrl(imageId);
    this.imgSrc = imageSrc;
    this._handleClipMediaLoaded()
  }



  // }
 
  toJSON() {
    const clipJSON =  {
      id: this.id,
      type:this.type,
      startTime:this._startTime, //time relative to the scene
      //startTime:this.startTime,
      duration:this.duration,
      metadata:this.metadata,
      zIndex:this.zIndex,
      imageId:this.imageId,
    };
    return clipJSON
  }

  
}


export { ImageClip }

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const SlideEditorDragSelectArea = ({ 
  elements, 
  setSlideEditorSelection, 
  scalar,   
  isDraggingToSelect,
  setIsDraggingToSelect, 
  setEditTextMode ,
  clearSlideElementsSelection,
  invertScalar,
  findParentLayoutGroup,
  slide,
  slideWidth,
  slideHeight
}) => {  
  const [selectionStart, setSelectionStart] = useState({ x: 0, y: 0 });
  const [selectionEnd, setSelectionEnd] = useState({ x: 0, y: 0 });  
  const containerRef = useRef(null);


const updateSelection = useCallback(debounce((rect) => {
  const selectedItems = new Set();
  const selectedGroups = new Set();

  elements.forEach(el => {
    const left = el.x;
    const top = el.y;
    const right = left + el.width;
    const bottom = top + el.height;

    if (right > rect.left && left < rect.right && bottom > rect.top && top < rect.bottom) {
      const parentGroup = findParentLayoutGroup(el.id, slide.layout);
      if (parentGroup && parentGroup.type !== 'free') { 
        selectedGroups.add(parentGroup.id);
      } else {
        selectedItems.add({ type: 'element', id: el.id });
      }
    }
  });
  // Add selected groups to the selectedItems set
  selectedGroups.forEach(groupId => {
    selectedItems.add({ type: 'layoutGroup', id: groupId });
  });
  // If a group is selected, remove its child elements from the selection
  selectedItems.forEach(item => {
    if (item.type === 'layoutGroup') {
      const group = slide.layoutGroups.find(g => g.id === item.id);
      if (group) {
        group.children.forEach(child => {
          if (!child.isLayoutGroup) {
            selectedItems.delete({ type: 'element', id: child.id });
          }
        });
      }
    }
  });
  setSlideEditorSelection(Array.from(selectedItems));
}, 1), [elements, setSlideEditorSelection, findParentLayoutGroup, slide.layout, slide.layoutGroups]);



  const getRelativeCoordinates = useCallback((clientX, clientY) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min((clientX - rect.left) / scalar, slideWidth));
      const y = Math.max(0, Math.min((clientY - rect.top) / scalar, slideHeight));
      return { x, y };
    }
    return { x: 0, y: 0 };
  }, [scalar, slideWidth, slideHeight]);

  const handleMouseMove = useCallback((e) => {
    if (!isDraggingToSelect) return;
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY);
    setSelectionEnd({ x, y });
    const selectionRect = {
      left: Math.min(selectionStart.x, x),
      top: Math.min(selectionStart.y, y),
      right: Math.max(selectionStart.x, x),
      bottom: Math.max(selectionStart.y, y),
    };
    updateSelection(selectionRect);
  }, [isDraggingToSelect, selectionStart, getRelativeCoordinates, updateSelection]);

  const handleMouseDown = useCallback((e) => {
    if (containerRef.current && containerRef.current.contains(e.target)) {
      const { x, y } = getRelativeCoordinates(e.clientX, e.clientY);
      setSelectionStart({ x, y });
      setSelectionEnd({ x, y });
      setIsDraggingToSelect(true);
      setEditTextMode(false);
    }
  }, [getRelativeCoordinates, setIsDraggingToSelect, setEditTextMode]);

  const handleMouseUp = useCallback(() => {
    if (isDraggingToSelect) {
      if (selectionStart.x === selectionEnd.x && selectionStart.y === selectionEnd.y) {
        // It's a click, not a drag
        clearSlideElementsSelection()
      }
      setIsDraggingToSelect(false);
      updateSelection.flush(); // Ensure the final update is applied
    }
  }, [isDraggingToSelect, selectionStart, selectionEnd, setSlideEditorSelection, setIsDraggingToSelect, updateSelection]);

  useEffect(() => {
    // Add global mousedown, mousemove, and mouseup event listeners
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      // Remove the listeners when the component unmounts
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <div
      ref={containerRef}
      className="editor-slideEditor-dragSelect"
      style={{
        width: slideWidth,
        height: slideHeight,        
      }}
    >
      {isDraggingToSelect && (
        <div 
          className='editor-slideEditor-dragSelect-area'
          style={{                    
            left: `${Math.min(selectionStart.x, selectionEnd.x)}px`,
            top: `${Math.min(selectionStart.y, selectionEnd.y)}px`,
            width: `${Math.abs(selectionStart.x - selectionEnd.x)}px`,
            height: `${Math.abs(selectionStart.y - selectionEnd.y)}px`,
            borderWidth: `${invertScalar * 1}px`
          }}
        />
      )}
    </div>
  );
};

export default SlideEditorDragSelectArea;
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import LibraryProjectItemLockedBanner from '../library/projectItem/LibraryProjectItemLockedBanner';
import Icon from '../misc/Icon';

const EditorReadOnlyToggle = (props) => {
  const [refreshLoading, setRefreshLoading] = useState(false);

  // React Spring animation config
  const refreshButtonSpring = useSpring({
    opacity: refreshLoading ? 0.65 : 1,
    scale: refreshLoading ? 0.97 : 1,
    config: {
      tension: 280,
      friction: 16
    }
  });

  const handleRefresh = () => {
    setRefreshLoading(true);
    props.onRefresh();
    
    setTimeout(() => {
      setRefreshLoading(false);
    }, 1500);
  };

  return (
    <div className='editor-readOnlyToggleContainer'>
      <div className='editor-readOnlyToggle'>
        {props.lockedAvatarUrl && 
          <div className='editor-readOnlyToggle-lockedBanner-avatar'>
            <img referrerPolicy="no-referrer" src={props.lockedAvatarUrl} />
          </div>        
        }
      
        {props.lockedUserFirstName && 
          <div className='editor-readOnlyToggle-label'>
            <span className='editor-readOnlyToggle-label-name'>{props.lockedUserFirstName}</span> is editing
          </div>
        }
        {!props.lockedUserFirstName && 
          <div className='editor-readOnlyToggle-label'>
            <span className='editor-readOnlyToggle-label-name'>Someone</span> is editing
          </div>
        }
        <div className='editor-readOnlyToggle-buttons'>
          <button className='editor-readOnlyToggle-btn editor-readOnlyToggle-btn--control' onClick={props.onTakeControl}>
            <div className='editor-readOnlyToggle-btn-label'>
              Take Control
            </div>
          </button>   
          <animated.button 
            style={refreshButtonSpring}
            className='editor-readOnlyToggle-btn editor-readOnlyToggle-btn--refresh'
            onClick={handleRefresh}
            disabled={refreshLoading}
            data-refresh-loading={refreshLoading ? true : false}
          >            
            <div className='editor-readOnlyToggle-btn-label'>
              See Latest Changes
            </div>            
          </animated.button>
        </div>      
      </div>
    </div>
  );
};

export default EditorReadOnlyToggle;
import {Plugin} from "prosemirror-state"

function getTrancriptChunkNodeDimensions(pos, view, id) {
  const domNode = view.nodeDOM(pos);
  let dimensions = { top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 };  
  if (domNode) {
    const rect = domNode.getBoundingClientRect();
    // Get the scrollable container
    const scrollContainer = document.querySelector('.editor-transcriptPanel-list');
    
    // Add the container's scroll position to convert to absolute positions
    const scrollTop = scrollContainer ? scrollContainer.scrollTop : 0;
    const scrollLeft = scrollContainer ? scrollContainer.scrollLeft : 0;
    
    dimensions = {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
      bottom: rect.bottom + scrollTop,
      right: rect.right + scrollLeft,
      width: rect.width,
      height: rect.height
    };
  }
  return dimensions;
}

export function activeAudioChunkPlugin(setActiveTranscriptPanelAudioChunk) {
	return new Plugin({
		view(editorView) {
			return {
				update: (view, prevState) => {
					const { state } = view
					const { selection } = state
					const { empty, from, to } = selection
					if (empty) {
						let foundChunk = null
						let foundPos = null
						let clipIndex = -1
						let currentSceneId = null

						// First count clips up to current position to get correct clipIndex
						state.doc.nodesBetween(0, from, (node, pos) => {
							if (node.type.name === "sceneHeader") {
								currentSceneId = node.attrs.sceneId;
							}
							if (node.type.name === "transcriptGroup") {
								const groupType = node.attrs.groupType;
								if (groupType === 'webcam-recording' || groupType === 'variable-webcam') {
									clipIndex++;
								}
							}
							if (node.type.name === "transcriptChunk") {
								const parent = state.doc.resolve(pos).parent
								if (parent && parent.type.name === 'transcriptGroup' && parent.attrs.groupType === 'standard') {
									clipIndex++
								}
							}
						})

						// Now find the current chunk
						state.doc.nodesBetween(from, to, (node, pos) => {
							if (node.type.name === "sceneHeader") {
								currentSceneId = node.attrs.sceneId;
							}
							if (node.type.name === 'transcriptChunk') {
								const parent = state.doc.resolve(pos).parent
								if (parent && parent.type.name === 'transcriptGroup' && parent.attrs.groupType === 'standard') {
									foundChunk = node
									foundPos = pos
									return false // Stop traversal
								}
							}
						})

						if(foundChunk && foundPos !== null) {
							const dimensions = getTrancriptChunkNodeDimensions(foundPos, view, foundChunk.attrs.clipId)
							setActiveTranscriptPanelAudioChunk({
								clipId: foundChunk.attrs.clipId,
								dimensions: dimensions,
								clipIndex: clipIndex,
								sceneId: currentSceneId
							})
						} else {
							setActiveTranscriptPanelAudioChunk(null)
						}
					} else {
						setActiveTranscriptPanelAudioChunk(null)
					}
				},
			}
		}
	})
}
// selectionPlugin.js
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

// Define a unique PluginKey for the selection plugin
const selectionPluginKey = new PluginKey('selectionPlugin');

// Helper function to find the parent node matching a predicate
function findParentNode(predicate, $pos) {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (predicate(node)) {
      return { node, pos: $pos.before(depth), depth };
    }
  }
  return null;
}

// Selection plugin to adjust selection behaviors
const selectionPlugin = new Plugin({
  key: selectionPluginKey,
  props: {
    // Handle selection changes
    handleDOMEvents: {
      mouseup: (view, event) => {
        // if (event.shiftKey) return false;
        adjustSelection(view);
        return false;
      },
      keyup: (view, event) => {
        // if (event.shiftKey) return false;
        adjustSelection(view);
        return false;
      },
    },
  },
});


function adjustSelection(view) {
  const { state, dispatch } = view;
  const { selection, doc } = state;
  const { $anchor, $head } = selection;
  
  // If empty selection, handle as before
  if (selection.empty) {
    // ... existing empty selection code ...
    return;
  }

  // Find the transcript groups at the selection anchor and head
  const anchorGroup = findParentNode(node => node.type.name === 'transcriptGroup', $anchor);
  const headGroup = findParentNode(node => node.type.name === 'transcriptGroup', $head);

  if (!anchorGroup || !headGroup) return;

  // If the selection crosses transcript group boundaries, adjust it
  if (anchorGroup.pos !== headGroup.pos) {
    const adjustedAnchor = Math.max($anchor.pos, anchorGroup.pos + 1);
    const adjustedHead = Math.min($head.pos, anchorGroup.pos + anchorGroup.node.nodeSize - 1);

    dispatch(state.tr.setSelection(TextSelection.create(doc, adjustedAnchor, adjustedHead)));
    return;
  }

  // Check if we're inside a webcamRecordingChunk
  const webcamParent = findParentNode(
    node => node.type.name === 'webcamRecordingChunk',
    $anchor
  );

  if (!webcamParent) return;

  // Adjust selection to whole words within webcamRecordingChunk
  const wordNodes = [];
  const fromPos = Math.min($anchor.pos, $head.pos);
  const toPos = Math.max($anchor.pos, $head.pos);
  
  doc.nodesBetween(fromPos, toPos, (node, pos) => {
    if (node.type.name === 'webcamTranscriptItem') {
      wordNodes.push({ node, pos });
    }
  });

  if (wordNodes.length > 0) {
    const firstWordPos = wordNodes[0].pos;
    const lastWordPos = wordNodes[wordNodes.length - 1].pos + wordNodes[wordNodes.length - 1].node.nodeSize;

    // Keep anchor at its end of the selection when adjusting to word boundaries
    const isForward = $anchor.pos <= $head.pos;
    if (isForward) {
      dispatch(state.tr.setSelection(TextSelection.create(doc, firstWordPos, lastWordPos)));
    } else {
      dispatch(state.tr.setSelection(TextSelection.create(doc, lastWordPos, firstWordPos)));
    }
  } else {
    // If no word nodes are found, adjust selection to nearest word
    const nearestWord = findNearestWord(doc, $head.pos, webcamParent.pos + 1, webcamParent.pos + webcamParent.node.nodeSize - 1);
    if (nearestWord) {
      const wordStart = nearestWord.pos;
      const wordEnd = wordStart + nearestWord.node.nodeSize;
      // Keep selection direction when snapping to nearest word
      if ($anchor.pos <= $head.pos) {
        dispatch(state.tr.setSelection(TextSelection.create(doc, wordStart, wordEnd)));
      } else {
        dispatch(state.tr.setSelection(TextSelection.create(doc, wordEnd, wordStart)));
      }
    }
  }
 }

function findNearestWord(doc, pos, minPos, maxPos) {
  // Search backward
  let $pos = doc.resolve(pos);
  while ($pos.pos > minPos) {
    const node = $pos.nodeBefore;
    if (node && node.type.name === 'webcamTranscriptItem') {
      return { node, pos: $pos.pos - node.nodeSize };
    }
    $pos = doc.resolve($pos.pos - 1);
  }

  // Search forward
  $pos = doc.resolve(pos);
  while ($pos.pos < maxPos) {
    const node = $pos.nodeAfter;
    if (node && node.type.name === 'webcamTranscriptItem') {
      return { node, pos: $pos.pos };
    }
    $pos = doc.resolve($pos.pos + 1);
  }

  return null;
}

export default selectionPlugin;

const initialState = {};

export default function(state = initialState, action) {
  switch(action.type) {
    case 'START_EXPORT_SUCCESS': {
      const { projectId, renderId, status, enqueuedTime, estimatedGenerationTime, renderSegmentSeconds, projectDuration } = action.payload;
      return {
        ...state,
        [projectId]: {
          renderId,
          status,
          percentDone: 0,
          phase: 'rendering',
          enqueuedTime,
          estimatedGenerationTime,
          renderSegmentSeconds,
          projectDuration,
          phaseStartTime: Date.now()
        }
      };
    }
    case 'UPDATE_EXPORT_STATUS': {
      const { projectId, status, inProgressTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status,
          inProgressTime,
          phaseStartTime: Date.now()
        }
      };
    }
    case 'UPDATE_EXPORT_PROGRESS': {
      const { projectId, percentDone } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      if (percentDone <= currentExport.percentDone) return state;      
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          percentDone,
          status: 'in_progress'
        }
      };        
      
    }
    case 'EXPORT_FINALIZE_STARTED': {
      const { projectId } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          phase: 'finalizing',
          phaseStartTime: Date.now(),
          percentDone: 80
        }
      };
    }
    case 'EXPORT_FINALIZE_FINISHED': {
      const { projectId } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          phase: 'downloading',
          phaseStartTime: Date.now(),
          percentDone: 90
        }
      };
    }
    case 'EXPORT_GENERATION_COMPLETED': {
      const { projectId, resultUrl, completionTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'generation_completed',
          resultUrl,
          completionTime,
          percentDone: 100,
          phase: 'completed'
        }
      };
    }
    case 'EXPORT_DOWNLOADING': {
      const { projectId } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'downloading'
        }
      };
    }
    case 'EXPORT_DOWNLOAD_COMPLETED': {
      const { projectId, filePath, filename, downloadCompletionTime } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: 'downloaded',
          filePath,
          filename,
          downloadCompletionTime
        }
      };
    }
    case 'EXPORT_FAILED':
    case 'EXPORT_DOWNLOAD_FAILED': {
      const { projectId, error } = action.payload;
      const currentExport = state[projectId];
      if (!currentExport) return state;
      return {
        ...state,
        [projectId]: {
          ...currentExport,
          status: action.type === 'EXPORT_FAILED' ? 'failed' : 'download_failed',
          error
        }
      };
    }
    case 'CANCEL_EXPORT': {
      const { projectId } = action.payload;
      const { [projectId]: _, ...newState } = state;
      return newState;
    }
    case 'START_EXPORT_FAILURE': {
      const { projectId, error } = action.payload;
      return {
        ...state,
        [projectId]: {
          status: 'failed',
          error
        }
      };
    }
    default:
      return state;
  }
}
import {fetch,batchedFetch,post,put,postCloudinary} from '../api'
import axios from 'axios'
import {downloadMissingRecordings} from '../utils/assets/downloadMissingRecordings'
import * as Sentry from '@sentry/electron/renderer'

export function fetchRecordings(){
	return (dispatch) => fetch(`/recordings`)
	.then((response) => {
		dispatch({ type: 'FETCH_RECORDINGS_SUCCESS', response })
		downloadMissingRecordings(response)
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}


const files= [
	{ "name": "recording.log", "type": "text/plain" },
	{ "name": "mouseclicks-0.json", "type": "application/json" },	
	{ "name": "mousemoves-0.json", "type": "application/json" },
	{ "name": "keystrokes-0.json", "type": "application/json" },
	{ "name": "timestamps.json", "type": "application/json" },
	{ "name": "metadata.json", "type": "application/json" },
]

const deviceFiles= [
	{ "name": "metadata.json", "type": "application/json" },
	{ "name": "log.json", "type": "application/json" },
]

///TODO refector syncNewRecording to only do device recordings
export function syncNewRecording(captureId, isDevice) {
	const requestBody = {capture_id: captureId}

	return async (dispatch) => {
		let endpoint = isDevice ? '/devicerecordings' : '/recordings'
		let filesToUpload = isDevice ? deviceFiles : files
		let basePath = isDevice ? `deviceRecordings/${captureId}` : `recordings/${captureId}`
		let videoFileName = isDevice ? 'deviceRecording.mov' : 'channel-1-display-0.mp4';
		let videoMimeType = isDevice ? 'video/mov' : 'video/mp4';
		let cloudinaryUploadInfo;
		let uploadPreset = isDevice?'device_recordings':'screen_recordings'
		try {
			// Log the start of the process
			const response = await post(endpoint, requestBody);
			cloudinaryUploadInfo = response.cloudinaryUploadInfo;
			const videoPath = `${basePath}/${videoFileName}`;
			const videoBuffer = await ipcRenderer.invoke('read-file-buffer', videoPath);
			const videoUploadURL = response.uploadURLs.find(url => url.fileName.includes(videoFileName)).uploadURL;
			await axios.put(videoUploadURL, videoBuffer, { headers: { 'Content-Type': videoMimeType } });
			// Upload each JSON file (and any other files)
			for (const file of filesToUpload) {
				const filePath = `${basePath}/${file.name}`;
				const fileContent = await ipcRenderer.invoke('read-file', filePath);
				const uploadURL = response.uploadURLs.find(url => url.fileName === file.name).uploadURL;
				await axios.put(uploadURL, fileContent, { headers: { 'Content-Type': file.type } });
			}
			// Update the recording status to complete
			if(isDevice){
				const updateResponse = await put(`/devicerecordings/${captureId}/complete`);
			}else{
				const updateResponse = await put(`/recordings/${captureId}/complete`);
			}			
		} catch (error) {
			console.error('Error during recording sync', error);
			error.name = 'Sync Recording Error'
			Sentry.withScope(scope => {
				scope.setExtra("RequestBody", requestBody);
				scope.setExtra("CaptureID", captureId);
				scope.setTag("isDevice", isDevice ? "Yes" : "No");
				scope.setTag("error-type", "SyncNewRecordingError");
				scope.setLevel("error");
				// Capture specific file and step information if available in error
				if (error.filePath) {
					scope.setExtra("FailedFilePath", error.filePath);
					scope.setTag("Step", "FileUpload");
				} else if (error.response) {
					scope.setExtra("FailedResponse", error.response);
					scope.setTag("Step", "APIRequest");
				}
				Sentry.captureException(error);
			});
		}
	}
}

import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPItemAnimationPanel from './DPItemAnimationPanel';
import DPNumberInput from './DPNumberInput';
import DPSlider from './DPSlider';
import * as Tooltip from '@radix-ui/react-tooltip';
import DPTextInput from './DPTextInput'
import DPSelectMenu from './DPSelectMenu';
import DPCheckbox from './DPCheckbox'
import DPLayoutBox from './DPLayoutBox'
import EditorDetailPanelWebcamCarousel from './EditorDetailPanelWebcamCarousel'
import { webcamSizes, webcamPositions } from '../../../utils/webcam/webcamConfigs';  // Adjust the path as needed

// zoom - min 1, default 1, max 1.5
// xOffset – default 0, min - 1000, max 1000
// yOffset – default 0, min - 1000, max 1000

const getValidPositionsForSize = (sizeName) => {
  const size = webcamSizes.find(s => s.name === sizeName);
  if (!size) return [];
  return size.validPositions;
};

const getPositionOptions = (sizeName) => {
  const validPositions = getValidPositionsForSize(sizeName);
  return webcamPositions
    .filter(pos => validPositions.includes(pos.name))
    .map(pos => ({
      value: pos.name,
      label: pos.userLabel
    }));
};

const borderOptions = [
	{value:'forceHide', label:'None'},
	{value:'auto',   label:'Auto'},
	{value:'forceShow',    label:'Always'},	
]

const sizeOptions = webcamSizes.map(size => ({
  value: size.name,
  label: size.userLabel
}));

const positionOptions = [
	{value:'topLeft',   		label:'Top Left'},
	{value:'middleLeft',    label:'Middle Left'},	
	{value:'bottomLeft',    label:'Bottom Left'},
	{value:'fillLeft',     	label:'Fill Left'},
	{value:'center',  			label:'Center'},
	{value:'topRight',  		label:'Top Right'},
	{value:'middleRight',   label:'Middle Right'},
	{value:'bottomRight', 	label:'Bottom Right'},
	{value:'fillRight',   	label:'Fill Right'},	
];


const instructionsOptions = [
	{value:'script',   label:'Script'},		
	{value:'instructions', label:'Instructions'},
]


const EditorDetailPanelWebcam = (props) => {
	const {updateClipMetadata,clip,toggleTrimMode} = props
	const [showWebcamDetails, setShowWebcamDetails] = useState(false);
	// const [border, setBorder] = useState('auto');

	const instructionsType = clip.metadata.hasInstructions?'instructions':'script'


	const setInstructionsType=(value)=>{
		let hasInstructions = false 
		if(value=='instructions'){
			hasInstructions=true
		}
		updateClipMetadata(clip.id,{hasInstructions:hasInstructions})
		props.updateTranscripPanelFromTimeline()
	}

	const handleUpdateMetadata = (field,value) => { 
		updateClipMetadata(clip.id,{[field]:value})
	}  

	const handleUpdateColorAdjustment=(field,value)=>{
		let newColorAdjustments = {...clip.metadata.colorAdjustments }
		newColorAdjustments[field]=value
		updateClipMetadata(clip.id,{colorAdjustments:newColorAdjustments})
	}


	const [webcamVariableName, setWebcamVariableName] = useState(
		(clip.metadata.variables && clip.metadata.variables[0]) || ''
	)

	const handleUpdateWebcamVariableName = (variableName) => {
		setWebcamVariableName(variableName)
		updateClipMetadata(clip.id,{variables:[variableName]})
	}  
	
	const setIsMuted=(value)=>{
		handleUpdateMetadata('isMuted',value)
		clip.updateIsMuted(value)//need to actually mute on the clip
	}

	const isVariableWebcam = clip.metadata.isVariable || false 
	const isCameraRecording = !isVariableWebcam

	const isMuted = clip.metadata.isMuted || false 

	const projectWebcamClipsHaveDifferentColorAdjustments = false

	// const handleUpdateVoiceType=(value)=>{
	// 	console.log('uodpate to ',value)
	// 	updateClipMetadata(clip.id,{audioTrack:value})
	// 	props.clip.handleUpdateAudioTrack()
	// }

	// const [aspectRatio, setAspectRatio] = useState('square');

	// const aspectRatioOptions = [
	//   { value: 'square', iconName: 'cameraShapeSquareMedium' },
	//   { value: 'circle', iconName: 'cameraShapeCircleMedium' },
	//   { value: 'portrait', iconName: 'cameraShapePortraitMedium' },
	//   { value: 'rectangle', iconName: 'cameraShapeRectangleMedium' },
	// ];


	// const [isDragging, setIsDragging] = useState(false);

	// const hAlignTypes = [
	//   { value: 'left', label: 'Left' },
	//   { value: 'center', label: 'Center' },
	//   { value: 'right', label: 'Right' }
	// ];

	// const vAlignTypes = [
	//   { value: 'top', label: 'Top' },
	//   { value: 'middle', label: 'Middle' },
	//   { value: 'bottom', label: 'Bottom' }
	// ];

	const [size, setSize] = useState('smallPortrait');
	const [position, setPosition] = useState('bottomLeft');

	const facePaddingRatio = clip.metadata.facePaddingRatios?.[props.size] || 0.6

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    
    // Check if current position is valid for new size
    const validPositions = getValidPositionsForSize(newSize);
    if (!validPositions.includes(position)) {
      // Find the size configuration to get alt positions
      const currentPosConfig = webcamPositions.find(p => p.name === position);
      if (currentPosConfig) {
        // Find first valid alternative position
        const newPosition = currentPosConfig.altPositions.find(alt => 
          validPositions.includes(alt)
        ) || validPositions[0]; // Fallback to first valid position
        setPosition(newPosition);
      }
    }
  };

    const positionOptions = getPositionOptions(size);



	const updateClipTargetPaddingRatio = (value) => {
		let newFacePaddingRatios = {...clip.metadata.facePaddingRatios}
		newFacePaddingRatios[props.size] = value
		updateClipMetadata(clip.id,{facePaddingRatios:newFacePaddingRatios})
	}

	
	const layoutCount = 8
  const [activeWebcamLayoutIndex, setActiveWebcamLayoutIndex] = useState(0);


	return (
		<>
			<div className='editor-detailPanel-header'>
				<div className='editor-detailPanel-header-label'>
					{isVariableWebcam ? 'Input Camera' : 'Camera' }
				</div>
				<div className='editor-detailPanel-header-vSpacer' />				


				{isCameraRecording && 
					<Tooltip.Root delayDuration={0}>
						<Tooltip.Trigger asChild> 
							<button 
								onClick={() => setShowWebcamDetails(!showWebcamDetails)}
								data-active-state={showWebcamDetails}
								className='dpButton dpButton--iconOnly dpButton--detailMode'
							>
								<div className='dpButton-iconContainer'>
									<Icon name='galleryDisplayList' />
								</div>
							</button>   
						</Tooltip.Trigger>        
						<Tooltip.Portal>
							<Tooltip.Content side="left" className="tooltip tooltip--detailsMode">
								<span>{showWebcamDetails ? 'Hide Details' : 'Show Details'}</span>
							</Tooltip.Content>
						</Tooltip.Portal>
					</Tooltip.Root> 
				}
				

			</div>
								
			{!isVariableWebcam && 
				<>
				<div className='editor-detailPanel-row editor-detailPanel-row--tight'>
					<button 
						onClick={toggleTrimMode}          
						className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
					>
						<div className='dpButton-label'>
							Edit Trim
						</div>
					</button>
				</div>
				</>				
			}
			{showWebcamDetails &&
				<>	
					<div className='editor-detailPanel-row editor-detailPanel-row--tight'>
						<div className='editor-detailPanel-row-label'>
							Border              
						</div>
						<div className='editor-detailPanel-row-hSpacer' />
						<DPSelectMenu
							value={clip.metadata.borderMode}
							onValueChange={(value)=>{handleUpdateMetadata('borderMode',value)}}
							options={borderOptions}
							// width={100}
						/>     
					</div>					
					<div className='editor-detailPanel-smallVSpacer' />
				</>
			}
				

			{isVariableWebcam && 
				<div className='editor-detailPanel-row'>
					<div className='editor-detailPanel-row-label'>
						Type      
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={instructionsType}
						onValueChange={(value)=>{setInstructionsType(value)}}
						options={instructionsOptions}
						width={130}
					/>     
				</div>
			}

				<div className='editor-detailPanel-smallVSpacer' />
			
				<div className='editor-detailPanel-divider' />

				<div className='editor-detailPanel-header-alignVSpacer' />
				{/*}
				<DPSlider
					min={0}
					max={1}
					value={cropLevel}
					setValue={(value)=>{setCropLevel(value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
					percentage
					label='Size'					
					step={0.2}
					suggestedValue={suggestedCropLevel}
					hideNonSuggestedLabels={true}									
				/>
				

				<div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row editor-detailPanel-row--layoutBox'>
        <div className='editor-detailPanel-row-label'>
          
        </div>
        <div className='editor-detailPanel-row-hSpacer' />  
        
				<DPLayoutBox 
          vAlign={vAlign}
          setVAlign={(value)=>{setVAlign(value)}}
          hAlign={hAlign}
          setHAlign={(value)=>{setHAlign(value)}}        
          isDragging={isDragging}
          setIsDragging={setIsDragging}        
        />
      </div>      
      */}


				<div className='editor-detailPanel-row editor-detailPanel-row--tight'>
					<div className='editor-detailPanel-row-label'>
						Size              
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={size}
						onValueChange={handleSizeChange}
						options={sizeOptions}
						width={140}
					/>     
				</div>

				<div className='editor-detailPanel-row editor-detailPanel-row--tight'>
					<div className='editor-detailPanel-row-label'>
						Position              
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={position}
						onValueChange={setPosition}
						options={positionOptions}
						width={140}
					/>     
				</div>

				
				{showWebcamDetails &&
					<DPSlider
						min={0}
						max={1}
						value={facePaddingRatio}
						setValue={(value)=>{updateClipTargetPaddingRatio(value)}} 
						setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
						percentage
						label='Face Padding'					
						step={0.05}
						suggestedValue={0.6}
						hideNonSuggestedLabels={true}									
					/>
				}

				


				
							
				
				{/*}
				<div className='editor-detailPanel-row'>
					<div className='editor-detailPanel-row-label'>
						Shape           
					</div>					
					<div className='editor-detailPanel-row-hSpacer' />
						<DPToggleGrid
		        items={aspectRatioOptions}
		        activeItem={aspectRatio}
		        rows={1}
		        columns={4}
		        onItemClick={(value) => setAspectRatio(value)}    
		        fixedWidth={130}  
		      />
				</div>
				/}
				

				{showWebcamDetails &&
			<>
				<div className='editor-detailPanel-header-alignVSpacer' />			
					<DPCheckbox
						label='Mute Webcam'
						checked={isMuted}
						setChecked={setIsMuted}
					/>
				</>
			}
			*/}
				
				
			 
				<div className='editor-detailPanel-vSpacer' />
			 
			 	{layoutCount > 0 &&
					<EditorDetailPanelWebcamCarousel
		        layoutCount={layoutCount}
		        activeIndex={activeWebcamLayoutIndex}
		        setActiveIndex={setActiveWebcamLayoutIndex}
		      />
		    }



				
				
			 

			
		</>
	);
};

export default EditorDetailPanelWebcam;



				{/*}
				{showVariableWebcamOption &&
					<>
						<div className='editor-detailPanel-divider' />
						
						<div className='editor-detailPanel-row'>
							{!isVariableWebcam && showVariableWebcamOption &&
								<button onClick={() => handleUpdateMetadata('isVariable', true)} className='dpButton dpButton--labelOnly dpButton--light'>             
									<div className='dpButton-label'>
										Make Variable
									</div>
								</button>     
							}
						
							{isVariableWebcam  && showVariableWebcamOption &&
								<button onClick={() => handleUpdateMetadata('isVariable', false)} className='dpButton dpButton--labelOnly dpButton--light'> 
									<div className='dpButton-label'>
										Make Static
									</div>
								</button>     
							}
						</div>
					</>
				}
				*/}
	
				{/*}
				{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						<div className='editor-detailPanel-row-label'>
							Variable
						</div>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPTextInput            
								value={webcamVariableName}
								setValue={handleUpdateWebcamVariableName}        
								width={120}
							/>
						</div>
					</>
				}
				{/*{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						<div className='editor-detailPanel-row-label'>
							Instructions
						</div>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPTextInput            
								value={variableWebcamInstructions}
								setValue={handleUpdateVariableWebcamInstructions}        
								width={120}
							/>
						</div>
					</>
				}*/}

{/*
				{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPCheckbox
								label='Has Instructions'
								checked={clip.metadata.hasInstructions}
								setChecked={setHasInstructions}
							/>

						</div>
					</>
				}*/}



{/*}
												
			{!isVariableWebcam && 
				<>
				<DPSlider
					min={-1}
					max={1}          
					value={clip.metadata.colorAdjustments.exposure}
					setValue={(value)=>{handleUpdateColorAdjustment('exposure',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
					percentage
					label={showWebcamDetails ? 'Exposure' : 'Brightness'}
					centerZero
					step={0.01}
				/>         
			
				<DPSlider
					min={-1}
					max={1}          
					value={clip.metadata.colorAdjustments.whiteBalance}
					setValue={(value)=>{handleUpdateColorAdjustment('whiteBalance',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
					percentage          
					label={showWebcamDetails ? 'White Balance' : clip.metadata.colorAdjustments.whiteBalance < 0 ? 'Cold' : 'Warm'}
					centerZero
					whiteBalance
					step={0.01}
				/>   

				{projectWebcamClipsHaveDifferentColorAdjustments && 
					<>
						<button 
							//onClick={toggleTrimMode} 
							//apply to all webcam clips in project
							className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton'
						>
							<div className='dpButton-label'>
								Apply to all...
							</div>
						</button>
						<div className='editor-detailPanel-smallVSpacer' />
						<div className='editor-detailPanel-smallVSpacer' />
						<div className='editor-detailPanel-smallVSpacer' />
					</>
				}
				</>
			}
			*/}


	{/*}
			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					Start Hidden
				</div>        
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={0}
					max={20}          
					value={clip.metadata.startHiddenDuration ||0}
					setValue={(value)=>{handleUpdateMetadata('startHiddenDuration',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='S'          
					width={79}
					step={0.1}
				/>
			 </div>

			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					End Hidden
				</div>    
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={0}
					max={20}          
					value={clip.metadata.endHiddenDuration ||0}
					setValue={(value)=>{handleUpdateMetadata('endHiddenDuration',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='E'         
					width={79}
					step={0.1}
				/>
			 </div>

			 </>
			}
	 

			{showWebcamDetails && 
				<>
				<div className='editor-detailPanel-divider' />
		 
				<DPItemAnimationPanel 
					handleUpdateMetadata={handleUpdateMetadata}
					activeStartTransition={clip.metadata.startTransitionType}
					activeEndTransition={clip.metadata.endTransitionType}
					item={clip}
					itemType='webcam'
				/> 
				</>
			}

			*/}

{/* <div className='editor-detailPanel-divider' />


			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					X Offset
				</div>        
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={-1000}
					max={1000}          
					value={clip.metadata.xOffset ||0}
					setValue={(value)=>{handleUpdateMetadata('xOffset',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='X'          
					width={79}
				/>
			 </div>

			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					Y Offset
				</div>    
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={-1000}
					max={1000}          
					value={clip.metadata.yOffset ||0}
					setValue={(value)=>{handleUpdateMetadata('yOffset',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='Y'         
					width={79}
				/>
			 </div> */}
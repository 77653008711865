import {getOrgBrand} from './getOrgBrand'


const DEFAULT_ENTER='fadeAndMoveUp'
const DEFAULT_EXIT='fadeAndMoveDown'

export function getScreenVideoDefaultTransitions  () {
  let enterTransition = DEFAULT_ENTER
  let exitTransition = DEFAULT_EXIT
  const orgBrand=getOrgBrand()

  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.enterTransition){
    enterTransition = orgBrand.screenVideoStyle.enterTransition
  }
  if(orgBrand && orgBrand.screenVideoStyle && orgBrand.screenVideoStyle.exitTransition){
    exitTransition = orgBrand.screenVideoStyle.exitTransition
  }
  return {enterTransition, exitTransition}
}

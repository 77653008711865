import React, { useState, useEffect, useRef } from 'react';
import Icon from '../misc/Icon';
import ReactSlider from 'react-slider';
import EditorTrimBarHandles from './EditorTrimBarHandles'

const formatTime = (timeInSeconds) => {
	const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
	const seconds = Math.floor(timeInSeconds % 60).toString().padStart(2, '0');
	const milliseconds = Math.floor((timeInSeconds % 1) * 100).toString().padStart(2, '0');
	return { minutes, seconds, milliseconds };
};

const EditorTrimBar = ({  trimPreviewPlaying, trimPreviewProgress, onTrimPreviewPlayPause, onTrimPreviewSeekChange,clip ,updateClipTrimValues}) => {

	const recordingVideoDuration = clip.recordingDuration
	const [refReady, setRefReady] = useState(false);

	const [trimStart, setTrimStart] = useState(clip.metadata.trimStart/recordingVideoDuration); // Start position of trim
	const [trimEnd, setTrimEnd] = useState(clip.metadata.trimEnd/recordingVideoDuration); // End position of trim

	const dragAreaRef = useRef(null);

	const handleTrimChange = (value, handleType) => {
		if (handleType === 'start') {
			setTrimStart(value); // Directly set the value	    
		} else if (handleType === 'end') {
			setTrimEnd(value); // Directly set the value
		}
	};

	useEffect(() => { //do this to trigger rerender when ref ready
    if (dragAreaRef.current) {
     setRefReady(true)
    }
}, [dragAreaRef.current]);

	
 const handleSliderStart = () => {
		if (trimPreviewPlaying) {
			onTrimPreviewPlayPause(); // Pause if currently playing
		}
	};

	const handleSliderStop = () => {
		if (trimPreviewPlaying) {
			onTrimPreviewPlayPause(); // Resume playing if it was playing before drag
		}
	};

	const handleSliderChange = value => {
		const newProgress = value / 100;
		onTrimPreviewSeekChange(newProgress);
	};

	const handleTrimPositionChange = (newPosition) => {	  
		const newProgress = newPosition;
		onTrimPreviewSeekChange(newProgress); // Ensure this updates video position
	};


	const currentTime = formatTime(trimPreviewProgress * recordingVideoDuration);
	const durationTime = formatTime(recordingVideoDuration);


	const handleDoneClick = () => {
		updateClipTrimValues(clip,trimStart, trimEnd);
	}


	return (
		<div className='editor-trimbar'>

			<button onClick={onTrimPreviewPlayPause} className='editor-trimbar-playBtn'>
				{trimPreviewPlaying ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' />}
			</button>

			 <div className='editor-trimbar-timestamp editor-trimbar-timestamp--current'>
				<span>{currentTime.minutes}</span><span className='editor-trimbar-timestamp-colon'>:</span>
				<span>{currentTime.seconds}</span><span className='editor-trimbar-timestamp-colon editor-trimbar-timestamp-colon--miliseconds'>:</span>
				<span className='editor-trimbar-timestamp-milliseconds'>{currentTime.milliseconds}</span>
			</div>

			<div className='editor-trimbar-dragArea' ref={dragAreaRef}>
				
				<ReactSlider
					className="editor-trimbar-slider"
					thumbClassName="editor-trimbar-slider-thumb"
					trackClassName="editor-trimbar-slider-track"
					onBeforeChange={handleSliderStart}
					onAfterChange={handleSliderStop}
					onChange={handleSliderChange}
					value={trimPreviewProgress * 100} // Convert 0-1 range to 0-100 for slider
					min={0}
					max={100}
					step={0.1}
					renderThumb={(props, state) => {
						const { key, ...restProps } = props;
						return <div key={key} {...restProps} />;
					}}
				/>


			<EditorTrimBarHandles 
				initialTrimStart={trimStart}
				initialTrimEnd={trimEnd}
				onTrimChange={handleTrimChange}
				onTrimPositionChange={handleTrimPositionChange} // Pass the new function as a prop
				dragAreaWidth={dragAreaRef.current ? dragAreaRef.current.offsetWidth : 0}
				currentVideoPosition={trimPreviewProgress} // Assuming trimPreviewProgress is a decimal representing the current video position
				isVideoPaused={!trimPreviewPlaying}
			/>

			</div>

			 <div className='editor-trimbar-timestamp editor-trimbar-timestamp--duration'>
				<span>{durationTime.minutes}</span><span className='editor-trimbar-timestamp-colon'>:</span>
				<span>{durationTime.seconds}</span><span className='editor-trimbar-timestamp-colon editor-trimbar-timestamp-colon--miliseconds'>:</span>
				<span className='editor-trimbar-timestamp-milliseconds'>{durationTime.milliseconds}</span>
			</div>
			<button onClick={handleDoneClick} className='editor-trimbar-doneBtn'>
				Done
			</button>
		</div>
	);
};

export default EditorTrimBar;

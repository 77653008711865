import { post, fetch,put } from '../api';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {downloadMissingWebcamRecordings} from '../utils/assets/downloadMissingWebcamRecordings'

export const handleWebcamFileUpdate = (captureId, fileType) => ({
  type: 'WEBCAM_FILE_UPDATE',
  payload: { 
    captureId,
    fileType // 'transcript', 'facebox', etc.
  }
});

//TODO faceboxes
export function handleWebcamProcessingFieldUpdate(captureId, field) {
  return async (dispatch) => {    
    if (field === 'transcription') {
      try {
        const result = await ipcRenderer.invoke('download-webcam-recording-files', captureId, 'transcript.json');
        if (result.success) {
          dispatch(handleWebcamFileUpdate(captureId, 'transcript'));
        }
      } catch (error) {
        console.error('Error downloading transcript:', error);
        Sentry.captureException(error);
      }
    }
    if (field === 'facebox') {
      dispatch(handleWebcamFileUpdate(captureId, 'facebox'));
    }
    return dispatch({
      type: 'WEBCAM_PROCESSING_FIELD_UPDATE',
      payload: { captureId, field }
    });
  };
}


export function fetchWebcamRecordings(){
  return (dispatch) => fetch(`/webcam-recording`)
  .then((response) => {
    dispatch({ type: 'FETCH_WEBCAM_RECORDINGS_SUCCESS', response })
    downloadMissingWebcamRecordings(response)
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}


export const syncRecordingSessionWebcam = (clipId, duration, captureId) => async (dispatch) => {
  try {
    dispatch(setPendingWebcamUpload(captureId, clipId))
    
    // Define the base path similar to screen recordings
    const basePath = `webcamRecordings/${captureId}`
    const videoFileName = 'webcam.mp4'
    const videoMimeType = 'video/mp4'
    const audioMimeType = 'audio/mpeg'
    const pausesResumesPath = `webcamRecordings/${captureId}/pauses-resumes.json`;
    const pausesResumes = await ipcRenderer.invoke('read-file', pausesResumesPath);

    // Get the signed URLs from your backend
    const response = await post('/webcam-recording', {
      capture_id: captureId,
      filename: `capture_${captureId}`,
      content_type: videoMimeType,
      duration: duration,
      pauses_resumes: pausesResumes
    })

    // If fallback is false, we don't need to upload the files - the API is handling processing
    if (response?.fallback === false) {
      return response.recording
    }
    // Read both file buffers
    const videoPath = `${basePath}/${videoFileName}`
    const [videoBuffer, audioBuffer] = await Promise.all([
      ipcRenderer.invoke('read-file-buffer', videoPath),
      ipcRenderer.invoke('get-webcam-audio-buffer', captureId, 'audio_original')
    ])

    // Upload both files to S3 using the signed URLs
    await Promise.all([
      axios.put(response.videoUploadUrl, videoBuffer, {
        headers: { 'Content-Type': videoMimeType }
      }),
      axios.put(response.audioUploadUrl, audioBuffer, {
        headers: { 'Content-Type': audioMimeType }
      })
    ])
    const requestBody = {
      original_audio_file: 'audio_original.mp3',
    }

    const updateResponse = await put(`/webcam-recording/${captureId}/upload-complete`, requestBody)
    return updateResponse.recording
  } catch (error) {
    console.error('Error during upload:', error)
    error.name = 'Sync Webcam Recording Error'
    Sentry.withScope(scope => {
      scope.setExtra("CaptureID", captureId)
      scope.setExtra("ClipID", clipId)
      scope.setTag("error-type", "SyncWebcamRecordingError")
      scope.setLevel("error")
      if (error.filePath) {
        scope.setExtra("FailedFilePath", error.filePath)
        scope.setTag("Step", "FileUpload")
      } else if (error.response) {
        scope.setExtra("FailedResponse", error.response)
        scope.setTag("Step", "APIRequest")
      }
      Sentry.captureException(error)
    })
    throw error
  }
}

///TODO chunked upload and retries
export const uploadWebcamRecording = (clipId, file, duration, captureId) => async (dispatch) => {
  try {
    dispatch(setPendingWebcamUpload(captureId, clipId));
    const response = await post('/webcam-recording', {
      capture_id: captureId,
      filename: file.name,
      content_type: file.type,
      duration
    });
    
    // Upload video to S3 using the signed URL
    await axios.put(response.videoUploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    let requestBody = {};

    // // Check if audio file exists and upload it
    // try {
    //   const audioBuffer = await ipcRenderer.invoke('get-webcam-audio-buffer', captureId, 'audio_original');
    //   if (audioBuffer) {
    //     console.log('uploading audio file', audioBuffer);
    //     await axios.put(response.audioUploadUrl, audioBuffer, {
    //       headers: { 'Content-Type': 'audio/mpeg' }
    //     });
    //     requestBody.original_audio_file = 'audio_original.mp3';
    //   }
    // } catch (audioError) {
    //   console.log('No audio file found:', audioError);
    // }

    const updateResponse = await put(`/webcam-recording/${captureId}/upload-complete`, requestBody);
    return updateResponse.recording;
  } catch (error) {
    console.error('Error during upload:', error);
    Sentry.captureException(error);
    throw error;
  }
};


//Dont think we need this anymore
// export const uploadWebcamRecordingWithPath = (clipId,file, duration, captureId) => async (dispatch) => {
//   try {
//     console.log('uploading webcam recording with path', file.path, file.type, duration, captureId);
//     dispatch(setPendingWebcamUpload(captureId,clipId))
//     const response = await post('/webcam-recording', {
//       capture_id: captureId,
//       filename: file.name,
//       content_type: file.type,
//       duration
//     });
//     const result = await ipcRenderer.invoke('upload-webcam-recording-with-path', {path:file.path,type:file.type, uploadUrl: response.uploadUrl});
//     if(result.success){
//       const updateResponse = await put(`/webcam-recording/${captureId}/upload-complete`);
//       return updateResponse.recording
//     }else{
//       throw new Error(result.error)
//     }
//   } catch (error) {
//     console.error('Error during upload:', error);
//     Sentry.captureException(error);
//     throw error;
//   }
// };

export const setPendingWebcamUpload = (captureId, clipId) => ({
  type: 'SET_PENDING_WEBCAM_UPLOAD',
  payload: { captureId, clipId }
});


export const webcamProcessingComplete = (captureId,webcamData) => async (dispatch) => {
  try {
    console.log('Starting webcam file downloads for', captureId);
    const result = await ipcRenderer.invoke('download-webcam-recording-files', captureId);
    if (!result.success) {
      throw new Error(result.error);
    }
    dispatch({
      type: 'WEBCAM_PROCESSING_COMPLETE',
      captureId:captureId,
      webcamData: webcamData
    });

    dispatch(fetchWebcamRecordings())

  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: 'WEBCAM_PROCESSING_ERROR',
      payload: {
        captureId: captureId,
        error: error.message
      }
    });
  }
};
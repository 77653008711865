import { post } from '../api';



export function generateCaptions( chunk) {

  return (dispatch) => {
    return post(`/webcam_recordings/captions`, { words: chunk })
      .then((response) => {
        // console.log('captions response')
        // console.log(response)
        return response;
      })
      .catch((error) => {
        console.error('Error fetching captions:', error);
      });
  };
}



export function generateSubtitles( chunk) {
  return (dispatch) => {
    return post(`/webcam_recordings/subtitles`, { words: chunk })
      .then((response) => {
        // console.log('subtitles response')
        // console.log(response)
        return response;
      })
      .catch((error) => {
        console.error('Error fetching captions:', error);
      });
  };
}
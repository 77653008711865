import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import { SpringAnimator } from '../utils/animations/SpringAnimator';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import { simulateAnimationDuration } from '../utils/animations/simulateAnimationDuration';

const FPS = 60; // Frames per second

const defaultWordBoxValues = {
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  scale: 1,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  opacity: 1,
};

const animationFrom = { 
  opacity: 0.6,
  scale: 0.95, 
  positionY: 0,
};

const animationTo = { 
  opacity: 1,
  scale: 1, 
  positionY: 0,
};

const animationParams = {  
  mass: 1,
  stiffness: 400,
  damping: 20,          
};

const CaptionsWordBox = ({ word, localTime }) => {
  let boxColor = "white";
  let maxOpacity = 0.1;  
  
  const startTime = word.startTime;
  const endTime = word.endTime;
  const duration = endTime - startTime;

  const extraTop = 1;
  const extraBottom = extraTop;
  const extraLeft = 8;
  const extraRight = extraLeft;
  const wordBoxWidth = word.width + extraLeft + extraRight;
  const wordBoxHeight = word.height + extraTop + extraBottom;

  const groupRef = useRef();
  const meshRef = useRef();

  const [animationStates, setAnimationStates] = useState(() => 
    Object.keys(defaultWordBoxValues).reduce((acc, key) => {
      acc[key] = [defaultWordBoxValues[key]];
      return acc;
    }, {})
  );

  const wordBoxGeometry = useMemo(() => {
    const shape = createRoundedRectShape(wordBoxWidth, wordBoxHeight, 15);
    return new THREE.ShapeGeometry(shape);
  }, [wordBoxWidth, wordBoxHeight]);

  useEffect(() => {
    let newAnimationStates = { ...animationStates };
    const framesCount = Math.round(duration * FPS);

    // Calculate the animation durations
    const inAnimationDuration = simulateAnimationDuration(animationFrom, animationTo, animationParams, FPS);
    const outAnimationDuration = simulateAnimationDuration(animationTo, animationFrom, animationParams, FPS);

    const outAnimationStartFrame = Math.max(0, framesCount - Math.round(outAnimationDuration * FPS));

    Object.keys(defaultWordBoxValues).forEach(property => {
      const spring = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        animationFrom[property] !== undefined ? animationFrom[property] : defaultWordBoxValues[property]
      );

      for (let frame = 0; frame < framesCount; frame++) {
        const simulationLocalTime = frame / FPS;

        if (simulationLocalTime < inAnimationDuration) {
          spring.setTarget(animationTo[property] !== undefined ? animationTo[property] : defaultWordBoxValues[property]);
        } else if (frame >= outAnimationStartFrame) {
          spring.setTarget(animationFrom[property] !== undefined ? animationFrom[property] : defaultWordBoxValues[property]);
        }

        const isOpacity = property === 'opacity';
        spring.simulate(1000 / FPS, isOpacity, 0, 1);
        newAnimationStates[property].push(spring.position);
      }
    });

    setAnimationStates(newAnimationStates);
  }, [duration]);

  useEffect(() => {
    if (groupRef.current && meshRef.current) {
      if (localTime >= startTime && localTime <= endTime) {
        const relativeTime = localTime - startTime;
        const frameIndex = Math.floor(relativeTime * FPS);

        if (frameIndex >= 0 && frameIndex < animationStates.opacity.length) {
          Object.keys(defaultWordBoxValues).forEach(property => {
            const value = animationStates[property][frameIndex];
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              groupRef.current.position[axis] = value;
            } else if (property === 'scale') {
              meshRef.current.scale.set(value, value, value);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              groupRef.current.rotation[axis] = value;
            } else if (property === 'opacity') {
              meshRef.current.material.opacity = value * maxOpacity;
              //console.log(value * maxOpacity)
            }
          });
        }
      } else {
        // Reset to initial state when outside the word's time range
        groupRef.current.position.set(0, 0, 0);
        meshRef.current.scale.set(animationFrom.scale, animationFrom.scale, animationFrom.scale);
        meshRef.current.material.opacity = 0; // Set opacity to 0 when outside time range
      }
    }
  }, [localTime, startTime, endTime, animationStates]);

  // Determine if the word box should be visible
  const isVisible = localTime >= startTime && localTime <= endTime;

  //console.log(isVisible)

  return (
    <group ref={groupRef} position={[0, 0, 2]} visible={isVisible} renderOrder={100}>
      <mesh
        ref={meshRef}
        geometry={wordBoxGeometry}
        renderOrder={700}
        position={[word.normalLeft, word.normalTop, 2]}
      >
        <meshBasicMaterial 
          color={boxColor}
          transparent={true} 
          opacity={animationFrom.opacity * maxOpacity}
          depthWrite={false}
          depthTest={false}
        />
      </mesh>
    </group>
  );
};

export default CaptionsWordBox;
import React from 'react';
import Icon from '../../misc/Icon'; // Adjust the path as needed

function TextSlideTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--textSlide'>
      Headline
    </div>
  );
}

function ChartsTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--charts'>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar'>
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block' />
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-labelBlob' />        
      </div>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar'>
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block' />
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-labelBlob' />        
      </div>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar'>
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block' />
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-labelBlob' />        
      </div>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar'>
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-block' />
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--charts-bar-labelBlob' />        
      </div>
      
    </div>
  );
}

function ScreenVideoTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--screenVideo'>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--screenVideo-device'>

      </div>
    </div>
  );
}


function RecordTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--record'>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-window'>      
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots'>      
          <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots-dot' />
          <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots-dot' />
          <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-window-dots-dot' />
        </div>
      </div>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-toolbar'>
        <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-toolbar-circle' />
      </div>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--record-webcam' />      
    </div>
  );
}


function MediaTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--media'>
      <Icon name='imageClipLight' />
      <Icon name='videoClipLight' />
    </div>
  );
}

function AddFromLibraryTooltipContent() {
  return (    
    <div className='editor-toolbar-insertBar-btn-tooltip-contents editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary'>
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
      <div className='editor-toolbar-insertBar-btn-tooltip-contents--addFromLibrary-video' />
    </div>
  );
}

export { TextSlideTooltipContent, MediaTooltipContent, ChartsTooltipContent, ScreenVideoTooltipContent, AddFromLibraryTooltipContent, RecordTooltipContent };

import React, {useState} from 'react'
import Icon from '../../misc/Icon'
import SelectColorDropdown from '../../uiKit/SelectColorDropdown'
import * as Tooltip from '@radix-ui/react-tooltip';
import { startTransitionConfigs } from '../../../three/textSlide/transitionConfigs/startTransitionConfigs'
import { endTransitionConfigs } from '../../../three/textSlide/transitionConfigs/endTransitionConfigs'
import find from 'lodash/find'
import { ToggleGroup, ToggleGroupBtn } from '../../uiKit/ToggleUI';
import { SmallTabGroup, SmallTab } from '../../uiKit/SmallTabsUI';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import {getAvailableTextStyles} from '../../../utils/brands/getAvailableTextStyles'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import { getTextColorForId } from '../../../utils/brands/getTextColorForId'
import {getTextColorForBackgroundId} from '../../../utils/brands/getTextColorForBackgroundId'

function PreviewButton({previewAnimation,clip,animationType,previewClip,previewType,resetPreview}) {
  let isPlaying = previewClip && previewClip.id === clip.id && previewType === animationType;
  const handleClick = (e) => {
    e.stopPropagation()
    if (isPlaying) {
      resetPreview();
    } else {
      previewAnimation(clip, animationType);
    }
  };
  return (
    <div onClick={handleClick} className='uiButton--preview-previewBtn'>
      <div className='uiButton--preview-previewBtn-iconContainer'>
        <Icon name={isPlaying ? 'playbackPause': 'playbackPlay'} />
      </div>
      <div className='uiButton--preview-previewBtn-label'>
        Preview
      </div>      
    </div>
  ); 
}

const motionOptions = [
  { value: 'auto', label: 'Auto' },
  { value: 'smooth', label: 'Smooth' },
  { value: 'bouncy', label: 'Bouncy' },
  { value: 'molasses', label: 'Molasses' },
  { value: 'slow', label: 'Slow' },
  { value: 'zippy', label: 'Zippy' },     
];


 //list type null,bullet or number
function TextSlidePanel({textSlideClip,updateClipAnimationSettings,updateClipMetadata,updateSlideClipTextStyle,deleteClip,previewAnimation,previewClip,previewType,resetPreview,setRandomTextSlideContent,updateTextSlideTextColor,updateTextSlideBackgroundColor,defaultMotionStyle,projectBackgroundId}) {  
  const clipId = textSlideClip.id
  const {listType,textAlign,animationGrouping} = textSlideClip.metadata

  const handleUpdateTextStyle = (newTextStyle) =>{
    updateSlideClipTextStyle(textSlideClip.id,newTextStyle)
  }


  const handleBulletToggle = () => {
    let newListType='bullet'
    if(listType=='bullet'){
      newListType=null
    }
    updateClipMetadata(clipId,{listType:newListType})
  };
  const handleNumberToggle = () => {
    let newListType='number'
    if(listType=='number'){
      newListType=null
    }
    updateClipMetadata(clipId,{listType:newListType})
  };

  const activeStartTransition = textSlideClip.metadata.startTransitionType
  const activeEndTransition = textSlideClip.metadata.endTransitionType
  const orgBrand=getOrgBrand()
  const availableTextStyles=getAvailableTextStyles() //title,heading1 etc
  const activeTextStyle = textSlideClip.metadata.textStyle

  const showTextStyleDropdown = availableTextStyles.length > 1  
  const [textColorDropdownOpen, setTextColorDropdownOpen] = useState(false)
  const [backgroundColorDropdownOpen, setBackgroundColorDropdownOpen] = useState(false)
  const hasBackgroundColor = textSlideClip.metadata.backgroundId?true:false

  let background = getBackgroundForId(projectBackgroundId) 
  if(textSlideClip.metadata.backgroundId && textSlideClip.metadata.backgroundId !=='none'){
    background = getBackgroundForId(textSlideClip.metadata.backgroundId)
  }

  const textColorIsAuto = !textSlideClip.metadata.textColorId

  let textColor 
  if(textColorIsAuto){
    textColor = getTextColorForBackgroundId(background.id)
  }else{
    textColor = getTextColorForId(textSlideClip.metadata.textColorId)
  }

  const textColorPreviewStyle = {
    background: textColor.rgba
  }

  const backgroundColorPreviewStyle = background
      ? { background: background.type === 'solid' ? background.rgba : '' }
      : {};

  const backgroundColorIsImage = background && background.type === 'image'
  
  let backgroundColorImage
  if(backgroundColorIsImage){
    backgroundColorImage = background.src
  }
  let motionStyle = textSlideClip.metadata.motionStyle
  if(textSlideClip.metadata.isAutoMotionStyle){
    motionStyle='Auto'
  }
  const setMotionStyle=(style)=>{
    if(style=='auto'){
      updateClipAnimationSettings(textSlideClip.id,{motionStyle:defaultMotionStyle,isAutoMotionStyle:true})
    }else{
      updateClipAnimationSettings(textSlideClip.id,{motionStyle:style,isAutoMotionStyle:false})
    }
  }


  const [animateActiveTab, setAnimateActiveTab] = useState('in')
  const [enterDropdownOpen, setEnterDropdownOpen] = useState(false)
  const [exitDropdownOpen, setExitDropdownOpen] = useState(false)
  const addBG = textSlideClip.metadata.backgroundId == 'none'

  const textStyleLabels = {
    title: "Title",
    heading1: "Heading 1",
    heading2: "Heading 2",
    heading3: "Heading 3",
    body: "Body",
    bodyAlt: "Body Alt",
  };

  return (
    <div className='editor-topBar editor-topBar--textSlide '>
      {showTextStyleDropdown && 
        <>
          <DropdownMenu.Root modal={false}>            
            <DropdownMenu.Trigger asChild>
              <button className='uiButton uiButton--textStyle'>
                <div className='uiButton-iconContainer'>
                  <Icon name='aaText' />
                </div>                        
                <div className='uiButton-label'>
                  {textStyleLabels[activeTextStyle]}              
                </div>                                
              </button>
            </DropdownMenu.Trigger>            

            <DropdownMenu.Content             
              align="start"
              alignOffset={0}
              className='dropdownMenu dropdownMenu--selectTextStyle forceDarkTheme'
            >                        
              {availableTextStyles.map(textStyle => (
                <DropdownMenu.Item key={textStyle} onSelect={()=>{handleUpdateTextStyle(textStyle)}} className={activeTextStyle === textStyle ? 'dropdownMenu-item--active' : ''}>
                  {textStyleLabels[textStyle]}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>  

          <div className='editor-topBar-smallSpacer'/>
        </>
      }

      <ToggleGroup>
        <ToggleGroupBtn
          iconName='alignTextLeft'
          active={listType ? false : textAlign === 'left'}
          handleClick={() =>{updateClipMetadata(textSlideClip.id,{textAlign:'left'})}}
          tooltipLabel='Left Align'
          disabled={listType}
          longTooltipDelay
          tooltipBelow
        />
        <ToggleGroupBtn
          iconName='alignTextCenter'
          active={listType ? false : textAlign === 'center'}
          handleClick={() =>{updateClipMetadata(textSlideClip.id,{textAlign:'center'})}}
          tooltipLabel='Center Align'
          disabled={listType}
          longTooltipDelay
          tooltipBelow
        />
        <ToggleGroupBtn
          iconName='alignTextRight'
          active={listType ? false : textAlign === 'right'}
          handleClick={() =>{updateClipMetadata(textSlideClip.id,{textAlign:'right'})}}
          tooltipLabel='Right Align'
          disabled={listType}
          longTooltipDelay
          tooltipBelow
        />
      </ToggleGroup>

      <div className='editor-topBar-smallSpacer'/>

      <ToggleGroup>
        <ToggleGroupBtn
          iconName='bulletList'
          active={listType === 'bullet'}
          handleClick={handleBulletToggle}
          tooltipLabel='Bullet List'
          tooltipBelow
        />
        <ToggleGroupBtn
          iconName='numberList'
          active={listType === 'number'}
          handleClick={handleNumberToggle}            
          tooltipLabel='Number List'
          tooltipBelow
        />        
      </ToggleGroup>

      <div className='editor-topBar-smallSpacer'/>

      <SelectColorDropdown
        colors={orgBrand.textColors}
        activeColorId={textSlideClip.metadata.textColorId}
        handleSelectColor={(id,isAutoTextColor)=> updateTextSlideTextColor(textSlideClip.id,id,isAutoTextColor)}
        handleClosePopover={() => setTextColorDropdownOpen(false)}      
        isTextColor
        isAutoTextColor={textSlideClip.metadata.isAutoTextColor}
      >
         <button onClick={() => setTextColorDropdownOpen(!textColorDropdownOpen)} className='uiButton uiButton--textColor'>
            <div style={textColorPreviewStyle} className='uiButton-colorPreview' />                            
            <div className='uiButton-label'>
              Text{textColorIsAuto && <span className='uiButton-label-sub'>Auto</span> }
            </div>
          </button>
      </SelectColorDropdown>

      <div className='editor-topBar-smallSpacer'/>
    
      <SelectColorDropdown
        colors={orgBrand.backgrounds}
        activeColorId={textSlideClip.metadata.backgroundId}
        handleSelectColor={(id)=> updateTextSlideBackgroundColor(textSlideClip.id,id)}
        handleClosePopover={() => setBackgroundColorDropdownOpen(false)}      
        isSlideBGColor   
        isAutoBGColor={textSlideClip.metadata.backgroundId?false:true}    
        isNoneBGColor={textSlideClip.metadata.backgroundId=='none'}         
        colorCount={orgBrand.backgrounds.length}
      >
         <button data-state={hasBackgroundColor ? "hasValue" : "noValue"} onClick={() => setBackgroundColorDropdownOpen(!backgroundColorDropdownOpen)} className={'uiButton uiButton--textBGColor' + (addBG ? ' uiButton--textBGColor--add ' : '')}>
            {addBG && 
              <>
                <div className='uiButton-iconContainer'>
                  <Icon name='plusMedium' />
                </div>
                <div className='uiButton-label'>
                  Add BG
                </div>
              </>
            }
            {!addBG && 
              <>
                <div style={backgroundColorPreviewStyle} className={'uiButton-colorPreview ' + (backgroundColorIsImage ? ' uiButton-colorPreview--image ' : '')}>
                  {backgroundColorIsImage && 
                   <img src={backgroundColorImage} />
                  }
                </div>
                <div className='uiButton-label'>
                  BG{!textSlideClip.metadata.backgroundId && <span className='uiButton-label-sub'>Auto</span>}
                </div>
              </>
            }
          </button>
      </SelectColorDropdown>
      
      <div className='editor-topBar-smallSpacer'/>




      <div className='editor-topBar-hSpacer'/>
          <DropdownMenu.Root modal={false} onOpenChange={(open) => setEnterDropdownOpen(open)}>
            <Tooltip.Root delayDuration={200}>
              <Tooltip.Trigger asChild>
                <DropdownMenu.Trigger asChild>                  
                  <button className='uiButton uiButton--transitionDropdown uiButton--transitionDropdown--enter'>
                    <div className='uiButton-label'>
                      {startTransitionConfigs[activeStartTransition].label}
                    </div>                
                  </button>                  
                </DropdownMenu.Trigger>
              </Tooltip.Trigger>  
              {!enterDropdownOpen && !exitDropdownOpen &&                             
                <Tooltip.Content side="bottom" className="tooltip tooltip--toggleBtn">
                  Enter Animation                      
                </Tooltip.Content>                         
              }                 
            </Tooltip.Root>
            <DropdownMenu.Content             
              align="start"
              alignOffset={-24}
              className='dropdownMenu dropdownMenu--selectTransition forceDarkTheme'
            >            
              <div className='dropdownMenu-title'>
                Enter Animation
              </div> 

              <div className='dropdownMenu--selectTransition-groupingRow'>
                <button data-state={animationGrouping=='everything' ? 'active' : 'inactive'} className='dropdownMenu--selectTransition-groupingRow-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'everything'})}}>
                  All At Once
                </button>      
                <button data-state={animationGrouping=='line' ? 'active' : 'inactive'} className='dropdownMenu--selectTransition-groupingRow-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'line'})}}>
                  By Line
                </button>      
                <button data-state={animationGrouping=='word' ? 'active' : 'inactive'} className='dropdownMenu--selectTransition-groupingRow-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'word'})}}>
                  By Word
                </button>
              </div>  

              <div className='dropdownMenu--selectTransition-divider' />
               
              {Object.keys(startTransitionConfigs).map(configKey => (
                <button 
                  data-state={configKey === activeStartTransition ? "active" : "inactive"}
                  key={configKey}
                  onClick={()=>{updateClipAnimationSettings(clipId,{startTransitionType:configKey})}}
                  className='uiButton uiButton--preview'
                >
                  <div className='uiButton-label'>
                    {startTransitionConfigs[configKey].label}
                  </div>
                  <PreviewButton 
                    previewAnimation={previewAnimation} 
                    clip={textSlideClip}
                    animationType={configKey}
                    previewClip={previewClip}
                    previewType={previewType}
                    resetPreview={resetPreview}
                    />
                </button>
              ))}
          
            </DropdownMenu.Content>
          </DropdownMenu.Root>            

          <DropdownMenu.Root modal={false} onOpenChange={(open) => setExitDropdownOpen(open)}>
            <Tooltip.Root delayDuration={200}>
              <Tooltip.Trigger asChild>
                <DropdownMenu.Trigger asChild>                  
                  <button className='uiButton uiButton--transitionDropdown uiButton--transitionDropdown--exit'>
                    <div className='uiButton-label'>
                      {endTransitionConfigs[activeEndTransition].label}
                    </div>                
                  </button>                  
                </DropdownMenu.Trigger>
              </Tooltip.Trigger>               
              {!enterDropdownOpen && !exitDropdownOpen &&
                <Tooltip.Content side="bottom" className="tooltip tooltip--toggleBtn">
                  Exit Animation                      
                </Tooltip.Content>    
              }                                      
            </Tooltip.Root>                          
            <DropdownMenu.Content             
              align="end"
              alignOffset={-24}
              className='dropdownMenu dropdownMenu--selectTransition forceDarkTheme'
            >            
              <div className='dropdownMenu-title'>
                Exit Animation
              </div>             
              <div className='dropdownMenu--selectTransition-divider' />
              {Object.keys(endTransitionConfigs).map(configKey => (
                <button 
                  data-state={configKey === activeEndTransition ? "active" : "inactive"}
                  key={configKey}
                  onClick={()=>{updateClipAnimationSettings(clipId,{endTransitionType:configKey})}}
                  className='uiButton uiButton--preview'
                >
                  <div className='uiButton-label'>
                  {endTransitionConfigs[configKey].label}
                  </div>
                  {/*}
                  <PreviewButton 
                    previewAnimation={previewAnimation} 
                    clip={textSlideClip}
                    animationType={configKey}
                    previewClip={previewClip}
                    previewType={previewType}
                    resetPreview={resetPreview}
                    />
                  */}
                </button>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>  

          <div className='editor-topBar-smallSpacer'/>          
          
          <DropdownMenu.Root modal={false}>
            <Tooltip.Root delayDuration={200}>
              <Tooltip.Trigger asChild>
                <DropdownMenu.Trigger asChild>
                  <button className='uiButton uiButton--motionStyle'>
                    <div className='uiButton-iconContainer'>
                      <Icon name='stopwatch' />
                    </div>        
                    {/*}        
                    <div className='uiButton-label'>
                      {motionStyle}                
                    </div>                
                    */}
                  </button>
              </DropdownMenu.Trigger>
            </Tooltip.Trigger>               
            {!exitDropdownOpen &&
              <Tooltip.Content side="bottom" align="end" className="tooltip tooltip--toggleBtn">
                Animation Speed            
              </Tooltip.Content>    
            }                                      
            </Tooltip.Root>      

            <DropdownMenu.Content             
              align="end"
              alignOffset={0}
              className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
            >            
              <div className='dropdownMenu-explainer'>
                Animation speed
              </div>
              {motionOptions.map(option => (
                <DropdownMenu.Item key={option.value} onSelect={() => setMotionStyle(option.value)} className={motionStyle === option.label ? 'dropdownMenu-item--active' : ''}>
                  {option.label}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>  


    
    
        {/* TEXT COLORS 
        



        <div className='rightPanel-separator' />

        {/*}
        <div className='rightPanel-subheader'>
          Animation
        </div>
        */}


        {/*}
        <div className='rightPanel-row rightPanel-row--tabAndDropdown'>
          
          
          <SmallTabGroup>
            <SmallTab
              label='Animate In'
              active={animateActiveTab === 'in'}
              handleClick={() => setAnimateActiveTab('in')}              
            />
            <SmallTab
              label='Animate Out'
              active={animateActiveTab === 'out'}
              handleClick={() => setAnimateActiveTab('out')}                              
            />          
          </SmallTabGroup>
          
          
          <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger asChild>
              <button className='uiButton uiButton--motionStyle'>
                <div className='uiButton-iconContainer'>
                  <Icon name='stopwatch' />
                </div>                
                <div className='uiButton-label'>
                  {motionStyle}                
                </div>                
              </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content             
              align="end"
              alignOffset={-48}
              className='dropdownMenu dropdownMenu--selectMotionPanel forceDarkTheme'
            >            
              <div className='dropdownMenu-explainer'>
                Slide animation settings.
              </div>
              {motionOptions.map(option => (
                <DropdownMenu.Item key={option.value} onSelect={() => setMotionStyle(option.value)} className={motionStyle === option.label ? 'dropdownMenu-item--active' : ''}>
                  {option.label}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>  
        
        </div>
        


        {animateActiveTab === 'in' && 
          <>
            
            <div className='rightPanel-row rightPanel-row--transitionBy'>
            
              <div className='rightPanel-row-smallLabel'>
                Transition
              </div>          
              <button data-state={animationGrouping=='everything' ? 'active' : 'inactive'} className='rightPanel-row-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'everything'})}}>
                All At Once
              </button>      
              <button data-state={animationGrouping=='line' ? 'active' : 'inactive'} className='rightPanel-row-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'line'})}}>
                By Line
              </button>      
              <button data-state={animationGrouping=='word' ? 'active' : 'inactive'} className='rightPanel-row-smallLabelBtn' onClick={() =>{updateClipMetadata(clipId,{animationGrouping:'word'})}}>
                By Word
              </button>      
            </div>

          
            {Object.keys(startTransitionConfigs).map(configKey => (
              <button 
                data-state={configKey === activeStartTransition ? "active" : "inactive"}
                key={configKey}
                onClick={()=>{updateClipAnimationSettings(clipId,{startTransitionType:configKey})}}
                className='uiButton uiButton--preview'
              >
                <div className='uiButton-label'>
                {startTransitionConfigs[configKey].label}
                </div>
                <PreviewButton 
                  previewAnimation={previewAnimation} 
                  clip={textSlideClip}
                  animationType={configKey}
                  previewClip={previewClip}
                  previewType={previewType}
                  resetPreview={resetPreview}
                  />
              </button>
            ))}
          </>
        }

        {animateActiveTab === 'out' && 
          <>
          <div className='rightPanel-row rightPanel-row--transitionBy'>            
            <div className='rightPanel-row-smallLabel'>
              Transition
            </div>                           
          </div>

          {Object.keys(endTransitionConfigs).map(configKey => (
              <button 
                data-state={configKey === activeEndTransition ? "active" : "inactive"}
                key={configKey}
                onClick={()=>{updateClipAnimationSettings(clipId,{endTransitionType:configKey})}}
                className='uiButton uiButton--preview'
              >
                <div className='uiButton-label'>
                {endTransitionConfigs[configKey].label}
                </div>
                {/*}
                <PreviewButton 
                  previewAnimation={previewAnimation} 
                  clip={textSlideClip}
                  animationType={configKey}
                  previewClip={previewClip}
                  previewType={previewType}
                  resetPreview={resetPreview}
                  />
                
              </button>
            ))}
          </>
        }
        */}
    {/*}
          <div className='rightPanel-vSpacer' />
          

           <div className='rightPanel-row'>
            <button className='uiButton' onClick={()=>{setRandomTextSlideContent(textSlideClip)}}>
              <div className='uiButton-label'>
                Random Text Input
              </div>
            </button>
          </div>
          <div className='rightPanel-row'>   
            <button className='uiButton' onClick={()=>{setRandomTextSlideContent(textSlideClip,true)}}>
              <div className='uiButton-label'>
                Random Text Paragraphs
              </div>
            </button>
          </div>
          */}
    {/*}
          <button className='uiButton uiButton--centered uiButton--light' onClick={()=>{deleteClip(clipId)}}>
            <div className='uiButton-label'>
              Delete
            </div>
          </button>
          */}
    </div>
  );
}

export default TextSlidePanel;


export default function (state = [], action) {
	switch (action.type) {
    case 'SIGN_OUT':
      return []
    case 'FETCH_ARCHIVED_PROJECTS_SUCCESS':
      return action.response
    case 'REMOVE_FROM_ARCHIVED_PROJECTS':
      return state.filter(project => project.id !== action.projectId)
    case 'ADD_TO_ARCHIVED_PROJECTS':
      return [...state, action.project]
    default:
      return state;
	}
}

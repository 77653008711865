import {post} from '../api'


///TODO move all the file syncing login into here-------

export function syncNewRecordingSession(sessionCaptureId) {
  return async (dispatch) => {
    const metadataPath = `recordingSessions/${sessionCaptureId}/sessionMetadata.json`;
    const session = await ipcRenderer.invoke('read-file', metadataPath);    
    const pausesResumesPath = `recordingSessions/${sessionCaptureId}/pauses-resumes.json`;
    const pausesResumes = await ipcRenderer.invoke('read-file', pausesResumesPath);
    const { screenRecordingCaptureId, webcamRecordingCaptureId, durationSeconds } = JSON.parse(session);
    const response = await post('/recording-sessions', {
      capture_id: sessionCaptureId,
      screen_recording_capture_id: screenRecordingCaptureId,
      webcam_recording_capture_id: webcamRecordingCaptureId,
      duration:durationSeconds,
      pauses_resumes: pausesResumes
    });
    return response
  };
}
export const combineEqualAdjacentPositions = (positions, clipDuration) => {
    try {
        if (!positions || positions.length === 0) return [];

        // Sort positions by startTime
        const sortedPositions = [...positions].sort((a, b) => a.startTime - b.startTime);

        const result = sortedPositions.reduce((acc, currentPosition, index) => {
            if (currentPosition.x === undefined || currentPosition.y === undefined || 
                currentPosition.width === undefined || currentPosition.height === undefined) {
                return acc;
            }
            // Round position values to 2 decimal places
            const roundedCurrent = {
                x: parseFloat(currentPosition.x.toFixed(2)),
                y: parseFloat(currentPosition.y.toFixed(2)),
                width: parseFloat(currentPosition.width.toFixed(2)),
                height: parseFloat(currentPosition.height.toFixed(2)),
                startTime: currentPosition.startTime,
                // Set endTime to next position's startTime or clipDuration if it's the last position
                endTime: index < sortedPositions.length - 1 ? sortedPositions[index + 1].startTime : clipDuration
            };

            // Get the last combined position
            const lastPosition = acc[acc.length - 1];

            // If this is the first position or if it's different from the last position
            if (!lastPosition ||
                lastPosition.x !== roundedCurrent.x ||
                lastPosition.y !== roundedCurrent.y ||
                lastPosition.width !== roundedCurrent.width ||
                lastPosition.height !== roundedCurrent.height) {
                // Add as new position
                acc.push(roundedCurrent);
            } else {
                // If positions are the same, update the endTime of the last position
                lastPosition.endTime = roundedCurrent.endTime;
            }

            return acc;
        }, []);

        return result;
    } catch (error) {
        console.log('positions', positions)
        console.error('Error in combineEqualAdjacentPositions', error);
        return [];
    }
}
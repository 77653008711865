import {Schema} from 'prosemirror-model'
import TranscriptNodes from './nodes/TranscriptNodes'
// import TranscriptMarks from './marks/TranscriptMarks'

const transcriptSchema = new Schema({
  nodes:TranscriptNodes,
  marks: []
})


export default transcriptSchema
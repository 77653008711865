// SoundEffectManager.js

import { keyToSoundNumber } from '../utils/soundEffects/keyboardSoundMappings';
import { SOUND_EFFECTS_CONFIG } from '../utils/soundEffects/soundEffectsConfig'
import { calculateZoomEffects } from '../utils/soundEffects/calculateZoomEffects'


//Sound Effects objects
//effectType: mouse,keyboard, sceneTranstion
//action: down,up
//keyValue: a,b,c,delete,enter,space, etc.


const getVolume = (effectType, settings) => {
  return SOUND_EFFECTS_CONFIG.getVolumeForEffect(effectType, settings)
}

export class SoundEffectManager {
  constructor(settings) {
    this.settings = settings;
    this.audioContext = null;
    this.buffers = new Map(); 
    this.volume = 1;            
    this.soundEffects = [];  
    this.pendingEffects = [];
    this.effectsVolumes={}

    this.checkInterval = null;
    this.lastCheckTime = 0;
    this.currentTime = 0;

    this.updateEffectVolumes(settings)
    
  }

  updateEffectVolumes(settings) {
    this.effectVolumes = {
      mouse: getVolume('mouse', settings.mouse),
      keyboard: getVolume('keyboard', settings.keyboard),
      sceneTransition: getVolume('sceneTransition', settings.sceneTransition),
      zoom: getVolume('zoom', settings.zoom),
    };
  }


	async updateSettings(settings) {
		const previousSoundSet = this.settings.keyboard.soundSet;
    const previousTransitionSound = this.settings.sceneTransition.soundEffect;
		this.settings = settings;
		// If sound set changed, reload keyboard sounds
		if (previousSoundSet !== settings.keyboard.soundSet) {
			// Clear old sound set buffers
			for (const key of this.buffers.keys()) {
				if (key.startsWith(previousSoundSet)) {
					this.buffers.delete(key);
				}
			}
			await this.loadKeyboardSounds();
		}
    if (previousTransitionSound !== settings.sceneTransition.soundEffect) {
      // Clear old transition sound
      this.buffers.delete(`sceneTransition-${previousTransitionSound}`);
      await this.loadSceneTransitionSounds();
    }
		this.updateEffectVolumes(settings);
	}

  
  async initialize() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    try {
      await this.loadSceneTransitionSounds();
      await this.loadKeyboardSounds();
      await this.loadMouseSounds();
      await this.loadZoomSounds();
    //  console.log('Sound effects initialized:', this.buffers.size, 'sounds loaded');
    } catch (error) {
      //console.error('Error loading sound effects:', error);
    }
  }

  async loadZoomSounds() {
    const soundPaths = {
        'in': 'soundEffects/zoom/zoom-in.mp3',
        'out': 'soundEffects/zoom/zoom-out.mp3',
        'switch': 'soundEffects/zoom/zoom-switch.wav'
    };

    for (const [action, path] of Object.entries(soundPaths)) {
        const audioBuffer = await this.loadSoundEffectAsBuffer(path);
        this.buffers.set(`zoom-${action}`, { buffer: audioBuffer, defaultVolume: 1 });
    }
}

  async loadSceneTransitionSounds() {
    const { sceneTransition } = SOUND_EFFECTS_CONFIG.sounds;
    const selectedSound = sceneTransition.find(
      sound => sound.value === this.settings.sceneTransition.soundEffect
    );
    if (!selectedSound) {
      return;
    }
    try {
      const buffer = await this.loadSoundEffectAsBuffer(selectedSound.audioPath);
      this.buffers.set(`sceneTransition-${selectedSound.value}`, {
        buffer,
        defaultVolume: 1
      });
    } catch (error) {
     // console.error(`Error loading scene transition sound ${selectedSound.value}:`, error);
    }
  }


  async loadSoundEffectAsBuffer(path) {
    const arrayBuffer = await window.ipcRenderer.invoke('read-sound-effect-file', path);
    return new Promise((resolve, reject) => {
      this.audioContext.decodeAudioData(
        arrayBuffer,
        (decodedData) => resolve(decodedData),
        (err) => reject(err)
      );
    });
  }

  async loadKeyboardSounds() {
		const uniqueSoundNumbers = [...new Set(Object.values(keyToSoundNumber))];
		const soundSet = this.settings.keyboard.soundSet;
		for (const soundNumber of uniqueSoundNumbers) {
			try {
				const [downBuffer, upBuffer] = await Promise.all([
					this.loadSoundEffectAsBuffer(`soundEffects/keyboard/${soundSet}/${soundNumber}-down.wav`),
					this.loadSoundEffectAsBuffer(`soundEffects/keyboard/${soundSet}/${soundNumber}-up.wav`)
				]);

				this.buffers.set(`${soundSet}-${soundNumber}-down`, { 
					buffer: downBuffer, 
					defaultVolume: 1 
				});
				this.buffers.set(`${soundSet}-${soundNumber}-up`, { 
					buffer: upBuffer, 
					defaultVolume: 1 
				});
			} catch (error) {
				console.error(`Error loading sound for set ${soundSet}, number ${soundNumber}:`, error);
			}
		}
	}

  async loadMouseSounds() {
    const mouseActions = ['down', 'up'];
    for (const action of mouseActions) {
      const audioBuffer = await this.loadSoundEffectAsBuffer(`soundEffects/mouse/mouse-${action}.wav`);
      // Store with new format: effectType-action
      this.buffers.set(`mouse-${action}`, { buffer: audioBuffer, defaultVolume: 1 });
    }
  }

  updatePlaybackTime(currentTime) {
    this.currentTime = currentTime;
    this.lastCheckTime = performance.now() / 1000;

    this.pendingEffects = this.soundEffects.filter(effect =>
      effect.startTime >= currentTime &&
      effect.startTime <= currentTime + 0.1
    );
    this.pendingEffects.sort((a, b) => a.startTime - b.startTime);

    if (this.pendingEffects.length > 0 && !this.checkInterval) {
      // Poll every 5ms to see if they've come due
      this.checkInterval = setInterval(() => this.checkPendingEffects(), 5);
    }
  }

  checkPendingEffects() {
    const currentTime = this.currentTime;
    const now = performance.now() / 1000;
    const deltaTime = now - this.lastCheckTime;
    const interpolatedTime = currentTime + deltaTime;
    const effectsToPlay = this.pendingEffects.filter(effect => 
      effect.startTime <= interpolatedTime && 
      effect.startTime > interpolatedTime - 0.01
    );

    this.pendingEffects = this.pendingEffects.filter(effect => effect.startTime > interpolatedTime);
    effectsToPlay.forEach(effect => this.playEffect(effect));
    if (this.pendingEffects.length === 0 && this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
      this.lastCheckTime = 0;
    }
  }

  //TODO can clean this up and combine the effects
  playEffect(effect) {
    if (!this.audioContext) {
      console.warn('AudioContext is not initialized.');
      return;
    }

    let bufferKey;
    let finalVolume = this.effectVolumes[effect.effectType];
 //   console.log('finalVolume',finalVolume)

    if (effect.effectType === 'keyboard') {
      const soundNumber = effect.soundNumber;
      if (!soundNumber) {
        console.log('No sound mapping found for key:', effect.keyValue);
        return;
      }
      bufferKey = `${this.settings.keyboard.soundSet}-${soundNumber}-${effect.action}`;
    } else if (effect.effectType === 'mouse') {
      bufferKey = `mouse-${effect.action}`;
    } else if (effect.effectType === 'sceneTransition') {
      bufferKey = `sceneTransition-${this.settings.sceneTransition.soundEffect}`;
    } else if (effect.effectType === 'zoom') {
      bufferKey = `zoom-${effect.action}`;
    }

    const bufferData = this.buffers.get(bufferKey);
    if (!bufferData) {
      console.log('No audio buffer found for:', bufferKey);
      return;
    }

    const { buffer } = bufferData;
    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = finalVolume;
    source.connect(gainNode);
    gainNode.connect(this.audioContext.destination);

    source.start(this.audioContext.currentTime);
  }



  async calculateSoundEffects(timeline) {
    const soundEffects = [];
    // Add scene transition effects
    for (let i = 0; i < timeline.scenes.length; i++) {
      const scene = timeline.scenes[i];
      if (this.settings.sceneTransition.enabled && i > 0) {
        soundEffects.push({
          effectType: 'sceneTransition',
          startTime: scene.startTime,
          sceneId: scene.id
        });
      }
      
      // Collect mouse and keyboard effects
      if (this.settings.mouse.enabled || this.settings.keyboard.enabled) {
        for (const clip of scene.clips) {
          if (clip.type === 'video' && clip.isScreenRecording) {
            const videoEffects = await clip.getSoundEffects(this.settings.mouse.enabled, this.settings.keyboard.enabled);
            soundEffects.push(...videoEffects);
          }
        }
      }
    }

    // Add zoom effects if enabled
    if (this.settings.zoom.enabled) {
      const zoomClips = timeline.clips.filter(clip => clip.type === 'zoom');
      const zoomEffects = calculateZoomEffects(zoomClips);
      soundEffects.push(...zoomEffects);
    }

    // Apply keyboard timing offsets
    const { soundSet } = this.settings.keyboard;
    const keydownTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.keyboard[soundSet].down;
    const keyupTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.keyboard[soundSet].up;

    const mouseDownTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.mouse.down;
    const mouseUpTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.mouse.up;

    const zoomInTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.zoom.in;
    const zoomOutTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.zoom.out;
    const zoomSwitchTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.zoom.switch;

    const sceneTransitionTimingOffset = SOUND_EFFECTS_CONFIG.timingOffset.sceneTransition.in;

   // console.log('sceneTransitionTimingOffset',sceneTransitionTimingOffset)
    this.soundEffects = soundEffects.map(effect => {
      if(effect.effectType === 'sceneTransition'){
        return {
          ...effect,
          startTime: Math.max(effect.startTime + sceneTransitionTimingOffset, 0)  
        };
      }
      
      if (effect.effectType === 'keyboard') {
        return {
          ...effect,
          startTime: Math.max(effect.startTime + (effect.action === 'down' ? keydownTimingOffset : keyupTimingOffset), 0)
        };
      } else if (effect.effectType === 'mouse') {
        return {
          ...effect,
          startTime: Math.max(effect.startTime + (effect.action === 'down' ? mouseDownTimingOffset : mouseUpTimingOffset), 0)
        };
      } else if (effect.effectType === 'zoom') {
        return {
          ...effect,
          startTime: Math.max(effect.startTime + (effect.action === 'in' ? zoomInTimingOffset : effect.action === 'out' ? zoomOutTimingOffset : zoomSwitchTimingOffset), 0)
        };
      }
      return effect;
    });
 //   console.log('soundEffects',soundEffects)
  }


  
  /** Cleanup all intervals & references */
  cleanup() {
    // Clear intervals
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }
    this.pendingEffects = [];
    this.soundEffects = [];

    // Not strictly necessary to do anything for the audio buffers 
    // unless you want to free references:
    this.buffers.clear();
  }

  /** Gracefully stop and close the AudioContext */
  destroy() {
    this.cleanup();
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
  }
}

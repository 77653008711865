import React, { useRef, useEffect, useCallback } from 'react';
import * as THREE from 'three';

const ScreenVideoWindowModeCroppedMaterial = ({ 
  videoElement, 
  opacity,
  meshWidth,
  meshHeight,
  recordingWidth,
  recordingHeight,
  windowX,
  windowY,
  windowWidth,
  windowHeight
}) => {
  const materialRef = useRef(null);
  const textureRef = useRef(null);

  // Debug logging
  // console.log('Cropping params:', {
  //   recordingWidth,
  //   recordingHeight,
  //   windowX,
  //   windowY,
  //   windowWidth,
  //   windowHeight
  // });

  const updateTexture = useCallback(() => {
    let needNewTexture = false;
    
    if (!textureRef.current || (videoElement && !(textureRef.current instanceof THREE.VideoTexture))) {
      needNewTexture = true;
    }

    if (needNewTexture && videoElement) {
      textureRef.current = new THREE.VideoTexture(videoElement);
      textureRef.current.colorSpace = THREE.SRGBColorSpace;
      textureRef.current.magFilter = THREE.LinearFilter;
      textureRef.current.minFilter = THREE.LinearFilter;
      textureRef.current.generateMipmaps = false;
    } else if (videoElement && textureRef.current instanceof THREE.VideoTexture) {
      textureRef.current.image = videoElement;
    }

    if (textureRef.current) {
      textureRef.current.needsUpdate = true;
    }

    return textureRef.current;
  }, [videoElement]);

  const updateTextureSettings = useCallback(() => {
    if (!textureRef.current || !recordingWidth || !recordingHeight) {
      console.log('Missing required values for texture update');
      return;
    }

    // Calculate relative dimensions
    const relativeWindowX = windowX / recordingWidth;
    const relativeWindowY = 1 - ((windowY + windowHeight) / recordingHeight); // Flip Y coordinate
    const relativeWindowWidth = windowWidth / recordingWidth;
    const relativeWindowHeight = windowHeight / recordingHeight;

    // console.log('Texture settings:', {
    //   relativeWindowX,
    //   relativeWindowY,
    //   relativeWindowWidth,
    //   relativeWindowHeight
    // });

    // Set texture repeat to crop to window size
    textureRef.current.repeat.set(relativeWindowWidth, relativeWindowHeight);

    // Set texture offset to position window correctly
    textureRef.current.offset.set(relativeWindowX, relativeWindowY);

    // Handle aspect ratio differences
    const meshAspect = meshWidth / meshHeight;
    const windowAspect = windowWidth / windowHeight;

    if (meshAspect > windowAspect) {
      // Mesh is wider than window - adjust X
      const scale = windowAspect / meshAspect;
      textureRef.current.repeat.x *= scale;
      textureRef.current.offset.x += (relativeWindowWidth * (1 - scale)) / 2;
    } else {
      // Mesh is taller than window - adjust Y
      const scale = meshAspect / windowAspect;
      textureRef.current.repeat.y *= scale;
      textureRef.current.offset.y += (relativeWindowHeight * (1 - scale)) / 2;
    }

    textureRef.current.needsUpdate = true;
  }, [meshWidth, meshHeight, recordingWidth, recordingHeight, windowX, windowY, windowWidth, windowHeight]);

  useEffect(() => {
    const texture = updateTexture();
    
    if (!materialRef.current) {
      materialRef.current = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: opacity,
        precision: 'highp',
      });
    } else {
      materialRef.current.map = texture;
    }
    
    updateTextureSettings();
    materialRef.current.needsUpdate = true;
  }, [updateTexture, updateTextureSettings, opacity]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.opacity = opacity;
      materialRef.current.needsUpdate = true;
    }
  }, [opacity]);

  useEffect(() => {
    updateTexture();
    updateTextureSettings();
    if (materialRef.current) {
      materialRef.current.needsUpdate = true;
    }
  }, [videoElement, updateTexture, updateTextureSettings]);

  return materialRef.current ? <primitive object={materialRef.current} attach="material" /> : null;
};

export default ScreenVideoWindowModeCroppedMaterial;
import { Plugin, PluginKey } from "prosemirror-state"
const key = new PluginKey('selectionStylingPlugin')

function getRelativePosition(view, coords) {
  const editorDom = view.dom
  const editorRect = editorDom.getBoundingClientRect()
  const scrollLeft = editorDom.scrollLeft || 0
  const scrollTop = editorDom.scrollTop || 0
  
  return {
    left: coords.left - editorRect.left + scrollLeft,
    top: coords.top - editorRect.top + scrollTop,
    right: coords.right - editorRect.left + scrollLeft,
    bottom: coords.bottom - editorRect.top + scrollTop
  }
}

function findLineRanges(view, from, to) {
  const ranges = []
  let currentLine = {
    start: from,
    end: from,
    top: null,
    bottom: null
  }
  
  const startCoords = view.coordsAtPos(from)
  currentLine.top = startCoords.top
  currentLine.bottom = startCoords.bottom

  for (let pos = from + 1; pos <= to; pos++) {
    const coords = view.coordsAtPos(pos)
    
    if (Math.abs(coords.top - currentLine.top) > 2) {
      ranges.push({...currentLine, end: pos - 1})
      
      currentLine = {
        start: pos,
        end: pos,
        top: coords.top,
        bottom: coords.bottom
      }
    } else {
      currentLine.bottom = Math.max(currentLine.bottom, coords.bottom)
    }
  }
  
  if (currentLine.start <= to) {
    ranges.push({...currentLine, end: to})
  }
  
  return ranges
}

function getSelectionType(view, range) {
  // Find the parent transcriptGroup node
  const $pos = view.state.doc.resolve(range.start)
  let transcriptGroup = null
  
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth)
    if (node.type.name === 'transcriptGroup') {
      transcriptGroup = node
      break
    }
  }
  
  if (!transcriptGroup) {
    return 'default'
  }

  const attrs = transcriptGroup.attrs
  
  if (attrs.groupType === 'standard') {
    return 'default'
  }
  if (attrs.groupType === 'webcam-recording') {
    if(attrs.linkedClipId){
      return 'screencastLinked'
    }
    return 'camera'
  }
  if (attrs.groupType === 'variable-webcam') {
    return attrs.hasInstructions ? 'instructions' : 'script'
  }
  
  return 'default'
}

function createLineRects(view, ranges) {
  return ranges.map(range => {
    const startCoords = view.coordsAtPos(range.start)
    const endCoords = view.coordsAtPos(range.end)
    
    const lineStart = {
      left: startCoords.left,
      right: startCoords.right,
      top: range.top,
      bottom: range.bottom
    }
    
    const lineEnd = {
      left: endCoords.left,
      right: endCoords.right,
      top: range.top,
      bottom: range.bottom
    }
    
    const relStart = getRelativePosition(view, lineStart)
    const relEnd = getRelativePosition(view, lineEnd)
    
    const selectionType = getSelectionType(view, range)
    
    return {
      left: relStart.left,
      top: relStart.top,
      width: relEnd.right - relStart.left,
      height: relEnd.bottom - relStart.top,
      selectionType
    }
  })
}

export function selectionStylingPlugin(handleSelectionRectsUpdated) {
  let lastSelection = null
  
  return new Plugin({
    key,
    view(editorView) {
      return {
        update: (view, prevState) => {
          const state = view.state
          const { selection } = state
          
          if (!selection.eq(lastSelection)) {
            lastSelection = selection
            
            if (!selection.empty) {
              try {
                const ranges = findLineRanges(view, selection.from, selection.to)
                const rects = createLineRects(view, ranges)
                handleSelectionRectsUpdated(rects)
              } catch (e) {

                handleSelectionRectsUpdated([])
              }
            } else {
              handleSelectionRectsUpdated([])
            }
          }
        },
        destroy: () => {
          lastSelection = null
          handleSelectionRectsUpdated([])
        }
      }
    }
  })
}
import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import * as Tooltip from '@radix-ui/react-tooltip'
import { TextSelection } from 'prosemirror-state';

import Icon from '../misc/Icon'
import EditorStatusBar from './statusBar/EditorStatusBar'
import EditorTimelineFooter from './timeline/EditorTimelineFooter'
import TranscriptPanel from './leftPanels/transcriptPanel/TranscriptPanel'
import EditorToolbar from './toolbar/EditorToolbar'
import EditorTrimBar from './EditorTrimBar'
import EditorTopBar from './topbar/EditorTopbar'
import EditorDetailPanel from './detailPanel/EditorDetailPanel'
import PMPanel from './rightPanels/PMPanel'

import SlideEditorContextMenu from './SlideEditorContextMenu'
import TemplateBar from './templateBar/TemplateBar'
import UpdatesMajorReleaseBanner from '../changelog/UpdatesMajorReleaseBanner'

import RecordWebcamContainer from '../../containers/RecordWebcamContainer'
import EditorRightPanelContainer from './rightPanels/EditorRightPanelContainer'
import EditorCanvasOuterContainer from './canvas/EditorCanvasOuterContainer'

import ExportManager from '../../ExportManager';
import ScenesTimeline from './timeline/scenes/ScenesTimeline'
import { TimelineProsemirrorManager } from '../../prosemirror/timelineProsemirror/TimelineProsemirrorManager'
import { TranscriptProsemirrorManager } from '../../prosemirror/transcriptProsemirror/TranscriptProsemirrorManager'

import {saveSlideAsTemplate} from '../../actions/slideTemplates'
import {setCopiedClip} from '../../actions/clipActions'
import {updateProjectBackground,updateProjectDefaultMotionStyle,fetchSingleProject,updateProjectSubtitlesType,updateProjectCollabPageSettings,createVoiceMatchForProject,fetchProjectCollabGenerations} from '../../actions/projects'
import {syncNewRecording} from '../../actions/recordings'
import {syncNewScreenRecording, checkScreenRecordingAutoZoom} from '../../actions/screenRecordings'
import {updateProMode} from '../../actions/user'
import {uploadMusicAndHandleResponse} from '../../actions/backgroundMusic'
import {uploadImageAndHandleResponse,updateImageInsertCount,updateImageDefaultDisplayWidth} from '../../actions/imageUpload'
import {uploadVideoAndHandleResponse,updateVideoInsertCount,updateVideoDefaultDisplayWidth} from '../../actions/videoUpload'
import {uploadWebcamRecording,syncRecordingSessionWebcam} from '../../actions/webcamRecording'
import {syncNewRecordingSession} from '../../actions/recordingSession'
import {createTimeline} from '../../timeline/utils/createTimeline'

import {randomID} from '../../utils/randomID'
import {clearClipsData} from '../../utils/recordings/legacyScreenRecordings/getLegacyCursorPositionAtTime'
import {clearScreenClipsData, updateCursorOpacityForClip} from '../../utils/recordings/screenRecordings/getCursorPositionAtTime'
import {maybeSyncRecording} from '../../utils/assets/maybeSyncRecording'
import { timeToPixels } from './timeline/utils/timeToPixels'
import {calculateDropLines} from './timeline/utils/calculateDropLines'
import {generateSRTFile} from '../../utils/generateSRTFile'
import {webcamClipDefaultMetadata} from '../../utils/webcam/webcamConfigs'
import {getOrgDomain} from '../../utils/getOrgDomain'
import {calculateZoomConfigFromBoundingBox, getZoomConfigForModal} from '../../utils/zoom/calculateZoomConfigFromBoundingBox'

import EditorReadOnlyToggle from './EditorReadOnlyToggle'
import { checkUserAccessLock, getLockedUserNameAndAvatar } from '../../utils/checkUserAccessLock';
import { lockProject, openProject } from '../../utils/projectUtils/openProject';
import { setPendingSessionCapture } from '../../actions/editor';

import { calulateVideoTimeFromTimelineTime } from '../../timeline/utils/calulateVideoTimeFromTimelineTime';
import { calculateClickBasedBoundingBoxes, calculateCursorBasedBoundingBox } from '../../utils/zoom/calculateCursorBasedBoundingBox';
import { calculateTimelineTimeFromVideoTime } from '../../timeline/utils/calculateTimelineTimeFromVideoTime';
import { trimZoomBoxes} from '../../utils/recordings/screenRecordings/getAutoZoomData';
import { getScreenRecordingChunksFromFile } from '../../utils/recordings/screenRecordings/getScreenRecordingChunks';
import {saveUploadedAssetToCache} from '../../utils/assets/saveUploadedAssetToCache'
import { getFileExtension } from '../../utils/getFileExtension'
import {syncUserUploadedWebcam} from '../../actions/syncUserUploadedWebcam'
import {getCustomWindowResizeOptions} from '../../utils/brands/getCustomWindowResizeOptions'


const MIN_PIXELS_PER_MS = 0.002
const MAX_PIXELS_PER_MS = 0.40
const MIN_ZOOM = 0
const MAX_ZOOM = 1
const defaultTimelineZoom=0.14
const COMMAND_KEY_DOWN_TIMEOUT = 200
const SCENE_GAP = 34 //px

function createTempImageObject(file) {
  return new Promise((resolve) => {
    const localUrl = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      const tempObject = {
        id: `temp_${Date.now()}`,
        asset_id: `temp_${Date.now()}`,
        containerWidth: img.width,
        created_at: new Date().toISOString(),
        created_by: "local",
        default_display_width: img.width,
        delivery_url: localUrl,
        format: file.type.split('/')[1],
        original_filename: file.name,
        original_height: img.height,
        original_width: img.width,
        public_id: `temp_${file.name}`,
        semi_transparent: false, // You might need more complex logic to determine this
        starred: false,
        status: "pending",
        type: "image",
        upload_failed: false,
        used_at: new Date().toISOString(),
        version: "temp",
        width: img.width
      };
      resolve(tempObject);
    };
    img.src = localUrl;
  });
}


class Editor extends React.Component{  
	
	constructor(props) {
		super(props);
		const pixelsPerMs=this.calculatePixelsPerMs(defaultTimelineZoom)
		this.state = {
			scenes:[],
			clips:[],
			isDnDMode:false,
			currentTime: 0,
			isPlaying: false,
			duration:0,
			timelineZoom:defaultTimelineZoom,
			pixelsPerMs:pixelsPerMs,  
			pixelsPerSec:pixelsPerMs*1000, 			
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			voiceoverKey:0,
			activePanelClip:null,
			projectName:'',
			// temporary zoom prototype
			zoomBox:{
				x: 0,
				y: 0,
				width: 640,
				height: 360,
			},			
			meshWidth:null,
			meshHeight:null,
			isPreviewing:false,
			prePreviewState:{},
			previewClip:null,
			previewType:null,
			trimMode: false,
			trimPreviewPlaying: false,
			trimPreviewProgress: 0,
			activeVoice:'',
			voiceoverPlaybackRate:1,
			backgroundMusicTrack:null,
			backgroundMusicVolume:0,
			isDraggingProgressMarker:false,
			defaultMotionStyle:null,
			testKey:0,
			showPMPanel:true, //dev panel
			pmDoc:{}, //for dev panel
			transcriptPmDoc:{}, //for dev panel

			isDragResizingMedia: false,
			maxTimelineDurationSeconds:120,

			draggingClips:[],
			isDragging:false, 
			dragClipId:null,
			dragClipNewStartTime:null,
			resizingSkipSegmentHandle:null, //null left or right
			isResizingSkipSegment:false,
			preSkipResizeCurrentTime:null,
			transcriptChunksForDragHandles:[],
			transcriptSceneHeaders:[],
			transcriptWebcamHeaders:[],
			hoveredTranscriptChunk:null,
			transcriptChunkWithCursorInside:null,
			timelineCmdIsDown: false,
			previewingAudioClipId:null,
			showShortcutsPopover: false,
			dropLines:{},
			focusedSlideElement:null,
			selectedSlideItems:[], //elements and layout groups
			projectVariables:[],
			showTranscriptPanel: true,
			isDragResizingNumberInput: false,
      slideEditorContextMenuPosition: null,
      slideEditorContextMenuElementId: null,
      slideEditorDragElementId:null,
     	slideEditorResizeElementId:null,
      slideEditorDragLayoutGroupId:null,
      slideElementTextSelectionInfo:null,
      slideEditorDraggingSpacer:null,
      isDraggingToReorder:false,
      isSlideEditorDragResizing:false,
      showReplaceImageMediaModal:false,
      subtitlesType:null,
      isDraggingWebcam: false,
      isResizingWebcam: false,
      webcamRecordModalProps:{isOpen:false},
      collabGenerations:[],
      generationsDialogOpen:false,
      readOnlyMode: null,
      skipMarkers:[],
      transcriptSelectionRects:[],
      aspectRatio:null,
      isGeneratingVoiceMatch:false,
      overrideVoiceMatch:false,
			soundEffectsSettings:null,
			isAddSkipSegmentModeActive:false,
			skipSegmentModeFromShortcut: false,
			addSkipSegmentModeClip: null,
			showWebcamPhasesForClipId: null,
			activeTranscriptPanelAudioChunk:null,
			isVideoResizeModeActive: false,
			preVideoResizeSnapshot:null
		};

		this.editorTimelineRef = React.createRef();
		this.debouncedSaveDisplayWidth = debounce(this.saveDisplayWidth, 1000);
		this.editorRef = React.createRef();
		this.pmManager = new TimelineProsemirrorManager(this.setPMDocForDevMode);
		this.transcriptPmManager = new TranscriptProsemirrorManager(
			this.seekToTimeInWebcamClip,
			this.setTranscriptPMDocForDevMode,
			this.updateTimelineFromTranscript,
			this.playClipFromTranscript,
			this.recalculateAudioClip,
			this.addSceneFromTranscriptPanel,
			this.handleMouseOverTranscriptChunk,
			this.handleMouseLeaveTranscriptChunk,
			this.handleCursorInsideTranscriptChunk,
			this.handleSelectionRectsUpdated,
			this.getTitleForScene,
			this.setActiveTranscriptPanelAudioChunk,
			this.splitRecordingClipFromTranscript,
			this.getSkippedItemsForRange,
			this.maybeMergeRecordingClipFromTranscript
		)
		this.cmdKeyTimer = null
		this.scrollContainerRef = React.createRef();
		this.debouncedUpdateCollabPageSettings = debounce((settings) => {
			this.props.updateProjectCollabPageSettings(this.props.projectId, settings)
		}, 500)

		this.renderCount=0
	}

	componentDidMount(){
		this.setState({ windowWidth: window.innerWidth });
		this.setState({ windowHeight: window.innerHeight });
		window.addEventListener('resize', this.handleWindowResize);
		window.addEventListener('keydown',this.handleKeydown)
		window.addEventListener('keyup', this.handleKeyup);
		if (this.props.project) {
			this.loadProject();
		} else {
			// Set a loading flag to prevent double initialization
			this.isInitializing = true;
			this.props.fetchSingleProject(this.props.projectId)
				.finally(() => {
					this.isInitializing = false;
				});
		}
		if (window.isElectron) {
			window.ipcRenderer.receive("recording-stopped", this.recordingStoppedListener);
		}
		document.addEventListener('mousedown', this.handleClickOutside);
		
		this.collabGenerationsInterval = setInterval(() => {
			this.handleFetchCollabGenerations()
		}, 10000);
		
		this.determineReadOnlyMode(this.props);
		this.timelineUpdateErrorListener = (error) => {
			this.determineReadOnlyMode(this.props);
		};
		document.addEventListener('timeline-update-error', this.timelineUpdateErrorListener);
	}

	componentDidUpdate = async (prevProps) => {
		if (this.props.project && !prevProps.project) {
			this.loadProject();
		}
		// Only check read-only mode if project access actually changed
		if (prevProps.userId !== this.props.userId) {
			this.determineReadOnlyMode(this.props);
		}
		// Only check read-only mode if project access actually changed
		if (prevProps.project?.project_access_user?.current_user_id !== 
				this.props.project?.project_access_user?.current_user_id) {
			this.determineReadOnlyMode(this.props);
		}
		Object.entries(this.props.webcamUploads || {}).forEach(async ([captureId, upload]) => {
			const prevUpload = (prevProps.webcamUploads || {})[captureId];
			if (upload.status === 'complete' && (!prevUpload || prevUpload.status !== 'complete')) {
				const clipId = upload.clipId;
				if (clipId) {
					await this.timeline.updateWebcamWithUploadResponse(clipId,captureId,upload)
					this.updateStateFromTimeline();
					this.manuallyRefreshTranscriptChunksForDragHandles()
					if(this.state.webcamRecordModalProps.isOpen && this.state.webcamRecordModalProps.clipId==clipId ){
						this.closeWebcamRecordModal()
					}
				}
			}
		});
		if (this.props.voiceMatchResult !== prevProps.voiceMatchResult) {
			if (this.props.voiceMatchResult) {
				this.handleVoiceMatchResult(this.props.voiceMatchResult);
			}
		}
		if (this.props.pendingSessionCapture && 
			(!prevProps.pendingSessionCapture || this.props.pendingSessionCapture.sessionCaptureId !== prevProps.pendingSessionCapture.sessionCaptureId) &&
			this.props.pendingSessionCapture.projectId === this.props.projectId) {
			const sessionCaptureId = this.props.pendingSessionCapture.sessionCaptureId
			this.handleImportSessionCaptureId(sessionCaptureId)
			this.props.setPendingSessionCapture(null,null)
			openProject(this.props.projectId,this.props.history)
		}
		
		// Check for file updates
		const prevFileStatuses = prevProps.webcamFileStatuses || {};
		const currentFileStatuses = this.props.webcamFileStatuses || {};
		if (currentFileStatuses !== prevFileStatuses) {
			this.timeline.clips.forEach(clip => {
				if (clip.type === 'webcam') {
					clip.checkForFileUpdates(currentFileStatuses);
					this.manuallyRefreshTranscriptChunksForDragHandles()
				}
			});
		}
	}

	determineReadOnlyMode(props) {
		try {
			const isLocked = checkUserAccessLock(props.project);
			// Only attempt to take control if:
			// 1. Project isn't locked
			// 2. We're currently in read-only mode or haven't set mode yet
			// 3. We haven't already started a refresh cycle
			if (!isLocked && 
				(this.state.readOnlyMode || this.state.readOnlyMode == null) && 
				!this.isRefreshing) {
				this.isRefreshing = true; // Set flag to prevent multiple refreshes
				
				this.refreshProject()
					.then(() => {
						return lockProject(this.props.projectId);
					})
					.catch(error => {
						console.error('Error taking control:', error);
						this.setState({ readOnlyMode: true });
					})
					.finally(() => {
						this.isRefreshing = false; // Clear flag when done
					});
			}

			// Only update state if lock status actually changed
			if (isLocked !== this.state.readOnlyMode) {
				this.setState({ readOnlyMode: isLocked });
			}
			
			return isLocked;
		} catch (error) {
			console.error('Error checking lock status:', error);
			this.setState({ readOnlyMode: true });
			return true;
		}
	}

	refreshProject = () => {
		const oldProject = this.props.project; 
		return this.props.fetchSingleProject(this.props.projectId)
			.then(newProject => { 
				if (!this.projectsAreEqual(oldProject, newProject)) {
				setTimeout(() => {
					this.loadProject();  
				}, 50); 
			} else {
				// console.log('Skipping loadProject() because data is unchanged.');
			}
		})
		.catch(error => {
			console.error('Error refreshing project:', error);
			throw error; 
		});
	}

	projectsAreEqual(oldProject, newProject) {
		if (!oldProject || !newProject) return false;
		return oldProject.updated_at === newProject.updated_at;
	}


	onTakeControl = () => {
		this.refreshProject()
			.then(() => {
				lockProject(this.props.projectId);
			})
			.catch(error => {
				console.error('Error taking control:', error);
				this.setState({ readOnlyMode: true });
			});
	}

	handleFetchCollabGenerations=()=>{//only do it for clay/yarn.so
		if(getOrgDomain()=='clay.com' || getOrgDomain()=='yarn.so'){
			if (this.props.projectId) {
				this.props.fetchProjectCollabGenerations(this.props.projectId)
				.then((generations) => {
					this.setState({ collabGenerations: generations });
				});
			}
		}
	}

	handleVoiceMatchResult = (result) => {
		this.timeline.applyVoiceMatchResult(result)
		this.updateStateFromTimeline()
		this.setState({isGeneratingVoiceMatch:false})
	}

	componentWillUnmount() {
		clearClipsData()
		clearScreenClipsData()
		window.removeEventListener('resize', this.handleWindowResize);
		window.removeEventListener('keydown', this.handleKeydown);
		window.removeEventListener('keyup', this.handleKeyup);
		if(this.timeline){
			this.timeline.destroy()
			this.timeline = null
		}
		if (window.isElectron) {
			window.ipcRenderer.removeListener("recording-stopped", this.recordingStoppedListener);
		}
		if (this.cmdKeyTimer) {
      clearTimeout(this.cmdKeyTimer);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
		if (this.collabGenerationsInterval) {
			clearInterval(this.collabGenerationsInterval);
		}
		document.removeEventListener('timeline-update-error', this.timelineUpdateErrorListener);
	}

	loadProject=()=>{
		const projectId = this.props.projectId
		this.setState({collabGenerations:[]}) //clear this
		this.handleFetchCollabGenerations()
		const project = this.props.project 
		const timeline = this.props.project.timeline
		createTimeline(projectId,timeline, this.onTimeUpdate,this.handlePlaybackEnded,this.handleClipMediaLoaded,this.setPMDocForDevMode,project.background,this.handleUpdateProjectBackground,this.pmManager,this.transcriptPmManager,this.handleVoiceoverAudioFileUpdated,this.setPreviewingAudioClipId,this.handleTextElementFontLoaded,this.handleCreateVoiceMatchForProject)
			.then(timeline => {
				this.timeline = timeline;
				//this.checkAssetsAreSynced(timeline.clips)
				this.updateStateFromTimelineWithTranscriptRefresh()
				setTimeout(() => {
					this.setState({testKey:this.state.testKey+1})
					this.setActivePanelClipFromCurrentTime()		
				}, 200)

			this.setState({
				defaultMotionStyle:project.default_motion_style,
				projectName: project.name,
				projectBackgroundId:project.background ,
				subtitlesType:project.subtitles_type
			})
			this.manuallyRefreshTranscriptChunksForDragHandles()
			if (this.props.pendingSessionCapture && 
				this.props.pendingSessionCapture.projectId === this.props.projectId) {
				const sessionCaptureId = this.props.pendingSessionCapture.sessionCaptureId
				this.handleImportSessionCaptureId(sessionCaptureId)
				this.props.setPendingSessionCapture(null,null)
			}
		})
		.catch(error => {
			console.error('Error in creating timeline:', error);
		});
	}

	checkAssetsAreSynced=(clips)=>{ //TODO basic videos
		clips.forEach((clip)=>{
			if(clip.type=='video'){
				if(clip.isScreenRecording){
					maybeSyncRecording(clip.captureId,clip.isDevice,clip.isScreenRecording)
				}
			}
		})
	}

	handleCreateVoiceMatchForProject=(captureIds)=>{
		if(captureIds.length){
			//if empty (you've deleted the webcam then dont set it to isGenerating--todo handle when have a capture id but the audio is empty -->invalid audio source --> no voice match) still send the createVoiceMatchForProject request if empty so it will delete the voice from eleven labs
			this.setState({isGeneratingVoiceMatch:true})
		}else{
			this.setState({isGeneratingVoiceMatch:false})
		}
		this.props.createVoiceMatchForProject(this.props.projectId,captureIds)
	}


	handleKeydown=(e)=>{
		if ((e.metaKey || e.ctrlKey) && e.key === 'v') {
			this.maybePasteClip()
		}
		if ((e.metaKey || e.ctrlKey) && e.key === 'c' && !e.shiftKey) {
			this.maybeCopyClip()
		}
		// Skip segment mode keyboard handling is now in EditorTimelineFooter component
		if(e.metaKey && e.shiftKey && e.key=="\\"){
			this.props.updateProMode(!this.props.userIsProMode)
		}
		if (e.metaKey && e.key === 'z' && !e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			const prevActiveClip = this.state.activePanelClip;
			const prevTime = this.state.currentTime;
			let transcriptHadFocusBeforeUndo = false

			if(this.transcriptPmManager && this.transcriptPmManager.view && this.transcriptPmManager.view.hasFocus()){ 
				transcriptHadFocusBeforeUndo = true
			}

			this.timeline.undo();

			this.updateStateFromTimeline().then(() => {
				if (prevActiveClip) {
					const updatedClip = this.getClipForId(prevActiveClip.id);
					const isClipStillValid = updatedClip && 
					updatedClip.startTime <= prevTime && 
					prevTime < (updatedClip.startTime + updatedClip.duration);
					if (!isClipStillValid) {
						this.handleDeactivateActiveClip();
					}else{
						this.setState({activePanelClip:updatedClip})
					}
				}
					this.manuallyRefreshTranscriptChunksForDragHandles();
					this.setState({ textSlideEditorKey: this.state.textSlideEditorKey + 1 });

					if(transcriptHadFocusBeforeUndo){
						this.transcriptPmManager.view.focus()
					}
				});
			}
		if (e.metaKey && e.key === 'c' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.splitScene()
		}
		if (e.metaKey && e.key === 'h' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(-30)
		}
		if (e.metaKey && e.key === 'l' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(30)
		}
		if (e.metaKey && e.key === 'c' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.splitScene()
		}
		if (e.metaKey && e.key === 'h' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(-30)
		}
			if (e.metaKey && e.key === 'l' && e.shiftKey) {
			e.stopPropagation()
			e.preventDefault()
			this.seekFromShortcut(30)
		}
		//REDO
		if (e.metaKey && e.shiftKey && e.key === 'z') {
			e.stopPropagation()
			e.preventDefault()
			const prevActiveClip = this.state.activePanelClip;
			const prevTime = this.state.currentTime;
			this.timeline.redo();

			this.updateStateFromTimeline().then(() => {
				if (prevActiveClip) {
					const updatedClip = this.getClipForId(prevActiveClip.id);
					const isClipStillValid = updatedClip && 
					updatedClip.startTime <= prevTime && 
					prevTime < (updatedClip.startTime + updatedClip.duration);
					if (!isClipStillValid) {
						this.handleDeactivateActiveClip();
					}else{
						this.setState({activePanelClip:updatedClip})
					}
				}
				this.manuallyRefreshTranscriptChunksForDragHandles();
				this.setState({ textSlideEditorKey: this.state.textSlideEditorKey + 1 });
			});
		}
		else if (e.metaKey && e.key === 'j' && e.shiftKey){		 	
	 		this.seekFromShortcut(-2)		 	
    }
  	else if (e.metaKey && e.key === 'k' && e.shiftKey) {	
  		this.seekFromShortcut(2)	 	
  	}    
		else if (e.key === 'Backspace') {
			if(this.transcriptPmManager && this.transcriptPmManager.view && this.transcriptPmManager.view.hasFocus()){
				// console.log('in the transcript panel')
			}else{
				var focusedElement = document.activeElement;
				if(focusedElement.tagName !=='INPUT' && focusedElement.tagName !== 'TEXTAREA' && !focusedElement.className.includes('ProseMirror') && !focusedElement.className.includes('rightPanel') && !focusedElement.className.includes('spreadsheet') ){
					this.handleDeleteAction();
				}
			}
		}
		if (e.code === 'Space') {
			if(!this.state.generationsDialogOpen){
        var focusedElement = document.activeElement;
        if (focusedElement.tagName !== 'INPUT' && 
        		focusedElement.tagName !== 'TEXTAREA' && 
            !focusedElement.className.includes('ProseMirror') && 
            !focusedElement.className.includes('rightPanel') && 
            !focusedElement.className.includes('spreadsheet')) {
            e.preventDefault(); // Prevent page scroll
            this.togglePlayPause();
        }
      }
    }
		if (e.key === 'Meta' || e.key === 'Control') {
				if (this.cmdKeyTimer) {
					clearTimeout(this.cmdKeyTimer);
				}
				this.cmdKeyTimer = setTimeout(() => {
					if(this.transcriptPmManager && this.transcriptPmManager.view){
						if (!this.transcriptPmManager.view.hasFocus()) {
						this.setState({ timelineCmdIsDown: true });
					}
					}				
				}, COMMAND_KEY_DOWN_TIMEOUT)
  	} else {
			if (this.cmdKeyTimer) {
				clearTimeout(this.cmdKeyTimer);
			}
    	this.setState({ timelineCmdIsDown: false });
  	}
	}

	seekFromShortcut=(seekAmount)=>{ //seeking from keyboard shortcut
		const newTime = this.state.currentTime + seekAmount
		this.handleSeek(newTime)
		//scroll into view
		const newPixelPosition = timeToPixels(newTime, this.state.pixelsPerSec, this.state.scenes, SCENE_GAP);
		const scrollableDiv = document.getElementById('timeline-scroll-div')		
		if(scrollableDiv){
			const leftEdgePixels = scrollableDiv.scrollLeft
			const rightEdgePixels = window.innerWidth + scrollableDiv.scrollLeft
			if(newPixelPosition < leftEdgePixels){
				scrollableDiv.scrollLeft = newPixelPosition - (window.innerWidth * 0.4);		
			}	
			if(newPixelPosition > rightEdgePixels){
				scrollableDiv.scrollLeft = newPixelPosition - window.innerWidth + (window.innerWidth * 0.4);		
			}	
		}
	}

	handleKeyup = (e) => {
  if (e.key === 'Meta' || e.key === 'Control') {
  	if (this.cmdKeyTimer) {
      clearTimeout(this.cmdKeyTimer);
    }
    this.setState({ timelineCmdIsDown: false });
  }
};

	handleDeleteAction=()=>{//check if there is an active clip and if it is video or zoom delete it
		const {activePanelClip} = this.state 
		if(activePanelClip && (activePanelClip.type=='zoom' || activePanelClip.type=='video' || activePanelClip.type=='chart' || activePanelClip.type=='image'|| activePanelClip.type=='textSlide'|| activePanelClip.type=='webcam')) {
			this.deleteClip(activePanelClip.id)
		}
		else if(activePanelClip && activePanelClip.type=='slide'){
			if(this.state.selectedSlideItems.length==0 && !this.state.focusedElement){
				this.deleteClip(activePanelClip.id)
			}
			else if(this.state.selectedSlideItems.length){
				this.deleteSlideItems()
			}
		}
	}

	recordingStoppedListener = (captureId)=>{
		this.addDeviceRecordingToProject(captureId)
		//TODO refector syncNewRecording to only do device recordings
		this.props.syncNewRecording(captureId,true)
	}

	handleUpdateDefaultMotionStyle=(style)=>{
		this.setState({defaultMotionStyle:style})
		this.timeline.updateProjectDefaultMotionStyle(style)
		this.props.updateProjectDefaultMotionStyle(this.props.projectId,style)
	}

  handleUpdateProjectBackground = (value,isUndo) =>{
  	this.props.updateProjectBackground(this.props.projectId,value)
  	this.setState({projectBackgroundId:value})
  	if(!isUndo==true){
  		this.timeline.updateProjectBackground(value)
  	}
  }	

	////// Timeline playback
	togglePlayPause=()=>{
		if(this.state.isPlaying){
			this.pauseTimeline()
		}else{
			this.playTimeline()
		}
	}

	playTimeline=()=>{		
		this.timeline.play()
		this.handleDeactivateActiveClip()
		this.setState({isPlaying:true})

	}

	pauseTimeline=()=>{
		this.timeline.pause()
		this.setState({isPlaying:false})
		this.setActivePanelClipFromCurrentTime()	
	}

	onTimeUpdate=(currentTime)=>{
		this.setState({currentTime:currentTime},()=>{
			if(!this.state.isPlaying){
				this.setActivePanelClipFromCurrentTime()
			}
		})
		const {isPreviewing} = this.state
		if(isPreviewing){
			const {previewClip} = this.state
			if(currentTime>=previewClip.startTime+previewClip.startTranstionDuration){
				this.resetPreview()
			}
		}
	}

	handleSeek=(newTime)=>{
		if (this.state.slideEditorContextMenuElementId) {
			this.closeSlideEditorContextMenu()
		}
		this.clearActiveWebcamPhasesClipId()
		this.timeline.seek(newTime)
		this.updateStateFromTimeline()
	}

	handleSeekWithPause=(newTime)=>{
		if(this.state.isPlaying){
			this.pauseTimeline()
		}
		this.timeline.seek(newTime)
	}

	handlePlaybackEnded=()=>{
		setTimeout(() => { //hacky set timeout for when start playing at time>duration
   			this.pauseTimeline()
		}, 10)
	}

	///// Timeline zoom 
	handleTimelineZoomChange = (value) => {
	  const scrollContainer = this.scrollContainerRef.current;
	  if (scrollContainer) {
	    const containerWidth = scrollContainer.clientWidth;
	    const scrollLeft = scrollContainer.scrollLeft;
	    const currentScrollWidth = scrollContainer.scrollWidth;
	    // Use high-precision calculations
	    const markerPosition = (this.state.currentTime / this.state.duration) * currentScrollWidth;
	    const visibleMarkerRatio = (markerPosition - scrollLeft) / containerWidth;
	    
	    const newPixelsPerMs = this.calculatePixelsPerMs(value);
	    this.setState({ 
	      timelineZoom: value, 
	      pixelsPerMs: newPixelsPerMs, 
	      pixelsPerSec: newPixelsPerMs * 1000 
	    }, () => {
	      // Recalculate with new dimensions
	      const newScrollWidth = scrollContainer.scrollWidth;
	      const newMarkerPosition = (this.state.currentTime / this.state.duration) * newScrollWidth;
	      
	      // Calculate new scroll position
	      const newScrollLeft = newMarkerPosition - (visibleMarkerRatio * containerWidth);
	      
	      // Apply the new scroll position, rounding to the nearest integer
	      scrollContainer.scrollLeft = Math.round(newScrollLeft);
	      
	      // Fine-tune the position if needed
	      requestAnimationFrame(() => {
	        const actualMarkerPosition = (this.state.currentTime / this.state.duration) * scrollContainer.scrollWidth;
	        const actualVisiblePosition = actualMarkerPosition - scrollContainer.scrollLeft;
	        const targetVisiblePosition = visibleMarkerRatio * containerWidth;
	        
	        if (Math.abs(actualVisiblePosition - targetVisiblePosition) > 1) {
	          scrollContainer.scrollLeft += (actualVisiblePosition - targetVisiblePosition);
	        }
	      });
	    });
	  } else {
	    const newPixelsPerMs = this.calculatePixelsPerMs(value);
	    this.setState({ 
	      timelineZoom: value, 
	      pixelsPerMs: newPixelsPerMs, 
	      pixelsPerSec: newPixelsPerMs * 1000 
	    });
	  }
	};

	calculatePixelsPerMs = (timelineZoom) => {
		const pixelsPerMs=(MIN_PIXELS_PER_MS+((MAX_PIXELS_PER_MS-MIN_PIXELS_PER_MS)*timelineZoom))
		return pixelsPerMs
	}

	handleWindowResize = () => {
		this.setState({ windowWidth: window.innerWidth,windowHeight: window.innerHeight  });
	}

	handleTimelineZoomToFit = () => {
	  const availableWidth = this.state.windowWidth - 100;
	  const sceneGapsWidth = (this.state.scenes.length - 1) * SCENE_GAP;
	  const contentDurationMs = this.state.duration * 1000;
	  
	  // Calculate exactly what we need
	  const neededPixelsPerMs = (availableWidth - sceneGapsWidth) / contentDurationMs;
	  
	  // Set this directly instead of using zoom level
	  this.setState({ 
	    pixelsPerMs: neededPixelsPerMs,
	    pixelsPerSec: neededPixelsPerMs * 1000,
	    timelineZoom: 0 // Set to minimum zoom level for UI consistency
	  });
	}

	/////// active clip (for detail panel and stuff)
	handleDeactivateActiveClip = () => {
		this.setState({activePanelClip:null})
	}

	handleSetActivePanelClip = (clip) =>{
		//defocus the transcript panel so doesnt intercept backspace handler
		this.setFocusToEditor()
		if(clip && this.state.activePanelClip && this.state.activePanelClip.id==clip.id){
		}else{
			this.setState({activePanelClip:clip})
			if(clip && clip.type!=='slide'){
				this.setState({selectedSlideItems:[]})
			}
		}	
	}

	setActivePanelClipFromCurrentTime=()=>{ //when stop playback set the active clip
		if(this.state.isDraggingProgressMarker || this.state.isAddSkipSegmentModeActive){
			return
		}
		let clipsAtPlayhead=[]
		const {clips,currentTime,activePanelClip} = this.state 
		clips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime <= (clip.startTime + clip.duration)){
				clipsAtPlayhead.push(clip)
			}
		})
		//This bit is so if you click a clip and it seeks it doesnt override making that clip active
		const isActiveClipCurrent = activePanelClip &&
			currentTime >= activePanelClip.startTime &&
			currentTime < (activePanelClip.startTime + activePanelClip.duration)

		if (!isActiveClipCurrent && clipsAtPlayhead.length > 0) {
        // Find the clip with the highest z-index
        let highestZIndexClip = clipsAtPlayhead.reduce((maxClip, clip) => {
            return (maxClip.zIndex > clip.zIndex) ? maxClip : clip;
        });
        this.handleSetActivePanelClip(highestZIndexClip)
    } else {
    	if(clipsAtPlayhead.length==0){
    		this.handleDeactivateActiveClip()
    	}
    }
	}

	////// update slide animation settings
	updateSlideClipTextStyle=(clipId,textStyle)=>{ 
		this.timeline.updateSlideClipTextStyle(clipId, textStyle)
		this.updateStateFromTimeline()
	}

	updateClipMetadata=(clipId,settings)=>{ 
		this.timeline.updateClipMetadata(clipId, settings)
		this.updateStateFromTimeline()
	}

	updateClipMetadataNoUndo=(clipId,settings)=>{ 
		this.timeline.updateNodeMetadataSilent(clipId, settings)
		this.updateStateFromTimeline()
	}

	//isPreview so dont update timeline with min clip duration if just previewing
	updateClipAnimationSettings=(clipId,settings,isPreview)=>{ //TODO
		this.timeline.updateClipAnimationSettings(clipId, settings,isPreview)
		this.updateStateFromTimeline()
	}

	//////////// Drag to move clip
	updateClipStartTime = (clipId,newStartTime) =>{
		this.timeline.updateClipStartTime(clipId, newStartTime)
		this.updateStateFromTimeline()
	}

	updateMultipleClipsStartTime = (updatedClips) =>{
		this.timeline.updateMultipleClipStartTimes(updatedClips)
		this.updateStateFromTimeline()
	}

  updateClipStartTimeAndDuration = (clipId, newStartTime, newDuration) => {    
    this.timeline.updateClipStartTime(clipId, newStartTime);    
    this.timeline.updateClipDuration(clipId, newDuration);    
    this.updateStateFromTimeline();
  };

	updateClipDuration = (clipId,newDuration) =>{
		this.timeline.updateClipDuration(clipId, newDuration)
		this.updateStateFromTimeline()
	}

	 setFocusToEditor = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

  ///Legacy text slide stuff
	updateTextSlideTextColor = (clipId,textColorId)=>{
		this.timeline.setTextSlideTextColor(clipId,textColorId)
		this.updateStateFromTimeline()
	}

	updateTextSlideBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}


/////ZOOOMS 
	addZoom=async()=>{
		const {currentTime,clips}=this.state 
		let parentClip=null 
		let parentClipIsDeviceRecording = false
		let nearestZoomStartTime = Infinity;
		let activeZoomAtPlayhead = false;
		let activeZoomClip = null;
		let zoomBox = null;
		let zoomConfig = null;

		await Promise.all(clips.map(async (clip)=>{
			if(clip.type=='video' && clip.startTime<=currentTime && currentTime<=clip.endTime){
				parentClip=clip.id
				if(clip.isDeviceRecording){
					parentClipIsDeviceRecording=true
				}
				zoomBox = await calculateCursorBasedBoundingBox(clip, calulateVideoTimeFromTimelineTime(currentTime,clip))
				if (zoomBox) {
					zoomBox.startTime = calculateTimelineTimeFromVideoTime(zoomBox.startTime,clip);
					zoomConfig = await calculateZoomConfigFromBoundingBox(zoomBox.boundingBox,clip);
				}
			}
			if (clip.type == 'zoom' && clip.startTime > currentTime) {
				nearestZoomStartTime = Math.min(nearestZoomStartTime, clip.startTime);
			}
			if (clip.type == 'zoom' && currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)) {
				activeZoomAtPlayhead = true;
				activeZoomClip = clip;
			}
		}))
		let newDuration
		//If there is an active zoom lets shorten it and then add a new one at current time
		const DEFAULT_ZOOM_DURATION= 3
		const MIN_ZOOM_DURATION=0.5
		if(newDuration>0 || !activeZoomClip){
			let duration = zoomBox?.duration || DEFAULT_ZOOM_DURATION;
			if (nearestZoomStartTime - currentTime < DEFAULT_ZOOM_DURATION) {
				duration = nearestZoomStartTime - currentTime;
			}
			if(duration>MIN_ZOOM_DURATION){ //prevent super tiny zooms
				const newClip = {
					id:randomID(),
					type:"zoom",
					startTime:zoomBox?.startTime||this.state.currentTime,
					duration:duration,
					metadata:{      	
						zoomValues: zoomConfig?.zoomValues|| {
							originX: 0, //originX * VIDEO_WIDTH,  
							originY: 0, //originY * VIDEO_HEIGHT, 
							scale: 0.8,  // Keep original scale
							motionSettings: 'zoomSmooth',
							endMotionSettings:null
						},
						zoomBox: zoomConfig?.zoomBox || {
							x: 0,
							y: 0,
							width: 1920 * 0.8,
							height: 1080 * 0.8,
						},      	
						parentClip: parentClip,
						parentClipIsDeviceRecording:parentClipIsDeviceRecording
					},
					zIndex:2
				}
				this.timeline.addClip(newClip)
				const seekAmount = Math.min(duration/2,1.5)
				this.handleSeek(currentTime + seekAmount)

				this.updateStateFromTimeline().then(() => {
					const createdClip = this.getClipForId(newClip.id);
					this.handleSetActivePanelClip(createdClip);
				});
			}
		}
	}

	createAutoZooms=async(type)=>{
		const {currentTime,clips}=this.state 
		clips.forEach(async (clip)=>{
			if(clip.isScreenRecording && clip.startTime<=currentTime && currentTime<=clip.endTime){
				if(clip.captureId){
					if (type === "click") {
						this.createClickBasedZooms(clip)
					} else if (type === "modal") {
						this.createModalBasedZooms(clip)
					}
				}
			}
		})
	}

	createClickBasedZooms = async (clip) => {
		let zoomBoxes = await calculateClickBasedBoundingBoxes(clip)
		zoomBoxes = trimZoomBoxes(clip, zoomBoxes)
		this.pmManager.startAutoZoomAction();
		for (const zoomBox of zoomBoxes) {
			await this.createAutoZoom(zoomBox, clip)
		}
		this.pmManager.endAutoZoomAction();
	}

	createModalBasedZooms = async (clip) => {
		let zoomBoxes = this.findAutoZoomsForScreenRecordingClip(clip)
		if (!zoomBoxes || zoomBoxes.length === 0) {
			console.log('No zoom boxes')
			return
		}
		try {
			this.pmManager.startAutoZoomAction();
			zoomBoxes = trimZoomBoxes(clip, zoomBoxes)
			if (!zoomBoxes || zoomBoxes.length === 0) {
				console.log('No zoom boxes after trimming')
				return
			}
			console.log('zoomBoxes', zoomBoxes);
			for (const zoomBox of zoomBoxes) {
				if (zoomBox.duration > 1) {
					await this.createAutoZoom(zoomBox, clip)
				}
			}
			// wait for the last zoom to finish before ending the action
			await new Promise(resolve => setTimeout(resolve, 100)).then(() => {
				this.pmManager.endAutoZoomAction();
			});
		} catch (error) {
			console.log('Error processing modal-based zooms:', error)
		}
	}

	updateZoomValues = (clipId,zoomValues)=>{
		this.timeline.updateClipMetadata(clipId, {zoomValues:zoomValues})		
		this.updateStateFromTimeline()
	}

	updateZoomBox = (clipId,zoomBox)=>{
		this.timeline.updateClipMetadata(clipId, {zoomBox:zoomBox})		
		this.updateStateFromTimeline()
	}


	createAutoZoom = async (zoomBox, clip) => {
		const {clips}=this.state 
		const currentTime = calculateTimelineTimeFromVideoTime(zoomBox.startTime, clip)
		let parentClip=null 
		let parentClipIsDeviceRecording = false
		let nearestZoomStartTime = Infinity;
		let activeZoomAtPlayhead = false;
		let activeZoomClip = null;

		clips.forEach((clip)=>{
			if(clip.type=='video' && clip.startTime<=currentTime && currentTime<=clip.endTime){
				parentClip=clip.id
				if(clip.isDeviceRecording){
					parentClipIsDeviceRecording=true
				}
			}
			if (clip.type == 'zoom' && clip.startTime > currentTime) {
				nearestZoomStartTime = Math.min(nearestZoomStartTime, clip.startTime);
			}
			if (clip.type == 'zoom' && currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)) {
				activeZoomAtPlayhead = true;
				activeZoomClip = clip;
			}
		})

		const MIN_ZOOM_DURATION=0.5
		if(!activeZoomClip){
			let duration = zoomBox.duration
			if (nearestZoomStartTime - currentTime < duration) {
				duration = nearestZoomStartTime - currentTime;
			}
			if(duration>MIN_ZOOM_DURATION){ //prevent super tiny zooms
				
				const isModal = zoomBox.eventType==="modal"
				const zoomConfig = isModal ? getZoomConfigForModal() : await calculateZoomConfigFromBoundingBox(zoomBox.boundingBox,clip)
				// console.log('zoomConfig',zoomConfig)

				const newClip = {
					id: randomID(),
					type: "zoom",
					startTime: currentTime,
					duration: duration,
					metadata: {      	
						zoomValues:zoomConfig.zoomValues,
						zoomBox:zoomConfig.zoomBox,      	
						parentClip: parentClip,
						parentClipIsDeviceRecording: parentClipIsDeviceRecording,
						isAutoZoom:true
					},
					zIndex: 2
				}
				this.timeline.addClip(newClip)
				// const seekAmount = Math.min(duration/2,1.5)
				// this.handleSeek(currentTime + seekAmount)

				this.updateStateFromTimeline().then(() => {
					const createdClip = this.getClipForId(newClip.id);
					this.handleSetActivePanelClip(createdClip);
				});
			}
		}
	}

	findAutoZoomsForScreenRecordingClip=(clip)=>{
		if (!clip?.isScreenRecording || !clip?.captureId) return null
		const screenRecording = this.props.screenRecordings.find(screenRecording => screenRecording.capture_id === clip.captureId.toString())
		return screenRecording?.ai_zoom_boxes
	}

	aiZoomStatus=(clip)=>{
		const zooms = this.findAutoZoomsForScreenRecordingClip(clip)
		if (!zooms) return 'pending'
		if (zooms.length === 0) return 'empty'
		return 'exists'
	}

	cursorZoomStatus=(clip)=>{
		return 'exists'
	}

////// Preview aniation -OLD for previewing text slide animations we probably want to add someting like this for the new slides
	previewAnimation=(clip,animationType)=>{
		let prePreviewState={} //so can return to previewstate
		prePreviewState.currentTime=this.state.currentTime
		prePreviewState.startTransitionType=clip.metadata.startTransitionType
		this.setState({
			isPreviewing:true,
			previewClip:clip,
			previewType:animationType,
			prePreviewState:prePreviewState
		})
		const isPreview=true
		this.updateClipAnimationSettings(clip.id,{startTransitionType:animationType},isPreview)
		this.handleSeek(clip.startTime)
		this.togglePlayPause()
	}

	resetPreview=()=>{
		this.togglePlayPause()
		const {prePreviewState,previewClip}=this.state 
		this.handleSeek(previewClip.startTime)
		const isPreview=true
		this.updateClipAnimationSettings(previewClip.id,{startTransitionType:prePreviewState.startTransitionType},isPreview)
		this.setState({isPreviewing:false,previewClip:null,previewType:null,prePreviewState:null})
	}

	//update mesh width/hegight
	updateMeshWidth=(clipId,width)=>{
		this.setState({meshWidth: width})
		this.updateClipMetadataNoUndo(clipId,{meshWidth:width})
	}
	updateMeshHeight=(clipId,height)=>{
		this.setState({meshHeight: height})
		this.updateClipMetadataNoUndo(clipId,{meshHeight:height})
	}

	getClipForId=(clipId)=>{
		const {clips} = this.state 
		const clip=find(clips, clip => String(clip.id) === String(clipId))
		return clip
	}
	updateChartBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	updateChartClip=(clipId,metadata)=>{
		this.timeline.updateChartClip(clipId,metadata)
		this.updateStateFromTimeline()
	}

	setIsDraggingProgressMarker = (isDragging) => {
    if (isDragging) {
        this.setState({isDraggingProgressMarker: true,activePanelClip:null})
    } else {
        this.setState({isDraggingProgressMarker: false}, () => {
            this.setActivePanelClipFromCurrentTime()
        })
    }
}

	removeFreeze=(clipId,segmentId)=>{
		this.timeline.removeFreeze(clipId,segmentId)
		this.updateStateFromTimeline()
	}

	removeSkip=(clipId,segmentId)=>{
		this.timeline.removeSkip(clipId,segmentId)
		this.updateStateFromTimeline()
	}

	updateClipBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	updateClipDisplayWidth=(clipId,displayWidth)=>{
		this.timeline.updateClipMetadata(clipId, {displayWidth:displayWidth})		
		this.updateStateFromTimeline()
		this.debouncedSaveDisplayWidth(clipId,displayWidth)
	}

	saveDisplayWidth=(clipId,displayWidth)=>{
		const clip =this.getClipForId(clipId)
		if(clip.type=='image'){
			this.props.updateImageDefaultDisplayWidth(clip.imageId,displayWidth)
		}else if(clip.type=='video'){
			this.props.updateVideoDefaultDisplayWidth(clip.videoId,displayWidth)
		}
	}

	updateStateFromTimeline = () => {
		return new Promise((resolve, reject) => {
			if (this.timeline) { // Check for initial load
				this.setState({
					duration: this.timeline.duration,
					currentTime: this.timeline.currentTime,
					clips: this.timeline.clips,
					activeVoice: this.timeline.activeVoice,
					voiceoverPlaybackRate: this.timeline.voiceoverPlaybackRate,
					backgroundMusicTrack: this.timeline.backgroundMusicTrack,
					backgroundMusicVolume: this.timeline.backgroundMusicVolume,
					maxTimelineDurationSeconds: this.timeline.maxTimelineDurationSeconds,
					scenes: this.timeline.scenes,
					isDragging: this.timeline.isDragging,
					dragClipId: this.timeline.dragClipId,
					isDnDMode: this.timeline.isDnDMode,
					dragClipNewStartTime:this.timeline.dragClipNewStartTime,
					projectVariables:this.timeline.variables,
					showCaptions:this.timeline.showCaptions,
					hideInactiveCursor:this.timeline.hideInactiveCursor,
					soundEffectsSettings:this.timeline.soundEffectsSettings,
					voiceMatch:this.timeline.voiceMatch,
					overrideVoiceMatch:this.timeline.overrideVoiceMatch,
					aspectRatio:this.timeline.aspectRatio,
					// isWebcamMode:this.timeline.isWebcamMode
					videoWindowPadding:this.timeline.videoWindowPadding
				}, resolve); // resolve the promise when setState completes
			} else {
			//reject(new Error("Timeline is undefined")); // Reject the promise if there is no timeline
			}
		});
	}

	updateStateFromTimelineWithTranscriptRefresh = () =>{
		this.setState({
			duration:this.timeline.duration,
			currentTime:this.timeline.currentTime,
			clips:this.timeline.clips,
			activeVoice:this.timeline.activeVoice,
			voiceoverPlaybackRate:this.timeline.voiceoverPlaybackRate,
			backgroundMusicTrack:this.timeline.backgroundMusicTrack,
			backgroundMusicVolume:this.timeline.backgroundMusicVolume,
			maxTimelineDurationSeconds:this.timeline.maxTimelineDurationSeconds,
			voiceoverKey:this.state.voiceoverKey+1, //lets take this out for now not sure we need it
			scenes:this.timeline.scenes,
			isDragging:this.timeline.isDragging, 
			dragClipId:this.timeline.dragClipId,
			isDnDMode:this.timeline.isDnDMode,
			dragClipNewStartTime:this.timeline.dragClipNewStartTime,
			projectVariables:this.timeline.variables,
			showCaptions:this.timeline.showCaptions,
			hideInactiveCursor:this.timeline.hideInactiveCursor,
			soundEffectsSettings:this.timeline.soundEffectsSettings,
			voiceMatch:this.timeline.voiceMatch,
			overrideVoiceMatch:this.timeline.overrideVoiceMatch,
			aspectRatio:this.timeline.aspectRatio
		})
	}

	refreshProjectVariables=()=>{
		this.timeline.calculateUniqueVariables()
		this.updateStateFromTimeline()
	}

///Timeline clip drag etc
	onDragStart=(clip,isDnDMode,isDragPullMode,isAltDragMode)=>{
		this.timeline.onDragStart(clip,isDnDMode,isDragPullMode,isAltDragMode)
		this.updateStateFromTimeline()
	}

	onDragEnd=(dragClip,dropTime,activeDropType)=>{
		this.timeline.onDragEnd(dragClip,dropTime,activeDropType)
		this.updateStateFromTimeline()
		setTimeout(() => {
		//check if should deactivate the active panel clip
		const {activePanelClip} = this.state 
		if(activePanelClip){
			const updatedClip = this.getClipForId(activePanelClip.id);
			const isClipStillValidAsActive = updatedClip && 
			updatedClip.startTime <= this.state.currentTime && 
				this.state.currentTime < (updatedClip.startTime + updatedClip.duration);
				if (!isClipStillValidAsActive) {
					setTimeout(() => { //try adding a slight timeout to prevent the # transaction getting added to new view
						this.handleDeactivateActiveClip();
					}, 10)
				}else{
					this.setState({activePanelClip:updatedClip})
				}
			}
		}, 5)
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	handleDragClip=(sceneId, clip, newStartTime,metaKeyIsPressed)=>{
		this.timeline.handleDragClip(sceneId, clip, newStartTime,metaKeyIsPressed,this.state.pixelsPerSec)
		this.updateStateFromTimeline()
	}

	onResizeStart=(clip,direction)=>{
		if(clip.type=='video' || (clip.type=='webcam' && !clip.metadata.isVariable)){
			if(direction=='right'){
				this.handleSeek(clip.endTime-0.001)
			}else{
				this.handleSeek(clip.startTime)
			}

			let preVideoResizeWebcamChunks=[] //snapshot the webcam chunks for previewing
			let preVideoResizeScreenChunks=[] //snapshot the screen chunks for previewing
			let webcamClip 
			let videoClip
			if(clip.type=='webcam'){
				webcamClip=clip
				if(clip.metadata.linkedClipId){
					videoClip=this.getClipForId(clip.metadata.linkedClipId)
				}
			}
			if(clip.type=='video'){
				videoClip=clip
				if(clip.metadata.linkedClipId){
					webcamClip=this.getClipForId(clip.metadata.linkedClipId)
				}
			}

			if(webcamClip){
				if(webcamClip.metadata.transcript && webcamClip.metadata.transcript.chunks){
					let transcriptChunks =[]
					const skipSegments = webcamClip.segments.filter(segment => segment.isSkipSegment)
					const skipRanges = skipSegments.map(segment => ({
						startTime: segment.originalStart,
						endTime: segment.originalEnd,
					}))
					
					webcamClip.metadata.transcript.chunks.forEach((chunk)=>{
						const startTime = Math.max(chunk.startTime,webcamClip.metadata.trimStart)
						const endTime = Math.min(chunk.endTime,webcamClip.metadata.trimEnd)
		
						const duration = calculateTimelineTimeFromVideoTime(endTime,webcamClip) -calculateTimelineTimeFromVideoTime(startTime,webcamClip) 
						const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime,webcamClip) - webcamClip.startTime
						let filteredText = chunk.text;
						if (chunk.items && webcamClip.metadata.trimStart !== undefined && webcamClip.metadata.trimEnd !== undefined) {
							const filteredItems = chunk.items.filter(item => 
								!(item.endTime < clip.metadata.trimStart || item.startTime > clip.metadata.trimEnd)&&
								 !skipRanges.some(range => item.startTime >= range.startTime && item.endTime <= range.endTime)
							);
					
							filteredText = filteredItems.map(item => {
								const word = item.word || '';
								return item.isPause ? word + '.' : word;
							}).join(' ');
						}
						if(duration>0){
							transcriptChunks.push({
								duration: duration,
								text: filteredText,
								startTime: relativeStartTime
							})
						}
					})
					preVideoResizeWebcamChunks=transcriptChunks
				}
			}

				if(videoClip){ //snapshot the chunks!
					let screenChunks=[]
					{videoClip.recordingChunks && videoClip.recordingChunks.map((chunk, index) => {
						if (chunk.inferredTitle || chunk.appName) {
							const startTime = Math.max(chunk.startTime, videoClip.metadata.trimStart)
							const endTime = Math.min(chunk.startTime + chunk.duration, videoClip.metadata.trimEnd)
							const duration = calculateTimelineTimeFromVideoTime(endTime, videoClip) - calculateTimelineTimeFromVideoTime(startTime, videoClip)
	
							const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime, videoClip) - videoClip.startTime
							screenChunks.push({
								duration: duration,
								startTime: relativeStartTime,
								inferredTitle: chunk.inferredTitle,
								inferredSubtitle: chunk.inferredSubtitle,
								appName: chunk.appName
							})
						}
					})
					preVideoResizeScreenChunks=screenChunks
				}
			}
			
			this.setState({
	      isVideoResizeModeActive: true,
	      preVideoResizeSnapshot: {
					videoClipId:clip.type=='video' ? clip.id : clip.metadata.linkedClipId,
					webcamClipId:clip.type=='webcam' ? clip.id : clip.metadata.linkedClipId,
					startTime:clip.startTime,
					endTime:clip.endTime,
					direction:direction,
					webcamChunks:preVideoResizeWebcamChunks,
					screenChunks:preVideoResizeScreenChunks
				},
	    });
		}
		this.timeline.onResizeStart(clip)
		this.updateStateFromTimeline()
	}

	onResizeStop=()=>{
		if(this.state.isVideoResizeModeActive){
			this.setState({
	      isVideoResizeModeActive: false,
	      preVideoResizeSnapshot: null,
	    });
		}

		this.timeline.onResizeStop()
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	onResize=(clip,newDuration,direction)=>{
		this.timeline.onResize(clip, newDuration,direction,this.state.pixelsPerSec)
		this.updateStateFromTimeline()
		if(direction=='right'){
			this.handleSeek(clip.endTime-0.01)
		}else{
			this.handleSeek(clip.startTime+0.01)
		}
		if(this.state.isVideoResizeModeActive && this.state.preVideoResizeSnapshot.webcamClipId){
			this.timeline.updateTranscriptFromTimeline()
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}
	}

	//// VOICEOVER CLIPS HERE
	handleChangeVoiceoverPlaybackRate = (rate)=>{
		this.timeline.updateVoiceoverPlaybackRate(rate)
		this.updateStateFromTimeline()
	}

	handleChangeActiveVoice = (voiceId) =>{
		this.timeline.updateActiveVoice(voiceId)
		this.updateStateFromTimeline()
	}

	handleVoiceoverAudioFileUpdated=()=>{ //when file is calculated we need to update the spacing and durations 
		if(this.timeline){
			this.timeline.calculateAudioTrackSpacing()
			this.timeline.debouncedSaveProjectTimeline()
			this.updateStateFromTimeline()
		}
	}

	playClipFromTranscript=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.playFromTranscript()
		}
	}

	recalculateAudioClip=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.recalculateAudio()
		}
	}

	setActiveTranscriptPanelAudioChunk=(chunk)=>{
		this.setState({activeTranscriptPanelAudioChunk:chunk})
	}

	//Find the transcript items in the range
	getSkippedItemsForRange=(clipId,range)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			const skippedItems = clip.metadata.transcript.items.filter(item => item.startTime < range.endTime && item.startTime >= range.startTime)
			return skippedItems
		}
	}

haveRecordingChunksChanged = (existingClip, newClip) => {
	const trimmedChunks = existingClip.metadata?.transcript?.chunks.filter(chunk => chunk.endTime >= existingClip.metadata.trimStart && chunk.startTime <= existingClip.metadata.trimEnd)
  if (!trimmedChunks || 
		trimmedChunks.length !== newClip.chunks.length) {
    return true;
  }
	return false
};

	haveRecordingSkipSegmentsChanged = (oldSegments,newSegments) => {
		if(oldSegments.length==0 && newSegments.length==0){
			return false
		}

		if(oldSegments.length !== newSegments.length){
			return true
		}

		const sortedOldSegments = [...oldSegments].sort((a, b) => a.originalStart - b.originalStart);
		const sortedNewSegments = [...newSegments].sort((a, b) => a.startTime - b.endTime);

		return sortedOldSegments.some((segment, index) => {
		return segment.originalStart !== sortedNewSegments[index].startTime ||
			segment.originalEnd !== sortedNewSegments[index].endTime;
		});
	};

	//turn transcript changes in timeline clips
	updateTimelineFromTranscript=(nodes)=>{
		let updatesArray=[]
		let {scenes} = this.state 
		const {audioNodes,sceneNodes,variableWebcamNodes,webcamRecordingNodes,webcamHeaders,skipMarkers} = nodes
		// console.log('set webcam headers here in update from transcript',webcamHeaders)
		this.setState({
			transcriptSceneHeaders:sceneNodes,
			transcriptWebcamHeaders:webcamHeaders,
			skipMarkers:skipMarkers
		})
		const existingAudioClips = this.state.clips.filter(clip =>clip.type=='audio')
		const existingWebcamClips = this.state.clips.filter(clip =>clip.type=='webcam')
		const createdAudioNodes = audioNodes.filter(node =>!existingAudioClips.some(clip => clip.id === node.clipId))
		const deletedAudioClips= existingAudioClips.filter(clip =>!audioNodes.some(node => node.clipId === clip.id))
		const updatedAudioNodes = audioNodes.filter(node =>!createdAudioNodes.includes(node) && existingAudioClips.some(clip => clip.id === node.clipId && (clip.metadata.text !== node.textContent || clip.clipIndex!=node.clipIndex || clip.parentWebcamClip!=node.parentWebcamClip || clip.indexInParentClip!=node.indexInParentClip)))


		///// Webcam recordings /////////
		// const updatedWebcamRecordingNodes = webcamRecordingNodes.filter(recordingNode => {
		// 	const existingClip = existingWebcamClips.find(clip => clip.id == recordingNode.clipId);
		// 	if (!existingClip) return false;
		// 	const existingSkipSegments = existingClip.segments.filter(segment => segment.isSkipSegment)
		// 		return (
		// 			existingClip.clipIndex !== recordingNode.clipIndex ||
		// 			this.haveRecordingSkipSegmentsChanged(
		// 			existingSkipSegments,
		// 			recordingNode.skipRanges
		// 		) ||
		// 		this.haveRecordingChunksChanged(existingClip, recordingNode)
		// 	);
		// });

		///TEMP FIX FOR SEGMENTS
		const updatedWebcamRecordingNodes = webcamRecordingNodes

		updatedWebcamRecordingNodes.forEach((node) => {
			updatesArray.push({
				type: 'updateWebcamRecording',
				clipId: node.clipId,
				clipIndex: node.clipIndex,
				skipRanges:node.skipRanges,
				chunks: node.chunks.map(chunk => ({
					startTime: chunk.startTime,
					endTime: chunk.endTime
				}))
			});
		});


		////////// Webcam Placeholder

		const createdVariableWebcamNodes = variableWebcamNodes.filter(node => 
			!existingWebcamClips.some(clip => clip.id === node.clipId)
		)
		
		const deletedVariableWebcamClips = existingWebcamClips.filter(clip => {
    	const matchingWebcamClip = variableWebcamNodes.find(node => node.clipId === clip.id)
    	return !matchingWebcamClip && clip.metadata.isVariable === true
		})
	
		// Find updated webcam clips
		const updatedVariableWebcamNodes = variableWebcamNodes.filter(node =>
			!createdVariableWebcamNodes.includes(node) && 
			existingWebcamClips.some(clip => clip.id === node.clipId)
		)

		// Process webcam clips
		createdVariableWebcamNodes.forEach(node => {
			updatesArray.push({
				type: 'createVariableWebcamClip',
				clipId: node.clipId,
				clipIndex:node.clipIndex,
				sceneId:node.sceneId,
				hasInstructions:node.hasInstructions
			})
		})

		updatedVariableWebcamNodes.forEach(node => {
			updatesArray.push({
				type: 'updateVariableWebcamClip',
				clipId: node.clipId,
				clipIndex:node.clipIndex,
				sceneId:node.sceneId,
				instructions:node.instructions,
				hasInstructions:node.hasInstructions
			})
		})

		deletedVariableWebcamClips.forEach(clip => {
			updatesArray.push({
				type: 'deleteClip',
				clip: clip
			})
		})

		/////// AUDIO CLIPS /////////		

		createdAudioNodes.forEach((node)=>{ //need to do this before the delete thing
			updatesArray.push({
				type:'createAudioClip',
				clipId:node.clipId,
				sceneId:node.sceneId,
				text:node.textContent,
				clipIndex:node.clipIndex,
				indexInParentClip:node.indexInParentClip,
				parentWebcamClip:node.parentWebcamClip
			})
		})

		updatedAudioNodes.forEach((node)=>{
			if(node.textContent && node.textContent !=='#' && node.textContent !=='---'){
				updatesArray.push({
					type:'updateAudioClip',
					clipId:node.clipId,
					text:node.textContent,
					clipIndex:node.clipIndex,
					indexInParentClip:node.indexInParentClip,
					parentWebcamClip:node.parentWebcamClip
				})
			}else{ //empty text things lets delete from timeline
				const clip = find(this.state.clips,{id:node.clipId})
				updatesArray.push({
					type:'deleteClip',
					clip:clip,
				})
			}
		})

		deletedAudioClips.forEach((clip)=>{
			updatesArray.push({
				type:'deleteClip',
				clip:clip,
			})
		})

		/////Scenes
		const updatedSceneNodes = sceneNodes.filter(node =>scenes.some(scene => scene.id === node.sceneId && (scene.title !== node.sceneTitle)))
		updatedSceneNodes.forEach((node)=>{
			updatesArray.push({
				type:'updateSceneTitle',
				sceneId:node.sceneId,
				title:node.sceneTitle
			})
		})

		const deletedScenes = scenes.filter(scene => !sceneNodes.some(node => node.sceneId === scene.id));
		deletedScenes.forEach((scene) => {
			updatesArray.push({
				type: 'mergeScene',
				sceneId: scene.id,
			});
		});


		if(this.timeline){ //initial load with webcamthing
			this.timeline.updateTimelineFromTranscript(updatesArray)
			this.updateStateFromTimeline()
		}
	}


	recalculateAudioClip=(clipId)=>{
		const clip = find(this.state.clips,{id:clipId})
		if(clip){
			clip.recalculateAudio()
		}
	}


	updateTranscripPanelFromTimeline=()=>{ //Manually sync the transcript panel with changes on timeline
		this.timeline.updateTranscriptFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}


	deleteClip=(clipId)=>{
		if(this.state.activePanelClip && this.state.activePanelClip.id==clipId){
			this.handleDeactivateActiveClip()
		}
		this.timeline.deleteClipById(clipId)
		this.updateStateFromTimeline()
		this.setFocusToEditor()
		if(this.timeline){
			this.timeline.updateTranscriptFromTimeline()
			this.timeline.calculateAudioTrackSpacing()
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}
	}

	duplicateClip=(clip)=>{
		let newClip = cloneDeep(clip)
		newClip.id=randomID()
		newClip.startTime = clip.startTime //now this is calc field need to add it for duplicate to work
		const duplicateLayoutGroup = (group) => {
			let newGroup = cloneDeep(group)
			newGroup.id = randomID()
			newGroup.children = group.children.map(child => {
				if (child.isLayoutGroup ) {
					return duplicateLayoutGroup(child);
				} else {
					return duplicateElement(child);
				}
			});
		return newGroup;
		};

		const duplicateElement = (element) => {
			let newElement = cloneDeep(element)
			newElement.id = randomID()
			return newElement
		};
		
		if(clip.type=='slide'){
			newClip.layout=duplicateLayoutGroup(clip.layout)
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
	}

	copyClip=(clip)=>{
		this.props.setCopiedClip(clip)
	}

	cutClip=(clip)=>{
		this.props.setCopiedClip(clip)
		this.deleteClip(clip.id)
	}

	maybePasteClip=()=>{
		if(this.props.copiedClip){
			const activeElement = document.activeElement;
			const isEditableFocused = activeElement && (
            activeElement.tagName === 'INPUT' || 
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable // Checks if the element is content editable
        );
			const hasProsemirrorClass = activeElement && Array.from(activeElement.classList).some(className => className.includes('ProseMirror'))
			if(!hasProsemirrorClass && !isEditableFocused){
				//let newClip={...this.props.copiedClip}
				let newClip=cloneDeep({...this.props.copiedClip})
				newClip.id=randomID()
				newClip.sceneId = null
				let newLinkedClip
				let newLinkedClipId=randomID()

				//TODO make this undoable as one thing

				if(newClip.metadata.linkedClipId){
					const linkedClip = this.getClipForId(newClip.metadata.linkedClipId)
					if(linkedClip){
						newClip.metadata.linkedClipId=newLinkedClipId
						newLinkedClip=cloneDeep({...linkedClip})
						newLinkedClip.id=newLinkedClipId
						newLinkedClip.startTime=this.state.currentTime
						newLinkedClip.pinnedStartTime=this.state.currentTime
						newLinkedClip.metadata.linkedClipId=newClip.id
						newLinkedClip.sceneId=null
					}
				}
				newClip.startTime=this.state.currentTime
				newClip.pinnedStartTime=this.state.currentTime
				this.timeline.addClip(newClip)
				if(newLinkedClip){
					this.timeline.addClip(newLinkedClip)
				}
				this.updateStateFromTimeline()
			}
		}
	}

  maybeCopyClip=()=>{ //command c copy if there is an active clip
		if(this.state.activePanelClip){
			this.copyClip(this.state.activePanelClip)
		}
	}
////Scenes
	addSceneFromTranscriptPanel=(splitSceneId,splitClipId,splitClipIndex)=>{
		setTimeout(() => { //try adding a slight timeout to prevent the # transaction getting added to new view
			this.timeline.addSceneFromTranscriptPanel(splitSceneId,splitClipId,splitClipIndex)
			this.updateStateFromTimeline()
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}, 10)
	}

	addSceneAtEndOfProject=()=>{ //when click the add scene button in transcript panel it adds a scene not splits it
		this.timeline.addNewScene()
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	addAudioClipAtEndOfProject=()=>{
		this.transcriptPmManager.manuallyAddAudioClip()
	}

	splitScene = () =>{
		this.timeline.splitScene()
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	addSceneAfterScene=(sceneId)=>{ //plus button in scene gap adds a scene before the scene
		this.timeline.addSceneAfterScene(sceneId)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	deleteScene=()=>{
		this.handleDeactivateActiveClip()
		const {scenes} = this.state
		this.timeline.deleteScene(scenes[0].id)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	mergeScene=(sceneId,mergeDirection)=>{ //default is merges with scene after unless merge direction is "before" 
		this.timeline.mergeScene(sceneId,mergeDirection)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	handleTranscriptPanelChunkDrop=(dropClipId,dropPosition)=>{
		this.timeline.handleTranscriptDnd(dropClipId,dropPosition)
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	openShortcutsPopover=()=>{
		this.setState({showShortcutsPopover: true})
	}

	closeShortcutsPopover=()=>{
		this.setState({showShortcutsPopover: false})
	}

	calculateAndSetDropLines=(clip,dx,sceneGap) => {
    const lines=calculateDropLines(clip,clip.startTime,dx,this.state.pixelsPerSec,this.state.clips, this.state.scenes,sceneGap) 
    this.setState({dropLines:lines})
  }

///device recordings

addDeviceRecordingToProject=async(captureId)=>{
	const isDevice = true
	const isScreenRecording = false
	const chunkIndex = null
	const sessionCaptureId = null
	const recordingChunks = null
	await this.timeline.addVideoClipFromCaptureId(captureId,isDevice,isScreenRecording,this.state.defaultMotionStyle, chunkIndex,sessionCaptureId, null, null, recordingChunks)	
	this.updateStateFromTimeline()
}

///// recording sessions
	handleImportSessionCaptureId = async(sessionCaptureId)=>{
		const session = await this.props.syncNewRecordingSession(sessionCaptureId) //TODO proper syncing with all the file stuff in the session sync
		const {screen_recording_capture_id:screenCaptureId,webcam_recording_capture_id:webcamCaptureId,duration} = session
		let webcamClipId = null
		if(webcamCaptureId){
			webcamClipId = randomID()
		}
		this.props.syncNewScreenRecording(screenCaptureId)
		const chunkIndex = null
		const recordingChunks = await getScreenRecordingChunksFromFile(screenCaptureId)
		if(webcamCaptureId){
			this.props.syncRecordingSessionWebcam(webcamClipId,duration, webcamCaptureId)
			await this.timeline.addScreencast(screenCaptureId,sessionCaptureId,chunkIndex,webcamCaptureId,this.state.defaultMotionStyle,duration,webcamClipId, recordingChunks)
			this.updateStateFromTimeline()
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}else{
			const isDevice = false 
			const isScreenRecording = true 
			await this.timeline.addVideoClipFromCaptureId(screenCaptureId,isDevice,isScreenRecording,this.state.defaultMotionStyle, chunkIndex,sessionCaptureId, null, null, recordingChunks)
			this.updateStateFromTimeline()
		}
	}

	addWebcamFromLibrary=async(captureId,sessionCaptureId)=>{
		const clipId = await this.timeline.addWebcamClipFromCaptureId(captureId,sessionCaptureId)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(clipId)
		this.handleSetActivePanelClip(createdClip)
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	addRecordingFromLibrary = async (captureId,sessionCaptureId,isDevice,isScreenRecording, chunkIndex = null, linkedWebcamCaptureId = null, recordingChunks = null)=>{
		if(linkedWebcamCaptureId){
			await this.timeline.addScreencast(captureId,sessionCaptureId,chunkIndex,linkedWebcamCaptureId,this.state.defaultMotionStyle, null, null,recordingChunks)
		}
		else{
			await this.timeline.addVideoClipFromCaptureId(captureId,isDevice,isScreenRecording,this.state.defaultMotionStyle, chunkIndex,sessionCaptureId, null, null, recordingChunks)	
		}
		this.updateStateFromTimeline()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	unlinkScreencast=(clipId)=>{
		this.timeline.unlinkScreencast(clipId)
		this.updateStateFromTimelineWithTranscriptRefresh()
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}


	handleNewVideoFileUpload = async (file, duration, webcamClip=null) => {
		let startTime = this.state.currentTime;
		const clipId = randomID();
		const videoId = randomID();		
		const newClip = {
			id: clipId,
			type: "video",
			videoId: videoId,
			isUploadingVideo: true,
			isBasicVideo: true,
			startTime: startTime,
			duration: duration,	
			metadata: {
				backgroundId: 'none',
				startTransitionType: 'fadeAndMoveUp',
				endTransitionType: 'fadeAndMoveDown',
				isAutoMotionStyle: true,
				motionStyle: this.state.defaultMotionStyle,
				label: file.name
			},
			zIndex: 0
		};

		this.timeline.addClip(newClip);
		await this.updateStateFromTimeline();
		const createdClip = this.getClipForId(clipId);
		if(createdClip){
			this.handleSetActivePanelClip(createdClip);
		}
		this.props.uploadVideoAndHandleResponse(file, videoId, webcamClip?.captureId).then((response)=>{
			if(this.timeline){
				this.timeline.updateVideoWithUploadResponse(clipId, videoId, response);
				this.updateStateFromTimeline(); // Ensure state is updated after response
			}
		});
		return createdClip;
	}

	insertVideoFromRecent=(videoObj)=>{ ///Insert basic video
		let startTime=this.state.currentTime
		const id = randomID()
		const newClip = {
			id:id,
			type:"video",
			videoId:videoObj.id,
			isBasicVideo:true,
			startTime:startTime,
			duration:videoObj.duration,
			metadata:{
				label:videoObj.original_filename,
				backgroundId:'none',
				startTransitionType:'fadeAndMoveUp',
				endTransitionType:'fadeAndMoveDown',
				isAutoMotionStyle:true,
				motionStyle:this.state.defaultMotionStyle,
				displayWidth:videoObj.default_display_width,
				semiTransparent:videoObj.semi_transparent,
				originalHeight:videoObj.original_height,
				originalWidth:videoObj.original_width
			},
			zIndex:0
		}
		this.timeline.addClip(newClip)
		this.updateStateFromTimeline()
		const createdClip = this.getClipForId(id)
		this.handleSetActivePanelClip(createdClip)
		this.props.updateVideoInsertCount(videoObj.id)
	}


	

//////For in app webcam upload
	handleNewWebcamFileUpload=async(file,duration)=>{
		let startTime=this.state.currentTime
		const clipId = randomID()
		const captureId =randomID()
		const fileExtension = getFileExtension(file)

		const payload = {
			assetType:'webcam',
			captureId:captureId,
			filepath:file.path,
		}
		const result = await saveUploadedAssetToCache(payload)
		console.log('result',result)
		///first we want to copy the file to the yarn cache

		const newClip = {
			id:clipId,
			type:"webcam",
			captureId:captureId,
			sessionCaptureId:null,
			isUploadingVideo:true,
			startTime:startTime,
			pinnedStartTime:startTime,
			duration:duration,
			metadata:{...webcamClipDefaultMetadata,userUploadFileExtension:fileExtension},
			zIndex:-1	
		}
		this.timeline.addClip(newClip)
		await this.updateStateFromTimeline()
		const createdClip = this.getClipForId(clipId)
		this.handleSetActivePanelClip(createdClip)
		this.props.syncUserUploadedWebcam(clipId,file,duration,captureId,fileExtension)


		// this.props.uploadWebcamRecording(clipId,file,duration,captureId)
		 this.manuallyRefreshTranscriptChunksForDragHandles()
		// return createdClip
	}

	splitRecordingClipFromTranscript=(clipId,splitItemTime)=>{
		const clip = this.getClipForId(`${clipId}`)
		if(clip){
			const splitTime = calculateTimelineTimeFromVideoTime(splitItemTime,clip)
			const newClipId = randomID()
			this.timeline.splitWebcamClip(clip,splitTime,newClipId)
			this.updateStateFromTimeline()
			//put the cursor at the top of the new chunk
			this.transcriptPmManager.putCursorAtTopOfNewGroup(newClipId)
			this.manuallyRefreshTranscriptChunksForDragHandles()
		}else{
			console.log('no clip found')
		}
	}

	maybeMergeRecordingClipFromTranscript=(clipId)=>{
		console.log('maybeMergeRecordingClipFromTranscript----->>>>',clipId)
	}

	
	splitRecordingClip=(clipId)=>{
		if(clipId){
			const clip = this.getClipForId(clipId)
			if(clip && clip.type=='webcam' && !clip.metadata.linkedClipId){
				this.timeline.splitWebcamClip(clip,this.state.currentTime)
				this.updateStateFromTimeline()
				this.manuallyRefreshTranscriptChunksForDragHandles()
				return
			}
		}
			const {currentTime,clips} = this.state
			const videoClips = filter(clips,{type:'video'})
			let activeClip 
			videoClips.forEach((clip)=>{
				if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
					activeClip = clip
				}
			})
			if(activeClip && !activeClip.isUploadingVideo){
				this.timeline.splitVideoClip(activeClip,currentTime).then(()=>{
					this.updateStateFromTimeline()
				})
			}
			else{ //if there is not a video clip look for a webcam clip at currentime
				const webcamClips = filter(clips,{type:'webcam'})
				let activeWebcamClip 
				webcamClips.forEach((clip)=>{
					if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
						activeWebcamClip = clip
					}
				})
				if(activeWebcamClip){
					this.timeline.splitWebcamClip(activeWebcamClip,currentTime)
					this.updateStateFromTimeline()
				}
			}
		
		this.manuallyRefreshTranscriptChunksForDragHandles();
	}

	findVideoClipAtPlayhead=()=>{
		let activeClip=null 
		const {clips,currentTime} = this.state
		const videoClips = filter(clips,{type:'video'})
		videoClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				activeClip = clip
			}
		})
		return activeClip
	}

	addFreezeFrame=(clipId)=>{
		let id = clipId 
		if(!id){
			const activeClip = this.findVideoClipAtPlayhead()
			if(activeClip){
				id=activeClip.id
			}
		}
		if(id){
			this.timeline.addFreezeFrame(id,this.state.currentTime)
			this.updateStateFromTimeline()
		}
	}

	addSkipSegment=(clipId)=>{
		let id = clipId 
		if(!id){
			const activeClip = this.findVideoClipAtPlayhead()
			if(activeClip){
				id=activeClip.id
			}
		}
		if(id){
			this.timeline.addSkipSegment(id,this.state.currentTime)
			this.updateStateFromTimeline()
		}
	}

	toggleSkipSegment=(clipId,segmentId,isExpanded)=>{
		this.timeline.toggleSkipSegment(clipId,segmentId,isExpanded)
		this.updateStateFromTimeline()
	}

	handleChangeFreezeSegmentPlaybackRate=(clipId,segmentId,playbackRate)=>{
		this.timeline.updateFreezeSegmentPlaybackRate(clipId,segmentId,playbackRate)
		this.updateStateFromTimeline()
	}

	handleResizeSkipSegmentStart=(direction)=>{
		this.setState({resizingSkipSegmentHandle:direction,preSkipResizeCurrentTime:this.state.currentTime})
	}

	handleResizeSkipSegmentStop=()=>{
		this.handleSeek(this.state.preSkipResizeCurrentTime);
		this.setState({resizingSkipSegmentHandle:null,preSkipResizeCurrentTime:null})
	}

	handleChangeSkipSegmentDuration=(clipId,segmentId,newDuration,resizeDirection)=>{
		this.timeline.updateSkipSegmentDuration(clipId,segmentId,newDuration,resizeDirection)
		this.updateStateFromTimeline()
	}

	handleChangeVideoClipPlaybackRate=(clipId,playbackRate)=>{
		this.timeline.changeVideoClipPlaybackRate(clipId,playbackRate)
		this.updateStateFromTimeline()
	}

	handleClipMediaLoaded=()=>{//bump state when video clip finishes loading
		this.updateStateFromTimeline()
	}



	addSlide=async(imageObj,elementId,slideClipId,elementType)=>{
		let startTime=this.state.currentTime
		const clipId = slideClipId || randomID()
		const elementText='Headline'
		let paragraphs=[]
		paragraphs.push({
			type: "paragraph",
			attrs: { indentLevel: 0 },
			content: [
				{ type: "text", text: elementText }
			]
		});
		const nodeJson = JSON.stringify({
			type: "doc",
			content: paragraphs
		})
		const newClip = {
			id:clipId,
			type:"slide",
			startTime:startTime,
			duration:5,
			metadata:{
				backgroundId:'none',
				variables:[]
			},
			zIndex:1,
			elements:[]	
		}
		this.timeline.addClip(newClip)
		if(imageObj){
			const newElementId = elementId || randomID()
		 	this.timeline.addImageElementToSlide(newClip.id,imageObj,newElementId)
		 	this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
		}else if(elementType=='chart'){
			await this.addSlideElement(clipId,'chart',false)
		}else{
			await this.addSlideElement(clipId,'text',false)
		}
		this.updateStateFromTimeline()
		setTimeout(() => { //hacky timeout to wait for transcript to be ready
			const createdClip = this.getClipForId(clipId)
			this.handleSetActivePanelClip(createdClip)
    }, 100);
	}

	addSlideElement = async (clipId, type, isVariable) => {
    try {
    	if (!this.state.activePanelClip || this.state.activePanelClip.id !== clipId) {
				this.setState({activePanelClip:this.getClipForId(clipId)});
			}
    	//make slie active here
    	const newElementId = randomID()
      await this.timeline.addSlideElement(clipId, type, isVariable,newElementId);
      this.updateStateFromTimeline();
      this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
    } catch (error) {
    	console.log('error adding slide element')
    }
  }

	deleteSlideItems = () => {
		this.timeline.deleteSlideItems(this.state.activePanelClip.id,this.state.selectedSlideItems)
		this.updateStateFromTimeline();
		this.resyncActivePanelClipInState()
		this.closeSlideEditorContextMenu()
	}

	handleUpdateCanvasFromSlideEditor=()=>{ //force canvas to update with changes while dragging
		if(this.state.isSlideEditorDragResizing){
			this.handleSyncSlideChanges()
		}else{
			this.resyncActivePanelClipInState()
		}
	}

	handleSyncSlideChanges=()=>{
		if(this.state.activePanelClip){
			const clip = this.getClipForId(this.state.activePanelClip.id)
			this.timeline.debouncedSaveProjectTimeline()
			this.updateStateFromTimeline()
			this.pmManager.syncSlideClip(clip)
			this.setState({activePanelClip:clip})
		}else{
			console.log('no panel clip')
		}
	}

	//after we make changes to the slide we update the clip in state and also check that the selected items still exist in the clip (e.g. delete selected item)
	resyncActivePanelClipInState = () => {
		if(this.state.activePanelClip && this.state.activePanelClip.type=='slide'){
			const activeClipId = this.state.activePanelClip.id;
			const newActivePanelClip = this.getClipForId(activeClipId);
			// Filter out selected items that no longer exist in the updated clip
			const updatedSelectedItems = this.state.selectedSlideItems.filter(item => {
				if (item.type === 'layoutGroup') {
					return newActivePanelClip.layoutGroups.some(group => group.id === item.id);
				} else {
					return newActivePanelClip.elements.some(element => element.id === item.id);
				}
			});
			this.setState({
				activePanelClip: newActivePanelClip,
				selectedSlideItems: updatedSelectedItems
			});
		}
	}

	updateLayoutGroupField=(clipId,layoutGroupId,field,value)=>{
		this.timeline.updateSlideLayoutGroupField(clipId,layoutGroupId,field,value)
		this.updateStateFromTimeline()
	}

	updateSlideAlignment = (clipId,alignment,value)=>{
		this.timeline.updateSlideAlignment(clipId,alignment,value)
		this.updateStateFromTimeline()
	}

	updateSlideBackgroundColor = (clipId,backgroundId)=>{
		this.timeline.setClipBackgroundId(clipId,backgroundId)
		this.updateStateFromTimeline()
	}

	handleSlideDragOrResizeStart=()=>{
		this.setState({isSlideEditorDragResizing:true})
		this.timeline.handleSlideDragOrResizeStart()
	}

	handleSlideDragOrResizeEnd=()=>{
		this.setState({isSlideEditorDragResizing:false})
		this.timeline.handleSlideDragOrResizeEnd()
	}


	//put this through seperate thing to other metadata so we can do font loading
	updateSlideTextElementTextProperties=(clipId,elementId,textStyle,newTextProperties)=>{
		this.timeline.updateSlideTextElementTextProperties(clipId, elementId,textStyle,newTextProperties)
		const activeClipId = this.state.activePanelClip.id
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
		this.updateStateFromTimeline()
	}

	handleTextElementFontLoaded=()=>{
		this.updateStateFromTimeline()
	}

	updateSlideElementMetadata=(clipId,elementId,newMetadata)=>{ 
		this.timeline.updateSlideElementMetadata(clipId, elementId,newMetadata)
		const activeClipId = this.state.activePanelClip.id
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
		this.updateStateFromTimeline()
	}

	updateSlideElementField=(clipId,elementId,field,value)=>{ 
		this.timeline.updateSlideElementField(clipId, elementId,field,value)
		this.updateStateFromTimeline()
	}

	updateSlideElementAnimationIndex=(clipId,elementId,newIndex)=>{
		this.timeline.updateSlideElementAnimationIndex(clipId, elementId,newIndex)
		this.updateStateFromTimeline()
	}

	updateSlideTextElementText=(lettersArray,text,docJson,clipId,elementId)=>{
		this.timeline.updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId)
		this.timeline.calculateUniqueVariables()
		this.updateStateFromTimeline()
	}


	updateSlideElementZOrder = (clipId,elementId,updateType)=>{
		this.timeline.updateSlideElementZOrder(clipId,elementId,updateType)
		this.updateStateFromTimeline()
	}

	
	handleDimensionsUpdatedFromDetailPanel=()=>{
		const clip=this.state.activePanelClip
		this.timeline.debouncedSaveProjectTimeline()
		this.updateStateFromTimeline()
		this.pmManager.syncSlideClip(clip)
	}	

	duplicateSlideItems = ()=>{
		const {selectedSlideItems} = this.state
		let duplicateItemIds=[]
		selectedSlideItems.forEach((item)=>{
			duplicateItemIds.push({originalId:item.id,duplicateItemId:randomID(),type:item.type})
		})
		const activeClipId = this.state.activePanelClip.id
		this.timeline.duplicateSlideItems(activeClipId,duplicateItemIds)
		this.updateStateFromTimeline()
		const newActivePanelClip = this.getClipForId(activeClipId)
		let newSelectedSlideItems=[]
		duplicateItemIds.forEach((duplicateItem)=>{
			newSelectedSlideItems.push({type:duplicateItem.type,id:duplicateItem.duplicateItemId})
		})
		this.closeSlideEditorContextMenu()
		this.setState({activePanelClip:newActivePanelClip,selectedSlideItems:newSelectedSlideItems})	
	}


	openSlideEditorContextMenu = (clipId, elementId, position) => {
		// Check if clicked element is in the current selectedSlideItems
		// If so just keep the selection and the context menu actions apply to all the selected things
		const isClickedElementSelected = this.state.selectedSlideItems.some(
		selectedItem => selectedItem.id === elementId 
		);

		const stateUpdates = {
			slideEditorContextMenuElementId: elementId,
			slideEditorContextMenuPosition: position
		};
		if (!isClickedElementSelected) {
			stateUpdates.selectedSlideItems = [{ type: 'element', id: elementId }];
		}
		// Update active panel clip if different from current
		if (!this.state.activePanelClip || this.state.activePanelClip.id !== clipId) {
			stateUpdates.activePanelClip = this.getClipForId(clipId);
		}
		this.setState(stateUpdates);
	}

	  closeSlideEditorContextMenu=()=>{
	  	this.setState({slideEditorContextMenuElementId:null,slideEditorContextMenuPosition:null})
	  }

		handleClickOutside = (event) => {
			if (this.state.slideEditorContextMenuElementId && 
				!event.target.closest('.slideEditorContextMenu')) {
				this.closeSlideEditorContextMenu();
			}
		};

		handleContextMenuButtonClick=(updateType)=>{
			this.updateSlideElementZOrder(this.state.activePanelClip.id,this.state.slideEditorContextMenuElementId,updateType)
			this.closeSlideEditorContextMenu()
		}


	updateActivePanelClipInState=()=>{ //update the clip object in state after changes made on clip
		const newActivePanelClip = this.getClipForId(this.state.activePanelClip.id)
		this.setState({activePanelClip:newActivePanelClip})
	}

	setIsResizingWebcam=(value,clipId)=>{
		if (clipId && (!this.state.activePanelClip || this.state.activePanelClip.id !== clipId)) {
			this.setState({activePanelClip:this.getClipForId(clipId)})
		}
		this.setState({isResizingWebcam:value})
	}

	setIsDraggingWebcam=(value,clipId)=>{
		if (clipId && (!this.state.activePanelClip || this.state.activePanelClip.id !== clipId)) {
			this.setState({activePanelClip:this.getClipForId(clipId)})
		}
		this.setState({isDraggingWebcam:value})
	}

	setShowWebcamPhasesForClipId=(clipId)=>{
		this.setState({showWebcamPhasesForClipId: clipId})
	}

	clearActiveWebcamPhasesClipId=()=>{
		this.setState({showWebcamPhasesForClipId: null})
	}
	//// Slide templates
	useSlideTemplate=(template)=>{
		const clip = this.state.activePanelClip;
		this.timeline.useSlideTemplate(clip.id, template);
		this.updateStateFromTimeline();
		this.updateActivePanelClipInState()
		this.setState({testKey:this.state.testKey+1})
	}

	saveSlideAsTemplate=()=>{
		this.props.saveSlideAsTemplate(this.props.projectId, this.state.activePanelClip)
	}


  handleAlignSlideElements=(alignType,alignValue)=>{
		const {selectedSlideElements}= this.state 
		const activeClipId = this.state.activePanelClip.id
		this.timeline.alignSlideElements(activeClipId,selectedSlideElements,alignType,alignValue)
		this.updateStateFromTimeline()
		const newActivePanelClip = this.getClipForId(activeClipId)
		this.setState({activePanelClip:newActivePanelClip})
	}

	handleSetSlideEditorDragElement=(dragElementId,dragLayoutGroup)=>{
		this.setState({slideEditorDragElementId:dragElementId,slideEditorDragLayoutGroupId:dragLayoutGroup})
	}

	handleSetSlideEditorResizeElement=(slideEditorResizeElementId)=>{
		this.setState({slideEditorResizeElementId:slideEditorResizeElementId})
	}

	updateSlideElementTextSelectionInfo=(info)=>{
		this.setState({slideElementTextSelectionInfo:info})
	}

	clearSlideElementsSelection=()=>{
		this.setState({selectedSlideItems:[],focusedSlideElement:null})
		this.clearUnselectedTextElementSelections([]);
	}

	setFocusedSlideElement=(elementId)=>{
		this.setState({focusedSlideElement:elementId})
	}

	setSlideEditorSelection=(items,clipId)=>{
		if (clipId && (!this.state.activePanelClip || this.state.activePanelClip.id !== clipId) ){
			this.setState({activePanelClip:this.getClipForId(clipId)});
		}
		this.setState({selectedSlideItems:items})
		//clear the prosmirror selection for any text elements that are not selected
		this.clearUnselectedTextElementSelections(items);
	}

	clearUnselectedTextElementSelections = (newlySelectedItems) => {
	  if (this.state.activePanelClip && this.state.activePanelClip.type === 'slide') {
	    const selectedIds = new Set(newlySelectedItems.map(item => item.id));

	    this.state.activePanelClip.elements.forEach(element => {
	      if (element.type === 'text' && !selectedIds.has(element.id)) {
	        // Clear selection for this text element
	        this.clearSelectionForElement(element.id);
	      }
	    });
	  }
	}

	clearSelectionForElement = (elementId) => {
	  const view = window[`textEditView_${elementId}`];
	  if (view) {
	    const tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, 0));
	    view.dispatch(tr);
	  }
	}

	alignSlideItems=(alignType,alignValue)=>{
		const clip = this.state.activePanelClip;
    this.timeline.alignSlideItems(clip.id, this.state.selectedSlideItems, alignType,alignValue);
    this.updateStateFromTimeline();
	}

	setIsDraggingToReorder=(value)=>{
		this.setState({isDraggingToReorder:value})
	}

	groupSlideItems = (groupingType) => {
  	if(this.state.selectedSlideItems.length>1){
	    const clip = this.state.activePanelClip;
	    const newLayoutGroupId=randomID()
	    this.timeline.groupSlideItems(clip.id, this.state.selectedSlideItems, groupingType,newLayoutGroupId);
	    this.updateStateFromTimeline();
	    this.setState({selectedSlideItems:[{type: 'layoutGroup', id: newLayoutGroupId }]})
	    this.closeSlideEditorContextMenu()
  	}
  }

	ungroupLayoutGroup=(layoutGroupId)=>{
		this.timeline.ungroupSlideLayoutGroup(this.state.activePanelClip.id,layoutGroupId)
		this.updateStateFromTimeline()
		this.setState({selectedSlideItems:[]})
	}


	////Slide Images

	//either insert image or replace image if there is a replaceElementId
	handleSlideImageFileUpload=async (file,replaceElementId,dropPosition)=>{
		const tempImageObject = await createTempImageObject(file);
		const elementId=replaceElementId ||randomID()
		const {clips,currentTime} = this.state
		let slideClipAtPlayhead=null 
		const slideClips = filter(clips,{type:'slide'})
	
		slideClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				slideClipAtPlayhead = clip
			}
		})

		let clipId
		if (slideClipAtPlayhead) {
			if(replaceElementId){
				this.timeline.updateImageElementImage(slideClipAtPlayhead.id,tempImageObject,replaceElementId)
			}else{
				this.timeline.addImageElementToSlide(slideClipAtPlayhead.id,tempImageObject,elementId,dropPosition)
				this.setState({selectedSlideItems:[{type:'element',id:elementId}]})
			}
			this.updateStateFromTimeline()
			clipId=slideClipAtPlayhead.id
		} else{
			clipId=randomID()
			this.addSlide(tempImageObject,elementId,clipId)
		}
		const imageId = randomID()
		this.props.uploadImageAndHandleResponse(file,imageId).then((response)=>{
			this.timeline.updateImageWithUploadResponse(clipId,elementId,response)
			this.updateStateFromTimeline
		})
	}

	insertImageFromRecent=(imageObj,replaceElementId)=>{
		const {clips,currentTime} = this.state
		let slideClipAtPlayhead=null 
		const slideClips = filter(clips,{type:'slide'})
		slideClips.forEach((clip)=>{
			if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
				slideClipAtPlayhead = clip
			}
		})
		if (slideClipAtPlayhead) {
			if(replaceElementId){
				this.timeline.updateImageElementImage(slideClipAtPlayhead.id,imageObj,replaceElementId)
			}else{
				const newElementId = randomID()
				this.timeline.addImageElementToSlide(slideClipAtPlayhead.id,imageObj,newElementId)
				this.setState({selectedSlideItems:[{type:'element',id:newElementId}]})
			}
			this.updateStateFromTimeline()
    } else{
    	this.addSlide(imageObj)
    }
	}

	handleSetIsBGImage = (element,isBGImage) => {
		if(this.state.activePanelClip){
			const clipId = this.state.activePanelClip.id
			this.updateSlideElementMetadata(clipId,element.id,{...element.metadata,isBGImage:isBGImage,isFill:true,zOrder:-1})
			this.closeSlideEditorContextMenu()
		}
	}  

	handleSetShowReplaceImageMediaModal=(value)=>{
		this.setState({showReplaceImageMediaModal:value})
		this.closeSlideEditorContextMenu()
	}

  //// Some transcript panel stuff
  /// testing seeking webcam clip when cursor through words- not going to do this because think its distracting
	seekToTimeInWebcamClip=(clipId,timeRelativeToClip)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			this.handleSeek(clip.startTime+timeRelativeToClip)
		}
	}

	setPreviewingAudioClipId=(clipId)=>{
		this.setState({previewingAudioClipId:clipId})
	}

	cancelPreviewingAudioClip=(clipId)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			clip.stopPreviewingClip()
		}
	}

	manuallyRefreshTranscriptChunksForDragHandles=()=>{ //TODO rename this 
		setTimeout(() => { //hacky timeout to wait for transcript to be ready
			const initialChunks = this.transcriptPmManager.getInitialChunks()
			if(initialChunks){
				this.setState({
					transcriptSceneHeaders:initialChunks.sceneNodes,
					transcriptWebcamHeaders:initialChunks.webcamHeaders,
					skipMarkers:initialChunks.skipMarkers
				})
			}
    }, 100);
	}

	handleMouseOverTranscriptChunk=(clipId)=>{
		this.setState({hoveredTranscriptChunk:clipId})
	}

	handleMouseLeaveTranscriptChunk=(clipId)=>{
		if(this.state.handleMouseOverTranscriptChunk==clipId){
			this.setState({hoveredTranscriptChunk:null})
		}
	}

	handleCursorInsideTranscriptChunk=(clipId)=>{
		if(clipId!==this.state.transcriptChunkWithCursorInside){
			this.setState({transcriptChunkWithCursorInside:clipId})
		}
	}


/// PM Dev panel 
	setPMDocForDevMode=(pmDoc)=>{
		if (process.env.NODE_ENV == 'development') {
			this.setState({pmDoc:pmDoc})
		}
	}

	setTranscriptPMDocForDevMode=(pmDoc)=>{
		if (process.env.NODE_ENV == 'development') {
			this.setState({transcriptPmDoc:pmDoc})
		}
	}

//Background music 
	updateBackgroundMusicTrack=(trackId)=>{
		this.timeline.updateBackgroundMusic(trackId)
		this.updateStateFromTimeline()
	}

	updateBackgroundMusicVolume=(newVolume)=>{
		this.timeline.updateBackgroundMusicVolume(newVolume)
		this.updateStateFromTimeline()
	}

	handleNewMusicFileUpload=async (file)=>{
		const musicId = randomID()
		await this.props.uploadMusicAndHandleResponse(file,musicId)
		return
	}

  //// Subtitles and captions
  handleChangeSubtitlesType=(type)=>{ //subtitles can be null, standard or captions
  	this.props.updateProjectSubtitlesType(this.props.projectId,type)
  	this.setState({subtitlesType:type})
  }

	handleGenerateSRTFile = () => {
  	const { clips } = this.state;	
  	//const audioClips = filter(clips, { type: 'audio' });

		//include webcam clips
		const audioTrackClips  =filter(clips, { zIndex:-1 });
  	const srtContent = generateSRTFile(audioTrackClips);
	  const blob = new Blob([srtContent], { type: 'text/plain;charset=utf-8' });	
	  const url = URL.createObjectURL(blob);
	  const link = document.createElement('a');
  	link.href = url;
  	link.download = `${this.props.project.name}_subtitles.srt`; // Set the desired filename
	  document.body.appendChild(link);
	  link.click();
	  document.body.removeChild(link);
  	URL.revokeObjectURL(url);
	}
  
  regenerateAllSubtitles=()=>{
  	const { clips } = this.state;	
  	const audioClips = filter(clips, { type: 'audio' });
  	audioClips.forEach((clip)=>{
  		clip.calculateSubtitles()
  	})
  }

  /// Collab page settings
	updateCollabPageSettings = (settings) => {
  	this.debouncedUpdateCollabPageSettings(settings)
	}

	//////Export
  handleExportProject=()=>{
  	ExportManager.startExport(this.props.projectId)
  }

  handleCancelExportProject=()=>{
  	ExportManager.cancelExport(this.props.projectId)
  }

  //Trim Mode
  toggleTrimMode = ()=>{
		this.setState({trimMode:!this.state.trimMode})
	}

	updateClipTrimValues = (clip,trimStart,trimEnd) =>{
		const trimStartTime = trimStart * clip.recordingDuration
		const trimEndTime = trimEnd * clip.recordingDuration
		this.timeline.updateClipTrimValues(clip.id,trimStartTime,trimEndTime)
		this.updateStateFromTimeline()
		this.toggleTrimMode()
	}
	
	onTrimPreviewPlayPause = () => {
		this.setState(prevState => {
			return { trimPreviewPlaying: !prevState.trimPreviewPlaying };
		});
	};

	onTrimPreviewSeekChange = (value) => {
		this.setState({ trimPreviewProgress: value });
	};

	updateCursorOpacityForClip=(clipId)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			updateCursorOpacityForClip(clip)
		}
	}

	toggleHideInactiveCursor=()=>{
		this.timeline.toggleHideInactiveCursor()
		this.updateStateFromTimeline()
	}

	setVideoWindowPadding=(videoWindowPadding)=>{
		this.timeline.setVideoWindowPadding(videoWindowPadding)
		this.updateStateFromTimeline()
	}

	//// Webcam header actions
	deleteWebcamClipFromHeader=(clipId)=>{
		const refreshTranscriptPanel = true
		this.deleteClip(clipId,refreshTranscriptPanel)
	}

	setWebcamHasInstructionsFromHeader=(clipId,hasInstructions)=>{
		this.updateClipMetadata(clipId,{hasInstructions:hasInstructions})
		this.updateTranscripPanelFromTimeline()
	}


	getScriptForClip=(clip)=>{
		const clipId = clip.id
		const scene=find(this.state.scenes,{id:clip.scene.id})
		if(scene){
			return scene.clips
				.filter(clip => 
					clip.type === 'audio' && 
					clip.parentWebcamClip === clipId
				)
				.sort((a, b) => a.startTime - b.startTime)
				.map(audioClip => audioClip.metadata?.text || '')
				.filter(text => text.trim())
				.join('\n');
		}
		else return null
	}

	recordWebcamClipFromHeader=(clipId)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			const script = clip.metadata.script || this.getScriptForClip(clip)
			clip.metadata.script = script //at the point of record save the script that was used (script is audio clips with parent webcam clip)
			const webcamRecordModalProps={
				isOpen:true,
				clipId:clipId,
				hasInstructions:clip.metadata.hasInstructions,
				instructions:clip.metadata.instructions,
				script:script
			}
			this.setState({webcamRecordModalProps:webcamRecordModalProps})
		}
	}

	convertAudioClipIntoScript=({clipId,sceneId,clipIndex})=>{
		this.timeline.convertAudioClipIntoScript(clipId,sceneId,clipIndex)
		this.manuallyRefreshTranscriptChunksForDragHandles()
		this.updateStateFromTimeline()
	}
	

	convertWebcamRecordingIntoScript=(clipId)=>{
		this.timeline.convertWebcamRecordingIntoScript(clipId) 
		this.manuallyRefreshTranscriptChunksForDragHandles()
		this.updateStateFromTimeline()
	}

	convertWebcamRecordingIntoVoiceover=(clipId)=>{
		this.timeline.convertWebcamRecordingIntoVoiceover(clipId) 
		this.manuallyRefreshTranscriptChunksForDragHandles()
		this.updateStateFromTimeline()
	}

	closeWebcamRecordModal = () => {
	  this.setState({
	    webcamRecordModalProps: {
	      isOpen: false
	    }
	  });
		this.manuallyRefreshTranscriptChunksForDragHandles()
	}

	setGenerationsDialogOpen=(isOpen)=>{
		this.setState({generationsDialogOpen:isOpen})
	}


	restoreWebcamSkip=(skip)=>{
		this.timeline.restoreWebcamSkip(skip)
		this.manuallyRefreshTranscriptChunksForDragHandles()
		this.updateStateFromTimelineWithTranscriptRefresh()
	}

	handleSelectionRectsUpdated=(rects)=>{
		this.setState({transcriptSelectionRects:rects})
	}

	insertScreencastFromRecent = async (item, chunkIndex) => {
		let startTime = this.state.currentTime;
		const clipId = randomID();
		const captureId = item.captureId;
		
		// Get chunks data
		const chunks = item.recordingChunks ? JSON.parse(item.recordingChunks) : [];
		const chunk = chunks[chunkIndex];
		
		const newClip = {
			id: clipId,
			type: "screenRecording",
			captureId: captureId,
			startTime: startTime,
			duration: chunk ? chunk.duration : item.duration,
			chunkIndex: chunkIndex,
			metadata: {
				label: chunk ? `${chunk.appName} - ${chunk.title}` : item.title,
				backgroundId: 'none',
				startTransitionType: 'fadeAndMoveUp',
				endTransitionType: 'fadeAndMoveDown',
				isAutoMotionStyle: true,
				motionStyle: this.state.defaultMotionStyle,
				displayWidth: item.width,
				originalHeight: item.height,
				originalWidth: item.width,
				chunkStartTime: chunk ? chunk.startTime : 0,
				chunkDuration: chunk ? chunk.duration : item.duration
			},
			zIndex: 0
		};

		this.timeline.addClip(newClip);
		await this.updateStateFromTimeline();
		const createdClip = this.getClipForId(clipId);
		this.handleSetActivePanelClip(createdClip);
		this.manuallyRefreshTranscriptChunksForDragHandles();
	}

	openScreenshareSwiftApp = () => {
		try {
        let config = {
            projectId: this.props.projectId,
            isCameraEnabled: false,
            timestamp: Date.now(),
						apiBaseURL: process.env.REACT_APP_API_ENDPOINT,
        };
				const customWindowResizeOptions = getCustomWindowResizeOptions()
				console.log('customWindowResizeOptions',customWindowResizeOptions)
				if(customWindowResizeOptions){
					config.resizeOptions = customWindowResizeOptions
				}

        // Send single message for both hiding window and launching recorder
        ipcRenderer.send('start-screen-recording-session', config);

    } catch (error) {
        console.error('Error launching screen recorder:', error);
    }
	}


	getTitleForScene=(sceneId)=>{
		const scene = find(this.state.scenes, {id: sceneId})
		return scene ? scene.title : ''
	}

	changeAspectRatio=(newRatio)=>{
		this.timeline.updateAspectRatio(newRatio)
		this.updateStateFromTimeline()
	}
	updateSoundEffectsSettings=(soundEffectsSettings)=>{
		this.timeline.updateSoundEffectsSettings(soundEffectsSettings)
		this.setState({soundEffectsSettings:soundEffectsSettings})
	}


	toggleAddSkipSegmentMode = (fromShortcut = false) => {
		if (this.state.isAddSkipSegmentModeActive) {
	    // If turning off, seek back to original position if available
	    if (this.state.preAddSkipCurrentTime !== null) {
	      this.handleSeekWithPause(this.state.preAddSkipCurrentTime);
	      this.updateStateFromTimeline();
	    }
	    // Then update state to turn off the mode
	    this.setState({
	      isAddSkipSegmentModeActive: false,
	      preAddSkipCurrentTime: null,
	      addSkipSegmentModeClip: null,
	      skipSegmentModeFromShortcut: false
	    });
	  } else {
	    // If turning on, find the video clip at the playhead
	    const videoClip = this.findVideoClipAtPlayhead();
	    // Update state to turn on the mode
	    this.setState({
	      isAddSkipSegmentModeActive: true,
	      preAddSkipCurrentTime: this.state.currentTime,
	      addSkipSegmentModeClip: videoClip,
	      skipSegmentModeFromShortcut: fromShortcut
	    });
	  }
	};

	handleTrimToClipEdge=(clipId,edge)=>{
		this.timeline.trimClipToEdge(clipId,edge)
		this.updateStateFromTimeline()
	}

	handleAddSkipSegmentFromSkipSegmentMode = () => { //option edge which can be start or end of the clip
		const clipId = this.state.addSkipSegmentModeClip.id;
		const startTime = Math.min(this.state.preAddSkipCurrentTime, this.state.currentTime);
		const endTime = Math.max(this.state.preAddSkipCurrentTime, this.state.currentTime);
		
		// Add the skip segment
		this.timeline.addSkipSegmentFromSkipSegmentMode(clipId, startTime, endTime);
		
		// Seek to just before the start of the skip segment
		this.handleSeekWithPause(Math.max(startTime - 0.05, 0));
		this.updateStateFromTimeline();
		
		// Now turn off skip segment mode
		this.setState({
			isAddSkipSegmentModeActive: false,
			preAddSkipCurrentTime: null,
			addSkipSegmentModeClip: null
		});
	}

	handleSeekToClipInTimelineWithScroll=(clipId)=>{
		const clip = this.getClipForId(clipId)
		if(clip){
			this.handleSeek(clip.startTime)
		//	Calculate the x position for the clip's start time
			const xPosition = clip.startTime * this.state.pixelsPerSec
			// Scroll to the clip's position
			if(this.scrollContainerRef.current){
				this.scrollContainerRef.current.scrollLeft = xPosition
			}
		}
	}


	handleTimelineDoubleClick=()=>{
		const currentTime = this.state.currentTime
		const audioTrackClips = filter(this.state.clips,{zIndex:-1})
		const sortedAudioTrackClips = sortBy(audioTrackClips,'startTime')
		// Find all clips that start before current time
		const clipsBeforeCurrent = sortedAudioTrackClips.filter(clip => clip.startTime < currentTime)
		
		if (clipsBeforeCurrent.length > 0) {
			// Get the last clip (closest to current time) from the filtered clips
			const closestClip = clipsBeforeCurrent[clipsBeforeCurrent.length - 1]
			if(closestClip){
				let scrollTop
				if(closestClip.type === 'audio'){
					const dimensions = this.transcriptPmManager.getDimensionsForAudioClip(closestClip.id)
					if(dimensions && dimensions.top){
						scrollTop = dimensions.top - 95
					}
				}
				if(closestClip.type=='webcam'){
					const webcamHeader = this.state.transcriptWebcamHeaders.find(header => header.clipId === closestClip.id)
					if(webcamHeader){
						scrollTop = webcamHeader.dimensions.top
					}
				}
				if(scrollTop){
						// Scroll the transcript panel to a fixed position
					const transcriptPanelList = document.querySelector('.editor-transcriptPanel-list')
					if (transcriptPanelList) {
						transcriptPanelList.scrollTop = scrollTop
					}
				}
			}
		}
	}

//////Webflow demo

applyBulletPointsToSlide=(slideId,bullets)=>{
console.log('applyBulletPointsToSlide',slideId,bullets)
if(this.timeline){
	this.timeline.applyBulletPointsToSlide(slideId,bullets)
	this.setState({testKey:this.state.testKey+1})
}
}



	render(){     
		const timelineHeight = 238
		let centerContainerHeight = this.state.windowHeight - timelineHeight - 40// 34 is topbar height		
		const {clips,duration,currentTime,isPlaying, isDragResizingMedia, isDragResizingNumberInput}=this.state

		//audio track clips are audio and webcam
		const audioTrackClips = filter(clips,{zIndex:-1})
		const sortedAudioTrackClips = sortBy(audioTrackClips,'startTime')
		const {meshHeight, meshWidth, zoomBox} = this.state

		let activeScreenVideoAtPlayhead=false 
		let activeScreenVideoAtPlayheadIsLinkedClip=false
		if(!isPlaying){
			const videoClips = filter(clips,{type:'video'})
				videoClips.forEach((clip)=>{
				if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
					activeScreenVideoAtPlayhead = true
					if(clip.metadata.linkedClipId){
						activeScreenVideoAtPlayheadIsLinkedClip=true
					}
				}
			})
		}

		let slideClipAtPlayhead=null 
		if(!isPlaying){
			const slideClips = filter(clips,{type:'slide'})
				slideClips.forEach((clip)=>{
				if(currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)){
					slideClipAtPlayhead = clip
				}
			})
		}




		let showPMPanel = false
		if(this.props.user && this.props.user.email=='nicole@yarn.so'){
			if (process.env.NODE_ENV == 'development') {
				showPMPanel=true
			}
		}
		if(this.props.user && this.props.user.email=='jasper@yarn.so'){			
			showPMPanel=false
		}

		const {scenes,pixelsPerSec,maxTimelineDurationSeconds, showTranscriptPanel} = this.state
		const preventSelection = this.state.isDnDMode || this.state.isDragging || this.state.isDraggingProgressMarker
		const {dragClipNewStartTime} = this.state
		//const showMajorReleaseBanner = !this.props.userSettings.updateBannerSlidesDismissed
		const showMajorReleaseBanner = false
		const {slideEditorContextMenuPosition,slideEditorContextMenuElementId, activePanelClip}=this.state
		const showTemplateBar = activePanelClip && activePanelClip.type === 'slide'  // when slide is selected

		const {subtitlesType} = this.state


		const variableWebcams = sortBy(
			filter(clips, clip => clip.type === 'webcam' && clip.metadata?.isVariable === true),
			'startTime'
		)

		let isLoadingWebcamVoice = false 
		clips.forEach((clip)=>{
			if(clip.isLoadingVoice){
				isLoadingWebcamVoice = true
			}
		})
		if(this.state.isGeneratingVoiceMatch && !this.state.overrideVoiceMatch){
			isLoadingWebcamVoice = true
		}
		
		const readOnlyMode = this.state.readOnlyMode
		const { lockedUserFirstName, lockedAvatarUrl } = getLockedUserNameAndAvatar(this.props.project?.project_access_user?.current_user_id)

		let sceneWidth = 1920
		let sceneHeight = 1080

		if(this.state.aspectRatio === '16_10'){
			sceneWidth = 1920
			sceneHeight = 1241
		}
	

		let projectName = this.props.project?.name
		// scenes.forEach((scene)=>{
		// 	console.log('scene',scene.id,scene.sceneIndex)
		// })

		// clips.forEach((clip)=>{
		// 	if(clip.type === 'webcam'){
		// 	console.log(clip.segments)
		// 	}
		// })

		//console.log(this.props.projectId)
	
		return (            
			<Tooltip.Provider>
				
				<UpdatesMajorReleaseBanner 
	      	animateIn={true}
	      	showMajorReleaseBanner={showMajorReleaseBanner}
	      />

	      <RecordWebcamContainer 
	      	webcamRecordModalProps={this.state.webcamRecordModalProps}
	      	onClose={this.closeWebcamRecordModal}
	      />
	     

	      {slideEditorContextMenuElementId &&
	      <SlideEditorContextMenu 
	      	left={slideEditorContextMenuPosition.x}
	      	top={slideEditorContextMenuPosition.y}
	      	updateZOrder={this.handleContextMenuButtonClick}
	      	duplicateSlideItems={this.duplicateSlideItems}
	      	deleteSlideItems={this.deleteSlideItems}
	      	selectedSlideItems={this.state.selectedSlideItems}
	      	activePanelClip={this.state.activePanelClip}
	      	handleSetIsBGImage={this.handleSetIsBGImage}
	      	handleSetShowReplaceImageMediaModal={this.handleSetShowReplaceImageMediaModal}
	      	groupSlideItems={this.groupSlideItems}
	      />
	    }

				<div ref={this.editorRef} tabIndex="-1"  className={'editor ' + (isDragResizingMedia ? ' editor--isDragResizingMedia ' : '') + (isDragResizingNumberInput ? ' editor--isDragResizingNumberInput ' : ' editor--isNotDragResizingNumberInput ') + (showPMPanel ? ' editor--showPMPanel ' : '')} data-dragging-slide-editor-spacer={this.state.slideEditorDraggingSpacer ? this.state.slideEditorDraggingSpacer : false} data-dragging-to-reorder={this.state.isDraggingToReorder ? 'true' : 'false'} data-prevent-selection={preventSelection ? true : false} data-read-only={readOnlyMode ? true : false}>

					
					<div className='editor-tabBarSpacer'>
						<EditorStatusBar 
							projectBackgroundId={this.state.projectBackgroundId}
							handleUpdateProjectBackground={this.handleUpdateProjectBackground}
							defaultMotionStyle={this.state.defaultMotionStyle}
							handleUpdateDefaultMotionStyle={this.handleUpdateDefaultMotionStyle}
							projectId={this.props.projectId}
							isEmpty={clips.length == 0}
							subscriptionStatus={this.props.subscriptionStatus}
							refreshProjectVariables={this.refreshProjectVariables}
							projectVariables={this.state.projectVariables}
							variableWebcams={variableWebcams}
							//projectName={this.state.projectName}
							handleExportProject={this.handleExportProject}
							cancelExportProject={this.handleCancelExportProject}
							exportState={this.props.exportState}
							project={this.props.project}
							updateCollabPageSettings={this.updateCollabPageSettings}
							updateClipMetadata={this.updateClipMetadata}
							collabGenerations={this.state.collabGenerations}
							generationsDialogOpen={this.state.generationsDialogOpen}
							setGenerationsDialogOpen={this.setGenerationsDialogOpen}
							readOnlyMode={readOnlyMode}
						/>
					</div>
					<div style={{height: `${centerContainerHeight}px`}} className='editor-center'>

						{!showTranscriptPanel &&
							<button onClick={()=> this.setState({showTranscriptPanel: true})} className='editor-center-showTranscriptPanelBtn'>
								<div className='editor-center-showTranscriptPanelBtn-iconContainer'>
									<Icon name='sideNav' />
								</div>
								<div className='editor-center-showTranscriptPanelBtn-label'>
									Transcript
								</div>								
							</button>
						}

						{showTranscriptPanel &&
							<>
								<div className='editor-center-leftEdgeSpacer' />
								<div className='editor-center-leftContainer'>
									<div className='editor-center-panelContainer'>														
										<TranscriptPanel
											duration={duration} //pass this to update when duration changes
											key={this.state.voiceoverKey} //force reload when paste clips in
											audioTrackClips={sortedAudioTrackClips}      
											activeVoice={this.state.activeVoice}
											handleChangeActiveVoice={this.handleChangeActiveVoice}
											handleChangeVoiceoverPlaybackRate={this.handleChangeVoiceoverPlaybackRate}
											activeVoiceoverPlaybackRate={this.state.voiceoverPlaybackRate}
											transcriptPmManager={this.transcriptPmManager}
											scenes={scenes}
											transcriptChunksForDragHandles={this.state.transcriptChunksForDragHandles}
											transcriptSceneHeaders={this.state.transcriptSceneHeaders}
											transcriptWebcamHeaders={this.state.transcriptWebcamHeaders}
											hoveredTranscriptChunk={this.state.hoveredTranscriptChunk}
											handleTranscriptPanelChunkDrop={this.handleTranscriptPanelChunkDrop}
											transcriptChunkWithCursorInside={this.state.transcriptChunkWithCursorInside}
											playClipFromTranscript={this.playClipFromTranscript}
											recalculateAudioClip={this.recalculateAudioClip}
											previewingAudioClipId={this.state.previewingAudioClipId}
											cancelPreviewingAudioClip={this.cancelPreviewingAudioClip}
											mergeScene={this.mergeScene}
											addSceneAtEndOfProject={this.addSceneAtEndOfProject}
											addAudioClipAtEndOfProject={this.addAudioClipAtEndOfProject}
											hideTranscriptPanel={()=> this.setState({showTranscriptPanel: false})}
											subtitlesType={subtitlesType}
											handleChangeSubtitlesType={this.handleChangeSubtitlesType}
											handleGenerateSRTFile={this.handleGenerateSRTFile}
											regenerateAllSubtitles={this.regenerateAllSubtitles}
											projectName={projectName}
											projectId={this.props.projectId}
											deleteWebcamClipFromHeader={this.deleteWebcamClipFromHeader}
											setWebcamHasInstructionsFromHeader={this.setWebcamHasInstructionsFromHeader}
											recordWebcamClipFromHeader={this.recordWebcamClipFromHeader}
											voiceMatch={this.state.voiceMatch}
											variableWebcams={variableWebcams}
											skipMarkers={this.state.skipMarkers}
											restoreWebcamSkip={this.restoreWebcamSkip}
											transcriptSelectionRects={this.state.transcriptSelectionRects}
											changeAspectRatio={this.changeAspectRatio}
											aspectRatio={this.state.aspectRatio}
											isLoadingWebcamVoice={isLoadingWebcamVoice}
											overrideVoiceMatch={this.state.overrideVoiceMatch}
											unlinkScreencast={this.unlinkScreencast}
											convertWebcamRecordingIntoScript={this.convertWebcamRecordingIntoScript}
											convertWebcamRecordingIntoVoiceover={this.convertWebcamRecordingIntoVoiceover}
											hideInactiveCursor={this.state.hideInactiveCursor}
											toggleHideInactiveCursor={this.toggleHideInactiveCursor}
											activeTranscriptPanelAudioChunk={this.state.activeTranscriptPanelAudioChunk}
											convertAudioClipIntoScript={this.convertAudioClipIntoScript}
											handleSeekToClipInTimelineWithScroll={this.handleSeekToClipInTimelineWithScroll}

										/>
										{/* THIS IS FOR GRAPH ONLY -- PORT TO DETAIL PANEL */}
										<EditorRightPanelContainer 
											activePanelClip={this.state.activePanelClip}
											deleteClip={this.deleteClip}
											projectBackgroundId={this.state.projectBackgroundId}
											updateChartClip={this.updateChartClip}
											defaultMotionStyle={this.state.defaultMotionStyle}
											updateChartBackgroundColor={this.updateChartBackgroundColor}
										/>
									</div>
								</div>  
							</> 
						} 

						{showPMPanel && 
							<div className='editor-devPanel'>
								<PMPanel
									pmDoc={this.state.pmDoc}
									transcriptPmDoc={this.state.transcriptPmDoc}
								/>
							</div>
						}
						
						<div className='editor-center-center'>
							<div onClick={this.clearSlideElementsSelection} className='editor-backgroundClick' />
							<div className='editor-center-center-templateBarContainer'>
								{showTemplateBar && !readOnlyMode &&
									<>
									<TemplateBar 
										useSlideTemplate={this.useSlideTemplate}
										projectBackgroundId={this.state.projectBackgroundId}
										currentSlideBackgroundId={activePanelClip.metadata.backgroundId}
										saveSlideAsTemplate={this.saveSlideAsTemplate}
										is1610={this.state.aspectRatio === '16_10'}
									/>				
									<div className='editor-center-center-templateBarContainer-edgeContainer'/>													
									</>
								}
	{!readOnlyMode &&
								<EditorTopBar 
									activePanelClip={this.state.activePanelClip}
									updateClipAnimationSettings={this.updateClipAnimationSettings}
									updateClipMetadata={this.updateClipMetadata}
									updateSlideClipTextStyle={this.updateSlideClipTextStyle}
									activeZoomClip={false}
									meshWidth={meshWidth}
									meshHeight={meshHeight}									
									updateZoomValues={this.updateZoomValues}
									updateZoomBox={this.updateZoomBox}
									deleteClip={this.deleteClip}
									previewAnimation={this.previewAnimation}
									previewClip={this.state.previewClip}
									previewType={this.state.previewType}
									resetPreview={this.resetPreview}
									projectBackgroundId={this.state.projectBackgroundId}
									updateTextSlideTextColor={this.updateTextSlideTextColor}
									updateTextSlideBackgroundColor={this.updateTextSlideBackgroundColor}
									handleDeactivateActiveClip={this.handleDeactivateActiveClip}
									getClipForId={this.getClipForId}
									trimMode={this.state.trimMode}
									toggleTrimMode={this.toggleTrimMode}
									updateChartClip={this.updateChartClip}
									defaultMotionStyle={this.state.defaultMotionStyle}
									updateChartBackgroundColor={this.updateChartBackgroundColor}
									updateClipBackgroundColor={this.updateClipBackgroundColor}
									changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
									addSlideElement={this.addSlideElement}
									selectedSlideItems={this.state.selectedSlideItems}
									updateSlideElementMetadata={this.updateSlideElementMetadata}
									insertImageFromRecent={this.insertImageFromRecent}	
									updateSlideBackgroundColor={this.updateSlideBackgroundColor}
									updateSlideAlignment={this.updateSlideAlignment}				
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}	
									addZoom={this.addZoom}	
									handleAlignSlideElements={this.handleAlignSlideElements}	
									alignSlideItems={this.alignSlideItems}	
									createAutoZooms={this.createAutoZooms}
									aiZoomStatus={this.aiZoomStatus(this.state.activePanelClip)}
									cursorZoomStatus={this.cursorZoomStatus(this.state.activePanelClip)}
								/>
	}

								{this.props.project && readOnlyMode &&
									<EditorReadOnlyToggle onTakeControl={this.onTakeControl} onRefresh={this.refreshProject} lockedUserFirstName={lockedUserFirstName} lockedAvatarUrl={lockedAvatarUrl} />
								}
							</div>
							
							<div className='editor-center-center-mainContainer'>
								<EditorCanvasOuterContainer 
									currentTime={currentTime}
									clips={clips}
									textSlideClips = {filter(clips, {zIndex: 1, type: 'textSlide'})}
									slideClips = {filter(clips, {zIndex: 1, type: 'slide'})}
									audioClips = {filter(clips, {type: 'audio'})}
									imageClips = {filter(clips, {zIndex: 1, type: 'image'})}
									chartClips = {filter(clips, {type: 'chart'})}
									videoClips = {filter(clips, {type:'video'})}
									webcamClips = {filter(clips, {type:'webcam'})}
									zoomClips = {filter(clips, {zIndex: 2})}
									isPlaying = {isPlaying}
									updateSlideTextElementText={this.updateSlideTextElementText}
									activePanelClip={this.state.activePanelClip}
									handleSetActivePanelClip={this.handleSetActivePanelClip}
									updateMeshWidth={this.updateMeshWidth}
									updateMeshHeight={this.updateMeshHeight}
									zoomBox={zoomBox}
									updateZoomBox={this.updateZoomBox}
									updateZoomValues={this.updateZoomValues}
									projectBackgroundId={this.state.projectBackgroundId}
									textSlideEditorKey={this.state.textSlideEditorKey}
									trimMode={this.state.trimMode}
									toggleTrimMode={this.toggleTrimMode}
									trimPreviewPlaying={this.state.trimPreviewPlaying}
									trimPreviewProgress={this.state.trimPreviewProgress}
									onTrimPreviewPlayPause={this.onTrimPreviewPlayPause}
									onTrimPreviewSeekChange={this.onTrimPreviewSeekChange}   
									isDraggingProgressMarker={this.state.isDraggingProgressMarker}    
									activeBrand={this.state.activeBrand} 		
									testKey={this.state.testKey}			        					
									updateClipDisplayWidth={this.updateClipDisplayWidth}
									isDragResizingMedia={this.state.isDragResizingMedia}
									startDragResizingMedia={()=>{this.setState({isDragResizingMedia: true})}}
									stopDragResizingMedia={()=>{this.setState({isDragResizingMedia: false})}}
									setSlideEditorSelection={this.setSlideEditorSelection}
									selectedSlideItems={this.state.selectedSlideItems}
									updateSlideElementMetadata={this.updateSlideElementMetadata}
									clearSlideElementsSelection={this.clearSlideElementsSelection}
									selectedLayoutGroup={this.state.selectedLayoutGroup}
									updateSlideElementField={this.updateSlideElementField}
									setFocusedSlideElement={this.setFocusedSlideElement}
									focusedSlideElement={this.state.focusedSlideElement}
									duplicateSlideItems={this.duplicateSlideItems}
									openSlideEditorContextMenu={this.openSlideEditorContextMenu}
									handleSetSlideEditorDragElement={this.handleSetSlideEditorDragElement}
									slideEditorDragElementId={this.state.slideEditorDragElementId}
									slideEditorDragLayoutGroupId={this.state.slideEditorDragLayoutGroupId}
									updateSlideElementTextSelectionInfo={this.updateSlideElementTextSelectionInfo}
									handleSetSlideEditorResizeElement={this.handleSetSlideEditorResizeElement}
									slideEditorResizeElementId={this.state.slideEditorResizeElementId}
									slideEditorDraggingSpacer={this.state.slideEditorDraggingSpacer}
									setSlideEditorDraggingSpacer={(value)=>{this.setState({ slideEditorDraggingSpacer: value })}}
									handleSyncSlideChanges={this.handleSyncSlideChanges}
									showCaptions={this.state.showCaptions}
									setIsDraggingToReorder={this.setIsDraggingToReorder}
									isDraggingToReorder={this.state.isDraggingToReorder}
									groupSlideItems={this.groupSlideItems}
									ungroupLayoutGroup={this.ungroupLayoutGroup}
									handleSlideDragOrResizeStart={this.handleSlideDragOrResizeStart}
									handleSlideDragOrResizeEnd={this.handleSlideDragOrResizeEnd}
									handleUpdateCanvasFromSlideEditor={this.handleUpdateCanvasFromSlideEditor}
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}
									subtitlesType={subtitlesType}
									updateClipMetadata={this.updateClipMetadata}
									isDraggingWebcam={this.state.isDraggingWebcam}
									setIsDraggingWebcam={this.setIsDraggingWebcam}
									isResizingWebcam={this.state.isResizingWebcam}
									setIsResizingWebcam={this.setIsResizingWebcam}
									readOnlyMode={readOnlyMode}
									sceneWidth={sceneWidth}
									sceneHeight={sceneHeight}
									key={`sceneHeight_${sceneHeight}`}
									videoWindowPadding={this.state.videoWindowPadding}
									isVideoResizeModeActive={this.state.isVideoResizeModeActive}
									preVideoResizeSnapshot={this.state.preVideoResizeSnapshot}
								/>		


{/*

								<EditorShortcutsPopover
			            showModal={this.state.showShortcutsPopover}   
			            closeModal={this.closeShortcutsPopover}         
			          >
			            <div className='editor-center-center-mainContainer-shortcutsPopoverTrigger' />						                  
			          </EditorShortcutsPopover>

			          */}

			          <div className='editor-center-center-detailPanelContainer'>
									<EditorDetailPanel 
										activePanelClip={this.state.activePanelClip}
										isDragResizingNumberInput={isDragResizingNumberInput}
										setIsDragResizingNumberInput={(value) => this.setState({ isDragResizingNumberInput: value })}
										updateSlideAlignment={this.updateSlideAlignment}
										selectedSlideItems={this.state.selectedSlideItems}
										groupSlideItems={this.groupSlideItems}
										handleDimensionsUpdatedFromDetailPanel={this.handleDimensionsUpdatedFromDetailPanel}
										updateSlideElementAnimationIndex={this.updateSlideElementAnimationIndex}
										updateSlideBackgroundColor={this.updateSlideBackgroundColor}
										updateClipAnimationSettings={this.updateClipAnimationSettings}
										updateClipMetadata={this.updateClipMetadata}
										updateSlideClipTextStyle={this.updateSlideClipTextStyle}
										activeZoomClip={false}
										meshWidth={meshWidth}
										meshHeight={meshHeight}									
										updateZoomValues={this.updateZoomValues}
										updateZoomBox={this.updateZoomBox}
										deleteClip={this.deleteClip}
										previewAnimation={this.previewAnimation}
										previewClip={this.state.previewClip}
										previewType={this.state.previewType}
										resetPreview={this.resetPreview}
										projectBackgroundId={this.state.projectBackgroundId}
										updateTextSlideTextColor={this.updateTextSlideTextColor}
										updateTextSlideBackgroundColor={this.updateTextSlideBackgroundColor}
										getClipForId={this.getClipForId}
										trimMode={this.state.trimMode}
										toggleTrimMode={this.toggleTrimMode}
										updateChartClip={this.updateChartClip}
										defaultMotionStyle={this.state.defaultMotionStyle}
										updateChartBackgroundColor={this.updateChartBackgroundColor}
										updateClipBackgroundColor={this.updateClipBackgroundColor}
										changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
										addSlideElement={this.addSlideElement}
										updateSlideElementMetadata={this.updateSlideElementMetadata}
										insertImageFromRecent={this.insertImageFromRecent}			
										handleSlideImageFileUpload={this.handleSlideImageFileUpload}	
										updateSlideElementZOrder={this.updateSlideElementZOrder}
										selectedLayoutGroup={this.state.selectedLayoutGroup}
										updateLayoutGroupField={this.updateLayoutGroupField}
										focusedSlideElement={this.state.focusedSlideElement}
										slideElementTextSelectionInfo={this.state.slideElementTextSelectionInfo}
										ungroupLayoutGroup={this.ungroupLayoutGroup}
										projectId={this.props.projectId}
										updateSlideTextElementTextProperties={this.updateSlideTextElementTextProperties}
										showReplaceImageMediaModal={this.state.showReplaceImageMediaModal}
										handleSetShowReplaceImageMediaModal={this.handleSetShowReplaceImageMediaModal}
										updateTranscripPanelFromTimeline={this.updateTranscripPanelFromTimeline}
										readOnlyMode={readOnlyMode}
										sceneWidth={sceneWidth}
									sceneHeight={sceneHeight}
									currentTime={currentTime}
									slideClips={this.state.slideClips}
									videoClips={this.state.videoClips}
									unlinkScreencast={this.unlinkScreencast}
									audioClips = {filter(clips, {type: 'audio'})}
									webcamClips = {filter(clips, {type:'webcam'})}
										setVideoWindowPadding={this.setVideoWindowPadding}
										applyBulletPointsToSlide={this.applyBulletPointsToSlide}
									/>
								</div>
							</div>
							
							{/*}
							<div className='editor-center-center-topbarContainer'>

							</div>
							*/}

							<div className='editor-center-center-toolbarContainer'>
								<EditorToolbar
									duration={duration}
									currentTime={currentTime}
									isPlaying={isPlaying}
									isPreviewing={this.state.isPreviewing}
									prePreviewState={this.state.prePreviewState}
									togglePlayPause={this.togglePlayPause}
									addSlide={this.addSlide}
									addRecordingFromLibrary={this.addRecordingFromLibrary}
									addZoom={this.addZoom}
									handleSeek={this.handleSeek}
									splitRecordingClip={this.splitRecordingClip}
									splitScene={this.splitScene}
									activeScreenVideoAtPlayhead={activeScreenVideoAtPlayhead}
									isEmpty={clips.length == 0}						
									handleNewVideoFileUpload={this.handleNewVideoFileUpload}
									insertImageFromRecent={this.insertImageFromRecent}
									insertVideoFromRecent={this.insertVideoFromRecent}
									userIsProMode={this.props.userIsProMode}
									isRyanDriverAI={this.props.isRyanDriverAI}
									isDnDMode={this.state.isDnDMode}
									addFreezeFrame={this.addFreezeFrame}
									addSkipSegment={this.addSkipSegment}
									addSlideElement={this.addSlideElement}
									activePanelClip={this.state.activePanelClip}
									handleSlideImageFileUpload={this.handleSlideImageFileUpload}
									handleNewWebcamFileUpload={this.handleNewWebcamFileUpload}
									slideClipAtPlayhead={slideClipAtPlayhead}
									readOnlyMode={readOnlyMode}
									insertScreencastFromRecent={this.insertScreencastFromRecent}
									openScreenshareSwiftApp={this.openScreenshareSwiftApp}
									isAddSkipSegmentModeActive={this.state.isAddSkipSegmentModeActive}
									toggleAddSkipSegmentMode={this.toggleAddSkipSegmentMode}
									addWebcamFromLibrary={this.addWebcamFromLibrary}
									activeScreenVideoAtPlayheadIsLinkedClip={activeScreenVideoAtPlayheadIsLinkedClip}
								/>
								{this.state.trimMode && this.state.activePanelClip && (this.state.activePanelClip.captureId || this.state.activePanelClip.isBasicVideo || this.state.activePanelClip.type=='webcam') &&
									<EditorTrimBar 
										trimPreviewPlaying={this.state.trimPreviewPlaying}
										trimPreviewProgress={this.state.trimPreviewProgress}
										onTrimPreviewPlayPause={this.onTrimPreviewPlayPause}
										onTrimPreviewSeekChange={this.onTrimPreviewSeekChange}
										clip={this.state.activePanelClip}
										updateClipTrimValues={this.updateClipTrimValues}
									/>
								}
							</div>
						</div>


						
					</div>


					{/*}
					<div className='editor-projectIDDev'>
						{this.props.projectId}
					</div>
					*/}


					
					<div style={{height: `${timelineHeight}px`}} className='editor-timelinePanel'>						
						{this.state.trimMode && 
							<div className='editor-timelinePanel-trimModeBlocker' />
						}
						
						<ScenesTimeline 
							scenes={scenes}
							pixelsPerSec={pixelsPerSec}  
							clips={clips}
							onDragStart={this.onDragStart}
							onDragEnd={this.onDragEnd}
							isDragging={this.state.isDragging}
							dragClipId={this.state.dragClipId}
							handleDragClip={this.handleDragClip}
							isDnDMode={this.state.isDnDMode}
							onResizeStart={this.onResizeStart}
							onResizeStop={this.onResizeStop}
							onResize={this.onResize}
							duration={this.state.duration}
							currentTime={currentTime}
							maxTimelineDurationSeconds={maxTimelineDurationSeconds}
							handleSeek={this.handleSeek}
							duplicateClip={this.duplicateClip}
							deleteClip={this.deleteClip}
							copyClip={this.copyClip}
							cutClip={this.cutClip}
							changeVideoClipPlaybackRate={this.handleChangeVideoClipPlaybackRate}
							handleChangeFreezeSegmentPlaybackRate={this.handleChangeFreezeSegmentPlaybackRate}
							addFreezeFrame={this.addFreezeFrame}
							handleSetActivePanelClip={this.handleSetActivePanelClip}
							activePanelClip={this.state.activePanelClip}
							removeFreeze={this.removeFreeze}
							removeSkip={this.removeSkip}
							splitRecordingClip={this.splitRecordingClip}
							dragClipNewStartTime={dragClipNewStartTime}
							mergeScene={this.mergeScene}
							splitScene={this.splitScene}
							addSkipSegment={this.addSkipSegment}
							addSceneAfterScene={this.addSceneAfterScene}							
							timelineCmdIsDown={false} // temp
							toggleSkipSegment={this.toggleSkipSegment}
							handleChangeSkipSegmentDuration={this.handleChangeSkipSegmentDuration}
							isResizingSkipSegment={this.state.resizingSkipSegmentHandle!=null}
							resizingSkipSegmentHandle={this.state.resizingSkipSegmentHandle}
							handleResizeSkipSegmentStart={this.handleResizeSkipSegmentStart}
							handleResizeSkipSegmentStop={this.handleResizeSkipSegmentStop}
							preSkipResizeCurrentTime={this.state.preSkipResizeCurrentTime}
							isVideoResizeModeActive={this.state.isVideoResizeModeActive}
							preVideoResizeSnapshot={this.state.preVideoResizeSnapshot}
							scrollRef={this.scrollContainerRef}
							isPlaying={this.state.isPlaying}
							calculateAndSetDropLines={this.calculateAndSetDropLines}
							dropLines={this.state.dropLines}
							setIsDraggingProgressMarker={this.setIsDraggingProgressMarker}
							isSlideEditorDragResizing={this.state.isSlideEditorDragResizing}
							isResizingWebcam={this.state.isResizingWebcam}
							isDraggingWebcam={this.state.isDraggingWebcam}
							readOnlyMode={readOnlyMode}
							unlinkScreencast={this.unlinkScreencast}
							updateClipMetadata={this.updateClipMetadata}
							isAddSkipSegmentModeActive={this.state.isAddSkipSegmentModeActive}
							preAddSkipCurrentTime={this.state.preAddSkipCurrentTime}
							toggleAddSkipSegmentMode={this.toggleAddSkipSegmentMode}
							addSkipSegmentModeClip={this.state.addSkipSegmentModeClip}
							handleAddSkipSegmentFromSkipSegmentMode={this.handleAddSkipSegmentFromSkipSegmentMode}
							showWebcamPhasesForClipId={this.state.showWebcamPhasesForClipId}
							setShowWebcamPhasesForClipId={this.setShowWebcamPhasesForClipId}
							handleTrimToClipEdge={this.handleTrimToClipEdge}
							handleTimelineDoubleClick={this.handleTimelineDoubleClick}
						/>
						

						<EditorTimelineFooter 
							handleTimelineZoomChange={this.handleTimelineZoomChange}
							updateBackgroundMusicTrack={this.updateBackgroundMusicTrack}
							backgroundMusicVolume={this.state.backgroundMusicVolume}
							updateBackgroundMusicVolume={this.updateBackgroundMusicVolume}
							activeBackgroundMusicTrack = {this.state.backgroundMusicTrack}
							initialSliderValue={defaultTimelineZoom}
							onZoomToFit={this.handleTimelineZoomToFit}
							isEmpty={clips.length == 0}
							userIsProMode={this.props.userIsProMode}
							handleNewMusicFileUpload={this.handleNewMusicFileUpload}
							addSceneToTimeline={this.addSceneToTimeline}
							deleteScene={this.deleteScene}
							timelineCmdIsDown={false} // temp
							showShortcutsPopover={this.state.showShortcutsPopover}
							openShortcutsPopover={this.openShortcutsPopover}
							closeShortcutsPopover={this.closeShortcutsPopover}
							readOnlyMode={readOnlyMode}
							soundEffectsSettings={this.state.soundEffectsSettings}
							updateSoundEffectsSettings={this.updateSoundEffectsSettings}
							isAddSkipSegmentModeActive={this.state.isAddSkipSegmentModeActive}
							toggleAddSkipSegmentMode={this.toggleAddSkipSegmentMode}
							handleAddSkipSegmentFromSkipSegmentMode={this.handleAddSkipSegmentFromSkipSegmentMode}
							seekFromShortcut={this.seekFromShortcut}
							skipSegmentModeFromShortcut={this.state.skipSegmentModeFromShortcut}
						/>

					</div>      
				</div>
			</Tooltip.Provider>
		)
	}

	setIsDraggingProgressMarker = (isDragging) => {
		if (isDragging) {
			this.setState({isDraggingProgressMarker: true})
		} else {
			this.setState({isDraggingProgressMarker: false}, () => {
				this.setActivePanelClipFromCurrentTime()
			})
		}
	}
}

function mapStateToProps(state,ownProps) {
	const project = find(state.projects,{id:`${ownProps.projectId}`})
	let subscriptionStatus=state.organization.subscription_status 
	let userIsProMode=false 
	if(state.user && state.user.is_pro_mode){
		userIsProMode=true
	}
	let isRyanDriverAI =false 
	if(state.user && state.user.id==10){
		isRyanDriverAI=true
	}
	const voiceMatchResult = state.voiceMatch?.results[ownProps.projectId];
	const exportState = state.projectExports[ownProps.projectId];
	const currentUser=state.user
	let userId = null
	if(currentUser){
		userId=currentUser.id
	}
	return {
		project:project,
		subscriptionStatus:subscriptionStatus,
		userIsProMode:userIsProMode,
		isRyanDriverAI:isRyanDriverAI,
		userSettings:state.userSettings,
		user:state.user,
		copiedClip: state.clipActions.copiedClip,
		exportState: exportState,
		webcamUploads: state.pendingWebcamUploads || {}, // Add this
		screenRecordings: state.screenRecordings || [],
		voiceMatchResult:voiceMatchResult,
		userId:userId,
		// Add the webcam file statuses from our new reducer
		webcamFileStatuses: state.webcamFiles.fileStatuses || {}
	}
}
export default connect(
		mapStateToProps,
		{
			updateProjectBackground,
			syncNewScreenRecording,
			syncNewRecording,
			updateProjectDefaultMotionStyle,
			uploadImageAndHandleResponse,
			uploadVideoAndHandleResponse,
			updateImageInsertCount,
			updateImageDefaultDisplayWidth,
			updateVideoInsertCount,
			updateVideoDefaultDisplayWidth,
			updateProMode,
			uploadMusicAndHandleResponse,
			fetchSingleProject,
			setCopiedClip,
			saveSlideAsTemplate,
			uploadWebcamRecording,
			updateProjectSubtitlesType,
			updateProjectCollabPageSettings,
			createVoiceMatchForProject,
			fetchProjectCollabGenerations,
			triggerAutoZoom: checkScreenRecordingAutoZoom,
			syncNewRecordingSession,
			setPendingSessionCapture,
			syncRecordingSessionWebcam,
			triggerAutoZoom: checkScreenRecordingAutoZoom,
			syncUserUploadedWebcam
	}
)(Editor)



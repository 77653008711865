const WebcamRecordingChunkNodeSpec = {
	group: "block",
	attrs: {
		clipId: { default: 'defaultID' },
		sceneId: { default: 'defaultID' },
		// firstIndex: { default: null },
		// lastIndex: { default: null },
		startTime: { default: null },
		endTime: { default: null },
	},
	content: "webcamTranscriptItem*",
	toDOM: function(node) {
		return ["webcamRecordingChunk",{}, 0];
	},
	parseDOM: [{ tag: "webcamRecordingChunk" }]
};

export default WebcamRecordingChunkNodeSpec;
import React, {Component} from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import pusherInstance from '../pusherInstance'
import {handleUpdateProjectAccessUserPusher,fetchProjects,receiveVoiceMatch,fetchSingleProject} from '../actions/projects'
import {webcamProcessingComplete,handleWebcamProcessingFieldUpdate} from '../actions/webcamRecording'
import {fetchScreenRecordings} from '../actions/screenRecordings'
import {fetchRecordings} from '../actions/recordings'
import {fetchCollections,fetchProjectCollections} from '../actions/collections'
import {fetchUploadedImages} from '../actions/imageUpload'
import {fetchUploadedVideos} from '../actions/videoUpload'
import {fetchOrganization} from '../actions/organization'
import {getCurrentUserId} from '../utils/getCurrentUserId'
import debounce from 'lodash/debounce'
import { downloadScreenRecordingChunksForCaptureId } from '../utils/assets/downloadMissingScreenRecordings'
import { fetchSingleScreenRecording } from '../actions/screenRecordings'


class OrgPusherContainer extends Component {

	constructor(){
		super()
		this.connectedToPusher=false
		this.debouncedFetchProjects = null

	}


	componentDidMount() {
		this.debouncedFetchProjects = debounce(this.props.fetchProjects, 4000)
	}


	componentDidUpdate(prevProps) {
		if(!this.connectedToPusher && this.props.organization && this.props.organization.id ){
			this.subscribeToOrganisationPusherChannel()
		}
	}

	subscribeToOrganisationPusherChannel(){
		const channel = pusherInstance.subscribe(`organization-${this.props.organization.id}`);
		channel.bind('project-access-user-update', this.handleProjectAccessUserUpdate) 
		channel.bind('new-project', this.handleNewProjectPusher) 
		channel.bind('project-updated', this.handleProjectUpdatedPusher) 
		channel.bind('subscription-updated', this.handleSubscriptionUpdatedPusher) 
		channel.bind('collection-updated', this.handleCollectionUpdated) 
		channel.bind('project-collection-updated', this.handleProjectCollectionUpdated) 
		channel.bind('screen-recording-chunks-processed', this.handleScreenRecordingChunksProcessed)
		channel.bind('webcam-processing-step-complete',this.handleWebcamProcessingUpdate)
		channel.bind('webcam-processing-complete',this.handleWebcamProcessingComplete)
		channel.bind('project-voice-match',this.handleVoiceMatchProcessed)
		channel.bind('screen-recording-uploaded-to-cloudinary',this.handleScreenRecordingUploadedToCloudinary)
		channel.bind('auto-zooms-created',this.handleAutoZoomComplete)
		this.connectedToPusher=true
	}


	handleWebcamProcessingUpdate=(pusherData)=>{
		const { captureId, step } = pusherData;
	//	console.log('webcam processing update',captureId,step)
		//console.log(pusherData)
		if (this.props.handleWebcamProcessingFieldUpdate) {
			this.props.handleWebcamProcessingFieldUpdate(captureId, step);
		}
	}

	handleScreenRecordingUploadedToCloudinary=(pusherData)=>{
		this.props.fetchScreenRecordings()
	}

	handleVoiceMatchProcessed=(pusherData)=>{
		const { projectId, requestId, sources,voiceId } = pusherData;
    this.props.receiveVoiceMatch(projectId, requestId, sources,voiceId);
	}


	handleWebcamProcessingComplete=(pusherData)=>{
	//	console.log('webcam processing complete',pusherData)
		this.props.webcamProcessingComplete(pusherData.captureId,pusherData.webcam);
	}

	handleScreenRecordingChunksProcessed=(pusherData)=>{
		this.props.fetchSingleScreenRecording(pusherData.captureId)
	}

	handleProjectAccessUserUpdate=(pusherData)=>{
		this.props.handleUpdateProjectAccessUserPusher(pusherData.project_id, pusherData.current_user_id, pusherData.last_opened)
	}

	handleCollectionUpdated=(pusherData)=>{
		const updatedBy=pusherData.updatedBy
		if(!updatedBy || updatedBy!==getCurrentUserId()){ //dont fetch if its you
			this.props.fetchCollections()
		}
	}


	handleProjectCollectionUpdated=()=>{
		this.props.fetchProjectCollections()
	}

	handleProjectUpdatedPusher=(pusherData)=>{ //TODO fetch individual project
		const updatedBy=pusherData.updatedBy
	//	console.log('pusherdata',pusherData)
		if(!updatedBy || updatedBy!==getCurrentUserId()){ //dont fetch if its you
			// this.debouncedFetchProjects()
			this.props.fetchSingleProject(pusherData.id)
		}
	}

	handleSubscriptionUpdatedPusher=()=>{
		this.props.fetchOrganization()
	}


	handleNewProjectPusher=(pusherData)=>{
		this.props.fetchSingleProject(pusherData.id)
	}

	handleAutoZoomComplete=(pusherData)=>{
		this.props.fetchSingleScreenRecording(pusherData.captureId)
	}

	render() {
		return null
	}
}


const mapStateToProps = (state) => ({
	organization:state.organization,
});

export default withRouter(connect(
	mapStateToProps,
	{	
	handleUpdateProjectAccessUserPusher,
	fetchProjects,
	fetchRecordings,
	fetchOrganization,
	fetchUploadedImages,
	fetchUploadedVideos,
	fetchCollections,
	webcamProcessingComplete,
	receiveVoiceMatch,
	fetchProjectCollections,
	fetchSingleProject,
	fetchScreenRecordings,
	fetchSingleScreenRecording,
	handleWebcamProcessingFieldUpdate
	})(OrgPusherContainer))

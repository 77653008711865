import React, { useState, useEffect } from 'react';
import Icon from '../misc/Icon'
import RecordWebcamToolbar from './RecordWebcamToolbar'
import RecordWebcamCameraSelect from './RecordWebcamCameraSelect'

const RecordWebcamScriptCameraPreview = ({
  videoRef,    
  isDisplayRecording,
  activeCamera,
  setActiveCamera,
  cameras
}) => {

  const [expanded, setExpanded] = useState(false);

  return (    
    <>
    <div data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} data-expanded-state={expanded ? 'expanded' : 'default'} className='recordWebcamWindow-main--script-preview-videoContainer'>
      <video 
        ref={videoRef} 
        autoPlay 
        playsInline 
        muted
        className="recordWebcamWindow-main--script-preview-video"
        onClick={() => setExpanded(!expanded)} 
      />      
      <RecordWebcamCameraSelect 
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        cameras={cameras}
        isDisplayRecording={isDisplayRecording}
        viewType='script'
      />
    </div>
    
    </>
  );
};

export default RecordWebcamScriptCameraPreview;
import { Plugin, TextSelection } from 'prosemirror-state'

const isAtStartOfFirstChunk = (state, $pos) => {
  // Find first chunk in document
  let firstChunkPos = null;
  let firstChunk = null;
  state.doc.descendants((node, pos) => {
    if (firstChunkPos === null && 
        (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk')) {
      firstChunkPos = pos;
      firstChunk = node;
      return false;
    }
  });
  if (!firstChunk) return false;
  
  // Check if we're in first chunk and at its start
  let currentChunkPos = null;
  state.doc.nodesBetween($pos.pos, $pos.pos, (node, pos) => {
    if (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') {
      currentChunkPos = pos;
      return false;
    }
  });
  return currentChunkPos === firstChunkPos && $pos.parentOffset === 0;
};

const hasOnlyOneScene = (state) => {
  let sceneCount = 0;
  state.doc.descendants(node => {
    if (node.type.name === 'sceneHeader') {
      sceneCount++;
      if (sceneCount > 1) return false;
    }
  });
  return sceneCount === 1;
};

const isFirstLineOfFirstChunk = (view) => {
  const { state } = view;
  const { selection } = state;
  const { $from } = selection;
  
  // Get current chunk we're in
  let currentChunk = null;
  let currentChunkPos = null;
  state.doc.nodesBetween($from.pos, $from.pos, (node, pos) => {
    if (node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') {
      currentChunk = node;
      currentChunkPos = pos;
      return false;
    }
  });
  if (!currentChunk) return false;
  
  // Check if this is the first chunk
  let isFirstChunk = false;
  state.doc.descendants((node, pos) => {
    if (pos >= currentChunkPos) return false;
    
    if (node.type.name === 'sceneHeader') {
      isFirstChunk = true;
      return false;
    }
    
    if ((node.type.name === 'transcriptChunk' || node.type.name === 'webcamRecordingChunk') 
        && pos !== currentChunkPos) {
      isFirstChunk = false;
      return false;
    }
  });
  
  if (!isFirstChunk) return false;
  
  const cursorCoords = view.coordsAtPos($from.pos);
  const chunkStartCoords = view.coordsAtPos(currentChunkPos + 1);
  const isFirstLine = Math.abs(cursorCoords.top - chunkStartCoords.top) < 5;
  return isFirstLine;
};

export const arrowPlugin = new Plugin({
  props: {
    handleKeyDown: (view, event) => {
      const { state } = view;
      const { selection } = state;

      if (event.shiftKey) {
        return false;
      }

      if (event.key === 'ArrowLeft') {
        if (isAtStartOfFirstChunk(state, selection.$from) && hasOnlyOneScene(state)) {
          // If there's a selection, clear it but prevent cursor movement
          if (!selection.empty) {
            event.preventDefault();
            // Clear the selection by creating a cursor at the current position
            const $cursor = selection.$from;
            view.dispatch(state.tr.setSelection(TextSelection.create(state.doc, $cursor.pos)));
            return true;
          }
          // No selection, just prevent the cursor movement
          event.preventDefault();
          return true;
        }
      }
      else if (event.key === 'ArrowUp') {
        if (isFirstLineOfFirstChunk(view) && hasOnlyOneScene(state)) {
          if (!selection.empty) {
            event.preventDefault();
            const $cursor = selection.$from;
            view.dispatch(state.tr.setSelection(TextSelection.create(state.doc, $cursor.pos)));
            return true;
          }
          event.preventDefault();
          return true;
        }
      }
      
      return false;
    }
  }
});
import { getMasterRecordingData } from './getMasterRecordingData';
import { getNormalizedCursorData } from './getNormalizedCursorData';
import { getNormalizedCursorDataForWindow } from './getNormalizedCursorDataForWindow';
import { getSmoothedCursorData } from './getSmoothedCursorData';
import { getTrimInterpolatedCursorData } from './getTrimInterpolatedCursorData'
import { binarySearchNearest } from '../../../three/utils/animations/binarySearchNearest'
import resizeDeviceToFit from '../../../three/utils/resizeDeviceToFit'
import resizeWindowDeviceToFit from '../../../three/utils/resizeWindowDeviceToFit'
import resizeDesktopToFit from '../../../three/utils/resizeDesktopToFit'
import { calulateVideoTimeFromTimelineTime } from '../../../timeline/utils/calulateVideoTimeFromTimelineTime'
import { CursorOpacityManager } from './getCursorOpacity';
import { getSkipSegmentsForClip } from './getSkipSegmentsForClip'


let clipsData = {};

export const CHROME_OFFSET_DEFAULT = 87;
export const CHROME_OFFSET_BOOKMARKS_BAR = 120;

export const legacyCalculateMeshScale = (masterRecordingData,screenVideoApp,screenVideoDeviceFrame, sceneWidth, sceneHeight, videoWindowPadding) => {
	let chromeHeightOffset = 0
  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'color'){
    chromeHeightOffset = CHROME_OFFSET_DEFAULT
  }
  if(screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'colorBB'){
    chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR
  }
	const data = resizeDeviceToFit(
		masterRecordingData.recordingWidth,
		masterRecordingData.recordingHeight - chromeHeightOffset,
		videoWindowPadding,
		sceneWidth,
		sceneHeight
	);
	return masterRecordingData.recordingWidth > 0 ? data.meshWidth / masterRecordingData.recordingWidth : 1;
};

export const desktopModeModeCalculateMeshScale = (recordingWidth, recordingHeight, sceneWidth, sceneHeight) => {
	const data = resizeDesktopToFit(
		recordingWidth,
		recordingHeight,
		sceneWidth,
		sceneHeight
	);
	return recordingWidth > 0 ? data.meshWidth / recordingWidth : 1;
};

export const windowModeCalculateMeshScale = (windowWidth, windowHeight, sceneWidth, sceneHeight, videoWindowPadding, recordingPointScale) => {
	const data = resizeWindowDeviceToFit(
		windowWidth,
		windowHeight,
		videoWindowPadding,
		sceneWidth,
		sceneHeight,
		recordingPointScale
	);
	return windowWidth > 0 ? data.meshWidth / windowWidth : 1;
};

/*
  The unified computeCursorDataForClips now takes the scene dimensions so that
  the scaling (meshScale) can be computed for window or desktop modes.
  
  In both window and desktop modes we split the raw cursor data into separate movement and click streams,
  normalize them using the appropriate functions,
  smooth the movement data (optionally using the click data), and then adjust opacity
  if hideInactiveCursor is requested.
*/
export const computeCursorDataForClips = async (clips, forceRecalculate=false, sceneWidth=1920, sceneHeight=1080) => {
	let calculatedClipsIds = [];
	for (let clip of clips) {
		const clipId = clip.id;
		// Check if cursor data needs recalculation (or a screen frame change)
		if (!clipsData[clipId] || !clipsData[clipId].cursorData || clipsData[clipId].screenVideoDeviceFrame !== clip.metadata.deviceFrame || forceRecalculate) {
			// Determine the screen video mode – for example 'window' or 'desktop'
			const displayMode = clip.metadata.displayMode;
			// Legacy recordings (without a sessionCaptureId) follow a different code-path
			const isLegacy = !clip.sessionCaptureId;
			
			try {
				if (displayMode === 'window' && (!clipsData[clipId] || !clipsData[clipId].cursorData || forceRecalculate)) {
					// In window mode, we adjust the coordinates using the clip's display box.
					const masterRecordingData = await getMasterRecordingData(clip.captureId);
					const recordingWidth = masterRecordingData.recordingWidth;
					const recordingHeight = masterRecordingData.recordingHeight;
					const recordingPointScale = masterRecordingData.pointScale;
					
					// Get the display box from the clip (relative values)
					const displayBox = clip.metadata.screenVideoDisplayPosition;
					const relativeWindowWidth = displayBox.width;
					const relativeWindowHeight = displayBox.height;
					const relativeWindowX = displayBox.x;
					const relativeWindowY = displayBox.y;
					
					// Convert relative display box to pixel values
					const windowWidth = relativeWindowWidth * recordingWidth;
					let windowHeight = relativeWindowHeight * recordingHeight;
					const windowX = relativeWindowX * recordingWidth;
					let windowY = relativeWindowY * recordingHeight;
					
					let chromeHeightOffset = 0;
					const screenVideoApp = clip.metadata.screenVideoApp;
					const screenVideoDeviceFrame = clip.metadata.deviceFrame;
					
					// If the recording is from Chrome, adjust based on device frame type
					if (screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'color') {
						chromeHeightOffset = CHROME_OFFSET_DEFAULT * recordingPointScale;
					}
					if (screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'colorBB') {
						chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR * recordingPointScale;
					}
					
					if (screenVideoApp === 'chrome' && screenVideoDeviceFrame) {
						windowHeight = windowHeight - chromeHeightOffset;
						windowY = windowY + chromeHeightOffset;
					}
					
					// Separate the cursor data into movement and click events
					const movementData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'moved' || point.mouseEventType === 'dragged'
					);
					const clickData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'down' || point.mouseEventType === 'up'
					);
					
					// Normalize both data sets using the window's display box info
					const normalizedMovementData = await getNormalizedCursorDataForWindow(
						movementData,
						recordingWidth,
						recordingHeight,
						windowX,
						windowY,
						windowWidth,
						windowHeight
					);
					const normalizedClickData = await getNormalizedCursorDataForWindow(
						clickData,
						recordingWidth,
						recordingHeight,
						windowX,
						windowY,
						windowWidth,
						windowHeight
					);

					const skipSegments = getSkipSegmentsForClip(clip);

				  //Add the trim and interpolation step
				  const trimInterpolatedMovementData = await getTrimInterpolatedCursorData(
					  normalizedMovementData,					  
					  skipSegments
					);

					  
				  //Smooth the movement data (optionally using the click data to inform smoothing)
				  const smoothedMovementData = await getSmoothedCursorData(
					  trimInterpolatedMovementData,
					  //normalizedMovementData,
					  masterRecordingData.recordingVideoDuration,
					  normalizedClickData,
					  skipSegments,
					  clip
					);



					// Determine the mesh scale using the scene dimensions
					const meshScale = windowModeCalculateMeshScale(windowWidth, windowHeight, sceneWidth, sceneHeight, clip.videoWindowPadding(), recordingPointScale);						
					
					// Scale the smoothed data by the computed mesh scale
					let meshScaledMovementData = smoothedMovementData.map(point => ({
						...point,
						x: point.x * meshScale,
						y: point.y * meshScale 
					}));
					

					const cursorOpacityData = CursorOpacityManager.getCursorOpacity(
						meshScaledMovementData, 
						normalizedClickData, 
						clip
					);
					
					// Store the processed data
					clipsData[clipId] = {
						clip,
						originalCursorData: meshScaledMovementData,
						cursorData: cursorOpacityData,
						clickData: normalizedClickData,
						screenVideoDeviceFrame: screenVideoDeviceFrame
					};
					calculatedClipsIds.push(clipId);
				} else if (displayMode === 'desktop' && (!clipsData[clipId] || !clipsData[clipId].cursorData || forceRecalculate)) {
					//console.log('DESKTOP');
					const masterRecordingData = await getMasterRecordingData(clip.captureId);
					const recordingWidth = masterRecordingData.recordingWidth;
					const recordingHeight = masterRecordingData.recordingHeight;
					
					// Separate the cursor data into movement and click events
					const movementData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'moved' || point.mouseEventType === 'dragged'
					);
					const clickData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'down' || point.mouseEventType === 'up'
					);
					
					// Normalize both data sets (desktop mode covers the entire recording)
					const normalizedMovementData = await getNormalizedCursorData(
						movementData,
						recordingWidth,
						recordingHeight,
						0,
						0
					);
					const normalizedClickData = await getNormalizedCursorData(
						clickData,
						recordingWidth,
						recordingHeight,
						0,
						0
					);							
					
					const skipSegments = getSkipSegmentsForClip(clip);

				  //Add the trim and interpolation step
				  const trimInterpolatedMovementData = await getTrimInterpolatedCursorData(
					  normalizedMovementData,					  
					  skipSegments
					);

					  
				  // Smooth the movement data (optionally using the click data to inform smoothing)
				  const smoothedMovementData = await getSmoothedCursorData(
					  trimInterpolatedMovementData,
					  // normalizedMovementData,
					  masterRecordingData.recordingVideoDuration,
					  normalizedClickData,
					  skipSegments,
					  clip
					);

					const meshScale = desktopModeModeCalculateMeshScale(recordingWidth, recordingHeight, sceneWidth, sceneHeight);
					
					// Scale the smoothed data by the computed mesh scale
					let meshScaledMovementData = smoothedMovementData.map(point => ({
						...point,
						x: point.x * meshScale,
						y: point.y * meshScale 
					}));
					
					const cursorOpacityData = CursorOpacityManager.getCursorOpacity(
						meshScaledMovementData,
						normalizedClickData,
						clip
					);
					
					// Store the processed desktop data
					clipsData[clipId] = {
						clip,
						originalCursorData: meshScaledMovementData,
						cursorData: cursorOpacityData,
						clickData: normalizedClickData
					};
					calculatedClipsIds.push(clipId);
				} else if (
					isLegacy &&
					(!clipsData[clipId] ||
						!clipsData[clipId].cursorData ||
						clipsData[clipId].screenVideoDeviceFrame !== clip.metadata.deviceFrame ||
						forceRecalculate)
				) {		
					let chromeHeightOffset = 0;
					const screenVideoApp = clip.metadata.screenVideoApp;
					const screenVideoDeviceFrame = clip.metadata.deviceFrame;
					
					if (screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'color') {
						chromeHeightOffset = CHROME_OFFSET_DEFAULT;
					}
					if (screenVideoApp === 'chrome' && screenVideoDeviceFrame === 'colorBB') {
						chromeHeightOffset = CHROME_OFFSET_BOOKMARKS_BAR;
					}
					
					const masterRecordingData = await getMasterRecordingData(clip.captureId);
					// Separate the cursor data into movement and click events
					const movementData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'moved' || point.mouseEventType === 'dragged'
					);
					const clickData = masterRecordingData.cursorData.filter(point =>
						point.mouseEventType === 'down' || point.mouseEventType === 'up'
					);
					
					// Normalize both data sets using the appropriate offset 
					const normalizedMovementData = await getNormalizedCursorData(
						movementData,
						masterRecordingData.recordingWidth,
						masterRecordingData.recordingHeight,
						0,
						(chromeHeightOffset / 2)
					);
					const normalizedClickData = await getNormalizedCursorData(
						clickData,
						masterRecordingData.recordingWidth,
						masterRecordingData.recordingHeight,
						0,
						(chromeHeightOffset / 2)
					);
					
					
					
					const skipSegments = getSkipSegmentsForClip(clip);

				  //Add the trim and interpolation step
				  const trimInterpolatedMovementData = await getTrimInterpolatedCursorData(
					  normalizedMovementData,					  
					  skipSegments
					);

					  
				  // Smooth the movement data (optionally using the click data to inform smoothing)
				  const smoothedMovementData = await getSmoothedCursorData(
					  trimInterpolatedMovementData,
					  // normalizedMovementData,
					  masterRecordingData.recordingVideoDuration,
					  normalizedClickData,
					  skipSegments,
					  clip
					);

					// Compute the mesh scale using the legacy function
					const meshScale = legacyCalculateMeshScale(masterRecordingData, screenVideoApp, screenVideoDeviceFrame, sceneWidth, sceneHeight, clip.videoWindowPadding());

					// Scale the smoothed data by the computed mesh scale
					const meshScaledMovementData = smoothedMovementData.map(point => ({
						...point,
						x: point.x * meshScale,
						y: point.y * meshScale 
					}));
					
					// Adjust opacity based on click data
					const cursorOpacityData = CursorOpacityManager.getCursorOpacity(
						meshScaledMovementData, 
						normalizedClickData, 
						clip
					);
					
					// Store/update the processed legacy data
					clipsData[clipId] = {
						clip,
						originalCursorData: meshScaledMovementData,
						cursorData: cursorOpacityData,
						clickData: normalizedClickData,
						screenVideoDeviceFrame: screenVideoDeviceFrame
					};
					calculatedClipsIds.push(clipId);
				} else {
					// For clips that already have calculated data, simply update the clip info.
					clipsData[clipId].clip = clip;
					calculatedClipsIds.push(clipId);
				}
			} catch (error) {
				console.error('Error precalculating data for clip:', error);
			}
		} else {
			// If data already exists, update the clip info.
			clipsData[clipId].clip = clip;
			calculatedClipsIds.push(clipId);
		}
	}
	return calculatedClipsIds;
};

// This returns the cursor position at a given local (in-video) time.
export const getCursorPositionAtLocalTime = (clipId, localTime) => {
	if (clipsData[clipId] && clipsData[clipId].cursorData) {
		const meshNormalizedCursorData = clipsData[clipId].cursorData;
		const mostRecentSmoothPosition = binarySearchNearest(meshNormalizedCursorData, localTime, 'time');
		if (mostRecentSmoothPosition) {
			return {
				x: mostRecentSmoothPosition.x,
				y: mostRecentSmoothPosition.y,
				cursorType: mostRecentSmoothPosition.cursorType
			};
		}
	}
	return null; // Return null if no position is found or data is missing
};

export const getCursorPositionAtTimelineTime = (clip, timelineTime) => {
	const clipId = clip.id;
	const localTime = calulateVideoTimeFromTimelineTime(timelineTime, clip);
	if (clipsData[clipId] && clipsData[clipId].cursorData) {
		const meshNormalizedCursorData = clipsData[clipId].cursorData;
		let mostRecentSmoothPosition;
		if (meshNormalizedCursorData && meshNormalizedCursorData.length > 0) {
			mostRecentSmoothPosition = binarySearchNearest(meshNormalizedCursorData, localTime, 'time');
		}
		if (mostRecentSmoothPosition) {
			return {
				x: mostRecentSmoothPosition.x,
				y: mostRecentSmoothPosition.y,
				cursorType: mostRecentSmoothPosition.cursorType,
				scale: mostRecentSmoothPosition.scale,
				opacity: mostRecentSmoothPosition.opacity
			};
		}
	}
	return null;
};

// This returns the cursor position at a given timeline time by finding the active clip.
export function getCursorPositionAtTime(timelineTime) {
	for (let clipId in clipsData) {
		const clip = clipsData[clipId].clip;
		if (timelineTime >= clip.startTime && timelineTime < clip.startTime + clip.duration) {
			const localTime = calulateVideoTimeFromTimelineTime(timelineTime, clip);
			// Get cursor position at local time
			const position = getCursorPositionAtLocalTime(clipId, localTime);
			return position;
		}
	}
	return { x: 0, y: 0 };
}

export const updateCursorOpacityForClip = (clip) => {
	const clipId = clip.id;
	const currentData = clipsData[clipId];
	if (currentData && currentData.originalCursorData) {
		clipsData[clipId] = {
			...currentData,
			clip,
			cursorData: CursorOpacityManager.recalculateOpacity(currentData.originalCursorData, currentData.clickData, clip)
		};
		return true;
	}
	return false;
};

export const clearScreenClipsData = () => {
	clipsData = {};
};

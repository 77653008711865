import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import DPSelectMenu from './DPSelectMenu';
import DPColorPicker from './DPColorPicker';
import EditorDetailPanelSlideAnimationPanel from './EditorDetailPanelSlideAnimationPanel'
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import {createSlideContent, saveSlideAsTemplate,updateSlideTemplate} from '../../../actions/slideTemplates'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const alignmentTypes = [
  { value: 'vertical', label: 'Vertical', iconName: 'arrowDownMedium' },    
  { value: 'horizontal', label: 'Horizontal', iconName: 'arrowRightMedium' }
];


const EditorDetailPanelSlide = (props) => {
  const {slideClip, updateSlideAlignment,updateSlideBackgroundColor,clipId,projectId, wordsOverlappingSlide} = props

  const [stylesDetail, showStylesDetail] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(100);
  const [vAlign, setVAlign] = useState(slideClip.vAlign || 'middle');
  const [hAlign, setHAlign] = useState(slideClip.hAlign || 'center');
  const [alignmentType, setAlignmentType] = useState('vertical');
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();
  const [loadingSlideContent, setLoadingSlideContent] = useState(false);

  // const slideTemplates = useSelector(state => state.slideTemplates);
  // const archivedSlideTemplates = useSelector(state => state.archivedSlideTemplates);

 
  // const [slideAlreadyHasTemplate, setSlideAlreadyHasTemplate] = useState(false);

  // const existingTemplate = [...slideTemplates, ...archivedSlideTemplates].find(
  //   template => template.original_slide_id == slideClip.id
  // );



  // const handleSaveOrUpdateTemplate = () => {
  //   if (existingTemplate) {
  //     dispatch(updateSlideTemplate(existingTemplate.id, slideClip));
  //   } else {
  //     dispatch(saveSlideAsTemplate(projectId, slideClip));
  //   }
  // };

  const orgBrand=getOrgBrand()
  let activeBg = 'auto' //null
  if(slideClip.metadata.backgroundId=='none'){
    activeBg= 'none'
  }else if(slideClip.metadata.backgroundId){
    activeBg=slideClip.metadata.backgroundId
    
  }


  const setBgStyle = (bgId)=>{
    let value=bgId 
    if(bgId=='auto'){
      value=null
    }
    updateSlideBackgroundColor(clipId,value)
  }

  let bgStyles=[{ id:'none',value: 'none', label: 'None' }, {id:'auto', value: 'auto', label: 'Auto' }]
  
  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })

  const handleCreateSlideContent = async () => {
    setLoadingSlideContent(true);
    const response = await createSlideContent(wordsOverlappingSlide, 5);
    const bullets = response?.slide?.bullets
    console.log(bullets)
    // const bulletsIncremental = []
    // let prevStart = 0
    // bullets.forEach(bullet => {
    //   const incremental = bullet.start_time - prevStart
    //   bulletsIncremental.push({...bullet, delay: incremental})
    //   prevStart = bullet.start_time
    // })
    // const numBullets = bulletsIncremental.length
    // const layoutNames = Array.from({length: numBullets}, (_, i) => `Label ${i+1}`);
    // const layoutElements = slideClip.layout?.children.filter(child => child.type === 'text')
    // console.log(layoutElements)
    // for (let i=0;i<numBullets;i++){
    //   const layoutName = layoutNames[i]
    //   const layoutElement = layoutElements.find(element => element.metadata?.text === layoutName)
    //   if (layoutElement) {
    //     layoutElement.metadata.enterDelay = bulletsIncremental[i].delay
    //   }
    // }
    props.applyBulletPointsToSlide(slideClip.id,bullets)
    //console.log(slideClip.layout)
    setLoadingSlideContent(false);
  }


  useEffect(() => {
    setVAlign(slideClip.vAlign || 'middle');
    setHAlign(slideClip.hAlign || 'center');
  }, [slideClip]);

  const handleUpdateSlideAlignment = (alignment, value) => {
    updateSlideAlignment(slideClip.id, alignment, value);
    if (alignment === 'vAlign') {
      setVAlign(value);
    } else if (alignment === 'hAlign') {
      setHAlign(value);
    }
  };

  //console.log(bgStyles)

  

  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          Slide
        </div>              
      </div>      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Background
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPColorPicker                    
          activeColorId={activeBg}
          onChange={setBgStyle}
          options={bgStyles}          
        />       
      </div>
      <div className='editor-detailPanel-divider' />    
      <div className='editor-detailPanel-row'>
       <button onClick={handleCreateSlideContent} className='dpButton dpButton--saveTemplate dpButton--labelOnly dpButton--fullWidth dpButton--strong' disabled={loadingSlideContent}>
          <div className='dpButton-label'>
            {loadingSlideContent ? 'Loading...' : 'Get slide stuff'}
          </div>
        </button>
      </div>
      {/* 
      <div className='editor-detailPanel-divider' />    
      <div className='editor-detailPanel-row'>
       <button onClick={handleSaveOrUpdateTemplate} className='dpButton dpButton--saveTemplate dpButton--labelOnly dpButton--fullWidth dpButton--strong'>
          <div className='dpButton-label'>
            {existingTemplate? "Update Template": "Save as Template"}
          </div>
        </button>
      </div>
      */}
      <div className='editor-detailPanel-divider' />
      <EditorDetailPanelSlideAnimationPanel
        slideClip={slideClip}
        elements={slideClip.elements}
        updateSlideElementAnimationIndex={props.updateSlideElementAnimationIndex}
        updateSlideElementMetadata={props.updateSlideElementMetadata}
        clipId={slideClip.id}
        setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
      />

    </>
  );
};

export default EditorDetailPanelSlide;
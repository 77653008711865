import React, { useState, useEffect, useRef } from 'react';
import Icon from '../../../../misc/Icon';
import { Rnd } from 'react-rnd';
import * as ContextMenu from '@radix-ui/react-context-menu';
import {calculateTimelineTimeFromVideoTime} from '../../../../../timeline/utils/calculateTimelineTimeFromVideoTime'

const SegmentLeftClipHandle = ({cursorStyle, onMouseDown, onMouseUp, active}) => (
  <div data-segment-type='skip' style={{cursor: cursorStyle }} data-active-state={active ? true : false} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={(e) => e.stopPropagation()} className='editor-timeline-clip-segment-handle editor-timeline-clip-segment-handle--skip editor-timeline-clip-segment-handle--skip--drag editor-timeline-clip-segment-handle--left'>
    {/*<div className='editor-timeline-clip-segment-handle-inner'>
      <div className='editor-timeline-clip-segment-inner-bar' />
    </div>*/}
    
    <div className='editor-timeline-clip-segment-handle-tooltipContainer'>
      <div className='editor-timeline-clip-segment-handle-tooltip'>
        Adjust trim start
      </div>
    </div>
  </div>
);

const SegmentRightClipHandle = ({cursorStyle, onMouseDown, onMouseUp, active}) => (
  <div data-segment-type='skip' style={{cursor: cursorStyle }} data-active-state={active ? true : false} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={(e) => e.stopPropagation()} className='editor-timeline-clip-segment-handle editor-timeline-clip-segment-handle--skip editor-timeline-clip-segment-handle--skip--drag editor-timeline-clip-segment-handle--right'>
    {/*<div className='editor-timeline-clip-segment-handle-inner'>
      <div className='editor-timeline-clip-segment-inner-bar' />
    </div>*/}
    
    <div className='editor-timeline-clip-segment-handle-tooltipContainer'>
      <div className='editor-timeline-clip-segment-handle-tooltip'>
        Adjust trim end
      </div>
    </div>
  </div>
);

const calculateBoundingBox = (segment, segments,totalDuration) => {
  let start = 0; // Default to the start of the clip
  let end =totalDuration


  const index = segments.findIndex(seg => seg.id === segment.id);
  for (let i = index - 1; i >= 0; i--) {
    const prevSegment = segments[i];
    if (prevSegment.isSkipSegment || prevSegment.isQuiet) {
      start = prevSegment.newEnd;
      break;
    }
  }

    for (let i = index + 1; i < segments.length; i++) {
      const nextSegment = segments[i];
      if (nextSegment.isSkipSegment || nextSegment.isQuiet) {
        end = nextSegment.newStart;
        break;
      }
    }

  

  return { start, end };
};

const SkipSegment = (props) => {
  
  const { 
    segment, 
    isExpanded,
    totalDuration, 
    clipWidth, 
    onResize, 
    onSegmentClick,
    trimStart, 
    scrollRef, 
    handleSeek, 
    pixelsPerSec,
    clipId, 
    yOffset,
    toggleSkipSegment,
    sceneGapOffset,    
    segments,
    resizingSkipSegmentHandle,
    isResizing
    } = props

  const rndRef = useRef(null); // Ref for the Rnd component
  const [cursorStyle, setCursorStyle] = useState('grab');
  const [isDragging, setIsDragging] = useState(false);

  let pixelWidth = (segment.newDuration / totalDuration) * clipWidth;
  let left = ((segment.newStart)/ totalDuration) * clipWidth + sceneGapOffset;  

  //adjust the left for the trim time
  const trimAdjustment = trimStart/totalDuration * clipWidth // need to shift segments to the left to account for start time being relative to unclipped time 

  left-=trimAdjustment  

  const overflowPixels = 20 // check if overflowing
  const isRightOverflow = (left + pixelWidth + overflowPixels) > clipWidth  
  const isLeftOverflow = left < overflowPixels


  let offsetCursor = 0

  // if(isRightOverflow){
  //   offsetCursor = -16 // this is to prevent overflow onto the right
  // }

  let offsetWidth = 0

  if(isLeftOverflow){
    offsetWidth = 8 // this is to prevent overflow onto the left
  }

  const rightHandleStyles = {
    cursor: `${cursorStyle} !important`,
    height: `13px`,
    marginTop: `6px`,    
  };
  const leftHandleStyles = {
    cursor: `${cursorStyle} !important`,
    height: `13px`,
    marginTop: `6px`,    
  };

  // const segmentBoundsLeft = 250
  // const segmentBoundsWidth = 400

  const bounds=calculateBoundingBox(segment,segments,totalDuration)
  const buffer = 10

  const segmentBoundsLeft = ((bounds.start)/ totalDuration) * clipWidth + sceneGapOffset + buffer;  
  const segmentBoundsWidth = ((bounds.end - bounds.start)/ totalDuration) * clipWidth - 2 * buffer;  

  // Calculate initial position for collapsed state using bounds
 // let closedX = segmentBoundsLeft + (left - segmentBoundsLeft);
  let closedX =left+offsetWidth

  // Ensure it's not too close to the edges using the existing bounds - take this out for now 
  const additionalEdgePadding = 0; // Extra padding if needed
  // closedX = Math.max(
  //   segmentBoundsLeft + additionalEdgePadding,
  //   Math.min(closedX, segmentBoundsLeft + segmentBoundsWidth - additionalEdgePadding)
  // );

  const closedLeftDistanceToSegmentBounds = closedX - segmentBoundsLeft;
  const closedRightDistanceToSegmentBounds = (segmentBoundsLeft + segmentBoundsWidth) - closedX;  

  const closedArrowsWidth = 30 // width of closedArrows
  const hideClosedArrows = (closedLeftDistanceToSegmentBounds < closedArrowsWidth) || (closedRightDistanceToSegmentBounds < closedArrowsWidth)

  const boundsDevMode = false

  // console.log('isResizing', isResizing)
  // console.log('resizingSkipSegmentHandle', resizingSkipSegmentHandle)

  return (
    <React.Fragment>      

      {!isExpanded &&
        <button
          onClick={()=>{toggleSkipSegment(clipId,segment.id,true)}} 
          style={{left: `${closedX}px`, top: `${yOffset}px`}}                    
          className='editor-timeline-clip-segment--skip-openSkipBtn'
        >          
            
          <div className='editor-timeline-clip-segment--skip-openSkipBtn-iconSet'>
            {!hideClosedArrows &&
              <div className='editor-timeline-clip-segment--skip-openSkipBtn-iconSet-left'>
                <Icon name='forwardArrowSemibold' />
              </div>
            }
            <div className='editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center'>
              <div className='editor-timeline-clip-segment--skip-openSkipBtn-iconSet-center-line' />
            </div>
            {!hideClosedArrows &&
              <div className='editor-timeline-clip-segment--skip-openSkipBtn-iconSet-right'>
                <Icon name='forwardArrowSemibold' />
              </div>
            }
          </div>   
                  
          <div className='editor-timeline-clip-segment--skip-openSkipBtn-tooltipContainer'>
            <div className='editor-timeline-clip-segment--skip-openSkipBtn-tooltip editor-timeline-clip-segment--skip-openSkipBtn-tooltip--edit'>
              Edit Trim
            </div>
          </div>              
            
        </button>
      }

      {isExpanded &&
        <div 
          id={`segmentBounds-${segment.id}`}
          style={{left: `${segmentBoundsLeft}px`, width: `${segmentBoundsWidth}px`, top: `${yOffset - 20}px`}} 
          className='editor-timeline-clip-segmentBounds' 
          data-dev-mode={boundsDevMode ? true : false}
        />
      }

      {isExpanded && 
        <div 
          style={{
            left: `${left + offsetWidth}px`,
            top: `${yOffset}px`,
            width: `${pixelWidth + offsetCursor - offsetWidth}px`,
            height: `30px`
          }}
          className='editor-timeline-clip-segment editor-timeline-clip-segment--skip editor-timeline-clip-segment--skip--render'>

          <div style={leftHandleStyles} data-segment-type='skip' data-active-state={(isResizing && resizingSkipSegmentHandle === 'left') ? true : false} className='editor-timeline-clip-segment-handle editor-timeline-clip-segment-handle--skip editor-timeline-clip-segment-handle--skip--render editor-timeline-clip-segment-handle--left'>
            <div className='editor-timeline-clip-segment-handle-inner'>
              <div className='editor-timeline-clip-segment-inner-bar' />
            </div>                      
          </div>

          <div style={rightHandleStyles} data-segment-type='skip' data-active-state={(isResizing && resizingSkipSegmentHandle === 'right') ? true : false} className='editor-timeline-clip-segment-handle editor-timeline-clip-segment-handle--skip editor-timeline-clip-segment-handle--skip--render editor-timeline-clip-segment-handle--right'>
            <div className='editor-timeline-clip-segment-handle-inner'>
              <div className='editor-timeline-clip-segment-inner-bar' />
            </div>                      
          </div>

          <div className='editor-timeline-clip-segment-fill'>
            {(pixelWidth - offsetCursor + offsetWidth) > 60 &&
                <div className='editor-timeline-clip-segment-fill--skip-iconSet'>
                  <div className='editor-timeline-clip-segment-fill--skip-iconSet-left'>
                    <Icon name='forwardArrowSemibold' />
                  </div>
                  <div className='editor-timeline-clip-segment-fill--skip-iconSet-center'>
                    <div className='editor-timeline-clip-segment-fill--skip-iconSet-center-line' />
                  </div>
                  <div className='editor-timeline-clip-segment-fill--skip-iconSet-right'>
                    <Icon name='forwardArrowSemibold' />
                  </div>
                </div>
              }
          </div>



        </div>
      }

      {isExpanded && 
      <ContextMenu.Root key={segment.id}> 
        <ContextMenu.Trigger 
          asChild
           onContextMenu={(event)=>{
            const clipRef = rndRef.current;
            if (clipRef && clipRef.props && clipRef.props.position) {
              const scrollAmount = scrollRef.current.scrollLeft;              
              const clickX = event.clientX - (segment.newStart * pixelsPerSec) + scrollAmount - 11; // 11 is left scroll gutter                            
              const seekTime = segment.newStart + (clickX / pixelsPerSec);
              handleSeek(seekTime);
            }
          }} 
        > 
          <Rnd
            ref={rndRef}
            className='editor-timeline-clip-segment editor-timeline-clip-segment--skip editor-timeline-clip-segment--skip--drag'
            style={{cursor: 'pointer'}}
            size={{ width: pixelWidth + offsetCursor - offsetWidth, height: 30 }}
            position={{ x: left + offsetWidth, y: yOffset }}
            onResize={(e, direction, ref, delta, position) => {
              const newPixelWidth = ref.offsetWidth - offsetCursor + offsetWidth;
              onResize(segment.id, newPixelWidth,direction);
            }}  
            onResizeStart={(e,direction)=>{
              props.handleResizeStart(e,direction,segment)}}   
            onResizeStop={props.handleResizeStop}    
            onClick={onSegmentClick}
            enableResizing={{
              right: true,
              left: true,
              top: false,
              bottom: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
            resizeHandleComponent={{
              right: <SegmentRightClipHandle cursorStyle={cursorStyle} skip  />,
              left: <SegmentLeftClipHandle cursorStyle={cursorStyle} skip />
            }}
             resizeHandleStyles={{
              right: rightHandleStyles,
              left: leftHandleStyles,
            }}
            //minWidth={1}
            bounds={`#segmentBounds-${segment.id}`}
            dragAxis={"none"} // Disable dragging
          >
            <div onClick={()=>{toggleSkipSegment(clipId,segment.id,false)}} className='editor-timeline-clip-segment-fill'>
            </div>
          </Rnd>
        </ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--skipSegment forceDarkTheme'>                                
            <ContextMenu.Item onSelect={()=>{props.removeSkip(clipId,segment.id)}}>
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='deleted' />
              </div>
              Remove Trim
            </ContextMenu.Item>          
          </ContextMenu.Content>
        </ContextMenu.Portal>
      </ContextMenu.Root>
      } 
    </React.Fragment>
  );
};



const EditorTimelineClipSkipSegments = (props) => {

  const { 
    clip, 
    clipWidth, 
    handleChangeSegmentPlaybackRate, 
    onSegmentClick,
    trimStart, 
    scrollRef, 
    handleSeek, 
    pixelsPerSec,
    handleChangeSkipSegmentDuration,
    yOffset,
    sceneGap
     } =props


  const [isResizing, setIsResizing] = useState(false);
  const { segments, id: clipId } = clip; // id of the clip is needed
  
  const totalDuration = clip.duration //this takes into account playback rate

  const handleResize = (segmentId, newPixelWidth,direction) => {
    const segment = segments.find(seg => seg.id === segmentId);
    if (segment) {
      const newDuration = (newPixelWidth / clipWidth) * totalDuration  * clip.clipPlaybackRate;
      handleChangeSkipSegmentDuration(clip.id,segmentId,newDuration,direction)
      let videoSeekTime = clip.startTime
       if(direction =='left'){
        videoSeekTime=(segment.originalEnd - newDuration)
      } else{
        videoSeekTime=(segment.originalStart + newDuration)
      }
      const seekTime = calculateTimelineTimeFromVideoTime(videoSeekTime, clip)
      handleSeek(seekTime)
    }
  };

  const handleResizeStart=(e,direction,segment)=>{
    props.handleResizeSkipSegmentStart(direction)
    props.setIsDraggingSegment(true)
    setIsResizing(true)
    let videoSeekTime = clip.startTime
    if(direction =='left'){
      videoSeekTime=segment.originalStart
    } else{
    videoSeekTime=segment.originalEnd
    }
    const seekTime = calculateTimelineTimeFromVideoTime(videoSeekTime, clip)
    handleSeek(seekTime)

  }

  const handleResizeStop=()=>{
    props.handleResizeSkipSegmentStop()
    props.setIsDraggingSegment(false)
    setIsResizing(false)
  }





  const skipSegments = segments
    .filter(segment => segment.isSkipSegment);

  const sceneGapOffset = clip.sceneIndex * sceneGap

  return (
    <>
      {skipSegments.map(segment => (
        <SkipSegment 
          key={segment.id} 
          segment={segment} 
          segments={segments}
          isExpanded={segment.isExpanded}
          toggleSkipSegment={props.toggleSkipSegment}
          removeSkip={props.removeSkip}
          clipId={clip.id}
          totalDuration={totalDuration} 
          clipWidth={clipWidth}
          onResize={handleResize} 
          onSegmentClick={onSegmentClick}
          trimStart={trimStart}
          scrollRef={scrollRef}
          handleSeek={handleSeek}          
          pixelsPerSec={pixelsPerSec}
          yOffset={yOffset}  
          sceneGapOffset={sceneGapOffset}    
          handleResizeStart={handleResizeStart}
          handleResizeStop={handleResizeStop}
          resizingSkipSegmentHandle={props.resizingSkipSegmentHandle}
          isResizing={isResizing}
        />
      ))}
    </>
  );
}

export default EditorTimelineClipSkipSegments;
export const getFileExtension = (file) => {
  // First try to get extension from filename
  if (file.name) {
    const nameParts = file.name.split('.');
    if (nameParts.length > 1) {
      return nameParts.pop().toLowerCase();
    }
  }
  
  // Fallback to MIME type mapping if filename doesn't have extension
  const mimeToExtension = {
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
    'video/x-flv': 'flv',
    'video/x-matroska': 'mkv',
    'video/mpeg': 'mpeg',
    'video/3gpp': '3gp',
    'video/ogg': 'ogv',
    'video/mp2t': 'ts',
    'video/x-ms-wmv': 'wmv'
  };
  
  return mimeToExtension[file.type] || 'mp4'; // Default to mp4 if no match
};
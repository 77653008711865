import React, { useRef, useEffect, useCallback, useState,useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const ExportScreenVideoWindowModeCroppedMaterial = ({ 
  codecVideoClip, 
  opacity,
  meshWidth,
  meshHeight,
  recordingWidth,
  recordingHeight,
  windowX,
  windowY,
  windowWidth,
  windowHeight
}) => {
  const { invalidate } = useThree();
  const materialRef = useRef(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  
  // Initialize textureRef with a base texture
  const textureRef = useRef(
    (() => {
      const texture = new THREE.Texture();
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.format = THREE.RGBAFormat;
      texture.colorSpace = THREE.SRGBColorSpace;
      return texture;
    })()
  );

  // Set up codec video clip connection
  useEffect(() => {
    if (codecVideoClip) {
      codecVideoClip.setExternalTexture(textureRef.current);
      codecVideoClip.setTextureUpdatedCallback(() => {
        setUpdateTrigger(prev => prev + 1);
        invalidate();
      });
    }
    return () => {
      if (codecVideoClip) {
        codecVideoClip.setExternalTexture(null);
        codecVideoClip.setTextureUpdatedCallback(null);
      }
    };
  }, [codecVideoClip, invalidate]);

  const updateTextureSettings = useCallback(() => {
    if (!textureRef.current || !recordingWidth || !recordingHeight) {
      console.log('Missing required values for texture update');
      return;
    }

    // Calculate relative dimensions
    const relativeWindowX = windowX / recordingWidth;
    const relativeWindowY = 1 - ((windowY + windowHeight) / recordingHeight); // Flip Y coordinate
    const relativeWindowWidth = windowWidth / recordingWidth;
    const relativeWindowHeight = windowHeight / recordingHeight;

    // Set texture repeat to crop to window size
    textureRef.current.repeat.set(relativeWindowWidth, relativeWindowHeight);

    // Set texture offset to position window correctly
    textureRef.current.offset.set(relativeWindowX, relativeWindowY);

    // Handle aspect ratio differences
    const meshAspect = meshWidth / meshHeight;
    const windowAspect = windowWidth / windowHeight;

    if (meshAspect > windowAspect) {
      // Mesh is wider than window - adjust X
      const scale = windowAspect / meshAspect;
      textureRef.current.repeat.x *= scale;
      textureRef.current.offset.x += (relativeWindowWidth * (1 - scale)) / 2;
    } else {
      // Mesh is taller than window - adjust Y
      const scale = meshAspect / windowAspect;
      textureRef.current.repeat.y *= scale;
      textureRef.current.offset.y += (relativeWindowHeight * (1 - scale)) / 2;
    }

    if (textureRef.current.image) {
      textureRef.current.needsUpdate = true;
    }
  }, [meshWidth, meshHeight, recordingWidth, recordingHeight, windowX, windowY, windowWidth, windowHeight]);

  useEffect(() => {
    updateTextureSettings();
  }, [updateTextureSettings, updateTrigger]);

  // useEffect(() => {
  //   if (!materialRef.current) {
  //     materialRef.current = new THREE.MeshBasicMaterial({
  //       map: textureRef.current,
  //       transparent: true,
  //       opacity: opacity,
  //       precision: 'highp',
  //     });
  //   }
    
  //   materialRef.current.opacity = opacity;
  //   materialRef.current.needsUpdate = true;
  // }, [opacity, updateTrigger]);

  const material = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      map: textureRef.current,
      transparent: true,
      opacity: opacity,
    });
  }, [opacity, updateTrigger]);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.map = textureRef.current;
      materialRef.current.needsUpdate = true;
    }
  }, [material]);


  // return materialRef.current ? <primitive object={materialRef.current} attach="material" /> : null;
  return <meshBasicMaterial ref={materialRef} map={textureRef.current} transparent opacity={opacity} attach="material" />;
};

export default ExportScreenVideoWindowModeCroppedMaterial;
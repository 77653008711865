import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { useTransition, animated, useSpring } from 'react-spring';
import Icon from './misc/Icon';
import ExportManager from '../ExportManager';
import * as Sentry from '@sentry/electron/renderer'

const PERCENTAGE_RANGE_FINALIZING = 20;  

const PERCENTAGE_RANGE_RENDERING = 100 - PERCENTAGE_RANGE_FINALIZING

const FINALIZING_DURATION_SCALE = 0.08; 

const ExportProgressTray = () => {
  const projectExports = useSelector(state => state.projectExports || {});
  const projects = useSelector(state => state.projects || []);
  const [progress, setProgress] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [localExportStatus, setLocalExportStatus] = useState('');

  const exportProjectId = Object.keys(projectExports)[0];
  const exportStatusObject = exportProjectId ? projectExports[exportProjectId] : null;
  const exportProject = exportProjectId ? find(projects, { id: exportProjectId }) : null;

  useEffect(() => {
    let status = exportStatusObject?.status || '';
    if (status === 'in_progress') {
      status = 'generating';
    }
    if (status === 'generation_completed') {
      status = 'completed';
    }
    setLocalExportStatus(status);
  }, [exportStatusObject?.status]);

  const hasActiveExport = localExportStatus !== '';

  useEffect(() => {
  let animationFrameId;
  const updateProgress = () => {
    if (!exportStatusObject) return;

    //console.log('Current phase:', exportStatusObject.phase);

    let currentProgress;

    if (exportStatusObject.phase === 'rendering') {
      currentProgress = (exportStatusObject.percentDone / 100) * PERCENTAGE_RANGE_RENDERING;
      // console.log('Render progress:', {
      //   inputPercentage: exportStatusObject.percentDone,
      //   calculatedProgress: currentProgress
      // });
    } else if (exportStatusObject.phase === 'finalizing') {
      const elapsedTime = (Date.now() - exportStatusObject.phaseStartTime) / 1000;
      const finalizeDuration = exportStatusObject.projectDuration * FINALIZING_DURATION_SCALE;
      const finalizeProgress = Math.min(elapsedTime / finalizeDuration, 1);
      currentProgress = PERCENTAGE_RANGE_RENDERING + 
        (finalizeProgress * (100 - PERCENTAGE_RANGE_RENDERING));
      // console.log('Finalize progress:', {
      //   elapsedTime,
      //   finalizeDuration,
      //   finalizeProgress,
      //   currentProgress
      // });
    } else if (exportStatusObject.phase === 'downloading' || exportStatusObject.phase === 'completed') {
      currentProgress = 100;
    } else {
      currentProgress = 0;
    }

    setProgress(currentProgress / 100);
    
    // Continue animation if not completed
    if (localExportStatus !== 'completed') {
      animationFrameId = requestAnimationFrame(updateProgress);
    }
  };

  if (hasActiveExport) {
    animationFrameId = requestAnimationFrame(updateProgress);
  }

  return () => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }
  };
}, [exportStatusObject, hasActiveExport, localExportStatus]);

  const transitions = useTransition(hasActiveExport && !isExiting, {
    from: { opacity: 0, transform: 'scale(0.95) translateY(0px)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0px)' },
    leave: { opacity: 0, transform: 'scale(0.95) translateY(0px)' },
    config: { tension: 300, friction: 18 },    
  });

  const clearExportStatus = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      ExportManager.cancelExport(exportProjectId);
    }, 300);
  }, [exportProjectId]);

  useEffect(() => {
    if (!hasActiveExport) {
      setIsExiting(false);
    }
  }, [hasActiveExport]);

  const traySpring = useSpring({
    height: localExportStatus === 'completed' ? 90 : 55,
    config: { tension: 300, friction: 26 }
  });

  const progressSpring = useSpring({
    from: { transform: `translateX(-100%)` },
    to: { transform: `translateX(${(progress - 1) * 100}%)` },
    immediate: progress === 0 && localExportStatus === 'enqueued',
    config: { tension: 340, friction: 40 }
  });

  
  const handleDownload = useCallback(() => {
    console.log('Download button clicked');
    console.log('Export project:', exportProject);
    console.log('Export status:', exportStatusObject);
    
    if (!exportProject) {
      const error = new Error('Download failed: No export project found');
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          component: 'ExportProgressTray',
          action: 'handleDownload'
        }
      });
      return;
    }
    
    if (!exportStatusObject?.resultUrl) {
      const error = new Error('Download failed: No result URL found in export status');
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          component: 'ExportProgressTray',
          action: 'handleDownload',
          projectId: exportProject.id
        },
        extra: {
          exportStatus: exportStatusObject
        }
      });
      return;
    }
    
    console.log('Initiating download with:', {
      url: exportStatusObject.resultUrl,
      filename: `${exportProject.name}.mp4`
    });
    
    try {
      window.ipcRenderer.send('download-exported-video', {
        url: exportStatusObject.resultUrl,
        filename: `${exportProject.name}.mp4`
      });
    } catch (error) {
      console.error('Error sending download request:', error);
      Sentry.captureException(error, {
        tags: {
          component: 'ExportProgressTray',
          action: 'handleDownload',
          projectId: exportProject.id
        },
        extra: {
          url: exportStatusObject.resultUrl,
          filename: `${exportProject.name}.mp4`
        }
      });
    }
  }, [exportProject, exportStatusObject?.resultUrl]);

  return transitions((styles, item) =>
    item && (
      <animated.div style={styles} data-active-export={hasActiveExport ? 'true' : 'false'} className='exportProgressTrayContainer'>
        <animated.div style={traySpring} data-export-state={localExportStatus} className='exportProgressTray'>
          <div className='exportProgressTray-primaryRow'>
            <div data-opacity-state={localExportStatus === 'completed' ? 'light' : 'normal'} className='exportProgressTray-primaryRow-title'>
              <div className='exportProgressTray-primaryRow-title-label'>
                {localExportStatus === 'completed' ? 'Export Complete' : 'Exporting'}
              </div>
            </div>      
          </div>          

          <div className='exportProgressTray-progressBarContainer'>
            <div className='exportProgressTray-progressBar'>
              <animated.div style={progressSpring} className='exportProgressTray-progressBar-bar' />
              <div className='exportProgressTray-progressBar-track' />
            </div>
          </div>

          {localExportStatus === 'completed' &&
            <button onClick={clearExportStatus} className='exportProgressTray-closeButton'>
              <div className='exportProgressTray-closeButton-inner'>
                <Icon name='crossSemibold' />
              </div>
            </button>
          }

          {localExportStatus !== 'completed' &&
            <button onClick={clearExportStatus} className='exportProgressTray-cancelButton'>
              Cancel
            </button>
          }

          <div data-visible-state={localExportStatus==='completed' ? 'visible' : 'hidden'} className='exportProgressTray-downloadBtnContainer'>
            <button onClick={handleDownload} className='exportProgressTray-downloadBtn'>
              Download
            </button>              
          </div>          
        </animated.div>
      </animated.div>
    )
  );
};

export default ExportProgressTray;
import React, { useState,useEffect, useMemo } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPColorPicker from './DPColorPicker';
import DPNumberInput from './DPNumberInput';
import { getOrgBrand } from '../../../utils/brands/getOrgBrand'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import DPTextInput from './DPTextInput'
import DPCheckbox from './DPCheckbox'
import DPItemAnimationPanel from './DPItemAnimationPanel';
import {getOrgDomain} from '../../../utils/getOrgDomain'
import DPSelectMenu from './DPSelectMenu';
import DPSlider from './DPSlider';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'


//metadata.displayMode can be window or desktop 
//displayType is colorFrame, window, or desktop

const EditorDetailPanelScreen = (props) => {

  const {toggleTrimMode,clip,updateClipMetadata,updateClipBackgroundColor, changeVideoClipPlaybackRate, unlinkScreencast,setVideoWindowPadding} = props

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(clip.clipPlaybackRate || 1.0); // Initialize from clip's playback rate

  const allowWindowMode = clip.metadata.allowWindowMode
  const isChromeRecording = clip.metadata.screenVideoApp=='chrome' 
  const displayMode = clip.metadata.displayMode //window or desktop

  let displayType = displayMode //window, desktop, or colorFrame
  if(displayMode=='window' && (clip.metadata.deviceFrame=='color' || clip.metadata.deviceFrame=='colorBB' )){
    displayType='colorFrame'
  }

  let activeDisplayTypeIconName = 'rectangleMedium'
  if(displayType === 'window'){
    activeDisplayTypeIconName = 'window'
  }
  if(displayType === 'desktop'){
    activeDisplayTypeIconName = 'laptop'
  }

  const setDisplayType = (value) => { //set it to window, desktop, or colorFrame
    let deviceFrame = null 
    let displayMode = 'window'
    if(value=='colorFrame'){
      deviceFrame = 'color' 
      displayMode = 'window'
    }else if(value=='window'){
      deviceFrame = null
      displayMode = 'window'
    }else if(value=='desktop'){
      deviceFrame = null
      displayMode = 'desktop'
    }
    updateClipMetadata(clip.id, { deviceFrame: deviceFrame, displayMode: displayMode })
  }

  
  const orgBrand=getOrgBrand()
  let activeBg = 'auto' //null
  if(clip.metadata.backgroundId=='none'){
    activeBg= 'none'
  }else if(clip.metadata.backgroundId){
    activeBg=clip.metadata.backgroundId
  }

  const setBgStyle = (bgId)=>{
    let value=bgId 
    if(bgId=='auto'){
      value=null
    }
    updateClipBackgroundColor(clip.id,value)
  }

  let bgStyles=[{ id:'none',value: 'none', label: 'None' }, {id:'auto', value: 'auto', label: 'Auto' }]
  
  orgBrand.backgrounds.forEach((bg)=>{
    if(bg.type=='image'){
      bgStyles.push({ value: bg.src, type: 'image',id:bg.id })
    }else{
      bgStyles.push({ value: bg.rgba, type: 'solid',id:bg.id  })
    }
  })

  const handleUpdateClayOverlayTitle = (title) => {
    updateClipMetadata(clip.id,{clayTitle:title})
  } 

  //if there is no profile picture then don't show the placeholder
  const handleUpdateHidePlaceholderClayProfileImage = (isHidden) => {
    updateClipMetadata(clip.id,{hidePlaceholderClayProfileImage:isHidden})
  }  

  const orgDomain = getOrgDomain()
  const isClay = (orgDomain=='clay.com' || orgDomain=='clay.run') 

  const handleUpdateMetadata = (field,value) => { 
    updateClipMetadata(clip.id,{[field]:value})
  }  

  const [hideBookmarksBar, setHideBookmarksBar] = useState(clip.metadata.deviceFrame === 'colorBB');

  useEffect(() => {
    if (clip.metadata.deviceFrame === 'color' || clip.metadata.deviceFrame === 'colorBB') {
      updateClipMetadata(clip.id, { deviceFrame: hideBookmarksBar ? 'colorBB' : 'color' });
    }
  }, [hideBookmarksBar]);
  

  // Check if the clip has a linked clip
  const isLinkedClip = clip.metadata.linkedClipId ? true : false;

  const singleChunk = useMemo(() => clip.recordingChunks.length === 1, [clip.recordingChunks])

  let primaryLabel
  let secondaryLabelInitial
  
  if(singleChunk && clip.recordingChunks){
    primaryLabel = clip.recordingChunks[0].inferredTitle
    secondaryLabelInitial = clip.recordingChunks[0].inferredSubtitle
  }

  const [secondaryLabel, setSecondaryLabel] = useState(secondaryLabelInitial);

  useEffect(() => {
    if (singleChunk && clip.recordingChunks) {
      setSecondaryLabel(clip.recordingChunks[0].inferredSubtitle);
    }
  }, [clip.id, singleChunk, clip.recordingChunks]);





  // <Tooltip.Root delayDuration={0}>
  //             <Tooltip.Trigger asChild> 
  //               <button className='editor-detailPanel-header-button editor-detailPanel-header-button--start'>              
  //                 {/* <Icon name='rectangleMedium' /> */}
  //                 {/* <Icon name='window' /> */}
  //                 <Icon name='laptop' />
  //               </button>   
  //             </Tooltip.Trigger>        
  //             <Tooltip.Portal>
  //               <Tooltip.Content side="right" className="tooltip tooltip--detailsMode">
  //                 <span>Edit display type</span>
  //               </Tooltip.Content>
  //             </Tooltip.Portal>
  //           </Tooltip.Root>   

  const showHasLinkedCamera = true

  // Update local state when clip changes
  useEffect(() => {
    setPlaybackRate(clip.clipPlaybackRate || 1.0);
  }, [clip.id, clip.clipPlaybackRate]);

  // Handle playback rate change
  const handlePlaybackRateChange = (newRate) => {
    setPlaybackRate(newRate);
    changeVideoClipPlaybackRate(clip.id, newRate);
  };
  const windowPadding = clip.videoWindowPadding()

  return (
    <>
      <div className='editor-detailPanel-header'>
        
      
        <DropdownMenu.Root>
          
          <DropdownMenu.Trigger asChild>
            <button className='editor-detailPanel-header-button editor-detailPanel-header-button--start'>
              <Icon name={activeDisplayTypeIconName} />
              <div className='editor-detailPanel-header-button-tooltipContainer'>
                <div className='tooltip tooltip--detailsMode'>
                  Change display type
                </div>
              </div>
            </button>

          </DropdownMenu.Trigger>          

        <DropdownMenu.Content
          align="start"
          alignOffset={-5}
          className='dropdownMenu dropdownMenu--screenRecordingDisplay forceDarkTheme'
        >           
             <div className='dropdownMenu-subHeader'>
               Select display type
             </div>
             
             {isChromeRecording &&
             <DropdownMenu.Item 
               onSelect={() => setDisplayType('colorFrame')} 
               className={`${!allowWindowMode ? 'dropdownMenu-item--disabled' : ''} ${
                 displayType === 'colorFrame' ? 'dropdownMenu-item--active' : ''
               }`}
             >
               <div className='dropdownMenu-item-iconContainer'>
                 <Icon name='rectangleMedium' />
               </div>
               Color Frame
             </DropdownMenu.Item>
            }
             <DropdownMenu.Item 
               onSelect={() => setDisplayType('window')} 
              className={`${!allowWindowMode ? 'dropdownMenu-item--disabled' : ''} ${
                 displayType === 'window' ? 'dropdownMenu-item--active' : ''
               }`}
             >
               <div className='dropdownMenu-item-iconContainer'>
                 <Icon name='window' />
               </div>
               Window
             </DropdownMenu.Item>

            <DropdownMenu.Item onSelect={() => setDisplayType('desktop')}
              className={`${displayType === 'desktop' ? 'dropdownMenu-item--active' : ''}`}
            >
              <div className='dropdownMenu-item-iconContainer'>
                <Icon name='laptop' />
              </div>
              Desktop
            </DropdownMenu.Item>

        </DropdownMenu.Content>
      </DropdownMenu.Root>


        <div className='editor-detailPanel-header-vSpacer' />        
             
        {isLinkedClip && 
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild> 
              <button 
                onClick={() => unlinkScreencast(clip.id)}
                className='editor-detailPanel-header-button editor-detailPanel-header-button--camera'
              >              
                <Icon name='speakerRectangleMedium' />             
              </button>   
            </Tooltip.Trigger>        
            <Tooltip.Portal>
              <Tooltip.Content side="left" className="tooltip tooltip--detailsMode">
                <span>Delink camera</span>
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        }    


        
        <Tooltip.Root delayDuration={800}>
          <Tooltip.Trigger asChild> 
            <button 
              onClick={() => setShowAdvanced(!showAdvanced)}
              data-active-state={showAdvanced ? 'active' : 'inactive'}
              className='editor-detailPanel-header-button editor-detailPanel-header-button--details'
            >              
              <Icon name='ellipses' />             
            </button>   
          </Tooltip.Trigger>        
          <Tooltip.Portal>
            {!showHasLinkedCamera &&             
              <Tooltip.Content side="left" className="tooltip tooltip--detailsMode">
                <span>{showAdvanced ? 'Show standard controls' : 'Show Pro controls'}</span>
              </Tooltip.Content>       
            }     
          </Tooltip.Portal>
        </Tooltip.Root>  
        

      </div>   

    


      

      {singleChunk && 
        <div className='editor-detailPanel-screenLabels-input'>        
          <input 
            className='editor-detailPanel-screenLabels-input-input'
            value={secondaryLabel}
            onChange={(e) => setSecondaryLabel(e.target.value)}
            disabled
          />
          
          <div className='editor-detailPanel-screenLabels-input-preview'>
            <div className='editor-detailPanel-screenLabels-input-preview-primaryLabel'>
              {primaryLabel}
            </div>
            <div className='editor-detailPanel-screenLabels-input-preview-secondaryLabel'>
              {secondaryLabelInitial}
            </div>
          </div>        
        </div>
      }

      {!singleChunk && 
        <div className='editor-detailPanel-screenLabels-mixed'>        
          <div className='editor-detailPanel-screenLabels-mixed-label'>        
            Mixed Recording
          </div>
        </div>
      }
      

      
      
      {/*}
        <div className='editor-detailPanel-row'>          
          <DPToggleGrid
            items={displayTypes}
            activeItem={activeDisplayType}
            onItemClick={updateActiveDisplayType} 
          />
        </div>    
        */}  

      
      {/*}
      
      <div className='editor-detailPanel-divider' />

      
      <div className='editor-detailPanel-row'>
        <button 
          onClick={toggleTrimMode}          
          className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
        >
          <div className='dpButton-label'>
            Edit Trim
          </div>
        </button>
      </div>
      */}
      

      

      <div className='editor-detailPanel-divider' />
      
      {showAdvanced && (clip.metadata.deviceFrame === 'color' || clip.metadata.deviceFrame === 'colorBB') &&  
        <>
          <div className='editor-detailPanel-negativeTopMargin' />
          <DPCheckbox 
            label='Hide bookmarks bar'
            checked={hideBookmarksBar}
            setChecked={setHideBookmarksBar}
          />
        </>
      }
      

      {showAdvanced &&  displayType!== 'desktop' &&          
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Background
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPColorPicker                              
              activeColorId={activeBg}
              onChange={setBgStyle}
              options={bgStyles}          
            />       
          </div> 
        </>
      }

             
      {showAdvanced && displayType !== 'desktop' &&
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <DPSlider
              min={0}
              max={30}
              value={windowPadding}
              setValue={setVideoWindowPadding}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              step={1}
              label="Window Padding"
            />
          </div>
        </>
      }
      

      {/*}
      {hasChunks && activeDisplayType === 'window' &&  
        <>          
          <div className='editor-detailPanel-row'>  
            <div className='editor-detailPanel-row-label'>
              Display
            </div>
            <div className='editor-detailPanel-row-hSpacer' />                   
            <DPSelectMenu
              value={activeScreenDevice}
              onValueChange={setColorFrame}
              options={windowStyleOptions}
              width={118}
            />        
          </div>
        </>
      }   
      */}

      {/*}
      {
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Background
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPColorPicker                              
              activeColorId={activeBg}
              onChange={setBgStyle}
              options={bgStyles}          
            />       
          </div>   
        </>
      }    
      */}   

        

      <div className='editor-detailPanel-negativeTopMargin' />
      <div className='editor-detailPanel-row'>        
        <DPSlider 
          min={0.5}
          max={2.5}
          step={0.05}
          value={playbackRate}
          setValue={handlePlaybackRateChange}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label="Playback Rate"
          width={222}
        />
      </div>

      
      
      {isClay && showAdvanced &&
        <>
        <div className='editor-detailPanel-divider' />

        <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Clay Title
          </div>
          <div className='editor-detailPanel-row-hSpacer' />            
          <DPTextInput            
            value={clip.metadata.clayTitle}
            setValue={handleUpdateClayOverlayTitle}
            width={120}
          />
        </div>

          <div className='editor-detailPanel-negativeTopMargin' />
          <DPCheckbox 
            label='Hide placeholder Clay image'
            checked={clip.metadata.hidePlaceholderClayProfileImage}
            setChecked={handleUpdateHidePlaceholderClayProfileImage}
          />
        </>      
      }      

      
    
      <div className='editor-detailPanel-divider' />

      <DPItemAnimationPanel 
        item={clip}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={props.clip.metadata.startTransitionType}
        activeEndTransition={props.clip.metadata.endTransitionType}
        itemType='screenVideo'
      />


     {/* <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Playback rate
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          min={0.5}
          max={10}
          value={playbackRate}
          setValue={setPlaybackRate}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='cornerRadius'              
        />
      </div>*/}
      
    </>
  );
};

export default EditorDetailPanelScreen;
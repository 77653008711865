import React, { useRef, useEffect, useState,memo }  from 'react';
import EditorTimelineClipContents from './EditorTimelineClipContents'
import EditorTimelineClipFreezeSegments from './EditorTimelineClipFreezeSegments'
import EditorTimelineClipSkipSegments from './EditorTimelineClipSkipSegments'
import EditorTimelineClipWebcamPhases from './EditorTimelineClipWebcamPhases'
import EditorTimelineClipContextMenuContents from './EditorTimelineClipContextMenuContents'
import * as ContextMenu from '@radix-ui/react-context-menu'
import { Rnd } from 'react-rnd';
import {timeToPixels} from '../../utils/timeToPixels'
import {pixelsToTime} from '../../utils/pixelsToTime'

//while dragging in dndMode we show the proposedClip location which is where it will be when dropped
const ResizeHandle = ({onMouseDown, onMouseUp,onMouseEnter,onMouseLeave}) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={(e) => e.stopPropagation()} className='editor-timeline-clip--draggableHandle' />    
);


const leftHandleStyle = {      
  marginTop: '-1px',
  opacity: 0.5,
  width: '8px',
  height: 'calc(100% + 2px)',
  left: '0px',      
  cursor: 'grab',      
  zIndex: 100,
}

const rightHandleStyle = {        
  marginTop: '-1px',
  width: '8px',
  height: 'calc(100% + 2px)',
  right: '0px', 
  opacity: 0.5,     
  cursor: 'grab',
  zIndex: 100,
}



const EditorTimelineClip = (props) => {

 const { 
    clip,
    isActive,
    draggablePositionX,
    draggablePositionY,
    handleChangeSegmentPlaybackRate,
    isDnDMode,
    isDragging,
    dragClipId,
    sceneGap,
    pixelsPerSec,
    yOffset,
    devMode,
    TRACK_HEIGHT,
    CLIP_HEIGHT,
    ZOOM_CLIP_HEIGHT,
    scenes,
    scrollRef,
    setIsDraggingSegment,
    showWebcamPhasesForClipId,
    setShowWebcamPhasesForClipId,
    preVideoResizeSnapshot,
    isVideoResizeModeActive,
    handleTrimToClipEdge
  } 
  = props

// // // // // // // // /////// TESTING

//   const renderCount = useRef(0);
//   const prevProps = useRef(props);

//   useEffect(() => {
//     renderCount.current += 1;
    
//     const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
//       if (prevProps.current[key] !== value) {
//         acc[key] = {
//           from: prevProps.current[key],
//           to: value
//         };
//       }
//       return acc;
//     }, {});

//     if(clip.id=='15248887'){
//     if (Object.keys(changedProps).length > 0) {
//       console.log(`[EditorTimelineClip] Render #${renderCount.current}. Changed props:`, changedProps);
//     } else {
//       console.log(`[EditorTimelineClip] Render #${renderCount.current}. No props changed.`);
//     }
//     }
//     prevProps.current = props;
//   });
// // // // // // // //   ///////////////////



  //when resizing a video clip, we want to show the max of the new duration and the original duration
  let clipDuration = clip.duration
  let clipStartTime = clip.startTime
    
  if(preVideoResizeSnapshot  && (preVideoResizeSnapshot.videoClipId==clip.id || preVideoResizeSnapshot.webcamClipId==clip.id)){

    if(preVideoResizeSnapshot.direction=='right'){
      clipDuration = Math.max(preVideoResizeSnapshot.endTime - preVideoResizeSnapshot.startTime, clip.duration)
    }else{
     clipStartTime = Math.min(preVideoResizeSnapshot.startTime,clip.startTime)
      clipDuration = clip.endTime -clipStartTime
    }
  }



  const rndRef = useRef(null);

  let clipType = clip.type  
  
  if(clip.type === 'video' && clip.isBasicVideo){
    clipType = 'basicVideo'
  }else if(clip.type === 'video' && !clip.isBasicVideo){
    clipType = 'screenVideo'
  }  

  

  ///temp
  if(clip.type=='webcam' && clip.metadata.isVariable && !clip.metadata.hasInstructions){
    clipType='webcamScript'
  }
  if(clip.type=='webcam' && clip.metadata.isVariable && clip.metadata.hasInstructions){
    clipType='webcamInstructions'
  }
  if(clip.type=='webcam' && !clip.metadata.isVariable){
    clipType='webcamRecording'
  }

  if(clip.type=='webcam' && clip.metadata.linkedClipId){
    clipType='linkedWebcamRecording'
  }

  //Temp for slides
  if(clipType=='slide'){
    clipType='textSlide'
  }
  //////



  const canHaveSegments = clipType === 'screenVideo' || clipType === 'basicVideo'

  const canChangePlaybackRate = clipType === 'screenVideo'

  //You can't resize audio clips or webcam recordings
  //You can resize "placeholder" webcam clips
  let canResize = true 
  // if(clip.type=='audio' || (clip.type=='webcam' && !clip.metadata.isVariable)){
  //   canResize = false
  // }
  if(clip.type=='audio' ){
    canResize = false
  }

  let minWidth=0
  let maxWidth = Infinity

  ////March 18th take out max width for video clips- not sure why we had it
  // if(clip.type=='video'){
  //   maxWidth = 1500 
  //   minWidth = 100
  // }

  if(clip.type=='webcam' && clip.metadata.isVariable){
    minWidth = clip.minDuration * pixelsPerSec
  }

  const draggableClipTopBottomPadding = 5 

  const showRnd = !isDragging || (clip.id==dragClipId)

  //

  let renderClipState = 'push'
  if(isDnDMode && clip.id == dragClipId){
    renderClipState = 'activePreview'
  }
  if(isDnDMode && clip.id != dragClipId){
    renderClipState = 'passivePreview'
  }

  //

  let draggableClipState = 'push'
  if(isDnDMode){
    draggableClipState = 'dnd'
  }

  //

  //const clipLeft = (proposedStartTime * pixelsPerSec) + sceneGapOffset
  const clipLeft = timeToPixels(clipStartTime, pixelsPerSec, scenes, sceneGap)
  const clipWidth = clipDuration * pixelsPerSec
  const clipHeight = clipType === 'zoom' ? ZOOM_CLIP_HEIGHT : CLIP_HEIGHT

  let marginTop = yOffset
  if(clipType === 'zoom'){
    marginTop = 10
  }

  //
  //const isActive = props.activePanelClip && props.activePanelClip.id==clip.id
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [hoveredHandle, setHoveredHandle] = useState(null); // 'left', 'right', or null
  const handleMouseEnterLeft = () => setHoveredHandle('left');
  const handleMouseLeaveLeft = () => setHoveredHandle(null);
  const handleMouseEnterRight = () => setHoveredHandle('right');
  const handleMouseLeaveRight = () => setHoveredHandle(null);


 const [resizingHandle, setResizingHandle] = useState(null);

  const customHandleResizeStop = (e, direction, ref, delta, position) => {
    handleResizeStop(e, direction, ref, delta, position);
    setResizingHandle(null);
  };


  const customHandleResizeStart = (e,direction) => {
    handleResizeStart(clip,direction)
    setResizingHandle(direction)
  };

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  useEffect(() => {
    if (contextMenuOpen) {
      const handleScroll = () => {
        document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
        // hack to close the context menu
      };

      const scrollElement = scrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener('scroll', handleScroll, { passive: true });

        // Clean up function to remove the event listener
        return () => {
          scrollElement.removeEventListener('scroll', handleScroll);
        };
      }
    }
  }, [contextMenuOpen]); // Only re-run this effect when contextMenuOpen changes


  let voiceoverRequiresUpdate = false
  if(clip.type=='audio' && clip.requiresUpdate){
    voiceoverRequiresUpdate= true
  }

  const [startX, setStartX] = useState(0);  
  const draggingCursorTimeMs = 200; 
  const draggingCursorDelta = 2; 
  const clickDelta = 6;
  const timeoutRef = useRef(null);


   const handleDragStart = (clip,e) => {
    const canDnD = clip.type!='audio' && clip.type!='webcam'
    const isDnDMode = e.metaKey && !e.shiftKey & canDnD
    const isAltDragMode = e.altKey && e.shiftKey
    const isDragPullMode = e.metaKey && e.shiftKey
    props.onDragStart(clip,isDnDMode,isDragPullMode,isAltDragMode)    
    setStartX(e.clientX);
    const timeoutId = setTimeout(() => { // Set a timeout to force dragging cursor after draggingCursorTime seconds
      props.setShowDraggingCursor(true);
    }, draggingCursorTimeMs);
    props.handleSetActivePanelClip(clip);
    // Store the timeout ID in a separate ref so we can clear it later
    timeoutRef.current = timeoutId;
    if(props.isDnDMode){
      props.calculateAndSetDropLines(clip,0,sceneGap)
    }
  };


  const onDragClip = (e,sceneId, clip,dx,d) => {
    let metaKeyIsPressed = false 
    if(e.metaKey){
      metaKeyIsPressed = true
    }
    const clipId = clip.id 
    const newStartTime = pixelsToTime(dx, pixelsPerSec, scenes, sceneGap)
    if (Math.abs(dx - startX) > draggingCursorDelta) {
      props.setShowDraggingCursor(true);
    }

    const newDirection = d.deltaX > 0 ? 'right' : d.deltaX < 0 ? 'left' : null;
    if (newDirection !== props.dragDirection) {
      props.setDragDirection(newDirection);
    }

    props.handleDragClip(sceneId,clip,newStartTime,metaKeyIsPressed)
     if(props.isDnDMode){
      props.calculateAndSetDropLines(clip,dx,sceneGap)
    }
  }


const handleDragStop = (clip, e) => {   
   props.setDragDirection(null) 
    const diffX = Math.abs(e.clientX - startX);    
    if (diffX < clickDelta) {
      // It's a click, not a drag
      const targetRect = e.target.getBoundingClientRect(); // Using e.target directly
      if (targetRect) {
        const clickX = startX - targetRect.left;
        const seekTime = clip.startTime + (clickX / pixelsPerSec);
        props.handleSeek(seekTime);        
      }      
    }
    props.setShowDraggingCursor(false);
    clearTimeout(timeoutRef.current);
    if(props.isDnDMode){
      const dropPostion = props.dropLines.closestPosition
      let dropTime = null
     
      const activeDropType = props.dropLines.activeDropType 
      if(dropPostion || dropPostion==0){
        dropTime = pixelsToTime(dropPostion, pixelsPerSec, props.scenes, props.sceneGap)
      }
      props.onDragEnd(clip,dropTime,activeDropType)
    }else{
      props.onDragEnd();
    }
  };

  const handleResize = (clip, e, direction, ref, delta, position) => {
    let newWidth = ref.offsetWidth;
    let newDuration = newWidth / pixelsPerSec
    props.onResize(clip,newDuration,direction)
  };

  const handleResizeStart = (clip,direction) =>{
    props.onResizeStart(clip,direction)
    props.setIsResizing(true)
    props.setResizeDirection(direction)
  }

  const handleResizeStop = () =>{
    props.onResizeStop()
    props.setIsResizing(false)
    props.setResizeDirection(null)
  }

  let opacity = 1

  const voiceoverWebcamClip = clip.type === 'audio' && clip.parentWebcamClip

  if(voiceoverWebcamClip){
    marginTop = 80    
    opacity = 0.5
  }
  const isLinkedClip = clip.metadata.linkedClipId?true:false
  const showWebcamPhases = showWebcamPhasesForClipId==clip.id


  return (
    <>  
      {showRnd && !voiceoverWebcamClip &&

          <ContextMenu.Root modal={false} key={clip.id}  onOpenChange={(open) => {
            setContextMenuOpen(open);
            if (open) {
              props.handleSetActivePanelClip(clip);
            }
          }}> 
          <ContextMenu.Trigger 
            asChild
             onContextMenu={(event)=>{
              const clipRef = rndRef.current 
               if (clipRef && clipRef.props && clipRef.props.position) {
                const scrollAmount = scrollRef.current.scrollLeft                
                const clickX = event.clientX - clipRef.props.position.x + scrollAmount - 11 - 5; // 11 is left scroll gutter
                const seekTime = clip.startTime + (clickX / pixelsPerSec);
                props.handleSeek(seekTime);
              }
            }}                        
            >
            <Rnd     
              ref={rndRef}                     
              key={`draggable_${clip.id}`}
              className='editor-timeline-clip--draggableContainer'
              bounds="parent"   
              minWidth={minWidth}   
              maxWidth={maxWidth}            
              position={ {x:draggablePositionX,y:draggablePositionY}}
              size={{ width: clipDuration * pixelsPerSec, height: clip.type === 'zoom' ? ZOOM_CLIP_HEIGHT + draggableClipTopBottomPadding : CLIP_HEIGHT + draggableClipTopBottomPadding  }}
              onDragStart={(e)=>{handleDragStart(clip,e)}}
              onDragStop={(e)=>{handleDragStop(clip,e)}}              
              onDrag={(e, d) => onDragClip(e,clip.sceneId, clip, d.x,d)}
              onResizeStart={customHandleResizeStart}
              onResizeStop={customHandleResizeStop}
              onResize={(e, direction, ref, delta, position) => handleResize(clip, e, direction, ref, delta, position)}            
              enableResizing={{top: false, right: canResize, bottom: false, left: canResize, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}
              dragAxis={isDnDMode ? "both" : "x"}                            
              style={{cursor: 'pointer'}}              
              resizeHandleStyles={{
                left: leftHandleStyle,
                right: rightHandleStyle,
              }}
              resizeHandleComponent={{
                left: <ResizeHandle onMouseEnter = {handleMouseEnterLeft} onMouseLeave={handleMouseLeaveLeft}/>,
                right: <ResizeHandle onMouseEnter = {handleMouseEnterRight} onMouseLeave={handleMouseLeaveRight}/>,
              }}
            >
              <div 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={()=>props.handleSetActivePanelClip(clip)}
                className={"editor-timeline-clip editor-timeline-clip--draggable" + (draggableClipState ? ` editor-timeline-clip--draggable--${draggableClipState} ` : '') + (clipType ? ` editor-timeline-clip--draggable--${clipType} ` : '')}>
                
                {devMode && <div className='draggableTrackClip-inner'> {clip.id} </div>}              
                
                {!showWebcamPhases &&
                  <EditorTimelineClipContents 
                    clip={clip}
                    clipType={clipType}   
                    isActive={isActive}    
                    hoveredHandle={hoveredHandle}  
                    resizingHandle={resizingHandle}     
                    webcamChunks={props.webcamChunks}         
                    preVideoResizeSnapshot={preVideoResizeSnapshot}
                  />   
                }                
              </div>
            </Rnd>                  
          </ContextMenu.Trigger>
          <EditorTimelineClipContextMenuContents
            clip={clip}
            cutClip={props.cutClip}
            copyClip={props.copyClip}
            duplicateClip={props.duplicateClip}
            addFreezeFrame={props.addFreezeFrame}
            addSkipSegment={props.addSkipSegment}
            canChangePlaybackRate={canChangePlaybackRate}
            changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}
            deleteClip={props.deleteClip}
            splitRecordingClip={props.splitRecordingClip}
            unlinkScreencast={props.unlinkScreencast}
            toggleAddSkipSegmentMode={props.toggleAddSkipSegmentMode}
            setShowWebcamPhasesForClipId={setShowWebcamPhasesForClipId}
            handleTrimToClipEdge={props.handleTrimToClipEdge}
          />                        
        </ContextMenu.Root>            
      }
    
      

      <div 
        data-clip-type={clipType}        
        data-clip-active={isActive ? true : false}
        data-clip-hovered={isHovered ? true : false}
        data-clip-context-menu-open={contextMenuOpen ? true : false}
        data-clip-promotezindex-state={contextMenuOpen ? true : false}
        data-clip-voiceover-updating={voiceoverRequiresUpdate ? true : false}
        data-clip-voiceover-webcam={voiceoverWebcamClip ? true : false}        
        style={{left: `${clipLeft}px`, width: `${clipWidth}px`, height: `${clipHeight}px`, marginTop: `${marginTop}px`,opacity:opacity}}
        className={'editor-timeline-clip editor-timeline-clip--render ' + (renderClipState ? ` editor-timeline-clip--render--${renderClipState} ` : '')}
      >      
        <EditorTimelineClipContents 
          clip={clip}
          clipType={clipType}
          canResize={canResize}  
          hoveredHandle={hoveredHandle}   
          resizingHandle={resizingHandle}   
          webcamChunks={props.webcamChunks}
          isDragging={isDragging}
          showWebcamPhases={showWebcamPhases}
          preVideoResizeSnapshot={preVideoResizeSnapshot}
        />      
      </div>
  


      {/* Webcam Phases */}
   
      {showWebcamPhases&& !isDnDMode &&
        <div data-clip-type={clipType} style={{left:`${clip.startTime*pixelsPerSec}px`}} className='editor-timeline-clip-webcamPhaseContainer'>
          <EditorTimelineClipWebcamPhases
            clip={clip}
            trimStart={clip.metadata.trimStart}
            clipWidth={clipDuration * pixelsPerSec}            
            scrollRef={scrollRef}                                    
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            TRACK_HEIGHT={TRACK_HEIGHT}
            clipHeight={CLIP_HEIGHT}
            sceneGap={sceneGap}
            setIsDraggingSegment={setIsDraggingSegment}
            clipType={clipType}
            updateClipMetadata={props.updateClipMetadata}
          />
        </div>
      }

      {canHaveSegments && !isDnDMode && !showWebcamPhases && !isLinkedClip && !isVideoResizeModeActive &&

        <div data-clip-type={clipType} data-clip-active={isActive ? true : false} style={{left:`${clip.startTime*pixelsPerSec}px`}} className='editor-timeline-clip-segmentContainer'>
          
          <EditorTimelineClipFreezeSegments          
            clip={clip}
            trimStart={clip.metadata.trimStart}
            clipWidth={clipDuration * pixelsPerSec}
            handleChangeSegmentPlaybackRate={handleChangeSegmentPlaybackRate}
            scrollRef={scrollRef}            
            handleSeek={props.handleSeek}          
            removeFreeze={props.removeFreeze}
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            TRACK_HEIGHT={TRACK_HEIGHT}
            clipHeight={CLIP_HEIGHT}
            sceneGap={sceneGap}
            setIsDraggingSegment={setIsDraggingSegment}
          />
          
          
          <EditorTimelineClipSkipSegments          
            clip={clip}
            trimStart={clip.metadata.trimStart}
            clipWidth={clipDuration * pixelsPerSec}
            toggleSkipSegment={props.toggleSkipSegment}
            handleChangeSkipSegmentDuration={props.handleChangeSkipSegmentDuration}       
            scrollRef={scrollRef}            
            handleSeek={props.handleSeek}          
            removeSkip={props.removeSkip}
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            TRACK_HEIGHT={TRACK_HEIGHT}
            clipHeight={CLIP_HEIGHT}
            sceneGap={sceneGap}
            handleResizeSkipSegmentStart={props.handleResizeSkipSegmentStart}
            handleResizeSkipSegmentStop={props.handleResizeSkipSegmentStop}
            setIsDraggingSegment={setIsDraggingSegment}
            resizingSkipSegmentHandle={props.resizingSkipSegmentHandle}
          />
          
        </div>
      }
    </>
  );
}
//);

// export default EditorTimelineClip;

//TODO when did we switch from using memo

const MemoizedEditorTimelineClip = memo(EditorTimelineClip);

export default EditorTimelineClip;



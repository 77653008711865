import React, { useMemo, useState, useCallback } from 'react';
import TranscriptPanelVoiceGutterDropdownButton from './TranscriptPanelVoiceGutterDropdownButton'

const TranscriptPanelVoiceGutter = ({activeTranscriptPanelAudioChunk,playClipFromTranscript,recalculateAudioClip,previewingAudioClipId,cancelPreviewingAudioClip,convertAudioClipIntoScript, scrollPosition}) => {

  let gutterHeight
  if(activeTranscriptPanelAudioChunk && activeTranscriptPanelAudioChunk.dimensions){
    gutterHeight = activeTranscriptPanelAudioChunk.dimensions.top + activeTranscriptPanelAudioChunk.dimensions.height
  }


  return (
    <>    
      <div style={{height: `${gutterHeight - 48}px`}} className='editor-transcriptPanel-voiceHeaderGutter'>
        {activeTranscriptPanelAudioChunk &&
        <TranscriptPanelVoiceGutterDropdownButton 
          clipId={activeTranscriptPanelAudioChunk.clipId}
          top={activeTranscriptPanelAudioChunk.dimensions.top - 12}
          height={activeTranscriptPanelAudioChunk.dimensions.height}
          playClipFromTranscript={playClipFromTranscript}
          recalculateAudioClip={recalculateAudioClip}
          previewingAudioClipId={previewingAudioClipId}
          cancelPreviewingAudioClip={cancelPreviewingAudioClip}
          convertAudioClipIntoScript={convertAudioClipIntoScript}
          activeTranscriptPanelAudioChunk={activeTranscriptPanelAudioChunk}
          scrollPosition={scrollPosition}
        />
}
        
      </div>
    </>
  );
};

export default TranscriptPanelVoiceGutter;
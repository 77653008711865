import { EditorState, Plugin } from "prosemirror-state"
import { history, undo, redo,closeHistory } from "prosemirror-history"
import timelineSchema from './schema/timelineSchema'
import { createClipPMNode,createScenePMNode } from './nodeCreators'

//User actions that we want to group into a tr
//drag
//resize clip 
// add clip
//delete 
//start time in clip node attrs is relative to the start of the scene not the absolute time in the timeline

const DEV_CONSOLE_LOGS = false

export class TimelineProsemirrorManager {

	constructor(setPMDocForDevMode) {
		this.setPMDocForDevMode=setPMDocForDevMode 
		this.initEditorState()
		this.editingAction = null; //drag,addClip etc
		this.currentTransaction = null
	}

	initEditorState() {
		this.doc = timelineSchema.nodes.doc.createAndFill();
		this.editorState = EditorState.create({
			doc: this.doc,
			plugins: [history()]
		});
	}

	startAction(actionType = 'default') {
		if (!this.currentTransaction) {
			this.currentTransaction = this.editorState.tr;
			this.editingAction = actionType||'action';
			this.currentTransaction.setMeta('actionType', actionType);
			if(DEV_CONSOLE_LOGS){
				console.log(`Starting action: ${actionType}`);
			}	
		}
	}

	startAutoZoomAction() {
		this.startAction('autoZoom')
	}

	endAutoZoomAction() {
		if (this.editingAction === 'autoZoom') {
			this.endAction();
		}
	}

	startAutoZoomAction() {
		this.startAction('autoZoom')
	}

	endAutoZoomAction() {
		if (this.editingAction === 'autoZoom') {
			this.endAction();
		}
	}

	endAction() {
		if (this.currentTransaction && this.editingAction) {
			closeHistory(this.currentTransaction) //this prevents e.g adding lots of clips quickly from being grouped together in 1 undo step
			this.editorState = this.editorState.apply(this.currentTransaction);
			this.setPMDocForDevMode(this.editorState.doc);
			if(DEV_CONSOLE_LOGS){
				console.log(`Ending action: ${this.editingAction}`);
			}
			this.currentTransaction = null;
			this.editingAction = null;
		}
	}

	addSceneToPMDoc(scene,isInitialLoad){
		let tr = this.currentTransaction || this.editorState.tr;
		if(isInitialLoad){
			tr.setMeta('addToHistory', false)
		}
		const node =  createScenePMNode(scene);
		const mappedPos = tr.mapping.map(this.editorState.doc.content.size);
		tr.insert(mappedPos, node);
		if (!this.currentTransaction) {
			this.editorState = this.editorState.apply(tr);
			this.setPMDocForDevMode(this.editorState.doc);
		} else {
			this.currentTransaction = tr;
		}
	}

	addClipToPMDoc(clip,isInitialLoad) {
		let tr = this.currentTransaction || this.editorState.tr;
		if(isInitialLoad){
			tr.setMeta('addToHistory', false)
		}
		const node = createClipPMNode(clip);
		const mappedPos = tr.mapping.map(this.editorState.doc.content.size);
		tr.insert(mappedPos, node);
		if(DEV_CONSOLE_LOGS){
			console.log(`Inserting clip ${clip.id} at position ${mappedPos}.`);
		}
		if (!this.currentTransaction) {
			this.editorState = this.editorState.apply(tr);
			this.setPMDocForDevMode(this.editorState.doc);
		} else {
			this.currentTransaction = tr;
			if(DEV_CONSOLE_LOGS){
				console.log('Added clip insertion to current transaction.');
			}
		}
	}

/////////////////////// SLIDES ///////////////////////////
syncSlideClip(updatedClip) {
//	console.log('sync slide clip here------')
	if(updatedClip){
  const clipId = updatedClip.id;
  let tr = this.currentTransaction || this.editorState.tr;
  let updated = false;

  this.editorState.doc.descendants((node, pos) => {
    if (node.type.name === 'slideClip' && node.attrs.id === clipId) {
      // Create a completely new node using the existing createSlideClipPMNode function
      const newNode = createClipPMNode(updatedClip);
      // Replace the entire slideClip node with the new one
      tr = tr.replaceWith(pos, pos + node.nodeSize, newNode);
      updated = true;
    }
  });

  if (updated) {
    if (!this.editingAction) {
      // If not in a grouped action, apply the transaction immediately
      this.editorState = this.editorState.apply(tr);
      this.setPMDocForDevMode(this.editorState.doc);
   //   console.log('SYNC SLIDE CLIP')
    } else {
      // If in a grouped action, update the current transaction
      this.currentTransaction = tr;
    }
  }
}
}

	onSlideDragResizeStart(){
		this.startAction('slideDragResize')
	}

	onSlideDragResizeEnd(){
		if (this.editingAction === 'slideDragResize') {
			this.endAction();
		}
	}
////////////////////////////////////////
//// dragging
	onDragStart() { //initialize a new transaction for batching updates
		this.startAction('drag')
	}

	onDragEnd() {
		if (this.editingAction === 'drag') {
			this.endAction();
		}
	}

	updateSceneIndex(sceneId, newSceneIndex) {
		let tr = this.currentTransaction || this.editorState.tr;
		let updated = false 
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === sceneId) {
				const updatedAttrs = { ...node.attrs,sceneIndex:newSceneIndex };
				const mappedPos = tr.mapping.map(pos)
				tr.setNodeMarkup(mappedPos, null, updatedAttrs);
				updated = true;
			}
		})
		if (updated) {
			if (!this.editingAction) {// If not in a transaction, apply the changes immediately
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	updateMultipleClipFields(updatesArray, preventUndo) {
		if(updatesArray.length) {
			let tr = this.currentTransaction || this.editorState.tr;
			if(preventUndo) {
				tr.setMeta('addToHistory', false)
			}
			let updated = false;
			updatesArray.forEach(update => {
				const { clipId, ...fields } = update;
				this.editorState.doc.descendants((node, pos) => {
					if (node.attrs.id === clipId) {
						const updatedAttrs = { ...node.attrs, ...fields };
						const mappedPos = tr.mapping.map(pos); // Add position mapping
						tr.setNodeMarkup(mappedPos, null, updatedAttrs);
						updated = true;
					}
				});
			});
			
			if (updated) {
				if (!this.editingAction) {
					this.editorState = this.editorState.apply(tr);
					this.setPMDocForDevMode(this.editorState.doc);
				} else {
					this.currentTransaction = tr;
				}
			}
		}
	}

	createDocument(contentArray) {
		this.doc = timelineSchema.nodes.doc.createAndFill({}, contentArray);
		this.editorState = EditorState.create({
			doc: this.doc,
			plugins: [history()]
		});
		this.setPMDocForDevMode(this.editorState.doc)
	}

	updateNodeMetadata(nodeId, newMetadata) {
		let transaction = this.currentTransaction || this.editorState.tr
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId) {
				const updatedAttrs = { ...node.attrs, metadata: { ...node.attrs.metadata, ...newMetadata } };
				transaction.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		});
		if (updated) {
			if(!this.editingAction){
	
				this.editorState = this.editorState.apply(transaction);
				this.setPMDocForDevMode(this.editorState.doc);
			}else{

				this.currentTransaction = transaction;
			}			
			// this.editorState = this.editorState.apply(transaction);
		}
		return updated;
	}

	//Use this for things like updating the placeholder img src (we dont want to undo to that state)
	updateNodeMetadataSilent(nodeId, newMetadata) {
		//const transaction = this.editorState.tr;
		let transaction = this.currentTransaction || this.editorState.tr
		// Set meta flag to prevent this from being added to history
		transaction.setMeta('addToHistory', false);
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId) {
				const updatedAttrs = { ...node.attrs, metadata: { ...node.attrs.metadata, ...newMetadata } };
				transaction.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		});
		if (updated) {
			if(!this.editingAction){
				
				this.editorState = this.editorState.apply(transaction);
				this.setPMDocForDevMode(this.editorState.doc);
			}else{
		
				this.currentTransaction = transaction;
			}
		}
		return updated;
	}
	
	//TODO we can combine this with normal updtae
	updateNodeAttrsSilent(clip) {

    let tr = this.currentTransaction || this.editorState.tr;
    // Set meta flag to prevent this from being added to history
    tr.setMeta('addToHistory', false);
    let updated = false;
    this.editorState.doc.descendants((node, pos) => {
        if (node.attrs.id === clip.id) {
            // Create a completely new set of attributes, preserving the node's existing metadata structure
            let updatedAttrs = {...node.attrs, ...clip, metadata: {...clip.metadata}};
    
            tr = tr.setNodeMarkup(pos, null, updatedAttrs);
            if(DEV_CONSOLE_LOGS) {
                console.log(`Silently updating node ${clip.id} at position ${pos}`);
            }
            updated = true;
        }
    });

    if (updated) {
        if (!this.editingAction) {
            this.editorState = this.editorState.apply(tr);
            this.setPMDocForDevMode(this.editorState.doc);
            if(DEV_CONSOLE_LOGS) {
                console.log('Applied silent transaction immediately.');
            }
        } else {
            this.currentTransaction = tr;
            if(DEV_CONSOLE_LOGS) {
                console.log('Added silent updates to current transaction.');
            }
        }
    }
    return updated;
}

	// updateNodeAttrs(clip,message){
	// 	let tr = this.currentTransaction || this.editorState.tr
	// 	let updated = false
	// 	this.editorState.doc.descendants((node, pos) => {
	// 		if (node.attrs.id === clip.id) {
	// 			let updatedAttrs = {...node.attrs, ...clip, metadata:{...clip.metadata}};
	// 			tr = tr.setNodeMarkup(pos, null, updatedAttrs);
	// 			if(DEV_CONSOLE_LOGS){
	// 				console.log(`Updating node ${clip.id} at position ${pos}: ${message}`);
	// 			}
	// 			updated = true;
	// 		}
	// 	});
	// 	if (updated) {
	// 		if (!this.editingAction) {
	// 			this.editorState = this.editorState.apply(tr);
	// 			this.setPMDocForDevMode(this.editorState.doc);
	// 			if(DEV_CONSOLE_LOGS){
	// 				console.log('Applied transaction immediately.');
	// 			}
	// 		} else {
	// 			this.currentTransaction = tr;
	// 			if(DEV_CONSOLE_LOGS){
	// 				console.log('Added updates to current transaction.');
	// 			}
	// 		}
	// 	} 
	// }

	updateNodeAttrs(clip, message) {
		let tr = this.currentTransaction || this.editorState.tr;
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
		if (node.attrs.id === clip.id) {
			const mappedPos = tr.mapping.map(pos);
			if (mappedPos < tr.doc.content.size) {
				const nodeAtPos = tr.doc.nodeAt(mappedPos);
				if (nodeAtPos && nodeAtPos.attrs.id === clip.id) {
					const updatedAttrs = {...node.attrs, ...clip, metadata: {...clip.metadata}};
					tr = tr.setNodeMarkup(mappedPos, null, updatedAttrs);
					if(DEV_CONSOLE_LOGS) {
						console.log(`Updating node ${clip.id} at position ${mappedPos}: ${message}`);
					}
					updated = true;
				}
			}
		}
	});
	if (updated) {
		if (!this.editingAction) {
			this.editorState = this.editorState.apply(tr);
			this.setPMDocForDevMode(this.editorState.doc);
			if(DEV_CONSOLE_LOGS) {
				console.log('Applied transaction immediately.');
			}
		} else {
		this.currentTransaction = tr;
		if(DEV_CONSOLE_LOGS) {
			console.log('Added updates to current transaction.');
		}
		}
		}
		return updated; // Return whether the update was successful
	}

	deleteNodeFromPmDoc(nodeId) {
		let tr = this.currentTransaction || this.editorState.tr
		let found = false
		let posToDelete = null
		this.editorState.doc.descendants((node, pos) => {
			if (node.attrs.id === nodeId && !found) {
				posToDelete = pos;
				found = true;
			}
		})
		// If a clip was found, map the position and perform the deletion
		if (found && posToDelete !== null) {
			let mappedPos = tr.mapping.map(posToDelete);
			tr.delete(mappedPos, mappedPos + tr.doc.nodeAt(mappedPos).nodeSize);
			if (!this.editingAction) {
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	undo() {
		const result = undo(this.editorState, transaction => {
			this.editorState = this.editorState.apply(transaction);
			this.setPMDocForDevMode(this.editorState.doc);
		});
		return result;
	}

	redo() {
		const result = redo(this.editorState, transaction => {
			this.editorState = this.editorState.apply(transaction);
			this.setPMDocForDevMode(this.editorState.doc);
		});
		if (result) {
			return true
		} else {
			console.log('No redo operation to perform.');
			return false
		}
	}

	
	updateProjectSetting(field,value) { //backgroundID and playback rate
		const tr = this.editorState.tr;
		let updated = false;
		this.editorState.doc.descendants((node, pos) => {
			if (node.type.name === "projectSettings") {  // Assuming 'projectSettings' is the correct node type
				const updatedAttrs = { ...node.attrs, [field]:value };
				tr.setNodeMarkup(pos, null, updatedAttrs);
				updated = true;
			}
		})
		if (updated) {
			if (!this.editingAction) {
				this.editorState = this.editorState.apply(tr);
				this.setPMDocForDevMode(this.editorState.doc);
			} else {// If in a transaction, the changes are applied when the transaction is committed
				this.currentTransaction = tr;
			}
		}
	}

	// updateLinkedScreencast(displayClip, webcamClip, isLinking = true) {
	// 	let tr = this.currentTransaction || this.editorState.tr;
	// 	let updated = false;
	  
	// 	// Update both clips in a single transaction
	// 	this.editorState.doc.descendants((node, pos) => {
	// 	  if (node.attrs.id === displayClip.id) {
	// 		const updatedAttrs = {
	// 		  ...node.attrs,
	// 		  linkedClipId: isLinking ? webcamClip.id : null,
	// 		  linkedClipCaptureId: isLinking ? webcamClip.captureId : null
	// 		};
	// 		tr.setNodeMarkup(pos, null, updatedAttrs);
	// 		updated = true;
	// 	  }
	// 	  if (node.attrs.id === webcamClip.id) {
	// 		const updatedAttrs = {
	// 		  ...node.attrs,
	// 		  linkedClipId: isLinking ? displayClip.id : null,
	// 		  linkedClipCaptureId: isLinking ? displayClip.captureId : null
	// 		};
	// 		tr.setNodeMarkup(pos, null, updatedAttrs);
	// 		updated = true;
	// 	  }
	// 	});
	  
	// 	if (updated) {
	// 	  if (!this.editingAction) {
	// 		this.editorState = this.editorState.apply(tr);
	// 		this.setPMDocForDevMode(this.editorState.doc);
	// 	  } else {
	// 		this.currentTransaction = tr;
	// 	  }
	// 	}
	// }


	destroy() {
		if (this.currentTransaction) {
			this.editorState = this.editorState.apply(this.currentTransaction);
			this.currentTransaction = null;
			this.editingAction = null;
		}
		this.editorState = null;
		this.doc = null;
		this.setPMDocForDevMode = null;
	}


}

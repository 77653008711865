const blobCache = new Map();
const processingQueue = new Map();

export const fetchWebcamVideo = async (captureId, audioTransformation = {}) => {
  const cacheKey = `${captureId}-${audioTransformation?.type}-${audioTransformation?.voiceId}`;
  const queueKey = captureId;

  if (blobCache.has(cacheKey)) {
    return { 
      videoUrl: blobCache.get(cacheKey),
      voiceMatchFailed: false 
    };
  }

  while (processingQueue.has(queueKey)) {
    await processingQueue.get(queueKey);
  }

  const processPromise = (async () => {
    if (window.isElectron) {
      const { buffer, voiceMatchFailed } = await ipcRenderer.invoke('get-webcam-file-buffer', captureId, audioTransformation);
      console.log('voiceMatchFailed',voiceMatchFailed)
      const videoBlob = new Blob([buffer], { type: 'video/webm' });
      const videoURL = URL.createObjectURL(videoBlob);
      
      if (!voiceMatchFailed) {
        // Clean up old cache entries for this captureId
        for (const [key, url] of blobCache.entries()) {
          if (key.startsWith(captureId)) {
            URL.revokeObjectURL(url);
            blobCache.delete(key);
          }
        }
        blobCache.set(cacheKey, videoURL);
      }
      
      return { videoUrl: videoURL, voiceMatchFailed };
    }
  })();

  processingQueue.set(queueKey, processPromise);
  try {
    return await processPromise;
  } finally {
    processingQueue.delete(queueKey);
  }
};

//while processing check if the there is a file in filesystem that we can show
export const fetchPlaceholderWebcamVideo = async (captureId,userUploadFileExtension) => {
  console.log('fetchPlaceholderWebcamVideo',captureId,userUploadFileExtension)
  let videoPath = `webcamRecordings/${captureId}/webcam.mp4`;
  if(userUploadFileExtension){
    videoPath = `webcamRecordings/${captureId}/user_upload.${userUploadFileExtension}`;
  }
//  console.log('videoPath', videoPath);

  if (window.isElectron) {
    try {
      const port = process.env.REACT_APP_VIDEO_SERVER_PORT;
      const response = await fetch(`http://localhost:${port}/video/`+ videoPath);
      if (!response.ok) {
        throw new Error(`Failed to fetch video: ${response.status} ${response.statusText}`);
      }
      const videoBlob = await response.blob();
      const videoURL = URL.createObjectURL(videoBlob);
      return videoURL;
    } catch (error) {
      console.log('Error fetching video:', error);
      return null;
    }
  }
  return null;
};



import React, { useState } from 'react';
import Icon from '../../misc/Icon'
import LibraryPageHeaderGalleryDisplay from './LibraryPageHeaderGalleryDisplay'
import LibraryPageHeaderFilter from './LibraryPageHeaderFilter'
import LibraryPageHeaderSearch from './LibraryPageHeaderSearch'
import LibraryPageHeaderSort from './LibraryPageHeaderSort'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {archiveCollection} from '../../../utils/collections/archiveCollection'
import { useHistory } from 'react-router-dom';
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'

  function LibraryPageStickyHeader(props) {
    const { 
      subPage,
      displayMode,
      setDisplayMode, 
      isHome,
      isCollection,
      searchQuery,
      handleSearchChange,
      createNewDraft,
      sortOption,
      handleSortChange,
      activeCollection,
      allowChangeDisplayMode
    } = props

    
   
    let showSearch = true
    let showFilter = true

    let showGalleryDisplay = true
    let showSortList = true

    let showPrimaryNewBtn = true
    let showSecondaryNewBtn = true

    let galleryDisplay = 'grid'    

    if(isHome){
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = true
      showSecondaryNewBtn = false
    }

    if(isCollection){
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = false
      showSecondaryNewBtn = true
    }

    let subPageLabel
    let subPageIconName

    if(subPage === 'drafts'){
      subPageLabel = 'Drafts'  
      subPageIconName = 'drafts'
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = true
      showSecondaryNewBtn = false
    }

    if(subPage === 'org'){
      subPageLabel = getOrgNameLabel()  
      subPageIconName = 'drafts'
      showSearch = true
      showFilter = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = true
      showSecondaryNewBtn = false
    }


    if(subPage === 'deleted'){
      subPageLabel = 'Deleted'
      subPageIconName = 'deleted'
      showSearch = true
      showFilter = false
      // showGalleryDisplay = false
      // showSortList = false
      showGalleryDisplay = true
      showSortList = true
      showPrimaryNewBtn = false
      showSecondaryNewBtn = false
    }


    const history = useHistory();
    if(!allowChangeDisplayMode){
      showGalleryDisplay = false
    }

    return (
      <div className='libraryPage-stickyHeaderContainer'>
        <div className='libraryPage-stickyHeader'>
          <div className='libraryPage-stickyHeader-leftContainer'>
            {isHome && 
            <>
              <div className='libraryPage-stickyHeader-iconContainer'>
                <Icon name='yourlibrary' />
              </div>
              <div className='libraryPage-stickyHeader-label'>
                Your Library
              </div>
            </>
            }
            {isCollection && activeCollection &&
              <>
                <div className={'libraryPage-stickyHeader-iconContainer libraryPage-stickyHeader-iconContainer--collection ' + (activeCollection.color ? `libraryPage-stickyHeader-iconContainer--collection--${activeCollection.color}` : '')}>                          
                  <Icon name='collectionSquircle' />
                </div>
                <div className='libraryPage-stickyHeader-label'>
                  {activeCollection.title}
                </div>
              </>
            }
            {subPage && 
            <>
              <div className='libraryPage-stickyHeader-iconContainer'>
                <Icon name={subPageIconName} />
              </div>
              <div className='libraryPage-stickyHeader-label'>
                {subPageLabel}
              </div>
            </>
            }
          </div>
          <div className='libraryPage-stickyHeader-rightContainer'>
            {showSearch && 
              <LibraryPageHeaderSearch 
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
              />
            }
            {showFilter && 
              <LibraryPageHeaderFilter 

              />
            }
            {showGalleryDisplay && 
              <LibraryPageHeaderGalleryDisplay 
                displayMode={displayMode}
                setDisplayMode={setDisplayMode}
              />
            }

            {showSortList && 
              <LibraryPageHeaderSort
                sortOption={sortOption}
                handleSortChange={handleSortChange}
              />
            }

            

            {showPrimaryNewBtn && 
              <>
              <div className='libraryPage-header-tinySpacer' />
              <button onClick={createNewDraft} className='uiButton uiButton--primaryNewBtn'>
                <div className='uiButton-iconContainer'>
                  <Icon name='composeMedium' />
                </div>
                <div className='uiButton-label'>
                  New Draft
                </div>  
              </button>
              </>
            }

            {showSecondaryNewBtn && 
              <button className='uiButton uiButton--secondaryNewBtn'>
                <div className='uiButton-iconContainer'>
                  <Icon name='composeMedium' />
                </div>
                <div className='uiButton-label'>
                  New in Collection
                </div>         
              </button>
            }

            {activeCollection && 
              <DropdownMenu.Root modal={true}> 
                <DropdownMenu.Trigger asChild>
                  <button className='uiButton uiButton--iconOnly uiButton--collectionHeaderDropdownBtn'>  
                    <div className='uiButton-iconContainer'>
                      <Icon name='ellipses' />                                          
                    </div>
                  </button>
                </DropdownMenu.Trigger>       
                <DropdownMenu.Portal>                
                  <DropdownMenu.Content 
                    align="end"
                    alignOffset={-10}                      
                    className='dropdownMenu dropdownMenu--collectionHeader forceDarkTheme'
                  >                      
                    <DropdownMenu.Item conSelect={()=>{archiveCollection(activeCollection.id,history)}} lassName='dropdownMenu-item--warning'>
                      Delete Collection
                    </DropdownMenu.Item>            
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            }


          </div>

        </div>            
      </div>
    );
}

export default LibraryPageStickyHeader

import { DataStream } from "mp4box";

export class Seeker {
  #callback;
  #evictCallback;
  #input;
  #decoders = new Map();

  #gop = [];
  #gopRange = {
    start: Number.MAX_SAFE_INTEGER,
    end: Number.MIN_SAFE_INTEGER,
    complete: false,
    track: null,
    hasAlpha: false,
  };
  #nextSyncFrame = null;
  #gopsProcessed = 0;
  #offset = 0;

  #seekTargetCTS = 0;
  #lastDecodedCTS = -1;  // New: Keep track of the last decoded frame

  #presentState = {
    video: null,
    alpha: null,
  };



  constructor(displayCallback) {
    this.#callback = displayCallback;
  }

  async getOrCreateDecoder(track) {
    if (!track) return null;
    if (!this.#decoders.has(track.id)) {
      const isMain = track.id === this.#gopRange.track.id;
      const decoder = new VideoDecoder({
        output: (frame) => {
          if (frame.timestamp === this.#seekTargetCTS) {
            if (isMain) this.#presentState.video = frame;
            else this.#presentState.alpha = frame;

            // Present if both video and alpha are ready
            if (
              this.#presentState.video &&
              (this.#gopRange.hasAlpha
                ? this.#presentState.alpha
                : true)
            ) {
              //console.log("Displaying seek target frame", frame.timestamp);
              this.#callback(
                this.#presentState.video,
                this.#presentState.alpha
              );
              this.#presentState.video = null;
              this.#presentState.alpha = null;
            }
          } else frame.close();
        },
        error: console.error,
      });

      const sample = isMain ? this.#gop[0] : this.#gop[0].alphaSample;
      const desc = sample.description;
      const box = desc.avcC ?? desc.hvcC ?? desc.vpcC ?? desc.av1C;
      if (!box) throw new Error(`unsupported codec: ${track.codec}`);

      const buffer = new DataStream(undefined, 0, DataStream.BIG_ENDIAN);
      box.write(buffer);
      const description = new Uint8Array(buffer.buffer, 8); // Remove the box header.

      // if the frame is not a keyframe, wait for it
      const config = {
        codec: track.codec,
        codedHeight: track.video.height,
        codedWidth: track.video.width,
        description,
        optimizeForLatency: true,
      };
      if (!(await VideoDecoder.isConfigSupported(config)))
        throw new Error("Unsupported codec configuration");

      // Warn if codec starts with vp0 but has 00 for level (2nd)
      if (config.codec.match(/^vp0[89]\.\d\d\.00.*/)) {
        console.warn(
          "Codec level is 00, this might not be supported. Replacing with 10"
        );
        config.codec = config.codec.split(".");
        config.codec[2] = "10";
        config.codec = config.codec.join(".");
        console.warn("New codec", config.codec);
      }

      console.warn("Configuring decoder", config);
      decoder.configure(config);
      this.#decoders.set(track.id, decoder);
    }

    return this.#decoders.get(track.id);
  }

  setInput(input) {
    const reader = input.getReader();
    this.#gopsProcessed = 0;
    this.#input = reader;
  }

  setEvictCallback(evictCallback) {
    this.#evictCallback = evictCallback;
  }



  async #seekInGOP(time) {
    // console.log(`Starting to seek within GOP for time: ${time}`);
    const seekInGopStartTime = performance.now();

    // Create a new decoder
    const videoDecoder = await this.getOrCreateDecoder(this.#gopRange.track);
    const alphaDecoder = await this.getOrCreateDecoder(
      this.#gopRange.track.alphaTrack
    );

    let closestSample = this.#gop[0];
    let closestDiff = Math.abs(
      closestSample.cts / closestSample.timescale - time
    );
    for (const sample of this.#gop) {
      const diff = Math.abs(sample.cts / sample.timescale - time);
      if (diff < closestDiff) {
        closestSample = sample;
        closestDiff = diff;
      }
    }


    // console.log(
    //   "Seeking to",
    //   (closestSample.cts / closestSample.timescale).toFixed(4),
    //   "error",
    //   (closestDiff * 1000).toFixed(2),
    //   "ms"
    // );

    // Save the seek target
    this.#seekTargetCTS = closestSample.cts;
   // console.log(`target cts ---- ${closestSample.cts}`)

    //console.log(`Decoding GOP samples for video track`);
    const videoDecodeStartTime = performance.now();

    let startIndex = 0;
    if (this.#lastDecodedCTS !== -1 && this.#lastDecodedCTS < this.#seekTargetCTS) {
      // We're in the same GOP, find the index to start decoding from
      startIndex = this.#gop.findIndex(sample => sample.cts > this.#lastDecodedCTS);
    }

    for (let i = startIndex; i < this.#gop.length; i++) {
      const sample = this.#gop[i];
      if (sample.cts > this.#seekTargetCTS) break;

      const chunk = new EncodedVideoChunk({
        type: sample.is_sync ? "key" : "delta",
        data: sample.data,
        timestamp: sample.cts,
      });
      videoDecoder.decode(chunk);

      this.#lastDecodedCTS = sample.cts;
    }


    const videoDecodeTime = performance.now() - videoDecodeStartTime;
   // console.log(`Time to decode video GOP: ${videoDecodeTime.toFixed(2)} ms`);

    let alphaDecodeTime = 0;
    // Alpha: Pass the GOP to the decoder
    if (alphaDecoder) {
     // console.log(`Decoding GOP samples for alpha track`);
      const alphaDecodeStartTime = performance.now();
      for (let i = startIndex; i < this.#gop.length; i++) {
        const sample = this.#gop[i];
        if (sample.cts > this.#seekTargetCTS) break;

        const chunk = new EncodedVideoChunk({
          type: sample.alphaSample.is_sync ? "key" : "delta",
          data: sample.alphaSample.data,
          timestamp: sample.alphaSample.cts,
        });
        alphaDecoder.decode(chunk);
      }
      alphaDecodeTime = performance.now() - alphaDecodeStartTime;
     // console.log(`Time to decode alpha GOP: ${alphaDecodeTime.toFixed(2)} ms`);
    }

    const seekInGopTime = performance.now() - seekInGopStartTime;

    return {
      from: this.#gopRange.start,
      to:
        this.#gopRange.track.movie_duration /
        this.#gopRange.track.movie_timescale,
    };
  }


  get timescale() {
    return this.#gopRange.track.timescale;
  }

  get offset() {
    return this.#offset;
  }

  #isInGOP(time) {
    if (time >= this.#gopRange.start && time <= this.#gopRange.end) return true;
    if (Math.ceil(time) >= this.#gopRange.start && time <= this.#gopRange.end)
      return true;
    return false;
  }

 async seek(time) {
    if (!this.#input) return;
    let previousCTS = this.#gopRange.end;
    time = parseFloat(time);
  //  console.log(`Seeking to time: ${time}`);

    let lastFrameTime = previousCTS;

    // If there is a sync frame waiting, process it
    const processPendingSyncFrame = () => {
      if (this.#nextSyncFrame) {
        this.#gop = [this.#nextSyncFrame];
        this.#gopRange.start =
          this.#nextSyncFrame.cts / this.#nextSyncFrame.timescale;
        this.#gopRange.complete = false;
        this.#nextSyncFrame = null;
        previousCTS = this.#gopRange.start;
      }
    };

    // Otherwise, seek to the next GOP
    while (!this.#isInGOP(time) || !this.#gopRange.complete) {
      processPendingSyncFrame();

      const { done, value } = await this.#input.read();

      // We might be done reading the stream
      if (done) {
        if (!this.#gopRange.complete) {
          this.#gopRange.complete = true;
          this.#gopRange.end = previousCTS;
          this.#gopsProcessed++;
        }
      //  console.log(`Reached end of stream. Last frame time: ${lastFrameTime}`);
        if (time >= lastFrameTime) {
       //   console.log(`Seek target ${time} is beyond last frame. Using last frame at ${lastFrameTime}`);
          time = lastFrameTime;
        }
        break;
      }

      const { track, video } = value;

      lastFrameTime = video.cts / video.timescale;

      // If GOP was incomplete, and this is a sync frame, close the GOP
      if (!this.#gopRange.complete && video.is_sync && this.#gop.length > 0) {
        this.#gopRange.complete = true;
        this.#gopRange.end = previousCTS;
        this.#nextSyncFrame = video; // don't process this frame yet
        this.#gopsProcessed++;

        // Special case: if this is the first GOP, round the GOP start to 0
        if (this.#gopsProcessed === 1) {
          this.#offset = this.#gopRange.start;
          break;
        }

        continue;
      }

      // If this is the sync frame, start a new GOP
      if (video.is_sync) {
        // We are evicting samples, so we must notify the parser
        if (this.#evictCallback) this.#evictCallback(track.id, video.number);

        if (this.#nextSyncFrame) processPendingSyncFrame();
        else {
          this.#gop = [];
          this.#gopRange.start = video.cts / video.timescale;
          this.#gopRange.complete = false;
        }
      }

      // Save the previous CTS
      previousCTS = video.cts / video.timescale;

      // Add the sample to the GOP
      this.#gop.push(video);
      this.#gopRange.track = track;
      this.#gopRange.hasAlpha = !!track.alphaTrack;
    }

   // console.log(`Seek complete. Final GOP range: ${this.#gopRange.start} - ${this.#gopRange.end}`);
    // Seek to the GOP
    return this.#seekInGOP(time + this.#offset);
  }
}

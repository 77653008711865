import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { openProject } from '../utils/projectUtils/openProject';

///Redirects to new project thats created for recording session

const NewRecordingProjectRedirect = () => {
  const location = useLocation();
  const history = useHistory();
  const pendingSessionCapture = useSelector(state => state.editor.pendingSessionCapture);

  useEffect(() => {
    if (pendingSessionCapture?.projectId) {
      openProject(pendingSessionCapture.projectId, history);
    }
  }, [pendingSessionCapture, history]);

  return null;
};

export default NewRecordingProjectRedirect;
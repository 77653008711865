import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/SlideTextNodes'
import EditorMarks from './marks/SlideTextMarks'

const slideTextSchema = new Schema({
  nodes:EditorNodes,
  marks: EditorMarks
})


export default slideTextSchema
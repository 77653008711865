import {ExportTimelineClip} from './ExportTimelineClip'


//For legacy image clips
// for export this loads the image from cloudinary instead of the filesystenm

class ExportImageClip extends ExportTimelineClip {
  constructor(options, scene,handleClipMediaLoaded) {
    super(options);
    this.image = new Image(); 
    this.scene = scene
    this.imgSrc=`http://res.cloudinary.com/yarn/image/upload/v1719088789/image_uploads/${options.imageId}.png`
    this.loadPromise = this.loadImage();
  }

  loadImage() {
    return new Promise((resolve, reject) => {
      this.image.onload = resolve;
      this.image.onerror = reject;
      this.image.src = this.imgSrc
    });
  }

  destroy() {
    this.image.src = '';  // Dereference the image source to allow garbage collection
    this.image = null;    // Dereference the Image object
  }
}

export { ExportImageClip }



///for saving uploaded assets to the yarn cache while syncing

export async function saveUploadedAssetToCache(payload) {
  if(window.isElectron){
   return await ipcRenderer.invoke('save-uploaded-asset-to-cache', payload);
  }
}




import React, { useRef, useEffect, useState, useContext } from 'react';
import SlideImageElement from './images/SlideImageElement';
import SlideImagePreviewElement from './images/SlideImagePreviewElement';
import SlideTextElement from './SlideTextElement';
import SlideTextPreviewElement from './SlideTextPreviewElement'
import {getBackgroundForId} from '../../utils/brands/getBackgroundForId'
import CanvasBackground from '../CanvasBackground';
import renderOrders from '../renderOrders'
import SlideBarChartContainer from './charts/barChart/SlideBarChartContainer';
import SlideDonutChartContainer from './charts/donutChart/SlideDonutChartContainer';

const Slide = ({ clip, time, projectBackground, isPlaying, hideRenderedTextSlide,variableValues,showSlideChartAnimated,showSlideChartStatic,showSlideImageStatic,showSlideTextStatic, focusedSlideElement,draggingElementId, draggingGroupId,resizingElementId,slideEditorDraggingSpacer,isDraggingToReorder, selectedSlideItemId,sceneWidth,sceneHeight}) => {
  const backgroundId = clip.metadata.backgroundId
  let textSlideBackground = projectBackground
  const isNoneBGColor = backgroundId =='none'
  if(backgroundId && !isNoneBGColor){
    textSlideBackground=getBackgroundForId(backgroundId)
  }

  const clipStartTime = clip.startTime
  const clipEndTime = clip.endTime
  const globalTime = time
  const isBGHidden = globalTime < clipStartTime || globalTime > clipEndTime;
  const slideVisible = !isBGHidden
  return (
    <>
      {textSlideBackground && !isBGHidden && !isNoneBGColor &&
        <CanvasBackground background={textSlideBackground} renderOrder={renderOrders.slideBG} sceneWidth={sceneWidth} sceneHeight={sceneHeight}/>    
      }

      {clip.elements.map((slideElement, index) => {
        const isInDraggingGroup = draggingGroupId ? clip.isElementInLayoutGroup(slideElement.id, draggingGroupId) : false;
        const showText = isDraggingToReorder || (selectedSlideItemId !== slideElement.id)
        if (slideElement.type === 'text'  && slideVisible && !showSlideTextStatic && showText) {
          return (
            <SlideTextElement 
              key={slideElement.id} 
              time={time}
              isPlaying={isPlaying}
              projectBackground={projectBackground}
              textElement={slideElement}
              variableValues={variableValues} 
              slideBackgroundId={clip.metadata.backgroundId}
              showSlideTextStatic={showSlideTextStatic}
              slideVisible={slideVisible}
              isDraggingElement={draggingElementId === slideElement.id}
              isInDraggingGroup={isInDraggingGroup}      
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}            
            />
          )
        }
        if (slideElement.type === 'text'  && slideVisible && showSlideTextStatic && showText) {
          return (
            <SlideTextPreviewElement 
              key={slideElement.id} 
              time={time}             
              projectBackground={projectBackground}
              textElement={slideElement}            
              variableValues={variableValues} 
              slideBackgroundId={clip.metadata.backgroundId}                                        
              isDraggingElement={draggingElementId === slideElement.id}
              isInDraggingGroup={isInDraggingGroup}
              anyElementResizing={resizingElementId ? true : false}
              isDraggingSpacer={slideEditorDraggingSpacer}
              isDraggingToReorder={isDraggingToReorder}
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}
            />
          )
        }
        if (slideElement.type === 'image' && slideVisible && !showSlideImageStatic) {          
          return (
            <SlideImageElement
              key={slideElement.id}
              time={time}
              isPlaying={isPlaying}                
              imageElement={slideElement}
              variableValues={variableValues} 
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}                           
            />
          )
        }
        if (slideElement.type === 'image' && slideVisible && showSlideImageStatic) {          
          return (
            <SlideImagePreviewElement
              key={slideElement.id}
              time={time}              
              imageElement={slideElement}
              variableValues={variableValues}   
              isDraggingElement={draggingElementId === slideElement.id}   
              isResizingElement={resizingElementId === slideElement.id}
              isInDraggingGroup={isInDraggingGroup}                      
              anyElementResizing={resizingElementId ? true : false}
              isDraggingSpacer={slideEditorDraggingSpacer}
              isDraggingToReorder={isDraggingToReorder}
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}
            />
          )
        }
        if (slideElement.type === 'chart' && slideElement.metadata.activeChartType === 'bar') {          
          return (
            <SlideBarChartContainer
              key={slideElement.id}
              time={time}
              isPlaying={isPlaying}
              projectBackground={projectBackground} 
              slideBackgroundId={clip.metadata.backgroundId}
              element={slideElement}           
              barChart={slideElement}  
              slideStartTime={clip.startTime}            
              hideRenderedTextSlide={hideRenderedTextSlide}
              chartData={slideElement.metadata.barChartData}
              showChartAnimated={showSlideChartAnimated}
              showChartStatic={showSlideChartStatic}
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}
            />
          )
        }
        if (slideElement.type === 'chart' && slideElement.metadata.activeChartType === 'donut') {          
          return (
            <SlideDonutChartContainer
              key={slideElement.id}
              time={time}
              isPlaying={isPlaying}
              projectBackground={projectBackground} 
              slideBackgroundId={clip.metadata.backgroundId}
              element={slideElement}           
              donutChart={slideElement}              
              slideStartTime={clip.startTime}            
              hideRenderedTextSlide={hideRenderedTextSlide}              
              showChartAnimated={showSlideChartAnimated}
              showChartStatic={showSlideChartStatic}
              sceneWidth={sceneWidth}
              sceneHeight={sceneHeight}
            />
          )
        }
        return null;
      })}
    </>
  );    
};

export default Slide;

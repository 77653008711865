import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPNumberInput from './DPNumberInput';
import DPItemAnimationPanel from './DPItemAnimationPanel';
import EditDetailPanelTextColorPicker from './EditDetailPanelTextColorPicker';
import EditDetailPanelTextStyleGrid from './EditDetailPanelTextStyleGrid'
import EditDetailPanelCustomTextStyle from './EditDetailPanelCustomTextStyle'
import DPSelectMenu from './DPSelectMenu';
import { getTextColorForId } from '../../../utils/brands/getTextColorForId';
import { findParentLayoutGroup } from '../../../three/slide/slideEditor/utils/findParentLayoutGroup';


const hAlignOptions = [
  { value: 'left', iconName: 'alignTextLeft' },
  { value: 'center', iconName: 'alignTextCenter' },
  { value: 'right', iconName: 'alignTextRight' }
];

const vAlignOptions = [
  { value: 'top', iconName: 'textVAlignTopAlt', tooltipLabel: 'Flow downwards' },
  { value: 'middle', iconName: 'textVAlignMiddleAlt', tooltipLabel: 'Flow middle' },
  { value: 'bottom', iconName: 'textVAlignBottomAlt', tooltipLabel: 'Flow upwards' },
];

const resizeTypes = [
  { value: 'autoWidth', iconName: 'textAutoWidth' },
  { value: 'autoHeight', iconName: 'textAutoHeight' },
  { value: 'fixed', iconName: 'textFixedSize' }
];

const listTypes = [
  { value: 'bullet', iconName: 'bulletList' },
  { value: 'number', iconName: 'numberList' },
  { value: 'letter', iconName: 'letterList' }
];

  
const noWrapTextOptions = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },        
];


const dynamicFontSizeOptions = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },        
];


const EditorDetailPanelText = (props) => {
  const {updateSlideElementMetadata,clipId,element} = props
  const [stylesDetail, showStylesDetail] = useState(false);
  const [listType, setListType] = useState(null);  

  const handleUpdateHAlign = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,textHAlign:value})
  }  

  const handleUpdateVAlign = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,textVAlign:value})
  }  

  const handleUpdateResizeType = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,textResizeType:value})
  }  

  const handleUpdateOpacity= (value) => {
    const opacity=value/100
    const view = window[`textEditView_${element.id}`];
    if (view) {
      const { state } = view;
      const { from, to } = state.selection;
      const hasTextSelection = from !== to;
      if (!showFullPanel) {
        applyOpacityMarkToSelection(view, opacity);
      } else {// Update entire element opacity
        const tr = state.tr;
        state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
          if (node.marks.length > 0) {
            node.marks.forEach(mark => {
              if (mark.type.name === 'textOpacity') {
                tr.removeMark(pos, pos + node.nodeSize, mark.type);
              }
            });
          }
        });
           view.dispatch(tr);
        updateSlideElementMetadata(clipId, element.id, {
        ...element.metadata,
        textOpacity: opacity,
        });
      }
    }
  }  

  const applyOpacityMarkToSelection = (view, opacity) => {
    const { state, dispatch } = view;
    const { from, to } = state.selection;
    if (state.schema.marks.textOpacity) {
      const tr = state.tr.addMark(from, to, state.schema.marks.textOpacity.create({ opacity }));
      dispatch(tr);
    }
  }

  const handleUpdateMetadata = (field,value) => { 
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  

  const handleChangePositon = (axis,newValue) => {
    if(axis=='x'){
      element.x = newValue     
    }
    if(axis=='y'){
      element.y = newValue     
    }
  };

  const showCustomTextStyle = element.metadata.textStyle=='custom'
  const {slideElementTextSelectionInfo,focusedSlideElement} = props 
  //show the full panel with text style and alignment options if there is not a partial text selection
  const showFullPanel= !slideElementTextSelectionInfo || (slideElementTextSelectionInfo && (!slideElementTextSelectionInfo.hasSelection || slideElementTextSelectionInfo.isAllSelected))
  let textColorId = element.metadata.textColorId || 'auto'
  
  const handleSelectTextColor = (id) => {
    const textColorId=id === 'auto' ? null : id
    if (element && element.type === 'text') {
      const view = window[`textEditView_${element.id}`];
      if (view) {
        const { state } = view;
        const { from, to } = state.selection;
        const hasTextSelection = from !== to;
       // if (hasTextSelection) {
          if (!showFullPanel) {
          applyColorMarkToSelection(view, textColorId);
        } else {
              //remove all color marks and instead set the text color on the element
        const tr = state.tr;
        state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
          if (node.marks.length > 0) {
            node.marks.forEach(mark => {
              if (mark.type.name === 'textColor') {
                tr.removeMark(pos, pos + node.nodeSize, mark.type);
              }
            });
          }
        });
           view.dispatch(tr);
          updateSlideElementMetadata(clipId, element.id, {
          ...element.metadata,
          textColorId: textColorId,
        });
      }
    }
    }
  }

  const applyColorMarkToSelection = (view, colorId) => {
    const { state, dispatch } = view;
    const { from, to } = state.selection;
    const rgba = getTextColorForId(colorId).rgba;
    if (state.schema.marks.textColor) {
      const tr = state.tr.addMark(from, to, state.schema.marks.textColor.create({ colorId,rgba }));
      dispatch(tr);
    }
  }

   const setTextColorToVariable = () => {
    if (element && element.type === 'text') {
      const view = window[`textEditView_${element.id}`];
      if (view) {
        const { state } = view;
        const { from, to } = state.selection;
        const hasTextSelection = from !== to;
        if (hasTextSelection) {
          applyVariableColorMarkToSelection(view);
         }
    }
  }
}

  const applyVariableColorMarkToSelection = (view) => {
    const { state, dispatch } = view;
    const { from, to } = state.selection;
    if (state.schema.marks.textColor) {
      const tr = state.tr;
      state.doc.nodesBetween(from, to, (node, pos) => {
        node.marks.forEach(mark => {
          if (mark.type.name === 'textColor') {
            const newMark = state.schema.marks.textColor.create({
              ...mark.attrs,
              isVariable: true
            });
            tr.addMark(pos, pos + node.nodeSize, newMark);
          }
        });
      });
      dispatch(tr);
    }
  }


  const updateVariableTextOptions=(field,value)=>{
    updateSlideElementMetadata(clipId, element.id, {
        ...element.metadata,
        variableTextOptions: {...element.metadata.variableTextOptions,[field]:value},
        });
  }


  //
  // Variable Text
  const variableText = element.metadata.variables && element.metadata.variables.length > 0
  
  let isNoWrap = false 
  let isDynamicFontSize = false 
  let maxHeight = element.height
  // let minimumFontSize = 100
  // let characterCrossover = 100 

  if(element.metadata.variableTextOptions && element.metadata.variableTextOptions.noWrap){
    isNoWrap=true
  }
  if(element.metadata.variableTextOptions && element.metadata.variableTextOptions.isDynamicFontSize){
    isDynamicFontSize=true
  }
  if(element.metadata.variableTextOptions && element.metadata.variableTextOptions.maxHeight){
    maxHeight=element.metadata.variableTextOptions.maxHeight
  }
 

  let currentOpacity = element.metadata.textOpacity*100

  let isMixedOpacity = false


  if (slideElementTextSelectionInfo) {
    const { selectionFrom, selectionTo, hasSelection, marks } = slideElementTextSelectionInfo;
    // Filter opacity marks that intersect with the selection
    const opacityMarksInSelection = marks.filter(mark => 
      mark.type.name === 'textOpacity' && 
      mark.from < selectionTo && 
      mark.to > selectionFrom
    );
    if (!hasSelection) {
      // No selection, check if there are any opacity marks in the document
      if (marks.some(mark => mark.type.name === 'textOpacity')) {
        isMixedOpacity = true;
      } 
    } else if (opacityMarksInSelection.length === 0) {

    } else if (opacityMarksInSelection.length === 1) {
      const mark = opacityMarksInSelection[0];
      if (mark.from <= selectionFrom && mark.to >= selectionTo) {// The mark covers the entire selection
        currentOpacity = mark.attrs.opacity * 100;
      } else {// The mark only partially covers the selection
        isMixedOpacity = true;
      }
    } else {
      // Multiple opacity marks in the selection
      isMixedOpacity = true;
    }
  }

  const slideLayout = element.slideClip?.layout;
  const isInLayoutGroup = slideLayout ? !!findParentLayoutGroup(element.id, slideLayout, false) : false;
  


  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          {showFullPanel ? 'Text' : 'Text Selection'}
        </div>
        <div className='editor-detailPanel-header-vSpacer' />

        {/* TEMP FOR PROD }
        <div className='editor-detailPanel-row'>
        <DPNumberInput 
          min={0}
          max={1600}          
          value={element.x}
          setValue={(value)=>{handleChangePositon('x',value)}}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='X'          
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={0}
          max={1600}               
          value={element.y}
          setValue={(value)=>{handleChangePositon('y',value)}}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='Y'
          width={79}
        />
        </div>
        */}


        {/*} Add when we allow custom text styling 
        <button 
          onClick={() => showStylesDetail(!stylesDetail)}
          data-active-state={stylesDetail}
          className='dpButton dpButton--iconOnly'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='sliders' />
          </div>
        </button>
        */}
        
      </div>


      <div className='editor-detailPanel-header-alignVSpacer' />
      
        {showFullPanel &&
          <>
         <EditDetailPanelTextStyleGrid 
        //  updateSlideElementMetadata={props.updateSlideElementMetadata}
          updateSlideTextElementTextProperties={props.updateSlideTextElementTextProperties}
          element={props.element}
          clipId={props.clipId}
        />    
      

        {showCustomTextStyle && 
          <EditDetailPanelCustomTextStyle
           // updateSlideElementMetadata={props.updateSlideElementMetadata}
            updateSlideTextElementTextProperties={props.updateSlideTextElementTextProperties}
            element={props.element}
            clipId={props.clipId}
            setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          />
        }

        <div className='editor-detailPanel-divider' />
        </>
      }

      
      <div className='editor-detailPanel-row'>
        <EditDetailPanelTextColorPicker 
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          element={props.element}
          clipId={props.clipId}
          textColorId={textColorId}
          handleSelectTextColor={handleSelectTextColor}
        />
      </div>
        
      <div className='editor-detailPanel-divider' />
      
      {showFullPanel &&

      <div className='editor-detailPanel-row'>
        <DPToggleGrid
          items={hAlignOptions}
          activeItem={element.metadata.textHAlign}
          onItemClick={handleUpdateHAlign}      
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />        
        

        <DPToggleGrid          
          disabled={isInLayoutGroup}
          items={vAlignOptions}
          activeItem={isInLayoutGroup ? null : element.metadata.textVAlign}
          onItemClick={handleUpdateVAlign}
        />

        {/*}
        <DPToggleGrid
          disabled
          items={listTypes}
          activeItem={listType}
          onItemClick={setListType}
        />
        {/*}
         <DPToggleGrid          
          items={resizeTypes}
          activeItem={element.metadata.textResizeType}
          onItemClick={handleUpdateResizeType}      
        />
        */}
        
      </div>
    }
      
      {/* }
      <div className='editor-detailPanel-row editor-detailPanel-row--secondDoubleRowUplift'>                
        <DPToggleGrid
          disabled={element.metadata.textResizeType !== 'fixed'}
          items={vAlignOptions}
          activeItem={element.metadata.textVAlign}
          onItemClick={handleUpdateVAlign}
        />

        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <div className='editor-detailPanel-row-smallHSpacer' />        
      </div>
      */}

      

      <div className='editor-detailPanel-tempHide'>
         <div className='editor-detailPanel-divider' />
        <div className='editor-detailPanel-row editor-detailPanel-row--dynamicHeight'>
            <button onClick={setTextColorToVariable}>variable color </button>
        </div>
      </div>

      
      

      {/*} Number List Support
      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          List Type
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <button 
          onClick={() => setListType(listType === 'bullet' ? null : 'bullet')}
          data-active-state={listType === 'bullet'}
          className='dpButton dpButton--iconOnly dpButton--listItem'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='bulletList' />
          </div>
        </button>
        <button 
          onClick={() => setListType(listType === 'number' ? null : 'number')}
          data-active-state={listType === 'number'}
          className='dpButton dpButton--iconOnly dpButton--listItem'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='numberList' />
          </div>
        </button>
      </div>
      */}

      <div className='editor-detailPanel-divider' />
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Opacity
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={0}
          max={100}
          value={currentOpacity}
          setValue={handleUpdateOpacity}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='opacity'      
          isMixed={isMixedOpacity}        
        />
      </div>         
      

      {variableText &&
        <>

        <div className='editor-detailPanel-divider' />

      
        <div className='editor-detailPanel-smallVSpacer' />
        <div className='editor-detailPanel-smallVSpacer' />
        <div className='editor-detailPanel-row editor-detailPanel-row--tight'>
          <div className='editor-detailPanel-row-label'>
            No Wrap Text
          </div>
          <div className='editor-detailPanel-row-hSpacer' />                      
          <DPSelectMenu
            width={72}
            value={isNoWrap}
            onValueChange={(value)=>{updateVariableTextOptions('noWrap',value)}}
            options={noWrapTextOptions}
          />
        </div>


        <div className='editor-detailPanel-row editor-detailPanel-row--tight'>
          <div className='editor-detailPanel-row-label'>
            Dynamic Font Size
          </div>
          <div className='editor-detailPanel-row-hSpacer' />            
          <DPSelectMenu
            width={72}
            value={isDynamicFontSize}
            onValueChange={(value)=>{updateVariableTextOptions('isDynamicFontSize',value)}}
            options={dynamicFontSizeOptions}
          />
        </div>

        {!isDynamicFontSize && 
          <>
          <div className='editor-detailPanel-smallVSpacer' />
          <div className='editor-detailPanel-smallVSpacer' />
          </>
        }

        {isDynamicFontSize && 
    
          <>
          <div className='editor-detailPanel-row editor-detailPanel-row--tight'>
            <div className='editor-detailPanel-row-label'>
              Max Height
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput             
              min={20}
              max={2000}
              value={maxHeight}
              setValue={(value)=>{updateVariableTextOptions('maxHeight',value)}}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='doubleEndedArrowVertical'               
            />
          </div>
          <div className='editor-detailPanel-smallVSpacer' />
          <div className='editor-detailPanel-smallVSpacer' />          
          </>
       }



{/*
        {isDynamicFontSize && 
        <>
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Minimum Font Size
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput             
              min={20}
              max={200}
              value={minimumFontSize}
              setValue={(value)=>{updateVariableTextOptions('minimumFontSize',value)}}
             // setValue={handleUpdateOpacity}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='aaText'               
            />
          </div>

          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Character Crossover
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput             
              min={1}
              max={200}
              value={characterCrossover}
              setValue={(value)=>{updateVariableTextOptions('characterCrossover',value)}}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='twoWayArrows'               
            />
          </div>
        </>
        }*/}
        </>
      }
      
      
      

      {showFullPanel &&
      <>
      <div className='editor-detailPanel-divider' />
      <DPItemAnimationPanel 
        item={element}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={props.element.metadata.startTransitionType}
        activeEndTransition={props.element.metadata.endTransitionType}
        animationGroupingType={element.metadata.animationGrouping}
        itemType='text'
      />
      </>
    }

      
      

       {/* <div className='editor-detailPanel-row-label'>
          Max Lines
        </div>
        <DPNumberInput 
          min={0}
          max={100}
          value={element.metadat}
          setValue={handleUpdateOpacity}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='opacity'              
        />
        */}
       


    </>
  );
};

export default EditorDetailPanelText;
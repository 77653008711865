import React, { useMemo } from 'react';
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useMicrophoneLevel } from './useMicrophoneLevel';

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const RecordWebcamMicrophoneSelect = ({ 
  activeMicrophone,
  setActiveMicrophone,
  microphones,
  mediaStream,
  viewType,
  isDisplayRecording
}) => {  
  const activeOption = microphones && microphones.find(mic => mic.value === activeMicrophone);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select Microphone...';
  const microphoneInput = useMicrophoneLevel(mediaStream);
  
  const volumeSegmentCount = 10;
  
  // Calculate which segments should be active based on microphoneInput
  const segments = useMemo(() => {
    return Array.from({ length: volumeSegmentCount }, (_, index) => {
      const segmentThreshold = (index + 1) / volumeSegmentCount;
      return microphoneInput * 1.2 >= segmentThreshold;
    });
  }, [microphoneInput, volumeSegmentCount]);
  
  return (    
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button data-view-type={viewType} data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} data-device-type='microphone' className='recordWebcamWindow-selectDeviceBtn'>
          <div className='recordWebcamWindow-selectDeviceBtn-iconContainer'>
            <Icon name='microphoneRegular' />
          </div>
          <div className='recordWebcamWindow-selectDeviceBtn-label'>
            {buttonLabel}
          </div>
          {/*}
          <div className='recordWebcamWindow-selectDeviceBtn--microphoneInputBarContainer'>
            <div style={{width: `${microphoneInput * 100}%`}} className='recordWebcamWindow-selectDeviceBtn--microphoneInputBar' />
          </div>          
          */}
          <div className='recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolumeContainer'>
            {segments.map((isActive, index) => (
              <div 
                key={index}
                data-state={isActive ? 'active' : 'inactive'} 
                className='recordWebcamWindow-selectDeviceBtn--microphoneSegmentedVolume' 
              />
            ))}
          </div>
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align="middle" 
        sideOffset={4} 
        side={'top'} 
        collisionPadding={10} 
        className='dropdownMenu'
      >        
        {microphones && microphones.map((microphone) => (
          <DropdownMenu.Item
            key={microphone.value}
            onSelect={() => setActiveMicrophone(microphone.value)}
            className={`dropdownMenu-item ${microphone.value === activeMicrophone ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(microphone.label)}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default RecordWebcamMicrophoneSelect;
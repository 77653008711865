export const getKeystrokeData = async (captureId) => {
  try {  
      const relativePath = `screenRecordings/${captureId}/recording.input-events.json`;
      const content = await ipcRenderer.invoke('read-file', relativePath);
      const data = JSON.parse(content);
    return data
      .filter(item => item.keyboardEventType === 'up')
      .map(item => ({
        time: item.time.seconds,
        keySymbol: item.keySymbol,
      }));
  } catch (error) {
    console.error('Failed to load mouse data:', error);
    return []; // Return an empty array in case of error
  }
};

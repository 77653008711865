import {fetch,post,put,deleteRequest} from '../api'
import { Mixpanel } from '../Mixpanel'
import {showToastNotification} from './toastNotification'



export function saveSlideAsTemplate(projectId,slide){
	const requestBody={
		slide:slide,
		originalProjectId:projectId
	}
	return (dispatch) => post(`/slide-templates`,requestBody)
	.then((response) => {
		dispatch({ type: 'CREATE_SLIDE_TEMPLATE_SUCCESS', response })
		const toastType='savedSlideTemplate'
		const data={}
		dispatch(showToastNotification(toastType,data))
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
} 


export function updateSlideTemplate(templateId, slide) {
	const requestBody = {
		slide: slide
	}
	return (dispatch) => put(`/slide-templates/${templateId}`, requestBody)
		.then((response) => {
			dispatch({ type: 'UPDATE_SLIDE_TEMPLATE_SUCCESS', response })
			dispatch(fetchSlideTemplates())
			dispatch(fetchArchivedSlideTemplates())
			const toastType = 'updatedSlideTemplate'
			const data = {}
			dispatch(showToastNotification(toastType, data))
			return response
		})
		.catch((error) => {
			console.error('Error updating slide template:', error)
			return error
	})
}

export async function createSlideContent(words, num_bullets) {
	const requestBody = {
		words: words,
		num_bullets: num_bullets
	}
	const startTime = new Date()
	const response = await post(`/slide-templates/create_slide_content`, requestBody)
	const endTime = new Date()
	const duration = endTime - startTime
	console.log(`Duration: ${duration}ms`)
	return response
}




export function fetchSlideTemplates(){
	return (dispatch) => fetch(`/slide-templates`)
	.then((response) => {
		dispatch({ type: 'FETCH_SLIDE_TEMPLATES_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
} 

export function fetchArchivedSlideTemplates(){
	return (dispatch) => fetch(`/slide-templates/archived`)
	.then((response) => {
		dispatch({ type: 'FETCH_ARCHIVED_SLIDE_TEMPLATES_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
} 

export function restoreSlideTemplate(templateId) {
  return (dispatch) => { 
    return put(`/slide-templates/${templateId}/restore`)
      .then((response) => {
				dispatch(fetchSlideTemplates())
				dispatch(fetchArchivedSlideTemplates())
				const toastType='restoredSlideTemplate'
				const data={}
				dispatch(showToastNotification(toastType,data))
				return response
      })
      .catch((error) => {
      	console.log(error)
        return error
      })
  }
}

export function archiveSlideTemplate(templateId) {
	return (dispatch) =>{ 
		return put(`/slide-templates/${templateId}/archive`)
			.then((response) => {
				dispatch({ type: 'ARCHIVE_SLIDE_TEMPLATE_SUCCESS', response: { id: templateId } });
				dispatch(fetchArchivedSlideTemplates())
				dispatch(fetchSlideTemplates())
				const toastType='archivedSlideTemplate'
				const data={}
				dispatch(showToastNotification(toastType,data))
				return response
			})
		.catch((error) => {
			console.log('archive project error')
			console.log(error)
			return error
		})
	}
}


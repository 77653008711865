import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';
import { SpringAnimator } from '../../utils/animations/SpringAnimator';
import SlideFitImageElementMaterial from './SlideFitImageElementMaterial'
import SlideFillImageElementMaterial from './SlideFillImageElementMaterial'
import createRoundedRectShape from '../../utils/createRoundedRectShape';
import {getAnimationParamsForMotionStyle} from '../../utils/animations/getAnimationParamsForMotionStyle'
import { startTransitionConfigs } from './imageTransitionConfigs/startTransitionConfigs';
import { endTransitionConfigs } from './imageTransitionConfigs/endTransitionConfigs';
import { simulateAnimationDuration } from '../../utils/animations/simulateAnimationDuration';
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import defaultImageAnimationValues from '../../utils/animations/defaultImageAnimationValues'
import renderOrders from '../../renderOrders'


const FPS = 60


const SlideImageElement = ({ 
  imageElement,  
  time,          
  isPlaying,  
  variableValues,  
  sceneWidth,
  sceneHeight

}) => {  
  let maxImageOpacity = 1
  if(imageElement.metadata.opacity){
    maxImageOpacity = imageElement.metadata.opacity
  }

  let imageWidth = imageElement.width
  let imageHeight = imageElement.height
  let imageX = imageElement.x
  let imageY = imageElement.y
  let imageCornerRounding = imageElement.metadata.cornerRounding
  let isFill = imageElement.metadata.isFill

  if(imageElement.metadata.isBGImage){
    imageWidth = sceneWidth
    imageHeight = sceneHeight
    imageX = 0
    imageY = 0
    imageCornerRounding = 0
    isFill = true
  }


  // We scale transitions to size of image element
  // I think we want to keep this
  const maxScalar = 1;
  const minScalar = 0.3;
  const maxDisplayScale = 1800; 
  const minDisplayScale = 300;
  const imageScaleScalar = minScalar + (imageWidth - minDisplayScale) * (maxScalar - minScalar) / (maxDisplayScale - minDisplayScale);  

  //Animation style
  const startTransitionType = imageElement.metadata.startTransitionType
  const endTransitionType = imageElement.metadata.endTransitionType
  const motionStyle = imageElement.metadata.motionStyle || 'smooth'
  
  let adjustedSpeed = 0
  let adjustedBounciness = 0
  if(imageElement.metadata.enterTransitionSpeedFactor){
    adjustedSpeed = imageElement.metadata.enterTransitionSpeedFactor
  }
  if(imageElement.metadata.enterTransitionBouncinessFactor){
    adjustedBounciness = imageElement.metadata.enterTransitionBouncinessFactor
  }

  const animationParams=getAnimationParamsForMotionStyle(motionStyle, adjustedSpeed, adjustedBounciness)

  let scalar = 1
  if(imageElement.metadata.enterTransitionValueFactor){
    scalar = Math.pow((imageElement.metadata.enterTransitionValueFactor + 1), 2) // convert to scalar
  }  

  const localTime = time - imageElement.startTime 
  const clipDuration = imageElement.duration
  const localStartTime = 0
  const localEndTime = clipDuration - 0.1
  const calculationWindowEnd = localEndTime + 0.2


  //Visibility
  let visible = false 
  if(time >= imageElement.startTime && time < imageElement.endTime){
    visible = true
  } 

  

  const { startFrom: transitionStartFrom, startTo: transitionStartTo} = (startTransitionConfigs[startTransitionType] || (() => ({})))(scalar, imageScaleScalar);
  const { endTo: transitionEndTo } = (endTransitionConfigs[endTransitionType] || (() => ({})))(imageScaleScalar);  

  const startFrom = { ...defaultImageAnimationValues, ...transitionStartFrom };
  const startTo = { ...defaultImageAnimationValues, ...transitionStartTo };
  const endTo = { ...defaultImageAnimationValues, ...transitionEndTo };

  const initialProperties = startFrom

  const [propertySprings, setPropertySprings] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [startToCompletedTime, setStartToCompletedTime] = useState(null);
  const [endToAnimationDuration, setEndToAnimationDuration] = useState(null);

  useEffect(() => {
    let newPropertySprings = {};
    let newAnimationStates = {};

    // Ensure that calculationWindowEnd and FPS are defined and valid
    const framesCount = calculationWindowEnd && FPS ? Math.round(calculationWindowEnd * FPS) : 0;

    // Initialize the springs with 'startFrom' values
    Object.keys(startFrom).forEach(property => {
      newPropertySprings[property] = new SpringAnimator(
        animationParams.mass,
        animationParams.stiffness,
        animationParams.damping,
        startFrom[property]
      );
      
      newAnimationStates[property] = new Array(framesCount).fill(startFrom[property]);
    });

    // Calculate the animation durations
    let startToDuration = simulateAnimationDuration(startFrom, startTo, animationParams, FPS);
    if(startToDuration === 'none'){
      startToDuration = 0
    }
    let endToDuration = simulateAnimationDuration(startTo, endTo, animationParams, FPS);
    if(endTransitionType === 'none'){
      endToDuration = 0
    }

    // Simulate the entire animation sequence
    for (let frame = 0; frame < calculationWindowEnd * FPS; frame++) {
      const simulationLocalTime = frame / FPS;

      // Animate from 'startFrom' to 'startTo'
      if (simulationLocalTime >= localStartTime && simulationLocalTime < startToDuration) {
        Object.keys(startTo).forEach(property => {
          newPropertySprings[property].setTarget(startTo[property]);
        });
      }

      // Start the 'endTo' animation at the endInitiateAnimationTime
      if (simulationLocalTime >= (localEndTime - endToDuration) && simulationLocalTime <= localEndTime) {
        Object.keys(endTo).forEach(property => {
          newPropertySprings[property].setTarget(endTo[property]);
        });
      }

      // Simulate the springs for all properties
      Object.keys(newPropertySprings).forEach(property => {
        const spring = newPropertySprings[property];        
        const isOpacity = property === 'opacity'
        spring.simulate(1000 / FPS, isOpacity, 0, 1); // Enable clamping only for opacity
        newAnimationStates[property][frame] = spring.position;
      });
    }

    // Update states at the end of the calculation
    setPropertySprings(newPropertySprings);
    setAnimationStates(newAnimationStates);
    setStartToCompletedTime(startToDuration);
    setEndToAnimationDuration(endToDuration);
  
  }, [clipDuration, JSON.stringify(animationParams), startTransitionType, endTransitionType, scalar, adjustedSpeed, adjustedBounciness]);


  //
  // Some Calcs

  const deviceRef = useRef();  
  const shadowRef = useRef();

  const originalImageWidth = imageElement.metadata.original.width;
  const originalImageHeight = imageElement.metadata.original.height; 
  const originalImageAspect = originalImageWidth / originalImageHeight;
  const meshWidth = imageWidth;
  const meshHeight = imageHeight;
  const meshAspect = meshWidth / meshHeight;

  const meshX = imageX
  const meshY = imageY

  const meshNormalizedLeft = imageX + meshWidth / 2 - sceneWidth / 2;
  const meshNormalizedTop = -imageY - meshHeight / 2 + sceneHeight / 2;
  

  //
  // Fill Image Stuff
  let fillImageWidth, fillImageHeight;

  if (meshAspect > originalImageAspect) {
    fillImageWidth = meshWidth;
    fillImageHeight = meshWidth / originalImageAspect;
  } else {
    fillImageHeight = meshHeight;
    fillImageWidth = meshHeight * originalImageAspect;
  }

  //
  // Setup Mesh

  const maxRadius = Math.min(meshWidth, meshHeight) / 2;

  // Use the smaller of the desired radius or the maximum allowed radius
  let meshRadius = 0;
  if (imageCornerRounding) {
    meshRadius = Math.min(imageCornerRounding, maxRadius);
  }

  const isCircle = meshRadius >= maxRadius && meshWidth === meshHeight;

  const deviceGeometry = useMemo(() => {
    if (isCircle) {
      // Create a CircleGeometry
      const geometry = new THREE.CircleGeometry(maxRadius, 64);
      return geometry;
    } else if (meshRadius === 0) {
      // Create a simple rectangle geometry
      const geometry = new THREE.PlaneGeometry(meshWidth, meshHeight);
      return geometry;
    } else {
      // Create a rounded rectangle shape
      const shape = createRoundedRectShape(meshWidth, meshHeight, meshRadius);
      const geometry = new THREE.ShapeGeometry(shape);        
      const uvs = new Float32Array(geometry.attributes.position.count * 2);
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
        uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
        uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
      }
      geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
      return geometry;
    }
  }, [meshWidth, meshHeight, meshRadius, isCircle, maxRadius]);

  //
  // Apple Animations
  useEffect(() => {
    const frameIndex = Math.floor(localTime * FPS);

    if (deviceRef.current) {
      Object.keys(animationStates).forEach(property => {
        const state = animationStates[property][frameIndex];
        
        if (state !== undefined) {
          if (property.startsWith('position')) {
            const axis = property.slice('position'.length).toLowerCase();
            deviceRef.current.position[axis] = state;
            // console.log('FIRE HERE');
            // console.log(state);
          } else if (property === 'scale' && deviceRef.current.scale) {
            deviceRef.current.scale.set(state, state, state);
          } else if (property.startsWith('rotation')) {
            const axis = property.slice('rotation'.length).toLowerCase();
            deviceRef.current.rotation[axis] = state;
          } else if (property === 'opacity' && deviceRef.current.material) {
            deviceRef.current.material.opacity = state * maxImageOpacity;
          }
        }
      });
    }
  }, [localTime, startTransitionType, endTransitionType, animationStates]);

  //
  // Variable Image Src Support
  let variableImageSrc
  let imgSrc = imageElement.metadata.imgSrc     
  const zIndex = imageElement.zIndex
  return (
    <>      
            
     {imgSrc &&
      <>
        <group 
          renderOrder={zIndex} 
          position={[meshNormalizedLeft, meshNormalizedTop, 0]}
        >
          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, 0]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}
            renderOrder={zIndex}
          >
            
            {!isFill &&
              <SlideFitImageElementMaterial 
                imgSrc={imgSrc}
                opacity={1} // update to animated
                renderOrder={zIndex}
              />
            }

            {isFill &&
              <SlideFillImageElementMaterial 
                imgSrc={imgSrc}   
                opacity={1} // update to animated
                meshWidth={meshWidth}
                meshHeight={meshHeight}
                imageWidth={fillImageWidth}
                imageHeight={fillImageHeight}
                renderOrder={zIndex}
              />     
            }         
         
          </mesh>
        </group>
      </>
    }

    </>
  );
};

export default SlideImageElement;





  // //
  // // Shadow Setup  
  // const shadowXOffset = 0;
  // const shadowYOffset = -8;
  // const shadowBlur = 20;
  // const shadowSpread = -15;
  // // const shadowOpacity = 0.68;
  // let shadowOpacity = 0.84;
  // if(hasTransparency){
  //   shadowOpacity = 0
  // }

  // if(orgImageStyle && orgImageStyle.shadowType && orgImageStyle.shadowType === 'none'){
  //   shadowOpacity = 0
  // }


  // const shadowMesh = useMemo(() => {
  //   const shadowOptions = {
  //     shadowXOffset, 
  //     shadowYOffset, 
  //     shadowBlur,    
  //     shadowSpread,  
  //     shadowColor: 'rgba(0,0,0,1)',
  //     shadowOpacity, 
  //   };
  //   return createDropShadow(meshWidth, meshHeight, shadowOptions);
  // }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);

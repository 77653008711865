import {randomID} from '../../utils/randomID'
import {webcamClipDefaultMetadata} from '../../utils/webcam/webcamConfigs'
import { getWebcamForCaptureId } from '../../utils/webcam/getWebcamForCaptureId';
import { getFaceBoxForWebcamCaptureId } from '../../utils/webcam/getFaceBoxForWebcamCaptureId';

export async function createWebcamClipObjFromCaptureId(captureId,sessionCaptureId,startTime,clipId,linkedClipId,chunkIndex,chunkTrimStart, chunkTrimEnd,recordingSessionDuration) {
	const id = clipId || randomID() 
	let metadata = {...webcamClipDefaultMetadata}
	let webcamObj = getWebcamForCaptureId(captureId)
	let isUploadingVideo = true
	if(webcamObj){
		isUploadingVideo = false
		metadata.originalHeight=webcamObj.original_height
		metadata.originalWidth=webcamObj.original_width
	}


	const faceBox = await getFaceBoxForWebcamCaptureId(captureId)
	metadata.faceBox = faceBox
	metadata.applyFaceBox = true
	metadata.audioTransformation={
		type:'normalized'
	}
	metadata.linkedClipId = linkedClipId

	let duration = recordingSessionDuration
	let recordingDuration = recordingSessionDuration

	if(webcamObj && !recordingSessionDuration){

		duration = Number(webcamObj.duration.toFixed(2))
		recordingDuration = Number(webcamObj.duration.toFixed(2))
	}


	if (chunkTrimStart !== null && chunkTrimEnd !== null) {
		metadata.trimStart = chunkTrimStart
		metadata.trimEnd = chunkTrimEnd
		metadata.minTrimStart = chunkTrimStart
		metadata.maxTrimEnd = chunkTrimEnd
		duration = chunkTrimEnd-chunkTrimStart
		metadata.chunkIndex = chunkIndex
	}

	const newClip = {
		id:id,
		type:"webcam",
		captureId:captureId,
		sessionCaptureId:sessionCaptureId,
		isUploadingVideo:isUploadingVideo,
		startTime:startTime,
		pinnedStartTime:startTime,
		duration:duration,
		recordingDuration:recordingDuration,
		metadata:metadata,
		zIndex:-1,
	}
	return newClip
}
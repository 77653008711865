import {TimelineClip} from './TimelineClip'
import {AudioClip} from './AudioClip'
import {VideoClip} from './VideoClip'
import {CodecVideoClip} from './CodecVideoClip'
import {TextSlideClip} from './TextSlideClip'
import {ZoomClip} from './ZoomClip'
import {ChartClip} from './ChartClip'
import {ImageClip} from './ImageClip'
import {SlideClip} from './slide/SlideClip'
import {WebcamClip} from './WebcamClip'
import {adjustClipsDraggingLeft} from './dragUtils/adjustClipsDraggingLeft'
import {adjustClipsDraggingRight} from './dragUtils/adjustClipsDraggingRight'
import {getSnappedClipTimes} from './dragUtils/getSnappedClipTimes'
import {dragPullClips} from './dragUtils/dragPullClips'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import {calulateVideoTimeFromTimelineTimeNoTrims} from './utils/calculateVideoTimeFromTimelineTimeNoTrims'
import {calculateTimelineTimeFromVideoTime} from './utils/calculateTimelineTimeFromVideoTime'


const MIN_SCENE_DURATION = 5
const PUSH_THRESHOLD = 1
const PUSH_THRESHOLD_PIXELS = 20

function roundToOneDecimal(value) {
	return Math.round(value * 10) / 10;
}

//times are relative to scene start time 
class TimelineScene { 
	constructor({id, startTime, duration,clips,sceneIndex,title},pmManager,transcriptPmManager,handleClipMediaLoaded,debouncedSaveProjectTimeline,projectId,handleVoiceoverAudioFileUpdated,onDurationUpdate,moveClipBetweenScenes,isLastScene,setPreviewingAudioClipId,activeVoice,getTranscript,isExport,handleTextElementFontLoaded,updateTranscriptPanelOnLoadWebcam,calculateSoundEffects,providerId,getAspectRatio,checkWebcamUploadStatus,videoWindowPadding) {
		this.id = id;
		this.title=title || 'Untitled Scene'
		this.activeVoice = activeVoice
		this.providerId = providerId
		this.startTime = startTime || 0;
		this.duration = duration;
		this.clips = []
		this._projectId=projectId 
		this.handleVoiceoverAudioFileUpdated=handleVoiceoverAudioFileUpdated
		this.onDurationUpdate=onDurationUpdate 
		this.handleClipMediaLoaded=handleClipMediaLoaded
		this.debouncedSaveProjectTimeline = debouncedSaveProjectTimeline
		this.moveClipBetweenScenes=moveClipBetweenScenes
		this.sceneIndex=sceneIndex
		this.pmManager = pmManager 
		this.transcriptPmManager=transcriptPmManager
		this.isLastScene=isLastScene
		this.setPreviewingAudioClipId=setPreviewingAudioClipId
		this.audioClipSpacing = null
		this.getTranscript=getTranscript
		this.handleTextElementFontLoaded = handleTextElementFontLoaded
		this.dndDirection = null
		this.enableDndPush = false
		this.enableResizePush = false
		this.isExport = isExport 
		this.updateTranscriptPanelOnLoadWebcam = updateTranscriptPanelOnLoadWebcam
		this.calculateSoundEffects=calculateSoundEffects
		this.getAspectRatio=getAspectRatio
		this.checkWebcamUploadStatus=checkWebcamUploadStatus
		this.videoWindowPadding = videoWindowPadding
		this.isVideoResizeMode = false
		this.preResizeDuration = null
	}


	initScene(isInitialLoad, clips, isPMUndoRedo, audioClipSpacing) {
		const scene = {
			id: this.id, 
			startTime: this.startTime, 
			duration: this.duration,
			sceneIndex: this.sceneIndex,
			title: this.title
		}
		this.audioClipSpacing = audioClipSpacing
		if (!isPMUndoRedo && this.pmManager) {
			this.pmManager.addSceneToPMDoc(scene, isInitialLoad)
		}
		clips.forEach((clip) => {
			// Skip placeholder webcam clips during export
			if (this.isExport && clip.type === 'webcam' && clip.metadata.isVariable) {
				return;
			}
			this.addClip({...clip, sceneId: this.id}, isInitialLoad, isPMUndoRedo)
		})
		this.calculateSceneDuration()
	}


	createNewTimelineClip(newClip,voiceOverPlaybackRate){
		const scene = this
		let timelineClip
		let clip = {...newClip}
		if(newClip.startTime){
			clip.startTime = Math.max(newClip.startTime - this.startTime,0) //adjust for scene start time (this is not a TimelineClip so doesnt go through the setter)
		}
		if(newClip.relativeStartTime || newClip.relativeStartTime==0){
			clip.startTime = newClip.relativeStartTime
		}
		if(newClip.relativePinnedStartTime || newClip.relativePinnedStartTime==0){
			clip.pinnedStartTime = newClip.relativePinnedStartTime 
		}
		if(newClip.pinnedStartTime){
			clip.pinnedStartTime = Math.max(newClip.pinnedStartTime - this.startTime,0) //adjust for scene start time (this is not a TimelineClip so doesnt go through the setter)
		}


		if((clip.duration >0 && clip.startTime>=0)){
			if(clip.type=='audio'){
				clip.voiceOverPlaybackRate = voiceOverPlaybackRate
				timelineClip = new AudioClip(clip,scene,this._projectId,this.transcriptPmManager,this.handleVoiceoverAudioFileUpdated,this.setPreviewingAudioClipId,this.activeVoice,this.getTranscript,this.providerId,this.getAspectRatio)
			}else if(clip.type=='video'){

				if(this.isExport){
					timelineClip = new CodecVideoClip(clip,scene, this.videoWindowPadding)
				}else{
					timelineClip = new VideoClip(clip,scene,this.handleClipMediaLoaded,this.updateVideoClipSegments,this.videoWindowPadding)
				}
			}else if(clip.type=='textSlide'){
				timelineClip = new TextSlideClip(clip,scene)
			}else if(clip.type=='zoom'){
				timelineClip = new ZoomClip(clip,scene)
			}else if(clip.type=='chart'){
				timelineClip = new ChartClip(clip,scene)
			}else if(clip.type=='image') {
				timelineClip = new ImageClip(clip,scene,this.handleClipMediaLoaded)

			}else if(clip.type=='slide') {
				timelineClip = new SlideClip(clip,scene,false,null,this.handleTextElementFontLoaded)
			}
			else if(clip.type=='webcam') {
				if(this.isExport){
					timelineClip = new CodecVideoClip(clip,scene,this.handleClipMediaLoaded)
				}else{
					timelineClip = new WebcamClip(clip,scene,this.handleClipMediaLoaded,this.updateVideoClipSegments,this.updateTranscriptPanelOnLoadWebcam,this.updateNodeAttrsWithoutUndo,this.checkWebcamUploadStatus,this.getAspectRatio)
				}
			}
			else {
				timelineClip = new TimelineClip(clip,scene)
			}	
		}
		return timelineClip	
	}

	findClipById(clipId) {
		return this.clips.find(clip => clip.id === clipId);		
	}
 
	moveClipIntoScene(clip){
		this.clips.push(clip)
	}

	moveClipOutOfScene(clipId){
		const clipIndex = this.clips.findIndex(s => s.id === clipId);
		if(clipIndex !== -1){
			this.clips.splice(clipIndex, 1);
		}
	}

	changeActiveVoice(activeVoice,providerId){
		this.activeVoice= activeVoice
		this.providerId = providerId
	}

	updateVideoClipSegments=(clipId)=> {
		const clip = this.findClipById(clipId)
		const message = 'update video clip segments'
		this.pmManager.updateNodeAttrs(clip,message)
	//	console.log('CACLAULET THE EFFECTSNOW')
		this.calculateSoundEffects()
	}

	updateNodeAttrsWithoutUndo=(clip)=>{
		this.pmManager.updateNodeAttrsSilent(clip ) 
	}

	addClipFromTranscriptSync(clip,voiceOverPlaybackRate){
		const timelineClip = this.createNewTimelineClip(clip,voiceOverPlaybackRate)
		if(timelineClip){
			this.clips.push(timelineClip)
			this.pmManager.startAction('addClip')
			this.pmManager.addClipToPMDoc({...clip,voiceOverPlaybackRate:voiceOverPlaybackRate,sceneId:this.id},false)	
			this.debouncedSaveProjectTimeline()
		}
	}

	addClip(clip, isInitialLoad, isPMUndoRedo, voiceoverPlaybackRate) {
		const timelineClip = this.createNewTimelineClip(clip, voiceoverPlaybackRate)
		if (timelineClip) {
			this.clips.push(timelineClip)
			const hasOngoingPmAction = this.isExport?false:this.pmManager.editingAction?true:false
			if (!isPMUndoRedo && !isInitialLoad && !hasOngoingPmAction) {
				this.pmManager.startAction('addClip')
			}	
			if (!isInitialLoad) {
				if (timelineClip.type === 'textSlide' || timelineClip.type === 'chart' || timelineClip.type === 'video' || timelineClip.type === 'image' || timelineClip.type === 'zoom' || timelineClip.type === 'slide') {
					this.resolveConflictsOnInsertClip(timelineClip)	
				}
			}
			if (!isPMUndoRedo && this.pmManager) { 
				this.pmManager.addClipToPMDoc(timelineClip,isInitialLoad)
				if(!isInitialLoad && !hasOngoingPmAction){
					this.pmManager.endAction()
					this.calculateSceneDuration()
				}
			}
		}
	}

	deleteClip(clipId,isPMUndoRedo){
		let clipIdsToDelete=[]
		clipIdsToDelete.push(clipId)
		this.clips.forEach((clip)=>{
			if(clip.type=='zoom' && clip.metadata.parentClip==clipId){
					clipIdsToDelete.push(clip.id)
				}
				if(clip.metadata.linkedClipId==clipId){
					clipIdsToDelete.push(clip.id)
				}
		})		

		clipIdsToDelete.forEach((id)=>{
			const clipIndex = this.clips.findIndex(s => s.id === id);
			if(clipIndex!==-1){
				const clip = this.clips[clipIndex]
				if (clip.destroy && typeof clip.destroy === 'function') {
					clip.destroy();
				}
				this.clips.splice(clipIndex, 1);
			}
		})
		if(!isPMUndoRedo){
			clipIdsToDelete.forEach((id)=>{
				this.pmManager.deleteNodeFromPmDoc(id)
			})
		}
		this.calculateSceneDuration()
		this.debouncedSaveProjectTimeline()
	}

	///TODO this should take into account linked clips when working out where you can insert clip (cant always push clips to 0)
	resolveConflictsOnInsertClip(newClip){
		const overlapThreshold = 0.5 //determins if we put the clip after existing it overlaps with or move the existing clip
		let sameTrackClips = this.clips.filter(clip => clip.zIndex === newClip.zIndex)
		sameTrackClips.sort((a, b) => a.startTime - b.startTime);
		let newClipEndTime = newClip.startTime + newClip.duration;
		let newClipIndex = sameTrackClips.findIndex(clip => clip.id === newClip.id);

		let updatesArray=[]
		if (newClipIndex > 0) {
			let precedingClip = sameTrackClips[newClipIndex - 1];
			let midpoint = precedingClip.startTime + (precedingClip.duration * overlapThreshold);
			if (newClip.startTime < precedingClip.endTime) {
				if (newClip.startTime < midpoint) {
					// Shift the preceding clip to after the newClip
					let shiftAmount = newClipEndTime - precedingClip.startTime;
					precedingClip.startTime += shiftAmount;
					updatesArray.push({clipId:precedingClip.id,relativeStartTime:precedingClip.relativeStartTime})
					 if(precedingClip instanceof VideoClip) {
						const zoomUpdates = this.moveZoomsWithVideoClip(precedingClip.id,shiftAmount)
						updatesArray = [...updatesArray,...zoomUpdates]
					}
					if(precedingClip.metadata.linkedClipId){
						const linkedClipUpdates = this.moveLinkedClip(precedingClip.metadata.linkedClipId,shiftAmount)
						updatesArray = [...updatesArray,...linkedClipUpdates]
					}

				} else {// Place the newClip after the preceding clip
					newClip.startTime = precedingClip.endTime;
					newClipEndTime = newClip.startTime + newClip.duration;
					updatesArray.push({clipId:newClip.id,relativeStartTime:newClip.relativeStartTime})
				}
			}
		}
		sameTrackClips.sort((a, b) => a.startTime - b.startTime);
		newClipIndex = sameTrackClips.findIndex(clip => clip.id === newClip.id);
		for (let i = newClipIndex + 1; i < sameTrackClips.length; i++) {
			let currentClip = sameTrackClips[i];
			if (currentClip.startTime < newClipEndTime) {
				let shiftAmount = newClipEndTime - currentClip.startTime;
				currentClip.startTime += shiftAmount;
				updatesArray.push({clipId:currentClip.id,relativeStartTime:currentClip.relativeStartTime})
				if(currentClip instanceof VideoClip) {
						const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id,shiftAmount)
						updatesArray = [...updatesArray,...zoomUpdates]
					}
				if(currentClip.metadata.linkedClipId){
					const linkedClipUpdates = this.moveLinkedClip(currentClip.metadata.linkedClipId,shiftAmount)
					updatesArray = [...updatesArray,...linkedClipUpdates]
				}
			}
			newClipEndTime = currentClip.endTime;
		}
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.debouncedSaveProjectTimeline()
	}

	handleDragClip(clip, newStartTime,isDnDMode,isDragPullMode,isAltDragMode,pixelsPerSec) {
		if(!isDnDMode){
			this.handleNormalDragClip(clip.id, newStartTime,isDragPullMode,isAltDragMode,pixelsPerSec)
		}
	}

	
	handleDnDMoveScenes(dragClip, originalScene) {
		let updatesArray = []
		this.moveClipIntoScene(dragClip)
		originalScene.moveClipOutOfScene(dragClip.id)
		dragClip.scene = this
		// Handle linked clips
		if (dragClip.metadata.linkedClipId) {
			const linkedClip = originalScene.findClipById(dragClip.metadata.linkedClipId)
			if (linkedClip) {
				this.moveClipIntoScene(linkedClip)
				originalScene.moveClipOutOfScene(linkedClip.id)
				linkedClip.scene = this
			}
		}
		// Handle zoom clips for videos
		if (dragClip.type === 'video') {
			let clipsToMove = [] // Gather all zoom clips first to avoid array changing during loop
			originalScene.clips.forEach((clip) => {
				if (clip.type === 'zoom' && clip.metadata.parentClip === dragClip.id) {
					clipsToMove.push(clip)
				}
			})

			clipsToMove.forEach((clip) => {
				this.moveClipIntoScene(clip)
				originalScene.moveClipOutOfScene(clip.id)
				clip.scene = this
			})
		}
		return updatesArray
	}


	handleStartBasedDrop(dragClip, dropTime, initialStartTime) {
		let updatesArray = []
		dragClip.startTime = dropTime
		const shiftAmount = dragClip.relativeStartTime - initialStartTime
		updatesArray.push({
			clipId: dragClip.id,
			relativeStartTime: dragClip.relativeStartTime,
			sceneId: this.id
		})
		// Handle linked clips
		if (dragClip.metadata.linkedClipId) {
			const linkedClipUpdates = this.moveLinkedClip(dragClip.metadata.linkedClipId, shiftAmount)
			updatesArray = [...updatesArray, ...linkedClipUpdates]
		}
		// Handle overlapping clips
		this.clips.forEach((clip) => {
			if (clip.id !== dragClip.id && clip.zIndex === dragClip.zIndex) {
				if (clip.startTime.toFixed(1) === dragClip.startTime.toFixed(1)) {
					clip.startTime += 0.01
				}
			}
			if (clip.type === 'zoom' && clip.metadata.parentClip === dragClip.id) {
				const newStartTime = Math.max(clip.relativeStartTime += shiftAmount, 0)
				clip.relativeStartTime = newStartTime
				updatesArray.push({
					clipId: clip.id,
					relativeStartTime: clip.relativeStartTime,
					sceneId: this.id
				})
			}
		})
		// Handle pushing other clips if there are conflicts
		let sortedClips = this.getSortedTrackClips(dragClip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, dragClip.id)
		const newEndTime = dragClip.endTime
		return adjustClipsDraggingRight(
			sortedClips,
			draggedClipIndex,
			newEndTime,
			updatesArray,
			this.moveZoomsWithVideoClip,
			this.moveAudioWithWebcam,
			this.audioClipSpacing
		)
	}


	handleEndBasedDrop(dragClip, dropTime, initialStartTime) {
		let updatesArray = []
		// Calculate new start time
		const targetStartTime = dropTime - dragClip.duration
		let sortedClips = this.getSortedTrackClips(dragClip.zIndex)
		let totalDurationOfPrecedingClips = 0

		sortedClips.forEach((clip) => {
			if (clip.id !== dragClip.id && 
				roundToOneDecimal(clip.endTime) <= roundToOneDecimal(dropTime) && 
				clip.zIndex === dragClip.zIndex) {
				totalDurationOfPrecedingClips += clip.duration
			}
		})

		const newStartTime = Math.max(targetStartTime, totalDurationOfPrecedingClips + this.startTime)
		dragClip.startTime = newStartTime

		// Handle updates after setting new start time
		const shiftAmount = dragClip.relativeStartTime - initialStartTime
		updatesArray.push({
			clipId: dragClip.id,
			relativeStartTime: dragClip.relativeStartTime,
			sceneId: this.id
		})

		// Handle linked clips
		if (dragClip.metadata.linkedClipId) {
			const linkedClipUpdates = this.moveLinkedClip(dragClip.metadata.linkedClipId, shiftAmount)
			updatesArray = [...updatesArray, ...linkedClipUpdates]
		}

		// Handle zoom clips
		this.clips.forEach((clip) => {
			if (clip.type === 'zoom' && clip.metadata.parentClip === dragClip.id) {
				const newStartTime = Math.max(clip.relativeStartTime += shiftAmount, 0)
				clip.relativeStartTime = newStartTime
				updatesArray.push({
					clipId: clip.id,
					relativeStartTime: clip.relativeStartTime,
					sceneId: this.id
				})
			}
		})

		// Handle clips before and after drop point
		return this.adjustSurroundingClips(dragClip, dropTime, updatesArray)
	}

	adjustSurroundingClips(dragClip, dropTime, updatesArray) {
		const clipsInTrack = this.clips.filter(clip => clip.zIndex === dragClip.zIndex)
		let clipsBeforeDrop = []
		let clipsAfterDrop = []
		// Sort clips into before/after arrays
		clipsInTrack.forEach((clip) => {
			if (clip.id !== dragClip.id) {
				const roundedClipStartTime = roundToOneDecimal(clip.startTime)
				const roundedDropTime = roundToOneDecimal(dropTime)
				if (roundedClipStartTime >= roundedDropTime) {
					clipsAfterDrop.push(clip)
				} else {
					clipsBeforeDrop.push(clip)
				}
			}
		})
		// Handle clips before drop point
		this.adjustPrecedingClips(clipsBeforeDrop, dragClip.startTime, updatesArray)
		// Handle clips after drop point
		this.adjustFollowingClips(clipsAfterDrop, dragClip.endTime, updatesArray)
		return updatesArray
	}

	adjustPrecedingClips(sortedClipsBefore, previousClipEndTime, updatesArray) {
		let stopAdjusting = false

		for (let i = sortedClipsBefore.length - 1; i >= 0 && !stopAdjusting; i--) {
			const currentClip = sortedClipsBefore[i]
			const initialStartTime = currentClip.relativeStartTime
			const currentClipEndTime = currentClip.startTime + currentClip.duration

			if (currentClipEndTime > previousClipEndTime) {
				let newStart = previousClipEndTime - currentClip.duration
				
				if (newStart < 0) {
					stopAdjusting = true
					newStart = 0
				}

				previousClipEndTime = newStart
				currentClip.startTime = newStart
				updatesArray.push({
					clipId: currentClip.id,
					relativeStartTime: currentClip.relativeStartTime
				})

				if (currentClip.type === 'video') {
					const finalStartTime = currentClip.relativeStartTime
					const shiftAmount = finalStartTime - initialStartTime
					const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id, shiftAmount)
					updatesArray = [...updatesArray, ...zoomUpdates]
				}
			}
		}
	}

	adjustFollowingClips(sortedClipsAfter, lastEndTime, updatesArray) {
		for (let i = 0; i < sortedClipsAfter.length; i++) {
			const currentClip = sortedClipsAfter[i]
			const initialStartTime = currentClip.relativeStartTime

			if (currentClip.startTime < lastEndTime) {
				const newStart = lastEndTime
				currentClip.startTime = newStart
				updatesArray.push({
					clipId: currentClip.id,
					relativeStartTime: currentClip.relativeStartTime
				})

				if (currentClip.type === 'video') {
					const finalStartTime = currentClip.relativeStartTime
					const shiftAmount = finalStartTime - initialStartTime
					const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id, shiftAmount)
					updatesArray = [...updatesArray, ...zoomUpdates]
				}

				lastEndTime = newStart + currentClip.duration
			}
		}
	}

	shouldHandleDrop(dropTime) { //check its valid drop and its in this scene
		if (!(dropTime || dropTime === 0)) {
			return false
		}
		return (dropTime >= this.startTime && dropTime <= this.endTime) || 
		(dropTime >= this.endTime && this.isLastScene(this.id))
	}


	onDnDDragEnd(dragClip,dropTime,activeDropType){
		if(!this.shouldHandleDrop(dropTime)) return
		const originalSceneId = dragClip.scene.id
		const newSceneId = this.id 
		const initialStartTime = dragClip.relativeStartTime
		let updatesArray=[]

		if(originalSceneId != newSceneId){
			updatesArray = [...updatesArray, ...this.handleDnDMoveScenes(dragClip, dragClip.scene)]
		}

		// Handle the drop based on type 
		updatesArray = [...updatesArray, ...(activeDropType === 'start'
			? this.handleStartBasedDrop(dragClip, dropTime, initialStartTime, updatesArray)
			: this.handleEndBasedDrop(dragClip, dropTime, initialStartTime, updatesArray))]
		
		console.log('updates array for ',this.id,updatesArray)
		this.pmManager.updateMultipleClipFields(updatesArray)
	}

	onDragStart(dragClipZIndex){ 
		this.enableDndPush=false
		this.dndDirection = null
	}

	/////Video clips

	handleUpdateSkipSegmentDuration(clipId,segmentId,newDuration,direction){
		const clip = this.findClipById(clipId)
		clip.updateSkipSegmentDuration(segmentId,newDuration,direction)
		this.pmManager.updateNodeAttrs(clip)
	}

	handleChangeVideoClipPlaybackRate(clipId,playbackRate){
		this.pmManager.startAction('changeClipPlaybackRate')
		const clip = this.findClipById(clipId)
		clip.updateClipPlaybackRate(playbackRate)
		this.pmManager.updateNodeAttrs(clip)
		this.adjustClipsRight(clip,clip.endTime,false)
		this.pmManager.endAction()
	}

	handleChangeSegmentPlaybackRate(clipId,segmentId,playbackRate){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.updateSegmentPlaybackRate(segmentId,playbackRate)
		this.pmManager.updateNodeAttrs(clip)
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let updatesArray=[]
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, clip.endTime, updatesArray,this.moveZoomsWithVideoClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
	}

	addFreezeFrame(clipId,freezeTime){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		if(clip){
			const videoTime = calulateVideoTimeFromTimelineTime(freezeTime,clip)	
			clip.addFreezeFrame(videoTime)
			this.pmManager.updateNodeAttrs(clip)
			this.adjustClipsRight(clip,clip.endTime,false)
		}
	}

	addSkipSegment(clipId,skipTime){
		const clip = this.findClipById(clipId)
		if(clip){
			const videoTime = calulateVideoTimeFromTimelineTime(skipTime,clip)	
			clip.addSkipSegment(videoTime)
			this.pmManager.updateNodeAttrs(clip)
		}
	}

	toggleSkipSegment(clipId,segmentId,isExpanded){ //TODO i think we want to group these which means a start ts action on drag start
		this.pmManager.startAction('toggleSkipSection')
		const clip = this.findClipById(clipId)
		clip.toggleSkipSegment(segmentId,isExpanded)
		if(isExpanded){
			let sortedClips = this.getSortedTrackClips(clip.zIndex)
			const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
			let updatesArray=[]
			updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, clip.endTime, updatesArray,this.moveZoomsWithVideoClip)
			this.pmManager.updateMultipleClipFields(updatesArray)
		}
		this.pmManager.updateNodeAttrs(clip)
		this.pmManager.endAction()
	}

	removeFreeze(clipId,segmentId){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.removeFreeze(segmentId)
		this.pmManager.updateNodeAttrs(clip)
	}

	removeSkip(clipId,segmentId){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.removeSkip(segmentId)
		this.pmManager.updateNodeAttrs(clip)
	}


	handleResizeVideoLeft(clip,targetNewDuration,pixelsPerSec){
		let deltaDuration = targetNewDuration - clip.duration 
		const targetStartTime = clip.startTime - deltaDuration
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
	
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime);
		const ignoreSnapType='end'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(sortedClips[draggedClipIndex-1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
					this.enableResizePush=true
				}
			}
		}
		if(this.startTime ==sortedClips[draggedClipIndex].startTime){
			isAlreadySnapped=true
		}	

		if(!isAlreadySnapped || this.enableResizePush || deltaDuration>0){
	
		if(snappedClipTimes.snapType=='start' && (!isAlreadySnapped || deltaDuration>0)){ //for smooth resize push but without the flashing
			newStartTime=snappedClipTimes.startTime
		}
	}

		let updatesArray=[]
		let newVideoTrimStart = calulateVideoTimeFromTimelineTimeNoTrims(newStartTime,clip) 
		if(clip.metadata.minTrimStart){
			newVideoTrimStart = Math.max(newVideoTrimStart,clip.metadata.minTrimStart)
		}
		let adjustedStartTime = calculateTimelineTimeFromVideoTime(newVideoTrimStart,clip)
		clip.startTime = adjustedStartTime
		clip.pinnedStartTime = adjustedStartTime
		clip.updateTrimValues(newVideoTrimStart,clip.metadata.trimEnd)
		//this.pmManager.updateNodeAttrs(clip)
		updatesArray.push({clipId:clip.id,relativeStartTime:clip.relativeStartTime,duration:clip.duration,metadata:{...clip.metadata},relativePinnedStartTime:clip.relativePinnedStartTime})

		if(clip.metadata.linkedClipId){
			const linkedClip = this.findClipById(clip.metadata.linkedClipId)
			linkedClip.startTime = adjustedStartTime
			linkedClip.pinnedStartTime = adjustedStartTime
			linkedClip.updateTrimValues(newVideoTrimStart,linkedClip.metadata.trimEnd)
			//this.pmManager.updateNodeAttrs(linkedClip)
			updatesArray.push({clipId:linkedClip.id,relativeStartTime:linkedClip.relativeStartTime,duration:linkedClip.duration,metadata:{...linkedClip.metadata},pinnedStartTime:adjustedStartTime})
		}
	
		const disableSnapping = true
		this.adjustClipsLeft(clip,adjustedStartTime,disableSnapping,pixelsPerSec)	
		this.pmManager.updateMultipleClipFields(updatesArray)
		//}
	}
	
	handleResizeVideoRight = (clip, targetNewDuration,pixelsPerSec)=>{
		let deltaDuration = targetNewDuration - clip.duration 
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		const targetEndTime = clip.startTime+targetNewDuration 
		let newEndTime = targetEndTime
		const ignoreSnapType='start'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD

		if(sortedClips[draggedClipIndex+1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if(targetEndTime - sortedClips[draggedClipIndex].endTime > pushThreshold){
					this.enableResizePush=true
				}
			}	
		}
		if(!isAlreadySnapped || this.enableResizePush || deltaDuration<0){

		if(snappedClipTimes.snapType=='end' && (!isAlreadySnapped || deltaDuration<0)){ //for smooth resize push but without the flashing
			newEndTime=snappedClipTimes.endTime
		}
	//	const newVideoTrimEnd = calulateVideoTimeFromTimelineTimeNoTrims(newEndTime,clip)
	let newVideoTrimEnd = calulateVideoTimeFromTimelineTimeNoTrims(newEndTime,clip)
	if(clip.metadata.maxTrimEnd){
		newVideoTrimEnd = Math.min(newVideoTrimEnd,clip.metadata.maxTrimEnd)
	}
		clip.updateTrimValues(clip.metadata.trimStart,newVideoTrimEnd)
		let adjustedEndTime = calculateTimelineTimeFromVideoTime(newVideoTrimEnd,clip)

		if(clip.metadata.linkedClipId){
			const linkedClip = this.findClipById(clip.metadata.linkedClipId)
			linkedClip.updateTrimValues(linkedClip.metadata.trimStart,newVideoTrimEnd)
			this.pmManager.updateNodeAttrs(linkedClip)
		}

		this.pmManager.updateNodeAttrs(clip)
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);

		let updatesArray=[]
		const isAudioTrack = false
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, adjustedEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,this.moveLinkedClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
		}
	}

	onResizeStart=(isVideoResizeMode)=>{
		this.preResizeDuration = this.duration
		this.enableResizePush=false
		this.isVideoResizeMode = isVideoResizeMode
	}


	onResizeStop=()=>{
		this.preResizeDuration = null
		this.isVideoResizeMode = false
	}

	handleResize = (clipId, targetNewDuration,direction,pixelsPerSec) => {
		const clip = this.findClipById(clipId);
		if(clip.type=='video' || (clip.type=='webcam' && !clip.metadata.isVariable)){ 
			if(direction =='left'){
				this.handleResizeVideoLeft(clip,targetNewDuration,pixelsPerSec)
			}else if(direction =='right'){
				this.handleResizeVideoRight(clip,targetNewDuration,pixelsPerSec)
			}
		}else{
			const minDuration = clip.minDuration || 0.5
			// // // // Ensure new duration does not go below the minimum duration TODO fancy text slide min duration based on animation type and text content
			let newDuration = Math.max(targetNewDuration, minDuration) 
			const adjustClipDuration = true
			if (direction === 'left') {
				const targetStartTime = clip.endTime - newDuration
				this.handleResizeLeftHandle(clip,targetStartTime,pixelsPerSec)
			}	else{
				const targetEndTime = clip.startTime + newDuration
				this.handleResizeRightHandle(clip,targetEndTime,pixelsPerSec)
			}
		}	
		this.debouncedSaveProjectTimeline()
	}

	handleResizeLeftHandle(clip,targetStartTime,pixelsPerSec){
		console.log('handleResizeLeftHandle',clip.id)
		let updatesArray = [] 
		const endTime = clip.endTime
		const deltaTime = targetStartTime - clip.startTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		sortedClips = sortedClips.filter(c => !c.parentWebcamClip );

		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime);
		const ignoreSnapType='end'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(sortedClips[draggedClipIndex-1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
					this.enableResizePush=true
				}
			}
		}
		if(this.startTime ==sortedClips[draggedClipIndex].startTime){
			isAlreadySnapped=true
		}	

		if(!isAlreadySnapped || this.enableResizePush || deltaTime>0){

		if(snappedClipTimes.snapType=='start' && (!isAlreadySnapped || deltaTime>0)){ //for smooth resize push but without the flashing
			newStartTime=snappedClipTimes.startTime
		}else{
			//console.log('DONT SNAP')
		}

		const finalStartTimeDelta = newStartTime - clip.startTime
		clip.startTime = newStartTime 	
		clip.duration = endTime - newStartTime
		
		if(clip.type=='webcam'){
			//plaeholder duration is similar to pinnedstarttime and overrides calced duration
			clip.placeholderDuration = clip.duration
			//when resizing left we will shift child clips of the placeholder to the left (when resizing right do nothing)
			const placeholderAudioClips = this.clips.filter(c => c.parentWebcamClip == clip.id);
			placeholderAudioClips.forEach(pa => {
        pa.startTime += finalStartTimeDelta;
        updatesArray.push({ clipId: pa.id, relativeStartTime: pa.relativeStartTime });
    	});

		}
		updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,placeholderDuration:clip.placeholderDuration })

		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,finalStartTimeDelta,this.moveLinkedClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
	}
	}

	handleResizeRightHandle(clip,targetEndTime,pixelsPerSec){
		let updatesArray = [] 
		const deltaTime = targetEndTime - clip.endTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		//exclude the placholder audio clips
		sortedClips = sortedClips.filter(c => !c.parentWebcamClip);
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newEndTime = targetEndTime
		const ignoreSnapType='start'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(sortedClips[draggedClipIndex+1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if(targetEndTime - sortedClips[draggedClipIndex].endTime > pushThreshold){
					this.enableResizePush=true
				}
			}	
		}
		if(!isAlreadySnapped || this.enableResizePush || deltaTime<0){

		if(snappedClipTimes.snapType=='end' && (!isAlreadySnapped || deltaTime<0)){ //for smooth resize push but without the flashing
			newEndTime=snappedClipTimes.endTime
		}
		clip.duration = newEndTime - clip.startTime
		if(clip.type=='webcam'){ //dont need to do move linked clip here as cant resize linked clip
			//plaeholder duration is similar to pinnedstarttime and overrides calced duration
			clip.placeholderDuration = clip.duration
		}
		updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,placeholderDuration:clip.placeholderDuration })

		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, newEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,this.moveLinkedClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
	}
	}

	handleNormalDragClip(clipId, newStartTime,isDragPullMode,isAltDragMode,pixelsPerSec) {
		const clip = this.findClipById(clipId)
		newStartTime = Math.max(newStartTime, this.startTime) //can't drag beyond scene start time in normal drag mode
		if (!clip || clip.startTime==newStartTime) return		
		const isMovingLeft = newStartTime < clip.startTime;
		if(isMovingLeft){
			this.adjustClipsLeft(clip,newStartTime,false,pixelsPerSec,isDragPullMode,isAltDragMode)
		}else{
			this.adjustClipsRight(clip,newStartTime+clip.duration,false,pixelsPerSec,isDragPullMode,isAltDragMode)
		}
		this.debouncedSaveProjectTimeline()
	}

	moveZoomsWithVideoClip=(clipId,shiftAmount)=>{
		let updates=[]
		this.clips.forEach((clip)=>{
			if(clip.type=='zoom' && clip.metadata.parentClip==clipId){
				const newStartTime = Math.max(clip.startTime+=shiftAmount,0)
				clip.startTime=newStartTime
				updates.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime})
			}
		})		
		return updates
	}

	

	updateTextSlideText(clipId,wordsArray,docJson,text) {
		const clip = this.findClipById(clipId)
		if (clip) {
			let textHasChanged = clip.metadata.text !==text
			clip.metadata = {...clip.metadata,text:text,docJson:docJson}
			clip.wordsArray = wordsArray
			clip.calculateMinSlideDuration()
			if(clip.duration < clip.minDuration){
				//this.handleSlideClipDurationLessThanMinimum(clip)
			}
			if(textHasChanged){
				this.pmManager.updateNodeAttrs(clip)
			}
		}
	}

	//For both dragging and resizing to the left
	//for resizing adjustClipDuration is true.  for drag its false 
	adjustClipsLeft(clip,targetStartTime,disableSnapping,pixelsPerSec,isDragPullMode,isAltDragMode){
		if(this.dndDirection != 'left'){
			this.dndDirection = 'left'
			this.enableDndPush=false
		}

		let updatesArray = [] //for prosemirror
		const initialStartTime = clip.relativeStartTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		
		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		if(isAudioTrack){ //exclude the audio clips for placeholder webcam
			sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		}

		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime);
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime)	

		let isAlreadySnapped = false 
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(!disableSnapping && clip.type!='audio' && clip.type!='webcam'){
			if(sortedClips[draggedClipIndex-1]){
				isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
				if(isAlreadySnapped){
					if(!this.enableDndPush){
						if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
							this.enableDndPush=true
						}
					}
				}
			}
			if(this.startTime ==sortedClips[draggedClipIndex].startTime){
				isAlreadySnapped=true
			}
			if(!isAlreadySnapped){
				newStartTime =snappedClipTimes.startTime
			}
		}

		if(!isAlreadySnapped || this.enableDndPush){ //if cant push dont do anything
			clip.startTime = newStartTime 	
			if(clip.type=='audio' || clip.type=='webcam'){
				clip.pinnedStartTime = newStartTime
			}
			const finalStartTime = clip.relativeStartTime
			const shiftAmount = finalStartTime -initialStartTime 
			if(clip.type=='audio' || clip.type=='webcam'){
				updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,relativePinnedStartTime:clip.relativePinnedStartTime })
				if(clip.type=='webcam') {
					const webcamAudioUpdates = this.moveAudioWithWebcam(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...webcamAudioUpdates]
				}

			}else{
				updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration })
			 	if(clip instanceof VideoClip) {
					const zoomUpdates = this.moveZoomsWithVideoClip(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...zoomUpdates]
				}
			}
			if(clip.metadata.linkedClipId){
				const linkedClipUpdates = this.moveLinkedClip(clip.metadata.linkedClipId,shiftAmount)
				updatesArray = [...updatesArray,...linkedClipUpdates]
			}
			updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,shiftAmount,this.moveLinkedClip)
			
			
			if(isDragPullMode || isAltDragMode){	
				let dragPullTracks = [clip.zIndex]
				if(isDragPullMode){
					dragPullTracks=[0,1,2] //zindex
				}
				const allClips = this.getSortedClips()
				updatesArray=dragPullClips(allClips,clip,initialStartTime,shiftAmount,updatesArray,dragPullTracks)
			}
			
			this.pmManager.updateMultipleClipFields(updatesArray)
			this.calculateSceneDuration()
		}
	}

	adjustClipsRight(clip,targetEndTime,disableSnapping,pixelsPerSec,isDragPullMode,isAltDragMode){
		if(this.dndDirection != 'right'){
			this.dndDirection = 'right'
			this.enableDndPush=false
		}
		let updatesArray = [] 
		const initialStartTime = clip.relativeStartTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		if(isAudioTrack){ //exclude the audio clips for placeholder webcam
			sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		}
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newEndTime = targetEndTime
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime)	
		let isAlreadySnapped = false 
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD

		if(clip.type!='audio' && clip.type!='webcam'){
			if(sortedClips[draggedClipIndex+1]){
				isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
				if(isAlreadySnapped){
					if(!this.enableDndPush){
						if(targetEndTime -  sortedClips[draggedClipIndex+1].startTime  > pushThreshold){
								this.enableDndPush=true
							}
						}
					}
				}
				//TODO snap to scene end
			if(!isAlreadySnapped){
				newEndTime =snappedClipTimes.endTime
			}
	}
		
		if(!isAlreadySnapped || this.enableDndPush){ 
			clip.startTime = newEndTime - clip.duration
			if(clip.type=='audio' || clip.type=='webcam'){
				clip.pinnedStartTime = clip.startTime
			}
			const finalStartTime = clip.relativeStartTime
			const shiftAmount = finalStartTime -initialStartTime 

			if(clip.type=='audio' || clip.type=='webcam'){
				updatesArray.push({ clipId: clip.id, duration:clip.duration,relativePinnedStartTime:clip.relativePinnedStartTime,relativeStartTime: clip.relativeStartTime })
				if(clip.type=='webcam') {
					const webcamAudioUpdates = this.moveAudioWithWebcam(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...webcamAudioUpdates]
				}

			}else{
				updatesArray.push({ clipId: clip.id, duration:clip.duration,relativeStartTime: clip.relativeStartTime})
				 if(clip instanceof VideoClip) {
					const zoomUpdates = this.moveZoomsWithVideoClip(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...zoomUpdates]
				}
			}
			if(clip.metadata.linkedClipId){
				const linkedClipUpdates = this.moveLinkedClip(clip.metadata.linkedClipId,shiftAmount)
				updatesArray = [...updatesArray,...linkedClipUpdates]
			}
		
			updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, newEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,this.moveLinkedClip)
	
			if(isDragPullMode || isAltDragMode){	
				let dragPullTracks = [clip.zIndex]
				if(isDragPullMode){
					dragPullTracks=[0,1,2] //zindex
				}
				const allClips = this.getSortedClips()
				updatesArray=dragPullClips(allClips,clip,initialStartTime,shiftAmount,updatesArray,dragPullTracks)
			}
		
		this.pmManager.updateMultipleClipFields(updatesArray)

		this.calculateSceneDuration()
		}
	}


	moveLinkedClip=(linkedClipId, shiftAmount, isDragPullMode )=> {
	  let updates = [];
	  const linkedClip = this.findClipById(linkedClipId);
	  if (!linkedClip) {
	    return updates; // no linked clip found
	  }
	  const oldStartTime = linkedClip.startTime;
	  const newStartTime = oldStartTime + shiftAmount;
	  linkedClip.startTime = newStartTime;
	  linkedClip.pinnedStartTime = newStartTime

	  if (linkedClip.zIndex === -1) {
    	const sceneAudioClips = linkedClip.scene.clips
      	.filter(c => c.zIndex === -1 && !c.parentWebcamClip)
      	.sort((a, b) => a.startTime - b.startTime);
	    // Find position for new clip
  	  const clipBefore = sceneAudioClips
    	  .filter(c => c.startTime < newStartTime)
      	.pop();
	    let newClipIndex = 0;
  	  if (clipBefore) {
    	  newClipIndex = clipBefore.clipIndex + 0.5;
    	}else{
    		newClipIndex = -0.5
    	}
    	//console.log('newClipIndx',newClipIndex)
    	linkedClip.clipIndex = newClipIndex;
  	}


	  updates.push({
	    clipId: linkedClip.id,
	    relativeStartTime: linkedClip.relativeStartTime,
	    relativePinnedStartTime:linkedClip.relativeStartTime,
	    clipIndex:linkedClip.clipIndex,
	    sceneId:this.id
	  });
	  let sortedClips = this.getSortedTrackClips(linkedClip.zIndex)
		const isAudioTrack = linkedClip.type=='audio' || linkedClip.type=='webcam'
		if(isAudioTrack){ //exclude the audio clips for placeholder webcam
			sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		}
	  const draggedClipIndex = sortedClips.findIndex(c => c.id === linkedClip.id);
	  if (draggedClipIndex < 0) {
	    return updates;
	  }
	  const isMovingLeft = (newStartTime < oldStartTime);
	  if (isMovingLeft) {
	    updates = adjustClipsDraggingLeft(
	      sortedClips,
	      draggedClipIndex,
	      newStartTime,
	      updates,
	      this.moveZoomsWithVideoClip,      
	      this.moveAudioWithWebcam,       
	      isAudioTrack,    
	      this.audioClipSpacing,
	      shiftAmount,
	      this.moveLinkedClip
	    );
	  } else {
	    const newEndTime = linkedClip.startTime + linkedClip.duration;
	    updates = adjustClipsDraggingRight(
	      sortedClips,
	      draggedClipIndex,
	      newEndTime,
	      updates,
	      this.moveZoomsWithVideoClip,     
	      this.moveAudioWithWebcam,       
	      isAudioTrack,
	      this.audioClipSpacing,
	      this.moveLinkedClip
	    );
	  }
	  return updates;
	}



// moveLinkedClip(clipId,shiftAmount){
	// 	console.log('move linked clip-------')
	// 	let updates=[]
	// 	const clip = this.findClipById(clipId)
	// 		const linkedClip = this.findClipById(clip.metadata.linkedClipId)
	// 		console.log('linkedClip',linkedClip)
	// 		if(linkedClip){
	// 			linkedClip.startTime += shiftAmount
	// 			updates.push({ clipId: linkedClip.id, relativeStartTime: linkedClip.relativeStartTime})
			
	// 	}
	// 	return updates
	// }


	//todo maybe for webcam audio time should be relative to parent webcam clip
	moveAudioWithWebcam=(clipId,shiftAmount)=>{
		let updates=[]
		this.clips.forEach((clip)=>{
		if(clip.type=='audio' && clip.parentWebcamClip==clipId){
			const newStartTime = Math.max(clip.startTime+=shiftAmount,0)
			clip.startTime=newStartTime
			updates.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,relativePinnedStartTime:clip.relativePinnedStartTime})
			}
		})		
		return updates
	}

	findClipForId(id){
		return this.clips.find(clip => clip.id === id);
	}

  getSortedClips() {
	  return this.clips.sort((a, b) => a.startTime - b.startTime);
  }

	getSortedTrackClips(zIndex) {
		let sameTrackClips = this.clips.filter(clip => clip.zIndex === zIndex);
		return sameTrackClips.sort((a, b) => a.startTime - b.startTime);
	}

  getClipIndex(clips, clipId) {
	  return clips.findIndex(c => c.id === clipId);
  }

  //TODO this is a big old mess clean it up

adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, newStartTime) {
	let currentPosition = this.startTime;
	const draggedClip = sortedClips[draggedClipIndex];

	// const calculateMinPosition = (clips, clipIndex) => {
	// 	let position = this.startTime;
	// 	for (let j = 0; j < clipIndex; j++) {
	// 		position += clips[j].duration;
	// 		const nextClip = clips[j + 1];
	// 		if (needsSpacing(clips[j], nextClip)) {
	// 			position += this.audioClipSpacing;
	// 		}
	// 	}
	// 	return position;
	//};

	const calculateMinPosition = (clips, clipIndex) => {
	let position = this.startTime;
	for (let j = 0; j < clipIndex; j++) {
		position += clips[j].duration;
		// Check spacing with next clip in same track
		const nextClip = clips[j + 1];
		if (needsSpacing(clips[j], nextClip)) {
			position += this.audioClipSpacing;
		}

		// If this is a linked clip, check spacing with next clip in linked track
		if (clips[j].metadata.linkedClipId) {
			const linkedClip = this.findClipById(clips[j].metadata.linkedClipId);
			if (linkedClip) {
				const linkedTrackClips = this.getSortedTrackClips(linkedClip.zIndex).filter(c => !c.parentWebcamClip);
				const linkedIndex = linkedTrackClips.findIndex(c => c.id === linkedClip.id);
				const nextLinkedClip = linkedTrackClips[linkedIndex + 1];
				if (nextLinkedClip && needsSpacing(linkedClip, nextLinkedClip)) {
					position += this.audioClipSpacing;
				}
			}
		}
	}
	return position;
};

// 	const needsSpacing = (clip1, clip2) => {
// 	return clip1.zIndex === -1 && (
// 		clip1.metadata.isVariable || 
// 		clip2.metadata.isVariable ||
// 		clip1.type !== 'webcam' || 
// 		clip2.type !== 'webcam' || 
// 		clip1.captureId !== clip2.captureId
// 	);
// };

const needsSpacing = (clip1, clip2) => {
	return clip1.zIndex === -1 && (
		clip1.type !== 'webcam' && clip2.type !== 'webcam' 
	);
};

	// Handle dragged clip with linked clip
	if (draggedClip.metadata.linkedClipId) {
		const linkedClip = this.findClipById(draggedClip.metadata.linkedClipId);
		if (linkedClip) {
			const linkedTrackClips = this.getSortedTrackClips(linkedClip.zIndex).filter(c => !c.parentWebcamClip);
			const draggedTrackClips = this.getSortedTrackClips(draggedClip.zIndex).filter(c => !c.parentWebcamClip);
			
			const linkedMinPosition = calculateMinPosition(linkedTrackClips, linkedTrackClips.findIndex(c => c.id === linkedClip.id));
			const draggedMinPosition = calculateMinPosition(draggedTrackClips, draggedTrackClips.findIndex(c => c.id === draggedClip.id));
			
			const minPosition = Math.max(linkedMinPosition, draggedMinPosition);
			if (newStartTime <= minPosition) return minPosition;
		}
	}

	// Handle preceding clips
	for (let i = 0; i < draggedClipIndex; i++) {
		const clip = sortedClips[i];
		if (clip.metadata.linkedClipId) {
			const linkedClip = this.findClipById(clip.metadata.linkedClipId);
			if (linkedClip) {
				const linkedTrackClips = this.getSortedTrackClips(linkedClip.zIndex).filter(c => !c.parentWebcamClip);
				const linkedClipIndex = linkedTrackClips.findIndex(c => c.id === linkedClip.id);
				const linkedTrackPosition = calculateMinPosition(linkedTrackClips, linkedClipIndex);
				currentPosition = Math.max(currentPosition, linkedTrackPosition);
				currentPosition += clip.duration;
			}
		} else {
			currentPosition += clip.duration;
		}

		if (clip.zIndex === -1) {
			const nextClip = i < draggedClipIndex - 1 ? sortedClips[i + 1] : draggedClip;
			if (needsSpacing(clip, nextClip)) {
				currentPosition += this.audioClipSpacing;
			}
		}
	}

	const buffer = this.startTime > 0 ? 0.00001 : 0;
	return Math.max(newStartTime, currentPosition + buffer);
}

	calculateSceneDuration(isDnDMode){
		let maxEndTime = 0;
		this.clips.forEach(clip => {
			if (clip.endTime > maxEndTime) {
				maxEndTime = clip.endTime;
				if(clip.type=='audio'){
					maxEndTime +=this.audioClipSpacing
				}
			}
		});
		maxEndTime -=this.startTime 
		const newCalculatedDuration = maxEndTime > 0 ? maxEndTime : MIN_SCENE_DURATION;
		if(this.isVideoResizeMode){
			this.duration = Math.max(this.preResizeDuration,newCalculatedDuration)
		}else{
			this.duration = newCalculatedDuration;
		}	
		if(this.onDurationUpdate){
			this.onDurationUpdate()
		}
	}

	getClipsAsJson() {
		return this.clips.map(clip => clip.toJSON());
	}

	toJSON() {
		const clips = this.getClipsAsJson()
		return {
		  id: this.id,
		  title:this.title,
		  clips:clips,
		  duration:this.duration,

		  sceneIndex:this.sceneIndex,
		  sceneStartTime:this.startTime

		};
  }

 get endTime() {
		return this.startTime+this.duration
	}

	destroy() {
		this.clips.forEach(clip => {
			if (clip.destroy && typeof clip.destroy === 'function') {
				clip.destroy();
			}
		});
		this.clips = null;
	}
}


export { TimelineScene }



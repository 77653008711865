import React from 'react';
import { calculateTimelineTimeFromVideoTime } from '../../../../../timeline/utils/calculateTimelineTimeFromVideoTime'


const EditorTimelineClipContentsScreenChunks = ({ clip,preVideoResizeSnapshot }) => {
  let screenChunks = []
  let clipDuration = clip.duration

  ///When resizing webcam clip and making it shorter show the snapshot chunks from preresize state
  if(preVideoResizeSnapshot && preVideoResizeSnapshot.videoClipId==clip.id && (preVideoResizeSnapshot.endTime - preVideoResizeSnapshot.startTime) > clip.duration){
    screenChunks=preVideoResizeSnapshot.screenChunks
    clipDuration = preVideoResizeSnapshot.endTime - preVideoResizeSnapshot.startTime
  }else{
    {clip.recordingChunks && clip.recordingChunks.map((chunk, index) => {
      if (chunk.inferredTitle || chunk.appName) {
        const startTime = Math.max(chunk.startTime, clip.metadata.trimStart)
        const endTime = Math.min(chunk.startTime + chunk.duration, clip.metadata.trimEnd)
        const duration = calculateTimelineTimeFromVideoTime(endTime, clip) - calculateTimelineTimeFromVideoTime(startTime, clip)

        const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime, clip) - clip.startTime
        screenChunks.push({
          duration: duration,
          startTime: relativeStartTime,
          inferredTitle: chunk.inferredTitle,
          inferredSubtitle: chunk.inferredSubtitle,
          appName: chunk.appName
        })
      }
    })
  }
}


  const singleChunk = screenChunks.length < 2

  return (
    <>
      <div
        data-single-screen-chunk={singleChunk ? true : false} className='editor-timeline-clip-inner'
      >
        {screenChunks.map((chunk, index) => {

          const left = Number(((chunk.startTime / clipDuration) * 100).toFixed(2))
          const width = Number(((chunk.duration / clipDuration) * 100).toFixed(2))
          if ((width > 0) && (width <= 101) && (left >= 0) && (left <= 101)) {
          return (
            <div
            key={index}
            className='editor-timeline-clip-inner-chunk'
            style={{
              left: `${left}%`,
              width: `${width}%`
            }}
          >
            <div className="editor-timeline-clip-inner-chunk-text">
              {chunk.inferredTitle || chunk.appName} <span className='editor-timeline-clip-inner-chunk-text--light'>{chunk.inferredSubtitle}</span>
            </div>
            <div className="editor-timeline-clip-inner-chunk-bg" />
          </div>
          )
        }
        })}



        {/* {chunks && chunks.map((chunk, index) => {
          if (chunk.inferredTitle || chunk.appName) {
            const startTime = Math.max(chunk.startTime, clip.metadata.trimStart)
            const endTime = Math.min(chunk.startTime + chunk.duration, clip.metadata.trimEnd)
            const duration = calculateTimelineTimeFromVideoTime(endTime, clip) - calculateTimelineTimeFromVideoTime(startTime, clip)

            const relativeStartTime = calculateTimelineTimeFromVideoTime(startTime, clip) - clip.startTime

            const left = Number(((relativeStartTime / clip.duration) * 100).toFixed(2))
            const width = Number(((duration / clip.duration) * 100).toFixed(2))

            if ((width > 0) && (width <= 101) && (left >= 0) && (left <= 101)) {
              return (
                <div
                  key={index}
                  className='editor-timeline-clip-inner-chunk'
                  style={{
                    left: `${left}%`,
                    width: `${width}%`
                  }}
                >

                  <div className="editor-timeline-clip-inner-chunk-text">
                    {chunk.inferredTitle || chunk.appName} <span className='editor-timeline-clip-inner-chunk-text--light'>{chunk.inferredSubtitle}</span>
                  </div>
                  <div className="editor-timeline-clip-inner-chunk-bg" />
                </div>
              )
            }
          } 
        }
        )} */}
      </div>
    </>
  );
};

export default EditorTimelineClipContentsScreenChunks;
import React from 'react';

const EditorInsertLauncherSearchBar = ({ 
  searchQuery, 
  setSearchQuery, 
  searchInputRef 
}) => {
  return (
    <div className="editorInsertLauncher-searchContainer">      
      <input
        ref={searchInputRef}
        type="text"
        className="editorInsertLauncher-searchInput"
        placeholder="Insert"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};


export default EditorInsertLauncherSearchBar;
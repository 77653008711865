import store from '../../store'
import find from 'lodash/find'



export function getWebcamForCaptureId(captureId) {	
	const state = store.getState()
	const webcam= find(state.webcamRecordings, {'capture_id':`${captureId}`})
	return webcam
}


import React, { useState, useEffect } from 'react';
import Icon from '../misc/Icon'
import RecordWebcamToolbarDevices from './RecordWebcamToolbarDevices'
import RecordWebcamToolbarStartStopBtn from './RecordWebcamToolbarStartStopBtn'

const RecordWebcamToolbar = ({ 
  isRecording,
  onRestart,
  onStartRecording,
  onOpenChange,
  onStopRecording,
  activeCamera, 
  setActiveCamera, 
  activeMicrophone, 
  setActiveMicrophone,
  cameras,
  microphones,
  isReadyToRecord,
  isDisplayRecording  
}) => {

    
  

  return (    
    
    <>
    {/*}
    <RecordWebcamToolbarDevices 
      microphones={microphones}
      activeMicrophone={activeMicrophone}
      setActiveMicrophone={setActiveMicrophone}
      cameras={cameras}
      activeCamera={activeCamera}
      setActiveCamera={setActiveCamera}
      isDisplayRecording={isDisplayRecording}
    /> 
    */}


    <div className='recordWebcamWindow-toolbarContainer'>
      <div className='recordWebcamWindow-toolbar'>          

        <button onClick={onRestart} data-visibility-state={isDisplayRecording ? 'visible' : 'hidden'} className='recordWebcamWindow-toolbar-restartBtn'>        
          <Icon name='restartRecordMedium' />
        </button>   

        <RecordWebcamToolbarStartStopBtn
          isRecording={isRecording}
          onStartRecording={onStartRecording}
          onStopRecording={onStopRecording}
          isReadyToRecord={isReadyToRecord}
          isDisplayRecording={isDisplayRecording}
        /> 


      </div>
    </div>
    </>   
  );
};

export default RecordWebcamToolbar;
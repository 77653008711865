import React, { useState,useEffect,useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import orderBy from 'lodash/orderBy' 
import {getRecentMedia} from '../../../../utils/getRecentMedia'
import AddMediaPopoverResultsList from './AddMediaPopoverResultsList'
import AddMediaPopoverPrefillSearchesGallery from './AddMediaPopoverPrefillSearchesGallery'
import { prefillSearchesImage, prefillSearchesVideo } from './prefillSearchTerms';
//import {searchPexels} from '../../../../actions/imageSearch'
import { debounce } from 'lodash';
import filter from 'lodash/filter'
import AddMediaPopoverContent from './AddMediaPopoverContent'

//media type is webcam, image or video

function AddMediaPopover(props) {
  const { 
    children,
    handleClosePopover,
    showPopover,
    handleNewImageFileUpload,
    insertImageFromRecent,
    handleNewVideoFileUpload,
    insertVideoFromRecent, 
    mediaType,
    handleNewWebcamFileUpload,
    insertWebcamFromRecent,
    insertScreencastFromRecent,
    isWebcam,
    addWebcamFromLibrary
  }=props

  const [submitLoading, setSubmitLoading] = useState(false);
  const [recentMedia,setRecentMedia] = useState([])

  const handleOpenPopover = () => {
    const recentMedia=getRecentMedia(mediaType)
    let filteredMedia 
    if(mediaType === 'image'){
      filteredMedia = filter(recentMedia, {type: 'image'})
    }else if(mediaType === 'video'){
      filteredMedia = filter(recentMedia, {type: 'video'})
    }else if(mediaType === 'webcam'){
      filteredMedia = filter(recentMedia, {type: "webcamVideo"})
    }else if(mediaType === 'screencast'){
      filteredMedia = filter(recentMedia, {type: "screencast"})
    }else{ //mediaType=='mixed'
      filteredMedia = recentMedia
    }

    setRecentMedia(filteredMedia)
  };

  const closePopover = () =>{
    handleClosePopover()
  }  

  return (
    <Popover.Root open={showPopover} onOpenChange={(open) => {
      if (!open && !submitLoading) {
        closePopover(); // This will flush the blobs and call the handleClosePopover passed from the parent
      }
    }}>
      <Popover.Trigger asChild onClick={handleOpenPopover}>
        {children}
      </Popover.Trigger>
      <Popover.Content 
        side="top"
        sideOffset={7}
        align="center"
        //alignOffset={-10}
        onCloseAutoFocus={submitLoading ? null : closePopover}
        className='popover popover--addMediaPopover forceDarkTheme'
      >
        <AddMediaPopoverContent
          handleClosePopover={handleClosePopover}          
          handleNewImageFileUpload={handleNewImageFileUpload}
          insertImageFromRecent={insertImageFromRecent}
          handleNewVideoFileUpload={handleNewVideoFileUpload}
          insertVideoFromRecent={insertVideoFromRecent}
          mediaType={mediaType}
          handleNewWebcamFileUpload={handleNewWebcamFileUpload}
          insertWebcamFromRecent={insertWebcamFromRecent}
          insertScreencastFromRecent={insertScreencastFromRecent}
          isWebcam={isWebcam}
          recentMedia={recentMedia}
          addWebcamFromLibrary={addWebcamFromLibrary}
        />
      </Popover.Content>
    </Popover.Root>
  );
}

export default AddMediaPopover;

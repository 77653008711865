import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { animated, useSpring } from '@react-spring/three';
import renderOrders from '../renderOrders';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import { calulateVideoTimeFromTimelineTime } from '../../timeline/utils/calulateVideoTimeFromTimelineTime';
import WebcamVideoPreviewMaterial from './WebcamVideoPreviewMaterial';

const createPreviewShape = (width, height, radius) => {
  const shape = new THREE.Shape();
  const minDimension = Math.min(width, height);
  const circleBlend = Math.min(1, radius / (minDimension / 2));
  
  // For circle: control point distance from vertex = radius * 0.552284749831
  // For rounded rect: control point distance = radius * 0.448715250169
  const controlRatio = 0.448715250169 + (0.552284749831 - 0.448715250169) * circleBlend;
  
  const x = -width / 2;
  const y = -height / 2;
  const w = width;
  const h = height;
  const r = radius;
  
  // Adjust corners based on circle blend
  const cpd = r * controlRatio; // control point distance

  // Start at top middle
  shape.moveTo(x + r, y);
  
  // Top edge and top-right corner
  shape.lineTo(x + w - r, y);
  shape.bezierCurveTo(
    x + w - r + cpd, y,
    x + w, y + r - cpd,
    x + w, y + r
  );
  
  // Right edge and bottom-right corner
  shape.lineTo(x + w, y + h - r);
  shape.bezierCurveTo(
    x + w, y + h - r + cpd,
    x + w - r + cpd, y + h,
    x + w - r, y + h
  );
  
  // Bottom edge and bottom-left corner
  shape.lineTo(x + r, y + h);
  shape.bezierCurveTo(
    x + r - cpd, y + h,
    x, y + h - r + cpd,
    x, y + h - r
  );
  
  // Left edge and top-left corner
  shape.lineTo(x, y + r);
  shape.bezierCurveTo(
    x, y + r - cpd,
    x + r - cpd, y,
    x + r, y
  );
  
  return shape;
};


const WebcamVideoPreview = (props) => {
  const { clip, time, isDraggingWebcam, isResizingWebcam, cameraPreviewRect, preVideoResizeSnapshot } = props;

  const [isDelayedDraggingResizing, setIsDelayedDraggingResizing] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const dragging = isDraggingWebcam || isResizingWebcam;

    if (dragging) {
      // Started dragging/resizing: set delayed state to true immediately
      setIsDelayedDraggingResizing(true);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    } else {
      // Stopped dragging/resizing: set delayed state to false after 400ms
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsDelayedDraggingResizing(false);
      }, 500);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isDraggingWebcam, isResizingWebcam]);

  // Then derive immediate from the states:
  const immediate = !(isDelayedDraggingResizing && !isDraggingWebcam && !isResizingWebcam);


  //console.log('inAnimationWindow', inAnimationWindow)

  const isVariable = clip.metadata.isVariable;
  let startHiddenDuration = clip.metadata.startHiddenDuration || 0;
  let endHiddenDuration = clip.metadata.endHiddenDuration || 0;
  const relativeTime = time - clip.startTime;
  const localTime = clip.metadata.isVariable ? relativeTime : calulateVideoTimeFromTimelineTime(time, clip);

  let visible = false;
  if (!clip.metadata.isVariable) {
    if (localTime >= clip.metadata.trimStart && localTime < clip.metadata.trimEnd) {
      visible = true;
    }
    if (clip.isUploadingVideo && relativeTime >= clip.startTime && relativeTime < clip.endTime) {
      visible = true;
    }
    if (relativeTime < startHiddenDuration || relativeTime > clip.duration - endHiddenDuration) {
      visible = false;
    }
  } else {
    if (time >= clip.startTime && time < clip.endTime) {
      visible = true;
    }
  }

  //If currently resizing the clip it should be visible (prevent flashing as resize it shorter)
  if(preVideoResizeSnapshot && preVideoResizeSnapshot.webcamClipId === clip.id){
    visible=true
  }




  // const SCENE_WIDTH = 1920;
  // const SCENE_HEIGHT = 1080;
  const {sceneWidth,sceneHeight} = props
  // console.log('props',props.sceneWidth)

  const rect = cameraPreviewRect || {};
  const left = rect.x;
  const top = rect.y;
  const displayWidth = rect.width;
  const displayHeight = rect.height;
  const webcamDisplayRadius = rect.cornerRadius

  // console.log('cameraPreviewRect', cameraPreviewRect)

  const borderMode = clip.metadata.borderMode //auto, forceShow or forceHide
  
  let frameBorderWidth = 0  
  if(borderMode === 'forceShow') {
    frameBorderWidth = 10
  }
  
  if ((displayWidth > 680) || (displayHeight > 700)) {
    frameBorderWidth = 0;
    if(borderMode === 'forceShow' && displayHeight < sceneHeight){
      frameBorderWidth = 10
    }
  }
  if(displayWidth > (sceneWidth / 2)){ // overhalf float
    frameBorderWidth = 0
    if(borderMode === 'forceShow' && displayHeight < sceneHeight){
      frameBorderWidth = 10      
    }
  } 
  if ((displayWidth < 360) || (displayHeight < 360)) {
    frameBorderWidth = 6;
  }
  if ((displayWidth < 20) || (displayHeight < 20)) {
    frameBorderWidth = 0;
  }
  if(borderMode === 'forceHide'){
    frameBorderWidth = 0
  }

  const [currentRadius, setCurrentRadius] = useState(rect.cornerRadius);
  const radiusTimeoutRef = useRef(null);

  useEffect(() => {
  if (isResizingWebcam) {
    // During resize, use default radius
    setCurrentRadius(20);
  } else {
    // Immediately use the config radius when resize ends
    setCurrentRadius(webcamDisplayRadius !== undefined ? webcamDisplayRadius : 20);
  }
}, [isResizingWebcam, webcamDisplayRadius]);

  const webcamFrameDisplayRadius = currentRadius + frameBorderWidth;
  const meshWidth = displayWidth - (frameBorderWidth * 2);
  const meshHeight = displayHeight - (frameBorderWidth * 2);

  const animationConfig = {
    tension: 280,
    friction: 24,
  }

  const [positionSprings, positionApi] = useSpring(() => ({
    position: [left + displayWidth / 2 - sceneWidth / 2, -(top + displayHeight / 2 - sceneHeight / 2), 0],
    config: animationConfig,
    immediate
  }));

  const [meshDimensionSprings, meshDimensionApi] = useSpring(() => ({
    meshWidth,
    meshHeight,
    radius: currentRadius,
    config: animationConfig,    
    immediate
  }));

  const [frameDimensionSprings, frameDimensionApi] = useSpring(() => ({
    displayWidth,
    displayHeight,
    config: animationConfig,
    immediate
  }));

  useEffect(() => {
    positionApi.start({
      position: [left + displayWidth / 2 - sceneWidth / 2, -(top + displayHeight / 2 - sceneHeight / 2), 0],
      immediate
    });
  }, [left, top, displayWidth, displayHeight, immediate]);

  useEffect(() => {
    meshDimensionApi.start({
      meshWidth,
      meshHeight,
      radius: currentRadius,
      immediate
    });
  }, [meshWidth, meshHeight, currentRadius, immediate]);

  useEffect(() => {
    frameDimensionApi.start({
      displayWidth,
      displayHeight,
      immediate
    });
  }, [displayWidth, displayHeight, immediate]);

  const deviceRef = useRef();
  const frameRef = useRef();

  const AnimatedMeshGeometry = animated(({ meshWidth, meshHeight, radius }) => {
    const shape = createPreviewShape(meshWidth, meshHeight, radius);
    const geometry = new THREE.ShapeGeometry(shape, 32);
    
    // Calculate UV coordinates by following the bezier curve paths
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    const positions = geometry.attributes.position.array;
    
    // Map each vertex to its curve parameter t (0 to 1 along each edge)
    // For a point on a bezier curve, we can calculate its parameter t
    // and use that to map UVs proportionally
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertexIndex = i * 3;
      const x = positions[vertexIndex];
      const y = positions[vertexIndex + 1];
      
      // Find nearest point on shape's curves and get its parameter
      // This gives us proper UV coordinates that follow the curve
      const uvX = (x + meshWidth / 2) / meshWidth;
      const uvY = (y + meshHeight / 2) / meshHeight;
      
      uvs[i * 2] = uvX;
      uvs[i * 2 + 1] = uvY;
    }
    
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  const AnimatedFrameGeometry = animated(({ displayWidth, displayHeight }) => {
    const shape = createRoundedRectShape(displayWidth, displayHeight, webcamFrameDisplayRadius);
    const geometry = new THREE.ShapeGeometry(shape);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + displayWidth / 2) / displayWidth;
      uvs[i * 2 + 1] = (vertex.y + displayHeight / 2) / displayHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  const placeholderBG = "rgb(170,170,170)";
  const webcamRenderOrder = (displayWidth === sceneWidth) ? 
    renderOrders.webcamVideoFullScreen : 
    renderOrders.webcamVideo;

  const isHidden = displayWidth < 20;

  return (
    <animated.group position={positionSprings.position} renderOrder={webcamRenderOrder}>
      <mesh
        ref={deviceRef}
        visible={visible}
        renderOrder={webcamRenderOrder}
      >
        <AnimatedMeshGeometry {...meshDimensionSprings} />
        {clip.metadata.isVariable ? (
          <meshBasicMaterial 
            color={placeholderBG}
            transparent
            opacity={1}
          />
        ) : (
          <WebcamVideoPreviewMaterial 
            videoElement={clip.video}
            meshWidth={meshWidth}
            meshHeight={meshHeight}
            zoom={clip.metadata.zoom}
            xOffset={clip.metadata.xOffset}
            yOffset={clip.metadata.yOffset}
            colorAdjustments={clip.metadata.colorAdjustments}
            renderOrder={webcamRenderOrder}
            videoAssetWidth={clip.metadata.originalWidth}
            videoAssetHeight={clip.metadata.originalHeight}             
            opacity={isHidden ? 0 : 1}
            faceBox={clip.metadata.faceBox}
            applyFaceBox={clip.metadata.applyFaceBox || false}
            isDelayedDraggingResizing={isDelayedDraggingResizing}
            immediate={immediate}
            isDraggingWebcam={isDraggingWebcam}
            isResizingWebcam={isResizingWebcam}
            facePaddingRatio={clip.metadata.facePaddingRatios?.[cameraPreviewRect?.sizeName] || 0.6}
          />
        )}
      </mesh>

      {frameBorderWidth > 0 && (
        <mesh
          ref={frameRef}
          visible={visible}
          renderOrder={renderOrders.webcamVideoFrame}
        >
          <AnimatedFrameGeometry {...frameDimensionSprings} />
          <meshBasicMaterial 
            color="rgb(248,248,252)"
            transparent
            opacity={1}
          />
        </mesh>
      )}
    </animated.group>
  );
};

export default WebcamVideoPreview;
import React, { useState, useEffect, useRef } from 'react'
import * as Select from '@radix-ui/react-select';
import Icon from '../../misc/Icon';
import DPTabs from './DPTabs';
import DPAnimationPanelTransitionPopover from './DPAnimationPanelTransitionPopover'
import DPSlider from './DPSlider';
import DPNumberInput from './DPNumberInput';

/**
 * Animation panel for detail panel
 * 
 * Special handling for screen videos in desktop mode:
 * - Only "Appear" and "Fade In/Out" transitions are allowed
 * - When switching to desktop mode, transitions are auto-converted:
 *   - "Appear" stays as "Appear"
 *   - All other transitions are converted to "Fade In/Out"
 */

const defaultStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade In", iconName: "fadeIn" },
  fadeAndMoveUp: { label: "Fade Up", iconName: "fadeUp" },
  fadeAndMoveDown: { label: "Fade Down", iconName: "fadeUp" },
  fadeAndMoveRight: { label: "Fade Left", iconName: "fadeRight" },
  fadeAndMoveLeft: { label: "Fade Right", iconName: "fadeRight" },
  fadeAndScaleUp: { label: "Scale Up", iconName: "scaleUp" },
  fadeAndScaleDown: { label: "Scale Down", iconName: "scaleDown" },
  moveUp: { label: "Move Up", iconName: "scaleDown" },
};


const defaultEndTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade Out", iconName: "fadeIn" },
  fadeAndMoveUp: { label: "Fade Up", iconName: "fadeUp" },
  fadeAndMoveDown: { label: "Fade Down", iconName: "fadeUp" },
  fadeAndMoveRight: { label: "Fade Right", iconName: "fadeRight" },
  fadeAndMoveLeft: { label: "Fade Left", iconName: "fadeRight" },
  fadeAndScaleUp: { label: "Scale Up", iconName: "scaleDown" },
  fadeAndScaleDown: { label: "Scale Down", iconName: "scaleDown" },
};

// New configs for screen videos in desktop mode
const screenVideoDesktopStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade In", iconName: "fadeIn" },
};

const screenVideoDesktopEndTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  fade: { label: "Fade Out", iconName: "fadeIn" },
};

const donutChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },  
  countUp: { label: "Count Up", iconName: "countUp" },
  fadeIn: { label: "Fade In", iconName: "fadeIn" },
  scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};

const barChartStartTransitionConfigs = {
  none: { label: "Appear", iconName: "noTransition" },
  countUp: { label: "Count Up", iconName: "countUp" },
  // fadeIn: { label: "Fade In", iconName: "fadeIn" },
  // scaleUp: { label: "Scale Up", iconName: "scaleUp" },
};

// Mapping between enter and exit transitions
const transitionMappings = {
  // Enter to Exit mappings
  enterToExit: {
    none: 'none', // Appear to Appear
    fade: 'fade', // Fade In to Fade Out
    fadeAndMoveUp: 'fadeAndMoveDown', // Fade Up to Fade Down
    fadeAndMoveDown: 'fadeAndMoveDown', // Fade Down to Fade Up
    fadeAndMoveRight: 'fadeAndMoveLeft', // Fade Left to Fade Right
    fadeAndMoveLeft: 'fadeAndMoveRight', // Fade Right to Fade Left
    fadeAndScaleUp: 'fadeAndScaleDown', // Scale Up to Scale Down
    fadeAndScaleDown: 'fadeAndScaleUp', // Scale Down to Scale Up
  },
  // Exit to Enter mappings
  exitToEnter: {
    none: 'none', // Appear to Appear
    fade: 'fade', // Fade Out to Fade In
    fadeAndMoveUp: 'fadeAndMoveDown', // Fade Up to Fade Down
    fadeAndMoveDown: 'fadeAndMoveDown', // Fade Down to Fade Up
    fadeAndMoveRight: 'fadeAndMoveLeft', // Fade Right to Fade Left
    fadeAndMoveLeft: 'fadeAndMoveRight', // Fade Left to Fade Right
    fadeAndScaleUp: 'fadeAndScaleDown', // Scale Up to Scale Down
    fadeAndScaleDown: 'fadeAndScaleUp', // Scale Down to Scale Up
  }
};

const DPItemAnimationPanel = ({activeStartTransition,activeEndTransition,handleUpdateMetadata,itemType,animationGroupingType,item,handleUpdateChartAnimationType}) => {
  const [transitionPopoverOpen, setTransitionPopoverOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('start');
  const [prevDisplayMode, setPrevDisplayMode] = useState(item?.metadata?.displayMode);
  const [showSwitchHint, setShowSwitchHint] = useState(false);
  const [switchPlace, setSwitchPlace] = useState('');
  const [switchConfig, setSwitchConfig] = useState('');
  const [lastChangedTransition, setLastChangedTransition] = useState(null);
  const [prevStartTransition, setPrevStartTransition] = useState(activeStartTransition);
  const [prevEndTransition, setPrevEndTransition] = useState(activeEndTransition);
  const [showDoneConfirmation, setShowDoneConfirmation] = useState(false);
  const [hintVisibilityState, setHintVisibilityState] = useState('hidden'); // 'hidden', 'visible', 'fading', 'clicked'
  
  const fadeTimeoutRef = useRef(null);

  // Check if this is a screen video in desktop mode
  const isScreenVideoDesktop = itemType === 'screenVideo' && item.metadata && item.metadata.displayMode === 'desktop';
  const currentDisplayMode = item?.metadata?.displayMode;

  // Auto-switch transitions for screen videos when display mode changes
  React.useEffect(() => {
    // Skip if not a screen video
    if (itemType !== 'screenVideo' || !item.metadata) return;
    
    // Handle switching to desktop mode
    if (currentDisplayMode === 'desktop' && prevDisplayMode !== 'desktop') {
      // For start transition: keep 'none' as is, otherwise set to 'fade'
      if (activeStartTransition !== 'none' && activeStartTransition !== 'fade') {
        handleUpdateMetadata('startTransitionType', 'fade');
      }
      
      // For end transition: keep 'none' as is, otherwise set to 'fade'
      if (activeEndTransition !== 'none' && activeEndTransition !== 'fade') {
        handleUpdateMetadata('endTransitionType', 'fade');
      }
    }
    
    // Update the previous display mode
    setPrevDisplayMode(currentDisplayMode);
  }, [currentDisplayMode, prevDisplayMode, activeStartTransition, activeEndTransition, handleUpdateMetadata, itemType, item.metadata]);

  // Handle showing switch hint when transitions change
  useEffect(() => {
    // Skip for chart items since they only have enter animations
    if (itemType === 'barChart' || itemType === 'donutChart') {
      return;
    }
    
    if (activeStartTransition !== prevStartTransition) {
      // Start transition changed
      const mappedEndTransition = transitionMappings.enterToExit[activeStartTransition];
      if (mappedEndTransition && mappedEndTransition !== activeEndTransition) {
        // Get the correct label from the appropriate config
        let configLabel;
        if (isScreenVideoDesktop) {
          configLabel = screenVideoDesktopEndTransitionConfigs[mappedEndTransition]?.label || 'Appear';
        } else {
          configLabel = defaultEndTransitionConfigs[mappedEndTransition]?.label || 'Appear';
        }
        
        setSwitchPlace('Exit');
        setSwitchConfig(configLabel);
        setShowSwitchHint(true);
        setHintVisibilityState('visible');
        setLastChangedTransition('start');
      }
      setPrevStartTransition(activeStartTransition);
    }
    
    // Clear any existing timeout
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
    }
    
    // Start fading out after 4.5 seconds
    if (hintVisibilityState === 'visible') {
      fadeTimeoutRef.current = setTimeout(() => {
        setHintVisibilityState('fading');
      }, 4500);
      
      // Hide completely after 5 seconds
      const hideTimeout = setTimeout(() => {
        setShowSwitchHint(false);
        setHintVisibilityState('hidden');
      }, 5000);
      
      return () => {
        clearTimeout(fadeTimeoutRef.current);
        clearTimeout(hideTimeout);
      };
    }
  }, [activeStartTransition, activeEndTransition, isScreenVideoDesktop, itemType, hintVisibilityState]);

  // Handle showing switch hint when end transition changes
  useEffect(() => {
    // Skip for chart items since they only have enter animations
    if (itemType === 'barChart' || itemType === 'donutChart') {
      return;
    }
    
    if (activeEndTransition !== prevEndTransition) {
      // End transition changed
      const mappedStartTransition = transitionMappings.exitToEnter[activeEndTransition];
      if (mappedStartTransition && mappedStartTransition !== activeStartTransition) {
        // Get the correct label from the appropriate config
        let configLabel;
        if (isScreenVideoDesktop) {
          configLabel = screenVideoDesktopStartTransitionConfigs[mappedStartTransition]?.label || 'Appear';
        } else {
          configLabel = defaultStartTransitionConfigs[mappedStartTransition]?.label || 'Appear';
        }
        
        setSwitchPlace('Enter');
        setSwitchConfig(configLabel);
        setShowSwitchHint(true);
        setHintVisibilityState('visible');
        setLastChangedTransition('end');
      }
      setPrevEndTransition(activeEndTransition);
    }
    
    // Clear any existing timeout
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
    }
    
    // Start fading out after 4.5 seconds
    if (hintVisibilityState === 'visible') {
      fadeTimeoutRef.current = setTimeout(() => {
        setHintVisibilityState('fading');
      }, 4500);
      
      // Hide completely after 5 seconds
      const hideTimeout = setTimeout(() => {
        setShowSwitchHint(false);
        setHintVisibilityState('hidden');
      }, 5000);
      
      return () => {
        clearTimeout(fadeTimeoutRef.current);
        clearTimeout(hideTimeout);
      };
    }
  }, [activeEndTransition, activeStartTransition, isScreenVideoDesktop, itemType, hintVisibilityState]);

  // Handle showing and hiding the done confirmation
  useEffect(() => {
    if (showDoneConfirmation) {
      const timer = setTimeout(() => {
        setShowDoneConfirmation(false);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [showDoneConfirmation]);

  const handleStartTransitionChange = (newTransition) => {
    const isChart = itemType=='barChart' || itemType=='donutChart'
    if(!isChart){
      handleUpdateMetadata('startTransitionType',newTransition)
    }
    else{
      handleUpdateChartAnimationType(newTransition)
    }     
    setTransitionPopoverOpen(false)
  }

  const handleEndTransitionChange = (newTransition) => {
    handleUpdateMetadata('endTransitionType',newTransition)
    setTransitionPopoverOpen(false)
  }

  const updateAnimationGrouping=(value)=>{
    handleUpdateMetadata('animationGrouping',value)
  }


  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    // Hide the hint when switching tabs
    setShowSwitchHint(false);
    setHintVisibilityState('hidden');
    // Clear any existing timeout
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
      fadeTimeoutRef.current = null;
    }
  };

  let activeStartTransitionConfig,activeEndTransitionConfig 
  let startTransitionConfigs,endTransitionConfigs

  if(itemType=='barChart'){
    activeStartTransitionConfig = barChartStartTransitionConfigs[activeStartTransition] || barChartStartTransitionConfigs.none
    startTransitionConfigs = barChartStartTransitionConfigs
  }else if(itemType=='donutChart'){
    activeStartTransitionConfig = donutChartStartTransitionConfigs[activeStartTransition] || donutChartStartTransitionConfigs.none
    startTransitionConfigs = donutChartStartTransitionConfigs
  }else if(isScreenVideoDesktop){
    // Use restricted transition configs for screen videos in desktop mode
    activeStartTransitionConfig = screenVideoDesktopStartTransitionConfigs[activeStartTransition] || screenVideoDesktopStartTransitionConfigs.none
    activeEndTransitionConfig = screenVideoDesktopEndTransitionConfigs[activeEndTransition] || screenVideoDesktopEndTransitionConfigs.none
    startTransitionConfigs = screenVideoDesktopStartTransitionConfigs
    endTransitionConfigs = screenVideoDesktopEndTransitionConfigs
  }else{
    activeStartTransitionConfig = defaultStartTransitionConfigs[activeStartTransition] || defaultStartTransitionConfigs.none
    activeEndTransitionConfig = defaultEndTransitionConfigs[activeEndTransition] || defaultEndTransitionConfigs.none
    startTransitionConfigs=defaultStartTransitionConfigs
    endTransitionConfigs=defaultEndTransitionConfigs
  }

  //if item type is chart we only show animate in options
  let tabOptions = [
    { id: 'start', label: 'Enter' },    
    { id: 'end', label: 'Exit' },
  ];
  if(itemType=='barChart' || itemType=='donutChart' ){
    tabOptions=[
      { id: 'start', label: 'Enter' }, 
    ]
  }

  const [showAnimationDetailsEnter, setShowAnimationDetailsEnter] = useState(false)


  const enterTransitionValueFactor = item.metadata.enterTransitionValueFactor || 0
  const enterTransitionSpeedFactor = item.metadata.enterTransitionSpeedFactor || 0
  const enterTransitionBouncinessFactor = item.metadata.enterTransitionBouncinessFactor || 0
  const wordEnterDelay = item.metadata.wordEnterDelay || 0.3 
  const lineEnterDelay = item.metadata.lineEnterDelay || 0.6

 const updateTransitionValues=(field,value)=>{
  handleUpdateMetadata(field,value)
}

  const animationSettingsAvailable = activeStartTransition !== 'none'
  const showAnimationScalarSetting = activeStartTransition !== 'fade'

  let animationScalarLabel = 'Distance Moved'  

  if(activeStartTransition === 'fadeAndScaleUp'){    
    animationScalarLabel = 'Initial Size'    
  }
  if(activeStartTransition === 'fadeAndScaleDown'){    
    animationScalarLabel = 'Initial Size'    
  }
  // console.log('activeStartTransition')
  // console.log(activeStartTransition)


  
  // const [enterGroupingWordDelay, setEnterGroupingWordDelay] = useState(0.3)
  // const [enterGroupingLineDelay, setEnterGroupingLineDelay] = useState(0.6)

  //console.log(`----->>>>animationGroupingType ${animationGroupingType}`)

  // Handle switch hint button click
  const handleSwitchHintClick = () => {
    // Set clicked state immediately
    setHintVisibilityState('clicked');
    
    // Update transition based on which was last changed
    if (lastChangedTransition === 'start') {
      const mappedEndTransition = transitionMappings.enterToExit[activeStartTransition];
      if (mappedEndTransition) {
        handleUpdateMetadata('endTransitionType', mappedEndTransition);
      }
    } else if (lastChangedTransition === 'end') {
      const mappedStartTransition = transitionMappings.exitToEnter[activeEndTransition];
      if (mappedStartTransition) {
        if (itemType === 'barChart' || itemType === 'donutChart') {
          handleUpdateChartAnimationType(mappedStartTransition);
        } else {
          handleUpdateMetadata('startTransitionType', mappedStartTransition);
        }
      }
    }
    
    // Hide the hint
    setShowSwitchHint(false);
    
    // Delay showing the done confirmation to give time for the hint to exit
    setTimeout(() => {
      setShowDoneConfirmation(true);
    }, 150);
  };

  return (
    <div className='dpItemAnimationPanel'>
      <div className='dpItemAnimationPanel-header'>
        <DPTabs 
          tabOptions={tabOptions}
          activeTab={activeTab}
          onTabChange={handleTabChange}          
        />
        {/* FOR CUSTOM CONFIGS 
        <button className='dpButton dpButton--iconOnly dpButton--mid'>
          <div className='dpButton-iconContainer'>
            <Icon name='sliders' />
          </div>
        </button>
        */}
      </div>

      
        {activeTab === 'start' && 
          <div className='dpItemAnimationPanel-typeBtnContainer'>
            <DPAnimationPanelTransitionPopover 
              activeTransition={activeStartTransition}
              onTransitionChange={handleStartTransitionChange}
              transitionConfigs={startTransitionConfigs}
              showGroupingOptions={itemType === 'text'}
              animationGroupingType={animationGroupingType}
              updateAnimationGrouping={updateAnimationGrouping}
              modalMode={false} // tempted to make true for text
            >
              <button 
                onClick={() => setTransitionPopoverOpen(!transitionPopoverOpen)} 
                className='dpItemAnimationPanel-typeBtn'
              >
                <div className='dpItemAnimationPanel-typeBtn-iconContainer'>
                  <Icon name={activeStartTransitionConfig.iconName} />
                </div>
                <div className='dpItemAnimationPanel-typeBtn-label'>
                  {activeStartTransitionConfig.label}
                </div>
              </button>
            </DPAnimationPanelTransitionPopover>
          
          
          {animationSettingsAvailable &&
            <button data-active-state={showAnimationDetailsEnter ? 'active' : 'inactive'} onClick={() => setShowAnimationDetailsEnter(!showAnimationDetailsEnter)} className='dpItemAnimationPanel-typeBtnSettingsToggle'>
              <div className='dpItemAnimationPanel-typeBtnSettingsToggle-inner'>
                <Icon name='sliders' />
              </div>
            </button>          
          }
          
          
            
          {animationSettingsAvailable &&
          <div data-visibility-state={showAnimationDetailsEnter ? 'true' : 'false'} className='dpItemAnimationPanel-settingsToggleList'>              
            

            {showAnimationScalarSetting &&
            <DPSlider
              min={-2}
              max={2}          
              value={enterTransitionValueFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionValueFactor',value)}}               
              percentage
              label={animationScalarLabel}
              centerZero        
              step={0.01}
              width={228}
            />
            }

            <DPSlider
              min={-1}
              max={1}          
              value={enterTransitionSpeedFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionSpeedFactor',value)}}               
              percentage
              label='Speed'
              centerZero        
              step={0.01}
              width={228}
            />
            <DPSlider
              min={-1}
              max={1}          
              value={enterTransitionBouncinessFactor}
              setValue={(value)=>{updateTransitionValues('enterTransitionBouncinessFactor',value)}}               
              percentage
              label='Bounciness'
              centerZero        
              step={0.01}
              width={228}
            />

            {animationGroupingType === 'word' &&
              <DPSlider
                min={0}
                max={2}
                value={wordEnterDelay}
                setValue={(value)=>{updateTransitionValues('wordEnterDelay',value)}}                                
                label='Word Delay'                     
                step={0.1}
                width={228}
              />
            }

            {animationGroupingType === 'line' &&
              <DPSlider
                min={0}
                max={2}
                value={lineEnterDelay}
                setValue={(value)=>{updateTransitionValues('lineEnterDelay',value)}}   
                //setValue={(value)=>{setEnterGroupingLineDelay(value)}}                               
                label='Line Delay'                     
                step={0.1}
                width={228}
              />
            }

          </div>  
          }          
          
        </div>
      }

      <div className='dpItemAnimationPanel-typeBtnContainer'>
        {activeTab === 'end' && 
          <DPAnimationPanelTransitionPopover 
            activeTransition={activeEndTransition}
            onTransitionChange={handleEndTransitionChange}
            transitionConfigs={endTransitionConfigs}          
          >
            <button 
              onClick={() => setTransitionPopoverOpen(!transitionPopoverOpen)} 
              className='dpItemAnimationPanel-typeBtn'
            >
              <div className='dpItemAnimationPanel-typeBtn-iconContainer'>
                <Icon name={activeEndTransitionConfig.iconName} />
              </div>
              <div className='dpItemAnimationPanel-typeBtn-label'>
                {activeEndTransitionConfig.label}
              </div>
            </button>
          </DPAnimationPanelTransitionPopover>
        }
      </div>

      <div className='dpItemAnimationPanel-hintOuterContainer'>
        <div 
          data-visibility-state={showSwitchHint ? hintVisibilityState : 'hidden'} 
          className='dpItemAnimationPanel-hintContainer'
        >
          <button className='dpItemAnimationPanel-hint' onClick={handleSwitchHintClick}>
            <div className='dpItemAnimationPanel-hint-iconContainer'>
              <Icon name='starGroup2' />
            </div>
            <div className='dpItemAnimationPanel-hint-label'>
              Update {switchPlace} to {switchConfig}?
            </div>
          </button>
        </div>

        <div 
          data-visibility-state={(showDoneConfirmation && !showSwitchHint)  ? 'visible' : 'hidden'} 
          className='dpItemAnimationPanel-hintContainer dpItemAnimationPanel-hintContainer--done'
        >
          <button className='dpItemAnimationPanel-hint'>
            <div className='dpItemAnimationPanel-hint-label'>
              Updated
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DPItemAnimationPanel;

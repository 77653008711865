const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;  
const SNAP_THRESHOLD = 120; // how close is canvas box to the window edge before it snaps
const MAX_WINDOW_PADDING_VERTICAL = 60; // padding outside the window to show in the snapped zoom
const MAX_WINDOW_PADDING_HORIZONTAL = 108;
const MAX_ZOOM_LEVEL = 1.5; // max level of zoom, i.e. 2x means polished zoom box is 1920/2
const MIN_POLISHED_WIDTH = SCENE_WIDTH / MAX_ZOOM_LEVEL;
const MIN_POLISHED_HEIGHT = SCENE_HEIGHT / MAX_ZOOM_LEVEL;

export function calculatePolishedZoomBox(canvasBox, windowDimensions) {
  const windowLeft = (SCENE_WIDTH - windowDimensions.meshWidth) / 2;
  const windowRight = windowLeft + windowDimensions.meshWidth;
  const windowTop = (SCENE_HEIGHT - windowDimensions.meshHeight) / 2;
  const windowBottom = windowTop + windowDimensions.meshHeight;

  // Distance from canvas edge (0,0) to window edges
  const windowLeftDistanceToCanvas = windowLeft;
  const windowTopDistanceToCanvas = windowTop;
  const windowRightDistanceToCanvas = SCENE_WIDTH - windowRight;
  const windowBottomDistanceToCanvas = SCENE_HEIGHT - windowBottom;

  // These are for determining if we should snap
  const leftDiff = Math.abs(canvasBox.x1 - windowLeft);
  const rightDiff = Math.abs(canvasBox.x2 - windowRight);
  const topDiff = Math.abs(canvasBox.y1 - windowTop);
  const bottomDiff = Math.abs(canvasBox.y2 - windowBottom);

  const snapsLeft = leftDiff < SNAP_THRESHOLD;
  const snapsRight = rightDiff < SNAP_THRESHOLD;
  const snapsTop = topDiff < SNAP_THRESHOLD;
  const snapsBottom = bottomDiff < SNAP_THRESHOLD;

  // console.log('Window bounds:', {
  //   left: windowLeft,
  //   right: windowRight,
  //   top: windowTop,
  //   bottom: windowBottom
  // });
  // console.log('Canvas box:', canvasBox);
  
  // console.log('Edge distances:', {
  //   leftDiff,
  //   rightDiff,
  //   topDiff,
  //   bottomDiff,
  // });
  // console.log('Edge snapping:', {
  //   left: snapsLeft ? 'SNAP' : 'no snap',
  //   right: snapsRight ? 'SNAP' : 'no snap',
  //   top: snapsTop ? 'SNAP' : 'no snap',
  //   bottom: snapsBottom ? 'SNAP' : 'no snap'
  // });

  let finalBox = {...canvasBox};

  if (snapsLeft && snapsTop && snapsRight) {    
    // Snapping to top and both sides
    const leftPadding = Math.min(windowLeftDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    // Box is fixed to left, right and top
    finalBox.x1 = windowLeft - leftPadding;
    finalBox.x2 = windowRight + rightPadding;
    finalBox.y1 = windowTop - topPadding;

    // Height is determined by width to maintain 16:9
    const width = finalBox.x2 - finalBox.x1;
    const height = width / (16/9);
    finalBox.y2 = finalBox.y1 + height;
  }
  else if (snapsLeft && snapsBottom && snapsRight) {    
    // Snapping to bottom and both sides
    const leftPadding = Math.min(windowLeftDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    // Box is fixed to left, right and bottom
    finalBox.x1 = windowLeft - leftPadding;
    finalBox.x2 = windowRight + rightPadding;
    finalBox.y2 = windowBottom + bottomPadding;

    // Height is determined by width to maintain 16:9
    const width = finalBox.x2 - finalBox.x1;
    const height = width / (16/9);
    finalBox.y1 = finalBox.y2 - height;
  }
  else if (snapsTop && snapsRight && snapsBottom) {    
    // Snapping to right and both top/bottom
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    // Box is fixed to right, top and bottom
    finalBox.x2 = windowRight + rightPadding;
    finalBox.y1 = windowTop - topPadding;
    finalBox.y2 = windowBottom + bottomPadding;

    // Width is determined by height to maintain 16:9
    const height = finalBox.y2 - finalBox.y1;
    const width = height * (16/9);
    finalBox.x1 = finalBox.x2 - width;
  }
  else if (snapsTop && snapsRight && snapsBottom) {    
    // Snapping to right and both top/bottom
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    // First fix the vertical extent
    finalBox.y1 = windowTop - topPadding;
    finalBox.y2 = windowBottom + bottomPadding;

    // Fix to right edge
    finalBox.x2 = windowRight + rightPadding;

    // Calculate width based on height while maintaining 16:9
    const height = finalBox.y2 - finalBox.y1;
    const width = height * (16/9);
    
    // Position x1 based on x2 and width
    finalBox.x1 = finalBox.x2 - width;
  }
  else if (snapsLeft && snapsTop) {
    const leftPadding = Math.min(windowLeftDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);
    
    finalBox.x1 = windowLeft - leftPadding;
    finalBox.y1 = windowTop - topPadding;
    
    const width = Math.max(
      canvasBox.x2 - (windowLeft - leftPadding),
      (canvasBox.y2 - (windowTop - topPadding)) * (16/9)
    );
    const height = width / (16/9);
    
    finalBox.x2 = finalBox.x1 + width;
    finalBox.y2 = finalBox.y1 + height;
  } 
  else if (snapsRight && snapsTop) {
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    finalBox.x2 = windowRight + rightPadding;
    finalBox.y1 = windowTop - topPadding;
    
    const width = Math.max(
      (windowRight + rightPadding) - canvasBox.x1,
      (canvasBox.y2 - (windowTop - topPadding)) * (16/9)
    );
    const height = width / (16/9);
    
    finalBox.x1 = finalBox.x2 - width;
    finalBox.y2 = finalBox.y1 + height;
  }
  else if (snapsLeft && snapsBottom) {
    const leftPadding = Math.min(windowLeftDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    finalBox.x1 = windowLeft - leftPadding;
    finalBox.y2 = windowBottom + bottomPadding;
    
    const width = Math.max(
      canvasBox.x2 - (windowLeft - leftPadding),
      ((windowBottom + bottomPadding) - canvasBox.y1) * (16/9)
    );
    const height = width / (16/9);
    
    finalBox.x2 = finalBox.x1 + width;
    finalBox.y1 = finalBox.y2 - height;
  }
  else if (snapsRight && snapsBottom) {
    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);

    finalBox.x2 = windowRight + rightPadding;
    finalBox.y2 = windowBottom + bottomPadding;
    
    const width = Math.max(
      (windowRight + rightPadding) - canvasBox.x1,
      ((windowBottom + bottomPadding) - canvasBox.y1) * (16/9)
    );
    const height = width / (16/9);
    
    finalBox.x1 = finalBox.x2 - width;
    finalBox.y1 = finalBox.y2 - height;
  }
  else if (snapsTop) {
    const topPadding = Math.min(windowTopDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);
    finalBox.y1 = windowTop - topPadding;
    
    const height = canvasBox.y2 - (windowTop - topPadding);
    const width = height * (16/9);
    
    const center = (canvasBox.x1 + canvasBox.x2) / 2;
    finalBox.x1 = center - (width / 2);
    finalBox.x2 = center + (width / 2);
    finalBox.y2 = finalBox.y1 + height;
  }
  else if (snapsBottom) {
    const bottomPadding = Math.min(windowBottomDistanceToCanvas, MAX_WINDOW_PADDING_VERTICAL);
    finalBox.y2 = windowBottom + bottomPadding;
    
    const height = (windowBottom + bottomPadding) - canvasBox.y1;
    const width = height * (16/9);
    
    const center = (canvasBox.x1 + canvasBox.x2) / 2;
    finalBox.x1 = center - (width / 2);
    finalBox.x2 = center + (width / 2);
    finalBox.y1 = finalBox.y2 - height;
  }
  else if (snapsLeft) {
    const leftPadding = Math.min(windowLeftDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    finalBox.x1 = windowLeft - leftPadding;
    
    const width = canvasBox.x2 - (windowLeft - leftPadding);
    const height = width / (16/9);
    
    const center = (canvasBox.y1 + canvasBox.y2) / 2;
    finalBox.y1 = center - (height / 2);
    finalBox.y2 = center + (height / 2);
    finalBox.x2 = finalBox.x1 + width;
  }
  else if (snapsRight) {

    const rightPadding = Math.min(windowRightDistanceToCanvas, MAX_WINDOW_PADDING_HORIZONTAL);
    finalBox.x2 = windowRight + rightPadding;
    
    const width = (windowRight + rightPadding) - canvasBox.x1;
    const height = width / (16/9);
    
    const center = (canvasBox.y1 + canvasBox.y2) / 2;
    finalBox.y1 = center - (height / 2);
    finalBox.y2 = center + (height / 2);
    finalBox.x1 = finalBox.x2 - width;
  }

  // no snapping
  if (!snapsLeft && !snapsRight && !snapsTop && !snapsBottom) {
    // First make sure we get the minimum dimensions while maintaining 16:9
    const minWidth = MIN_POLISHED_WIDTH;
    const minHeight = MIN_POLISHED_HEIGHT;
    
    // Get the canvas box dimensions
    const width = canvasBox.x2 - canvasBox.x1;
    const height = canvasBox.y2 - canvasBox.y1;
    
    // Calculate 16:9 dimensions that will cover both the canvas box AND minimum dimensions
    let targetWidth, targetHeight;
    if (width / height > 16/9) {
      // Box is wider than 16:9
      targetWidth = Math.max(width, minWidth);
      targetHeight = targetWidth / (16/9);
    } else {
      // Box is taller than 16:9
      targetHeight = Math.max(height, minHeight);
      targetWidth = targetHeight * (16/9);
    }

    // Make sure we meet both minimum dimensions
    if (targetWidth < minWidth) {
      targetWidth = minWidth;
      targetHeight = minWidth / (16/9);
    }
    if (targetHeight < minHeight) {
      targetHeight = minHeight;
      targetWidth = minHeight * (16/a9);
    }

    // Center this box on the canvas box
    const centerX = (canvasBox.x1 + canvasBox.x2) / 2;
    const centerY = (canvasBox.y1 + canvasBox.y2) / 2;

    finalBox = {
      x1: centerX - (targetWidth / 2),
      x2: centerX + (targetWidth / 2),
      y1: centerY - (targetHeight / 2),
      y2: centerY + (targetHeight / 2)
    };

    // Now constrain to window + padding bounds
    const minX = windowLeft - MAX_WINDOW_PADDING_HORIZONTAL;
    const maxX = windowRight + MAX_WINDOW_PADDING_HORIZONTAL;
    const minY = windowTop - MAX_WINDOW_PADDING_VERTICAL;
    const maxY = windowBottom + MAX_WINDOW_PADDING_VERTICAL;

    // If box goes outside bounds, shift it back while maintaining size
    if (finalBox.x1 < minX) {
      const shift = minX - finalBox.x1;
      finalBox.x1 += shift;
      finalBox.x2 += shift;
    }
    if (finalBox.x2 > maxX) {
      const shift = finalBox.x2 - maxX;
      finalBox.x1 -= shift;
      finalBox.x2 -= shift;
    }
    if (finalBox.y1 < minY) {
      const shift = minY - finalBox.y1;
      finalBox.y1 += shift;
      finalBox.y2 += shift;
    }
    if (finalBox.y2 > maxY) {
      const shift = finalBox.y2 - maxY;
      finalBox.y1 -= shift;
      finalBox.y2 -= shift;
    }
  }


  // After snapping, ensure minimum dimensions while maintaining snap points
  const width = finalBox.x2 - finalBox.x1;
  const height = finalBox.y2 - finalBox.y1;

  if (width < MIN_POLISHED_WIDTH || height < MIN_POLISHED_HEIGHT) {
    if (width < MIN_POLISHED_WIDTH) {
      if (snapsLeft) {
        finalBox.x2 = finalBox.x1 + MIN_POLISHED_WIDTH;
      } else if (snapsRight) {
        finalBox.x1 = finalBox.x2 - MIN_POLISHED_WIDTH;
      } else {
        const center = (finalBox.x1 + finalBox.x2) / 2;
        finalBox.x1 = center - (MIN_POLISHED_WIDTH / 2);
        finalBox.x2 = center + (MIN_POLISHED_WIDTH / 2);
      }
    }

    if (height < MIN_POLISHED_HEIGHT) {
      if (snapsTop) {
        finalBox.y2 = finalBox.y1 + MIN_POLISHED_HEIGHT;
      } else if (snapsBottom) {
        finalBox.y1 = finalBox.y2 - MIN_POLISHED_HEIGHT;
      } else {
        const center = (finalBox.y1 + finalBox.y2) / 2;
        finalBox.y1 = center - (MIN_POLISHED_HEIGHT / 2);
        finalBox.y2 = center + (MIN_POLISHED_HEIGHT / 2);
      }
    }
  }

  console.log('Final box:', finalBox);
  return finalBox;
}
import { getUserForId } from "./getUserForId";
import { getCurrentUserId } from "./getCurrentUserId";

const LOCK_TIMEOUT_MINUTES = 10;

export function checkUserAccessLock(project) {
    if (!project) {
        return true;
    }
    if (!project?.project_access_user?.current_user_id) {
        return false;
    }

    const currentTime = new Date().getTime();
    const lastUpdateTime = new Date(project?.updated_at).getTime();
    const lastOpenedTime = new Date(project?.project_access_user?.last_opened).getTime();
    
    // Check both update time and last opened time
    const minutesSinceUpdate = (currentTime - lastUpdateTime) / (1000 * 60);
    const minutesSinceLastOpened = (currentTime - lastOpenedTime) / (1000 * 60);
    
    const currentUserId = getCurrentUserId()
    if(!currentUserId){
        return false
    }

    const isLockedByOtherUser = parseInt(project?.project_access_user?.current_user_id) !== currentUserId;
    const isWithinLockPeriod = minutesSinceUpdate < LOCK_TIMEOUT_MINUTES || 
                              minutesSinceLastOpened < LOCK_TIMEOUT_MINUTES;

    return isLockedByOtherUser && isWithinLockPeriod;
}

export function getLockedUserNameAndAvatar(id) {
    const lockedUser = getUserForId(id)
    return {
        lockedUserFirstName: lockedUser?.first_name,
        lockedAvatarUrl: lockedUser?.profile_image_url
    }
}
// for clip A, if gap to next clip is greater than 1 seconds, add zoom out to clip A end
// if gap to next clip is less than 1 seconds, add zoom switch to clip A t end
// if gap less than 1 seconds to clip before, don't do sound at start, otherwise do zoom start at front

const SWITCH_TIME = 1;

export function calculateZoomEffects(clips) {
  // Sort clips by startTime
  const sortedClips = clips.sort((a, b) => a.startTime - b.startTime);


  const effects = [];
  for (let i = 0; i < sortedClips.length; i++) {
    const currentClip = sortedClips[i];
    const previousClip = sortedClips[i - 1];
    const nextClip = sortedClips[i + 1];

    // Only add zoom in if there's no previous clip or gap is >= SWITCH_TIME
    if (!previousClip || (currentClip.startTime - previousClip.endTime) >= SWITCH_TIME) {
      effects.push({
        effectType: 'zoom',
        action: 'in',
        startTime: currentClip.startTime,
        sceneId: currentClip.sceneId
      });
    }

    // If there's a next clip and the gap is less than SWITCH_TIME seconds,
    // use 'switch' instead of 'out'
    const action = nextClip && (nextClip.startTime - currentClip.endTime) < SWITCH_TIME 
      ? 'switch' 
      : 'out';

    // Add zoom out/switch effect at end of clip
    effects.push({
      effectType: 'zoom',
      action: action,
      startTime: currentClip.endTime,
      sceneId: currentClip.sceneId
    });
  }

  return effects;
}
import React, {useState} from 'react'
import Icon from '../../misc/Icon'

function LibraryProjectItemLockedBanner({isLocked, lockedUserFirstName, lockedAvatarUrl, isEditorToggle}) {
	const containerClass = isEditorToggle 
		? 'libraryPage-project-previewUI-lockedBannerContainer editor-locked-banner'
		: 'libraryPage-project-previewUI-lockedBannerContainer';

	return (		
		
		
		<div className={containerClass}>
			<div className='libraryPage-project-previewUI-lockedBanner'>
							
					{lockedAvatarUrl && 
						<div className='libraryPage-project-previewUI-lockedBanner-avatar'>
						 <img referrerPolicy="no-referrer" src={lockedAvatarUrl} />
						</div>				
					}

					{!lockedAvatarUrl && 
						<div className='libraryPage-project-previewUI-lockedBanner-avatar'>
						 <Icon name='userCircle' />
						</div>				
					}
				
					{lockedUserFirstName && 
						<div className='libraryPage-project-previewUI-lockedBanner-label'>
							<span className='libraryPage-project-previewUI-lockedBanner-name'>{lockedUserFirstName}</span> is editing
						</div>
					}

					{!lockedUserFirstName && 
						<div className='libraryPage-project-previewUI-lockedBanner-label'>
							<span className='libraryPage-project-previewUI-lockedBanner-name'>Someone</span> is editing
						</div>
					}

			</div>      				
		</div>			

		
	);
}

export default LibraryProjectItemLockedBanner;

import {TimelineScene} from './TimelineScene'
import {saveProjectTimeline} from '../utils/projectUtils/saveProjectTimeline'
import debounce from 'lodash/debounce'
import {randomID} from '../utils/randomID'
import {getBackgroundMusicTrackForId} from '../utils/backgroundMusic/getBackgroundMusicTrackForId'
import {calculateTimelineTimeFromVideoTime} from './utils/calculateTimelineTimeFromVideoTime'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import { getFontForTextStyle } from '../utils/brands/getFontForTextStyle'
import {fadeVolumeTowardsEnd} from './utils/fadeVolumeTowardsEnd'

import {createProjectSettingsPmNode} from '../prosemirror/timelineProsemirror/nodeCreators'

import {AudioClip} from './AudioClip'
import {VideoClip} from './VideoClip'
import {TextSlideClip} from './TextSlideClip'
import {WebcamClip} from './WebcamClip'
import {findActiveSegmentForVideoClip} from './utils/findActiveSegmentForVideoClip'

import {createVideoClipObjFromCaptureId} from './clipObjCreators/createVideoClipObjFromCaptureId'
import {createWebcamClipObjFromCaptureId} from './clipObjCreators/createWebcamClipObjFromCaptureId'
import {estimateAudioDuration} from '../utils/estimateAudioDuration'
import {convertSlideNodeToJSON} from './utils/convertSlideNodeToJSON'
import {getVoiceForId} from '../utils/voiceover/getVoiceForId'
import {webcamClipDefaultMetadata} from '../utils/webcam/webcamConfigs'
import { getFaceBoxForWebcamCaptureId } from '../utils/webcam/getFaceBoxForWebcamCaptureId'
import { updateCursorOpacityForClip } from '../utils/recordings/screenRecordings/getCursorPositionAtTime'
import { CursorOpacityManager } from '../utils/recordings/screenRecordings/getCursorOpacity'

import {checkWebcamUploadStatusForCaptureId} from './utils/checkWebcamUploadStatusForCaptureId'

//Sound effects
import { SoundEffectManager } from './SoundEffectsManager';
import { SOUND_EFFECTS_CONFIG } from '../utils/soundEffects/soundEffectsConfig';
import {getDefaultSoundEffectsSettings} from '../utils/soundEffects/soundEffectsConfig'
import { computeCursorDataForClips } from '../utils/recordings/screenRecordings/getCursorPositionAtTime'

import { calculateScreenRecordingDisplaySettings } from '../utils/recordings/screenRecordings/calculateScreenRecordingDisplaySettings'

import {getDefaultVideoWindowPadding} from './utils/getDefaultVideoWindowPadding'

const TICK_LENGTH=20
const SYNC_INTERVAL = 200 //was 500 might want to play with this
const BACKGROUND_TRACK_VOLUME=0.08
const INFINITE_TIMELINE = true
const MIN_SPLIT_CLIP_DURATION=0.5 //if split clip is less than this then delete it

function makeSceneTemplate(){
  return{
    id: randomID(), 
    sceneIndex:0,
    startTime:0,
    duration:5,
    clips: []
  }
}


/**
 * Scenes have an index
 * scene start time is calculated based on scenes before it when do calculateDuration
 * We save the clip start time relative to the scene
 * but for use in the app start time is a calculated field and absolute time on timeline
 * most operations go through the scene which handles things like resolving clip conflicts and stuff
 * 
 * 
 * Voice Match Logic
 * when the webcam are added/removed we make a voice match based on the webcams in the project
 * we update active voice/provider id and voiceMatchCaptureIds
 * dont apply voice match to webcams if only webcams and no tts
 * if there is tts then use the voice match for both tts and apply it to webcams with speech to speech (update the audio transformation webcam clip will load the correct audio track)
 * 	metadata.audioTransformation={type:voiceMatch,voiceId:voiceId}
 * if delete tts then revert to the default webcam audio (metadata.audioTransformation={type:'normalized'})
 * */


//aspectRatio options 16_9 and 16_10
const DEFAULT_ASPECT_RATIO = '16_9'
const DEFAULT_VOICE_ID='1'
const AUDIO_CLIP_SPACING=0.15 //gap between clips


///For these legacy capture we dont want to trigger a voice match
const LEGACY_WEBCAM_CAPTURES=['2070071889']

class Timeline {
	
	constructor(projectId,timelineData,onTimeUpdate,handlePlaybackEnded,handleClipMediaLoaded,setPMDocForDevMode,projectBackgroundId,handleUpdateProjectBackground,pmManager,transcriptPmManager,handleVoiceoverAudioFileUpdated,setPreviewingAudioClipId,handleTextElementFontLoaded,handleCreateVoiceMatchForProject) {
		this._projectBackground = null
		this._currentTime = 0;
		this._isPlaying = false;
		this._playbackInterval = null;
		this._syncInterval = null
		this._onTimeUpdate = onTimeUpdate
		this._handlePlaybackEnded = handlePlaybackEnded
		this.handleClipMediaLoaded=handleClipMediaLoaded
		this.handleVoiceoverAudioFileUpdated=handleVoiceoverAudioFileUpdated
		this._projectId = projectId

		this._activeVoice = timelineData.activeVoice || DEFAULT_VOICE_ID
		
		let providerId = timelineData.providerId
		if(!providerId){ //for legacy support for projects that dont have a saved providerId
			providerId=this._activeVoice
			const voiceObj = getVoiceForId(this._activeVoice)
			if(voiceObj){
				providerId = voiceObj.providerId
			}
		}
		this._providerId = providerId

		this._backgroundMusicTrack = timelineData.backgroundMusicTrack || null
		this.backgroundMusicVolume=timelineData.backgroundMusicVolume|| BACKGROUND_TRACK_VOLUME
		this.voiceoverPlaybackRate=timelineData.voiceoverPlaybackRate || 1 
		this.showCaptions = timelineData.showCaptions || false

		this.hideInactiveCursor = (timelineData.hideInactiveCursor === undefined || timelineData.hideInactiveCursor === null) ? true : timelineData.hideInactiveCursor    
	 	this.soundEffectsSettings = timelineData.soundEffectsSettings || getDefaultSoundEffectsSettings()
		
		this.voiceMatch = timelineData.voiceMatch
		//if we want to use a different voice instead of the voice match voice then set this to true
		this.overrideVoiceMatch = timelineData.overrideVoiceMatch || false
  
		this.setPMDocForDevMode=setPMDocForDevMode
		this.setPreviewingAudioClipId=setPreviewingAudioClipId
		this.projectBackgroundId = projectBackgroundId
		this.handleUpdateProjectBackgroundOnUndo=handleUpdateProjectBackground
		
		this.debouncedSaveProjectTimeline = debounce(this.handleSaveProjectTimeline, 1000);
		this.maxTimelineDurationSeconds = 120
		this.backgroundMusicElement=null
		if(this._backgroundMusicTrack){
			this.initBackgroundMusic();
		}

		this.currentVideoSegments = null

		this._scenes = [];
		this.isDnDMode = false //command 
		this.isDragPullMode = false //command shift
		this.isAltDragMode=false //command alt- drags the track
		this.isDragging =false 
		this.dragClipId = null

		this.dragClipZIndex = null
		this.pmManager = pmManager
		this.transcriptPmManager=transcriptPmManager 
		this.isExport = false
		this.handleTextElementFontLoaded=handleTextElementFontLoaded
		this.handleCreateVoiceMatchForProject=handleCreateVoiceMatchForProject
		this.variables=[]
		this.aspectRatio = timelineData.aspectRatio || DEFAULT_ASPECT_RATIO
		this.pendingVoiceMatchSourceIds = null
 
		this.soundEffectManager = new SoundEffectManager(this.soundEffectsSettings);
		this.debouncedCalculateSoundEffects = debounce(() => {
			if (this.soundEffectManager) {
				this.soundEffectManager.calculateSoundEffects(this);
				this.debouncedSaveProjectTimeline()
			}
		}, 200);

		CursorOpacityManager.setHideInactiveCursor(this.hideInactiveCursor)
		this.initSoundEffects()
		this._videoWindowPadding = (timelineData && timelineData.videoWindowPadding === undefined || timelineData.videoWindowPadding === null) ? getDefaultVideoWindowPadding() : timelineData.videoWindowPadding

	}

////Webflow demo

applyBulletPointsToSlide=(slideId,bullets)=>{
	console.log('in timeline----applyBulletPointsToSlide',slideId,bullets)
	const slideClip = this.findClipForId(slideId)
	if(slideClip){
		const slideElements = slideClip.elements
		const bulletsIncremental = []
    let prevStart = 0
    bullets.forEach(bullet => {
      const incremental = bullet.start_time - prevStart
      bulletsIncremental.push({...bullet, delay: incremental})
      prevStart = bullet.start_time
    })
    const numBullets = bulletsIncremental.length
    const names = Array.from({length: numBullets}, (_, i) => `Label ${i+1}`);
    const textElements = slideClip.layout?.children.filter(child => child.type === 'text')
    for (let i=0;i<numBullets;i++){
      const name = names[i]
      const textElement = slideElements.find(element => element.metadata?.text === name)
      if (textElement) {
       // textElement.metadata.enterDelay = bulletsIncremental[i].delay
				const textToUse = bulletsIncremental[i].text
			textElement.metadata.text = textToUse;
			const docJson = JSON.parse(textElement.metadata.docJson);
			const replaceTextInNode = (node) => {
				if (node.type === 'text') {
					return { ...node, text: textToUse };
				} else if (node.content) {
					return { ...node, content: node.content.map(replaceTextInNode) };
				}
				return node;
			};
			const newDocJson = { ...docJson, content: docJson.content.map(replaceTextInNode) };
			textElement.metadata.docJson = JSON.stringify(newDocJson);
			textElement.metadata.enterDelay = bulletsIncremental[i].delay
      }
		}
	}
}




	async initSoundEffects() {
		this.soundEffectManager.initialize();
	}

	updateSoundEffectsSettings(soundEffectsSettings){
		this.soundEffectsSettings = soundEffectsSettings
		this.soundEffectManager.updateSettings(soundEffectsSettings)
		this.debouncedCalculateSoundEffects()

	}


	forceRecalculateCursorData(clip){
		const is16_10 = this.aspectRatio === '16_10'
		const sceneWidth = 1920
		const sceneHeight = is16_10 ? 1241 : 1080
		const forceRecalculate = true
		computeCursorDataForClips([clip],forceRecalculate,sceneWidth,sceneHeight)
	}

	//exclude legacy captures from triggering voice match (clay onboarding video)
	getUniqueNonVariableWebcamCaptureIds() {
	  const uniqueCaptureIds = new Set(
	    this.clips
	      .filter(clip => clip.type === 'webcam' && !clip.metadata.isVariable && !clip.isUploadingVideo && !LEGACY_WEBCAM_CAPTURES.includes(clip.captureId))
	      .map(clip => clip.captureId)
	  );
	  return Array.from(uniqueCaptureIds);
	}


	//Check if there are different clips for voice matching
	checkForVoiceMatchSourcesChange() {
		const newIds = this.getUniqueNonVariableWebcamCaptureIds();
		const existingVoiceMatchSources = this.pendingVoiceMatchSourceIds || this.voiceMatch?.sources || [];
		const existingSourceIds = existingVoiceMatchSources
		.filter(source => source.sourceType === 'webcam')
		.map(source => source.captureId);
		// Compare voiceMatch source IDs with new IDs
		const oldIdsSorted = existingSourceIds.sort().join(',');
		const newIdsSorted = newIds.sort().join(',');
		if (oldIdsSorted !== newIdsSorted) {
			this.handleUniqueWebcamIdsChanged(newIds, existingSourceIds);
		}else{
	  	this.maybeApplyVoiceMatch()
	  }
	}

	handleUniqueWebcamIdsChanged=(newIds)=>{
		if(this.handleCreateVoiceMatchForProject){
			this.handleCreateVoiceMatchForProject(newIds)
			const pendingSources=[]
			newIds.forEach((id)=>{
				pendingSources.push({
					sourceType:'webcam',
					captureId:id
				})	
			})
			this.pendingVoiceMatchSourceIds = pendingSources
			if(!newIds.length){
				if(this.voiceMatch){
					this.voiceMatch=null //voice will get deleted on the backend
					this.updateActiveVoice(DEFAULT_VOICE_ID) //todo maybe we save non voice matched voice so it goes bacj to that
				}
			}
		}
	}

	applyVoiceMatchResult(result){		
		this.voiceMatch={
			sources:result.sources,
			voiceId:result.voiceId,
		}
	//	console.log('this.voiceMatch is',this.voiceMatch)
		this.pendingVoiceMatchSourceIds = null
		const isVoiceMatchResult = true
		this.updateActiveVoice(result.voiceId,isVoiceMatchResult)
		this.maybeApplyVoiceMatch()		
		this.debouncedSaveProjectTimeline()
	}

///TODO make sure apply this to the right project/timeline can fuck up if you switch projects while generating
	maybeApplyVoiceMatch() {
		if (!this.voiceMatch) return;
		if (this.overrideVoiceMatch) { //if you have overriden wby selecting a voice then save it but dont apply it
			this.voiceMatch = {...this.voiceMatch, isActive: false};
			return;
		}
		//only want to apply the voice match to the webcams if there is tts in the project otherwise 
		const hasAudioClips = this.clips.some(clip => clip.type === 'audio');
		const shouldUseVoiceMatch = hasAudioClips;	
		this.voiceMatch = {...this.voiceMatch, isActive: shouldUseVoiceMatch};
		this.clips.forEach(clip => {
			if (clip.type !== 'webcam') return;
			clip.metadata.audioTransformation = shouldUseVoiceMatch 
				? {type: 'voiceMatch', voiceId: this.voiceMatch.voiceId}
				: {type: 'normalized'};
				clip.handleUpdateAudioTrack();
		});
		this.debouncedSaveProjectTimeline();
	}

	updateActiveVoice(activeVoice,isVoiceMatchResult){
		if(isVoiceMatchResult && this.overrideVoiceMatch){
			return
		}
		this._activeVoice= activeVoice
		const voiceObj = getVoiceForId(this._activeVoice)
		let providerId=this._activeVoice
		if(voiceObj){
			providerId = voiceObj.providerId
		}
		this._providerId = providerId
		//if you are setting the active voice to the voicematch result show it as active
		if(!isVoiceMatchResult && this.voiceMatch){ // if you have a voice match and this is not the voice match voice then overrisde
			this.overrideVoiceMatch = true
		}else{
			//console.log('dont override it')
		}

		let applyToWebcams = true 
		let audioTransformation = {type:'voiceMatch',voiceId:providerId}

		if(this.voiceMatch && this.voiceMatch.voiceId == providerId){
			this.voiceMatch={...this.voiceMatch,isActive:true} //set it to true for the panel
			this.overrideVoiceMatch = false
			//if settingit back to the voice match result hen only applyu it if there are audio clips otherwidse use the 
			if(!isVoiceMatchResult){ //setting it back to the voice match
				const hasAudioClips = this.clips.some(clip => clip.type === 'audio');
				const shouldUseVoiceMatch = hasAudioClips;
				if(!shouldUseVoiceMatch){
					audioTransformation = {type:'normalized'}
					this.voiceMatch={...this.voiceMatch,isActive:false} 
				}

			}
		}
		this.clips.forEach(clip => {
				if(clip instanceof AudioClip) {
					clip.changeActiveVoice(activeVoice,providerId)
				}
				if(clip.type=='webcam' && !isVoiceMatchResult){ //the voice match results get applied elsewhere so only do this for manual override
					clip.metadata.audioTransformation=audioTransformation
					clip.handleUpdateAudioTrack()
				}
			})

			this._scenes.forEach((scene)=>{
				scene.changeActiveVoice(activeVoice,providerId)
			})
		this.debouncedSaveProjectTimeline()
	}





	//Some utils
	findClipForId(clipId) {
		for (const scene of this._scenes) {
			for (const clip of scene.clips) {
				if (clip.id === clipId) {
					return clip;
				}
			}
		}
		return null;
	}

	findSceneForId(sceneId) {
		for (const scene of this._scenes) {
			if (scene.id == sceneId) {
				return scene;
			}	
		}
		return null;
	}

	findLastScene(){
		this._scenes.sort((a, b) => a.startTime - b.startTime)
		return this._scenes[this._scenes.length-1]
	}

	findSceneForCurrentTime() {
		for (const scene of this._scenes) {
			if (this._currentTime >= scene.startTime && this._currentTime < scene.endTime) {
				return scene;
			}
		}
		if(this._currentTime == this.duration){
			return this._scenes[this._scenes.length-1]
		}
		return null;
	}

	findSceneForTime(time) {
		for (const scene of this._scenes) {
			if (time >= scene.startTime && time <= scene.endTime) {
				return scene;
			}
		}
		if(time >= this.duration){
			return this._scenes[this._scenes.length-1]
		}
		return null;
	}

	toggleShowCaptions=()=>{
		this.showCaptions=!this.showCaptions
	}

	toggleHideInactiveCursor=()=>{
		this.hideInactiveCursor=!this.hideInactiveCursor
		CursorOpacityManager.setHideInactiveCursor(this.hideInactiveCursor)
		const clips = this.clips.filter(clip=>clip.type=='video')
		clips.forEach(clip=>{
			updateCursorOpacityForClip(clip)
		})
		this.debouncedSaveProjectTimeline()
	}

	setVideoWindowPadding(videoWindowPadding){
		this._videoWindowPadding = videoWindowPadding
		this.debouncedSaveProjectTimeline()
	}

	async initScenes(scenes){//This happens on load timeline
		scenes.forEach((scene)=>{
			this.addScene(scene, true)
		})
		this.calculateDuration()
		this.calculateUniqueVariables()
		this.debouncedCalculateSoundEffects()
		this.checkForVoiceMatchSourcesChange()
	}

	moveClipBetweenScenesOnSplit=(clip,newSceneId,startTimeOffset,updatesArray)=>{
		let originalScene = clip.scene
		let newScene = this.findSceneForId(newSceneId)
	//	console.log('moveClipBetweenScenesOnSplit',clip,newSceneId,startTimeOffset)
		clip.scene = newScene
		clip.startTime -= startTimeOffset
	//	console.log('start time offset---------',startTimeOffset)
		clip.pinnedStartTime-=startTimeOffset
		newScene.moveClipIntoScene(clip)
		originalScene.moveClipOutOfScene(clip.id)
		updatesArray.push({clipId:clip.id,relativeStartTime:clip.relativeStartTime,sceneId:newSceneId})
	}

	

mergeScene=(sceneId,mergeDirection)=>{ //this merges the scene after sceneId with sceneId unless mergeDirection is "before" when it merges this scene with the one before it
    const actionScene = this.findSceneForId(sceneId)
    const sceneIndex = actionScene.sceneIndex 
    let scene
    if(mergeDirection=='before' && sceneIndex==0 && this.scenes.length==1){
        scene=actionScene
        scene.title='Default title'
        let updatesArray=[]
        updatesArray.push({
            type:'updateTitle',
            sceneId:sceneId,
            title:'Default title'
        })
        this.pmManager.updateMultipleClipFields(updatesArray)
        this.pmManager.endAction()
        this.updateTranscriptFromTimeline()
    }else{
        let sceneToMerge 
        if(mergeDirection=='before'){
            scene = this.scenes[sceneIndex-1]
            sceneToMerge = actionScene
        }else{
            scene = actionScene
            sceneToMerge = this.scenes[sceneIndex+1]
        }

        if(sceneToMerge){
            this.pmManager.startAction('mergeScene')
            let updatesArray=[]
            
            // Get all audio clips from both scenes and sort them by their current positions
            const allAudioClips = [
                ...scene.clips.filter(clip => (clip.type=='audio' || clip.type=='webcam')),
                ...sceneToMerge.clips.filter(clip => (clip.type=='audio'|| clip.type=='webcam'))
            ].sort((a, b) => {
                // Sort by absolute timeline position
                return (a.scene.startTime + a.relativeStartTime) - (b.scene.startTime + b.relativeStartTime);
            });

            // Get all non-audio clips that need to be moved
            const nonAudioClips = sceneToMerge.clips.filter(clip => !(clip instanceof AudioClip));
            
            // Move all clips from sceneToMerge to scene
            [...nonAudioClips, ...sceneToMerge.clips.filter(clip => (clip instanceof AudioClip))].forEach(clip => {
                clip.scene = scene;
                clip.relativeStartTime += scene.duration;
                clip.relativePinnedStartTime+=scene.duration
                scene.moveClipIntoScene(clip);
                sceneToMerge.moveClipOutOfScene(clip.id);
                updatesArray.push({
                    clipId: clip.id,
                    relativeStartTime: clip.relativeStartTime,
                    relativePinnedStartTime:clip.relativePinnedStartTime,
                    sceneId: clip.scene.id
                });
            });

            // Reassign clipIndex values to maintain proper order
            allAudioClips.forEach((clip, index) => {
                clip.clipIndex = index;
                updatesArray.push({
                    clipId: clip.id,
                    clipIndex: index
                });
            });


            this.pmManager.updateMultipleClipFields(updatesArray)
            this.deleteScene(sceneToMerge.id)
            this.pmManager.endAction()
            this.updateTranscriptFromTimeline()
            this.calculateAudioTrackSpacing()
            this.recalculateSceneDurations()
        }
    }
}

	

	updateTranscriptPanelOnLoadWebcam=()=>{
		this.updateTranscriptFromTimeline()
	}



	addSceneAfterScene=(sceneId)=>{
		let sceneBefore = this.findSceneForId(sceneId)
		const sceneIndex = sceneBefore.sceneIndex 
		let newScene = makeSceneTemplate()
		newScene.startTime = sceneBefore.startTime + sceneBefore.duration
		newScene.sceneIndex = sceneIndex + 1
		this._scenes.forEach(scene => {
			if (scene.sceneIndex > sceneIndex) {
				scene.sceneIndex += 1
				scene.startTime += newScene.duration
			}
		})
		this.addScene(newScene, false)
	}

	splitScene(targetSplitTime,presetSceneId,newSceneTitle){
		const splitTime = targetSplitTime || this._currentTime
		this.pmManager.startAction('splitScene')
		const currentScene = this.findSceneForTime(splitTime)
		let clipsToMove=[]
		currentScene.clips.forEach((clip)=>{
			if(clip.startTime >= splitTime){
				clipsToMove.push(clip)
			}
		})
		let updatesArray=[]
		const startTimeOffset = splitTime - currentScene.startTime
		let newSceneId
		if(currentScene){
			const currentSceneIndex = currentScene.sceneIndex
			//bunp all other scenes by +1 
			this._scenes.forEach(scene => {
				if(scene.title=='Default title'){
					scene.title='Untitled Scene'
					this.pmManager.updateNodeAttrs(scene)
				}

				if (scene.sceneIndex > currentSceneIndex) {
					scene.sceneIndex += 1;
					this.pmManager.updateSceneIndex(scene.id,scene.sceneIndex)
				}
			});
			//then add a new one
			let scene = makeSceneTemplate()
			if(presetSceneId){
				scene.id=presetSceneId 
				scene.title = newSceneTitle || 'Untitled Scene'
			}
			scene.startTime = this.duration 
			scene.sceneIndex = currentSceneIndex+1
			this.addScene(scene,false)
			newSceneId = scene.id
		}

		//find the clip to move with the smallest start time
		const sortedClipsToMove = clipsToMove.sort((a, b) => a.startTime - b.startTime);
		const firstClipStartTime = sortedClipsToMove[0]?.startTime - 0.00001|| 0

		// console.log('firstClipStartTime',firstClipStartTime)



		//move the clips between scenes
		clipsToMove.forEach((clip)=>{
			this.moveClipBetweenScenesOnSplit(clip,newSceneId,startTimeOffset,updatesArray)
		})


    //console.log('updatesArray',updatesArray)


		
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.recalculateSceneDurations()
		this.updateTranscriptFromTimeline()
		this.pmManager.endAction()
		this.calculateAudioTrackSpacing()
	}

	updateTranscriptFromTimeline(){
		const audioTrackClips = this.clips.filter(clip => clip.zIndex==-1)
		this.transcriptPmManager.updateTranscriptFromTimeline(audioTrackClips,this._scenes)
		this.calculateAudioTrackSpacing()
	}

	deleteScene(sceneId,isPMUndoRedo){
		if(!isPMUndoRedo){
			this.pmManager.startAction('deleteScene')
		}
		const sceneIndex = this._scenes.findIndex(s => s.id === sceneId)
		if(sceneIndex !== -1){
			const scene = this._scenes[sceneIndex];
			const clipIdSet = new Set(scene.clips.map(clip => clip.id));
			clipIdSet.forEach((clipId)=>{
				scene.deleteClip(clipId,isPMUndoRedo)
			})
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
			this._scenes.splice(sceneIndex, 1);
			if(!isPMUndoRedo){
				this.pmManager.deleteNodeFromPmDoc(sceneId)
			}
		}
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
		//lets redo the scene indexes
		this._scenes.forEach((scene,i)=>{
			scene.sceneIndex = i 
			if(!isPMUndoRedo){
				this.pmManager.updateSceneIndex(scene.id,i)
				}
			})
		this.calculateDuration()
		if(!isPMUndoRedo){
			this.pmManager.endAction()
		}
		this.calculateUniqueVariables()
		this.updateTranscriptFromTimeline()
		this.debouncedSaveProjectTimeline()
	}

	getSceneAudioTrackClips(sceneId){
		/// type audio with parentWebcamClip 
		const audioTrackClips = this.clips
			.filter(clip => (
				clip.sceneId == sceneId &&
				(clip.type === 'audio' || clip.type=='webcam') && !clip.parentWebcamClip))
			.sort((a, b) => a.clipIndex - b.clipIndex);
		return audioTrackClips
	}

	addSceneFromTranscriptPanel(splitSceneId,splitClipId,splitClipIndex){
		const newSceneId = randomID()
		const scene = this.findSceneForId(splitSceneId)
		const originalTitle = scene.title
		let targetSplitTime
		if(scene){
			if(splitClipIndex==0){
				targetSplitTime = scene.startTime + 0.01
			}else{
				const audioTrackClips = this.getSceneAudioTrackClips(splitSceneId)
				const splitClip = audioTrackClips[splitClipIndex-1]
				this.deleteClipById(splitClipId)	
				targetSplitTime = splitClip.endTime
			}
			this.splitScene(targetSplitTime,newSceneId)
		}
		this.calculateAudioTrackSpacing()
		this.updateTranscriptFromTimeline()
		this.transcriptPmManager.focusSceneHeader(newSceneId)
	}

	addNewScene(){ //adds a scene at the hend of the project
		let scene = makeSceneTemplate()
		scene.startTime = this.duration 
		scene.sceneIndex = this._scenes.length
		this.addScene(scene,false)
		this.transcriptPmManager.focusSceneHeader(scene.id)
	}

	getTranscript = () => {
		const audioClips = this.clips.filter(clip => clip instanceof AudioClip);
		const sortedAudioClips = audioClips.sort((a, b) => a.startTime - b.startTime);
		const transcript = sortedAudioClips.map(clip => {
			if (clip.metadata && clip.metadata.text) {
				return clip.metadata.text.trim();
			}
			return '';
		}).join(' ');
		return transcript;
	}

	addScene(scene,isInitialLoad,isPMUndoRedo) {
		const timelineScene = new TimelineScene(scene,this.pmManager,this.transcriptPmManager,this.handleClipMediaLoaded,this.debouncedSaveProjectTimeline,this._projectId,this.handleVoiceoverAudioFileUpdated,this.onSceneDurationChange,this.moveClipBetweenScenes,this.isLastScene,this.setPreviewingAudioClipId,this._activeVoice,this.getTranscript,this.isExport,this.handleTextElementFontLoaded,this.updateTranscriptPanelOnLoadWebcam,this.calculateSoundEffects,this._providerId ,this.getAspectRatio,this.checkWebcamUploadStatus, () => this.videoWindowPadding)		
		timelineScene.initScene(isInitialLoad,scene.clips,isPMUndoRedo,AUDIO_CLIP_SPACING)
		this._scenes.push(timelineScene);
		if(!isInitialLoad){
			this.updateTranscriptFromTimeline()
			this.calculateDuration()
			this.debouncedSaveProjectTimeline()
		}		
	}

	addClip(clip,isInitialLoad,isPMUndoRedo){
		let scene 
		const isWebcamRecording = clip.type=='webcam' && !clip.metadata.isVariable
		if(clip.sceneId){
			scene = this.findSceneForId(clip.sceneId)
		}else{
			scene=this.findSceneForCurrentTime() 
			if(!scene){
				if(this._currentTime > this._duration){
					scene=this.findLastScene()
				}
			}
		}
		if(scene){
			if(isWebcamRecording){
				//calculate the clipIndex need to do this before we calculate audio track spacing in updateTranscriptFromTimeline
				const sceneClips = this.clips.filter(clip => clip.sceneId === scene.id);
				const audioTrackClips = sceneClips
			    .filter(clip => 
			        (clip.type === 'audio' && !clip.parentWebcamClip) || 
			        clip.type == 'webcam'
			    )
			    .sort((a, b) => a.startTime - b.startTime);

				let webcamClipIndex = 0 
				if(audioTrackClips.length){
					const clipBefore = audioTrackClips
						.filter(existingClip => existingClip.startTime <= clip.startTime)
						.pop()
					const clipAfter = audioTrackClips
						.find(existingClip => existingClip.startTime > clip.startTime)
					if (clipBefore) {// Put it 0.5 after the previous clip's index
						webcamClipIndex = audioTrackClips.indexOf(clipBefore) + 0.5
					} else if (clipAfter) {// Put it 0.5 before the next clip's index
						webcamClipIndex = audioTrackClips.indexOf(clipAfter) - 0.5
					}
				}
				 clip.clipIndex = webcamClipIndex	
			}
			
			if(this.overrideVoiceMatch){
				clip.metadata.audioTransformation={
					type:'voiceMatch',
					voiceId:this._providerId
				}
			}
			scene.addClip(clip,isInitialLoad,isPMUndoRedo,this.voiceoverPlaybackRate)
			this.debouncedSaveProjectTimeline()
		}else{
			console.log('cant find scene to add clip')
		}

		if(isWebcamRecording){
			this.calculateAudioTrackSpacing()
			this.updateTranscriptFromTimeline()
		}
		this.calculateSoundEffects()
		this.checkForVoiceMatchSourcesChange()
	}

	deleteClipById(clipId) {
		const clip = this.findClipForId(clipId)
		if(clip){
			this.deleteClip(clip)
			if(clip.type=='webcam'){
				this.updateTranscriptFromTimeline()
			}
		}
		this.calculateUniqueVariables()
		this.debouncedSaveProjectTimeline()
	}



	async initBackgroundMusic() {
		const track = getBackgroundMusicTrackForId(this._backgroundMusicTrack)
		if(track){
			if(track.isUpload){
				const trackData = await ipcRenderer.invoke('read-background-music-file', this._backgroundMusicTrack);
				this.backgroundMusicElement = new Audio(trackData);
				this.backgroundMusicElement.preload = 'auto';
				this.backgroundMusicElement.load();
				this.backgroundMusicElement.volume = this.backgroundMusicVolume
			}else{
				this.backgroundMusicElement = new Audio()
				this.backgroundMusicElement.preload = 'auto'; 
				this.backgroundMusicElement.src = track.src;
				this.backgroundMusicElement.load();
				this.backgroundMusicElement.volume = this.backgroundMusicVolume
			}
			this.backgroundMusicElement.loop = true
		}
	}

///// PLAYBACK //////
	play() {
		if (this._isPlaying) return	
		if (this._currentTime >= this._duration) {
			this._currentTime = this._duration;
			this.pause();
			this._handlePlaybackEnded();
			this._onTimeUpdate(this._currentTime)
      return;
    }	
		this._isPlaying = true
		if (this.backgroundMusicElement) {
        this.backgroundMusicElement.play().catch(error => {
            console.warn('Background music playback failed:', error);
        });
    }
		
		let lastUpdateTime = performance.now();
		const updatePlayback = () => {
			let now = performance.now();
			let deltaTime = (now - lastUpdateTime) / 1000;
			lastUpdateTime = now;
			this._currentTime = parseFloat((this._currentTime + deltaTime).toFixed(2));
			if (this.backgroundMusicElement) {
				const newVolume = fadeVolumeTowardsEnd(this._currentTime, this._duration,this.backgroundMusicVolume);
				this.backgroundMusicElement.volume = newVolume;
			}
			if (this._currentTime >= this._duration) {
				this._currentTime = this._duration
				this.pause(); // Automatically pause when the end is reached
				this._handlePlaybackEnded();
			} else {
				this.updateClipsPlaybackState();
			}
			if (this._onTimeUpdate) {
				this._onTimeUpdate(this._currentTime); // Update parent component
			}
		};
		updatePlayback();
    this._playbackInterval = setInterval(updatePlayback, TICK_LENGTH);
		this._syncInterval = setInterval(() => {
			this.synchronizeTimeline();
		}, SYNC_INTERVAL);
	}


	// updateClipsPlaybackState() {
	// 	this.clips.forEach(clip => {
	// 		if (this._currentTime >= clip.startTime && this._currentTime < clip.endTime) {
	// 			if (clip instanceof VideoClip  || clip instanceof CodecVideoClip || clip instanceof WebcamClip) {
	// 				let activeSegment = findActiveSegmentForVideoClip(this._currentTime, clip) 
	// 				console.log('ACTIVE SEGMENT',activeSegment)
	// 				if(activeSegment){
	// 					const currentSegment = activeSegment.segment 
	// 					const isAfterCollapsedSkipSegment = activeSegment.isAfterCollapsedSkipSegment
	// 					if(isAfterCollapsedSkipSegment && currentSegment.id != this.currentVideoSegment){
	// 						clip.seek(this._currentTime)
	// 					}

	// 					if(currentSegment && currentSegment.isQuiet){
	// 						if(!clip.video.paused || currentSegment.id !== this.currentVideoSegment){
	// 							if(!currentSegment.isManualFreeze){ //don't seek for manual freeze zones
	// 								clip.seek(clip.startTime+ currentSegment.startTime + ((currentSegment.originalDuration - 0.000000000001) / (currentSegment.playbackRate * clip.clipPlaybackRate )))
	// 							}else{
	// 								clip.seek(this._currentTime)
	// 							}
	//             	clip.pause();
	//             	this.currentVideoSegment = currentSegment.id
	// 						}
	// 					}
	// 					else if (clip.video.paused) {
	// 						clip.playFromCurrentTime(this._currentTime);
	// 						this.currentVideoSegment = currentSegment.id
	// 						this.synchronizeTimeline();
	// 					}	else{
	// 						this.currentVideoSegment=currentSegment.id
	// 					}
	// 				}else{

	// 				}
	// 			}
	// 			 else if (clip instanceof AudioClip && clip.audio.paused) {
	// 				clip.playFromCurrentTime(this._currentTime);
	// 			}
	// 		} else {
	// 			if (clip instanceof AudioClip && !clip.audio.paused ||
	// 				clip instanceof VideoClip && !clip.video.paused || 
	// 				clip instanceof WebcamClip && !clip.video.paused) {
	// 					clip.pause();
	// 				}
	// 			}
	// 		});
	// 	} 

updateClipsPlaybackState() {
  if (!this.currentVideoSegments) {
    this.currentVideoSegments = new Map();
  }

  this.clips.forEach(clip => {
    if (this._currentTime >= clip.startTime && this._currentTime < clip.endTime) {
      if (clip instanceof VideoClip || clip instanceof WebcamClip) {
        let activeSegment = findActiveSegmentForVideoClip(this._currentTime, clip) 
        if (activeSegment) {
          const currentSegment = activeSegment.segment 
          const isAfterCollapsedSkipSegment = activeSegment.isAfterCollapsedSkipSegment
          if (isAfterCollapsedSkipSegment && currentSegment.id != this.currentVideoSegments.get(clip.id)) {
            clip.seek(this._currentTime)
          }

          if (currentSegment && currentSegment.isQuiet) {
            if (!clip.video.paused || currentSegment.id !== this.currentVideoSegments.get(clip.id)) {
              if (!currentSegment.isManualFreeze) { //don't seek for manual freeze zones
                clip.seek(clip.startTime + currentSegment.startTime + ((currentSegment.originalDuration - 0.000000000001) / (currentSegment.playbackRate * clip.clipPlaybackRate)))
              } else {
                clip.seek(this._currentTime)
              }
              clip.pause();
              this.currentVideoSegments.set(clip.id, currentSegment.id)
            }
          }
          else if (clip.video.paused) {
            clip.playFromCurrentTime(this._currentTime);
            this.currentVideoSegments.set(clip.id, currentSegment.id)
            this.synchronizeTimeline();
          } else {
            this.currentVideoSegments.set(clip.id, currentSegment.id)
          }
        }
      }
      else if (clip instanceof AudioClip && clip.audio.paused) {
        clip.playFromCurrentTime(this._currentTime);
      }
    } else {
      if (clip instanceof AudioClip && !clip.audio.paused ||
        clip instanceof VideoClip && !clip.video.paused || 
        clip instanceof WebcamClip && !clip.video.paused) {
          clip.pause();
          this.currentVideoSegments.delete(clip.id);
      }
    }
  });
	if(this.soundEffectManager){
		this.soundEffectManager.updatePlaybackTime(this._currentTime)
	}
}

	synchronizeTimeline() {
		this.clips.forEach(clip => {
			if(clip instanceof VideoClip && !clip.video.paused && !clip.isBasicVideo) { //TODO do we need to sync thing
				if(this._currentTime>=clip.startTime && this._currentTime<clip.startTime+clip.duration){
					const videoCurrentTime = clip.video.currentTime
					const timelineTime = calculateTimelineTimeFromVideoTime(videoCurrentTime,clip)
					if(clip.captureId!='990947292'){ //hacky thing for clay bug
						const deltaTime = timelineTime - this._currentTime
						//console.log('deltaTime',deltaTime)
						this._currentTime = timelineTime
					}					
				}
			}
		});
	}

	pause() {
		if (!this._isPlaying) return;
		this._isPlaying = false;
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.pause()
		}
		clearInterval(this._playbackInterval);
		this._playbackInterval = null;
		clearInterval(this._syncInterval);
		this._syncInterval = null;
		this.clips.forEach(clip => {
			if (clip instanceof AudioClip || clip instanceof VideoClip || clip instanceof WebcamClip) {
				clip.seek(this._currentTime);
				clip.pause();
			}	
		});
	}
	
	seek(time) {
		const wasPlaying = this._isPlaying;
		if (wasPlaying) {
			this.pause();
		}
		this._currentTime =Math.max(time, 0) //allow seeking beyond video

		if(this.backgroundMusicElement){
			this.backgroundMusicElement.currentTime = this._currentTime;
		}
		if (this._onTimeUpdate) {
			this._onTimeUpdate(this._currentTime); // Update parent component
		}
		if (wasPlaying) {
			this.play();
		}
		this.clips.forEach(clip => {
			if (clip instanceof AudioClip || clip instanceof VideoClip || clip instanceof WebcamClip) {
				clip.seek(this._currentTime);
			} 
		});

	}

////////////////////////////// PROSEMIRROR ////////////////////////
	createPMDoc() {
		if(this.pmManager){
			let contentArray = [];
			const projectSettingsNode = createProjectSettingsPmNode(this.projectBackgroundId, this.voiceoverPlaybackRate);
			contentArray.push(projectSettingsNode);
			this.pmManager.createDocument(contentArray)
		}
	}

///// DRAG /////
	onDragStart(clip,isDnDMode,isDragPullMode,isAltDragMode) {
		this.isDnDMode = isDnDMode
		this.isDragPullMode = isDragPullMode
		this.isAltDragMode=isAltDragMode

		if(!isDnDMode){
			this.pmManager.onDragStart()
		}
		
		this.isDragging = true 
		this.dragClipId=clip.id
		this.dragClipZIndex=clip.zIndex
		this._scenes.forEach((scene)=>{
			scene.onDragStart(this.dragClipZIndex)
		})
	}

	onDragEnd(dragClip,dropTime,activeDropType) {
		if(this.isDnDMode && dropTime||dropTime==0){
			this.pmManager.startAction('dndEnd')
			this._scenes.forEach((scene)=>{
				scene.onDnDDragEnd(dragClip,dropTime,activeDropType)
			})
			this.pmManager.endAction()
		}
		this.recalculateSceneDurations()
		this.calculateAudioTrackSpacing()
		this.updateTranscriptFromTimeline()
		this.checkAllLinkedClipsAreAligned()
		this.pmManager.onDragEnd()
		this.isDragging = false 
		this.dragClipId=null
		this.dragClipZIndex = null
		this.debouncedSaveProjectTimeline();
		this.isDnDMode=false
		this.isDragPullMode = false
		this.isAltDragMode = false
	
	}

	handleDragClip(sceneId, clip, newStartTime,metaKeyIsPressed,pixelsPerSec) {
		this._scenes.forEach(scene => {
			scene.handleDragClip(clip, newStartTime, this.isDnDMode, this.isDragPullMode,this.isAltDragMode, pixelsPerSec);
		});
	}


///RESIZE //////
	onResizeStart = (clip) => {   
		this.isDragging = true 
		this.dragClipId=clip.id 
		const isVideoResizeMode = clip.type === 'video'||clip.type === 'webcam'
		this.pmManager.startAction('resize')
		this._scenes.forEach((scene)=>{
				scene.onResizeStart(isVideoResizeMode)
		})
	};

	onResizeStop = () => {
		this._scenes.forEach((scene)=>{
			scene.onResizeStop()
		})
		this.checkAllLinkedClipsAreAligned()
		this.pmManager.endAction()
		this.isDragging = false 
		this.dragClipId=null
		this.debouncedSaveProjectTimeline()
		this.updateTranscriptFromTimeline()
	};

	checkAllLinkedClipsAreAligned=()=>{//TODO do extra layer of checks to make sure all linked clips are perfectly aligned
		//console.log('check properly aligned')
	}

	onResize(clip, newDuration,direction,pixelsPerSec) {
		const scene = this._scenes.find(scene => scene.id === clip.sceneId);
		if(scene){
			scene.handleResize(clip.id, newDuration, direction, pixelsPerSec);
		}
	}

	undo() {
		if (this.pmManager.undo()) {
			this.recalculateTimeline();  // Additional logic to recalculate the timeline if necessary
		} 
	}

	redo() {
		if (this.pmManager.redo()) {
			this.recalculateTimeline();  // Additional logic to recalculate the timeline if necessary
		} 
	}

	moveClipBetweenScenesOnUndo=(clip,newSceneId)=>{
		let originalScene = clip.scene
		let newScene = this.findSceneForId(newSceneId)
		clip.scene = newScene
		///TODO figure out when this happens 
		if(newScene){
			newScene.moveClipIntoScene(clip)
		}if(originalScene){
			originalScene.moveClipOutOfScene(clip.id)
		}
	}

	updateSlideClip(existingClip, updatedSlideClip) {
		if (existingClip.scene.id !== updatedSlideClip.sceneId) {
			this.moveClipBetweenScenesOnUndo(existingClip, updatedSlideClip.sceneId);
		}
		existingClip.updateFromJSON(updatedSlideClip)
	}

	updateRegularClip(clip, attrs) {
		if (clip.scene.id !== attrs.sceneId) {
			this.moveClipBetweenScenesOnUndo(clip, attrs.sceneId);
		}
		for (const key in attrs) {
			if (JSON.stringify(clip[key]) !== JSON.stringify(attrs[key])) {
				if (key === 'metadata') {
					clip.metadata = { ...attrs.metadata };
				} else if (key === 'voiceoverPlaybackRate') {
					clip.changeVoiceoverPlaybackRate(attrs[key]);
				} else {
					clip[key] = attrs[key];
				}
			}
		}
	}
 
 recalculateTimeline() { //on undo/redo we force recalc the timeline
		const isInitialLoad = false
		const isPMUndoRedo=true
		const sceneIdSet = new Set(this._scenes.map(scene => scene.id));
		const clipIdSet = new Set(this.clips.map(clip => clip.id));
			
		let clipsToAdd = []	
		this.pmManager.editorState.doc.descendants(node => {
    if (node.type.name.includes('Clip')) {
      if (!clipIdSet.has(node.attrs.id)) {
        let newClip;
        if (node.type.name === 'slideClip') {
          newClip = convertSlideNodeToJSON(node);
        } else {
          newClip = node.attrs;
        }
        clipsToAdd.push(newClip);
      }
    }
  });

		this.pmManager.editorState.doc.descendants(node => {
			if(node.attrs.type=='scene'){
				if (!sceneIdSet.has(node.attrs.id)) {// Create a new clip based on the node's attributes
					let newScene = {...node.attrs, clips: []}
					// Filter clips that belong to this scene, add them, and remove from clipsToAdd
					clipsToAdd = clipsToAdd.filter(clip => {
						if (clip.sceneId === node.attrs.id) {
							newScene.clips.push(clip);
						return false; // Remove this clip from clipsToAdd
						}
						return true; // Keep this clip in clipsToAdd
					});
					this.addScene(newScene,isInitialLoad,isPMUndoRedo);
				}
			}
			else if(node.attrs.type=='settings'){
				const isUndo=true
				this.handleUpdateProjectBackgroundOnUndo(node.attrs.projectBackgroundId,isUndo)
				if(node.attrs.voiceoverPlaybackRate){
					this.voiceoverPlaybackRate=node.attrs.voiceoverPlaybackRate
				}
			}
		});

		this.clips.forEach(clip => {
			let existsInPM = false;
			this.pmManager.editorState.doc.descendants(node => {
				if (node.attrs.id === clip.id) {
				existsInPM = true;
				if(node.type.name =='slideClip'){
					this.updateSlideClip(clip,convertSlideNodeToJSON(node))
				}else{
					this.updateRegularClip(clip,node.attrs)
				}
			}
			})
			if(!existsInPM){
				this.deleteClip(clip,isPMUndoRedo)
			}
		})
	
		//reload all audio clips
		this.clips.forEach(clip => {
			if(clip.type=='audio'){
				clip.reloadAudio()
			}
		});		
		this._scenes.forEach(scene => {
			let existsInPM = false;
			this.pmManager.editorState.doc.descendants(node => {
				if (node.attrs.id === scene.id) {
					existsInPM = true;	
					for (const key in node.attrs) {
						if (JSON.stringify(scene[key])!=JSON.stringify(node.attrs[key])) {
							scene[key] = node.attrs[key];
						}
					}
				}
			});
			if (!existsInPM) {
				this.deleteScene(scene.id,isPMUndoRedo);
			}
		});
		clipsToAdd.forEach((clip)=>{
			this.addClip(clip,isInitialLoad,isPMUndoRedo);
		})

		this.clips.forEach((clip)=>{
			if(clip.type=='video'){
				updateCursorOpacityForClip(clip)
			}
		})

		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
		this.updateTranscriptFromTimeline()
		this.calculateAudioTrackSpacing()
	}

	updateNodeMetadata(clipId, newMetadata) {
		this.pmManager.updateNodeMetadata(clipId,newMetadata)
	}

	updateNodeMetadataSilent(clipId,newMetadata){
		this.pmManager.updateNodeMetadataSilent(clipId,newMetadata)
	}

	updateProjectBackground(projectBackgroundId){
		this.pmManager.updateProjectSetting('projectBackgroundId',projectBackgroundId);
		this.projectBackgroundId = projectBackgroundId;
	}

	async checkAndUpdateAspectRatio(clip, captureId) {
		if(clip){
			const displayMode = clip.metadata.displayMode
			if(displayMode=='desktop'){
				//check if we want to switch to 10_9 aspect ratio 
				const relativePath = `screenRecordings/${captureId}/metadata.json`
				const content = await ipcRenderer.invoke('read-file', relativePath)
				const metadata = JSON.parse(content)
				const screenRecordingDimensions = metadata.dimensions
				const ratio = screenRecordingDimensions.width / screenRecordingDimensions.height;
				const ratio16_9 = 16/9; 
				const ratio16_10 = 16/10;
				const diff16_9 = Math.abs(ratio - ratio16_9);
				const diff16_10 = Math.abs(ratio - ratio16_10);
				let aspectRatio = '16_9'
				if (diff16_10 < diff16_9) {
					aspectRatio = '16_10';
				}
				if(aspectRatio=='16_10' && aspectRatio !== this.aspectRatio){
					this.updateAspectRatio(aspectRatio)
				}
			}
		}
	}

	async addVideoClipFromCaptureId(captureId,isDevice,isScreenRecording,motionStyle, chunkIndex = null,sessionCaptureId=null,clipId,linkedClipId=null, recordingChunks=null) {
		let trimStart,trimEnd = null 
		if((chunkIndex || chunkIndex==0) && recordingChunks){
			const chunk = recordingChunks[chunkIndex]
			trimStart = chunk.startTime
			trimEnd = chunk.startTime + chunk.duration
		}
		const isEmptyProject = this.clips.length === 0
		const newClip = await createVideoClipObjFromCaptureId(captureId,isDevice,isScreenRecording,motionStyle,this._currentTime, chunkIndex, trimStart,trimEnd,sessionCaptureId,clipId,linkedClipId,null, recordingChunks)
		this.addClip(newClip)

		//Check aspect ratio for screen recordings in empty projects
		if(isScreenRecording && isEmptyProject){
			await this.checkAndUpdateAspectRatio(newClip, captureId)
		}

		return newClip
	}

	async addWebcamClipFromCaptureId(captureId,sessionCaptureId=null,linkedClipId=null,clipId=null,trimStart=null,trimEnd=null) {
		const newClip = await createWebcamClipObjFromCaptureId(captureId,sessionCaptureId,this._currentTime,clipId,linkedClipId,trimStart, trimEnd)
		this.addClip(newClip)
		return newClip.id
	}

	////TODO aspect ratio is not in prosemirror/not udoable so undo insert where it changes aspect ratio will remove clip but not reset aspect
	async addScreencast(screenCaptureId,sessionCaptureId,chunkIndex,linkedWebcamCaptureId,motionStyle,duration,optionalWebcamClipId, recordingChunks){
		const isEmptyProject = this.clips.length === 0
		const screenClipId=randomID()
		const webcamClipId=optionalWebcamClipId || randomID()
		let trimStart,trimEnd = null 
		if(chunkIndex || chunkIndex==0){
			const chunk = recordingChunks[chunkIndex]
			trimStart = chunk.startTime
			trimEnd = chunk.startTime + chunk.duration
		}
		this.pmManager.startAction('addScreencast')	
		const isDevice = false 
		const isScreenRecording = true
		const screenClip = await createVideoClipObjFromCaptureId(screenCaptureId,isDevice,isScreenRecording,motionStyle,this._currentTime, chunkIndex, trimStart,trimEnd,sessionCaptureId,screenClipId,webcamClipId,null, recordingChunks)
		const webcamClip = await createWebcamClipObjFromCaptureId(linkedWebcamCaptureId,sessionCaptureId,this._currentTime,webcamClipId,screenClipId, chunkIndex, trimStart,trimEnd,duration)
		this.addClip(screenClip)
		this.addClip(webcamClip)
		
		//Check aspect ratio for empty projects
		if(isEmptyProject){
			await this.checkAndUpdateAspectRatio(screenClip, screenCaptureId)
		}

		this.pmManager.endAction()
		return screenClip
	}



	resolveConflicts(newClip) {
		//console.log('resolve conflicts')
	}


	deleteClip(clip,isPMUndoRedo) {
		const scene = this.findSceneForId(clip.sceneId)
		if(scene){
			scene.deleteClip(clip.id,isPMUndoRedo)
			this.calculateUniqueVariables()
			this.debouncedSaveProjectTimeline()
			this.checkForVoiceMatchSourcesChange()
		}else{
			console.log(`CANT FIND SCENE TO DELETE CLIP!`)
		}
	}

	deleteClipFromPmDoc(clipId) {
		this.pmManager.deleteNodeFromPmDoc(clipId)
	}

	updateTextSlideText(clipId,wordsArray,docJson,text) {
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.scene.updateTextSlideText(clipId,wordsArray,docJson,text)
		}	
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}

	updateProjectDefaultMotionStyle(motionStyle){
		this.clips.forEach(clip => {
			if (clip instanceof TextSlideClip){
				clip.updateProjectDefaultMotionStyle(motionStyle)
				clip.calculateMinSlideDuration()
				if(clip.duration < clip.minDuration){
					this.handleSlideClipDurationLessThanMinimum(clip)
				}
			}
			if (clip instanceof VideoClip){
				if(clip.metadata.isAutoMotionStyle){
					clip.metadata.motionStyle=motionStyle
				}
			}
			if (clip instanceof ChartClip){
				clip.updateProjectDefaultMotionStyle(motionStyle)
			}
		})
	}

/////Animation 
	updateClipMetadata(clipId, settings) {
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata = {...clip.metadata,...settings};
			if(clip.type=='textSlide'){
				clip.calculateMinSlideDuration()
				if(clip.duration < clip.minDuration){
					this.handleSlideClipDurationLessThanMinimum(clip)
				}
				this.calculateDuration();
			}
			if(clip.isScreenRecording && ('displayMode' in settings)){
				this.forceRecalculateCursorData(clip)
			}
			this.pmManager.updateNodeAttrs(clip)
			this.debouncedSaveProjectTimeline()
		}
	}

	updateSlideClipTextStyle(clipId,newTextStyle){
		const clip = this.clips.find(clip => clip.id === clipId)
		if(clip){
			let newMetadata={...clip.metadata}
			newMetadata.textStyle=newTextStyle 
			let font = getFontForTextStyle(newTextStyle) 
    	newMetadata.fontFamily=font.fontFamily
    	newMetadata.fontWeight=font.fontWeight
    	newMetadata.fontSize=font.fontSize
    	newMetadata.lineHeight = font.lineHeight
    	newMetadata.letterSpacing = font.letterSpacing
    	this.updateClipMetadata(clipId,newMetadata)
		}
	}

	handleSlideClipDurationLessThanMinimum(clip){
		//if the slide clip duration is less than required do some stuff here
		clip.duration = clip.minDuration;
		this.resolveConflicts(clip)
	}

	updateClipAnimationSettings(clipId, settings,isPreview) {
		const clip = this.findClipForId(clipId)
		if (clip) {
			clip.metadata = {...clip.metadata,...settings};
			if(clip.type!='video'){
				clip.calculateMinSlideDuration()
				if(!isPreview){ //dont change the clip duration if you are just previewing
					if(clip.duration < clip.minDuration){
						this.handleSlideClipDurationLessThanMinimum(clip)
					}
				}
			}
			this.calculateDuration();
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}


///Background music
	async updateBackgroundMusic(trackId){
		this._backgroundMusicTrack = trackId
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.src = '';
			this.backgroundMusicElement.load();
			this.backgroundMusicElement = null
		}
		if(trackId){
			await this.initBackgroundMusic(trackId)
			this.backgroundMusicElement.currentTime = this._currentTime;
			if(this._isPlaying){
				this.backgroundMusicElement.play()
			}
		}
		this.debouncedSaveProjectTimeline()
	}

	updateBackgroundMusicVolume(newVolume){
		this.backgroundMusicVolume = newVolume
		this.backgroundMusicElement.newVolume = newVolume;
		this.debouncedSaveProjectTimeline()
	} 
	
////Video Clip actions
	addFreezeFrame(clipId,freezeTime){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.addFreezeFrame(clipId,freezeTime)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	addSkipSegment(clipId,skipTime){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.addSkipSegment(clipId,skipTime)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	addSkipSegmentFromSkipSegmentMode(clipId,skipStartTime,skipEndTime){
		const clip = this.clips.find(s => s.id === clipId)
		const videoSkipStartTime = calulateVideoTimeFromTimelineTime(skipStartTime,clip)	
		const videoSkipEndTime =  calulateVideoTimeFromTimelineTime(skipEndTime,clip)	
		clip.addSkipSegmentFromSkipSegmentMode(videoSkipStartTime,videoSkipEndTime)
		this.pmManager.updateNodeAttrs(clip)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
		this.forceRecalculateCursorData(clip)
	}

	toggleSkipSegment(clipId,segmentId,isExpanded){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.toggleSkipSegment(clipId,segmentId,isExpanded)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
		this.forceRecalculateCursorData(clip)
	}

	removeFreeze(clipId,segmentId){
		const clip = this.clips.find(s => s.id === clipId);
		clip.scene.removeFreeze(clipId,segmentId)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	removeSkip(clipId,segmentId){
		const clip = this.clips.find(s => s.id === clipId);
		clip.scene.removeSkip(clipId,segmentId)
		this.debouncedSaveProjectTimeline()	
	}

	updateSkipSegmentDuration(clipId,segmentId,newDuration,direction){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleUpdateSkipSegmentDuration(clipId,segmentId,newDuration,direction)
		this.debouncedSaveProjectTimeline()
	}

	updateFreezeSegmentPlaybackRate(clipId,segmentId,playbackRate){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleChangeSegmentPlaybackRate(clipId,segmentId,playbackRate)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}

	changeVideoClipPlaybackRate(clipId,playbackRate){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleChangeVideoClipPlaybackRate(clipId,playbackRate)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}
	
	trimClipToEdge(clipId,edge){
		const clip = this.clips.find(s => s.id === clipId)
		if(clip){
			const startTime = edge === 'start' ? this.currentTime : clip.startTime
			const endTime = edge === 'start' ? clip.endTime : this.currentTime
			const trimStartTime = calulateVideoTimeFromTimelineTime(startTime,clip)
			const trimEndTime = calulateVideoTimeFromTimelineTime(endTime,clip)
			clip.updateTrimValues(trimStartTime,trimEndTime);
			if(edge === 'start'){
				clip.startTime=startTime
			}
			this.pmManager.updateNodeAttrs(clip)
			this.debouncedSaveProjectTimeline()
		}
	}


	async splitVideoClip(clip, splitTime) {
		this.pmManager.startAction('splitVideoClip')		
		const videoTime = calulateVideoTimeFromTimelineTime(splitTime,clip)
		//step 1 update the trim end for the first clip
		const newTrimEnd = videoTime
		let metadata ={...clip.metadata}
		metadata.trimStart=videoTime
		metadata.trimEnd=clip.metadata.trimEnd

		const newRecordingSegments = clip.recordingSegments
		const newTrimmedSegments = calculateTrimmedSegments(newRecordingSegments,metadata.trimStart,metadata.trimEnd,clip.clipPlaybackRate)
		//const newChunks = calculateTrimmedChunks(clip.recordingChunks,metadata.trimStart,metadata.trimEnd,clip.clipPlaybackRate,clip.duration)

		const newVideoClipId=randomID()
		const newWebcamClipId = randomID() 

		let newClip = {
			id: newVideoClipId,
			clipPlaybackRate:clip.clipPlaybackRate,
			captureId: clip.captureId,
			sessionCaptureId:clip.sessionCaptureId,
			videoId:clip.videoId,
			isBasicVideo:clip.isBasicVideo,
			isScreenRecording:clip.isScreenRecording,
			isDeviceRecording:clip.isDeviceRecording,
			type: 'video',
			startTime: splitTime,
			duration: clip.duration-(splitTime-clip.startTime),
			zIndex: 0,
			metadata: metadata,
			recordingSegments:newRecordingSegments,
			segments:newTrimmedSegments,
			recordingChunks:clip.recordingChunks,
			recordingDuration:clip.recordingDuration
		};

		if(newClip.sessionCaptureId){
			const newClipOptions = await calculateScreenRecordingDisplaySettings({
				recordingChunks: newClip.recordingChunks,
				trimStart: newClip.metadata.trimStart,
				trimEnd: newClip.metadata.trimEnd,
				recordingDuration: newClip.recordingDuration,
				currentDisplayMode: newClip.metadata.displayMode,
				currentWebcamLayouts: newClip.metadata.webcamLayouts,
				captureId: newClip.captureId
			})
			newClip.metadata.displayMode = newClipOptions.displayMode
			newClip.metadata.allowWindowMode = newClipOptions.allowWindowMode
			newClip.metadata.screenVideoApp = newClipOptions.screenVideoApp
			//newClip.metadata.deviceFrame = newClipOptions.deviceFrame
			newClip.metadata.screenVideoDisplayPosition = newClipOptions.displayPosition
			newClip.metadata.webcamLayouts = newClipOptions.webcamLayouts
		}

		//console.log('new chunks are----------------',newChunks)

		if(clip.metadata.linkedClipId){
			//has a linked clip so add that to the metadata
			newClip.metadata.linkedClipId=newWebcamClipId 
			const webcamClip = this.findClipForId(clip.metadata.linkedClipId);
			const newWebcamClip = this.splitWebcamClip(webcamClip, splitTime, newWebcamClipId,newVideoClipId);
		}
		const zoomClips = this.clips.filter(c => c.type === 'zoom' && c.metadata.parentClip === clip.id);
		
		if(splitTime - clip.startTime > MIN_SPLIT_CLIP_DURATION ) { 
			this.updateClipTrimValues(clip.id,clip.metadata.trimStart,newTrimEnd,true)
		}else{
			this.deleteClip(clip) 
		}

		if(newClip.duration > MIN_SPLIT_CLIP_DURATION){
			this.addClip(newClip, false, false)
			//if zoom is after the split put it on the new clip
			zoomClips.forEach((zoomClip)=>{
				if(zoomClip.startTime >= splitTime){
						zoomClip.metadata.parentClip=newClip.id 
						this.pmManager.updateNodeAttrs(zoomClip)
					}
				})
		}else{
			// console.log('dont add new clip cos its too small')
		}		
		this.pmManager.endAction()
	}

	splitWebcamClip(clip,splitTime,newClipId,linkedClipId){
		const isLinked = linkedClipId?true:false
		if(!isLinked){ //if isLinked then we are already in a pm action
			this.pmManager.startAction('splitWebcamClip')
		}
		const clipId=newClipId||randomID()
		const videoTime = calulateVideoTimeFromTimelineTime(splitTime,clip)
		//step 1 update the trim end for the first clip
		const newTrimEnd = videoTime
		let metadata ={...clip.metadata}
		metadata.trimStart=videoTime
		metadata.trimEnd=clip.metadata.trimEnd
		metadata.captionGroups=null //force it to reload the captions
		metadata.subtitles=null //force it to reload the subtitles
		const newRecordingSegments = clip.recordingSegments
		const newTrimmedSegments = calculateTrimmedSegments(newRecordingSegments,metadata.trimStart,metadata.trimEnd,clip.clipPlaybackRate)
		
		let newClip = {
			id: clipId,
			type:'webcam',
			captureId:clip.captureId,
			clipPlaybackRate:clip.clipPlaybackRate,
			startTime: splitTime,
			pinnedStartTime:splitTime,
			duration: clip.duration-(splitTime-clip.startTime),
			recordingDuration:clip.recordingDuration,
			zIndex: -1,
			metadata: metadata,
			recordingSegments:newRecordingSegments,
			segments:newTrimmedSegments,
			isUploadingVideo:clip.isUploadingVideo
		};
		
		if(isLinked){
			newClip.metadata.linkedClipId = linkedClipId
		}

		if(splitTime - clip.startTime > MIN_SPLIT_CLIP_DURATION ){ 
			this.updateClipTrimValues(clip.id,clip.metadata.trimStart,newTrimEnd,true)
		}else{
			this.deleteClip(clip)
		}

		if(newClip.duration > MIN_SPLIT_CLIP_DURATION){
			this.addClip(newClip, false, false)
		}else{
			// console.log('dont add new clip cos its too small')
		}		
		if(!isLinked){
			this.pmManager.endAction()
		}
		return newClip
	}

	updateClipTrimValues(clipId,trimStartTime,trimEndTime){
		const clip = this.clips.find(s => s.id === clipId);
		clip.updateTrimValues(trimStartTime,trimEndTime);
		const message = 'update clip trim values'
		this.pmManager.updateNodeAttrs(clip,message)
		this.debouncedSaveProjectTimeline()		
		if(clip.type=='webcam'){
			this.updateTranscriptFromTimeline()
		}
	}


//////
	setTextSlideTextColor(clipId,textColorId){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata.textColorId=textColorId
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}

	setClipBackgroundId(clipId,backgroundId){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata.backgroundId=backgroundId
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}

	////CHART
	updateChartClip(clipId,metadata){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata=metadata
			this.updateNodeMetadata(clipId,clip.metadata)
			this.debouncedSaveProjectTimeline()
		}
	}

	updateImageWithUploadResponse(clipId,elementId,response){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
		clip.updateImageWithUploadResponse(elementId,response)
		//Update the metadata but make it not undoable
		this.pmManager.updateNodeMetadataSilent(elementId, {
			imgSrc: response.delivery_url,
			semiTransparent: response.semi_transparent
		});
		this.debouncedSaveProjectTimeline()
		}
	}

	checkWebcamUploadStatus=async(clipId) => {
	//	console.log('check webcam upload status',clipId)
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			const webcam = await checkWebcamUploadStatusForCaptureId(clip.captureId);
			if(webcam.status === 'complete' && webcam.transcription_status === 'complete' && webcam.facebox_status === 'complete' && webcam.client_ready_status === 'complete' && webcam.normalize_audio_status === 'complete'){
			//	console.log('webcam is complete')
				this.updateWebcamWithUploadResponse(`${clipId}`,`${clip.captureId}`,{webcam:webcam})
			}
		}
		return false;
	}

	updateWebcamWithUploadResponse=async(clipId,captureId,response)=>{
		const webcamData = response.webcam
		const clip = this.clips.find(clip => clip.id == clipId);
		if (clip) {
			clip.isUploadingVideo = false
			clip.metadata.isVariable = false
			clip.captureId=webcamData.capture_id
			clip.metadata.applyFaceBox = true
			clip.metadata.originalWidth = webcamData.original_width 
			clip.metadata.originalHeight = webcamData.original_height

			clip.metadata.audioTransformation={type:"normalized"}//default use the audio normalised on
			if(this.overrideVoiceMatch){
				clip.metadata.audioTransformation={
						type:'voiceMatch',
						voiceId:this._providerId
					}
			}
			if(!clip.metadata.faceBox){
				const faceBox = await getFaceBoxForWebcamCaptureId(captureId)
				clip.metadata.faceBox = faceBox
			}
			
			await clip.finishUpload()
			//we need to delete all audio clips that have this clip as the parent webcam (for script tts)
			this.clips.forEach((clip)=>{
				if(clip.parentWebcamClip==clipId){
					this.deleteClip(clip)
				}
			})
			this.debouncedSaveProjectTimeline()
			this.updateTranscriptFromTimeline()
			this.checkForVoiceMatchSourcesChange()
		}else{
			console.log('no webcam clip found')
		}
	}

	updateVideoWithUploadResponse(clipId,videoId,response){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.videoId=videoId
			clip.metadata.originalWidth = response.original_width 
			clip.metadata.originalHeight = response.original_height
			clip.metadata.displayWidth=response.default_display_width
			clip.metadata.semiTransparent = response.semi_transparent
			clip.metadata.originalFileName = response.original_filename
			if(clip.type=='webcam'){
				clip.duration = response.duration
			}
			clip.finishUpload()
			this.debouncedSaveProjectTimeline()
		}
	}


	convertAudioClipIntoScript(clipId,sceneId,clipIndex){
		const audioClip = this.findClipForId(clipId);

		let startTime = 0
		let duration = 5
		if(audioClip){
			startTime = audioClip.startTime
			duration = audioClip.duration 
		}

	//if (!audioClip) return;
		this.pmManager.startAction('convertAudioClipIntoScript')
		//make a new variable webcam clip with the start time and duration of the audio clip
		//



		const newClipId = randomID()
		const newClipMetadata	= {
			...webcamClipDefaultMetadata,
			isVariable: true,
			hasInstructions: false
		}
		const newClip = {
			id: newClipId,
			type: 'webcam',
			zIndex: -1,
			startTime: startTime,
			duration: duration,
			metadata: newClipMetadata,
			clipIndex: clipIndex-0.5,
			sceneId: sceneId,
			pinnedStartTime: startTime,
		}

		this.addClip(newClip, false, false)

		if(audioClip){
			audioClip.clipIndex = null
			audioClip.indexInParentClip = 0 
			audioClip.parentWebcamClip = newClipId
			this.pmManager.updateNodeAttrs(audioClip)
		}


		this.pmManager.endAction()
		
		this.calculateAudioTrackSpacing();
		this.updateTranscriptFromTimeline();
		this.debouncedSaveProjectTimeline();
	}

	// convertAudioClipIntoScript(clipId){
	// 	const audioClip = this.findClipForId(clipId);
	// 	if (!audioClip) return;
	// 	this.pmManager.startAction('convertAudioClipIntoScript')
	// 	//make a new variable webcam clip with the start time and duration of the audio clip
	// 	//
	// 	const clipIndex = audioClip.clipIndex
	// 	const clipStartTime = audioClip.startTime
	// 	const clipDuration = audioClip.duration

	// 	const newClipId = randomID()
	// 	const newClipMetadata	= {
	// 		...webcamClipDefaultMetadata,
	// 		isVariable: true,
	// 		hasInstructions: false
	// 	}
	// 	const newClip = {
	// 		id: newClipId,
	// 		type: 'webcam',
	// 		zIndex: -1,
	// 		startTime: clipStartTime,
	// 		duration: clipDuration,
	// 		metadata: newClipMetadata,
	// 		clipIndex: clipIndex,
	// 		sceneId: audioClip.sceneId,
	// 		pinnedStartTime: clipStartTime,
	// 	}

	// 	this.addClip(newClip, false, false)


	// 	audioClip.clipIndex = null
	// 	audioClip.indexInParentClip = 0 
	// 	audioClip.parentWebcamClip = newClipId
	// 	this.pmManager.updateNodeAttrs(audioClip)


	// 	this.pmManager.endAction()
	// 	this.updateTranscriptFromTimeline();
	// 	this.calculateAudioTrackSpacing();
	// 	this.debouncedSaveProjectTimeline();
	// }

	// convertWebcamRecordingIntoVoiceover(clipId) {
	// 	const clip = this.findClipForId(clipId);
	// 	if (!clip) return;
	
	// 	this.pmManager.startAction('convertWebcamToVoiceover');
		
	// 	if (clip.metadata.linkedClipId) {
	// 		this.unlinkScreencast(clipId);
	// 	}
	
	// 	const trimStart = clip.metadata.trimStart;
	// 	const trimEnd = clip.metadata.trimEnd;
	// 	const transcript = clip.metadata.transcript;
	
	// 	this.deleteClip(clip);
	
	// 	if (transcript?.chunks) {
	// 		let audioChunks = [];
	// 		let currentText = [];
	// 		let currentStartTime = null;
	// 		let lastEndTime = null;
			
	// 		// Check if first non-trimmed items are skipped
	// 		let firstIncludedTime = null;
	// 		let hasSkipsAtStart = false;
			
	// 		for (const chunk of transcript.chunks) {
	// 			for (const item of chunk.items) {
	// 				// Only look at items within trim range
	// 				if (item.startTime < trimStart || item.startTime > trimEnd) continue;
					
	// 				const isSkipped = transcript.skippedItems?.some(skip => skip.originalIndex === item.originalIndex);
	// 				if (isSkipped) {
	// 					hasSkipsAtStart = true;
	// 				} else if (!item.isPause) {
	// 					firstIncludedTime = item.startTime;
	// 					break;
	// 				}
	// 			}
	// 			if (firstIncludedTime !== null) break;
	// 		}
	
	// 		transcript.chunks.forEach(chunk => {
	// 			chunk.items.forEach(item => {
	// 				// Skip items outside trim range
	// 				if (item.startTime < trimStart || item.startTime > trimEnd) {
	// 					return;
	// 				}
	
	// 				// Check if this is a skipped item or a pause
	// 				const isSkipped = transcript.skippedItems?.some(skip => skip.originalIndex === item.originalIndex);
	// 				const shouldSplit = isSkipped || item.isPause;
	
	// 				// If we hit a skip or pause and have accumulated text, create a chunk
	// 				if (shouldSplit) {
	// 					if (currentText.length > 0 && currentStartTime !== null && lastEndTime !== null) {
	// 						// Only normalize to firstIncludedTime if we had skips at start
	// 						const startTimeOffset = hasSkipsAtStart ? (currentStartTime - firstIncludedTime) : (currentStartTime - trimStart);
							
	// 						audioChunks.push({
	// 							text: currentText.join(' '),
	// 							startTime: startTimeOffset,
	// 							duration: lastEndTime - currentStartTime
	// 						});
	// 					}
	// 					// Reset for next chunk
	// 					currentText = [];
	// 					currentStartTime = null;
	// 					lastEndTime = null;
	// 					return;
	// 				}
	
	// 				// If this is a regular word (not skipped/pause), add it
	// 				if (!shouldSplit) {
	// 					if (currentStartTime === null) {
	// 						currentStartTime = item.startTime;
	// 					}
	// 					currentText.push(item.word || item.text);
	// 					lastEndTime = item.endTime;
	// 				}
	// 			});
	// 		});
	
	// 		// Add final chunk if there's remaining text
	// 		if (currentText.length > 0 && currentStartTime !== null && lastEndTime !== null) {
	// 			// Only normalize to firstIncludedTime if we had skips at start
	// 			const startTimeOffset = hasSkipsAtStart ? (currentStartTime - firstIncludedTime) : (currentStartTime - trimStart);
				
	// 			audioChunks.push({
	// 				text: currentText.join(' '),
	// 				startTime: startTimeOffset,
	// 				duration: lastEndTime - currentStartTime
	// 			});
	// 		}
	
	// 		// Create audio clips from chunks
	// 		audioChunks.forEach((audioChunk) => {
	// 			if (audioChunk.text.trim()) {
	// 				const audioClip = {
	// 					id: randomID(),
	// 					type: "audio",
	// 					startTime: clip.startTime + audioChunk.startTime,
	// 					pinnedStartTime: clip.startTime + audioChunk.startTime,
	// 					originalDuration: audioChunk.duration,
	// 					duration: audioChunk.duration,
	// 					requiresUpdate: true,
	// 					metadata: {
	// 						text: audioChunk.text.trim()
	// 					},
	// 					zIndex: -1,
	// 					sceneId: clip.sceneId,
	// 					clipIndex: null
	// 				};
	// 				this.addClip(audioClip, false, false);
	// 			}
	// 		});
	// 	}
	
	// 	this.pmManager.endAction();
	// 	this.updateTranscriptFromTimeline();
	// 	this.checkForVoiceMatchSourcesChange();
	// 	this.calculateAudioTrackSpacing();
	// 	this.debouncedSaveProjectTimeline();
	// }


	convertWebcamRecordingIntoVoiceover(clipId) {
		const clip = this.findClipForId(clipId);
		if (!clip) return;
	
		this.pmManager.startAction('convertWebcamToVoiceover');
		
		if (clip.metadata.linkedClipId) {
			this.unlinkScreencast(clipId);
		}
	
		const sceneId = clip.sceneId
		const trimStart = clip.metadata.trimStart;
		const trimEnd = clip.metadata.trimEnd;
		const transcript = clip.metadata.transcript;
		const skipSegments = clip.segments.filter(segment => segment.isSkipSegment)
		const skipRanges = skipSegments.map(segment => ({
			startTime: segment.originalStart,
			endTime: segment.originalEnd,
		 }))
		const firstClipIndex = clip.clipIndex 
		const chunkCount = transcript.chunks.length
		const indexIncrement = 1/(chunkCount+1)

		let audioChunks=[]
		transcript.chunks.forEach((chunk,i)=>{
			console.log('i is',i)
			const startTime = Math.max(chunk.startTime,trimStart)
			const endTime = Math.min(chunk.endTime,trimEnd)
			const estimatedDuration = calculateTimelineTimeFromVideoTime(endTime,clip) -calculateTimelineTimeFromVideoTime(startTime,clip) 
			if (chunk.items && trimStart !== undefined && trimEnd !== undefined) {
				
				const filteredItems = chunk.items.filter(item => 
					!item.isPause &&
					!(item.endTime < trimStart || item.startTime > trimEnd)&&
					 !skipRanges.some(range => item.startTime >= range.startTime && item.endTime <= range.endTime)
				);

				const filteredText = filteredItems.map(item => {
					return item.word;
				}).join(' ');

				const chunkStartTime= calculateTimelineTimeFromVideoTime(startTime,clip)
				audioChunks.push({
					text: filteredText,
					startTime: chunkStartTime,
					clipIndex:firstClipIndex+ (i*indexIncrement),
					//duration: estimateAudioDuration(filteredText.trim())/1000,
					duration: estimatedDuration -0.3 //take a bit off so doesnt push pinned 
				});
			}
		})



		this.deleteClip(clip);
			// Create audio clips from chunks
		audioChunks.forEach((audioChunk) => {
				if (audioChunk.text.trim()) {
					const audioClip = {
						id: randomID(),
						type: "audio",
						clipIndex: audioChunk.clipIndex,
						startTime: audioChunk.startTime,
						pinnedStartTime: audioChunk.startTime,
						originalDuration: audioChunk.duration,
						duration: audioChunk.duration,
						requiresUpdate: true,
						metadata: {
							text: audioChunk.text.trim()
						},
						zIndex: -1,
						sceneId: sceneId,
						//clipIndex: null
					};
					this.addClip(audioClip, false, false);
				}
			});
		
	
		this.pmManager.endAction();
		this.updateTranscriptFromTimeline();
		this.checkForVoiceMatchSourcesChange();
		this.calculateAudioTrackSpacing();
		this.debouncedSaveProjectTimeline();
	}

	convertWebcamRecordingIntoScript(clipId) {
		const clip = this.findClipForId(clipId)
		
		if (!clip) return
		
		this.pmManager.startAction('convertWebcamToScript')

		const transcript = clip.metadata.transcript
		const script = clip.metadata.script
		
		// Update the clip metadata to make it a variable webcam
		clip.metadata = {
			...webcamClipDefaultMetadata,
			isVariable: true,
			hasInstructions: false
		}
		
		// Clear recording-specific properties
		clip.captureId = null
		clip.recordingSegments = []
		clip.segments = []
		clip.recordingDuration = null
		
		let totalDuration = 0
		let lines = []
		// Use script if available, otherwise use transcript chunks
		if (script) {
			lines = script.split('\n')
				.filter(line => line.trim())
				.map(line => ({
					text: line.trim(),
					startTime: 0,  // No specific timing for script lines
					duration: estimateAudioDuration(line.trim())/1000
				}))
		} else if (transcript?.chunks) {
			lines = transcript.chunks.map(chunk => {
				const firstItem = chunk.items[0]
				const lastItem = chunk.items[chunk.items.length - 1]
				return {
					text: chunk.text,
					startTime:0,
					duration: estimateAudioDuration(chunk.text.trim())/1000
				}
			})
		}		
		// Create audio clips from lines
		lines.forEach((line, index) => {
			const audioClip = {
				id: randomID(),
				type: "audio",
				startTime: clip.startTime + (line.startTime || totalDuration),
				originalDuration: line.duration,
				duration: line.duration,
				requiresUpdate: true,
				parentWebcamClip: clipId,
				indexInParentClip: index,
				metadata: {
					text: line.text
				},
				zIndex: -1,
				sceneId: clip.sceneId,
				clipIndex: null // will be set by calculateAudioTrackSpacing
			}
			
			totalDuration += line.duration
			this.addClip(audioClip, false, false)
		})
		
		// Set webcam duration based on audio clips
		clip.placeholderDuration = totalDuration
		
		// Update the clip in prosemirror
		this.pmManager.updateNodeAttrs(clip)
		this.pmManager.endAction()
		this.checkForVoiceMatchSourcesChange()
		this.updateTranscriptFromTimeline()
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
	}



///// save
	getClipsAsJson() {
		return this.clips.map(clip => clip.toJSON());
	}


	getScenesAsJson() {
		const effectsByScene = {};
		
		// Get the selected scene transition sound if scene transitions are enabled
		const sceneTransitionSound = this.soundEffectsSettings.sceneTransition.enabled ? 
			SOUND_EFFECTS_CONFIG.sounds.sceneTransition.find(
				sound => sound.value === this.soundEffectsSettings.sceneTransition.soundEffect
			) : null;
	
		if (this.soundEffectManager && this.soundEffectManager.soundEffects) {
			this.soundEffectManager.soundEffects.forEach(effect => {
				if (!effectsByScene[effect.sceneId]) {
					effectsByScene[effect.sceneId] = [];
				}
	
				const scene = this._scenes.find(s => s.id === effect.sceneId);
				if (scene) {
					const relativeStartTime = effect.startTime - scene.startTime;
					
					// Base effect data
					let effectData = {
						...effect,
						relativeStartTime,
						absoluteStartTime: effect.startTime
					};
	
					// Add volume and settings based on effect type
					switch (effect.effectType) {
						case 'keyboard':
							const keyboardSettings = this.soundEffectsSettings.keyboard;
							const soundNumber = effect.soundNumber;
							effectData = {
								...effectData,
								audioPath: `soundEffects/keyboard/${keyboardSettings.soundSet}/${soundNumber}-${effect.action}.wav`,
								volume: SOUND_EFFECTS_CONFIG.volumeLevels.keyboard[keyboardSettings.soundSet][keyboardSettings.loudness],
							};
							break;
	
						case 'mouse':
							const mouseSettings = this.soundEffectsSettings.mouse;
							effectData = {
								...effectData,
								audioPath: `soundEffects/mouse/mouse-${effect.action}.wav`,
								volume: SOUND_EFFECTS_CONFIG.volumeLevels.mouse[mouseSettings.loudness],
							};
							break;
	
						case 'sceneTransition':
							if (sceneTransitionSound) {
								const sceneSettings = this.soundEffectsSettings.sceneTransition;
								effectData = {
									...effectData,
									volume: SOUND_EFFECTS_CONFIG.volumeLevels.sceneTransition[sceneSettings.loudness],
								//	loudness: sceneSettings.loudness,
									audioPath: sceneTransitionSound.audioPath
								};
							}
							break;

							case 'zoom':
								const zoomSettings = this.soundEffectsSettings.zoom;
								let audioPath = `soundEffects/zoom/zoom-${effect.action}.mp3`
								if(effect.action=='switch'){
									audioPath = `soundEffects/zoom/zoom-switch.wav`
								}
								effectData = {
									...effectData,
									audioPath: audioPath,
									volume: SOUND_EFFECTS_CONFIG.volumeLevels.zoom[zoomSettings.loudness],
								};
								break;
					}
					
					effectsByScene[effect.sceneId].push(effectData);
				}
			});
		}
	
		// Add sound effects to each scene's JSON
		return this._scenes.map(scene => {
			const sceneJson = scene.toJSON();
			sceneJson.soundEffects = effectsByScene[scene.id] || [];
			return sceneJson;
		});
	}


	calculateUniqueVariables() {
		const allVariables = new Set();
		if(this._scenes){
			this._scenes.forEach(scene => {
				scene.clips.forEach(clip => {
					if (clip.type == 'slide' && clip.metadata && Array.isArray(clip.metadata.variables)) {
						clip.metadata.variables.forEach(variable => {
						allVariables.add(variable);
					});
					}
					if (clip.type === 'audio') {
						const text = clip.metadata.text;
						const variableRegex = /{{(.*?)}}/g;
						let match;
						while ((match = variableRegex.exec(text)) !== null) {
							allVariables.add(match[1]);
						}
					}
				});
			});
			this.variables = Array.from(allVariables);
		}
	}

/////// Audio clips

	addClipFromTranscriptSync(clip,sceneId){
		const scene = this.findSceneForId(sceneId)
		if(scene){
			scene.addClipFromTranscriptSync(clip,this.voiceoverPlaybackRate)
		}
		this.checkForVoiceMatchSourcesChange()
	}

	mergeSceneFromTranscriptSync=(sceneId)=>{ //this merges the scene sceneId with teh scene before it
		const sceneToMerge = this.findSceneForId(sceneId)
		const sceneIndex = sceneToMerge.sceneIndex 
		if(sceneIndex!=0){
			const scene = this.scenes[sceneIndex-1]
			let updatesArray=[]
			const clipIdSet = new Set(sceneToMerge.clips.map(clip => clip.id));
			clipIdSet.forEach((clipId)=>{
				const clip =this.findClipForId(clipId)
				clip.scene = scene 
				clip.relativeStartTime+=scene.duration
				scene.moveClipIntoScene(clip)
				sceneToMerge.moveClipOutOfScene(clip.id)
				updatesArray.push({clipId:clip.id,relativeStartTime:clip.relativeStartTime,sceneId:clip.scene.id})
			})
			this.pmManager.updateMultipleClipFields(updatesArray)
			this.deleteScene(sceneToMerge.id)
		}else{
			this.updateTranscriptFromTimeline()
		}
	}

	updateTimelineFromTranscript(updatesArray){
		this.pmManager.startAction('transcriptSync')
		updatesArray.forEach((update)=>{
			if(update.type=='createAudioClip' && update.text!=='#' && update.text!=='---'){
				const text = update.text 
				const estimatedDuration = estimateAudioDuration(text)/1000
				const newClip = {
					id: update.clipId,
					type:"audio",
					startTime:0,
					originalDuration:estimatedDuration,
					duration:estimatedDuration,
					name:"voiceClip",
					requiresUpdate:true,
					parentWebcamClip:update.parentWebcamClip,
					metadata:{
						text:text
					},
					zIndex:-1,
					sceneId:12,
					clipIndex:update.clipIndex
				}
				const sceneId = update.sceneId 
				this.addClipFromTranscriptSync(newClip,sceneId)		
			}
			else if(update.type=='deleteClip'){
				this.deleteClip(update.clip)		
			}
			
			else if(update.type=='updateAudioClip'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.indexInParentClip = update.indexInParentClip
					clip.parentWebcamClip = update.parentWebcamClip
					const oldText = clip.metadata.text
					const newText = update.text
					if(oldText!==newText){
						clip.metadata.text = newText;
						const estimatedDuration=estimateAudioDuration(newText)/1000
			 			clip.originalDuration = estimatedDuration
			 			clip.voiceoverPlaybackRate = this.voiceoverPlaybackRate
						clip.duration = estimatedDuration / this.voiceoverPlaybackRate				
						clip.handleTextUpdated(newText,estimatedDuration)
					}
					this.pmManager.updateNodeAttrs(clip)	
				}
			}
			else if(update.type=='updateSceneTitle'){
				const scene = this._scenes.find(s => s.id === update.sceneId);
				const newTitle = update.title
				scene.title = newTitle
				this.pmManager.updateNodeAttrs(scene)
			}

			else if(update.type=='mergeScene'){
				this.mergeSceneFromTranscriptSync(update.sceneId)
			}

			else if(update.type=='createVariableWebcamClip'){
				const newClip = {
					id: update.clipId,
					type:"webcam",
					clipIndex:update.clipIndex,
					startTime:0,
					originalDuration:3,
					duration:3,
					zIndex:-1,
					sceneId:update.sceneId,
					metadata:{...webcamClipDefaultMetadata,hasInstructions:update.hasInstructions,isVariable:true}
				}
				this.addClipFromTranscriptSync(newClip,update.sceneId)	
			}
			else if(update.type=='updateVariableWebcamClip'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.metadata.instructions=update.instructions
					clip.metadata.hasInstructions=update.hasInstructions
					this.pmManager.updateNodeAttrs(clip)	
				}
			}
			else if(update.type=='updateWebcamRecording'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.updateChunksAndSkipRanges(update.chunks,update.skipRanges)
					this.pmManager.updateNodeAttrs(clip)
					if(clip.metadata.linkedClipId){
						const linkedClip = this.findClipForId(clip.metadata.linkedClipId)
						linkedClip.recordingSegments = clip.recordingSegments.map(segment => ({
							...segment,
							id: randomID()
						}))
						linkedClip.segments = clip.segments.map(segment => ({
							...segment,
							id: randomID()
						}))

						linkedClip.duration = clip.duration
						this.pmManager.updateNodeAttrs(linkedClip)

						const is16_10 = this.aspectRatio === '16_10'
						const sceneWidth = 1920
						const sceneHeight = is16_10 ? 1241 : 1080
						computeCursorDataForClips([linkedClip],true,sceneWidth,sceneHeight)

					}
				}
			}
		})
		this.pmManager.endAction()
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
	}

	handleTranscriptDnd(dropClipId, dropPosition) {
		const clip = this.findClipForId(dropClipId);
		if (!clip) {
			return;
		}
    const originalScene = clip.scene;
    const targetScene = this.findSceneForId(dropPosition.sceneId);
		if (!targetScene) {
			return;
		}
    this.pmManager.startAction('moveAudioClip');
    const originalIndex = clip.clipIndex;
		let newClipIndex = dropPosition.clipIndex; //lets add fractional indexes then reorder and assign new indexes
		if (dropPosition.dropType === 'after') {
			newClipIndex += 0.5;
		} else {
			newClipIndex -= 0.5;
		}
   
    if (originalScene.id === targetScene.id) {
			const audioClips = originalScene.clips.filter(c => c.type === 'audio');
			clip.clipIndex = newClipIndex
			audioClips.sort((a, b) => a.clipIndex - b.clipIndex);
			// Reassign integer indexes
			audioClips.forEach((c, index) => {
				c.clipIndex = index;
				this.pmManager.updateNodeAttrs(c);
			});

    } else {
    	 originalScene.moveClipOutOfScene(clip.id);
        // Moving to a different scene
        originalScene.clips.filter(c => c.type === 'audio' && c.clipIndex > originalIndex).forEach(c => {
            c.clipIndex -= 1;
            this.pmManager.updateNodeAttrs(c);
        });
        
        clip.clipIndex = newClipIndex
        targetScene.moveClipIntoScene(clip);

        const audioClips = targetScene.clips.filter(c => c.type === 'audio'); 
        audioClips.sort((a, b) => a.clipIndex - b.clipIndex);

				// Reassign integer indexes
				audioClips.forEach((c, index) => {
					c.clipIndex = index;
					this.pmManager.updateNodeAttrs(c);
				});
    }

    // Move the clip
    clip.scene = targetScene;
    clip.clipIndex = newClipIndex;
    //targetScene.moveClipIntoScene(clip);
    this.pmManager.updateNodeAttrs(clip);
    this.pmManager.endAction();
    this.calculateAudioTrackSpacing();
    this.recalculateSceneDurations();
    this.updateTranscriptFromTimeline();
    this.debouncedSaveProjectTimeline();
}

	//new set the clip index on clips 
	//this was happening through transcript panel updates but now we can add webcam on timeline we need to recalc indexes
	//If you have 2 webcam clips after each other and the have the same clip.captureId then the minimum spacing between them is 0
	calculateAudioTrackSpacing() {
		let updatesArray = []
		const makeAudioTrackUpdateObj=(clip)=>{
			return{
				clipId: clip.id,
				relativeStartTime: clip.relativeStartTime,
				relativePinnedStartTime: clip.relativePinnedStartTime,
				duration: clip.duration,
				clipIndex:clip.clipIndex,
				minDuration:clip.minDuration
			}
		}

		this._scenes.forEach((scene) => {			

			const sceneAudioTrackClips = this.getSceneAudioTrackClips(scene.id)
			let previousClipEndTime = scene.startTime-AUDIO_CLIP_SPACING;
			let previousClip = null;

			sceneAudioTrackClips.forEach((clip, i) => {


				// const requiresSpacing = !previousClip || 
				// 	previousClip.type !== 'webcam' || 
				// 	clip.type !== 'webcam' || 
				// 	previousClip.captureId !== clip.captureId ||
				// 	previousClip.metadata.isVariable ||
				// 	clip.metadata.isVariable;

					
					const requiresSpacing = !previousClip || 
					(previousClip.type !== 'webcam' && clip.type !== 'webcam');

				const spacing = requiresSpacing ? AUDIO_CLIP_SPACING : 0;

				if (clip.pinnedStartTime) {
					if (clip.pinnedStartTime < previousClipEndTime + spacing){
						clip.pinnedStartTime = null
						clip.startTime = previousClipEndTime + spacing;
					}
				}
				else{ //not pinned
					clip.startTime = previousClipEndTime + spacing;
				}

				if(clip.metadata.linkedClipId){ //linked webcam should always have a pinned starttime
					clip.pinnedStartTime = clip.startTime
					const linkedClip = this.findClipForId(clip.metadata.linkedClipId)
					if(linkedClip){
						const startTimeDelta = clip.startTime - linkedClip.startTime 
						if(startTimeDelta>0){
							scene.moveLinkedClip(clip.metadata.linkedClipId,startTimeDelta)
						}if(startTimeDelta<0){
							clip.startTime = linkedClip.startTime 
							clip.pinnedStartTime=linkedClip.pinnedStartTime
						}
					}
				}

				//We need to reset the index because when we insert webcam on timeline we give it a non integer index in between the clips we insert it between
				clip.clipIndex = i 

				if(clip.type=='webcam' && clip.metadata.isVariable){
					//we need to work out the new duration of the webcam from its child clips
					let placeholderClipDuration = 0
					let minDuration = 1
					const placeholderAudioClips = this.clips.filter(c => c.parentWebcamClip == clip.id);
					placeholderAudioClips.sort((a, b) => a.indexInParentClip - b.indexInParentClip);
					if(placeholderAudioClips.length==0){
						placeholderClipDuration = clip.placeholderDuration || 5
					}
					else{
						let previousPlaceholderAudioEndTime = clip.startTime - spacing
						let placeholderClipEndTime
						//make sure to reset clipIndex and pinnedStartTime for the placeholder audio clips incase we joined normal chunks into the placeholder
						placeholderAudioClips.forEach(pa=>{
							pa.startTime = previousPlaceholderAudioEndTime + spacing
							pa.pinnedStartTime = null 
							pa.clipIndex = null //might not need this 
							updatesArray.push(makeAudioTrackUpdateObj(pa))
							previousPlaceholderAudioEndTime =pa.endTime 
							placeholderClipEndTime=pa.endTime + spacing
						})
						placeholderClipDuration = placeholderClipEndTime - clip.startTime
						minDuration = placeholderClipDuration
					}
					clip.minDuration = minDuration
					if(clip.placeholderDuration){
						if(clip.placeholderDuration<placeholderClipDuration && placeholderClipDuration){
							clip.placeholderDuration = null
							clip.duration = placeholderClipDuration
						}else{	

							//calced duration is less that the one set so leave it
						}
					}else{
						clip.duration = placeholderClipDuration
					}
				}
				
				previousClipEndTime = clip.endTime
				previousClip = clip
				updatesArray.push(makeAudioTrackUpdateObj(clip))
			})
		})
		const preventUndo = true;
	//	this.pmManager.updateMultipleClipFields(updatesArray, preventUndo);
		this.recalculateSceneDurations();
	}

	updateSlideElementAnimationIndex(clipId,elementId,newIndex){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.updateSlideElementAnimationIndex(elementId,newIndex)
		}
		this.debouncedSaveProjectTimeline()
	}

	updateVoiceoverPlaybackRate(rate){
		this.pmManager.startAction('voiceoverPlaybackRate')
		this.pmManager.updateProjectSetting('voiceoverPlaybackRate',rate);
		this.voiceoverPlaybackRate= rate

		let updatesArray=[]
		this.clips.forEach(clip => {
			if(clip instanceof AudioClip) {
				clip.changeVoiceoverPlaybackRate(rate)
				updatesArray.push({clipId:clip.id,duration:clip.duration,voiceoverPlaybackRate:rate})
			}
		})
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.pmManager.endAction()
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
	}


	isLastScene=(sceneId)=>{
    if (!this._scenes.length) return false; // Return false if there are no scenes
    this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
    return this._scenes[this._scenes.length - 1].id === sceneId;
	}

	recalculateSceneDurations = () =>{
		this._scenes.forEach((scene)=>{
			scene.calculateSceneDuration()
		})
		this.calculateDuration()
	}


	onSceneDurationChange = () => {
		this.calculateDuration()
	}

///////// SLIDES ///////////////////
	saveSlideChanges=(clip)=>{
		this.calculateUniqueVariables()
		this.pmManager.syncSlideClip(clip)
		this.debouncedSaveProjectTimeline()
	}

	handleSlideDragOrResizeStart(){
		this.pmManager.onSlideDragResizeStart()
	}

	handleSlideDragOrResizeEnd(){
		this.pmManager.onSlideDragResizeEnd()
	}

	async addSlideElement(clipId, type, isVariable,newElementId) {
    const clip = this.findClipForId(clipId);
    if (clip) {
      await clip.addSlideElement(type, isVariable,newElementId);
      this.saveSlideChanges(clip);
    }
  }

	duplicateSlideItems(clipId,duplicateItemIds){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.duplicateSlideItems(duplicateItemIds)
			this.saveSlideChanges(clip)
		}
	}

	deleteSlideItems(clipId,items){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.deleteItems(items)
			this.saveSlideChanges(clip)
		}
	}

	groupSlideItems(clipId,slideItems,groupingType,newLayoutGroupId){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.groupSlideItems(slideItems,groupingType,newLayoutGroupId)
			this.saveSlideChanges(clip)
		}
	}

	ungroupSlideLayoutGroup(clipId,layoutGroupId){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.ungroupLayoutGroup(layoutGroupId)
			this.saveSlideChanges(clip)	
		}
	}

	updateSlideLayoutGroupType(clipId,layoutGroupId,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateLayoutGroupType(layoutGroupId,value)
			this.saveSlideChanges(clip)
		}
	}

	updateSlideLayoutGroupField(clipId,layoutGroupId,field,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateLayoutGroupField(layoutGroupId,field,value)
			this.saveSlideChanges(clip)
		}
	}

	alignSlideItems(clipId,slideItems,alignType,alignValue){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.alignSlideItems(slideItems,alignType,alignValue)
			this.saveSlideChanges(clip)
		}
	}

	updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementText(elementId,lettersArray,text,docJson)

			this.saveSlideChanges(clip)
		}
	}

	updateSlideAlignment(clipId,alignment,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateSlideAlignment(alignment,value)
			this.saveSlideChanges(clip)	
		}
	}
	
	updateSlideElementMetadata(clipId,elementId,newMetadata){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementMetadata(elementId,newMetadata)
			this.saveSlideChanges(clip)		
		}
	}

	updateSlideElementField(clipId,elementId,field,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementField(elementId,field,value)
			this.saveSlideChanges(clip)		
		}
	}

	updateSlideTextElementTextProperties(clipId,elementId,textStyle,newTextProperties){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateTextElementTextProperties(elementId,textStyle,newTextProperties)
			this.saveSlideChanges(clip)		
		}
	}


	alignSlideElements(clipId,selectedSlideElements,alignType,alignValue){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.alignSlideElements(selectedSlideElements,alignType,alignValue)
			this.saveSlideChanges(clip)		
		}
	}

	updateImageElementImage(clipId,imgObj,replaceElementId){
		const clip = this.findClipForId(clipId)
		clip.updateImageElementImage(imgObj,replaceElementId)
		this.saveSlideChanges(clip)	
	}

	addImageElementToSlide(clipId,imgObj,elementId,dropPosition){
		const clip = this.findClipForId(clipId)
		clip.addImageElement(imgObj,elementId,dropPosition)
		this.saveSlideChanges(clip)
	}

	updateSlideElementZOrder(clipId,elementId,updateType){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementZOrder(elementId,updateType)
			this.saveSlideChanges(clip)
		}
	}

	useSlideTemplate(clipId, template){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.useSlideTemplate(template)
			this.saveSlideChanges(clip)		
		}
	}

	updateAspectRatio(newAspectRatio){
		this.aspectRatio=newAspectRatio
		this.debouncedSaveProjectTimeline()
	}

	unlinkScreencast=(clipId)=>{
		const clip = this.findClipForId(clipId)
		if(clip && clip.metadata.linkedClipId){
			const linkedClip = this.findClipForId(clip.metadata.linkedClipId)
			if(linkedClip){
				const hasOngoingPmAction = this.pmManager.editingAction?true:false 
				if(!hasOngoingPmAction){
					this.pmManager.startAction('unlinkScreencast')
				}
				clip.metadata.linkedClipId = null 
				this.pmManager.updateNodeAttrs(clip)
				linkedClip.metadata.linkedClipId = null 
				this.pmManager.updateNodeAttrs(linkedClip)
				if(!hasOngoingPmAction){
					this.pmManager.endAction()
					this.debouncedSaveProjectTimeline()
				}
			}
		}
	}

	////Webcam
	restoreWebcamSkip(skip){
		this.pmManager.startAction('restoreWebcamSkip')

		const clipId = skip.clipId 
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.restoreWebcamSkip(skip)
			this.pmManager.updateNodeAttrs(clip)
			if(clip.metadata.linkedClipId){
				const linkedClip = this.findClipForId(clip.metadata.linkedClipId)
				
				linkedClip.recordingSegments = clip.recordingSegments.map(segment => ({
					...segment,
					id: randomID()
				}))
				linkedClip.segments = clip.segments.map(segment => ({
					...segment,
					id: randomID()
				}))
				linkedClip.duration = clip.duration
				this.pmManager.updateNodeAttrs(linkedClip)
			}
		}
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
		this.pmManager.endAction()
	}
	calculateSoundEffects=()=>{
		if(this.soundEffectManager){
			this.soundEffectManager.calculateSoundEffects(this)
		}
	}
	
	calculateDuration() {
		let cumulativeDuration = 0;
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
		this._scenes.forEach((scene, index) => {
			if (index === 0) {
				scene.startTime = 0;
			} else {
				scene.startTime = cumulativeDuration;
			}
			cumulativeDuration += scene.duration;
		})
		this._duration = cumulativeDuration;
		if(INFINITE_TIMELINE){
			const adjustedClipEnd = cumulativeDuration + 30; // Add 30 seconds to ensure a minimum of 30 seconds interval
			const nextHalfMinute = Math.ceil(adjustedClipEnd / 30) * 30;
			this.maxTimelineDurationSeconds = Math.max(120, nextHalfMinute)
		}

		this.debouncedCalculateSoundEffects()

	}

	handleSaveProjectTimeline(){
		if(this.calculateUniqueVariables){
			this.calculateUniqueVariables()
		}
		if(this._scenes){
			const voiceObj = getVoiceForId(this._activeVoice)
			let providerId=this._activeVoice
			if(voiceObj){
				providerId = voiceObj.providerId
			}

			const scenesJson = this.getScenesAsJson();
			let timelineData={}
			timelineData.activeVoice = this._activeVoice

			timelineData.providerId = providerId
			timelineData.voiceMatch = this.voiceMatch
			timelineData.overrideVoiceMatch = this.overrideVoiceMatch
			timelineData.variables =this.variables
			timelineData.voiceoverPlaybackRate=this.voiceoverPlaybackRate
			timelineData.scenes = scenesJson
			timelineData.backgroundMusicTrack = this._backgroundMusicTrack
			timelineData.backgroundMusicFadeEffect=this._backgroundMusicFadeEffect || false
			timelineData.backgroundMusicVolume=this.backgroundMusicVolume || BACKGROUND_TRACK_VOLUME
			timelineData.showCaptions=this.showCaptions
			timelineData.aspectRatio = this.aspectRatio
			timelineData.hideInactiveCursor=this.hideInactiveCursor
			timelineData.soundEffectsSettings=this.soundEffectsSettings
			timelineData.videoWindowPadding=this._videoWindowPadding

			//timelineData.soundEffects=this.soundEffectManager.soundEffects
			saveProjectTimeline(this._projectId,timelineData,this.duration)
		}
	}

	destroy() {
		if (this._isPlaying) {
			this.pause();
		}
		if (this._playbackInterval) {
			clearInterval(this._playbackInterval);
			this._playbackInterval = null;
		}
		if (this._syncInterval) {
			clearInterval(this._syncInterval);
			this._syncInterval = null;
		}
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.src = '';
    	this.backgroundMusicElement.load();
		}
		this._scenes.forEach(scene => {
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
		});
		this._scenes = null;
		if(this.pmManager){
			this.pmManager.destroy()
		}
		if(this.transcriptPmManager){
			this.transcriptPmManager.destroy()
		}
		if(this.soundEffectManager){
			this.soundEffectManager.destroy()
		}

	}

	getAspectRatio=()=>{
		return this.aspectRatio
	}

	getAspectRatio=()=>{
		return this.aspectRatio
	}

	get duration() {
		return this._duration;
	}

	get currentTime() {
		return this._currentTime;
	}

	get isPlaying() {
		return this._isPlaying;
	}

	get clips() {
		const clips= this._scenes.sort((a, b) => a.startTime - b.startTime)
			.flatMap((scene, sceneIndex) => 
				scene.clips.map(clip => (
					clip
				))
			);
		return clips
	}

	get activeVoice() {
		return this._activeVoice ;
	}

	get backgroundMusicTrack() {
		return this._backgroundMusicTrack ;
	}

	// get scenes() {
	// 	return this._scenes
	// 		.sort((a, b) => a.startTime - b.startTime)
	// }
	// get scenes() {
	// 	if (!this._scenes) {
	// 		return [];
	// 	}
	// 	return this._scenes.sort((a, b) => a.startTime - b.startTime);
	// }
	get scenes() {
		if (!this._scenes) {
			return [];
		}
		const sortedScenes = this._scenes.sort((a, b) => a.startTime - b.startTime);
		sortedScenes.forEach((scene, index) => {
			scene.sceneIndex = index;
		});
		return sortedScenes;
	}

	get videoWindowPadding() {
		return this._videoWindowPadding;
	}

}

export { Timeline }




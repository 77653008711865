import {fetch,batchedFetch,post,put} from '../api'
import { Mixpanel } from '../Mixpanel'
import {showToastNotification} from './toastNotification'
import {openProject} from '../utils/projectUtils/openProject'
import {getOrgNameLabel} from '../utils/getOrgNameLabel'
import {getOrgDomain} from '../utils/getOrgDomain'
import {fetchProjectCollections} from './collections'



export function generateProjectRender(projectId, renderOptions) {
  return () => {
    return post(`/projects/${projectId}/render`, renderOptions)
      .then((response) => {
        console.log('generateProjectRender', response)
        if (!response || response.error) {
          throw new Error(response?.error || 'Failed to generate project render');
        }
        return response
      })
      .catch((error) => {
        // Ensure error is properly thrown instead of returned
        throw error
      })
  }
}


export function handleUpdateProjectAccessUserPusher(projectId, userId, lastOpened) {
	return (dispatch, getState) => {
		const project = getState().projects.find(p => p.id === projectId);
		if (!project) return;

		const currentPAU = project.project_access_user || {};
		// Only dispatch if the user ID actually changed
		if (currentPAU.current_user_id !== userId) {
			dispatch({ type: 'UPDATE_PROJECT_ACCESS_USER', projectId, userId, lastOpened });
		}
	}
}

export function fetchProjects(){
	return (dispatch) => {
		const startTime = performance.now();	
		return batchedFetch(`/projects`)
			.then((response) => {
				const endTime = performance.now();
				const duration = endTime - startTime;
				console.log(`F ${response.length} p ${duration.toFixed(2)/1000}`);
				dispatch({ type: 'FETCH_PROJECTS_SUCCESS', response })
				return response
			})
			.catch((error) => {
				return error
			})
	}
}

export function fetchProjectCollabGenerations(projectId) {
  return (dispatch) => {
    return fetch(`/projects/${projectId}/collab-generations`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }
}


export function fetchSingleProject(projectId) {
  return (dispatch) => {
    return fetch(`/projects/${projectId}`)
      .then((response) => {
        dispatch({ type: 'FETCH_SINGLE_PROJECT_SUCCESS', response })
        return response
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_SINGLE_PROJECT_FAILURE', error })
        return error
      })
  }
}


export function updateProjectCollabPageSettings(projectId, pageSettings) {
	const requestBody = {
		pageSettings: pageSettings
	};
	return (dispatch) => { 
		dispatch({ 
			type: 'UPDATE_COLLAB_PAGE_SETTINGS_REQUEST', 
				projectId,
				pageSettings
		})
		return put(`/projects/${projectId}/collab-page-settings`, requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response });
				return response;
			})
			.catch((error) => {
				console.log('Update project collab page  type error');
				console.log(error);
				return error;
			});
	};
}



export function updateProjectSubtitlesType(projectId, subtitlesType) {
	const requestBody = {
		subtitlesType: subtitlesType
	};
	return (dispatch) => { 
		return put(`/projects/${projectId}/subtitles-type`, requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response });
				Mixpanel.track('update_project_subtitles_type', { subtitlesType: subtitlesType });
				return response;
			})
			.catch((error) => {
				console.log('Update project subtitles type error');
				console.log(error);
				return error;
			});
	};
}


export function fetchArchivedProjects(){
	return (dispatch) => batchedFetch(`/projects/archived`)
	.then((response) => {
		dispatch({ type: 'FETCH_ARCHIVED_PROJECTS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}
 

export function updateProjectPrivateStatus(projectId,isPrivate) {
	const requestBody={
		is_private:isPrivate
	}
	return (dispatch) =>{ 
		dispatch({ type: 'UPDATE_PROJECT_PRIVATE_STATUS', projectId, isPrivate });
		return put(`/projects/${projectId}/status`,requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				if(!isPrivate){
					const toastType='moveToTeamLibrary'
					const data={
						projectName: response.name,
						orgName:getOrgNameLabel() // team name
					}
					dispatch(showToastNotification(toastType,data))
				}
				return response
			})
			.catch((error) => {
				dispatch(fetchProjects())
				return error
		})
	}
}

export function openProjectRequest(projectId) {
	return (dispatch) =>{ 
		return put(`/projects/${projectId}/open`)
			.then((response) => {
				return response
			})
			.catch((error) => {

				return error
		})
	}
}

export function closeProjectRequest(projectId) {
	return (dispatch) =>{ 
		return put(`/projects/${projectId}/close`)
			.then((response) => {
				return response
			})
			.catch((error) => {

				return error
		})
	}
}

export function closeAllProjects() {
	return (dispatch) =>{ 
		return put(`/projects/close-all`)
			.then((response) => {

				return response
			})
			.catch((error) => {
				return error
		})
	}
}

export function createProject(project,collectionId) {
	const requestBody={project:project,collectionId:collectionId}
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_PROJECT_REQUEST', project })
		 return post(`/projects`,requestBody)
			.then((response) => {
				dispatch({ type: 'CREATE_PROJECT_SUCCESS', response })
				if(collectionId){
					dispatch(fetchProjectCollections())
				}
				Mixpanel.track('create_project')
				return response
			})
			.catch((error) => {
				console.log('create project error')
				console.log(error)
				return error
			})
		}
}


export function archiveProjectRequest(projectId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_PROJECT_REQUEST', projectId })
		 return put(`/projects/${projectId}/archive`)
			.then((response) => {
				//console.log(response)
				Mixpanel.track('archive_project')
				dispatch(fetchArchivedProjects())
				const toastType='deleteProject'
				const data={      
					projectName:response.name,
				}
				dispatch(showToastNotification(toastType,data))
				return response
			})
			.catch((error) => {
				console.log('archive project error')
				console.log(error)
				return error
			})
		  }
}

export function restoreProjectRequest(projectId, history) {
  return (dispatch, getState) => { 
    const archivedProject = getState().archivedProjects.find(p => p.id === projectId);
    dispatch({ type: 'RESTORE_PROJECT_REQUEST', project: archivedProject })
    dispatch({ type: 'REMOVE_FROM_ARCHIVED_PROJECTS', projectId })
    return put(`/projects/${projectId}/restore`)
      .then((response) => {
        Mixpanel.track('restore_project')
        dispatch({ type: 'RESTORE_PROJECT_SUCCESS', projectId })
        openProject(projectId, history)
				const toastType='restoreProject'
				const data={projectName:archivedProject.name}
				dispatch(showToastNotification(toastType,data))
        return response
      })
      .catch((error) => {
        dispatch({ type: 'RESTORE_PROJECT_FAILURE', projectId })
        dispatch({ type: 'ADD_TO_ARCHIVED_PROJECTS', project: archivedProject })
        return error
      })
  }
}


export function updateProjectDefaultMotionStyle(projectId,style) {
	const requestBody={
		style:style
	}
	return (dispatch) =>{ 
		 return put(`/projects/${projectId}/motion-style`,requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				Mixpanel.track('update_project_motion_settings',{style:style})
				return response
			})
			.catch((error) => {
				return error
			})
		  }
}



export function updateProjectName(projectId,name) {
	const requestBody={name:name}
	return (dispatch) =>{ 
		return put(`/projects/${projectId}/name`,requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				return response
			})
			.catch((error) => {
				return error
			})
		}
}

export function updateProjectBackground(projectId,background) {
	const requestBody={
		background:background
	}
	return (dispatch) =>{ 
		return put(`/projects/${projectId}/background`,requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				return response
			})
			.catch((error) => {
				return error
			})
		}
}


export function updateProjectTimeline(projectId, timeline, duration) {
	const requestBody = {
		newTimeline: timeline,
		duration: duration
	}
	return (dispatch) => { 
		return put(`/projects/${projectId}/timeline`, requestBody)
			.then((response) => {
				dispatch({ type: 'UPDATE_PROJECT_SUCCESS', response })
				return response
			})
			.catch((error) => {
				if (error?.response?.status === 403) {
					// Dispatch custom event that Editor is listening for
					document.dispatchEvent(new CustomEvent('timeline-update-error', { detail: error }));
				}
				return error
			})
	}
}

export function createVoiceMatchForProject(projectId,captureIds) {
	const requestBody ={captureIds:captureIds}
	return (dispatch) =>{ 
		 return post(`/projects/${projectId}/voice-match`,requestBody)
			.then((response) => {
				const { requestId } = response;
	      dispatch({ 
	        type: 'VOICE_MATCH_REQUESTED', 
	        projectId,
	        requestId
	      });
				return response
			})
			.catch((error) => {
				return error
			})
	}
}


export function receiveVoiceMatch(projectId, requestId, sources,voiceId) {
  return (dispatch) => {
    dispatch({
      type: 'VOICE_MATCH_RECEIVED',
      projectId,
      requestId,
      sources,
      voiceId
    });
  };
}

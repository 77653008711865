// RecordingSessionWebcamLayoutUtils.js

import { webcamSizes, webcamPositions } from '../../utils/webcam/webcamConfigs';
import { calculateWebcamRectForSizeAndPosition } from '../../utils/webcam/calculateWebcamRectForSizeAndPosition';
import resizeWindowDeviceToFit from '../../three/utils/resizeWindowDeviceToFit';

const TIME_WINDOW_SIZE = 1; // seconds
const MOVEMENT_PENALTY = {
  SAME: 0,
  ADJACENT: 1,
  DIAGONAL: 2,
  SIZE_CHANGE: 1.5  // Add penalty for size changes
};

const OVERLAP_WEIGHT = 0.7;
const MOVEMENT_WEIGHT = 0.2;

const POSITION_PRIORITIES = {
    bottomRight: 2,
    bottomLeft: 1.5,
    topRight: 1,
    topLeft: 0
};

let sceneWidth = 1920
let sceneHeight = 1080

// if(state.aspectRatio === '16_10'){
//     sceneWidth = 1920
//     sceneHeight = 1241
// }

const MAX_PRIORITY = 2; // Highest priority value
const POSITION_PRIORITY_WEIGHT = 0.15; // Adjust this weight to control priority influence

export const calculateDefaultWebcamLayouts = async (duration, cursorData, windowBox = null, videoWindowPadding = 18) => {
    // Initialize layouts array that we'll return
    const defaultWebcamLayouts = [];
    
    if (!cursorData?.length) {
        return defaultWebcamLayouts;
    }

    if(windowBox){
        cursorData = getNormalizedCursorDataForWindow(cursorData, windowBox, videoWindowPadding);
    }

    const numWindows = Math.ceil(duration / TIME_WINDOW_SIZE);
    
    // Get all valid position/size combinations
    const validLayouts = getValidLayouts();
    
    // Calculate overlap penalties for each time window and layout
    const overlapScores = calculateOverlapScores(cursorData, validLayouts, numWindows);
    
    // Run dynamic programming to find optimal path
    const optimalPath = findOptimalPath(overlapScores, validLayouts, numWindows);
    
    // Convert optimal path to layout keyframes
    return convertPathToLayouts(optimalPath, TIME_WINDOW_SIZE, duration);
};

function getNormalizedCursorDataForWindow(cursorData, windowBox, videoWindowPadding) {
    const { x: windowX, y: windowY, width: windowWidth, height: windowHeight } = windowBox;

    // Calculate the centered position of the window
    const centeredX = (sceneWidth - (windowWidth * sceneWidth)) / 2;
    const centeredY = (sceneHeight - (windowHeight * sceneHeight)) / 2;

    // Calculate scaling factors using resizeWindowDeviceToFit
    const { meshWidth, meshHeight } = resizeWindowDeviceToFit(
        windowWidth * sceneWidth,
        windowHeight * sceneHeight,
        videoWindowPadding,
        sceneWidth,
        sceneHeight,
        2
    );

    const scaleX = meshWidth / (windowWidth * sceneWidth);
    const scaleY = meshHeight / (windowHeight * sceneHeight);

    return cursorData.map(pt => {
        // Convert normalized input coordinates (0-1) to scene coordinates
        const sceneX = pt.x * sceneWidth;
        const sceneY = pt.y * sceneHeight;

        // Get position relative to window's original position
        const relativeX = sceneX - (windowX * sceneWidth);
        const relativeY = sceneY - (windowY * sceneHeight);

        // Apply scaling and center translation
        const finalX = (relativeX * scaleX) + centeredX;
        const finalY = (relativeY * scaleY) + centeredY;

        // Normalize back to 0-1 range
        return {
            time: pt.time,
            x: finalX / sceneWidth,
            y: finalY / sceneHeight
        };
    });
}

function getValidLayouts() {
    const layouts = [];
    webcamSizes.forEach(size => {
        if (size.name !== 'xSmallSquare') {
            return;
        }   
        size.validPositions.forEach(position => {
            layouts.push({ size: size.name, position });
        });
    });
    return layouts;
}

function calculateOverlapScores(cursorData, layouts, numWindows) {
    const scores = Array(numWindows).fill().map(() => new Map());
    
    // Define scene dimensions and distance threshold
    const DISTANCE_THRESHOLD = 100; // pixels, adjust this value to control how far the influence extends
    
    layouts.forEach(layout => {
        for (let window = 0; window < numWindows; window++) {
            const windowStart = window * TIME_WINDOW_SIZE;
            const windowEnd = windowStart + TIME_WINDOW_SIZE;
            
            const windowCursorPoints = cursorData.filter(
                point => point.time >= windowStart && point.time < windowEnd
            );
            
            const rect = calculateWebcamRectForSizeAndPosition(
                layout.size,
                layout.position,
                sceneWidth,
                sceneHeight
            );
            
            let totalScore = 0;
            windowCursorPoints.forEach(point => {
                const cursorX = point.x * sceneWidth;
                const cursorY = point.y * sceneHeight;
                
                // Calculate distance to nearest edge/corner of webcam box
                const distance = getDistanceToRect(
                    { x: cursorX, y: cursorY },
                    rect
                );
                
                // Convert distance to a score between 0 and 1
                // Closer points have higher scores (worse for webcam placement)
                if (distance === 0) {
                    totalScore += 1; // Direct overlap
                } else if (distance < DISTANCE_THRESHOLD) {
                    totalScore += 1 - (distance / DISTANCE_THRESHOLD);
                }
            });
            
            const averageScore = totalScore / Math.max(1, windowCursorPoints.length);
            scores[window].set(layoutToKey(layout), averageScore);
        }
    });
    
    return scores;
}

function getDistanceToRect(point, rect) {
    const dx = Math.max(rect.x - point.x, 0, point.x - (rect.x + rect.width));
    const dy = Math.max(rect.y - point.y, 0, point.y - (rect.y + rect.height));
    return Math.sqrt(dx * dx + dy * dy);
}

function findOptimalPath(overlapScores, layouts, numWindows) {
    const dp = Array(numWindows).fill().map(() => new Map());
    const prev = Array(numWindows).fill().map(() => new Map());
    
    // Initialize first window
    layouts.forEach(layout => {
        const key = layoutToKey(layout);
        dp[0].set(key, overlapScores[0].get(key));
    });
    
    // Fill DP table
    for (let t = 1; t < numWindows; t++) {
        layouts.forEach(currentLayout => {
            const currentKey = layoutToKey(currentLayout);
            let minScore = Infinity;
            let bestPrev = null;
            
            layouts.forEach(prevLayout => {
                const prevKey = layoutToKey(prevLayout);
                const movementCost = calculateMovementPenalty(prevLayout, currentLayout);
                const score = dp[t-1].get(prevKey) + 
                            (overlapScores[t].get(currentKey) * OVERLAP_WEIGHT) +
                            (movementCost * MOVEMENT_WEIGHT);
                
                if (score < minScore) {
                    minScore = score;
                    bestPrev = prevKey;
                }
            });
            
            dp[t].set(currentKey, minScore);
            prev[t].set(currentKey, bestPrev);
        });
    }
    
    // Trace back to find optimal path
    return tracePath(dp, prev, layouts, numWindows);
}

function convertPathToLayouts(path, windowSize, duration) {
    if (path.length === 0) return [];
    
    const collapsedLayouts = [];
    let currentLayout = {
        id: 0,
        startTime: 0,
        duration: windowSize,
        size: path[0].size,
        position: path[0].position,
        endTime: windowSize
    };

    let nextId = 1;
    
    for (let i = 1; i < path.length; i++) {
        const nextLayout = path[i];
        
        // If the next layout has the same size and position, extend the current layout
        if (nextLayout.size === currentLayout.size && nextLayout.position === currentLayout.position) {
            currentLayout.duration += windowSize;
            currentLayout.endTime += windowSize;
        } else {
            // Add the current layout and start a new one
            collapsedLayouts.push(currentLayout);
            currentLayout = {
                id: nextId++,
                startTime: i * windowSize,
                duration: windowSize,
                endTime: i * windowSize + windowSize,
                size: nextLayout.size,
                position: nextLayout.position
            };
        }
    }
    
    // Add the last layout with adjusted duration to match clip end
    const finalDuration = duration - currentLayout.startTime;
    currentLayout.duration = finalDuration;
    currentLayout.endTime = currentLayout.startTime + finalDuration;
    collapsedLayouts.push(currentLayout);
    
    return collapsedLayouts;
}

// Helper functions
function layoutToKey(layout) {
    return `${layout.size}-${layout.position}`;
}

function calculateMovementPenalty(layout1, layout2) {
    let penalty = 0;
    
    // Position penalty
    if (layout1.position === layout2.position) {
        penalty += MOVEMENT_PENALTY.SAME;
    } else {
        const pos1 = webcamPositions.find(p => p.name === layout1.position);
        const pos2 = webcamPositions.find(p => p.name === layout2.position);
        
        // Check if positions are adjacent (share a side)
        penalty += pos1.altPositions.includes(pos2.name) 
            ? MOVEMENT_PENALTY.ADJACENT 
            : MOVEMENT_PENALTY.DIAGONAL;
    }
    
    // Add penalty for suboptimal position
    penalty += (MAX_PRIORITY - POSITION_PRIORITIES[layout2.position]) * POSITION_PRIORITY_WEIGHT;
    
    // Size penalty
    if (layout1.size !== layout2.size) {
        penalty += MOVEMENT_PENALTY.SIZE_CHANGE;
    }
    
    return penalty;
}

function tracePath(dp, prev, layouts, numWindows) {
    const path = [];
    let currentWindow = numWindows - 1;
    
    // Find the layout with minimum score in the last window
    let currentKey = Array.from(dp[currentWindow].entries())
        .reduce((a, b) => a[1] < b[1] ? a : b)[0];
    
    // Trace back
    while (currentWindow >= 0) {
        const layout = layouts.find(l => layoutToKey(l) === currentKey);
        path.unshift(layout);
        currentKey = prev[currentWindow].get(currentKey);
        currentWindow--;
    }
    
    return path;
}
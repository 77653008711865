import React, { useState, useMemo, useRef, useEffect } from 'react';
import Icon from '../../../misc/Icon';
import ReactPlayer from 'react-player/file';
import EditorGenerationsDialogVideoPlayerControls from './EditorGenerationsDialogVideoPlayerControls';
import Hls from 'hls.js';

function EditorGenerationsDialogVideoPlayer({activeGeneration,handleDownloadVideo,previewVideo,handleUpdatePreviewVideo}) {

  const muxPlaybackID = activeGeneration.mux_playback_id

  const playbackUrl = `https://stream.mux.com/${muxPlaybackID}.m3u8` 

  const posterTime = 2
  const posterWidth = 1920
  
  let posterImageURL = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=${posterWidth}&time=${posterTime}`
  
  const playerRef = useRef(null);
  const hlsRef = useRef(null);

  useEffect(() => {
    let hls;

    if (Hls.isSupported() && playerRef.current) {
      const video = playerRef.current.getInternalPlayer();
      
      hls = new Hls({
        
      });

      hlsRef.current = hls;
      hls.loadSource(playbackUrl);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {        
        hls.startLevel = hls.levels.length - 1;              
      });

      // Add quality change monitoring
      hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        const newLevel = hls.levels[data.level];        
      });

      
    }

    return () => {
      if (hls) {
        hls.destroy();
      }      
      if (playerRef.current) {
        const video = playerRef.current.getInternalPlayer();
        if (video) {
          video.src = "";
          video.load();
        }
      }
    };
  }, [playbackUrl, muxPlaybackID]);


  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [visualVolume, setVisualVolume] = useState(1);
  const [muted, setMuted] = useState(false);  
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0); // Between 0 and 1
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);  
  const [posterImageLoaded, setPosterImageLoaded] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);    
  };

  const handlePause = () => {
    setPlaying(false);    
  };

  const handleEnded = () => {
    setPlaying(false);
    // Reset progress if needed
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);
    }
  };

  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);    
  };

  const handleSeekMouseUp = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
    setSeeking(false);    
  };

  
  //

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    setVisualVolume(newVolume);
    setMuted(newVolume === 0);
  };

  const handleToggleMute = () => {
    setMuted(!muted);
    if (muted) {
      setVolume(visualVolume === 0 ? 1 : visualVolume);
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Space' && e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
        e.preventDefault();
        setPlaying(!playing);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [playing]);




  return (    
    <>
      <div className="editor-generationsDialog-topSpacer" />
      <div className="editor-generationsDialog-videoPreview">
        {!hasPlayed && progress == 0 && posterImageURL && (
          <div 
            className='editor-generationsDialog-videoPreview-posterImageContainer'
            style={{ opacity: posterImageLoaded ? 1 : 0 }}
          >
            <img 
              src={posterImageURL} 
              className='editor-generationsDialog-videoPreview-posterImage' 
              onLoad={() => setPosterImageLoaded(true)}            
            />
          </div>
        )}

        <ReactPlayer
          ref={playerRef}
          className='editor-generationsDialog-videoPreview-video'
          width='100%'
          height='100%'
          url={playbackUrl}
          playing={playing}        
          progressInterval={50}
          playbackRate={playbackRate}
          volume={seeking || muted ? 0 : volume}
          muted={seeking || muted}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          onDuration={handleDuration}
          onProgress={handleProgress}
          //onReady={handlePlayerReady}          
          controls={false}
        />

        <button onClick={() => setPlaying(!playing)} className='videoPlayer-bgPlayPauseBtn' />
    
      </div>
      
        <EditorGenerationsDialogVideoPlayerControls
          playbackId={muxPlaybackID}
          playing={playing}
          onPlayPauseClick={() => {
            setPlaying(!playing);            
          }}
          onVolumeChange={(e) => setVolume(parseFloat(e.target.value))}        
          progress={progress}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          duration={duration}          
          volume={volume}
          muted={muted}
          onVolumeChange={handleVolumeChange}
          onToggleMute={handleToggleMute}
          handleDownloadVideo={handleDownloadVideo}
          handleUpdatePreviewVideo={handleUpdatePreviewVideo}
          previewVideo={previewVideo}
          generation={activeGeneration}
        />      
    </>
  );
}

export default EditorGenerationsDialogVideoPlayer;
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import moment from 'moment';
import {formatDuration} from '../../../../utils/dateFormatters/formatDuration'
import * as ContextMenu from '@radix-ui/react-context-menu';

const formatDate = (timestamp) => {
  const date = moment(timestamp);
  const now = moment();
  const diffMinutes = now.diff(date, 'minutes');
  
  // If within last 10 minutes
  if (diffMinutes <= 10) {
    return 'Just now';
  } 
  // If same day
  else if (date.isSame(now, 'day')) {
    return `Today ${date.format('h:mmA')}`;
  } 
  // If yesterday
  else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return `Yesterday ${date.format('h:mmA')}`;
  } 
  // If within current week
  else if (date.isSame(now, 'week')) {
    return date.format('ddd D MMM h:mmA');
  } 
  // Otherwise show full date
  else {
    return date.format('D MMM YYYY h:mmA');
  }
};



const GenerationButton = forwardRef(({ 
  generation, 
  previewVideo,
  isActive, 
  onClick ,
  handleUpdatePreviewVideo
}, ref) => {
  const muxPlaybackID = generation.mux_playback_id;
  const thumbnailWidth = 400;
  const thumbnailURL = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=${thumbnailWidth}&time=1`;
  const isPreview = previewVideo && previewVideo.id==generation.id
  return (
    <ContextMenu.Root key={generation.mux_playback_id} modal={true}>
        <ContextMenu.Trigger asChild>
          <button
            ref={ref}
            data-active-state={isActive ? 'active' : 'inactive'}
            className="editor-generationsDialog-item"
            onClick={onClick}
          >
            <div className="editor-generationsDialog-item-previewContainer">
              <div className="editor-generationsDialog-item-preview">
                {/* <div className="editor-generationsDialog-item-preview-border"/> */}
                <img src={thumbnailURL} alt="Generation preview" />
              </div>
            </div>
            <div className="editor-generationsDialog-item-rightContainer">
              <div className="editor-generationsDialog-item-rightTopContainer">
                <div className="editor-generationsDialog-item-timestamp">
                  {formatDate(generation.updated_at)}
                </div>
                {/*}
                {isPreview && 
                <div className="editor-generationsDialog-item-previewTag">
                  Example
                </div>
                }
                */}
              </div>
              <div className="editor-generationsDialog-item-duration">
               {isPreview && `Collab Page Preview ·`} {formatDuration(generation.duration || 500)}
              </div>
            </div>
          </button>
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className='contextMenu contextMenu--generationsDialogList forceDarkTheme'>          
          {!isPreview &&
          <ContextMenu.Item onSelect={() => handleUpdatePreviewVideo(generation)}>            
            Make Collab Page Preview
          </ContextMenu.Item>
        }
        {isPreview &&
          <ContextMenu.Item onSelect={() => handleUpdatePreviewVideo(null)}>            
            Remove as Collab Page Preview
          </ContextMenu.Item>
        }
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>


    
  );
});



function EditorGenerationsDialogList({ generations, activeGeneration, onGenerationClick,handleUpdatePreviewVideo,previewVideo }) {
  const activeItemRef = useRef(null);

  const handleKeyDown = (e) => {
    const currentIndex = generations.findIndex(gen => gen.id === activeGeneration.id);    
    if (e.key === 'ArrowUp' && currentIndex > 0) {
      e.preventDefault();
      onGenerationClick(generations[currentIndex - 1]);
    } else if (e.key === 'ArrowDown' && currentIndex < generations.length - 1) {
      e.preventDefault();
      onGenerationClick(generations[currentIndex + 1]);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [activeGeneration, generations]);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest'
      });
    }
  }, [activeGeneration]);

  return (    
    <div className="editor-generationsDialog-list">
      {generations.map((generation) => (
        <GenerationButton
          key={generation.id}
          ref={generation.id === activeGeneration ? activeItemRef : null}
          generation={generation}
          isActive={generation.id === activeGeneration.id}
          onClick={() => onGenerationClick(generation)}
          previewVideo={previewVideo}
          handleUpdatePreviewVideo={handleUpdatePreviewVideo}
        />
      ))}
    </div>    
  );
}

export default EditorGenerationsDialogList;
import React, {useState} from 'react'
import Icon from '../../../misc/Icon';
import moment from 'moment';
import EditorGenerationsDialogVideoPlayerSeekBar from './EditorGenerationsDialogVideoPlayerSeekBar'
import {formatDuration} from '../../../../utils/dateFormatters/formatDuration'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

function VideoDuration({ seconds }) {
  const formattedDuration = formatDuration(seconds)
  return (
    <time dateTime={`P${Math.floor(seconds)}S`}>
      {formattedDuration}
    </time>
  );
}

function pad(number) {
  return number.toString().padStart(2, '0');
}




function VideoPlayerControls({
    playbackId,
    playing,
    onPlayPauseClick,    
    progress,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    duration,
    showCaptions,
    toggleShowCaptions,
    showControls,
    volume,
    muted,
    onVolumeChange,
    onToggleMute,
    handleToggleFullscreen,
    isFullscreen,
    playbackRate,
    setPlaybackRate,
    showTimelinePreview,
    handleDownloadVideo,
    previewVideo,
    handleUpdatePreviewVideo,
    generation
  }) {


  const isPreview = previewVideo && previewVideo.id==generation.id

  return (
    <>

    <div className='editor-generationsDialog-controlsContainer'>

      <div className='editor-generationsDialog-controls'>  
            
        
        <EditorGenerationsDialogVideoPlayerSeekBar 
          playbackId={playbackId}
          playing={playing}
          progress={progress}
          onSeek={onSeek}
          onSeekMouseDown={onSeekMouseDown}
          onSeekMouseUp={onSeekMouseUp}
          duration={duration}              
        /> 
               
         
        <div className='editor-generationsDialog-controls-btnBar'>
          <button onClick={onPlayPauseClick} className='editor-generationsDialog-controls-playPauseBtn'>
            {playing && 
              <Icon name='playbackPause' />
            }
            {!playing && 
              <Icon name='playbackPlay' />
            }
          </button>

          <div className='editor-generationsDialog-controls-timestamps'>
            <div className='editor-generationsDialog-controls-timestamps-timestamp videoPlayer-controls-timestamps-timestamp--current'>              
              <VideoDuration seconds={progress * duration} />
            </div>
            <div className='editor-generationsDialog-controls-timestamps-timestamp videoPlayer-controls-timestamps-timestamp--total'>
              <VideoDuration seconds={duration} />
            </div>
          </div>

          <div className='editor-generationsDialog-controls-hSpacer' />

          <button onClick={handleDownloadVideo} className='editor-generationsDialog-controls-btn editor-generationsDialog-controls-btn--download'>
          	<Icon name='upArrowCircleLayered' />
          </button>

          <DropdownMenu.Root modal={true}> 
          <DropdownMenu.Trigger asChild>
            <button className='editor-generationsDialog-controls-btn editor-generationsDialog-controls-btn--download'>
              <Icon name='ellipses' />
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>            
          <DropdownMenu.Content             
            align="end"
            alignOffset={-30}
            sideOffset={-2}
            side="top"
            className='dropdownMenu dropdownMenu--generationsDialog forceDarkTheme'
          >             

            {!isPreview &&
              <DropdownMenu.Item onSelect={() => handleUpdatePreviewVideo(generation)}>            
                Make Collab Page Preview
              </DropdownMenu.Item>
            }
            {isPreview &&
              <DropdownMenu.Item onSelect={() => handleUpdatePreviewVideo(null)}>            
                Remove as Collab Page Preview
              </DropdownMenu.Item>
            }
          </DropdownMenu.Content>   
          </DropdownMenu.Portal>       
        </DropdownMenu.Root>

        </div>


      </div>
            
    </div>    

    

    </>
  );
}

export default VideoPlayerControls;
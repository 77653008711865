import React, { useState,useEffect,useRef,useMemo,useCallback } from 'react';
import EditorTimelineSceneBounds from './sceneBounds/EditorTimelineSceneBounds';
import EditorTimelineProgressMarker from '../markers/EditorTimelineProgressMarker'
import EditorTimelineSkipSegmentCursor from '../markers/EditorTimelineSkipSegmentCursor'
import EditorTimelineAddSkipSegmentMode from '../scenes/EditorTimelineAddSkipSegmentMode'
import EditorTimelineSnapLines from './EditorTimelineSnapLines'
import EditorTimelineDropLines from './EditorTimelineDropLines'
import SceneTrack from './SceneTrack'
import filter from 'lodash/filter'
import EditorTimelineVideoResizeMode from '../scenes/EditorTimelineVideoResizeMode'

import find from 'lodash/find'
import get from 'lodash/get'

const sceneGap = 34 //px
const DEV_MODE =false
const tracks=[
  {name:'zoom',zIndex:2},
  {name:'slides',zIndex:1},
  {name:'video',zIndex:0},
  {name:'audio',zIndex:-1}
]

let TRACK_HEIGHT = DEV_MODE?100:40
let CLIP_HEIGHT = 32
const ZOOM_CLIP_HEIGHT = 22

const getClipVersion = (clip) => {
  const relevantFields = [
    'id',
    'startTime',
    'duration',
    'minDuration',
    'metadata.playbackRate',
    'metadata.text',
    'metadata.transcript',
    'parentWebcamClip',
    'requiresUpdate',
    'sceneId',
    'recordingChunks'
  ];
  return relevantFields.map(field => `${field}:${get(clip, field, '')}`).join('|');
};


const useMemoizedClipsByTrack = (clips) => {

  const prevClipsRef = useRef(clips);

  const getClipsForTrack = (zIndex) => {
    return filter(clips, { zIndex });
  };

  const getTrackVersion = (zIndex) => {
    const trackClips = getClipsForTrack(zIndex);
    return trackClips.map(getClipVersion).join('||');
  };

  const zoomClipsVersion = useMemo(() => getTrackVersion(2), [clips]);
  const slideClipsVersion = useMemo(() => getTrackVersion(1), [clips]);
  const videoClipsVersion = useMemo(() => getTrackVersion(0), [clips]);
  const audioClipsVersion = useMemo(() => getTrackVersion(-1), [clips]);
 
  const memoizedZoomClips = useMemo(() => getClipsForTrack(2), [zoomClipsVersion]);
  const memoizedSlideClips = useMemo(() => getClipsForTrack(1), [slideClipsVersion]);
  const memoizedVideoClips = useMemo(() => getClipsForTrack(0), [videoClipsVersion]);
  const memoizedAudioClips = useMemo(() => getClipsForTrack(-1), [audioClipsVersion]);

  useEffect(() => {
    prevClipsRef.current = clips;
  }, [clips]);

  return useMemo(() => [
    memoizedZoomClips,
    memoizedSlideClips,
    memoizedVideoClips,
    memoizedAudioClips
  ], [memoizedZoomClips, memoizedSlideClips, memoizedVideoClips, memoizedAudioClips]);
};


const ScenesTimeline = React.memo((props) => {
  const {
    pixelsPerSec,
    scenes,
    clips,
    onDragStart,
    onDragEnd,
    isDragging,
    dragClipId,
    handleDragClip,
    isDnDMode,
    onResizeStart,
    onResizeStop,
    onResize,
    currentTime,
    duration,
    maxTimelineDurationSeconds,
    handleSeek,
    changeVideoClipPlaybackRate,
    handleSetActivePanelClip,
    timelineCmdIsDown,
    preSkipResizeCurrentTime,
    isResizingSkipSegment,
    scrollRef,
    isPlaying,
    readOnlyMode,
    preAddSkipCurrentTime,
    isAddSkipSegmentModeActive,
    toggleAddSkipSegmentMode,
    addSkipSegmentModeClip,
    preVideoResizeSnapshot,
    isVideoResizeModeActive,
    handleTimelineDoubleClick
    
  } =props


  const [showDraggingCursor, setShowDraggingCursor] = useState(false);

  const [dragDirection, setDragDirection] = useState(null); //left or right
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState(null);


  const scrollToStart = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  const getScrollRef = () => {
    return scrollRef.current;
  };

  const scrollToPosition = (xPosition) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = xPosition;      
    }
  };

  const scrollToEnsureVisible = (markerPosition) => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const buffer = 70; // Buffer of 200 pixels from the right edge
    const containerWidth = scrollContainer.offsetWidth;
    const currentScroll = scrollContainer.scrollLeft;
    const visibleEnd = currentScroll + containerWidth;

    if (markerPosition > visibleEnd - buffer) {
      const newScrollPosition = markerPosition - containerWidth + buffer;
      scrollContainer.scrollLeft = newScrollPosition;
    }
  };

  const [isDraggingSegment, setIsDraggingSegment] = useState(false);  


  const forceGrabbingCursor = showDraggingCursor || isResizing || isDraggingSegment
  const forceGrabCursor = isDnDMode && !showDraggingCursor // to do fix this

  const totalPixelWidth = (maxTimelineDurationSeconds * pixelsPerSec) + (scenes.length * sceneGap)
  const draggedClip = find(clips,{id:dragClipId})


  let playheadCurrentTime = currentTime 
  if(isResizingSkipSegment){
    playheadCurrentTime = preSkipResizeCurrentTime
  }
  if(isAddSkipSegmentModeActive){
    playheadCurrentTime = preAddSkipCurrentTime
  }

  const memoizedClipsByTrack = useMemoizedClipsByTrack(clips);
//  console.log('render')
  return (
    <div data-read-only={readOnlyMode ? true : false} data-command-down-state={timelineCmdIsDown ? 'true' : 'false'} className={"editor-timelineContainer" + (forceGrabbingCursor ? ' editor-timelineContainer--showGrabbingCursor ' : '') + (forceGrabCursor ? ' editor-timelineContainer--showGrabCursor ' : '')}>
      <div id='timeline-scroll-div' ref={scrollRef} className={'editor-timeline ' + (DEV_MODE ? 'editor-timeline--devMode' : '')}>
        

      {!isAddSkipSegmentModeActive && !isVideoResizeModeActive &&
        <EditorTimelineProgressMarker
          currentTime={currentTime}
          pixelsPerSec={pixelsPerSec}
          scenes={scenes}
          sceneGap={sceneGap}
          timelineDurationSeconds={duration}
          handleSeek={handleSeek}
          scrollToEnsureVisible={scrollToEnsureVisible}
          getScrollRef={getScrollRef}
          maxTimelineDurationSeconds={maxTimelineDurationSeconds}      
          splitScene={props.splitScene}  
          playheadCurrentTime={playheadCurrentTime}
          scrollRef={scrollRef}
          isPlaying={isPlaying}
          isResizingSkipSegment={props.isResizingSkipSegment}
          setIsDraggingProgressMarker={props.setIsDraggingProgressMarker}
          handleTimelineDoubleClick={handleTimelineDoubleClick}
        />
      }


        <EditorTimelineSkipSegmentCursor 
          currentTime={currentTime}
          pixelsPerSec={pixelsPerSec}
          scenes={scenes}
          sceneGap={sceneGap}
          timelineDurationSeconds={duration}
          maxTimelineDurationSeconds={maxTimelineDurationSeconds}
          isResizingSkipSegment={props.isResizingSkipSegment}
        />     

        {isAddSkipSegmentModeActive && 
          <EditorTimelineAddSkipSegmentMode
            currentTime={currentTime}
            pixelsPerSec={pixelsPerSec}
            scenes={scenes}
            sceneGap={sceneGap}
            timelineDurationSeconds={duration}
            maxTimelineDurationSeconds={maxTimelineDurationSeconds}
            isResizingSkipSegment={props.isResizingSkipSegment}
            handleSeek={handleSeek}
            scrollToEnsureVisible={scrollToEnsureVisible}
            getScrollRef={getScrollRef}
            isAddSkipSegmentModeActive={isAddSkipSegmentModeActive}
            toggleAddSkipSegmentMode={toggleAddSkipSegmentMode}
            preAddSkipCurrentTime={preAddSkipCurrentTime}
            addSkipSegmentModeClip={addSkipSegmentModeClip}
            handleAddSkipSegmentFromSkipSegmentMode={props.handleAddSkipSegmentFromSkipSegmentMode}
          />     
        }

        {isVideoResizeModeActive && 
          <EditorTimelineVideoResizeMode
            currentTime={currentTime}
            pixelsPerSec={pixelsPerSec}
            scenes={scenes}
            sceneGap={sceneGap}
            timelineDurationSeconds={duration}
            maxTimelineDurationSeconds={maxTimelineDurationSeconds}
            handleSeek={handleSeek}
            scrollToEnsureVisible={scrollToEnsureVisible}
            getScrollRef={getScrollRef}
            preVideoResizeSnapshot={preVideoResizeSnapshot}

          />     
        }




{/*
        <EditorTimelineAltCursor 
          currentTime={currentTime}
          pixelsPerSec={pixelsPerSec}
          scenes={scenes}
          sceneGap={sceneGap}
          timelineDurationSeconds={duration}
          handleSeek={handleSeek}
          maxTimelineDurationSeconds={maxTimelineDurationSeconds}
          timelineAltIsDown={timelineAltIsDown}
          preTimelineAltCurrentTime={preTimelineAltCurrentTime}
          onTimelineAltKeyDown={onTimelineAltKeyDown}
          onTimelineAltKeyUp={onTimelineAltKeyUp}
        />     */}
        
     
        <div style={{width: `${totalPixelWidth}px`}}  className='editor-timeline-innerContainer'>        
          <EditorTimelineSnapLines 
            clips={clips}
            pixelsPerSec={pixelsPerSec}
            isDragging={isDragging}
            dragClipId={dragClipId}
            sceneGap={sceneGap}
            dndMode={isDnDMode}
            scenes={scenes}
            dragDirection={dragDirection}
            resizeDirection={resizeDirection}
          />

          {isDragging && isDnDMode  && draggedClip &&
            <EditorTimelineDropLines 
              pixelsPerSec={pixelsPerSec}
              sceneGap={sceneGap}
              dndMode={isDnDMode}
              scenes={scenes}
              dropLines={props.dropLines}
              dragZIndex={draggedClip.zIndex}
            />
          }

          {tracks.map((track,i)=>{
            const zIndex = track.zIndex
            return(
              <SceneTrack 
                key={`scene_track_${i}`}
                clips={memoizedClipsByTrack[i]}
                isDragging={isDragging}
                sceneGap={sceneGap}
                isDnDMode={isDnDMode}
                pixelsPerSec={pixelsPerSec}
                dragClipId={dragClipId}
                trackIndex={i}
                TRACK_HEIGHT={TRACK_HEIGHT}
                CLIP_HEIGHT={CLIP_HEIGHT}
                ZOOM_CLIP_HEIGHT={ZOOM_CLIP_HEIGHT}
                devMode={DEV_MODE}
                duplicateClip={props.duplicateClip}
                deleteClip={props.deleteClip}
                copyClip={props.copyClip}
                cutClip={props.cutClip}
                scrollRef={scrollRef}
                changeVideoClipPlaybackRate={changeVideoClipPlaybackRate}
                handleChangeSegmentPlaybackRate={props.handleChangeFreezeSegmentPlaybackRate}
                addFreezeFrame={props.addFreezeFrame}
                handleSetActivePanelClip={handleSetActivePanelClip}
                activePanelClip={props.activePanelClip}
                removeFreeze={props.removeFreeze}
                removeSkip={props.removeSkip}
                splitRecordingClip={props.splitRecordingClip}
                scenes={scenes}
                handleSeek={handleSeek}
                isResizing={isResizing}
                addSkipSegment={props.addSkipSegment}
                toggleSkipSegment={props.toggleSkipSegment}
                handleChangeSkipSegmentDuration={props.handleChangeSkipSegmentDuration}
                handleResizeSkipSegmentStart={props.handleResizeSkipSegmentStart}
                handleResizeSkipSegmentStop={props.handleResizeSkipSegmentStop}
                setIsDraggingSegment={setIsDraggingSegment}
                resizingSkipSegmentHandle={props.resizingSkipSegmentHandle}
                onDragStart={onDragStart}
                setShowDraggingCursor={setShowDraggingCursor}
                setDragDirection = {setDragDirection}
                handleDragClip={handleDragClip}
                onDragEnd={onDragEnd}
                // setDropLines={setDropLines}
                dropLines={props.dropLines}
                onResizeStart={onResizeStart}
                setIsResizing={setIsResizing}
                onResizeStop={onResizeStop}
                onResize={onResize}
                calculateAndSetDropLines={props.calculateAndSetDropLines}
                setResizeDirection={setResizeDirection}
                resizeDirection={resizeDirection}
                unlinkScreencast={props.unlinkScreencast}
                updateClipMetadata={props.updateClipMetadata}
                toggleAddSkipSegmentMode={props.toggleAddSkipSegmentMode}
                showWebcamPhasesForClipId={props.showWebcamPhasesForClipId}
                setShowWebcamPhasesForClipId={props.setShowWebcamPhasesForClipId}
                preVideoResizeSnapshot={preVideoResizeSnapshot}
                isVideoResizeModeActive={isVideoResizeModeActive}
                handleTrimToClipEdge={props.handleTrimToClipEdge}
              />
            )
          })}

          {scenes && scenes.map((scene,i)=> (
            <EditorTimelineSceneBounds
              key={`scene_bounds_${i}`}
              sceneGap={sceneGap}
              scene={scene}
              pixelsPerSec={pixelsPerSec} 
              devMode={DEV_MODE}
              TRACK_HEIGHT={TRACK_HEIGHT}
              CLIP_HEIGHT={CLIP_HEIGHT}
              sceneCount={scenes.length}
              maxTimelineDurationSeconds={maxTimelineDurationSeconds}
              mergeScene={props.mergeScene}
              setShowDraggingCursor={setShowDraggingCursor}
              handleSeek={handleSeek}
              scrollRef={scrollRef}
              splitScene={props.splitScene}
              addSceneAfterScene={props.addSceneAfterScene}
            />
          ))}

          <button onClick={()=>handleSeek(0)} className='editor-timeline-leftEdgeBtn' />        

        </div>
      </div>
    </div>
  );
},(prevProps,nextProps)=>{
  //return false
  return (nextProps.isSlideEditorDragResizing || nextProps.isResizingWebcam || nextProps.isDraggingWebcam);
})

export default ScenesTimeline;

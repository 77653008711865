import React from 'react';
import { Rnd } from 'react-rnd';
import {isLightBackground} from '../../../utils/brands/isLightBackground'


const handleInset = 30
const handleWidth = 34

const leftHandleStyle = {              
  width: `${handleWidth + handleInset}px`,
  top: `${handleInset}px`,
  height: `calc(100% - ${handleInset * 2}px)`,
  left: `${-handleWidth}px`,  
  cursor: 'grab',    
}

const rightHandleStyle = {              
  width: `${handleWidth + handleInset}px`,
  top: `${handleInset}px`,
  height: `calc(100% - ${handleInset * 2}px)`,
  right: `${-handleWidth}px`,  
  cursor: 'grab',      
}

const topHandleStyle = {              
  width: `calc(100% - ${handleInset * 2}px)`,   
  height: `${handleWidth + handleInset}px`,
  top: `${-handleWidth}px`,
  left: `${handleInset}px`,
  cursor: 'grab',     
}

const bottomHandleStyle = {              
  width: `calc(100% - ${handleInset * 2}px)`,  
  height: `${handleWidth + handleInset}px`,
  bottom: `${-handleWidth}px`,
  left: `${handleInset}px`,
  cursor: 'grab',      
}

const bottomRightHandleStyle = {              
  height: `${handleWidth + handleInset}px`,
  width: `${handleWidth + handleInset}px`,
  bottom: `${-handleWidth}px`,
  right: `${-handleWidth}px`,    
  cursor: 'grab',      
}

const bottomLeftHandleStyle = {              
  height: `${handleWidth + handleInset}px`,
  width: `${handleWidth + handleInset}px`,
  bottom: `${-handleWidth}px`,
  left: `${-handleWidth}px`,    
  cursor: 'grab',      
}

const topRightHandleStyle = {              
  height: `${handleWidth + handleInset}px`,
  width: `${handleWidth + handleInset}px`,
  top: `${-handleWidth}px`,
  right: `${-handleWidth}px`,    
  cursor: 'grab',  
 
}

const topLeftHandleStyle = {              
  height: `${handleWidth + handleInset}px`,
  width: `${handleWidth + handleInset}px`,
  top: `${-handleWidth}px`,
  left: `${-handleWidth}px`,    
  cursor: 'grab',      
}

const EditorCanvasResizeMediaUIHandle = ({side}) => {

  let containerStyle
  if(side === 'top'){
    containerStyle = topHandleStyle
  }
  if(side === 'left'){
    containerStyle = leftHandleStyle
  }
  if(side === 'bottom'){
    containerStyle = bottomHandleStyle
  }
  if(side === 'right'){
    containerStyle = rightHandleStyle
  }

  return (
   
    <div style={containerStyle} className={'editor-canvasMediaUI--resize-resizeBoxHandleContainer ' + (side ? ` editor-canvasMediaUI--resize-resizeBoxHandleContainer--side--${side} ` : '')}>
      <div className='editor-canvasMediaUI--resize-resizeBoxHandle editor-canvasMediaUI--resize-resizeBoxHandle' />
    </div>

  );
};



const EditorCanvasResizeMediaUI = ({
    canvasWidth,
    canvasHeight,
    clip,
    mediaType,
    updateClipDisplayWidth,
    originalMediaWidth,
    originalMediaHeight,
    isDragResizingMedia,
    startDragResizingMedia,
    stopDragResizingMedia,
    projectBackgroundId,
    sceneWidth,
    sceneHeight
  }) => {
  
  const imageAspectRatio = originalMediaWidth / originalMediaHeight;
  
  const clipId = clip.id;
  
  const displayWidth = clip.metadata.displayWidth;
  const displayHeight = displayWidth / imageAspectRatio;
  const displayScalar = canvasWidth / sceneWidth;
  
  const scaledWidth = displayWidth * displayScalar;
  const scaledHeight = displayHeight * displayScalar;

  const maxPadding = 0
  const maxDisplayWidth = sceneWidth - maxPadding - maxPadding
  const maxDisplayHeight = sceneHeight - maxPadding - maxPadding

  const maxAllowedWidth = Math.min(maxDisplayWidth, maxDisplayHeight * imageAspectRatio);  
  const maxAllowedHeight = maxAllowedWidth / imageAspectRatio

  const minDisplayWidth = 80;
  const minDisplayHeight = 80;

  // Ensure that minimum width and height are respected in terms of the original scale
  const minAllowedWidth = Math.max(minDisplayWidth, minDisplayHeight * imageAspectRatio);
  const minAllowedHeight = minAllowedWidth / imageAspectRatio;


  const handleResizeStart = () => {
    startDragResizingMedia(); // Call the function when resize starts
  };

  const handleResizeStop = () => {
    stopDragResizingMedia(); // Call the function when resize stops
  };

   

    const handleResize = (e, direction, ref, delta) => {
        // Double the delta to account for centering
        const deltaWidth = delta.width
        const deltaHeight = delta.height

        // Calculate the new width and height based on the delta
        let newWidth = (ref.offsetWidth + deltaWidth) / displayScalar;
        let newHeight = (ref.offsetHeight + deltaHeight) / displayScalar;

        // Constrain the new dimensions within the minimum and maximum bounds
        newWidth = Math.max(Math.min(newWidth, maxAllowedWidth), minAllowedWidth);
        newHeight = Math.max(Math.min(newHeight, maxAllowedHeight), minAllowedHeight);

        // Update the display width based on the new width
        // Make sure the new height doesn't violate the aspect ratio
        if (newWidth / imageAspectRatio <= newHeight) {
            updateClipDisplayWidth(clipId, newWidth);
        } else {
            updateClipDisplayWidth(clipId, newHeight * imageAspectRatio);
        }
    };




  const backgroundId = clip.metadata.backgroundId || projectBackgroundId

  const isLightBG = isLightBackground(backgroundId)
  // console.log(`is light----->>>>> ${isLight}`)


  return (
    <div
      data-state={isDragResizingMedia ? 'dragging' : 'notDragging'}
      className={'editor-canvasMediaUI editor-canvasMediaUI--resize ' + (isLightBG ? ' editor-canvasMediaUI--lightBG ' : ' editor-canvasMediaUI--darkBG ')}
      style={{ width: canvasWidth, height: canvasHeight, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div
        style={{ width: `${scaledWidth}px`, height: `${scaledHeight}px` }}
        className='editor-canvasMediaUI--resize-resizeBoxContainer'
      >        
        <Rnd
          size={{
            width: scaledWidth,
            height: scaledHeight
          }}
          position={{
            x: 0,
            y: 0,
          }}
          bounds={'#canvasMediaUIResizeGuide'}
          onResizeStart={handleResizeStart}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          enableResizing={{
            top: true,
            right: true,
            bottom: true,
            left: true,
            topRight: true,
            bottomRight: true,
            bottomLeft: true,
            topLeft: true
          }}
          resizeHandleStyles={{
            left: leftHandleStyle,
            right: rightHandleStyle,
            top: topHandleStyle,
            bottom: bottomHandleStyle,
            topRight: topRightHandleStyle,
            topLeft: topLeftHandleStyle,
            bottomLeft: bottomLeftHandleStyle,
            bottomRight: bottomRightHandleStyle,
          }}
          disableDragging
          lockAspectRatio={true}
          className='editor-canvasMediaUI--resize-resizeBox'
        />

        <EditorCanvasResizeMediaUIHandle side='top' />
        <EditorCanvasResizeMediaUIHandle side='left' />
        <EditorCanvasResizeMediaUIHandle side='right' />
        <EditorCanvasResizeMediaUIHandle side='bottom' />
        
      </div>
    </div>
  );
};

export default EditorCanvasResizeMediaUI;

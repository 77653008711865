import React, { useState, useRef, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useSelector } from 'react-redux';
import {duplicateProject} from '../../../../utils/projectUtils/duplicateProject'
import {archiveProject} from '../../../../utils/projectUtils/archiveProject'
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
import Icon from '../../../misc/Icon'

const TranscriptPanelTitleBtn = ({ 
  projectName,
  projectId,
  scrollPosition,  
  handleChangeSubtitlesType,
  subtitlesType,
  hideTranscriptPanel,
  handleGenerateSRTFile,
  hideInactiveCursor,
  toggleHideInactiveCursor,
  aspectRatio,
  changeAspectRatio
}) => {

  const history = useHistory()

  // const [title, setTitle] = useState(projectName);
  const [mainPopoverOpen, setMainPopoverOpen] = useState(false);
  const [inputPopoverOpen, setInputPopoverOpen] = useState(false);
  const inputRef = useRef(null);

  // const [aspectRatio, setAspectRatio] = useState(aspectRatio)

  const project = useSelector(state => find(state.projects, { id: projectId }));
  const isDraft = project && project.is_private

  // console.log('projectId', projectId)
  // console.log('project', project)
  // console.log('isDraft', isDraft)

  useEffect(() => {
    setMainPopoverOpen(false);
    setInputPopoverOpen(false);
  }, [scrollPosition]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleInputBlur = () => {
    setInputPopoverOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    } else if (e.key === 'Escape') {
     // setTitle(projectName);
      setInputPopoverOpen(false);
    }
  };

  const handleSingleClick = (e) => {
    e.stopPropagation(); // Prevent the click from triggering onInteractOutside
    if (!inputPopoverOpen) {
      setMainPopoverOpen(prev => !prev);
    }
  };


  const handleDoubleClick = (e) => {
    e.preventDefault();
    setMainPopoverOpen(false);
    setInputPopoverOpen(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  };

  const handleRenameClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMainPopoverOpen(false);
    setInputPopoverOpen(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  };


  const preventBlur = (e) => {
    e.preventDefault();
  };


  const handleDuplicateProject = async () => {
    const isDraft = true;
    try {
      const newProjectId = await duplicateProject(projectId, isDraft);
      setMainPopoverOpen(false);
      console.log('Navigating to new project:', newProjectId);
      history.push(`/project/${newProjectId}`);
    } catch (error) {
      console.error('Failed to duplicate project:', error);
    }
  };

  const handleArchiveProject = (e) => {
    e.stopPropagation();
    archiveProject(projectId,history)
  };

  const handleCaptionStyleChange = (style, event) => {
    let newSubtitlesType;
    if (style === 'Standard') {
      newSubtitlesType = 'standard';
    } else if (style === 'Pop') {
      newSubtitlesType = 'captions';
    }
    if (newSubtitlesType === subtitlesType) {
      newSubtitlesType = null;
    }
    handleChangeSubtitlesType(newSubtitlesType);    
  };

  const isButtonElement = (element) => {
  return element.closest('.editor-transcriptPanel-titleBtn') !== null;
};

  return (
    
    <>
      <Popover.Root open={inputPopoverOpen} modal={false}>
        <Popover.Trigger asChild>
          <button
            className="editor-transcriptPanel-titleBtn"
            onClick={handleSingleClick}
            //onDoubleClick={handleDoubleClick} {/* ADD LATER */}
            data-visibility-state={inputPopoverOpen ? 'hidden' : 'visible'}
            data-main-modal-state={mainPopoverOpen ? 'open' : 'closed'}
          >
            <div className="editor-transcriptPanel-titleBtn-label">
              {projectName}
            </div>
            {isDraft && (
              <div className="editor-transcriptPanel-titleBtn-draft">
                Draft
              </div>
            )}
            <div className='editor-transcriptPanel-titleBtn-iconContainer'>
              <Icon name='ellipses' />
            </div>
          </button>
        </Popover.Trigger>

        <Popover.Content
          align="start"
          side="bottom"
          sideOffset={-30}
          className="editor-transcriptPanel-header-titleInput-wrapper"
          onInteractOutside={handleInputBlur}
          onEscapeKeyDown={() => setInputPopoverOpen(false)}
        >
          <input
            ref={inputRef}
            className="editor-transcriptPanel-header-titleInput"
            value={projectName}
            onChange={handleTitleChange}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
          />
        </Popover.Content>
      </Popover.Root>

      {/* Main Menu Popover */}
      <Popover.Root open={mainPopoverOpen} modal={false}>
        <Popover.Trigger asChild>
          <div className="editor-transcriptPanel-header-popoverTrigger" />
        </Popover.Trigger>

        <Popover.Content
          align="start"
          alignOffset={8}
          side="bottom"
          sideOffset={4}
          className="miniPopoverWrapper miniPopoverWrapper--transcriptHeader"
          onInteractOutside={(e) => {
            if (!isButtonElement(e.target)) {
              setMainPopoverOpen(false);
            }
          }}
          onEscapeKeyDown={() => setMainPopoverOpen(false)}
        >
          <div className="miniPopover miniPopover--transcriptHeader forceDarkTheme">
            {/*} ADD LATER 
            <button
              className="miniPopover-item"
              onClick={handleRenameClick}
            >
              Rename...
            </button>
            */}

            <button
              className="miniPopover-item"
              onClick={handleDuplicateProject}
              onMouseDown={preventBlur}                                  
            >
              Make a Copy
            </button>

            <div className="popoverMenu-separator" />

            <div className='dropdownMenu-subHeader'>
              Add Captions
            </div>

            <div
              className="miniPopover-item miniPopover-item--captions"
              data-captions-type="pop"
              data-captions-active={subtitlesType === 'captions'}
              onClick={(e) => handleCaptionStyleChange('Pop', e)}
              onMouseDown={preventBlur}
            >
              Pop
            </div>

            <div
              className="miniPopover-item miniPopover-item--captions"
              data-captions-type="standard"
              data-captions-active={subtitlesType === 'standard'}
              onClick={(e) => handleCaptionStyleChange('Standard', e)}
              onMouseDown={preventBlur}
            >
              Standard Subs
            </div>

            <div className="popoverMenu-separator" />                

            <div className='dropdownMenu-subHeader'>
              Video Size
            </div>

            <div
              className={'miniPopover-item ' + (aspectRatio === '16_9' ? ' miniPopover-item--active ' : '' )}              
              onClick={() => changeAspectRatio('16_9')}
              onMouseDown={preventBlur}
            >
              Widescreen 16:9
            </div>

            <div
              className={'miniPopover-item ' + (aspectRatio === '16_10' ? ' miniPopover-item--active ' : '' )}
              onClick={() => changeAspectRatio('16_10')}
              onMouseDown={preventBlur}
            >
              Widescreen 16:10
            </div>

            <div className="popoverMenu-separator" />



            <button
              className="miniPopover-item"
              onClick={() => {
                handleGenerateSRTFile();
                setMainPopoverOpen(false);
              }}
              onMouseDown={preventBlur}
            >                  
              Download SRT...
            </button>

            {/*}
            <button
              className="miniPopover-item"
              onClick={() => {
                hideTranscriptPanel();
                setMainPopoverOpen(false);
              }}
              onMouseDown={preventBlur}
            >
              Hide Transcript Panel
            </button>
            */}

            <div className="popoverMenu-separator" />

            <button
              className="miniPopover-item"
              onClick={toggleHideInactiveCursor}
              onMouseDown={preventBlur}
            >
              {hideInactiveCursor ? 'Turn off cursor hiding' : 'Turn on cursor hiding'}
            </button>
            
            <div className="popoverMenu-separator" />

            <button
              className="miniPopover-item miniPopover-item--light"
              onClick={handleArchiveProject}
              onMouseDown={preventBlur}
            >
              Delete Project
            </button>

          </div>
        </Popover.Content>
      </Popover.Root>
    </>    
  );
};

export default TranscriptPanelTitleBtn;
import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import ReactSlider from 'react-slider';

const EditorDetailPanelSlideAnimationPanelItem = ({
  isDraggingParent,
  handleDelayChange,
  enterDelay,
  setIsDragResizingNumberInput
}) => {  
  const [delay, setDelay] = useState(enterDelay);

  // Configuration variables
  const MAX_DELAY = 5; // Maximum delay in seconds
  const STEP_SIZE = 0.05; // Step size in seconds
  const DECIMAL_PLACES = 2; // Number of decimal places to display

  useEffect(() => {
    setDelay(enterDelay);
  }, [enterDelay]);

  const handleSliderChange = (value) => {
    const newDelay = Number(value.toFixed(DECIMAL_PLACES));
    setDelay(newDelay);
    handleDelayChange(newDelay);
  };

  return (
    <div data-no-delay={delay === 0 ? true : false} className='editor-detailPanel--slide-animationPanel-item-delayInputContainer'>
      <ReactSlider
        className='editor-detailPanel--slide-animationPanel-item-delayInput-sliderContainer'
        thumbClassName='editor-detailPanel--slide-animationPanel-item-delayInput-slider'
        value={delay}
        min={0}
        max={MAX_DELAY}
        step={STEP_SIZE}
        onChange={handleSliderChange}
        onBeforeChange={() => setIsDragResizingNumberInput(true)}
        onAfterChange={() => setIsDragResizingNumberInput(false)}
        renderThumb={(props, state) => {
          const { key, ...restProps } = props;
          return (
            <div key={key} {...restProps}>
              <Icon name="drag-handle" />
            </div>
          );
        }}
      />
    </div>
  );
};

export default EditorDetailPanelSlideAnimationPanelItem;
import store from './store';
// import pusherInstance from './pusherInstance';
// import axios from 'axios';
import { Mixpanel } from './Mixpanel';
import Pusher from 'pusher-js'
import find from 'lodash/find'
import {generateProjectRender} from './actions/projects'
import {handleSaveExport} from './actions/exportUpload'
import * as Sentry from '@sentry/electron/renderer'

const PUSHER_KEY='a7eac2ac287fcd5e92ea'
//const PUSHER_KEY='857f156820169492b745' //for dev

//TODO not sure when the render segment second calc stuff got taken out 


// States:
// - enqueued: Initial state when export is requested
// - in_progress: After the first pusher with render-segment-started action
// - generation_completed: After pusher with status completed
// - downloading: When download starts
// - downloaded: After download is completed
// - failed: If export or download fails at any point

class ExportManager {
  constructor() {
    this.channel = null;
    this.pusher = new Pusher(PUSHER_KEY, {
      cluster: 'us2',
      encrypted: true
    });
    this.setupPusher();
  }

  setupPusher() {
    if (!this.channel) {
      this.channel = this.pusher.subscribe('video-renders');
      this.channel.bind('render-progress-update', this.handleProgressUpdatePusher);
      this.channel.bind('render-update', this.handlePusherUpdate);
    }
  }

  handleProgressUpdatePusher = (data) => {
    const { projectId, renderId, action, percentDone } = data;
    if (action === 'progress') {      
      store.dispatch({
        type: 'UPDATE_EXPORT_PROGRESS',
        payload: {
          projectId,
          renderId,
          percentDone
        }
      });
    } else if (action === 'all-rendered') {
      store.dispatch({
        type: 'EXPORT_FINALIZE_STARTED',
        payload: {
          projectId,
          renderId
        }
      });
    } else if (action === 'finalize-started') {
      // Already handled by all-rendered
      // We keep this for logging purposes
      //console.log('Finalize started');
    } else if (action === 'finalize-finished') {
      store.dispatch({
        type: 'EXPORT_FINALIZE_FINISHED',
        payload: {
          projectId,
          renderId
        }
      });
    }
  }

  handlePusherUpdate = (data) => {
    if (data.status === 'completed') {
      const state = store.getState();
      const activeExports = state.projectExports || {};
      if (activeExports[data.projectId] && activeExports[data.projectId].renderId == data.renderId) {
        const project = find(state.projects, {id: data.projectId});
        let name = project ? project.name : 'export';
        const filename = `${name}.mp4`;
        const completionTime = Date.now();
        store.dispatch({
          type: 'EXPORT_GENERATION_COMPLETED',
          payload: {
            projectId: data.projectId,
            renderId: data.renderId,
            resultUrl: data.resultUrl,
            completionTime,
          }
        });
        handleSaveExport(data.projectId, data.resultUrl);
      }
    } else if (data.status === 'failed') {
      store.dispatch({
        type: 'EXPORT_FAILED',
        payload: {
          projectId: data.projectId,
          renderId: data.renderId,
          error: 'Video generation failed'
        }
      });
    }
  }

  async startExport(projectId) {
    try {
      const enqueuedTime = Date.now();
      const state = store.getState();
      const project = find(state.projects, { id: projectId });
      const projectDuration = project?.duration || 0;
      const response = await this.generateVideo(projectId);     
      store.dispatch({
        type: 'START_EXPORT_SUCCESS',
        payload: {
          projectId,
          renderId: `${response.renderId}`,
          status: 'enqueued',
          enqueuedTime,
          projectDuration
        }
      });

      Mixpanel.track('Project Export Started', {
        projectId,
        renderId: response.renderId,
        enqueuedTime
      });

      return response;
    } catch (error) {
      store.dispatch({ 
        type: 'START_EXPORT_FAILURE', 
        payload: { projectId, error: error.message } 
      });
      Mixpanel.track('Project Export Failed', {
        projectId,
        error: error.message
      });
     // throw error;
    }
  }


  async generateVideo(projectId) {
    let apiTarget = 'prod';
    if(process.env.REACT_APP_ENV=='development' || process.env.REACT_APP_ENV=='local'){
      apiTarget='dev'
    }
    const useDevOrchestrator=false
    const renderOptions={
      apiTarget,
      useDevOrchestrator
    }
    
    try {
      const response = await store.dispatch(generateProjectRender(projectId, renderOptions))
      if (!response || !response.renderId) {
        throw new Error('Invalid response from video generation');
      }
      return response
    } catch (error) {
      console.log('error', error)
      error.name = 'Video Gen Request Error'
      Sentry.withScope(scope => {
        scope.setLevel("error");
        scope.setExtra("project_id", projectId);
        scope.setExtra("api_target", apiTarget);
        scope.setExtra("use_dev_orchestrator", useDevOrchestrator);
        scope.setExtra("render_options", JSON.stringify(renderOptions));
        
        if (error.response) {
          scope.setExtra("response_status", error.response.status);
          scope.setExtra("response_data", error.response.data);
          scope.setExtra("response_headers", error.response.headers);
        }
        
        if (error.config) {
          scope.setExtra("full_config", {
            url: error.config.url,
            method: error.config.method,
            headers: error.config.headers,
            timeout: error.config.timeout,
            baseURL: error.config.baseURL
          });
        }
        
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  // async generateVideo(projectId, maxRetries = 5) {
  //   let apiTarget = 'prod';
  //   if(process.env.REACT_APP_ENV=='development' || process.env.REACT_APP_ENV=='local'){
  //     apiTarget='dev'
  //   }
  //   const url = `https://orch.yarndist.com/orchestrator/render/${projectId}?exportWidth=3840&apiTarget=${apiTarget}`;
  
  //   for (let attempt = 1; attempt <= maxRetries; attempt++) {
  //     try {
  //       const response = await axios.get(url);
  //       console.log('Video generation successful:', response.data);
  //       return response.data;
  //     } catch (error) {
  //       console.error(`Error generating video (Attempt ${attempt}/${maxRetries}):`, error);
  //       if (error.response) {
  //         console.error('Error response data:', error.response.data);
  //         console.error('Error response status:', error.response.status);
  //         console.error('Error response headers:', error.response.headers);
  //       } else if (error.request) {
  //         console.error('Error request:', error.request);
  //       } else {
  //         console.error('Error message:', error.message);
  //       }
  //       console.error('Error config:', error.config);
  //       error.name = 'Video Gen Request Error'
  //       Sentry.withScope(scope => {
  //         scope.setLevel("error");
  //         scope.setExtra("request_url", url);
  //         scope.setExtra("api_target", apiTarget);
  //         scope.setExtra("attempt_number", attempt);
  //         if (error.request) {
  //           scope.setExtra("request_method", error.request.method);
  //           scope.setExtra("request_path", error.request.path);
  //           scope.setExtra("request_protocol", error.request.protocol);
  //           scope.setExtra("request_host", error.request.host);
  //           const requestHeaders = error.config?.headers || {};
  //           scope.setExtra("request_headers", JSON.stringify(requestHeaders));
  //         }
  //         if (error.response) {
  //           scope.setExtra("response_status", error.response.status);
  //           scope.setExtra("response_data", error.response.data);
  //           scope.setExtra("response_headers", error.response.headers);
  //         }
  //         if (error.config) {
  //           scope.setExtra("full_config", {
  //             url: error.config.url,
  //             method: error.config.method,
  //             headers: error.config.headers,
  //             timeout: error.config.timeout,
  //             baseURL: error.config.baseURL
  //           });
  //         }
  //         Sentry.captureException(error);
  //       });

  //       if (attempt === maxRetries) {
  //         throw new Error(`Failed to generate video after ${maxRetries} attempts: ${error.message}`);
  //       }
  //       const backoffSeconds = 3 * Math.pow(2, attempt - 1);
  //       await new Promise(resolve => setTimeout(resolve, backoffSeconds*1000));
  //     }
  //   }
  // }

  sendExportCompletionEvent(projectId, renderId, projectName, filename) {
    const state = store.getState();
    const exportData = state.projectExports[projectId];

    if (exportData) {
      const { enqueuedTime, inProgressTime, completionTime, downloadCompletionTime, projectDuration, resultUrl } = exportData;

      const queueTime = inProgressTime - enqueuedTime;
      const generationTime = completionTime - inProgressTime;
      const totalTime = completionTime - enqueuedTime;
      const downloadTime = downloadCompletionTime - completionTime;

      // Format times in seconds with 2 decimal places
      const queueTimeSeconds = (queueTime / 1000).toFixed(2);
      const generationTimeSeconds = (generationTime / 1000).toFixed(2);
      const totalTimeSeconds = (totalTime / 1000).toFixed(2);
      const downloadTimeSeconds = (downloadTime / 1000).toFixed(2);

      const organization = state.organization;
      const orgId = organization ? organization.id : null;
      const orgName = organization ? organization.name : null;

      Mixpanel.track('Project Export Completed', {
        projectId,
        projectName,
        renderId,
        queueTimeSeconds: parseFloat(queueTimeSeconds),
        generationTimeSeconds: parseFloat(generationTimeSeconds),
        totalTimeSeconds: parseFloat(totalTimeSeconds),
        downloadTimeSeconds: parseFloat(downloadTimeSeconds),
        filename,
        resultUrl,
        orgId,
        orgName,
        projectDuration
      });
    }
  }

  async cancelExport(projectId) {
    const state = store.getState();
    const activeExports = state.projectExports || {};
    const exportData = activeExports[projectId];
    if (exportData && exportData.renderId) {
      try {
        store.dispatch({
          type: 'CANCEL_EXPORT',
          payload: { projectId, renderId: exportData.renderId }
        });        
      } catch (error) {
        throw new Error('Failed to cancel export');
      }
    } else {
      console.log(`No active export found for project ${projectId}`);
    }
  }
}

const exportManager = new ExportManager();

export default exportManager;
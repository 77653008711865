// //item is a word or a pause
const WebcamTranscriptItemNodeSpec = {
    inline: true,
    group: "inline",
    attrs: {
        startTime: { default: 0 },
        endTime: { default: 0 },
        originalIndex: { default: 0 },
        isPause: { default: false }
    },
    content: "text*",
    toDOM: function(node) {
        const baseClass = "editor-transcriptPanel-transcriptGroup--cameraRecording-word";
        const pauseClass = "editor-transcriptPanel-transcriptGroup--cameraRecording-word--pause";
        
        return ["span", { 
            class: node.attrs.isPause ? `${baseClass} ${pauseClass}` : baseClass,
            spellcheck: "false",
        }, 0];
    },
    parseDOM: [{ 
        tag: "span.editor-transcriptPanel-transcriptGroup--cameraRecording-word",
        getAttrs: dom => ({
            isPause: dom.classList.contains("editor-transcriptPanel-transcriptGroup--cameraRecording-pause")
        })
    }]
};

export default WebcamTranscriptItemNodeSpec;
export const getWebcamDuration = async (captureId) => {
  try {
    const result = await ipcRenderer.invoke('get-webcam-duration', captureId);
    if (result.success) {
     // console.log(`Video duration: ${result.duration} seconds`);
      return result.duration;
    } else {
      throw new Error(result.error);
    }
  } catch (error) {
    console.error(`Error fetching video duration: ${error.message}`);
    throw error;
  }
};
import React, { useState, useEffect, useRef } from 'react';
import * as Select from '@radix-ui/react-select';
import Icon from '../../misc/Icon';

const DPSelectMenuWithAudioPreview = ({ value, setValue, options, width, btnLabelLight }) => {
  const buttonWidth = width || 80;
  const menuWidth = buttonWidth + 12;
  const [open, setOpen] = useState(false);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);
  const audioContextRef = useRef(null);
  const audioBuffersRef = useRef(new Map());
  const currentSourceRef = useRef(null);


  useEffect(() => {
    // Initialize audio context
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    // Load audio buffers for all options
    const loadBuffers = async () => {
      for (const option of options) {
        if (option.audioPath) {
          try {
            const arrayBuffer = await window.ipcRenderer.invoke('read-sound-effect-file', option.audioPath);
            const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
            audioBuffersRef.current.set(option.value, audioBuffer);
          } catch (error) {
            console.error('Error loading audio:', error);
          }
        }
      }
    };

    loadBuffers();

    // Cleanup
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [options]);

  const handleAudioButtonClick = async (e, option) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentlyPlayingId === option.value) {
      if (currentSourceRef.current) {
        currentSourceRef.current.stop();
        currentSourceRef.current = null;
      }
      setCurrentlyPlayingId(null);
      return;
    }

    if (currentSourceRef.current) {
      currentSourceRef.current.stop();
      currentSourceRef.current = null;
    }

    const audioBuffer = audioBuffersRef.current.get(option.value);
    if (audioBuffer) {
      setCurrentlyPlayingId(option.value);

      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 1;

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      currentSourceRef.current = source;

      source.onended = () => {
        if (currentSourceRef.current === source) {
          setCurrentlyPlayingId(null);
          currentSourceRef.current = null;
        }
      };

      source.start(0);
    }
  };


  const handleSelectButtonClick = (e, option) => {
    setOpen(false);
      e.preventDefault();
      e.stopPropagation();
      setValue(option.value);
    };


  return (
    <Select.Root
      value={value}
      open={open}
      onOpenChange={(isOpen) => {
        // Stop any currently playing audio
        setOpen(isOpen);
        if (currentSourceRef.current) {
          currentSourceRef.current.stop();
          currentSourceRef.current = null;
        }
        setCurrentlyPlayingId(null);
        
      }}
    >
      <Select.Trigger
        style={{ width: `${buttonWidth}px` }}
        className="dpSelectBtn"
        onClick={() => setOpen(true)}
      >
        <button
          type="button"
          onMouseDown={(e) => {
            // Only stop propagation if clicking directly on the audio button
            if (e.target.closest('.dpSelectBtn-audioPreview')) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onPointerDown={(e) => {
            if (e.target.closest('.dpSelectBtn-audioPreview')) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onClick={(e) => {
            // Only handle audio if clicking directly on the audio button
            if (e.target.closest('.dpSelectBtn-audioPreview')) {
              e.preventDefault();
              e.stopPropagation();
              const selectedOption = options.find(opt => opt.value === value);
              if (selectedOption) {
                handleAudioButtonClick(e, selectedOption);
              }
            }
          }}
          className="dpSelectBtn-audioPreview"
          data-audio-preview="true"
        >
          {currentlyPlayingId === value ? (
            <Icon name="chunkPause" />
          ) : (
            <Icon name="speaker-wave-2" />
          )}
        </button>
        <div data-light-state={btnLabelLight} className="dpSelectBtn-label">
          <Select.Value /> {value.subLabel && 'test'}
        </div>
        <Select.Icon className="dpSelectBtn-iconContainer">
          <Icon name="chevronUpDownMedium" />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          style={{ width: `${menuWidth}px` }}
          className="dpSelectMenu forceDarkTheme"
        >
          <Select.Viewport className="dpSelectMenu-viewport">
            {options.map((option) => (
              // By marking the item as disabled, Radix won't auto-close when it's clicked.
              <Select.Item
                key={option.value}
                value={option.value}
                disabled
                className="dpSelectMenu-item"
              >
                {/* Use a container to hold two buttons side-by-side */}
                
                  {/* Audio Preview Button */}
                  <button
                    type="button"
                    onMouseDown={(e) => {
                      if (e.target.closest('.dpSelectMenu-item-audioPreview')) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onPointerDown={(e) => {
                      if (e.target.closest('.dpSelectMenu-item-audioPreview')) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onClick={(e) => {
                      if (e.target.closest('.dpSelectMenu-item-audioPreview')) {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAudioButtonClick(e, option);
                      }
                    }}
                    className="dpSelectMenu-item-audioPreview"
                  >
                    {currentlyPlayingId === option.value ? (
                      <Icon name="chunkPause" />
                    ) : (
                      <Icon name="speaker-wave-2" />
                    )}
                  </button>

                  {/* Selection Button */}
                  <div
                    type="button"
                    onClick={(e) => handleSelectButtonClick(e, option)}
                    className="dpSelectMenu-item-select-innerLabel"
                  >                    
                    <Select.ItemText>{option.label}</Select.ItemText>
                  </div>
                
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default DPSelectMenuWithAudioPreview;

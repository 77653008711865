import React, { useEffect, useState } from 'react';
import { SpringAnimator } from '../../../utils/animations/SpringAnimator';
import SlideDonutChartPartRing from './SlideDonutChartPartRing';
import SlideDonutChartFullRing from './SlideDonutChartFullRing';
import SlideDonutChartLabels from './SlideDonutChartLabels';

const FPS = 60; // Frames per second
const calculateAnimationDuration = 10;

const SlideDonutChart = ({ time, targetValue, suffix, postLabel, preLabel, animationType, isHidden, fontFamily, fontWeight, donutValueTextColor, donutLabelTextColor, donutFillColor, donutPartRingMaxOpacity, donutFullRingMaxOpacity, chartWidth, chartHeight, chartX, chartY, hideLabel, sceneWidth, sceneHeight}) => {
  let targetValueDecimal = targetValue > 1 ? targetValue / 100 : targetValue;
  const initialTargetValue = targetValueDecimal * 0.75;

  const outerRadius = 460;
  const thickness = 72;
  const innerRadius = outerRadius - thickness;

  const fullRingMaxOpacity = donutFullRingMaxOpacity;
  const partRingMaxOpacity = donutPartRingMaxOpacity;

  const springParams = { mass: 1, stiffness: 180, damping: 70 };

  const [percentFillFrames, setPercentFillFrames] = useState([]);
  const [ringOpacityFrames, setRingOpacityFrames] = useState([]);
  const [ringScaleFrames, setRingScaleFrames] = useState([]);

  useEffect(() => {
    const percentFillSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, initialTargetValue);
    const ringOpacitySpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0);
    const ringScaleSpring = new SpringAnimator(springParams.mass, springParams.stiffness, springParams.damping, 0.8, 0, 0.001);

    const percentFillFrames = [];
    const ringOpacityFrames = [];
    const ringScaleFrames = [];

    for (let frame = 0; frame < FPS * calculateAnimationDuration; frame++) {
      percentFillSpring.setTarget(targetValueDecimal);
      percentFillSpring.simulate(1000 / FPS);
      percentFillFrames.push(percentFillSpring.position);

      ringOpacitySpring.setTarget(1);
      ringOpacitySpring.simulate(1000 / FPS);
      ringOpacityFrames.push(ringOpacitySpring.position);

      ringScaleSpring.setTarget(1); // Assuming the target scale is 1
      ringScaleSpring.simulate(1000 / FPS);
      ringScaleFrames.push(ringScaleSpring.position);
    }

    setPercentFillFrames(percentFillFrames);
    setRingOpacityFrames(ringOpacityFrames);
    setRingScaleFrames(ringScaleFrames);
  }, [targetValueDecimal]);

  const frameIndex = Math.floor(time * FPS);
  let percentFill = percentFillFrames[frameIndex] || targetValueDecimal;
  let ringOpacity = ringOpacityFrames[frameIndex] || 0;
  let ringScale = ringScaleFrames[frameIndex] || 1;

  // console.log('IN DONUT ANIMATION TYPE')
  // console.log(animationType)
  // console.log('-----')
  

  if(animationType === 'none'){
    percentFill = targetValueDecimal
    ringScale = 1
    ringOpacity = 1
  }

  if(animationType === 'fadeIn'){
    percentFill = targetValueDecimal
    ringScale = 1    
  }

  if(animationType === 'scaleUp'){
    percentFill = targetValueDecimal    
  }

  if(isHidden){
    ringOpacity = 0
  }
  
  const fullRingOpacity = ringOpacity * fullRingMaxOpacity;
  const partRingOpacity = ringOpacity * partRingMaxOpacity;

  const formattedValue = Math.floor(percentFill * 100, 1);

  const side = Math.min(chartWidth, chartHeight) // must fit into a box

  const sizeScale1Width = 920 // compare item width against this scale  
  const sizeScale = side / sizeScale1Width
    
  // chartWidth 
  // chartHeight
  // chartX // top left coordinate system
  // chartY // top right coordinate system



  const chartCenterX = chartX + chartWidth / 2;
  const chartCenterY = chartY + chartHeight / 2;

  const translateX = chartCenterX - (sceneWidth / 2); // not sure why 4 is required
  const translateY = (sceneHeight / 2) - chartCenterY;

  // want to find translateX and translateY

  return (
    <>
      <group scale={[sizeScale, sizeScale, sizeScale]} position={[translateX,translateY,0]}>
        <group scale={[ringScale, ringScale, ringScale]} position={[0,0,0]}>
          {!isHidden &&
            <SlideDonutChartFullRing
              color={donutFillColor}
              opacity={fullRingOpacity}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
            />
          }
          {!isHidden &&
            <SlideDonutChartPartRing
              color={donutFillColor}
              opacity={partRingOpacity}
              percentFill={percentFill}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
            />
          }
        </group>

        {!hideLabel &&
          <SlideDonutChartLabels 
            animationType={animationType}
            formattedValue={formattedValue}
            time={time}
            suffix={suffix}
            postLabel={postLabel}
            preLabel={preLabel}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            isHidden={isHidden}
            donutValueTextColor={donutValueTextColor}
            donutLabelTextColor={donutLabelTextColor}
          />
        }
      </group>
    </>
  );
};

export default SlideDonutChart;

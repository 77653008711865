import React from 'react';
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const RecordWebcamCameraSelect = ({ 
  activeCamera,
  setActiveCamera,
  cameras,
  viewType,
  isDisplayRecording
}) => { 
  const activeOption = cameras && cameras.find(camera => camera.value === activeCamera);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select Camera...';

  let sideOffset = 4
  let side= 'top'
  let align = 'start'
  if(viewType === 'instructions'){
    sideOffset = 0
    side = 'bottom'
  }
  if(viewType === 'script'){
    align = 'middle'    
  }


  return (    
    <DropdownMenu.Root modal={viewType === 'script' ? true : false}>
      <DropdownMenu.Trigger asChild>
        <button data-view-type={viewType} data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} data-device-type='camera' className='recordWebcamWindow-selectDeviceBtn'>
          <div className='recordWebcamWindow-selectDeviceBtn-iconContainer'>
            <Icon name='cameraOutline' />
          </div>
          <div className='recordWebcamWindow-selectDeviceBtn-label'>
            {buttonLabel}
          </div>
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align={align}
        side={side} 
        sideOffset={sideOffset}         
        className='dropdownMenu'
      >        
        {cameras && cameras.map((camera) => (
          <DropdownMenu.Item
            key={camera.value}
            onSelect={() => setActiveCamera(camera.value)}
            className={`dropdownMenu-item ${camera.value === activeCamera ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(camera.label)}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default RecordWebcamCameraSelect;
import React from 'react';
import * as Popover from '@radix-ui/react-popover';

const TOP_OFFSET = 88;
const LEFT_OFFSET = 0;
const MIN_LEFT = 24;
//const FIRST_RECT_TOP_ADJUSTMENT = -5;
const FIRST_RECT_TOP_ADJUSTMENT = 0;
// const VERTICAL_PADDING = 2;
// const HORIZONTAL_PADDING = 1;
const VERTICAL_PADDING = 1.5;
const HORIZONTAL_PADDING = 1;

const TranscriptPanelSelectionRects = ({ transcriptSelectionRects }) => {


  const calculateRectPosition = (rect, index) => {
    // Apply horizontal padding to original width if height > 1
    const adjustedRect = {
      ...rect,
      width: rect.height > 1 ? rect.width + (2 * HORIZONTAL_PADDING) : rect.width,
      left: rect.left - HORIZONTAL_PADDING
    };

    const calculatedLeft = adjustedRect.left + LEFT_OFFSET;
    const adjustedLeft = Math.max(calculatedLeft, MIN_LEFT);
    const leftDifference = adjustedLeft - calculatedLeft;
    
    // Only apply vertical padding adjustments if height is greater than 1
    const heightAdjustments = rect.height > 1 ? {
      top: -VERTICAL_PADDING,  // Move up by padding amount
      height: 2 * VERTICAL_PADDING  // Add padding to both top and bottom
    } : {
      top: 0,
      height: 0
    };
    
    return {
      left: adjustedLeft,
      top: rect.top + TOP_OFFSET + 
           (index === 0 ? FIRST_RECT_TOP_ADJUSTMENT : 0) + 
           heightAdjustments.top,
      width: Math.max(0, adjustedRect.width - leftDifference),
      height: rect.height + heightAdjustments.height
    };
  };

  return (
    <>
      {transcriptSelectionRects && transcriptSelectionRects.map((rect, i) => (
        <div
          key={`selection_rect_${i}`}          
          data-selection-type={rect.selectionType}

          className='editor-transcriptPanel-list-selectionRect'
          style={calculateRectPosition(rect, i)}
        />
      ))}
    </>
  );
};

export default TranscriptPanelSelectionRects;
import React, {Component} from 'react'
import {Switch,Route,useLocation} from "react-router-dom"
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Mixpanel } from '../Mixpanel'
import ElectronMacDots from '../components/ElectronMacDots'
import ExportProgressTray from '../components/ExportProgressTray'
import LibraryPageContainer from './LibraryPageContainer'
import RecordWebcamContainer from './RecordWebcamContainer'
import ForceUpdateModal from '../components/ForceUpdateModal'
import {BrowserRouter as Router} from "react-router-dom"
const history = createBrowserHistory()


import EditorWrapperContainer from './EditorWrapperContainer'
import RecordingWidgetContainer from './RecordingWidgetContainer'
import RecordingControls from '../components/recordingWindow/RecordingControls'
import CursorGameContainer from './CursorGameContainer'
import TabBar from '../components/tabBar/TabBar'
import ExportWrapperContainer from './ExportWrapperContainer'
import OrgPusherContainer from './OrgPusherContainer'

//Routing
import MatchAuthenticated from '../components/routing/MatchAuthenticated'
import RedirectAuthenticated from '../components/routing/RedirectAuthenticated'
import LoginContainer from './auth/LoginContainer' 
import SettingsContainer from './settings/SettingsContainer'
import ServerSideExportPage from '../serverSideExport/ServerSideExportPage'
import ToastNotifications from '../components/ToastNotifications'
import AppShortcuts from './AppShortcuts'

import {checkWebVersion} from '../actions/webVersionCheck'

import NewRecordingProjectRedirect from './NewRecordingProjectRedirect'

//use HashRouter instead of BrowserRouter
//https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron

import {authenticateSession} from '../actions/auth'
import {handleDownloadFileSucess,handleImageDownloadSucess,handleBasicVideoDownloadSucess,handleMusicDownloadSucess} from '../actions/downloads'
// import {maybeDownloadFailedRecording} from '../utils/assets/maybeDownloadFailedRecording'
// import DynamicFontPreloader from './DynamicFontPreloader'

import {closeAllProjects} from '../actions/projects'
import UpdatesPage from '../components/changelog/UpdatesPage'
import { createNewProject } from '../utils/projectUtils/createNewProject'
import { randomID } from '../utils/randomID'
import { setPendingSessionCapture } from '../actions/editor'



class AppContainer extends Component {

	constructor(){
		super()
		this.state = {
			isLegacyElectronMode: false
		}
		Mixpanel.track('app_load')
		if(window.ipcRenderer){
			window.isElectron=true
		}
	}

	componentDidMount() {	
		this.props.authenticateSession().then(() => {})
		if (window.ipcRenderer) {
			window.ipcRenderer.receive("set-auth-token", this.setAuthToken)
			window.ipcRenderer.receive("file-download-success", this.handleFileDownloadSucess)
			window.ipcRenderer.receive("image-download-success", this.handleImageDownloadSucess)
			window.ipcRenderer.receive("music-download-success", this.handleMusicDownloadSucess)
			window.ipcRenderer.receive("basic-video-download-success", this.handleBasicVideoDownloadSucess)
			window.ipcRenderer.receive("window-close-initiated",this.handleWindowCloseInitiated)
			window.ipcRenderer.receive('add-new-recording-session', this.handleAddNewRecordingSession)
			window.ipcRenderer.receive('renderer-log', (message) => {
				console.log(message); // This will log once in the renderer
			});

			// Safely test if get-electron-version invoke exists
			if (window.ipcRenderer.invoke) {
				window.ipcRenderer.invoke('get-app-version')
					.then(version => {
						console.log('--------------------Electron version:', version);
						this.setState({ isLegacyElectronMode: false });
					})
					.catch(err => {
						console.log('get-electron-version not available in preload');
						this.setState({ isLegacyElectronMode: true });
					});
			}
		}
		this.pingApiInterval=setInterval(() => {
			this.props.checkWebVersion()
  	},60000)

		
	}

	componentWillUnmount() {
		if (window.ipcRenderer) {
			window.ipcRenderer.removeListener("set-auth-token", this.setAuthToken)
			window.ipcRenderer.removeListener("file-download-success", this.handleFileDownloadSucess)
			window.ipcRenderer.removeListener("image-download-success", this.handleImageDownloadSucess)
			window.ipcRenderer.removeListener("music-download-success", this.handleMusicDownloadSucess)
			window.ipcRenderer.removeListener("basic-video-download-success", this.handleBasicVideoDownloadSucess)
			window.ipcRenderer.removeListener("window-close-initiated",this.handleWindowCloseInitiated)
			window.ipcRenderer.removeListener('add-new-recording-session', this.handleAddNewRecordingSession)
			window.ipcRenderer.removeListener('renderer-log');
		}
		if(this.pingApiInterval){
			clearInterval(this.pingApiInterval)
		}
	}

	handleWindowCloseInitiated=()=>{ //close all tabs to unlock projects
		this.props.closeAllProjects().then(() => {
    // This code will be executed after closeAllProjects has completed
		    if (window.ipcRenderer) {
		      window.ipcRenderer.send('cleanup-done');
		    }
		  }).catch((error) => {
		    console.error('Error in closing projects:', error);
		     if (window.ipcRenderer) {
		      window.ipcRenderer.send('cleanup-done');
		    }
		  });
	}

	handleImageDownloadSucess=(event,data)=>{
		this.props.handleImageDownloadSucess(event.imageId)
	}

	handleMusicDownloadSucess=(event)=>{
		this.props.handleMusicDownloadSucess(event.trackId)
	}

	handleBasicVideoDownloadSucess=(event,data)=>{
		this.props.handleBasicVideoDownloadSucess(event.fileId)
	}

	// handleFileDownloadSucess=(event,data)=>{
	// 	this.props.handleDownloadFileSucess(event.captureId,event.fileName)
	// }
	handleFileDownloadSucess=(event,data)=>{
		if (data && data.files) {
			data.files.forEach(file => {
				this.props.handleDownloadFileSucess(data.captureId, file.fileName);
			});
		} else if (event && event.captureId && event.fileName) {
			// Handle legacy format
			this.props.handleDownloadFileSucess(event.captureId, event.fileName);
		}
	}

	setAuthToken=(args)=>{
		this.props.authenticateSession()
	}


	handleAddNewRecordingSession = async (event) => {
		const sessionCaptureId = event.sessionCaptureId
		let projectId = event.projectId
		const screenRecordingDimensions = event.screenRecordingDimensions
		const activeTab = this.props.tabs.find(tab => tab.active)
		const addToExistingProject = projectId && activeTab && activeTab.projectId === projectId

		if(!addToExistingProject){
			projectId = `${randomID()}`
			await createNewProject(null, null, projectId, true);
			this.props.setPendingSessionCapture(sessionCaptureId,projectId);
		} else {
			// For existing projects, use Redux since we're already on the page
			this.props.setPendingSessionCapture(sessionCaptureId,projectId);
		}
	}

	render() {
		const { isAuthenticated, willAuthenticate } = this.props
		const authProps = { isAuthenticated, willAuthenticate }
		//const theme = "light"
		const theme = "dark"

		const showForceUpdateModal = this.state.isLegacyElectronMode

		return (
			<div id="app" data-theme={theme}  className={"app " + (window.isElectron ? ' app--electron ' : ' app--web ' )}>				
			 	{/*<DynamicFontPreloader orgBrand={this.props.orgBrand}/>*/}

			 	<Router history={history}>
			 		<AppShortcuts/>

			 		<ElectronMacDots />

			 		{isAuthenticated &&
			 			<TabBar />
			 		}
			 		{isAuthenticated &&
						<OrgPusherContainer/>
					}

					{isAuthenticated && 
						<SettingsContainer/>
					}

					<ToastNotifications/>

					<ForceUpdateModal
						showModal={showForceUpdateModal}
					/>

					<ExportProgressTray />

					<NewRecordingProjectRedirect/>

					<Switch>
						<Route path='/server/:id' component={ServerSideExportPage}/>						
						<RedirectAuthenticated path='/login' component={LoginContainer} {...authProps}/>
						
						<Route path='/recording' component={RecordingWidgetContainer}/>
						<Route path='/export/:id' component={ExportWrapperContainer}/>

					
						<MatchAuthenticated path='/library/collection/:id' component={LibraryPageContainer}  {...authProps}/>
						<MatchAuthenticated path='/library/:subPage' component={LibraryPageContainer}  {...authProps}/>
						<MatchAuthenticated path='/library' component={LibraryPageContainer}  {...authProps}/>
					
						<Route path='/cursorGame' component={CursorGameContainer}/>
						<Route path='/recording-controls' component={RecordingControls}/>				
						<Route path='/updates' component={UpdatesPage}/>				
						<Route path='/record-webcam' component={RecordWebcamContainer}/>				
	
						<MatchAuthenticated path='/project/:id' component={EditorWrapperContainer}  {...authProps}/>
						<MatchAuthenticated path='/' component={LibraryPageContainer}  {...authProps}/>
						
					</Switch>
	  		</Router>

	  	</div>
		)
	}
}


const mapStateToProps = (state) => ({
	isAuthenticated: state.authenticationStatus.isAuthenticated,
	willAuthenticate: state.authenticationStatus.willAuthenticate,
	orgBrand: state.orgBrand,
	tabs: state.tabs,
	pendingSessionCapture: state.pendingSessionCapture
});


export default connect(
	mapStateToProps,
	{
		authenticateSession,
		handleDownloadFileSucess,
		closeAllProjects,
		handleImageDownloadSucess,
		handleBasicVideoDownloadSucess,
		handleMusicDownloadSucess,
		checkWebVersion,
		setPendingSessionCapture
	}
)(AppContainer)

						


import React, { useState, useEffect } from 'react';
import Icon from '../misc/Icon'
import RecordWebcamToolbar from './RecordWebcamToolbar'
import RecordWebcamCameraSelect from './RecordWebcamCameraSelect'
import RecordWebcamMicrophoneSelect from './RecordWebcamMicrophoneSelect'

const RecordWebcamClean = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,  
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  instructions,
  isReadyToRecord,
  showIsRecordingIndicator,  
  mediaStream,
}) => {
  

  return (    
    <>
      <div className='recordWebcamWindow-mainContainer'>
        <div className='recordWebcamWindow-main'>
          <div className='recordWebcamWindow-main--clean-preview-videoContainer'>
            <RecordWebcamCameraSelect 
              activeCamera={activeCamera}
              setActiveCamera={setActiveCamera}
              cameras={cameras}
              isDisplayRecording={showIsRecordingIndicator}
              viewType='clean'
            />
            <RecordWebcamMicrophoneSelect 
              activeMicrophone={activeMicrophone}
              setActiveMicrophone={setActiveMicrophone}
              microphones={microphones}
              mediaStream={mediaStream}
              isDisplayRecording={showIsRecordingIndicator}
              viewType='clean'
            />
            <video 
              ref={videoRef} 
              autoPlay 
              playsInline 
              muted
              className="recordWebcamWindow-main--clean-preview-video"
            />
          </div>
        </div>
      </div>

      <RecordWebcamToolbar 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        onRestart={onRestart}        
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={showIsRecordingIndicator}        
      />
      
    </>
  );
};

export default RecordWebcamClean;
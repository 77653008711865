import { LayoutGroup } from '../LayoutGroup'

const LEFT_INSET = 160;
const RIGHT_INSET = 160;
const TOP_INSET = 80;
const BOTTOM_INSET = 80;
const TEXT_ELEMENT_MAX_WIDTH = 1400;

export function calculateElementPositions(layout, slideWidth, slideHeight) {
  positionNestedLayouts(layout, 0, 0, slideWidth, slideHeight);
  adjustFreeTextElements(layout, slideHeight);
}

function adjustFreeTextElements(layout, slideHeight) {
  layout.children.forEach(child => {
    if (child.type === 'text') {
      adjustTextElement(child, slideHeight);
    }
  });
}

function calculateLayoutDimensions(layout) {
  let width = 0;
  let height = 0;
  let lastChildMargin = 0;

  layout.children.forEach((child, index) => {
    let childWidth, childHeight;
    if (child instanceof LayoutGroup) {
      const childDimensions = calculateLayoutDimensions(child);
      childWidth = childDimensions.width;
      childHeight = childDimensions.height;
    } else {
      childWidth = child.width;
      childHeight = child.height;
    }

    if (layout.type === 'hstack') {
      width += childWidth;
      if (index < layout.children.length - 1) {
        width += child.rightMargin || 0;
      } else {
        lastChildMargin = child.rightMargin || 0;
      }
      height = Math.max(height, childHeight);
    } else { // vstack
      width = Math.max(width, childWidth);
      height += childHeight;
      if (index < layout.children.length - 1) {
        height += child.bottomMargin || 0;
      } else {
        lastChildMargin = child.bottomMargin || 0;
      }
    }
  });

  return { width, height, lastChildMargin };
}

/**
 * Adjusts a free text element's vertical position in two steps:
 *
 * 1. Height-change adjustment: compares the element's current height to a stored baseHeight.
 *    If the element is nested (i.e. a parentVAlign is provided) then we use that value exclusively.
 *    Otherwise, we fall back to the child's own metadata or the global default.
 *
 * 2. Overflow adjustment: if the bottom of the element extends past the slide,
 *    we shift upward to prevent overflow.
 
 */
function adjustTextElement(element, slideHeight, parentVAlign) {
  // Step 1: Height-change adjustment
  let baseHeight =
    element.metadata && element.metadata.baseHeight !== undefined
      ? element.metadata.baseHeight
      : element.height;
  const heightDiff = element.height - baseHeight;

  // If a parent v-align is provided, ignore the child's setting.
  const effectiveAlignment = parentVAlign !== undefined 
    ? parentVAlign 
    : (element.metadata && element.metadata.textVAlign ? element.metadata.textVAlign : "top");

  // Apply adjustment based on the effective alignment.
  if (heightDiff !== 0) {
    if (effectiveAlignment === 'bottom') {
      element.y -= heightDiff;
    } else if (effectiveAlignment === 'middle') {
      element.y -= heightDiff / 2;
    }
  }

  // Step 2: Overflow adjustment (original logic)
  const buffer = 10;
  const bottomY = element.y + element.height;
  if (bottomY > (slideHeight - buffer)) {
    const overflow = bottomY - (slideHeight - buffer);
    element.y -= overflow;
  }

  // Update the stored baseHeight for future adjustments.
  if (element.metadata) {
    element.metadata.baseHeight = element.height;
  } else {
    element.metadata = { baseHeight: element.height };
  }
}

function positionNestedLayouts(layout, startX, startY, slideWidth, slideHeight) {
  if (layout.type === 'free') {
    layout.children.forEach(child => {
      if (child instanceof LayoutGroup) {
        positionNestedLayouts(child, startX + child.x, startY + child.y, slideWidth, slideHeight);
      }
    });
    return;
  }

  let currentX = startX;
  let currentY = startY;

  // First pass: calculate total dimensions.
  const { width, height } = calculateLayoutDimensions(layout);

  // Apply pinning for vstack and hstack.
  if (layout.type === 'vstack') {
    const pin = layout.vAlign || 'middle';
    const originalHeight = layout.height || height;
    switch (pin) {
      case 'top':
        layout.y = startY;
        break;
      case 'bottom':
        layout.y = startY + (originalHeight - height);
        break;
      case 'middle':
      default:
        const growth = height - originalHeight;
        layout.y = startY - growth / 2;
        break;
    }
    currentY = layout.y;
  } else if (layout.type === 'hstack') {
    const pin = layout.hAlign || 'middle';
    const originalWidth = layout.width || width;
    switch (pin) {
      case 'left':
        layout.x = startX;
        break;
      case 'right':
        layout.x = startX + (originalWidth - width);
        break;
      case 'middle':
      default:
        const growth = width - originalWidth;
        layout.x = startX - growth / 2;
        break;
    }
    currentX = layout.x;
  }

  layout.width = width;
  layout.height = height;

  // Second pass: position children with alignment.
  layout.children.forEach((child, index) => {
    let childWidth, childHeight;
    if (child instanceof LayoutGroup) {
      const childDimensions = calculateLayoutDimensions(child);
      childWidth = childDimensions.width;
      childHeight = childDimensions.height;
      if (layout.type === 'hstack') {
        child.x = currentX;
        const vAlign = layout.vAlign;
        switch (vAlign) {
          case 'top':
            child.y = currentY;
            break;
          case 'bottom':
            child.y = currentY + layout.height - childHeight;
            break;
          case 'middle':
          default:
            child.y = currentY + (layout.height - childHeight) / 2;
            break;
        }
        currentX += childWidth + (child.rightMargin || 0);
      } else { // vstack
        const hAlign = layout.hAlign;
        switch (hAlign) {
          case 'left':
            child.x = currentX;
            break;
          case 'right':
            child.x = currentX + layout.width - childWidth;
            break;
          case 'middle':
          default:
            child.x = currentX + (layout.width - childWidth) / 2;
            break;
        }
        child.y = currentY;
        currentY += childHeight + (child.bottomMargin || 0);
      }
      child.width = childWidth;
      child.height = childHeight;
      positionNestedLayouts(child, child.x, child.y, slideWidth, slideHeight);
    } else if (child) {
      childWidth = child.width;
      childHeight = child.height;
      if (layout.type === 'hstack') {
        child.x = currentX;
        const vAlign = layout.vAlign;
        switch (vAlign) {
          case 'top':
            child.y = currentY;
            break;
          case 'bottom':
            child.y = currentY + layout.height - childHeight;
            break;
          case 'middle':
          default:
            child.y = currentY + (layout.height - childHeight) / 2;
            break;
        }
        currentX += childWidth + (child.rightMargin || 0);
      } else { // vstack
        const hAlign = layout.hAlign;
        switch (hAlign) {
          case 'left':
            child.x = currentX;
            break;
          case 'right':
            child.x = currentX + layout.width - childWidth;
            break;
          case 'middle':
          default:
            child.x = currentX + (layout.width - childWidth) / 2;
            break;
        }
        child.y = currentY;
        currentY += childHeight + (child.bottomMargin || 0);
      }
    }
    // For free text elements, apply our adjustment using the parent's vAlign.
    // This ignores any per-child vAlign.
    if (child.type === 'text') {
      adjustTextElement(child, slideHeight, layout.vAlign);
    }
  });
}

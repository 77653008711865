export default function projectCollections(state = [], action) {
  switch (action.type) {
    case 'SIGN_OUT':
      return []

    case 'FETCH_PROJECT_COLLECTIONS_SUCCESS':
      return action.response

    case 'ADD_PROJECT_TO_COLLECTION_REQUEST':
      const existingProject = state.find(p => p.project_id === action.projectId)
      if (existingProject) {
        return state.map(p => 
          p.project_id === action.projectId 
            ? {...p, collections: [...p.collections, action.collectionId]}
            : p
        )
      }
      return [...state, {
        project_id: action.projectId,
        collections: [action.collectionId]
      }]

    case 'REMOVE_PROJECT_FROM_COLLECTION_REQUEST':
      return state.map(p => 
        p.project_id === action.projectId
          ? {...p, collections: p.collections.filter(id => id !== action.collectionId)}
          : p
      )

    default:
      return state
  }
}
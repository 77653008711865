//We want to prevent groups merging with a webcam recoridng group
//we prevent backspace if it is at the start of the first transcript chunk in a group and the previous group is a webcam recordin

export const backspaceHandler = () => {
	return (state, dispatch, view) => {
		const { selection } = state;
		const { $cursor } = selection;
		
		// Proceed only if the selection is a cursor (not a range)
		if (!$cursor) return false;
		
		// Check if the cursor is at the beginning of its parent node
		if ($cursor.parentOffset > 0) return false;

		// Find the current transcript chunk and its depth
		let currentChunk = null;
		let chunkDepth = null;
		for (let i = $cursor.depth; i > 0; i--) {
			const node = $cursor.node(i);
			if (node.type.name === 'transcriptChunk') {
				currentChunk = node;
				chunkDepth = i;
				break;
			}
		}
		if (!currentChunk) return false;

		// Find the transcript group containing this chunk
		let currentGroup = null;
		let groupDepth = null;
		for (let i = chunkDepth - 1; i > 0; i--) {
			const node = $cursor.node(i);
			if (node.type.name === 'transcriptGroup') {
				currentGroup = node;
				groupDepth = i;
				break;
			}
		}
		if (!currentGroup) return false;

		// Get the positions of our current chunk
		const chunkPos = $cursor.before(chunkDepth);
		const groupPos = $cursor.before(groupDepth);
		const chunkIndex = $cursor.index(chunkDepth - 1);// Check if there's any transcriptChunk before this one in the group
		const groupStartPos = $cursor.start(groupDepth);// Get the position at the start of the current 'transcriptGroup'

		// // If this isn't the first chunk in the group (index > 0), allow backspace
		// if (chunkIndex > 0) {
		// 	return false;
		// }

		if (currentGroup.attrs.groupType === 'variable-webcam') {
			const isChunkEmpty = currentChunk.content.size === 2;
			const isOnlyChunk = currentGroup.childCount === 1;
			// Only convert to standard if it's empty and the only chunk
			if (isChunkEmpty && isOnlyChunk) {
				if (dispatch) {
					// Create a new standard group with the same chunk
					const standardGroup = state.schema.nodes.transcriptGroup.createChecked({
						groupId: currentGroup.attrs.groupId,
						groupType: 'standard'
					}, [currentChunk]);
					// Replace the current group with the standard group
					const tr = state.tr.replaceWith(groupPos, groupPos + currentGroup.nodeSize, standardGroup);
					// Set cursor position inside the new standard group
					const newGroupPos = groupPos + 1; // +1 to move inside the group
					tr.setSelection(state.selection.constructor.near(tr.doc.resolve(newGroupPos)));
					dispatch(tr);
				}
				return true;
			}
			return false;
		}

		
		if (chunkIndex > 0) return false;
		// If the group starts at position 0, there's no previous node
		if (groupStartPos === 0) return false;

		// Resolve the position just before the current 'transcriptGroup'
		const $prevPos = state.doc.resolve(groupStartPos - 1);
		
		// Get the node before the current 'transcriptGroup'
		const prevNode = $prevPos.nodeBefore;
		if (prevNode && 
			prevNode.type.name === 'transcriptGroup' && 
			prevNode.attrs.groupType === 'webcam-recording') {

			const isChunkEmpty = currentChunk.content.size === 2;      
			if (!isChunkEmpty) {
				return true; // Don't delete if chunk has content
			}
			///only do this is the chunk is empty! othewse do nothing
			if (dispatch) {
				const chunkStartPos = $cursor.before(chunkDepth);
				const chunkEndPos = $cursor.after(chunkDepth);
				const webCamGroupEnd = groupStartPos - 4;

			if (currentGroup.childCount === 1) {
			// Delete entire group
			const groupEndPos = $cursor.after(groupDepth);
			const tr = state.tr.delete(groupStartPos-1, groupEndPos);
			tr.setSelection(state.selection.constructor.near(tr.doc.resolve(webCamGroupEnd)));
			dispatch(tr);
			} else {
				// Delete just the chunk but still move cursor
				const tr = state.tr.delete(chunkStartPos, chunkEndPos);
				tr.setSelection(state.selection.constructor.near(tr.doc.resolve(webCamGroupEnd)));
				dispatch(tr);
			}
		return true;
		}
	}

		// Allow default behavior
		return false;
	};
};
import { LerpAnimator } from '../animations/LerpAnimator';

// Easing functions to create more natural animations
const easingFunctions = {
  // Linear (no easing)
  linear: t => t,
  
  // Ease out quad - decelerates toward the end
  easeOutQuad: t => t * (2 - t),
  
  // Ease in out quad - accelerates in the middle
  easeInOutQuad: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
  
  // Ease out cubic - more pronounced deceleration
  easeOutCubic: t => (--t) * t * t + 1,
  
  // Ease in out cubic - smooth accelerate/decelerate
  easeInOutCubic: t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    
  easeInOutQuartic: t => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
  
  easeInOutQuint: t => t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,
  
  // Ease in out sextic - extremely pronounced easing
  easeInOutSextic: t => t < 0.5 ? 32 * t * t * t * t * t * t : 1 - 32 * (--t) * t * t * t * t * t,
  
};

// Lerp animation parameters
const lerpParamsMap = {
  zoomSmooth: { 
    baseDuration: 1.2,
    easing: easingFunctions.easeInOutQuartic, 
    precision: 0.01, 
    instant: false 
  },
  zoomZippy: { 
    baseDuration: 0.4, 
    easing: easingFunctions.easeOutQuad, 
    precision: 0.01, 
    instant: false 
  },
  zoomSlow: { 
    baseDuration: 1.2, 
    easing: easingFunctions.easeInOutCubic, 
    precision: 0.01, 
    instant: false 
  },
  zoomInstant: { 
    baseDuration: 0.1, 
    easing: easingFunctions.linear, 
    precision: 0.01, 
    instant: true 
  }
};

const FPS = 60; // Frames per second
const SCENE_WIDTH = 1920;
const SCENE_HEIGHT = 1080;
const defaultCameraBox = { x: 0, y: 0, width: 1920, height: 1080 };

export const calculateLerpAnimatedCameraBoxFrames = (cameraBoxSequence, zoomClips) => {
  const animatedCameraBoxFrames = [];
  
  // Initialize lerp animators for each property
  const propertyAnimators = {};
  let lastMotionSettings = 'zoomSmooth';
  
  Object.keys(defaultCameraBox).forEach(property => {
    const params = lerpParamsMap[lastMotionSettings];
    propertyAnimators[property] = new LerpAnimator(
      params.baseDuration,
      params.easing,
      defaultCameraBox[property],
      params.precision,
      params.instant
    );
  });
  
  // Calculate the total animation duration
  const totalDuration = parseFloat(Object.keys(cameraBoxSequence).pop()) + 5;
  
  // Get all keyframes from the sequence
  const keyframes = Object.entries(cameraBoxSequence)
    .map(([time, values]) => ({ time: parseFloat(time), ...values }))
    .sort((a, b) => a.time - b.time);
  
  // Simulation state variables
  let currentKeyframeIndex = 0;
  let nextKeyframeIndex = 1;
  let currentKeyframe = keyframes[0];
  let nextKeyframe = keyframes.length > 1 ? keyframes[1] : null;
  
  // Simulate the animation frame by frame
  for (let frame = 0; frame <= totalDuration * FPS; frame++) {
    const currentTime = frame / FPS;
    
    // Check if we've reached the next keyframe
    if (nextKeyframe && currentTime >= nextKeyframe.time) {
      // Move to the next keyframe pair
      currentKeyframeIndex++;
      nextKeyframeIndex = currentKeyframeIndex + 1;
      
      currentKeyframe = keyframes[currentKeyframeIndex];
      nextKeyframe = nextKeyframeIndex < keyframes.length ? keyframes[nextKeyframeIndex] : null;
      
      // Update motion settings if needed
      if (currentKeyframe.motionSettings && currentKeyframe.motionSettings !== lastMotionSettings) {
        lastMotionSettings = currentKeyframe.motionSettings;
        const newParams = lerpParamsMap[lastMotionSettings] || lerpParamsMap.zoomSmooth;
        
        Object.keys(propertyAnimators).forEach(property => {
          const currentPos = propertyAnimators[property].position;
          propertyAnimators[property] = new LerpAnimator(
            newParams.baseDuration,
            newParams.easing,
            currentPos,
            newParams.precision,
            newParams.instant
          );
        });
      }
      
      // Set new targets based on next keyframe
      Object.keys(defaultCameraBox).forEach(property => {
        const animator = propertyAnimators[property];
        if (currentKeyframe[property] !== undefined) {
          animator.setTarget(currentKeyframe[property]);
        }
      });
    }
    
    // If we have current and next keyframes, animate between them
    if (currentKeyframe && nextKeyframe) {
      // Calculate how far we are between the two keyframes
      const keyframeDuration = nextKeyframe.time - currentKeyframe.time;
      const timeSinceKeyframe = currentTime - currentKeyframe.time;
      const progress = Math.min(timeSinceKeyframe / keyframeDuration, 1);
      
      // Apply easing function based on motion settings
      const params = lerpParamsMap[lastMotionSettings];
      const easedProgress = params.easing(progress);
      
      // Update all animators
      Object.keys(propertyAnimators).forEach(property => {
        if (currentKeyframe[property] !== undefined && nextKeyframe[property] !== undefined) {
          const startValue = currentKeyframe[property];
          const endValue = nextKeyframe[property];
          
          // Linear interpolation
          propertyAnimators[property].position = startValue + (endValue - startValue) * easedProgress;
        }
      });
    }
    
    // Create frame data
    const frameData = {
      time: currentTime,
      x: propertyAnimators.x.position,
      y: propertyAnimators.y.position,
      width: propertyAnimators.width.position,
      height: propertyAnimators.height.position
    };
    
    animatedCameraBoxFrames.push(frameData);
  }
  
  return animatedCameraBoxFrames;
};
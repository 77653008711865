import React, {useState, useEffect} from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../../misc/Icon';
import {getVoicesForOrg} from '../../../../../utils/voiceover/voicesData'
import SelectVoicePopoverSpeedDropdown from './SelectVoicePopoverSpeedDropdown'
import SelectVoicePopoverVoiceButton from './SelectVoicePopoverVoiceButton'
import { excludedVoiceIds } from './excludedVoices'
import { getOrgId } from '../../../../../utils/getOrgId'


function SelectVoicePopover({ 
  children, 
  activeSpeakerId,
  handleChangeActiveVoice,
  handleChangeVoiceoverPlaybackRate,
  activeVoiceoverPlaybackRate,
  voiceoverMatchType,
  voiceMatch
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showGlobalLanguages, setShowGlobalLanguages] = useState(false);

  const handleClosePopover = () => {
    setDropdownOpen(false);
  };

  const voices = getVoicesForOrg();
  const currentOrgId = getOrgId();
  
  const customVoices = voices.filter(voice => voice.orgId && voice.orgId === currentOrgId);

  
  const hasCustomVoices = customVoices.length > 0 || voiceMatch;
  //const hasCustomVoices = false

  const nonCustomVoices = voices.filter(voice => !voice.orgId || voice.orgId !== currentOrgId);

  const includedVoices = (voices) => {
    return voices.filter(voice => !excludedVoiceIds.includes(voice.id));
  };
  
  const langBtnLabel = showGlobalLanguages ? 'World' : 'English'

  let speedLabel = 'Default Speed'
  if(activeVoiceoverPlaybackRate === 0.92){
    speedLabel = 'Slowest Talking Speed'
  }
  if(activeVoiceoverPlaybackRate === 0.96){
    speedLabel = 'Slow Talking Speed'
  }
  if(activeVoiceoverPlaybackRate === 1.04){
    speedLabel = 'Fast Talking Speed'
  }
  if(activeVoiceoverPlaybackRate === 1.08){
    speedLabel = 'Fastest Talking Speed'
  }

  const getCountryName = (countryCode) => {
    const countryNames = {
      'uk': 'British English',
      'dn': 'Danish',
      'nl': 'Dutch',
      'fr': 'French',
      'de': 'German',
      'it': 'Italian',
      'jp': 'Japanese',
      'ng': 'Nigerian English',
      'es': 'Spanish',
      'se': 'Swedish',
    };
    return countryNames[countryCode] || countryCode.toUpperCase();
  };

   const sortVoices = (voices) => {
    if (!showGlobalLanguages) {
      // Sort by first name for English voices
      return voices.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      // Keep the existing country-based sorting for global languages
      return voices.sort((a, b) => {
        const countryA = a.country ? getCountryName(a.country) : 'USA';
        const countryB = b.country ? getCountryName(b.country) : 'USA';
        if (countryA === countryB) {
          return a.name.localeCompare(b.name);
        }
        return countryA.localeCompare(countryB);
      });
    }
  };

  const filterVoices = (voices) => {
    if (showGlobalLanguages) {
      return voices.filter(voice => voice.country && !['uk', 'ng'].includes(voice.country));
    } else {
      return voices.filter(voice => !voice.country || ['uk', 'ng'].includes(voice.country));
    }
  };

  const [initialSetupDone, setInitialSetupDone] = useState(false);


  const delayedLanguageSwitch = () => {
    setTimeout(() => {
      const activeVoice = voices.find(voice => voice.id === activeSpeakerId);
      setShowGlobalLanguages(activeVoice && activeVoice.country && !['uk', 'ng'].includes(activeVoice.country));
    }, 100);
  };

  useEffect(() => {
    if (!dropdownOpen) {
      delayedLanguageSwitch();
    }
  }, [dropdownOpen, activeSpeakerId, voices]);

  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  let preHeaderLabel
  let preHeaderDescription

  if(voiceoverMatchType === 'preview'){
    preHeaderLabel = 'Select Preview Voice'
    preHeaderDescription = 'After recording from camera inputs, the voice will be matched to the camera input video.'  
  }

  const showHeader = preHeaderLabel && preHeaderDescription
  


  return (
    <Popover.Root 
      open={dropdownOpen} 
      onOpenChange={(open) => {
        setDropdownOpen(open);
        if (!open) {
          delayedLanguageSwitch();
          setCurrentlyPlayingId(null);
        }
      }} 
      modal={true}
    >
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          sideOffset={1}
          align="end"
          alignOffset={-13}
          sideOffset={-35}
          className='dropdownMenu selectVoiceDropdown forceDarkTheme'
        >
          {showHeader &&
          <div className='selectVoiceDropdown-header'>
            <div className='selectVoiceDropdown-header-title'>
              {preHeaderLabel}
            </div>                          
            <div className='selectVoiceDropdown-header-description'>
              {preHeaderDescription}
            </div>
            <div className='selectVoiceDropdown-header-bar' />                                  
          </div>
          }

          {!showHeader && !hasCustomVoices &&
            <div className='selectVoiceDropdown-noHeaderVSpacer' />
          }


          
          <div className='selectVoiceDropdown-closeBtnFixedContainer'>
            <button onClick={() => setDropdownOpen(false)} className='selectVoiceDropdown-header-closeBtn'>
              <div className='selectVoiceDropdown-header-closeBtn-inner'>              
                <Icon name='crossSemibold' />
              </div>
            </button>       
          </div>


          {!hasCustomVoices &&       
          <div className='selectVoiceDropdown-actionBar'>                                    
            <button 
              onClick={() => {
                setShowGlobalLanguages(!showGlobalLanguages);
                setCurrentlyPlayingId(null);
              }} 
              data-global-state={showGlobalLanguages ? true : false} 
              className='selectVoiceDropdown-actionBar-langToggleBtn'
            >
              <div className='selectVoiceDropdown-actionBar-langToggleBtn-iconContainer'>
                <Icon name='globe' />
              </div>
              <div className='selectVoiceDropdown-actionBar-langToggleBtn-label'>
                {langBtnLabel}
              </div>
            </button>              

            <SelectVoicePopoverSpeedDropdown
              handleChangeVoiceoverPlaybackRate={handleChangeVoiceoverPlaybackRate}
              activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
            >
              <button className='selectVoiceDropdown-actionBar-speedToggleBtn'>              
                <div className='selectVoiceDropdown-actionBar-speedToggleBtn-label'>
                  {speedLabel}
                </div>
              </button>     
            </SelectVoicePopoverSpeedDropdown>

            <div className='selectVoiceDropdown-header-hSpacer' />
            
          </div>
          }

          <div className={'selectVoiceDropdown-gridContainer ' + (showGlobalLanguages ? ' selectVoiceDropdown-gridContainer--globalLanguages ' : '')  + (hasCustomVoices ? ' selectVoiceDropdown-gridContainer--hasCustomVoices ' : '')}>
            {hasCustomVoices && (
              <>
                {!showHeader && <div className='selectVoiceDropdown-gridContainer-customTopSpacer'/> }
                <div className='selectVoiceDropdown-gridContainer-sectionHeader selectVoiceDropdown-gridContainer-sectionHeader--custom'>
                  <div className='selectVoiceDropdown-gridContainer-sectionHeader-title'>
                    Custom Voices
                  </div>
                </div>
                <div className='selectVoiceDropdown-grid'>
                  <div className='selectVoiceDropdown-grid-grid'>
                    {voiceMatch && (
                      <SelectVoicePopoverVoiceButton 
                          key={voiceMatch.voiceId}
                          voice={{...voiceMatch.voiceId,name:'Matched',id:voiceMatch.voiceId}}
                          active={activeSpeakerId === voiceMatch.voiceId}
                          handleClosePopover={handleClosePopover}
                          handleChangeActiveVoice={handleChangeActiveVoice}
                          hideSubLabel 
                          currentlyPlayingId={currentlyPlayingId}
                          setCurrentlyPlayingId={setCurrentlyPlayingId}                         
                      />
                    )}

                    {includedVoices(customVoices)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(voice => (
                        <SelectVoicePopoverVoiceButton 
                          key={voice.id}
                          voice={voice}
                          active={activeSpeakerId === voice.id}
                          handleClosePopover={handleClosePopover}
                          handleChangeActiveVoice={handleChangeActiveVoice}
                          hideSubLabel 
                          currentlyPlayingId={currentlyPlayingId}
                          setCurrentlyPlayingId={setCurrentlyPlayingId}                         
                        />
                    ))}
                  </div>
                </div>
                <div className='selectVoiceDropdown-gridContainer-customBottomSpacer'/>
              </>
            )}

            {hasCustomVoices && 
              <div className='selectVoiceDropdown-gridContainer-sectionHeader'>
                <div className='selectVoiceDropdown-gridContainer-sectionHeader-tabs'>
                  <button 
                    data-state={!showGlobalLanguages ? 'active' : 'inactive'} 
                    onClick={() => {
                      setShowGlobalLanguages(false);
                      setCurrentlyPlayingId(null);
                    }} 
                    className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab'
                  >                  
                    <div className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-label'>
                      English                      
                    </div>
                    <div className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder'/>
                  </button>
                  <button 
                    data-state={showGlobalLanguages ? 'active' : 'inactive'}
                    onClick={() => {
                      setShowGlobalLanguages(true);
                      setCurrentlyPlayingId(null);
                    }} 
                    className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab'
                    >
                    <div className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-label'>                    
                      World
                    </div>
                    <div className='selectVoiceDropdown-gridContainer-sectionHeader-langToggleTab-bottomBorder'/>
                  </button>
                </div>
              </div>
            }

            <div className='selectVoiceDropdown-grid'>
              <div className='selectVoiceDropdown-grid-grid'>
                {sortVoices(filterVoices(includedVoices(nonCustomVoices)))
                  .map(voice => (
                    <SelectVoicePopoverVoiceButton 
                      key={voice.id}
                      voice={voice}
                      active={activeSpeakerId === voice.id}
                      handleClosePopover={handleClosePopover}
                      handleChangeActiveVoice={handleChangeActiveVoice}
                      showSubLabel={showGlobalLanguages}
                      currentlyPlayingId={currentlyPlayingId}
                      setCurrentlyPlayingId={setCurrentlyPlayingId}
                    />
                ))}
              </div>
            </div>
          </div>

          {hasCustomVoices && 
            <div className='selectVoiceDropdown-footer'>
              <SelectVoicePopoverSpeedDropdown
                handleChangeVoiceoverPlaybackRate={handleChangeVoiceoverPlaybackRate}
                activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate}
              >
                <button className='selectVoiceDropdown-actionBar-speedToggleBtn'>              
                  <div className='selectVoiceDropdown-actionBar-speedToggleBtn-label'>
                    {speedLabel}
                  </div>
                </button>     
              </SelectVoicePopoverSpeedDropdown>
            </div>
          }

          
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default SelectVoicePopover;
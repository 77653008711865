import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import Icon from '../../misc/Icon'
import MoveToCollectionPopover from '../../uiKit/moveToCollection/MoveToCollectionPopover'
import {shareProjectWithTeam} from '../../../utils/projectUtils/shareProjectWithTeam'
import {archiveProject} from '../../../utils/projectUtils/archiveProject'
import {duplicateProject} from '../../../utils/projectUtils/duplicateProject'
import {getOrgNameLabel} from '../../../utils/getOrgNameLabel'
import {orgHasCollections} from '../../../utils/collections/orgHasCollections'
import {restoreProject} from '../../../utils/projectUtils/restoreProject'
import { useHistory } from 'react-router-dom'

const LibraryProjectDropdownMenuContents = ({
  setShowMoveToPopover, 
  showMoveToPopover,
  project,
  closeDropdown,
  renameProject,
  alignOffset, 
  sideOffset,
  pendingChanges,
  handleCollectionChange
}) => {

  const history = useHistory();
  const isPrivate = project.is_private
  let orgNameLabel = getOrgNameLabel()
  const hasCollections = orgHasCollections() // org has collections
  const isArchived = project.archived_at?true:false

  return (
    <>              
      {isArchived &&          
        <DropdownMenu.Content 
          align="end"
          alignOffset={alignOffset}
          sideOffset={sideOffset}
          className='dropdownMenuWrapper dropdownMenuWrapper--libraryProject'
          onEscapeKeyDown={closeDropdown}
          onPointerDownOutside={closeDropdown}
          onFocusOutside={closeDropdown}
          onInteractOutside={closeDropdown}
        >        
            <div data-state={!showMoveToPopover ? "open" : "closed"} className='dropdownMenu dropdownMenu--libraryProject forceDarkTheme'>                    
               
              
              <DropdownMenu.Item onSelect={() => restoreProject(project.id,history)}>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='moveToMedium' />
                </div>
                Restore Project
              </DropdownMenu.Item>            

            </div>

        </DropdownMenu.Content>
      }

      {!isArchived &&
        <DropdownMenu.Content 
          align="end"
          alignOffset={alignOffset}
          sideOffset={sideOffset}
          className='dropdownMenuWrapper dropdownMenuWrapper--libraryProject'
          onEscapeKeyDown={closeDropdown}
          onPointerDownOutside={closeDropdown}
          onFocusOutside={closeDropdown}
          onInteractOutside={closeDropdown}
        >        
            <div data-state={!showMoveToPopover ? "open" : "closed"} className='dropdownMenu dropdownMenu--libraryProject forceDarkTheme'>                    
               
              {!isPrivate && hasCollections &&
                <DropdownMenu.Item
                  onSelect={(event) => {
                    event.preventDefault();
                    setShowMoveToPopover(true);
                  }}
                >
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='plusMedium' />
                  </div>
                  Add to Collection
                </DropdownMenu.Item>  
              }   
              

              {isPrivate && 
                <DropdownMenu.Item                 
                  onClick={(event) => {
                    event.preventDefault();
                    shareProjectWithTeam(project.id);
                  }}
                >
                  <div className='dropdownMenu-item-iconContainer'>
                    <Icon name='moveToMedium' />
                  </div>
                  Move to {orgNameLabel}
                </DropdownMenu.Item>  
              }
              
              <DropdownMenu.Item onSelect={renameProject}>
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='aaTextMedium' />
                </div>
                Rename
              </DropdownMenu.Item>            

              <DropdownMenu.Item  onSelect={() => duplicateProject(project.id)} >
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='duplicateMedium' />
                </div>
                Make a Copy
              </DropdownMenu.Item>   
            
              <DropdownMenu.Separator />  
                      
              <DropdownMenu.Item onSelect={() => archiveProject(project.id)} className='contextMenu-item--light'>              
                <div className='dropdownMenu-item-iconContainer'>
                  <Icon name='deletedMedium' />
                </div>
                Delete
              </DropdownMenu.Item>   
            </div>
          

          <MoveToCollectionPopover 
            showMoveToPopover={showMoveToPopover}
            project={project}
            pendingChanges={pendingChanges}
            handleCollectionChange={handleCollectionChange}
          />

        </DropdownMenu.Content>
      }
    </>
  );
};

export default LibraryProjectDropdownMenuContents;
import { InputRule } from 'prosemirror-inputrules';
import { schema } from '../schema/transcriptSchema';
import { getAnchorNode } from '../utils/getAnchorNode';

function findClipIndex(state, clipId) {
	let clipIndex = 0;
	let currentGroupType = null;
	let foundIndex = -1; // Track the found index separately
	state.doc.descendants((node, pos) => {    
		if (node.type.name === "sceneHeader") {
			clipIndex = 0;
		}
		else if (node.type.name === "transcriptGroup") {
			currentGroupType = node.attrs.groupType;
			if(currentGroupType=='webcam-recording' || currentGroupType=='variable-webcam'){
				clipIndex+=1
			}
		}

		else if (node.type.name === "transcriptChunk") {      
			if (currentGroupType !== 'variable-webcam') {        
				// Store the index when we find the matching clip
				if (node.attrs.clipId === clipId) {
					foundIndex = clipIndex;
				}
				const textContent = node.textContent.trim();
				if (textContent) {
					clipIndex += 1;
				}
			}
		}
	});
	
	// Return the found index, or 0 if not found
	return foundIndex >= 0 ? foundIndex : 0;
}

export function insertSceneInputRule(addSceneFromTranscriptPanel) {
 // return new InputRule(/^#$/, (state, match, start, end) => {
	return new InputRule(/^(#|-{3})$/, (state, match, start, end) => {
		let { $from } = state.selection;
		const transcriptChunk = getAnchorNode($from, 'transcriptChunk');
		
		if (transcriptChunk) {
			// Check for parent transcript group and its type
			const transcriptGroup = getAnchorNode($from, 'transcriptGroup');
			const isWebcamGroup = transcriptGroup?.attrs.groupType === 'webcam';
			const textWithoutTrigger = transcriptChunk.textContent.replace(/^(-+|#)/, '').trim();
			
			// Only proceed if we're not in a webcam group and the chunk is empty
			if (!isWebcamGroup && !textWithoutTrigger) {
				const { attrs } = transcriptChunk;
				const { clipId, sceneId } = attrs;
				const clipIndex = findClipIndex(state, clipId);
				addSceneFromTranscriptPanel(sceneId, clipId, clipIndex);
			}
		}
		return null;
	});
}
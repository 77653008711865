import React, { useMemo, useState } from 'react'
import Icon from '../../misc/Icon'
import EditorDetailPanelText from './EditorDetailPanelText'
import EditorDetailPanelWebcam from './EditorDetailPanelWebcam'
import EditorDetailPanelImage from './EditorDetailPanelImage'
import EditorDetailPanelScreen from './EditorDetailPanelScreen'
import EditorDetailPanelLegacyScreen from './EditorDetailPanelLegacyScreen'
import EditorDetailPanelVideo from './EditorDetailPanelVideo'
import EditorDetailPanelBasicVideo from './EditorDetailPanelBasicVideo'
import EditorDetailPanelMobile from './EditorDetailPanelMobile'
import EditorDetailPanelSlide from './EditorDetailPanelSlide'
import EditorDetailPanelLayoutGroup from './EditorDetailPanelLayoutGroup'
import EditorDetailPanelChart from './charts/EditorDetailPanelChart'
import EditorDetailPanelMixedSelection from './EditorDetailPanelMixedSelection'
import { getWebcamSizeForTime } from '../../../utils/webcam/getWebcamRectForTime'
import { groupCharactersIntoWords } from '../../../timeline/utils/groupCharactersIntoWords'

const EditorDetailPanel = (props) => {

  const {selectedSlideItems,activePanelClip,focusedSlideElement, sceneWidth, sceneHeight, webcamClips, audioClips} = props

  let detailPanelType = null
  let selectedElement = null;
  let selectedLayoutGroupId = null
  


  const calculateWordsOverlappingSlide = () => {
    if (!activePanelClip || activePanelClip.type !== 'slide') {
      return []
    }
    const relevantWebcamClips = webcamClips.filter(clip => clip.startTime <= activePanelClip.endTime && clip.endTime >= activePanelClip.startTime)
    const relevantAudioClips = audioClips.filter(clip => clip.startTime <= activePanelClip.endTime && clip.endTime >= activePanelClip.startTime)
    const words = []
    relevantWebcamClips.forEach(clip => {
      const allWords = clip.calculateFormattedWords()
      allWords.forEach(word => {
        const startTime = clip.startTime + word.start_time
        const endTime = clip.startTime + word.end_time
        if(startTime <= activePanelClip.endTime && endTime >= activePanelClip.startTime){
          words.push({startTime: Math.max(0, startTime - activePanelClip.startTime), endTime: Math.min(endTime - activePanelClip.startTime, activePanelClip.endTime - activePanelClip.startTime), word: word.word})
        }
      })
    })
    relevantAudioClips.forEach(clip => {
      if (!clip.metadata.alignment) {
        return
      }
      const allWords = groupCharactersIntoWords(clip.metadata.alignment)
      
      allWords.forEach(word => {
        const startTime = clip.startTime + word.start_time
        const endTime = clip.startTime + word.end_time
        if(startTime <= activePanelClip.endTime && endTime >= activePanelClip.startTime){
          words.push({startTime: Math.max(0, startTime - activePanelClip.startTime), endTime: Math.min(endTime - activePanelClip.startTime, activePanelClip.endTime - activePanelClip.startTime), word: word.word})
        }
      })
    })
    return words.sort((a, b) => a.startTime - b.startTime)
  }

  let wordsOverlappingSlide = useMemo(() => {
    return calculateWordsOverlappingSlide()
  }, [activePanelClip, webcamClips, audioClips, activePanelClip?.startTime, activePanelClip?.endTime])

  if (activePanelClip && activePanelClip.type === 'slide') {
    detailPanelType = 'slide';
    if(selectedSlideItems.length==1 && selectedSlideItems[0].type=='layoutGroup'){
      detailPanelType='layoutGroup'
      selectedLayoutGroupId = selectedSlideItems[0].id
      //selectedLayoutGroup = 
    }

    else if (selectedSlideItems.length === 1 && selectedSlideItems[0].type=='element') {
      const selectedElementId = selectedSlideItems[0].id;
      selectedElement = activePanelClip.elements.find(element => element.id === selectedElementId);
      if (selectedElement) {
      if(selectedElement.type=='image'){
        detailPanelType='image'
      }
      if(selectedElement.type=='text'){
        detailPanelType='text'
      }
      if(selectedElement.type=='chart'){
        detailPanelType='chart'
      }
    } else {
      console.warn('Selected element not found in activePanelClip.elements');
      }
    }
  }

  if (activePanelClip && activePanelClip.type === 'video' && !activePanelClip.isBasicVideo && !activePanelClip.isDeviceRecording) {
    detailPanelType = 'screen';
  }
  if (activePanelClip && activePanelClip.type === 'video' && !activePanelClip.isBasicVideo && activePanelClip.isDeviceRecording) {
    detailPanelType = 'mobile';
  }

  let webcamSize = null
  if (activePanelClip && activePanelClip.type === 'webcam') {
    detailPanelType = 'webcam';
    webcamSize = getWebcamSizeForTime(activePanelClip.metadata.layout, props.currentTime, props.slideClips, props.videoClips)
  }
  if (activePanelClip && activePanelClip.isBasicVideo) {
    detailPanelType = 'basicVideo';
  }

  const handleDevButtonClick = () => {
    setCurrentClipTypeIndex((prevIndex) => (prevIndex + 1) % clipTypes.length)
  }

  if(selectedSlideItems.length>1){
    detailPanelType='mixedSelection'
  }


  return (      
    <div data-detail-panel-type={(detailPanelType && !props.readOnlyMode)  ? detailPanelType : 'none'} className='editor-detailPanel'>
    

      {detailPanelType === 'slide' && !props.readOnlyMode &&
        <EditorDetailPanelSlide
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideAlignment={props.updateSlideAlignment}
          slideClip={props.activePanelClip}
          updateSlideElementAnimationIndex={props.updateSlideElementAnimationIndex}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          updateSlideBackgroundColor={props.updateSlideBackgroundColor}
          clipId={activePanelClip.id}
          projectId={props.projectId}
          wordsOverlappingSlide={wordsOverlappingSlide}
          applyBulletPointsToSlide={props.applyBulletPointsToSlide}
        />
      }      


      {detailPanelType === 'text' && !props.readOnlyMode &&
      	<EditorDetailPanelText 
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          clipId={activePanelClip.id}
          element={selectedElement}
          updateSlideElementZOrder={props.updateSlideElementZOrder}
          slideElementTextSelectionInfo={props.slideElementTextSelectionInfo}
          focusedSlideElement={focusedSlideElement}
          updateSlideTextElementTextProperties={props.updateSlideTextElementTextProperties}
      	/>
      }


       {detailPanelType === 'chart' && !props.readOnlyMode &&
        <EditorDetailPanelChart 
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          handleDimensionsUpdatedFromDetailPanel={props.handleDimensionsUpdatedFromDetailPanel}
          projectBackgroundId={props.projectBackgroundId}
          slideBackgroundId={activePanelClip.background}
          clipId={activePanelClip.id}
          element={selectedElement}
        />
      }


      {/* Maybe add this back in again in the future, it's tricky with layouts and stuff 
      {detailPanelType === 'webcam' && !props.readOnlyMode &&
	      <EditorDetailPanelWebcam 
          key={activePanelClip.id}
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipMetadata={props.updateClipMetadata}
          clip={activePanelClip}
          toggleTrimMode={props.toggleTrimMode}
          updateTranscripPanelFromTimeline={props.updateTranscripPanelFromTimeline}
          size={webcamSize}
	      />
    	}
      */}


    	{detailPanelType === 'screen' && !props.readOnlyMode && activePanelClip.sessionCaptureId &&
	      <EditorDetailPanelScreen
          clip={activePanelClip}
	      	isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}    
          trimMode={props.trimMode}
          toggleTrimMode={props.toggleTrimMode}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          defaultMotionStyle={props.defaultMotionStyle}
          projectBackgroundId={props.projectBackgroundId}
          updateClipBackgroundColor={props.updateClipBackgroundColor}
          changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}
          unlinkScreencast={props.unlinkScreencast}      
          setVideoWindowPadding={props.setVideoWindowPadding}
	      />
    	}

      {detailPanelType === 'screen' && !props.readOnlyMode && !activePanelClip.sessionCaptureId &&
        <EditorDetailPanelLegacyScreen
          clip={activePanelClip}
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}    
          trimMode={props.trimMode}
          toggleTrimMode={props.toggleTrimMode}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          defaultMotionStyle={props.defaultMotionStyle}
          projectBackgroundId={props.projectBackgroundId}
          updateClipBackgroundColor={props.updateClipBackgroundColor}
          changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}      
          setVideoWindowPadding={props.setVideoWindowPadding}
	      />
    	}

      {detailPanelType === 'mobile' && !props.readOnlyMode &&
        <EditorDetailPanelMobile
          clip={activePanelClip}
          isDragResizingNumberInput={props.isDragResizingNumberInput}          
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          trimMode={props.trimMode}
          toggleTrimMode={props.toggleTrimMode}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          defaultMotionStyle={props.defaultMotionStyle}
          projectBackgroundId={props.projectBackgroundId}
          updateClipBackgroundColor={props.updateClipBackgroundColor}        
          changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}      
        />
      }



      {detailPanelType === 'basicVideo' && !props.readOnlyMode &&
        <EditorDetailPanelBasicVideo
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          updateClipBackgroundColor={props.updateClipBackgroundColor}
          clip={activePanelClip}
          toggleTrimMode={props.toggleTrimMode}
          sceneWidth={sceneWidth}
          sceneHeight={sceneHeight}
        />
      }


      {/* J: not sure this is being used? */}
    	{detailPanelType === 'video' && !props.readOnlyMode &&
	      <EditorDetailPanelVideo
	      	isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          sceneWidth={sceneWidth}
          sceneHeight={sceneHeight}
	      />
    	}

      
      {detailPanelType === 'image' && !props.readOnlyMode &&
      	<EditorDetailPanelImage 
          key={selectedElement.id}
      		isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          handleDimensionsUpdatedFromDetailPanel={props.handleDimensionsUpdatedFromDetailPanel}
          clipId={activePanelClip.id}
          element={selectedElement}
          updateSlideElementZOrder={props.updateSlideElementZOrder}
          insertImageFromRecent={props.insertImageFromRecent}
          handleSlideImageFileUpload={props.handleSlideImageFileUpload}
          showReplaceImageMediaModal={props.showReplaceImageMediaModal}
          handleSetShowReplaceImageMediaModal={props.handleSetShowReplaceImageMediaModal}
          sceneWidth={sceneWidth}
          sceneHeight={sceneHeight}
      	/>
      }


      {detailPanelType === 'layoutGroup' && !props.readOnlyMode && 
        <EditorDetailPanelLayoutGroup
          slide={activePanelClip}
          layoutGroupId={selectedLayoutGroupId}
          updateLayoutGroupField={props.updateLayoutGroupField}
          ungroupLayoutGroup={props.ungroupLayoutGroup}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
     
        />
      }


      {detailPanelType === 'mixedSelection' && !props.readOnlyMode &&
        <EditorDetailPanelMixedSelection
          groupSlideItems={props.groupSlideItems}
        />
      }


              
    </div>
  )
}

export default EditorDetailPanel
import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

function SubscriptionPanelInviteDialog({ 
  isOpen, 
  onOpenChange, 
  inviteType,
  onInvite 
}) {
  const [emails, setEmails] = useState('');

  const handleSubmit = () => {
    // Split emails by commas or newlines and trim whitespace
    const emailList = emails
      .split(/[\n,]/)
      .map(email => email.trim())
      .filter(email => email.length > 0);
      
    onInvite(emailList);
    setEmails('');
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className='settingsModal-inviteDialogOverlay' />
        <Dialog.Content className='settingsModal-inviteDialog forceDarkTheme'>
          <div className='settingsModal-inviteDialog-header'>
	          <div className='settingsModal-inviteDialog-header-title'>
	            {inviteType === 'members' ? 'Invite Team Members' : 'Invite Guests'}
	          </div>          
	          <div className='settingsModal-inviteDialog-header-hSpacer' />
	          <Dialog.Close asChild>
	            <button className='settingsModal-inviteDialog-header-closeBtn'>
	              Cancel
	            </button>
            </Dialog.Close>
	        </div>

	        {inviteType === 'guests' &&
	        	<div className='settingsModal-inviteDialog-guestExplanation'>
	        		<div className='settingsModal-inviteDialog-guestExplanation-para'>
	        			Guests can create drafts and add projects to the team library.
	        		</div>
	        		<div className='settingsModal-inviteDialog-guestExplanation-para'>
	        			They can't see the projects made by other people, any collections or shared assets, or view the team member list.
	        		</div>
	        	</div>	
	      	}

	        <div className='settingsModal-inviteDialog-middle'>
	        	<div className='settingsModal-inviteDialog-middle-title'>
	        		{inviteType === 'members' ? 'Email addresses' : 'Guest email addresses'} 
	        	</div>
	        	<textarea
              autoFocus
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              placeholder="email@example.com, email2@example.com..."
              className="settingsModal-inviteDialog-middle-textArea"
              rows={3}
            />
	        </div>

	        <div className='settingsModal-inviteDialog-footer'>
	        	<button disabled={emails.length < 1} className='settingsModal-panel-section-submitBtn' onClick={handleSubmit}>
              Send Invites
            </button>
	        </div>                  
          
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default SubscriptionPanelInviteDialog;
import {ExportTimelineScene} from './ExportTimelineScene'
import {CodecVideoClip} from '../../timeline/CodecVideoClip'

//Simplified version of timeline.js used for server side export

class ExportTimeline {

	
	constructor(exportStartTime,exportEndTime,onTimeUpdate,renderVariables,aspectRatio,videoWindowPadding) {
		console.log('ExportTimeline constructor')
		console.log('this.renderVariables', renderVariables)
		this._currentTime = exportStartTime || 0;
		this.exportStartTime = exportStartTime 
		this.exportEndTime = exportEndTime
		this._onTimeUpdate = onTimeUpdate
		this._scenes = [];
		this.loadMediaPromises=[]
		this.renderVariables=renderVariables
		this.aspectRatio=aspectRatio
		this._videoWindowPadding=videoWindowPadding
	}


	async initScenes(scenes){//This happens on load timeline
		scenes.forEach((scene)=>{
			this.addScene(scene)
		})
		this.calculateDuration()
	}


	addLoadMediaPromise(promise) {
  		this.loadMediaPromises.push(promise);
	}

	findClipForId(clipId) {
		for (const scene of this._scenes) {
			for (const clip of scene.clips) {
				if (clip.id === clipId) {
					return clip;
				}
			}
		}
		return null;
	}

	findSceneForId(sceneId) {
		for (const scene of this._scenes) {
			if (scene.id == sceneId) {
				return scene;
			}	
		}
		return null;
	}


	addScene(scene) {
		const timelineScene = new ExportTimelineScene(scene,this.addLoadMediaPromise.bind(this),this.renderVariables,this.exportStartTime,this.exportEndTime, this.aspectRatio, () => this.videoWindowPadding)		
		timelineScene.initScene(scene.clips)
		this._scenes.push(timelineScene);
	}

	
	seek(time) {
		this._currentTime =Math.max(time, 0) //allow seeking beyond video
		if (this._onTimeUpdate) {
			this._onTimeUpdate(this._currentTime); // Update parent component
		}

		this.clips.forEach(clip => {
			if ( clip instanceof CodecVideoClip) {
				clip.seek(this._currentTime);
			}
		});
	}




	get duration() {
		return this._duration;
	}

	get currentTime() {
		return this._currentTime;
	}

	get isPlaying() {
		return this._isPlaying;
	}


get clips() {
	const clips= this._scenes.sort((a, b) => a.startTime - b.startTime)
		.flatMap((scene, sceneIndex) => 
			scene.clips.map(clip => (
				clip
			))
		);
		return clips
	}


	calculateDuration() {
		let cumulativeDuration = 0;
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);

			// this._scenes.sort((a, b) => a.startTime - b.startTime);
		this._scenes.forEach((scene, index) => {
			if (index === 0) {
				scene.startTime = 0;
			} else {
				scene.startTime = cumulativeDuration;
			}
			// scene.sceneIndex=index
			cumulativeDuration += scene.duration;
		})

		this._duration = cumulativeDuration;
	}


	get scenes() {
		return this._scenes
			.sort((a, b) => a.startTime - b.startTime)
	}

	get videoWindowPadding() {
		return this._videoWindowPadding
	}

	destroy() {
		if (this._isPlaying) {
			this.pause();
		}
		if (this._playbackInterval) {
			clearInterval(this._playbackInterval);
			this._playbackInterval = null;
		}
		if (this._syncInterval) {
			clearInterval(this._syncInterval);
			this._syncInterval = null;
		}
		
		this._scenes.forEach(scene => {
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
		});
		this._scenes = null;
	}

}

export { ExportTimeline }




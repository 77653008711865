import React from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { Rnd } from 'react-rnd'
import { TextSelection } from 'prosemirror-state'
import SlideEditorSnapLines from './SlideEditorSnapLines'
import CameraEditor from  '../../../components/editor/cameraEditor/CameraEditor'
import SlideEditorTextInput from './SlideEditorTextInput'
import SlideTextElementGhost from './SlideTextElementGhost'
import SlideImageElementPreview from './SlideImageElementPreview'
import SlideEditorCanvasPreview from './SlideEditorCanvasPreview'
import SlideEditorResizableHandles from './SlideEditorResizableHandles'
import SlideEditorDragSelectArea from './SlideEditorDragSelectArea'
import SlideEditorSpacers from './SlideEditorSpacers'
import getSlideConfigs from '../../../timeline/slide/slideUtils/getSlideConfigs'
import {calculateElementPositions} from '../../../timeline/slide/slideUtils/calculateElementPositions'
import { getBackgroundForId } from '../../../utils/brands/getBackgroundForId'
import {calculateSlideSnapLines} from './utils/calculateSlideSnapLines'
import {calculateSnappedPosition} from './utils/calculateSnappedPosition'
import {findParentLayoutGroup} from './utils/findParentLayoutGroup'
import {findImmediateParentLayoutGroup} from './utils/findImmediateParentLayoutGroup'
import {findGroupById} from './utils/findGroupById'
import {simpleHash} from './utils/simpleHash'
import {findNextLayoutGroup} from './utils/findNextLayoutGroup'


//TODO seperate this out into a shared util
function luminance(r, g, b) {
		const a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928 ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Function to determine if background is light or dark
function isSlideBackgroundLight(rgba) {
		const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)/i;
		const matches = regex.exec(rgba);
		if (matches) {
				const lum = luminance(parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3]));
				return lum > 0.5;
		}
		return false; // Default in case of an error
}


//We have elements and layoutgroups.  both collectively are "items"

class SlideEditor extends React.Component{  
	constructor(props) {
		super(props);
		this.state = {
			slideHash: this.getSlideHash(props.slide),
			isDraggingBottomMargin: false,
			isDraggingToSelect: false,
			dragPosition:null,
			dragElementX:null, //for updating view
			dragElementY:null, //for updating view
			isMouseDownOnUnselectedElement: false, //this is to prevent it being focusable on first mouse down on element
			mouseDownElementId:null,
			isDragging:false,    
			snapLines:[]
		};	
		this.editorRef = React.createRef();
	}

	componentDidMount() {
		this.calculatePositions();
		document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		this.props.setSlideEditorSelection([])
		document.removeEventListener('keydown', this.handleKeyDown);
	}

	componentDidUpdate() {
		const newHash = this.getSlideHash(this.props.slide);
		if (newHash !== this.state.slideHash) {
			this.setState({ slideHash: newHash }, () => {
				this.calculatePositions();
			});
		}
	}

	isSlideItemSelected = (itemId) => {
		return this.props.selectedSlideItems.some(
			selectedItem => selectedItem.id === itemId 
		);
	}

	isSlideItemLocked = (itemId) => {
		const checkParentLocks = (currentId, layout) => {
			const parent = findImmediateParentLayoutGroup(currentId, layout);
			if (!parent) {
				return false; // We've reached the root without finding a locked group
			}
			if (parent.isLocked) {
				return true; // Found a locked parent
			}
			// Recursively check the parent's parent
			return checkParentLocks(parent.id, layout);
		};
		return checkParentLocks(itemId, this.props.slide.layout);
	}

	//For arrow key to move element only do it if you have selected elements and you are not in transcript panel or detail panel
	shouldMoveItem=()=>{
		if (this.props.focusedSlideElement) {
			return false
		}
		const activeElement = document.activeElement;

		const disableMove = activeElement && (
			activeElement.tagName === 'INPUT' || 
			activeElement.tagName === 'TEXTAREA' ||
			activeElement.isContentEditable 
		);
		const hasProsemirrorClass = activeElement && Array.from(activeElement.classList).some(className => className.includes('ProseMirror'))
		const hasSpreadsheetClass = activeElement && Array.from(activeElement.classList).some(className => className.includes('spreadsheet'))

		if(disableMove || hasProsemirrorClass || hasSpreadsheetClass){
			return false
		}
		 // Check if any selected item has a parent layout group (other than root)
		const { selectedSlideItems, slide } = this.props;
		return selectedSlideItems.every(item => {
			const parentGroup = findParentLayoutGroup(item.id, slide.layout, false);
			return !parentGroup; // Return true if there's no parent group (other than root)
		});
		return true
	}

	handleKeyDown = (event) => {
		const { selectedSlideItems, focusedSlideElement, slide } = this.props;
		if ((event.metaKey || event.ctrlKey) && event.key === 'd') {
			event.preventDefault();
			this.props.duplicateSlideItems();
			return;
		}
		if ((event.metaKey || event.ctrlKey) && event.key === 'g') {
			event.preventDefault();
			if (event.shiftKey) {// Command+Shift+G (or Ctrl+Shift+G): Ungroup
				if(this.props.selectedSlideItems.length==1 && this.props.selectedSlideItems[0].type=='layoutGroup'){
					this.props.ungroupLayoutGroup(this.props.selectedSlideItems[0].id)
				}
			} else {// Command+G (or Ctrl+G): Group
				this.props.groupSlideItems();
			}
			return;
		}

		if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
			return;
		}
		const shouldMove = this.shouldMoveItem()		
		if(shouldMove){
			event.preventDefault();
			const moveDistance = event.shiftKey ? 10 : 1;
			const moveDelta = {
				ArrowUp: { x: 0, y: -moveDistance },
				ArrowDown: { x: 0, y: moveDistance },
				ArrowLeft: { x: -moveDistance, y: 0 },
				ArrowRight: { x: moveDistance, y: 0 }
			}[event.key];
	
			selectedSlideItems.forEach(item => {
				if (item.type === 'element') {
					const element = slide && slide.elements.find(el => el.id === item.id);
					if (element) {
						this.moveElement(element, moveDelta);
					}
				} else if (item.type === 'layoutGroup') {
					const layoutGroup = slide && slide.layoutGroups.find(group => group.id === item.id);
					if (layoutGroup) {
						this.moveLayoutGroup(layoutGroup, moveDelta);
					}
				}
			});
			this.calculatePositions();
		}
	}

	moveElement = (element, delta) => {
		element.x += delta.x;
		element.y += delta.y;
		this.props.updateSlideElementField(this.props.slide.id, element.id, 'x', element.x);
		this.props.updateSlideElementField(this.props.slide.id, element.id, 'y', element.y);
	}

	moveLayoutGroup = (layoutGroup, delta) => {
		layoutGroup.x += delta.x;
		layoutGroup.y += delta.y;
		this.moveLayoutGroupChildren(layoutGroup, delta);
	}

	moveLayoutGroupChildren = (layoutGroup, delta) => {
		layoutGroup.children.forEach(child => {
			if (child.type === 'element') {
				child.x += delta.x;
				child.y += delta.y;
				this.props.updateSlideElementField(this.props.slide.id, child.id, 'x', child.x);
				this.props.updateSlideElementField(this.props.slide.id, child.id, 'y', child.y);
			} else if (child.type === 'layoutGroup') {
				this.moveLayoutGroup(child, delta);
			}
		});
	}


	//click element logic
	// layout groups and elements can be selected
	//single click selects the outermost parent layout group for the element. 
		// Unless an element within the same immediate group is selected in which case you can select that element
 //double click drills down a level (either the next layout group down the heirachy or if not available then selects the element itself)
//can only multi select elements in the same parent group
//TODO maybe when clicking into an element that is in a nested layout group but has the same parent it should select the group instead of element

handleElementClick = (element, event) => {
	const { slide, setSlideEditorSelection, selectedSlideItems } = this.props;
	const isDoubleClick = event.detail === 2;
	const isMultiSelect = event.shiftKey;

if (isMultiSelect) {
		// Handle multi-selection
		const elementParentGroup = findImmediateParentLayoutGroup(element.id, slide.layout);
		const selectedElementItems = selectedSlideItems.filter(item => item.type === 'element');
		const selectedLayoutGroups = selectedSlideItems.filter(item => item.type === 'layoutGroup');

		if (selectedElementItems.length === 0 && selectedLayoutGroups.length === 0) {
			// No existing selection, start a new one
			setSlideEditorSelection([{ type: elementParentGroup ? 'layoutGroup' : 'element', id: elementParentGroup ? elementParentGroup.id : element.id }]);
		} else {
			// Check if the element can be added to the existing selection
			const selectedElementIds = selectedElementItems.map(item => item.id);
			const selectedParentGroups = selectedElementIds.map(id => findImmediateParentLayoutGroup(id, slide.layout));
			const allSelectedHaveNoParentGroup = selectedParentGroups.every(group => !group);

			if (allSelectedHaveNoParentGroup && elementParentGroup) {
				// Existing selection has elements with no parent group, and clicked element has a parent group
				// Select the parent layout group of the clicked element and add it to the selection
				const isGroupAlreadySelected = selectedSlideItems.some(item => item.type === 'layoutGroup' && item.id === elementParentGroup.id);
				if (isGroupAlreadySelected) {
					// Deselect the group if already selected
					const newSelection = selectedSlideItems.filter(item => !(item.type === 'layoutGroup' && item.id === elementParentGroup.id));
					setSlideEditorSelection(newSelection);
				} else {
					setSlideEditorSelection([...selectedSlideItems, { type: 'layoutGroup', id: elementParentGroup.id }]);
				}
			} else {
				// Proceed with normal multi-select logic
				const allInSameGroup = selectedParentGroups.every(group => {
					if (!group && !elementParentGroup) {
						return true;
					}
					if (!group || !elementParentGroup) {
						return false;
					}
					return group.id === elementParentGroup.id;
				});

				if (allInSameGroup) {
					const isAlreadySelected = selectedSlideItems.some(item => item.type === 'element' && item.id === element.id);
					if (isAlreadySelected) {
						// Deselect the element
						const newSelection = selectedSlideItems.filter(
							item => !(item.type === 'element' && item.id === element.id)
						);
						setSlideEditorSelection(newSelection);
					} else {
						// Add the element to the selection
						setSlideEditorSelection([...selectedSlideItems, { type: 'element', id: element.id }]);
					}
				} else {
					// Elements are not in the same parent group or one has no parent, cannot multiselect
					// Optionally, you can show a message or ignore the action
				}
			}
		}
		event.stopPropagation();
		return;
	}
	else{
		//Allow clicking directly into text from another text element even if in different layout group
		if (element.type === 'text') {
			if (selectedSlideItems.length === 1 && selectedSlideItems[0].type === 'element') {
				const selectedEl = slide.elements.find(el => el.id === selectedSlideItems[0].id);
				if (selectedEl && selectedEl.type === 'text') {
					setSlideEditorSelection([{ type: 'element', id: element.id }]);
					return;
				}
			}
	}

	if (element.type === 'image' && element.metadata.isBGImage) { //if click slected BG image then unselect it
		if(this.isSlideItemSelected(element.id)){
			setSlideEditorSelection([])
			return
		}
	}

	// Find the next level down in the layout hierarchy
	const findNextLevelDown = (currentGroupId, targetElementId) => {
		const currentGroup = findGroupById(slide.layout, currentGroupId);
		if (!currentGroup) return null;
		for (let child of currentGroup.children) {
			if (child.id === targetElementId) {
				return null; // We've reached the target element
			}
		 // if (child.isLayoutGroup && isParentOf(child.id, targetElementId, slide.layout)) {
		 if (child.isLayoutGroup && slide.isElementInLayoutGroup(targetElementId,child.id)) {
				return child;
			}
		}
		return null;
	};

	if (isDoubleClick) {
		// Double-click behavior: drill down a level
		const currentSelection = selectedSlideItems[0]; 
		if (currentSelection && currentSelection.type === 'layoutGroup') {
			const nextLevel = findNextLevelDown(currentSelection.id, element.id);
			if (nextLevel) {
				setSlideEditorSelection([{ type: 'layoutGroup', id: nextLevel.id }]);
			} else {
				setSlideEditorSelection([{ type: 'element', id: element.id }]);
			}
		} else {
			// If no layout group is currently selected, behave like a single click
			const outermostGroup = findParentLayoutGroup(element.id, slide.layout);
			if (outermostGroup) {
				setSlideEditorSelection([{ type: 'layoutGroup', id: outermostGroup.id }]);
			} else {
				setSlideEditorSelection([{ type: 'element', id: element.id }]);
			}
		}
	} else {
		// Single-click behavior: select the outermost parent layout group only if current selection is not a parent
		const currentSelection = selectedSlideItems[0]; 
		if (currentSelection && currentSelection.type === 'layoutGroup') {
			const isInLayoutGroup = slide.isElementInLayoutGroup(element.id,currentSelection.id)
			if(isInLayoutGroup){
				// console.log('current selection is a parent so dont do anything')
				return;
			}
		}
		let selectionParentGroup 
		if(currentSelection){
			selectionParentGroup=findParentLayoutGroup(currentSelection.id,slide.layout)
		}
		const outermostGroup = findParentLayoutGroup(element.id, slide.layout);
		if(selectionParentGroup && outermostGroup && selectionParentGroup.id == outermostGroup.id){ //just select the eleement
			const selectionImmediateParent = findImmediateParentLayoutGroup(currentSelection.id,slide.layout)
			const clickedImmediateParent = findImmediateParentLayoutGroup(element.id,slide.layout)
			if(clickedImmediateParent.depth <= selectionImmediateParent.depth){
				setSlideEditorSelection([{ type: 'element', id: element.id }]);
				return
			}
		}
		if (outermostGroup) {
			setSlideEditorSelection([{ type: 'layoutGroup', id: outermostGroup.id }]);
		} else {
			setSlideEditorSelection([{ type: 'element', id: element.id }]);
		}
	}
}
	event.stopPropagation();
};

	handleElementMouseDown = (element, event) => {
		const isSelected=this.isSlideItemSelected(element.id)
		if (!isSelected) {
			this.setState({
				isMouseDownOnUnselectedElement: true,
				mouseDownElementId: element.id,
			});
		}
	}

	getSlideHash(slide) {
		const slideString = JSON.stringify(slide);
		return simpleHash(slideString);
	}

	calculatePositions=()=>{
		const {slide} = this.props
		if (!slide) return;
		//1. measure ghosts
		const textElements = this.props.slide.elements.filter(element => element.type === 'text');
		textElements.forEach(element => {
			const ghostElement = document.getElementById(`ghost_${element.id}`);
			if(ghostElement){
				element.height = ghostElement.clientHeight
				element.width= ghostElement.clientWidth
			}												
		});
		//2. sets the positions on the elements
		calculateElementPositions(slide && slide.layout,this.props.slideWidth,this.props.slideHeight); 
		this.props.handleUpdateCanvasFromSlideEditor();
	}

	handleSpacerDrag = (slideItem, data, type) => {
		const deltaY = data.deltaY * 2;
		const deltaX = data.deltaX * 2;
		if(type === 'bottom'){
			const newBottomMargin = (slideItem.bottomMargin || 0) + deltaY;
			slideItem.bottomMargin = Math.max(newBottomMargin, 0)
			this.setState({dragElementY:newBottomMargin})
		}
		if(type === 'right'){
			const newRightMargin = (slideItem.rightMargin || 0) + deltaX;
			slideItem.rightMargin= Math.max(newRightMargin, 0)
			this.setState({dragElementX:newRightMargin})
		}
		// this.props.handleDimensionsUpdatedOnDrag();
		this.calculatePositions()
		//TODO batch drag tr- add start/end spacer drag bits
		this.props.handleSyncSlideChanges()
	}

	defocusElement=(element)=>{
		if (element.type === 'text') {
			const view = window[`textEditView_${element.id}`];
			if (view) {
				view.dom.blur(); 
				const { state, dispatch } = view;
				dispatch(state.tr.setSelection(TextSelection.create(state.doc, 0, 0)));
			}
		}
	}

	handleDragStop = (draggedElement, data) => {
		const {slide} = this.props
		slide && slide.elements.forEach(element => {
			element.previewX = null;
			element.previewY = null;
			element.previewDeltaX = null;
			element.previewDeltaY = null;
		});
		// Reset preview and preview delta for all layout groups
		const resetLayoutGroups = (group) => {
			group.previewX = null;
			group.previewY = null;
			group.previewDeltaX = null;
			group.previewDeltaY = null;
			group.children.forEach(child => {
				if (child.isLayoutGroup) {
					resetLayoutGroups(child);
				}
			});
		};
		resetLayoutGroups(slide.layout);
		this.props.handleSetSlideEditorDragElement(null,null)
		this.props.setIsDraggingToReorder(false)
		this.setState({dragPosition:null,isMouseDownOnUnselectedElement: false,mouseDownElementId: null,isDragging:false,snapLines:[]})
		this.props.handleSlideDragOrResizeEnd()
		//this.props.handleSyncSlideChanges()
	}

	handleDragStart = (slideElement, data, event) => {
		this.props.handleSlideDragOrResizeStart()
	}

	findNearestSelectedParentGroup = (elementId) => {
		const findParentChain = (id, group, chain = []) => {
			for (let child of group.children) {
				if (child.id === id) {
					return [...chain, group];
				}
				if (child.isLayoutGroup) {
					const result = findParentChain(id, child, [...chain, group]);
					if (result) return result;
					}
				}
			return null;
		};
		const parentChain = findParentChain(elementId, this.props.slide.layout);
		if (!parentChain) return null;
		// Reverse the chain to start from the immediate parent
		for (let i = parentChain.length - 1; i >= 0; i--) {
			if (this.isSlideItemSelected(parentChain[i].id)) {
				return parentChain[i].id;
			}
		}
		return null;
	};

	//if element is not selected and it is in a layout group then select that group and drag it
	//if element is selected then either move it if its not in a layout group or reorder it if it is in a layout group
	handleElementDrag = (slideElement, data,event,isLocked) => {
		if(!isLocked){
		let isDraggingToReorder = this.props.isDraggingToReorder
		if(!this.state.isDragging){
			this.setState({isDragging:true})
			this.defocusElement(slideElement);
			const isElementSelected = this.isSlideItemSelected(slideElement.id);
			const parentLayoutGroup = findParentLayoutGroup(slideElement.id, this.props.slide.layout);
			const nearestSelectedParentGroupId = this.findNearestSelectedParentGroup(slideElement.id);


			let dragElementId = null
			let dragGroupId = null
			if (!isElementSelected) {
				if (!parentLayoutGroup) {
					this.props.setSlideEditorSelection([{ type: 'element', id: slideElement.id }]);
					dragElementId=slideElement.id
				} else if (!nearestSelectedParentGroupId) {
					this.props.setSlideEditorSelection([{ type: 'layoutGroup', id: parentLayoutGroup.id }]);
					dragGroupId = parentLayoutGroup.id;
				} else {
					dragGroupId = nearestSelectedParentGroupId;
			 }
			}else{
				dragElementId = slideElement.id
			}
			//if is in a layout group drag to reorder otherwise just free drag
			if(parentLayoutGroup && (isElementSelected || (dragGroupId && dragGroupId!=parentLayoutGroup.id))){
				this.props.setIsDraggingToReorder(true)
				isDraggingToReorder=true
				slideElement.previewX = slideElement.x 
				slideElement.previewY = slideElement.y 
				slideElement.previewDeltaY = 0
				slideElement.previewDeltaX = 0
			}
			this.props.handleSetSlideEditorDragElement(dragElementId, dragGroupId);
		}else{ //TODO this is a bit hacky as you will miss the first all to handle element dragging but yolo
			if(isDraggingToReorder){
				this.handleDragToReorder(slideElement,data)
			}else{
				this.handleNormalDrag(slideElement,data)
			}
		}
	}
	}

	handleNormalDrag=(slideElement,data)=>{
		const {dragElementId,dragLayoutGroupId} = this.props 
		const { x, y,deltaX,deltaY } = data;
		if(dragElementId){
			const snapLines = calculateSlideSnapLines(this.props.slide.layout,slideElement.id,this.props.slideWidth,this.props.slideHeight);
			const { x: snappedX, y: snappedY } = calculateSnappedPosition(
				slideElement,
				x,
				y,
				deltaX,
				deltaY,
				snapLines
			);
			slideElement.x = snappedX;
			slideElement.y = snappedY;			
			this.setState({snapLines:snapLines})	
			//this.props.handleDimensionsUpdatedOnDrag()
		}else{
		const selectedGroup = findGroupById(this.props.slide.layout, dragLayoutGroupId);
			const snapLines = calculateSlideSnapLines(this.props.slide.layout, dragLayoutGroupId,this.props.slideWidth,this.props.slideHeight);
			// Calculate the group's movement based on the dragged element's position change
			const groupDeltaX = x - slideElement.x;
			const groupDeltaY = y - slideElement.y;
			// Calculate the new group position
			const newGroupX = selectedGroup.x + groupDeltaX;
			const newGroupY = selectedGroup.y + groupDeltaY;
			const { snappedDeltaX, snappedDeltaY, snapType } = calculateSnappedPosition(
				selectedGroup,
				newGroupX,
				newGroupY,
				groupDeltaX,
				groupDeltaY,
				snapLines,
				true // isLayoutGroup
			);
			this.moveGroupAndChildren(selectedGroup, snappedDeltaX, snappedDeltaY);
			this.setState({ snapLines: snapLines });
			this.calculatePositions();
		}
	}

	moveGroupAndChildren = (group, deltaX, deltaY) => {
		group.x += deltaX;
		group.y += deltaY;  
		group.children.forEach(child => {
			if (child.isLayoutGroup) {
				this.moveGroupAndChildren(child, deltaX, deltaY);
			} else {
				child.x += deltaX;
				child.y += deltaY;
			}
		});
	}

	addPreviewDeltaToGroupAndChildren = (group, deltaX, deltaY) => {
			group.previewDeltaY = deltaY
			group.previewDeltaX = deltaX
			group.previewY =  group.y + deltaY 
			group.previewX = group.x + deltaX
			group.children.forEach(child => {
				if (child.isLayoutGroup) {
				 // this.moveGroupAndChildren(child, deltaX, deltaY);
					this.addPreviewDeltaToGroupAndChildren(child, deltaX, deltaY);
				} else {
					child.previewDeltaY = deltaY
					child.previewDeltaX = deltaX
					child.previewY =  child.y + deltaY 
					child.previewX = child.x + deltaX
				}
			});
		}

	handleDragToReorder=(slideElement,data)=>{	
		const {dragElementId,dragLayoutGroupId,slide}=this.props
		if(dragElementId){
		const parentLayoutGroup= findImmediateParentLayoutGroup(slideElement.id, this.props.slide.layout);  
		slideElement.previewDeltaY =  data.y -slideElement.y 
		slideElement.previewDeltaX = data.x -slideElement.x 
		slideElement.previewY =  data.y 
		slideElement.previewX = data.x 
		this.updateElementPositionInGroup(slideElement, parentLayoutGroup);
		this.props.handleUpdateCanvasFromSlideEditor()
		//this.props.handleDimensionsUpdatedOnDrag();
		}else{
			const {x,y} = data
			const selectedGroup = findGroupById(slide.layout, dragLayoutGroupId);
			const parentLayoutGroup= findImmediateParentLayoutGroup(dragLayoutGroupId, this.props.slide.layout);  
			const groupDeltaX = x - slideElement.x;
			const groupDeltaY = y - slideElement.y;
			this.addPreviewDeltaToGroupAndChildren(selectedGroup,groupDeltaX,groupDeltaY)
			this.updateElementPositionInGroup(selectedGroup, parentLayoutGroup);
			this.props.handleUpdateCanvasFromSlideEditor()
		//	this.props.handleDimensionsUpdatedOnDrag();
		}
	}

handleContextMenu = (event, scalar, slideElements) => {
	event.preventDefault(); // Prevents the default context menu from appearing
	const slideEditorElement = document.getElementById("editor-slideEditor");
	if (slideEditorElement && slideElements) {
		const rect = slideEditorElement.getBoundingClientRect();
		const normalizedX = (event.clientX - rect.left) * (1/scalar);
		const normalizedY = (event.clientY - rect.top) * (1/scalar);
		let clickedElement = null;
		let highestZIndex = -Infinity;
		for (const element of slideElements) {
			if (
				normalizedX >= element.x &&
				normalizedX <= element.x + element.width &&
				normalizedY >= element.y &&
				normalizedY <= element.y + element.height
			) {
				const elementZIndex = element.metadata.zOrder || 0;
				if (elementZIndex > highestZIndex) {
					clickedElement = element;
					highestZIndex = elementZIndex;
				}
			}
		}
		if (clickedElement) {
			 this.props.openSlideEditorContextMenu(this.props.slide.id,clickedElement.id,{x:event.clientX,y:event.clientY})
		}
	} else {
		//console.error("Could not find the slideEditor element");
	}
}

	updateElementPositionInGroup = (draggedItem,layoutGroup) => {
		const SWAP_THRESHOLD = 0.5;
		const updateGroup = (currentGroup) => {
		let itemsToSort = [];
		if(currentGroup && currentGroup.children){
			for (let child of currentGroup.children) {
			 itemsToSort.push(child);
			}
		}
		if (itemsToSort.length > 0) {
			const draggedIndex = itemsToSort.findIndex(item => item.id === draggedItem.id);
			if (draggedIndex !== -1) {
				const isDraggingDown = draggedItem.previewDeltaY > 0;
				const isDraggingRight = draggedItem.previewDeltaX > 0;
				if (currentGroup.type === 'vstack') {
					const draggedBottom = draggedItem.y + draggedItem.height + (draggedItem.previewDeltaY || 0);
					for (let i = 0; i < itemsToSort.length; i++) {
						if (i !== draggedIndex) {
							const itemMidY = itemsToSort[i].y + itemsToSort[i].height * SWAP_THRESHOLD;
							if ((isDraggingDown && draggedBottom > itemMidY && i > draggedIndex) ||
							(!isDraggingDown && draggedItem.y + (draggedItem.previewDeltaY || 0) < itemMidY && i < draggedIndex)) {
								[itemsToSort[draggedIndex], itemsToSort[i]] = [itemsToSort[i], itemsToSort[draggedIndex]];
								break;
							}
						}
					}
				} else if (currentGroup.type === 'hstack') {
					const draggedRight = draggedItem.x + draggedItem.width + (draggedItem.previewDeltaX || 0);
					for (let i = 0; i < itemsToSort.length; i++) {
						if (i !== draggedIndex) {
							const itemMidX = itemsToSort[i].x + itemsToSort[i].width * SWAP_THRESHOLD;
							if ((isDraggingRight && draggedRight > itemMidX && i > draggedIndex) ||
							(!isDraggingRight && draggedItem.x + (draggedItem.previewDeltaX || 0) < itemMidX && i < draggedIndex)) {
								// Swap items
								[itemsToSort[draggedIndex], itemsToSort[i]] = [itemsToSort[i], itemsToSort[draggedIndex]];
								break;
							}
						}
					}
				}
				itemsToSort.forEach((item, index) => {
					currentGroup.reorderChild(item.id, index);
				});
				return true; // Indicate that we found and updated the relevant group
				}
			}
			return false; // Item not found in this group or its descendants
		};
		return updateGroup(layoutGroup);
	}

	findFirstParentLayoutGroup = () => { //for showing the immediate parent to the current selection 
		const { selectedSlideItems, slide } = this.props;
		if (!selectedSlideItems || selectedSlideItems.length === 0 || !slide || !slide.layout) {
			return null;
		}
		//if (selectedSlideItems.length === 0) return null;
		// Check only for the first selected item (assuming all selected items are in the same group)
		const firstSelectedItem = selectedSlideItems[0];
		return findImmediateParentLayoutGroup(firstSelectedItem.id, slide.layout);
	}

	handleResizeStart=(resizeElementId)=>{
		this.props.handleSlideDragOrResizeStart()
		this.props.handleSetSlideEditorResizeElement(resizeElementId)
	}

	handleResizeEnd=()=>{
		this.props.handleSlideDragOrResizeEnd()
		this.props.handleSetSlideEditorResizeElement(null)
	}

	

	handleDragOver = (event) => {
		event.preventDefault();
		this.setState({ isDraggingOver: true });
	}

	handleDragLeave = () => {
		this.setState({ isDraggingOver: false });
	}

	handleDrop = (event) => {
		event.preventDefault();
		this.setState({ isDraggingOver: false });
		const files = event.dataTransfer.files;
		if (files.length > 0) {
			const file = files[0];
			if (file.type.startsWith('image/')) {
				const dropPosition = this.calculateDropPosition(event);
				this.handleImageDrop(file,dropPosition);
			}
		}
	}

	calculateDropPosition = (event) => {
		const editorRect = this.editorRef.current.getBoundingClientRect();
		const scalar = this.props.canvasWidth / this.props.slideWidth
		const x = (event.clientX - editorRect.left) / scalar;
		const y = (event.clientY - editorRect.top) / scalar;
		return { x, y };
	}

	handleImageDrop = (file,dropPosition) => {
		this.props.handleSlideImageFileUpload(file,null,dropPosition)
	}


	render(){       
		const {canvasWidth, canvasHeight, slide, hidden, projectBackground, selectedSlideItems, selectedLayoutGroup, focusedSlideElement, dragElementId, dragLayoutGroupId, resizeElementId} = this.props	

		const {slideWidth, slideHeight} = this.props
		const {isDraggingBottomMargin, isDraggingToSelect,snapLines,isDragging} = this.state
		const scalar = canvasWidth / slideWidth
		const backgroundId =  slide && slide.metadata && slide.metadata.backgroundId
		let background = projectBackground
		if(backgroundId && backgroundId!=='none'){ 
			background=getBackgroundForId(backgroundId)
		}		


		 let slideBGIsLight = false 
		// const backgroundColor = clip.metadata.
		if(background && background.type ==='solid' && background.rgba){
			slideBGIsLight = isSlideBackgroundLight(background.rgba);
		}
		if(background && background.type ==='image' && background.src === '../bgImages/causal/causalBG.png'){
			slideBGIsLight = true
		}


		const isDraggingElement = dragElementId ? true : false
		const layoutGroups = slide && slide.layoutGroups

		const immediateParentLayout = this.findFirstParentLayoutGroup();

		const textZIndex = 60
		const imageZIndex = 55
		const textAreaZIndex = 910

		const isResizingElement = resizeElementId?true:false
		let dragItem

		if(dragLayoutGroupId){
			dragItem = findGroupById(slide.layout,dragLayoutGroupId)
		}
		if(dragElementId){
			dragItem = slide && slide.elements.find(el => el.id === dragElementId);
		}

		let promotedTextElement 
		if(selectedSlideItems.length==1 && selectedSlideItems[0].type=='element'){
			promotedTextElement = selectedSlideItems[0].id
		}
		if(this.props.isDraggingToReorder){
			promotedTextElement = null
		}

		let uiColorMode = 'blue'
		if(!slideBGIsLight){
			uiColorMode = 'yellow'
		}

		return (            				
			<>			
				{slide && 
				<div data-state={hidden ? 'hidden' : 'visible'} style={{width: `${slideWidth}px`, height: `${slideHeight}px`}} className='editor-slideEditorCanvasContainer'>		        					
					<Canvas className='editor-slideEditorCanvas' style={{ width: `calc(${slideWidth}px * 1/${scalar})`, height: `calc(${slideHeight}px * 1/${scalar})` }} gl={{ alpha: true, toneMapping: THREE.NoToneMapping }}>	        	
						<SlideEditorCanvasPreview
							slide={slide}	          	
							background={background}		
							promotedTextElement={promotedTextElement}
							sceneWidth={slideWidth}
							sceneHeight={slideHeight}
						/>
					</Canvas>
				</div>
				}


				<div 
					onDragOver={this.handleDragOver}
					onDragLeave={this.handleDragLeave}
					onDrop={this.handleDrop}
					ref={this.editorRef}
					id="editor-slideEditor"
					data-ui-color-mode={uiColorMode}
					data-dragging-to-select={isDraggingToSelect ? true : false}
					data-resizing-element={isResizingElement ? true : false}
					data-dragging-element={isDraggingElement ? true : false}
					data-dragging-bottom-margin={isDraggingBottomMargin ? true : false} 					
					style={{width: `${slideWidth}px`, height: `${slideHeight}px`}}
					className='editor-slideEditor'
					onContextMenu={(e) => this.handleContextMenu(e, scalar, slide && slide.elements)}
				>
				
					{this.props.showCameraEditor &&
						<CameraEditor             
							currentTime={this.props.currentTime}
							slideClips={this.props.slideClips} 
							videoClips={this.props.videoClips}
							selectedWebcam={this.props.selectedWebcam}           
							scalar={scalar}
							updateClipMetadata={this.props.updateClipMetadata}
							isResizingWebcam={this.props.isResizingWebcam}
							isDraggingWebcam={this.props.isDraggingWebcam}
							setIsResizingWebcam={this.props.setIsResizingWebcam}
							setIsDraggingWebcam={this.props.setIsDraggingWebcam}
							setCameraPreviewRect={this.props.setCameraPreviewRect}
							slideClipAtPlayhead={this.props.slideClipAtPlayhead}
							videoClipAtPlayhead={this.props.videoClipAtPlayhead}
							sceneWidth={slideWidth}
							sceneHeight={slideHeight}							
							bgIsLight={slideBGIsLight}
						/>
					}
				
					{/* Dragging images/charts (they are rendered on canvas)*/}
					{slide && slide.elements.map((slideElement)=>{			
						if(slideElement.type!=='text'){ 
							const isSelected = this.isSlideItemSelected(slideElement.id)	
							let adjustedTextTranslateY = 0
							let zIndex = 100
							if(slideElement.zIndex){
								zIndex = 100 + slideElement.zIndex
							}	
							const isLocked= this.isSlideItemLocked(slideElement.id)				
							let isBGImage = false
							if(slideElement.metadata.isBGImage){
								isBGImage = true
								zIndex = 50 + slideElement.zIndex
							}
							const adjustedElementWidth = isBGImage ? slideWidth : slideElement.width
							const adjustedElementHeight = isBGImage ? slideHeight : slideElement.height
							const adjustedElementX = isBGImage ? 0 : (slideElement.previewX || slideElement.x)
							const adjustedElementY = isBGImage ? 0 : (slideElement.previewY || slideElement.y)
							return(
								<Rnd
									key={`rnd_${slideElement.id}`}
									size={{ width: adjustedElementWidth, height: adjustedElementHeight }}
									position={isDragging ? null: { x: adjustedElementX, y: adjustedElementY}}			               							  
									onDragStop={(e, data) => this.handleDragStop(slideElement, data)}     
									onDrag={(e, data) => this.handleElementDrag(slideElement, data,e)}    
									onDragStart={(e, data) => this.handleDragStart(slideElement, data,e)}    	                		
									scale={scalar}	                
									className={
									'editor-slideEditor-draggableItem editor-slideEditor-draggableItem--media ' 
									+ (isSelected? ' editor-slideEditor-draggableItem--selected ' : ' editor-slideEditor-draggableItem--unselected ')
									+ (dragElementId==slideElement.id ? ' editor-slideEditor-draggableItem--dragging ' : ' editor-slideEditor-draggableItem--notDragging ')
									}
									enableResizing={false}
									onMouseDown={(e) => this.handleElementMouseDown(slideElement, e)}
									onClick={(e) => {this.handleElementClick(slideElement, e)}}
									disableDragging={isDraggingToSelect || isResizingElement || isLocked || isBGImage}			                
									style={{
										cursor: 'pointer',
										marginTop: `${adjustedTextTranslateY}px`,	                	
										zIndex: zIndex,
									}}			                
								/>
							)
						}
					})}

					{/* Dragging and editing text */}
					{slide && slide.elements.map((slideElement)=>{			
						if(slideElement.type=='text'){
							const isSelected = this.isSlideItemSelected(slideElement.id)	
							let adjustedTextTranslateY = 0													
							const hAlign = slideElement.metadata.textHAlign
							const vAlign = slideElement.metadata.textVAlign
							//can click into it if its focused or its selected and it is the only item selected
							const isFocused = focusedSlideElement==slideElement.id		
							const isFocusable = (!this.state.isMouseDownOnUnselectedElement && isSelected	) || focusedSlideElement							
							const isDragging = dragElementId === slideElement.id
							let zIndex = 100
							if(slideElement.zIndex){
								zIndex = 100 + slideElement.zIndex
							}	
							const isLocked= this.isSlideItemLocked(slideElement.id)	
							const adjustedElementX =  (slideElement.previewX || slideElement.x)
							const adjustedElementY = (slideElement.previewY || slideElement.y)

							return(
								<Rnd
									key={`rnd_${slideElement.id}`}
									size={{ width: slideElement.width, height: slideElement.height }}
									position={isDragging ? null: { x: adjustedElementX, y: adjustedElementY}}	
									onDragStop={(e, data) => this.handleDragStop(slideElement, data)}     
									onDrag={(e, data) => this.handleElementDrag(slideElement, data,e,isLocked)}    
									onDragStart={(e, data) => this.handleDragStart(slideElement, data,e)}    	                		
									scale={scalar}	                
									className={
										'editor-slideEditor-draggableItem editor-slideEditor-draggableItem--text ' 
										+ (isSelected ? ' editor-slideEditor-draggableItem--selected ' : ' editor-slideEditor-draggableItem--unselected ')
										+ (isFocused ? ' editor-slideEditor-draggableItem--focused ' : ' editor-slideEditor-draggableItem--unfocused ')
										+ (isFocusable ? ' editor-slideEditor-draggableItem--focusable ' : ' editor-slideEditor-draggableItem--unfocusable ')
										+ (dragElementId==slideElement.id ? ' editor-slideEditor-draggableItem--dragging ' : ' editor-slideEditor-draggableItem--notDragging ')
									}	                
									enableResizing={false}
									onMouseDown={(e) => this.handleElementMouseDown(slideElement, e)}
									onClick={(e) => {this.handleElementClick(slideElement, e)}}
									disableDragging={isDraggingToSelect || isFocused || isResizingElement}
									style={{
										cursor: 'pointer',
										marginTop: `${adjustedTextTranslateY}px`,	
										zIndex: zIndex
									}}
								>
									{!isDragging && 
										<div data-element-type='text' style={{width: `${slideElement.width}px`, height: `${slideElement.height}px`, zIndex: textAreaZIndex}} className='editor-slideEditor-textAreaContainer'>
											<SlideEditorTextInput 
												key={`slide_text_input_${slideElement.id}`}
												elementId={slideElement.id}
												isFontLoaded={slideElement.isFontLoaded}
												element={slideElement}
												initialValue={slideElement.metadata.docJson}
												clipId={slide.id}
												selectSlideElement={this.props.selectSlideElement}
												handleSetLettersArray={this.props.handleSetLettersArray}
												canvasX={this.props.canvasX}
												canvasY={this.props.canvasY}
												canvasWidth={this.props.canvasWidth}
												canvasHeight={this.props.canvasHeight}
												invertScalar={this.props.invertScalar}
												hAlign={hAlign}
												slideHash={this.state.slideHash}
												slide={slide}
												setFocusedSlideElement={this.props.setFocusedSlideElement}
												updateSlideElementTextSelectionInfo={this.props.updateSlideElementTextSelectionInfo}
												textStyle={slideElement.metadata.textStyle}
												text={slideElement.metadata.text}
												textProperties={slideElement.metadata.textProperties}
												textResizeType={slideElement.metadata.textResizeType}
												textPropertiesString={JSON.stringify(slideElement.metadata.textProperties)}
												elementWidth={slideElement.width}
												focusedSlideElement={focusedSlideElement}
												slideWidth={slideWidth}
												slideHeight={slideHeight}
											/>
										</div>
									}	
								</Rnd>
							)
						}
					})}


					{/* RESIZE ELEMENTS */}
					{slide && slide.elements.map((slideElement)=>{
						if(!isDragging && slideElement.type=='text'){
							const isSelected = this.isSlideItemSelected(slideElement.id)	
							const isFocused = focusedSlideElement==slideElement.id
							if(isSelected && !isFocused){
								const isLocked= this.isSlideItemLocked(slideElement.id)	
								const parentGroup = findParentLayoutGroup(slideElement.id, slide.layout, false);
								const isInLayoutGroup = parentGroup? true:false 
								if(!isLocked){
								return(
									<SlideEditorResizableHandles
										x={slideElement.x}
										y={slideElement.y}
										width={slideElement.width}
										height={slideElement.height}
										scalar={scalar}
										isText={true}
										updateSlideElementField={this.props.updateSlideElementField}
										clipId={slide.id}
										element={slideElement}		
										handleResizeStart={this.handleResizeStart}
										handleResizeEnd={this.handleResizeEnd}				
										invertScalar={1/scalar}
										isResizing={isResizingElement}
										isInLayoutGroup={isInLayoutGroup}
										uiColorMode={uiColorMode}
									/>
								)
							}
							}
						}
						if(!isDragging && (slideElement.type=='image' || slideElement.type=='chart')){
							const isSelected = this.isSlideItemSelected(slideElement.id)	
							if(isSelected){
								const isLocked= this.isSlideItemLocked(slideElement.id)	
								const isBGImage = slideElement.metadata.isBGImage
								const parentGroup = findParentLayoutGroup(slideElement.id, slide.layout, false);
								const isInLayoutGroup = parentGroup? true:false 

								if(!isLocked && !isBGImage){
									return(
										<SlideEditorResizableHandles
											x={slideElement.x}
											y={slideElement.y}
											width={slideElement.width}
											height={slideElement.height}
											scalar={scalar}
											updateSlideElementField={this.props.updateSlideElementField}
											clipId={slide.id}
											element={slideElement}
											isText={false}
											fixedAspect={!slideElement.metadata.isFill && slideElement.type !== 'chart'}									
											handleResizeStart={this.handleResizeStart}
											handleResizeEnd={this.handleResizeEnd}						
											invertScalar={1/scalar}
											isResizing={isResizingElement}
											isInLayoutGroup={isInLayoutGroup}
											uiColorMode={uiColorMode}
										/>
									)
								}
							}
						}
					})}

					{slide && slide.elements.map((slideElement)=>{		
						if(!isDragging){
							const isSelected = this.isSlideItemSelected(slideElement.id)	
							if(isSelected){	
								let width = slideElement.width
								let height = slideElement.height
								let x = slideElement.x
								let y = slideElement.y
								if(slideElement.metadata.isBGImage){
									width = slideWidth - 2
									height = slideHeight - 2
									x = 1
									y = 1
								}
								return(
									<div 
										style={{										
											width: `${width}px`,
											height: `${height}px`,
											left: `${x}px`,
											top: `${y}px`,
											outlineWidth: `${2 * 1/scalar}`,
										}}
										className='editor-slideEditor-selectionBox' 
										data-selection-box='normal'
									/>
								)
							}
						}
					})}

					{/* GROUP SPACERS */}

					{slide && 
					 <SlideEditorSpacers 
							slide={slide}
							scalar={scalar}
							handleSpacerDrag={this.handleSpacerDrag}
							setSlideEditorDraggingSpacer={this.props.setSlideEditorDraggingSpacer}
							isSlideItemLocked={this.isSlideItemLocked}
					 />
					}

					{slide && layoutGroups.map((layoutGroup)=>{		
						if(layoutGroup.depth>0 && (this.isSlideItemSelected(layoutGroup.id) || immediateParentLayout&&immediateParentLayout.id==layoutGroup.id)){	
							const isImmediateParent=immediateParentLayout&&immediateParentLayout.id==layoutGroup.id
							let x = layoutGroup.previewX || layoutGroup.x 
							let y = layoutGroup.previewY || layoutGroup.y
							return(
								<div 
								style={{
									height: `${layoutGroup.height}px`,
									width: `${layoutGroup.width}px`,
									left: `${x}px`,
									top: `${y}px`,
									outlineWidth: `${2 * 1/scalar}`,
								}}
								data-selection-box={isImmediateParent ? 'light' : 'normal'}
								className='editor-slideEditor-selectionBox' 
							/>
							)
						}
					})}



					{slide && isDragging &&
						 <SlideEditorSnapLines 
							snapLines={snapLines}
							slideWidth={slideWidth}
							slideHeight={slideHeight}
							elements = {slide.elements}
							dragItem={dragItem}
							scalar={scalar}
						/>
					}

					{slide &&
						<SlideEditorDragSelectArea
							elements={slide.elements}
							slide={slide}
							findParentLayoutGroup={findParentLayoutGroup}
							setSlideEditorSelection={this.props.setSlideEditorSelection}
							clearSlideElementsSelection={this.props.clearSlideElementsSelection}
							scalar={scalar}		   
							isDraggingToSelect={this.state.isDraggingToSelect}     
							setIsDraggingToSelect={(value) => this.setState({isDraggingToSelect: value})}
							setEditTextMode={()=>{}}
							invertScalar={1/scalar}			
							slideWidth={slideWidth}
							slideHeight={slideHeight}        
						/>
					}
					</div>	

					<div style={{width: `${slideWidth}px`, height: `${slideHeight}px`}} className='editor-slideEditor-ghostContainer'>
					{slide && slide.elements.map((slideElement)=>{					
						if(slideElement.type=='text'){
							const width = slideElement.width
							return(
								<SlideTextElementGhost 
									key={`ghost_component_${slideElement.id}`}
									elementId={slideElement.id}
									element={slideElement}										
									slideElement={slideElement}
									clipId={slide.id}								
									width={width}
								/>
							)
						}
					})}
				</div>		
			</>
		)
	}
}

export default SlideEditor
